import React, { useEffect, useState } from 'react'
import { getThisWeek } from '../../components/dateRangePicker/dateRangePicker'
import FilterRow from '../../components/filterRow/filterRow'
import { Button, Pagination, Tooltip } from '@mui/material'
import * as Constants from '../../data/constants';
import axios from 'axios';
import { bytesToSize, getLocalStorageValue } from '../../util/util';
import TableModel from '../../components/tableModel/tablemodel';
import ShowLoaderComponent from '../../components/loader';
import { Store } from 'react-notifications-component';
import ReactSelect from 'react-select';
import changeConversionImage from '../../img/changeConversion.png'
import { getFilterDict, updateUrlFilter } from './filterSearchAndSet';
import { isTestingUser } from '../../data/sidebarTabs';

const PostbacksV4 = (props) => {
    var {firstDayOfThisWeek,lastDayOfThisWeek} = getThisWeek()

    var optionsAdvertiserList= props.optionsAdvertiserList
    var optionsManagersList= props.optionsManagersList
    var optionsAffiliateList= props.optionsAffiliateList

    const [loader,setLoader] = useState(false)
    const [exportLoader,setExportLoader] = useState(false)
    const [limit, setLimit] = useState(50);
    const [page, setPage] = useState(1);
    const [offset, setOffset] = useState(0);
    const [totalResults, setTotalResults] = useState(0);
    const [tableValues,setTableValues] = useState([])
    const [loadingSeconds, setLoadingSeconds] = useState(0)
    const [responseSize, setResponseSize] = useState(0)
    const [tableValuesSelected,setTableValueSelected] = useState([])
    const [groupByRequested, setGroupByRequest] = useState(false);
    const [totalRow, setTotalRow] = useState({});

    var filtersList = [
        {label:'Date Range',paramKey:'dateRange',value:'date_range',type:'daterangepicker'},
        {label:'Offer Id',paramKey:'offerId',value:'offer_id',type:'textfield'},
        // {label:'External Offer Id',paramKey:'externalOfferId',value:'external_offer_id',type:'textfield'},
        {label:'Advertiser',paramKey:'advertiser',value:'advertiser_id',type:'select',multiple:true,options:optionsAdvertiserList},
        {label:'Affiliate',paramKey:'affiliate',value:'affiliate_id',type:'select',multiple:true,options:optionsAffiliateList},
        {label:'Conversion Status',value:'type',type:'select',isSingle:true,options:[{label:'All',value:undefined},{label:'Approved',value:'approved'},{label:'Declined',value:'declined'}]},
        {label:'Goal Value',value:'goal_value',type:'textfield'},
        {label:'Declined Reason',value:'declined_reason',type:'select',isSingle:true,isClearable:true,options:[{label:"Caps Limit Reached",value:'cap-reached'},{label:"Non Payable Goal",value:"payment-not-found"},{label:"By Manager",value:"by-manager"},],show:true},
        {label:'Group By',isSingle:true,isClearable:true,value:'group_by',type:'select',options:[{label:"Declined Reason",value:'declined_reason'},{label:"Goal Value",value:"goal_value"}],show:true},
        {label:'p_sub1',value:'p_sub1',type:'textfield'},
        {label:'p_sub2',value:'p_sub2',type:'textfield'},
        {label:'p_sub3',value:'p_sub3',type:'textfield'},
        {label:'p_sub4',value:'p_sub4',type:'textfield'},
        {label:'p_advertiser_id',value:'p_advertiser_id',type:'textfield'},
        {label:'p_offer_id',value:'p_offer_id',type:'textfield'},
    ]
    
    var defaultFilterData = {
        from_date:new Date(),
        end_date:new Date(),
        show_converted_stats:{label:'Yes',value:true},
    }
    const [filterData, setFilterData] = useState({...defaultFilterData,...getFilterDict(filtersList)})

    const getStatsData = async(e,manual_limit,manual_page) => {
        if(e==='export'){
            setExportLoader(true)
        }
        else{
            setLoader(true)
        }
        setOffset((manual_limit ? manual_limit : limit)*(manual_page ? (manual_page-1) : (page-1)))
        var start_time = new Date().getTime()
        var from_date = filterData.from_date.toLocaleString('fr-CA',{year:'numeric',month:'numeric',day:'numeric'})
        var end_date = filterData.end_date.toLocaleString('fr-CA',{year:'numeric',month:'numeric',day:'numeric'})
        
        var filter_dict = {}
        for(var index in filtersList){
            var item = filtersList[index]
            if(item.type==='textfield'){
                if(filterData[item.value]){
                    filter_dict[item.value] = filterData[item.value]
                }
            }
            if(item.type==='select' && !item.isSingle){
                var value = ''
                for(var xIndex in filterData[item.value]){
                    var xItem = filterData[item.value][xIndex]
                    if(parseInt(xIndex)===filterData[item.value].length-1){
                        value+= xItem.value
                    }
                    else{
                        value+= xItem.value + ','
                    }
                }
                if(value || value===0){
                    filter_dict[item.value] = value
                }
            }
            if(item.type==='select' && item.isSingle){
                if(filterData[item.value] && filterData[item.value].value){
                    filter_dict[item.value] = filterData[item.value].value
                }
            }
            
        }
        var requestPage = manual_page ? manual_page : page
        var requestLimit = manual_limit ? manual_limit : limit
        var params = {
            startDate:from_date,
            endDate:end_date,
            limit:requestLimit,
            page:requestPage,
            events_only: window.location.href.includes('conversions/events') ? 1 : undefined,
            ...filter_dict
          }
        var method = 'GET'
        var data = {}
        if(e!=='export'){
            if(filter_dict.group_by){
                var url = "".concat(Constants.PROTOCOL2,Constants.HOST2,Constants.API_USER_PATH,"statistics/conversion/count")
            }
            else{
                url = "".concat(Constants.PROTOCOL,Constants.HOST2,Constants.API_USER_PATH,"statistics/conversion/view")
            }
        }
        else{
            url = "".concat(Constants.PROTOCOL,Constants.HOST2,Constants.API_USER_PATH,"statistics/conversion/export")
            method = 'POST'
            var columns_requested = []
            for(var index in tempTableHeaders){
                var item = tempTableHeaders[index]
                columns_requested.push(item.value)
            }
            columns_requested = [
                "created_at",
                "offer_id",
                "click_id",
                "affiliate_id",
                "affiliate_manager_id",
                "advertiser_id",
                "advertiser_manager_id",
                "currency",
                "goal_value",
                "payout",
                "revenue",
                "payout_status",
                "declined_reason",
                "ip",
                "user_agent",
                "sub1",
                "sub2",
                "sub3",
                "sub4",
                "sub5",
                "sub6",
                "sub7",
                "sub8"
            ]
            var data = {
                ...params,
                columns_requested,
                stats_type:'Stats-Postback'
            }
            params = {}
        }
        
        await axios({
            method,
            url,
            headers: {
                "Content-Type":"application/json",
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params,
            data
        })
        .then(resp => {
            if(e==='export'){
                Store.addNotification({
                    title: "Success!",
                    message: 'Export Request has been created, please check Export Section to download the export file.',
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true
                    }
                });
            }
            else{
                var response = resp.data.result
                setTableValues(response)
                setTotalResults(resp.data.count)
                const resSize = +resp?.headers?.['content-length']
                setResponseSize(resSize)
                if(filter_dict.group_by){
                    if(filter_dict.group_by==='goal_value'){
                        var totalRow = {'goal_value':'Total',approved_count:0,declined_count:0,total_count:0}
                    }
                    else{
                        totalRow = {'declined_reason':'Total',count:0}
                    }
                    for(var index in response){
                        if(filter_dict.group_by==='goal_value'){
                            totalRow.declined_count += response[index].declined_count
                            totalRow.approved_count += response[index].approved_count
                            totalRow.total_count += response[index].declined_count+response[index].approved_count
                        }
                        else{
                            totalRow.count+=response[index].count
                        }
                    }
                    setTotalRow(totalRow)
                }
            }
        })
        .catch((error) => {
            if (error.response) {
                console.log(error.response.data);
                if(error.response.data.result){
                }
                else{
                }
                console.log(error.response.status);
                console.log(error.response.headers);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        })

        if(filter_dict.group_by){
            setGroupByRequest(filter_dict.group_by);
            setLoader(false);
        }
        else{
            setGroupByRequest(false);
            setLoader(false);
        }
        setExportLoader(false)
        setLoader(false)
        var end_time = new Date().getTime()
        setLoadingSeconds((end_time-start_time)/1000)
    };
    const overrideSetFilterData = (value,filterItem) => {
        if(filterItem==='dateRangePicker'){
            setFilterData({...filterData,...value})
        }
        else{
            setFilterData({...filterData,[filterItem.value]:value})
        }
    }

    useEffect(() => {
        getStatsData()
        
        return () => {
        }
    }, [])
    

    const dateComponent = (valueItem,item) => {
        return new Date(valueItem[item.value]).toLocaleString('en-US',{month:'short',day:'numeric',hour:'2-digit',minute:'2-digit',second:'2-digit'})
    }
    const statusComponent = (valueItem,item) => {
        var status = {
            'declined':{
                highlightColor :'#ffcccc',
                color :'#ff0000',
                label:'Declined'
            },
            'approved':{
                highlightColor :'#d2ffcc',
                color :'#0f8500',
                label:'Approved'
            }
        }
        var highlightColor = status[valueItem[item.value]].highlightColor
        var color = status[valueItem[item.value]].color
        var label = status[valueItem[item.value]].label
        return <div style={{color,fontWeight:500,background:highlightColor,padding:'5px',width:'max-content',borderRadius:'10px',border:'1px solid'+color}}>
            {label}
        </div>
    }

    const currencyComponent = (valueItem,item) => {
        var currencyCode = valueItem.currency
        if(currencyCode){
            if(Constants.CURRENCY_LIST_SYMBOLS[currencyCode.toUpperCase()]){
                return valueItem[item.value].toFixed(2)+' '+Constants.CURRENCY_LIST_SYMBOLS[currencyCode.toUpperCase()]
            }
        }
        return valueItem[item.value]+' '+currencyCode
        
    }
    const tooltipComponent = (valueItem,item) => {
        var value = valueItem[item.value]
        if(value || value === 0){
            return <Tooltip title={value}>
                {value}
            </Tooltip>
        }
        else{
            return '-'
        }
    }

    const renameComponent = (valueItem,item) => {
        var renameDict = {
            'payment-not-found':'Non Payable Goal',
            'expiredOffer':'Offer Expired',
            'cap-reached' : 'Caps Limit Reached'
        } 
        if(renameDict[valueItem[item.value]]){
            return renameDict[valueItem[item.value]]
        }
        return valueItem[item.value]
    }
    var tempTableHeaders = [
        {show:true,label:'Date',locked:true,value:'created_at',minWidth:130,width:170,customComponent:dateComponent},
        {show:true,label:'Offer',value:'offer_id',minWidth:40,width:80},
        {show:true,label:'Click ID',value:'click_id',minWidth:90,width:170},
        {show:true,label:'Conversion Status',value:'payout_status',minWidth:80,width:110,customComponent:statusComponent},
        {show:true,label:'Declined Reason',value:'declined_reason',minWidth:80,width:110},
        {show:true,label:'Advertiser',value:'advertiser_id',minWidth:80,width:110},
        {show:true,label:'Advertiser Manager',value:'advertiser_manager_id',minWidth:80,width:110},
        {show:true,label:'Affiliate',value:'affiliate_id',minWidth:80,width:110},
        {show:true,label:'Affiliate Manager',value:'affiliate_manager_id',minWidth:80,width:110},
        {show:true,label:'Goal Value',value:'goal_value',minWidth:80,width:110},
        // {label:'Currency',value:'currency',minWidth:80,width:110},
        {show:true,label:'Payout',value:'payout',minWidth:80,width:110,customComponent:currencyComponent},
        {show:true,label:'Revenue',value:'revenue',minWidth:80,width:110,customComponent:currencyComponent},
        {show:true,label:'User Agent',value:'user_agent',textAlign:'left',minWidth:80,width:110,customComponent:tooltipComponent},
        {show:true,label:'IP',value:'ip',minWidth:80,width:110,customComponent:tooltipComponent},
        {show:true,label:'sub1',value:'sub1',minWidth:80,width:110,customComponent:tooltipComponent},
        {show:true,label:'sub2',value:'sub2',minWidth:80,width:110,customComponent:tooltipComponent},
        {show:true,label:'sub3',value:'sub3',minWidth:80,width:110,customComponent:tooltipComponent},
        {show:true,label:'sub4',value:'sub4',minWidth:80,width:110,customComponent:tooltipComponent},
        {show:true,label:'sub5',value:'sub5',minWidth:80,width:110,customComponent:tooltipComponent},
        {show:true,label:'sub6',value:'sub6',minWidth:80,width:110,customComponent:tooltipComponent},
        {show:true,label:'sub7',value:'sub7',minWidth:80,width:110,customComponent:tooltipComponent},
        {show:true,label:'sub8',value:'sub8',minWidth:80,width:110,customComponent:tooltipComponent},
        {show:true,label:'p_offer_id',value:'p_offer_id',minWidth:130,width:160,customComponent:tooltipComponent},
        {show:true,label:'p_advertiser_id',value:'p_advertiser_id',minWidth:130,width:160,customComponent:tooltipComponent},
        {show:true,label:'p_sub1',value:'p_sub1',minWidth:80,width:110,customComponent:tooltipComponent},
        {show:true,label:'p_sub2',value:'p_sub2',minWidth:80,width:110,customComponent:tooltipComponent},
        {show:true,label:'p_sub3',value:'p_sub3',minWidth:80,width:110,customComponent:tooltipComponent},
        {show:true,label:'p_sub4',value:'p_sub4',minWidth:80,width:110,customComponent:tooltipComponent},
    ]
    const [multipleActions, setMultipleActions] = useState(false)
    const [multipleActionLoader, setMultipleActionLoader] = useState(false)
    const handleMultipleActions = async() => {
        setMultipleActionLoader(true)
        var id_list = tableValuesSelected.map((item)=>{return tableValues[item].id})
        var offer_id_affiliate_id_list = tableValuesSelected.map((item)=>{return tableValues[item].offer_id+'_'+tableValues[item].affiliate_id})
        await axios({
            method: 'POST',
            url:"".concat(Constants.PROTOCOL,Constants.HOST2,Constants.API_USER_PATH,"conversions/update/new"),
            headers: {
                "Content-Type":"application/json",
                Authorization:getLocalStorageValue('adminPanelLoginData').token
              },
            data: {
                "type" : {
                        "status" : multipleActions.status ? multipleActions.status : undefined,     
                        "revenue":multipleActions.revenue ? parseFloat(multipleActions.revenue) : undefined,     
                        "payout" : multipleActions.payout ? parseFloat(multipleActions.payout)  : undefined, 
                        "value_currency":multipleActions.currency ? multipleActions.currency : undefined
                    },  
                "ids": id_list,
                "offer_id_affiliate_id_list":offer_id_affiliate_id_list
              }
        })
        .then(resp => {
            setTableValueSelected([]);
            // getStatsData(1,limit,1)
            setMultipleActions(false);
            Store.addNotification({
                title: "Success!",
                message: resp.data.result,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 2000,
                  onScreen: true
                },
              });
            })
        .catch((error) => {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                Store.addNotification({
                    title: "Error!",
                    message: error.response.data.result,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 2000,
                      onScreen: true
                    }
                  });
    
                } else if (error.request) {
                console.log(error.request);
                } else {
                console.log('Error', error.message);
                }
        })   
        setMultipleActionLoader(false)
    }
    if(groupByRequested){
            
        tempTableHeaders = [
            {label:(groupByRequested==='goal_value' ? 'Goal Value' : 'Declined Reason'),value:(groupByRequested==='goal_value' ? 'goal_value' : 'declined_reason'),customComponent:(groupByRequested==='goal_value' ? false : renameComponent)}
            ,
            ...(
                groupByRequested==='goal_value' ? 
                    [{label:'Approved Count',value:'approved_count'},
                    {label:'Declined Count',value:'declined_count'},
                    {label:'Total Count',value:'total_count'}] 
                    :
                    [{label:'Declined Count',value:'count'},
                    ]
                )
        ]
    }

    return (
        <>
            <div style={{position:'sticky',left:0,width:'100%',zIndex:100}}>
            {!loader && tableValuesSelected.length===0 && <div style={{position:'absolute',fontSize:'13px',left:5,bottom:-45}}>Showing {offset+1} - {(offset+limit < totalResults) ? offset+limit : totalResults} out of {totalResults}</div>}
                {/* {!loader && <div style={{position:'absolute',fontSize:'10px',left:5,bottom:-45}}>Data Loaded in {loadingSeconds} sec {responseSize ? '('+bytesToSize(responseSize)+')' : ''}</div>} */}
                <div className='demographyRouterWrapper'>
                    <div className='demographyFilterRow'>
                        <div className='demographyFilterAddRow'>
                            <FilterRow 
                            onEnterFunction={()=>{getStatsData(0,limit,1)}}
                            filtersList={filtersList} filterData={filterData} setFilterData={overrideSetFilterData}/>
                        </div>
                        <div>
                            <Button 
                                onClick={()=>{getStatsData(); window.history.pushState('Statistics - Postbacks', 'Statistics - Postbacks', updateUrlFilter(filtersList,filterData));}}
                                sx={{...Constants.CONTAINEDBUTTON,textTransform:'capitalize',transform:'scale(0.8)',transformOrigin:'left'}}>
                                Search
                            </Button>
                            <Button sx={{...Constants.OUTLINEDBUTTON,textTransform:'capitalize',transform:'scale(0.8)',transformOrigin:'left'}}>Reset</Button>
                        </div>
                        <div style={{marginTop:'20px',fontSize:'12px',cursor:'pointer',width:'max-content'}} onClick={()=>{localStorage.setItem('statsV2',true);window.location.reload();}}>
                            Switch To Old Design
                        </div>
                    </div>
                </div>
            </div>
            {   tableValuesSelected
                    &&
                    tableValuesSelected.length>0
                    &&
                    <div className='valueSelectedChangesWrapper'>
                        <div style={{margin:'20px'}}>
                            {tableValuesSelected.length +' rows selected.'}
                        </div>
                        <div style={{margin:'20px',cursor:'pointer',display:'flex',alignItems:'center'}}>
                            <div id='status' onClick={(e)=>{e.stopPropagation();setMultipleActions({});}} style={{cursor:'pointer',paddingLeft:'10px',paddingRight:'10px',color:'white'}}>
                                Multiple Actions
                            </div>
                        </div>
                    </div>
                }
            {loader
            &&
            <ShowLoaderComponent/>
            }
            {!loader
                &&
                typeof tableValues === 'object' && 
                tableValues.length>0 
                &&
                <TableModel
                    totalRow={groupByRequested ? [totalRow] : false}
                    showCheckbox={groupByRequested ? false : true}
                    valuesCheckbox={tableValuesSelected}
                    setValuesCheckbox={setTableValueSelected}
                    tableHeaders={tempTableHeaders}
                    topRowStyles={{background:'rgb(230,230,230)'}}
                    serialOffset={offset}
                    // setTableHeaders={setTableHeaders}
                    tableValues={tableValues}
                    setTableValues={setTableValues}
                    style={{background:'rgb(245,245,245)'}}
                    tableValueRow={'rest'}
                    setTableValueRow={()=>{console.log('test')}}
                    exportFunction={exportLoader ? 'loading' : ()=>{getStatsData('export',limit,1)}}
                    // totalRow={totalRow.length>0 ? totalRow : undefined}
                    // setTotalRow={setTotalRow}
                    // sortingFunction={sortingFunction}
                    // exportFunction={()=>{getRevenueData(null,null,null,null,null,true)}}
                    />
                }
            {!loader && totalResults/limit>1 && typeof tableValues === 'object' && tableValues.length>0 && 
                <div style={{display:'flex',alignItems:'center',justifyContent:"space-between",paddingLeft:'20px',paddingRight:'20px',paddingBottom:'20px',position:'sticky',bottom:0,left:0,background:'white',zIndex:1000}}>
                    <div>
                        <label style={{paddingRight:"5px",fontSize:'14px'}}>Limit</label>
                        <select value={limit} style={{outline:'none',border:'none'}} onChange={(e)=>{getStatsData(e,e.target.value,1);setLimit(parseInt(e.target.value));setPage(1);}}>
                            {[25,50,75,100,500].map((item,index)=>{
                                return <option key={index} value={item}>{item}</option>
                            })}
                        </select>
                    </div>
                    <Pagination count={Math.ceil(totalResults/limit)} page={page} onChange={(event,value)=>{getStatsData(event,limit,value);setPage(value);setTableValueSelected([]);window.scrollTo({top: 0,left: 0,behavior: 'smooth'});}} />
                </div>
            }
            {multipleActions && <div style={{position:'fixed',top:0,left:0,zIndex:5000,height:'100vh',width:'100vw',display:'flex',alignItems:'center',justifyContent:'center'}} onClick={()=>{setMultipleActions(false);}}>
                <div style={{background:'white',border:'1px solid rgb(220,220,220)',height:'60vh',width:'60vw',display:'flex',alignItems:'center'}} onClick={(e)=>{e.stopPropagation()}}>
                    <img src={changeConversionImage} alt='' width='40%'/>
                    <div style={{borderLeft:'1px solid rgb(220,220,220)',display:'flex',flexDirection:'column',paddingLeft:'30px',paddingRight:'30px',width:'100%'}}>
                        <div style={{display:'flex',justifyContent:"space-between",width:'100%'}}>

                            <label style={{fontSize:'20px',fontWeight:'500',marginBottom:'30px'}}>
                                Change Postback.
                            </label>
                            <div style={{fontSize:'13px',cursor:'pointer'}} onClick={()=>{setMultipleActions(false)}}>
                                X
                            </div>
                        </div>
                        <div style={{width:'60%',marginBottom:'10px'}}>
                            <label style={{fontSize:'12px',fontWeight:'500'}}>Status</label>
                            <div style={{fontSize:'13px'}}>
                                <ReactSelect 
                                    options={[{label:'Approve',value:'approved'},{label:'Decline',value:'declined'}]}
                                    defaultValue={multipleActions.status}
                                    onChange={(e)=>{setMultipleActions({...multipleActions,status:e.value});}}
                                    menuPortalTarget={document.body} 
                                    styles={{...Constants.ReactSelectStyles}}/>
                            </div>
                        </div>
                        {/* <div style={{width:'60%',marginBottom:'10px'}}>
                            <label style={{fontSize:'12px',fontWeight:'500'}}>Currency</label>
                            <div style={{fontSize:'13px',maxHeight:'30vh'}}>
                                <ReactSelect 
                                    options={Object.keys(Constants.CURRENCY_LIST).map((currencyItem,currencyIndex)=>{return {label:Constants.CURRENCY_LIST[currencyItem],value:currencyItem}})}
                                    defaultValue={multipleActions.currency}
                                    onChange={(e)=>{setMultipleActions({...multipleActions,currency:e.value})}}
                                    menuPortalTarget={document.body} 
                                    styles={{...Constants.ReactSelectStyles}}/>
                            </div>
                        </div> */}
                        <div style={{width:'60%',marginBottom:'10px'}}>
                            <label style={{fontSize:'12px',fontWeight:'500'}}>Revenue</label>
                            <div style={{display:'flex',alignItems:'center',width:'100%',position:'relative'}}>
                                <Constants.CssTextField placeholder='Revenue' fullWidth size='small' sx={{'& .MuiOutlinedInput-root':{fontSize:'13px'}}}
                                    onChange={(e)=>{setMultipleActions({...multipleActions,revenue:e.target.value})}}
                                />
                                <div style={{position:'absolute',right:'5%',fontSize:'12px',color:'rgb(50,50,50)'}}>USD</div>
                            </div>
                        </div>
                        <div style={{width:'60%',marginBottom:'30px'}}>
                            <label style={{fontSize:'12px',fontWeight:'500'}}>Payout</label>
                            <div style={{display:'flex',alignItems:'center',width:'100%',position:'relative'}}>
                                <Constants.CssTextField placeholder='Payout' fullWidth size='small' sx={{'& .MuiOutlinedInput-root':{fontSize:'13px'}}}
                                    onChange={(e)=>{setMultipleActions({...multipleActions,payout:e.target.value})}}
                                />
                                <div style={{position:'absolute',right:'5%',fontSize:'12px',color:'rgb(50,50,50)'}}>USD</div>
                            </div>
                        </div>
                        <Button disabled={multipleActionLoader} sx={{...Constants.CONTAINEDBUTTON,height:'35px',fontSize:'15px',width:'100px',textTransform:'none'}} onClick={handleMultipleActions}>Save</Button>
                    </div>
                </div>
                </div>}
        </>
    )
}

export default PostbacksV4