import React, { useState } from 'react'
import { bytesToSize } from '../../util/util';
import TableModel from '../../components/tableModel/tablemodel';
import Chart from "react-apexcharts";
import { IconButton } from '@mui/material';
import { ArrowBackIos, ArrowBackIosNew } from '@mui/icons-material';

const DbMeasurements = (props) => {

    const instanceSize = (process.env.REACT_APP_RDS_SIZE ? parseInt(process.env.REACT_APP_RDS_SIZE) : 1024)*1024*1024*1024
    const channelConfig = {
        'MA':{
            color:'#C2553A',
            label:'Mobiaviator'
        },
        'QM':{
            color:'#3AC299',
            label:'Quasarmobi'
        },
        'OI':{
            color:'#C2553A',
            label:'Offersinfinite'
        },
        'AH':{
            color:'#3AC299',
            label:'Adshustle'
        },
        'AF':{
            color:'#C2553A',
            label:'Appsfollowing'
        },
        'VA':{
            color:'#3AC299',
            label:'Vestaapps'
        },
        'MP':{
            color:'#C2553A',
            label:'Mobpine'
        },
        'AA':{
            color:'#3AC299',
            label:'AppAmplify'
        },
        '77':{
            color:'#3AC299',
            label:'77Ads'
        }
    }
    const channelConfigList=  {
        "MA":['MA'],
        "QM":['QM'],
        "AH":['AH','AF'],
        "MP":['MP','AA'],
    }

    const currentChannelList = channelConfigList[process.env.REACT_APP_CHANNEL_SYMBOL]
    var tableValues = props.tableValues
    var combinedData = {}
    var dateData = {}
    for(var channel in tableValues){
        var channelData = tableValues[channel].result
        if(!combinedData[channel]){
            combinedData[channel] = {}
        }
        for(var dateIndex in channelData){
            var dateItem = channelData[dateIndex]
            if(!dateData[dateItem.date]){
                dateData[dateItem.date] = {}
            }
            if(!dateData[dateItem.date][channel]){
                dateData[dateItem.date][channel] = dateItem
            }
            if(!combinedData[channel][dateItem.date]){
                combinedData[channel][dateItem.date] = dateItem
            }
        }
    }

    var combinedList = []
    var keyList = ['STATS','OFFER','LOG','USER']
    var uniqueChannels = []
    var graphData = {
        'dateList':[],
        'freeStorageList':[],
        'usedStorage':[],
    }
    for(var date in dateData){
        var dateItem = dateData[date]
        for(var channel in dateItem){
            if(!uniqueChannels.includes(channel)){
                uniqueChannels.push(channel)
            }
        }
    }

    var i = 0
    for(var date in dateData){
        var dict__ = {'TOTAL':{}}
        var dateItem = dateData[date]
        dict__["date"] = date
        var old_date = new Date(date)
        old_date.setDate(old_date.getDate() - 1);
        old_date = old_date.toLocaleDateString('en-CA')
        var date_total = 0
        for(var channelIndex in uniqueChannels){
            var channel = uniqueChannels[channelIndex]
            var channelData = dateItem[channel]
            var total = 0
            if(!channelData){
                channelData = {'overall':{}}
                channelData['overall']['LOG'] = 0
                channelData['overall']['OFFER'] = 0
                channelData['overall']['STATS'] = 0
                channelData['overall']['USER'] = 0
            }
            for(var keyIndex in keyList){
                var key = keyList[keyIndex]
                if(!dict__[key]){
                    dict__[key] = {}
                }
                dict__[key][channel+"_"+key] = channelData.overall[key]
                if(!graphData[[channel+"_"+key]]){
                    graphData[channel+"_"+key] = []
                }
                graphData[channel+"_"+key].push(channelData.overall[key])
                if(dateData[old_date] && dateData[old_date][channel] && dateData[old_date][channel].overall[key]){
                    dict__[key][channel+"_"+key+"_diff"] = (channelData.overall[key] - dateData[old_date][channel].overall[key])*100/dateData[old_date][channel].overall[key]
                }
                else{
                    dict__[key][channel+"_"+key+"_diff"] = 100
                }
                total += channelData.overall[key]
            }
            if(dateData[old_date] && dateData[old_date][channel] && dateData[old_date][channel].overall){
                var old_total = 0
                for(keyIndex in keyList){
                    var keyItem = keyList[keyIndex]
                    old_total += dateData[old_date][channel].overall[keyItem]
                }
                dict__['TOTAL'][channel+"_TOTAL"+"_diff"] = (total - old_total)*100/old_total
            }
            else{
                dict__['TOTAL'][channel+"_TOTAL"+"_diff"] = 100
            }
            
            dict__['TOTAL'][channel+"_TOTAL"] = total
            if(!graphData[channel+"_TOTAL_LIST"]){
                graphData[channel+"_TOTAL_LIST"] = []
            }
            graphData[channel+"_TOTAL_LIST"].push(total)
            date_total+=total
        }
        dict__['used_size'] = date_total
        dict__['free_size'] = instanceSize-date_total
        dict__['rds_size'] = instanceSize

        graphData.usedStorage.push(date_total)
        graphData.freeStorageList.push(instanceSize-date_total)
        graphData.dateList.push(date)
        combinedList.push(dict__)
        i+=1
    }
    for(var i in combinedList){
        i = parseInt(i)
        if(i<combinedList.length-1){
            combinedList[i]['used_size_diff'] = (combinedList[i]['used_size'] - combinedList[i+1]['used_size'])*100/combinedList[i+1]['used_size']
            combinedList[i]['free_size_diff'] = (combinedList[i]['free_size'] - combinedList[i+1]['free_size'])*100/combinedList[i+1]['free_size']
        }
    }

    for(var key in graphData){
        graphData[key].reverse()
    }

    var channelColors = uniqueChannels.map(channel=>{
        return channelConfig[channel].color
    })

    const initialGraphState = {
        options: {
            chart: {
                // id: "basic-bar",
                // type:'bar',
                stacked: true,
                events: {
                    dataPointSelection: (event, chartContext, config) => { 
                        if(config.seriesIndex<2){
                            setRedirectionState(1);
                            setGraphState(channelWiseGraphState(config.seriesIndex))
                        }
                    }
                },
                toolbar: {
                    show: false,
                }
            },
            plotOptions: {
                bar: {
                    columnWidth: '30%'
                }
            },
            colors:[...channelColors,'rgb(200,200,200)','rgb(255,0,0)'],
            dataLabels:{
                enabled:true,
                enabledOnSeries: [0,1,3],
                textAnchor:'middle',
                style: {
                    fontSize: '8px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 'bold',
                    colors:['rgb(255,255,255)','rgb(255,255,255)','rgb(255,255,255)','rgb(0,0,0)']
                },
                background:{
                    enabled:false,
                    borderWidth:0,
                },
                formatter: (value) => {
                    return bytesToSize(value)
                }
            },
            stroke:{
                width:2,
            },
            xaxis: {
                categories: graphData.dateList
            },
            yaxis: {
                forceNiceScale:true,
                // tickAmount: 6,
                max:instanceSize,
                labels:{
                    formatter: (value) => {
                        return bytesToSize(value)
                    }
                }
            }
        },
        series: [
            // {   
            //     name : 'Total Size',
            //     data : graphData.dateList.map((item)=>{
            //         return instanceSize
            //     })}
            ...uniqueChannels.map((channel)=>{
                return {
                    name: channel,
                    type:'column',
                    data: graphData[channel+"_TOTAL_LIST"]
                }
            }),
            // {   
            //     name : 'Used Size',
            //     type:'area',
            //     data : graphData.usedStorage,
            // },
            {   
                name : 'Free Size',
                type:'column',
                data : graphData.freeStorageList,
            },
            {   
                name : 'Total Storage',
                type:'line',
                data : graphData.dateList.map((item)=>{return instanceSize}),
            }
        ]
    };

    const [graphState, setGraphState] = useState(initialGraphState)
    const [redirectionState, setRedirectionState] = useState(0)
    const [selectedChannelData, setSelectedChannelData] = useState(null)

    var channelWiseGraphState = (channelIndex) => {
        var channel = uniqueChannels[channelIndex]
        setSelectedChannelData(channel)
        var series = [
            // {
            //     name:channel+"_TOTAL",
            //     data:graphData[channel+"_TOTAL_LIST"]
            // }
            // ,
            ...keyList.map((key)=>{
                return {
                    name: channel+"_"+key,
                    data: graphData[channel+"_"+key],
                    type:'column'
                }
            })
        ]
        return {
            options: {
                chart: {
                    id: "basic-bar",
                    type:'bar',
                    stacked: true,
                    events: {
                        dataPointSelection: (event, chartContext, config) => { 
                            setRedirectionState(2);
                            console.log(config.seriesIndex)
                        }
                    }
                },
                plotOptions: {
                    bar: {
                        columnWidth: '20%'
                    }
                },
                colors:['#1A4E6A','#5B63A2','#BF67A8','#EC7176'],
                dataLabels:{
                    style: {
                        fontSize: '8px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 'bold',
                    },
                    formatter: (value) => {
                        return bytesToSize(value)
                    }
                },
                xaxis: {
                    categories: graphData.dateList
                },
                yaxis: {
                    forceNiceScale:true,
                    labels:{
                        formatter: (value) => {
                            return bytesToSize(value)
                        }
                    }
                }
            },
            series
        }
    };


    const customChildComponent = (valueItem,childItem,childIndex,item) => {
        var valueInBytes = valueItem[item.value][childItem.value] ? valueItem[item.value][childItem.value].toFixed(2) : valueItem[item.value][childItem.value]
        valueInBytes = bytesToSize(valueInBytes)
        if(valueItem[item.value][childItem.value+"_diff"]){
            var increse_decrease = valueItem[item.value][childItem.value+"_diff"].toFixed(0)
            if(increse_decrease<=0){
                var color = 'green'
            }
            if(increse_decrease>0){
                var color = 'orange'
            }
            if(increse_decrease>50){
                var color = 'red'
            }
        }
        return <div style={{display:'flex',position:'relative',alignItems:'flex-end',justifyContent:'flex-start'}}>
            <div>{valueInBytes}</div>
            {increse_decrease
            &&
            <div style={{fontSize:'9px',position:'absolute',right:'-23px',width:'20px',textAlign:'left',color}}>
                {increse_decrease}%
            </div>
            }
        </div>
    }
    
    const bytesToSizeComponent = (valueItem,item,index,valueIndex) => {
        var valueInBytes = valueItem[item.value]
        valueInBytes = bytesToSize(valueInBytes)
        if(valueItem[item.value+'_diff']){
            var increse_decrease = valueItem[item.value+'_diff'].toFixed(0)
            if(item.value==='free_size'){
                if(increse_decrease<=0){
                    var color = 'orange'
                }
                if(increse_decrease>0){
                    var color = 'green'
                }
                if(increse_decrease>50){
                    var color = 'green'
                }
            }
            else{
                if(increse_decrease<=0){
                    var color = 'green'
                }
                if(increse_decrease>0){
                    var color = 'orange'
                }
                if(increse_decrease>50){
                    var color = 'red'
                }
            }
        }
        return <div style={{display:'flex',position:'relative',alignItems:'flex-end',justifyContent:'flex-start'}}>
            <div>{valueInBytes}</div>
            {increse_decrease
            &&
            <div style={{fontSize:'9px',position:'absolute',right:'8px',width:'20px',textAlign:'left',color}}>
                {increse_decrease}%
            </div>
            }
        </div>
    }
    var tableHeaders = [
        {label:'Date',value:'date',hideSorting:true,width:'80px',locked:true},
        {label:'RDS Size',value:'rds_size',hideSorting:true,width:'60px',customComponent:bytesToSizeComponent},
        {label:'Used Size',value:'used_size',hideSorting:true,width:'100px',customComponent:bytesToSizeComponent},
        {label:'Free Space',value:'free_size',hideSorting:true,width:'100px',customComponent:bytesToSizeComponent},
        {label:'TOTAL',value:'TOTAL',width:'200px',style:{background:'var(--ambient-color-60-percent-lighter)'},valueStyle:{borderLeft:'1px solid rgb(220,220,200)',borderRight:'1px solid rgb(220,220,200)'},
        child:currentChannelList.map((item)=>{
            return {label:item,value:item+'_TOTAL',hideSortingChild:true,customComponent:customChildComponent}
        })
        },
        {label:'STATS',value:'STATS',width:'200px',child:currentChannelList.map((item)=>{
            return {label:item,value:item+'_STATS',hideSortingChild:true,customComponent:customChildComponent}
        })
        },
        {label:'OFFER',value:'OFFER',width:'200px',child:currentChannelList.map((item)=>{
            return {label:item,value:item+'_OFFER',hideSortingChild:true,customComponent:customChildComponent}
        })
        },
        {label:'LOG',value:'LOG',width:'200px',child:currentChannelList.map((item)=>{
            return {label:item,value:item+'_LOG',hideSortingChild:true,customComponent:customChildComponent}
        })
        },
        {label:'USER',value:'USER',width:'200px',child:currentChannelList.map((item)=>{
            return {label:item,value:item+'_USER',hideSortingChild:true,customComponent:customChildComponent}
        })
        },
    ]
    if(props.loader){
        return <div>

        </div>
    }

    return (
        <div>
            {redirectionState === 0
            &&
            <div style={{height:'40px',marginTop:'10px',display:'flex',justifyContent:'flex-start',alignItems:'center',paddingLeft:'50px',fontWeight:'500'}}>
                RDS Instance Summary
            </div>}
            {redirectionState > 0
            &&
            <div style={{height:'40px',marginTop:'10px',display:'flex',justifyContent:'flex-start',alignItems:'center'}}>
                <IconButton onClick={()=>{setRedirectionState(0);setGraphState(initialGraphState)}}>
                    <ArrowBackIosNew/>
                </IconButton>
                <div style={{marginLeft:"10px",fontWeight:'500'}}>{selectedChannelData} Database Summary</div>
            </div>}
            <Chart
              options={graphState.options}
              series={graphState.series}
              width="100%"
              height={'300px'}
            />
            <div style={{width:'100%',position:'relative',overflow:'auto'}}>
                <TableModel
                    tableHeaders={tableHeaders}
                    tableValues={combinedList}
                />
            </div>
        </div>
    )
}

export default DbMeasurements