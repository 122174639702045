import React from 'react'
import './dbComponents.css';
import { CircularProgress, Tooltip } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { useSpring, animated } from "@react-spring/web";
import { abbreviateNumber } from '../../util/util';


export const AnimatedNumber = ({ value }) => {
    const { number } = useSpring({
        from: { number: 0 },
        to: { number: value },
        config: { duration: 500 },
    });
  
    return (
        <animated.div className="animatedValue">
            {number.interpolate((val) => abbreviateNumber(Math.floor(val)))}
        </animated.div>
    );
};
  

const DashboardCards = ({background,loader,cardHeight,cardWidth,label,value, styles, tooltip ,onClick}) => {
    return (
        <div className='dbCardContainer' onClick={onClick} style={{height:cardHeight ? cardHeight : '50px',width:cardWidth ? cardWidth : '50px',...styles}}>
            <div style={{display:'flex',alignItems:'center'}}>
                <div className='dbCardLabel'>{label}</div>

                {tooltip && 
                <Tooltip title={tooltip}>
                    <InfoOutlined style={{fontSize:'12px',color:'rgb(120,120,120)'}}/>
                </Tooltip>
                }
                {
                    loader &&
                    <CircularProgress size={'15px'} sx={{marginLeft:'8px'}}/>
                }
            </div>
            <div className='dbCardValue'>
                <AnimatedNumber value={value}/>
            </div>

        </div>
    )
}

export default DashboardCards