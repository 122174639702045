import axios from "axios";
import React, { useEffect, useState } from "react";
import '../billing/insightsManik/insightsCss.css';
import { getLocalStorageValue } from "../billing/insightsManik/util/util";
import annotationPlugin from 'chartjs-plugin-annotation';
import 'chartjs-plugin-datalabels';
import chartTrendline from 'chartjs-plugin-trendline';
import { ManagerIcon, UpArrow, DownArrow } from '../../data/icons';
import SelectComponent from '../../components/filterV2/selectComponent';
import * as Constants from '../../data/constants';
import { getManager } from '../../apis/dropdown/dropdowns';
import './managerStatsCss.css';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineController,
  BarElement,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
  Chart,
} from "chart.js";
import CustomSelect from "./CustomSelect";
ChartJS.register(
  CategoryScale,
  LinearScale,
  LineController,
  BarElement,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
);
Chart.register(annotationPlugin)
Chart.register(chartTrendline);

var myChart1 = null;
var myChart2 = null;
var myChart3 = null;
var myChart4 = null;
var myChart5 = null;
var myChart6 = null;
var managerString = "";
const AccountManagerSummary = () => {
  const token = getLocalStorageValue('adminPanelLoginData').token;
  const user_id = getLocalStorageValue('adminPanelLoginData').userdata.id;
  const username = getLocalStorageValue('adminPanelLoginData').username;
  const employee_type = getLocalStorageValue('adminPanelLoginData').userdata.employee_type;
  const company = process.env.REACT_APP_COMPANY_NAME;
  var words = company.toLowerCase().split(" ")
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1)
  }

  const companyName = words.join(' ');
  const [chartData3, setchartData3] = useState({
    date: [],
    data: [],
    color: []
  });
  const [chartData2, setchartData2] = useState({
    date: [],
    data: [],
  });
  const [chartData1, setchartData1] = useState({
    date: [],
    data: [],
  });
  const [contribution, setContribution] = useState({ "": { date: [], data: [] } });
  const [cardData, setCardData] = useState({ yesterday: "", yesterday_earning: 0, this_week: 0, week_date: "", this_month: 0, month_date: "", yes_diff: 0, week_diff: 0, month_diff: 0 })
  const [trendData3, settrendData3] = useState([]);
  const [trendData2, settrendData2] = useState([]);
  const [trendData1, settrendData1] = useState([]);
  const [maxLen, setMaxLen] = useState("");
  const [submaxLen, setSubMaxLen] = useState("");
  const [filterData, setFilterData] = useState({})
  const [subContribution, setSubContribution] = useState(false)
  const [markWeekends, setMarkWeekends] = useState(true);
  const [singleAM, setSingleAM] = useState(false);
  const [singleAMId, setSingleAMId] = useState("");
  const is_gm = employee_type == "general_manager";
  const [inCompleteData, setIncompleteData] = useState({});
  const [isModalOpen, setisModalOpen] = useState(false);
  const [clickedDate, setclickedDate] = useState("");
  const [openCardModel, setOpenCardModel] = useState('');
  const [differenceData, setDifferenceData] = useState({ 'day_wise': {}, 'week_wise': {}, 'month_wise': {} });
  const obj = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec"
  };

  const modalStyle = {
    position: "absolute",
    top: '30rem',
    left: '70rem',
    transform: "translate(-50%, -50%)",
    width: "18%",
    bgcolor: "background.paper",
    overflowY: "auto",
    boxShadow: 24,
    padding: "5px",
    borderRadius: "5px",
    border: 'none'
  };

  const modalStyle1 = {
    position: "absolute",
    top: '50%',
    left: '50%',
    transform: "translate(-50%, -50%)",
    width: "40%",
    bgcolor: "background.paper",
    overflowY: "auto",
    boxShadow: 24,
    padding: "0",
    borderRadius: "5px",
    border: 'none',
    maxHeight: '80%',
    scrollBarWidth: 'none'
  };

  const dayOptions = [
    { value: 7, label: 'Last 7 Days' },
    { value: 14, label: 'Last 14 Days' },
    { value: 30, label: 'Last 30 Days' },
    { value: 45, label: 'Last 45 Days' },
    { value: 60, label: 'Last 60 Days' }]

  const [days, setDays] = useState(30);

  const contriOptions = [
    { value: 7, label: 'Last 7 Days' },
    { value: 14, label: 'Last 14 Days' }
  ]
  const [progressMonth, setProgressMonth] = useState(new Date().getMonth() + 1)
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];
  const progressMonthOptions = [];

  for (let i = currentMonth; i >= 0; i--) {
    progressMonthOptions.push({ label: monthNames[i], value: i + 1 });
  }
  const [contriDays, setContriDays] = useState(7);
  const [channelMonthlyEarning, setChannelMonthlyEarning] = useState({})
  const [manager, setManager] = useState([]);
  const [optionsManagersList, setOptionsManagerList] = useState([])
  const [teamData, setTeamData] = useState([])
  const [hasSubTeam, setHasSubTeam] = useState(false)
  var filtersList = [
    { label: 'Account Manager', icon: ManagerIcon, paramKey: 'accountManager', value: 'manager_id', type: 'select', hideValueInLabel: true, multiple: false, options: optionsManagersList },
  ]
  for (let i = 0; i < manager.length; i++) {
    if (i === manager.length - 1) managerString += manager[i];
    else managerString += manager[i] + ",";
  }

  const [targetRevenue, setTargetRevenue] = useState(0);
  const [teamTarget, setteamTarget] = useState(0);

  const swappedMap = new Map();
  for (let i = 0; i < optionsManagersList.length; i++) {
    if (optionsManagersList[i]) {
      swappedMap.set(optionsManagersList[i].value, optionsManagersList[i].label.split(' - ')[0]);
    }
  }

  const targetMap = new Map();
  const managerMap = new Map();
  var target = 0;
  for (let i = 0; i < teamData.length; i++) {
    if (teamData[i]) {
      if (!teamData[i]["sub_team"] && !teamData[i]['is_tl'])
        target += teamData[i]["target_revenue"];
      targetMap.set((teamData[i].user_id).toString(), teamData[i].target_revenue);
      managerMap.set((teamData[i].user_id).toString(),
        teamData[i].sub_team ? [teamData[i].user_id] :
          teamData[i].managed_by ? teamData[i].managed_by : [])
      if (teamData[i].managed_by) {
        for (let item in teamData[i].managed_by)
          managerMap.set(teamData[i].managed_by[item].toString(), teamData[i].managed_by);
      }
    }
  }
  targetMap.set(user_id.toString(), target);
  console.log(targetMap)
  const getAllManagers = async () => {
    const list = await getManager()
    setOptionsManagerList(list)
    return list;

  }
  useEffect(() => {

    const allManagers = getAllManagers()
    axios.get(
      "".concat(Constants.PROTOCOL, Constants.HOST, "/api_am/get_team/"),
      {
        headers: {
          Authorization: `${token}`,
        },
        params: { user_id: user_id, is_gm: employee_type === "general_manager" }
      }
    )
      .then((response) => {
        var res = []
        var target = 0;
        var team_ids = [];
        const data = response.data.result;
        const subTeam = response.data.hasSubTeam;
        const teamTarget = response.data.team_target;
        var singleAMId = ""
        setTeamData(data)
        setHasSubTeam(subTeam)
        data.sort((a, b) => b.target_revenue == a.target_revenue ? a.user_id - b.user_id : b.target_revenue - a.target_revenue)
        for (let i = 0; i < data.length; i++) {
          if (!data[i]["sub_team"])
            target += data[i]["target_revenue"];
          if (data[i].active)
            team_ids.push(data[i]["user_id"])
          if (data[i]["managed_by"] && data[i]["managed_by"].length != 0)
            team_ids.unshift(...data[i].managed_by)
          if (is_gm && data[i].sub_team && data[i].team_members.length + 1 == data.length) {
            setSingleAM(true)
            singleAMId = data[i].user_id;
            setSingleAMId(data[i].user_id)
          }
        }
        var list = [];
        allManagers.then((resp) => {
          list = resp;
          for (let i = 0; i < list.length; i++) {
            var val = Number(list[i].value)
            if (team_ids.includes(val) && list[i].value != singleAMId) {
              res.push(list[i])
            }
          }
          res = res.sort((a, b) => team_ids.indexOf(Number(a.value)) - team_ids.indexOf(Number(b.value)))
          // if(employee_type!="general_manager"){
          setOptionsManagerList(res)
          // }
          setTargetRevenue(teamTarget)
          setteamTarget(teamTarget)
          getStatus()
          getDifference()
        })
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    getMonthlyEarning()
  }, [progressMonth])

  const overrideSetFilterData = (value, filterItem) => {
    if (filterItem.value === 'manager_id') {
      var __list = []
      if (value.value) {
        __list.push(value.value)
        if (value.value == singleAMId)
          setSingleAM(false)
      }
      // for (var index in value) {
      //   console.log(value[index],"sneha2134")
      //   var item = value[index]
      //   __list.push(item.value)
      // }
      setManager(__list)
      checkSubContribution(__list)
      var target = 0;
      for (let i = 0; i < teamData.length; i++) {
        var user = (teamData[i]["user_id"]).toString()
        if (__list.includes(user))
          target += teamData[i]["target_revenue"]
      }
      if (__list.length == 0) {
        for (let i = 0; i < teamData.length; i++) {
          if (!teamData[i]["sub_team"])
            target += teamData[i]["target_revenue"];
        }
        if (singleAMId != "")
          setSingleAM(true)
        target = teamTarget
      }
      target = targetMap.get(value.value) ? targetMap.get(value.value) : target
      setTargetRevenue(target)

    }
    setFilterData({ ...filterData, [filterItem.value]: value })
  }

  const checkManager = (manager) => {
    var list = [];
    for (var index in manager) {
      if (managerMap.get(manager[index]).length == 0)
        continue;
      else {
        list.push(...managerMap.get(manager[index]))
      }
    }
    return list;
  }

  const checkOtherManager = (manager) => {
    var list = [];
    for (var index in manager) {
      for (let i = 0; i < teamData.length; i++) {
        if (teamData[i].user_id == manager[index]) {
          if (teamData[i].other_manager || teamData[i].user_id == user_id)
            return true;
        }
      }
    }
    return false;
  }
  const isDataIncomplete = (val) => {
    const date = val;
    if (inCompleteData[date]) {
      if (manager != '') {
        for (const item in inCompleteData[date]) {
          let user = inCompleteData[date][item]['user_id'];
          if (manager.includes(user.toString()))
            return true;
        }
        return false;
      }
      else if (inCompleteData[date].length > 0)
        return true;
      else
        return false;
    }
    return false;
  }

  const getStatus = async () => {
    let data;
    const API_ENDPOINT = "".concat(Constants.PROTOCOL, Constants.HOST, "/api_am/daily_status/")
    await axios
      .get(API_ENDPOINT, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((response) => {
        data = response.data.result;
        let newCompiledData = {};
        data.forEach(({ date, users }) => {
          if (!date)
            date = new Date(new Date().setDate(new Date().getDate() - 1))
          else
            date = new Date(date)
          var date1 = date.getDate() + '-' + obj[date.getMonth() + 1]
          if (!newCompiledData[date1]) {
            newCompiledData[date1] = {};
          }
          newCompiledData[date1] = users;
        });
        setIncompleteData(newCompiledData)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getDifference = async () => {
    let data;
    const API_ENDPOINT = "".concat(Constants.PROTOCOL, Constants.HOST, "/api_am/get_difference/")
    await axios
      .get(API_ENDPOINT, {
        headers: {
          Authorization: `${token}`,
        },
        params: {
          is_gm: employee_type === "general_manager",
        }
      })
      .then((response) => {
        data = response.data;
        let newCompiledData = { 'day_wise': {}, 'month_wise': {}, 'week_wise': {} };
          if (data) {
            newCompiledData['day_wise'] = data['day_wise']
            newCompiledData['week_wise'] = data['week_wise']
            newCompiledData['month_wise'] = data['month_wise'];
          }
        setDifferenceData(newCompiledData)
      })
      .catch((err) => {
      })
  }


  const getMonthlyEarning = async () => {
    let data;
    if (is_gm) {
      const API_ENDPOINT = "".concat(Constants.PROTOCOL, Constants.HOST, "/api_am/get_monthly_value/")
      await axios
        .get(API_ENDPOINT, {
          headers: {
            Authorization: `${token}`,
          },
          params: {
            is_gm: employee_type === "general_manager",
            month: progressMonth
          }
        })
        .then((response) => {
          data = response.data;
          setChannelMonthlyEarning(data)
        })
        .catch((err) => {
          setChannelMonthlyEarning(data)
        })
    }
  }
  function clickableScales(canvas, click) {
    let resetCoordinates = canvas.getBoundingClientRect();
    const x = click.clientX - resetCoordinates.left;
    const y = click.clientY - resetCoordinates.top;

    const xScale = myChart3.scales.x;
    const tickCount = xScale.ticks.length;
    const tickWidth = xScale.width / tickCount;

    for (let i = 0; i < tickCount; i++) {
      const tickLeft = xScale.left + i * tickWidth;
      const tickRight = tickLeft + tickWidth;
      if (y >= xScale.top && y <= xScale.bottom) {
        if (x >= tickLeft && x <= tickRight) {
          const label = myChart3.data.labels[i];
          if (isDataIncomplete(label)) {
            setclickedDate(label)
            setisModalOpen(true);
            break;
          }
        }
      }
    }
  }
  function clickableScalesContri(canvas, click) {
    let resetCoordinates = canvas.getBoundingClientRect();
    const x = click.clientX - resetCoordinates.left;
    const y = click.clientY - resetCoordinates.top;

    const xScale = myChart4.scales.x;
    const tickCount = xScale.ticks.length;
    const tickWidth = xScale.width / tickCount;

    for (let i = 0; i < tickCount; i++) {
      const tickLeft = xScale.left + i * tickWidth;
      const tickRight = tickLeft + tickWidth;
      if (y >= xScale.top && y <= xScale.bottom) {
        if (x >= tickLeft && x <= tickRight) {
          const label = myChart4.data.labels[i];
          if (isDataIncomplete(label)) {
            setclickedDate(label)
            setisModalOpen(true);
            break;
          }
        }
      }
    }
  }

  const PendingModal = () => {
    const renderIncompleteStatus = () => {
      let elements = [];
      if (inCompleteData[clickedDate] && inCompleteData[clickedDate].length > 0) {
        inCompleteData[clickedDate].forEach((item) => {
          elements.push(<div key={item.username}>{item.username + ' - Pending'}</div>);
        });
      }
      return elements;
    };
    return <Modal
      open={isModalOpen}
      onClose={() => { setisModalOpen(!isModalOpen) }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={modalStyle}>
        <div>Data status</div>
        {renderIncompleteStatus()}
      </Box>
    </Modal>
  }

  useEffect(() => {
    const API_ENDPOINT =
      "".concat(Constants.PROTOCOL, Constants.HOST, "/api_am/data/");
    axios
      .get(API_ENDPOINT, {
        headers: {
          Authorization: `${token}`,
        },
        params: { user_id: user_id, manager_id: manager.toString() == user_id ? "" : manager.toString(), is_gm: employee_type === "general_manager" || manager.length == 0, days: days }
      })
      .then((response) => {
        const chartData3 = {
          date: [],
          data: [],
          color: []
        };
        const chartData2 = {
          date: [],
          data: [],
        };
        const chartData1 = {
          date: [],
          data: [],
        };
        const yes = response.data["yesterday"]
        const yesterday_earn = response.data["yesterday_earning"]
        const this_week_earn = response.data["this_week_earning"]
        const this_month_earn = response.data["this_month_earning"]
        const month_date = response.data["month"]
        const week_date = response.data["this_week"]
        const yesterday_diff = response.data["yesterday_earning"] - response.data["prev_yes_earning"]
        const week_diff = response.data["this_week_earning"] - response.data["last_week_earning"]
        const month_diff = response.data["this_month_earning"] - response.data["prev_month_earning"]


        const data3 = response.data["last_30_days"];
        for (let i = 0; i < data3.length; i++) {
          data3[i].sum = Math.round(data3[i].sum)
          chartData3.date.push(
            new Date(data3[i].date).getDate() + '-' + obj[new Date(data3[i].date).getMonth() + 1]
          )
          chartData3.data.push(
            data3[i].sum
          )
          markWeekends ?
            chartData3.color.push(
              new Date(data3[i].date).getDay() == 0 || new Date(data3[i].date).getDay() == 6 ? "#DE9B89" : "#A9C779"
            ) :
            chartData3.color.push("#A9C779")
        }
        setchartData3(chartData3);
        const movingAvgData = calculateMovingAverage(chartData3.data, 3);
        settrendData3(movingAvgData)

        const data2 = response.data["last_12_weeks"];
        for (let i = 0; i < data2.length; i++) {
          data2[i].data = Math.round(data2[i].data)
          chartData2.date.push(
            obj[data2[i].month] + "'W" + data2[i].week
          )
          chartData2.data.push(
            data2[i].data
          )
        }
        setchartData2(chartData2);
        const movingAvgData2 = calculateMovingAverage(chartData2.data, 2);
        settrendData2(movingAvgData2)
        const data1 = response.data["last_12_months"];
        for (let i = 0; i < data1.length; i++) {
          data1[i].sum = Math.round(data1[i].sum)
          chartData1.date.push(
            obj[data1[i].month] + "'" + data1[i].year.toString().substr(2)
          )
          chartData1.data.push(
            data1[i].c ?
              data1[i].sum / data1[i].c : data1[i].sum
          )
        }
        const movingAvgData3 = calculateMovingAverage(chartData1.data, 2);
        settrendData1(movingAvgData3)
        setchartData1(chartData1);
        setCardData({
          yesterday_earning: yesterday_earn,
          this_week: this_week_earn,
          this_month: this_month_earn,
          yesterday: yes,
          week_date: week_date,
          month_date: month_date,
          yes_diff: yesterday_diff, month_diff: month_diff, week_diff: week_diff
        })
      });

    if ((hasSubTeam && manager == "") || (manager != "" && !checkOtherManager(manager))) {
      const API =
        "".concat(Constants.PROTOCOL, Constants.HOST, "/api_am/contribution/");
      axios.get(API, {
        headers: {
          Authorization: `${token}`,
        },
        params: { user_id: user_id, manager_id: singleAM && is_gm ? singleAMId : "", is_gm: employee_type === "general_manager", days: contriDays }
      })
        .then((response) => {
          const contribution = {
          }
          const data = response.data;
          var max = 0;
          var maxLabel = "";
          for (const key in data) {
            if (data.hasOwnProperty(key)) {
              var user = swappedMap.get(key.toString())
              contribution[user] = { date: [], data: [] };
              const userData = data[key];
              if (userData.length > max) {
                max = userData.length;
                maxLabel = user;
              }
              const target = targetMap.get(key.toString())
              for (let i = 0; i < userData.length; i++) {
                contribution[user].date.push(
                  new Date(userData[i].date).getDate() + '-' + obj[new Date(userData[i].date).getMonth() + 1]
                )
                contribution[user].data.push(
                  userData[i].sum / target * 100
                )
              }
            }
          }
          // for(const key in contribution){
          // while(contribution[key].data.length<max)
          //     contribution[key].data.unshift(0);
          // }
          setContribution(contribution)
          setMaxLen(maxLabel)
        });
    }
    else {
      setContribution({})
    }

  }, [manager, markWeekends, optionsManagersList, days, contriDays]);

  const checkSubContribution = (manager) => {
    if (manager != "") {
      const list = checkManager(manager)
      if (list.length > 0 && !list.includes(user_id)) {
        const API =
          "".concat(Constants.PROTOCOL, Constants.HOST, "/api_am/contribution/");
        axios.get(API, {
          headers: {
            Authorization: `${token}`,
          },
          params: { user_id: user_id, manager_id: list.toString(), is_gm: employee_type === "general_manager", days: contriDays }
        })
          .then((response) => {
            const contribution = {
            }
            const data = response.data;
            var max = 0;
            var maxLabel = "";
            for (const key in data) {
              if (data.hasOwnProperty(key)) {
                var user = swappedMap.get(key.toString())
                contribution[user] = { date: [], data: [] };
                const userData = data[key];
                if (userData.length > max) {
                  max = userData.length;
                  maxLabel = user;
                }
                const target = targetMap.get(key.toString())
                for (let i = 0; i < userData.length; i++) {
                  contribution[user].date.push(
                    new Date(userData[i].date).getDate() + '-' + obj[new Date(userData[i].date).getMonth() + 1]
                  )
                  contribution[user].data.push(
                    userData[i].sum / target * 100
                  )
                }
              }
            }
            setSubContribution(contribution)
            setSubMaxLen(maxLabel)
          });
      }
      else {
        setSubContribution({})
      }
    }
    else {
      setSubContribution({})
    }
  }

  function getProgressColor(progress) {
    let color;
    if (progress < 70) {
      color = "#cc4125";
    } else if (progress < 90) {
      color = "#e6b8af";
    } else if (progress < 110) {
      color = "#d9ead3";
    } else if (progress < 120) {
      color = "#b6d7a8";
    } else {
      color = "#93c47d";
    }
    return color;
  }
  const calculateMovingAverage = (data, windowSize) => {
    let movingAvg = [];
    for (let i = 0; i < data.length - windowSize + 1; i++) {
      let sum = 0;
      for (let j = 0; j < windowSize; j++) {
        sum += data[i + j];
      }
      movingAvg.push(sum / windowSize);
    }
    return movingAvg;
  };

  useEffect(() => {
    try {
      var max3 = 0;
      for (const key in chartData3) {
        if (chartData3.hasOwnProperty(key)) {
          if (key != "date") {
            const array = chartData3[key];
            for (let i = 0; i < array.length; i++) {
              if (array[i] > max3) max3 = array[i];
            }
          }
        }
      }
      max3 = Math.max(targetRevenue, max3) + 200
      max3 = Math.round(max3/100)*100
      var max2 = 0;
      for (const key in chartData2) {
        if (chartData2.hasOwnProperty(key)) {
          if (key != "date") {
            const array = chartData2[key];
            for (let i = 0; i < array.length; i++) {
              array[i] = Number(array[i])
              if (array[i] > max2) max2 = array[i];
            }
          }
        }
      }
      max2 = Math.max(targetRevenue, max2) + 200
      max2 = Math.round(max2/100)*100
      var max1 = 0;
      for (const key in chartData1) {
        if (chartData1.hasOwnProperty(key)) {
          if (key != "date") {
            const array = chartData1[key];
            for (let i = 0; i < array.length; i++) {
              array[i] = Number(array[i])
              if (array[i] > max1) max1 = array[i];
            }
          }
        }
      }
      max1 = Math.max(targetRevenue, max1) + 200
      max1 = Math.round(max1/100)*100
      var ctx4;
      var ctx5;
      var ctx6;
      const canvas3 = document.getElementById("chart3");
      const ctx3 = canvas3.getContext("2d");
      canvas3.addEventListener('click', (e) => { clickableScales(canvas3, e) })
      const canvas2 = document.getElementById("chart2");
      const ctx2 = canvas2.getContext("2d");
      const canvas1 = document.getElementById("chart1");
      const ctx1 = canvas1.getContext("2d");
      if (is_gm) {
        const canvas6 = document.getElementById("chart6");
        ctx6 = canvas6.getContext("2d");
      }
      var contriDatasets = [];
      var subContriDatasets = [];
      var managerProgressData = { 'label': [], 'data': [], 'color': [], 'value': [], 'target': [] }
      var curr_month_days = new Date().getDate() - 1;
      if (hasSubTeam && Object.keys(contribution).length > 0) {
        const canvas4 = document.getElementById("chart4");
        ctx4 = canvas4.getContext("2d");
        canvas4.addEventListener('click', (e) => { clickableScalesContri(canvas4, e) })
      }
      if (Object.keys(subContribution).length > 0) {
        const canvas5 = document.getElementById("chart5");
        ctx5 = canvas5.getContext("2d");
      }
      var bg_colors2 = chartData2["date"].map((item, ind) => ind == chartData2["date"].length - 1 ? "#4A80BD" : "#A9C779");
      var bg_colors1 = chartData1["date"].map((item, ind) => ind == chartData1["date"].length - 1 ? "#0E6BA8" : "#A9C779");
      var bg_colors4 = ["#DCBF00", "#9E4770", "#70A0AF", "#A9C779", "#6F4778", "#69B19C", "#EB8A90", "#EAAC8B", "#C3979F", "#E07A5F"]
      let i = 0;
      for (const key in contribution) {
        contriDatasets.push(
          {
            type: "bar",
            label: key,
            data: contribution[key].data,
            backgroundColor: bg_colors4[i % 10],
            yAxisID: "B",
            borderRadius: 3,
            datalabels: { color: "#252525" },
            user_id: Array.from(swappedMap.keys()).find(key1 => swappedMap.get(key1) === key)
          })
        i++;
      }
      for (const key in subContribution) {
        subContriDatasets.push(
          {
            type: "bar",
            label: key,
            data: subContribution[key].data,
            backgroundColor: bg_colors4[i % 10],
            yAxisID: "B",
            borderRadius: 3,
            datalabels: { color: "#252525" },
            user_id: Array.from(swappedMap.keys()).find(key1 => swappedMap.get(key1) === key)
          })
        i++;
      }

      let managers_earning = channelMonthlyEarning ? channelMonthlyEarning.manager_wise : {}
      for (const key in managers_earning) {
        if (swappedMap.get(key) && key != singleAMId) {
          let earning = managers_earning[key]
          let manager_progress = earning / targetMap.get(key) * 100
          managerProgressData.label.push(swappedMap.get(key))
          managerProgressData.data.push(manager_progress)
          managerProgressData.color.push(getProgressColor(manager_progress))
          managerProgressData.value.push(earning)
          managerProgressData.target.push(targetMap.get(key))
        }
      }
      let sortedIndices1 = managerProgressData.data.map((value, index) => ({ value, index })).sort((a, b) => b.value - a.value).map(({ index }) => index);
      managerProgressData = {
        label: sortedIndices1.map(index => managerProgressData.label[index]),
        data: sortedIndices1.map(index => managerProgressData.data[index]),
        color: sortedIndices1.map(index => managerProgressData.color[index]),
        value: sortedIndices1.map(index => managerProgressData.value[index]),
        target: sortedIndices1.map(index => managerProgressData.target[index])
      };
      if (myChart1) {
        myChart1.clear();
        myChart1.destroy();
      }
      if (myChart2) {
        myChart2.clear();
        myChart2.destroy();
      }
      if (myChart3) {
        myChart3.clear();
        myChart3.destroy();
      }
      if (is_gm && myChart6) {
        myChart6.clear();
        myChart6.destroy();
      }
      if (hasSubTeam && Object.keys(contribution).length > 0) {
        if (myChart4) {
          myChart4.clear();
          myChart4.destroy();
        }
      }
      if (Object.keys(subContribution).length > 0) {
        if (myChart5) {
          myChart5.clear();
          myChart5.destroy();
        }
      }

      myChart3 = new ChartJS(ctx3, {
        type: "bar",
        data: {
          labels: chartData3.date,
          datasets: [
            {
              type: 'line',
              label: 'Trendline',
              data: [null, null, ...trendData3],
              borderColor: "#4a4a4a",
              borderWidth: 2,
              backgroundColor: "#4a4a4a",
              yAxisID: "B",
              fill: false,
              datalabels: { display: false },
              pointRadius: 0,
            },
            {
              type: "bar",
              label: "Earning Achieved",
              data: chartData3.data,
              backgroundColor: chartData3.color,
              yAxisID: "B",
              borderRadius: 2,
              datalabels: {
                color: "#252525",
                font: { size: days == 60 ? 9.8 : 12, },
              }
            },


          ],
        },
        options: {
          maintainAspectRatio: false,
          layout: {
            padding: {
              left: 20,
              right: 20,
              top: 30,
              bottom: 10,
            },
          },
          responsive: true,
          scales: {
            x: {
              grid: {
                display: false,
              },
              ticks: {
                color: "#252525",
                font: function (context) {
                  let val = context.tick.label;
                  if (isDataIncomplete(val)) {
                    return {
                      size: 12.8,
                      weight: 'bold'
                    };
                  } else {
                    return {
                      size: 12.8,
                      weight: 'normal'
                    };
                  }
                },
              },
            },
            y: {
              display: false,
            },
            B: {
              grid: {
                display: false,
              },

              min: 0,
              max: max3,
              ticks: {
                color: "#252525",
                callback: function (label, index, labels) {
                  return Math.round(label);
                  // if (label == 0) return label;
                  // else if (label <= 1000) return Math.round(label);
                  // else if (label < 100000)
                  //   return Math.round(label / 1000) + "k";
                  // else return Math.round(label / 100000) + "L";
                },
                font: {
                  // family: "kanit,sans-serif",
                  size: 12.8,
                  weight: "normal",
                },
                stepSize: Math.round(max3 / 2),
              },
            },
          },

          elements: {
            bar: {
              borderWidth: 0,
            },
          },
          plugins: {
            annotation: {
              annotations: {
                line1: {
                  id: 'targetLine',
                  type: 'line',
                  mode: 'horizontal',
                  borderColor: '#FF0000',
                  borderWidth: 2,
                  color: 'black',
                  scaleID: 'B',
                  value: targetRevenue,
                  borderDash: [7, 5],
                  label: {
                    display: false,
                    backgroundColor: "#f0f0f0",
                    drawTime: 'afterDatasetsDraw',
                    content: `Target Earning: ${targetRevenue}`,
                    borderWidth: 1,
                    borderColor: "",
                    color: "#252525",
                  },
                  enter({ element }, event) {
                    element.label.options.display = true;
                    return true;
                  },
                  leave({ element }, event) {
                    element.label.options.display = false;
                    return true;
                  }
                },
              }
            },
            tooltip: {
              backgroundColor: "#f0f0f0",
              titleColor: "#252525",
              borderWidth: 1,
              borderColor: "",
              bodyColor: "#252525",
            },
            legend: {
              display: false
            },
            datalabels: {
              anchor: 'end',
              align: 'top',
              formatter: Math.round
            },
          }
        },
      });
      myChart2 = new ChartJS(ctx2, {
        type: "bar",
        data: {
          labels: chartData2.date,
          datasets: [
            {
              type: 'line',
              label: 'Trendline',
              data: [null, ...trendData2, null],
              borderColor: "#4a4a4a",
              borderWidth: 2,
              backgroundColor: "#4a4a4a",
              yAxisID: "B",
              fill: false,
              datalabels: { display: false },
              pointRadius: 0,
            },
            {
              type: "bar",
              label: "Earnning Achieved",
              data: chartData2.data,
              backgroundColor: bg_colors2,
              yAxisID: "B",
              borderRadius: 2,
              datalabels: { color: "#252525" }
            },

          ],
        },
        options: {
          maintainAspectRatio: false,
          layout: {
            padding: {
              left: 20,
              right: 20,
              top: 30,
              bottom: 10,
            },
          },
          responsive: true,
          scales: {
            x: {
              grid: {
                display: false,
              },
              ticks: {
                color: "#252525",
                font: {
                  size: 12.8,
                  weight: "normal",
                },
              },
            },
            y: {
              display: false,
            },
            B: {
              grid: {
                display: false,
              },

              min: 0,
              max: max2,
              ticks: {
                color: "#252525",
                callback: function (label, index, labels) {
                  return Math.round(label);
                  // if (label == 0) return label;
                  // else if (label <= 1000) return Math.round(label);
                  // else if (label < 100000)
                  //   return Math.round(label / 1000) + "k";
                  // else return Math.round(label / 100000) + "L";
                },
                font: {
                  // family: "kanit,sans-serif",
                  size: 12.8,
                  weight: "normal",
                },
                stepSize: Math.round(max2 / 2),
              },
            },
          },
          elements: {
            bar: {
              borderWidth: 0,
            },
          },
          plugins:
          {
            annotation: {
              annotations: {
                line1: {
                  id: 'targetLine',
                  type: 'line',
                  mode: 'horizontal',
                  borderColor: '#FF0000',
                  borderWidth: 2,
                  color: 'black',
                  scaleID: 'B',
                  value: targetRevenue,
                  borderDash: [7, 5],
                  label: {
                    display: false,
                    backgroundColor: "#f0f0f0",
                    drawTime: 'afterDatasetsDraw',
                    content: `Target Earning: ${targetRevenue}`,
                    borderWidth: 1,
                    borderColor: "",
                    color: "#252525",
                  },
                  enter({ element }, event) {
                    element.label.options.display = true;
                    return true;
                  },
                  leave({ element }, event) {
                    element.label.options.display = false;
                    return true;
                  }
                }
              }
            },
            tooltip: {
              backgroundColor: "#f0f0f0",
              titleColor: "#252525",
              borderWidth: 1,
              borderColor: "",
              bodyColor: "#252525",
            },
            legend: {
              display: false
            },
            datalabels: {
              anchor: 'end',
              align: 'top',
              formatter: Math.round
            },
          },
        },
      });
      myChart1 = new ChartJS(ctx1, {
        type: "bar",
        data: {
          labels: chartData1.date,
          datasets: [
            {
              type: 'line',
              label: 'Trendline',
              data: [null, ...trendData1, null],
              borderColor: "#4a4a4a",
              borderWidth: 2,
              backgroundColor: "#4a4a4a",
              yAxisID: "B",
              fill: false,
              datalabels: { display: false },
              pointRadius: 0,
            },
            {
              type: "bar",
              label: "Earning Achieved",
              data: chartData1.data,
              backgroundColor: bg_colors1,
              yAxisID: "B",
              borderRadius: 2,
              datalabels: { color: "#252525" }
            },
          ],
        },
        options: {
          datalabels: {
            anchor: 'start',
            align: 'top',
            font: {
              weight: 'bold'
            }
          },
          maintainAspectRatio: false,
          layout: {
            padding: {
              left: 20,
              right: 20,
              top: 30,
              bottom: 10,
            },
          },
          responsive: true,
          scales: {
            x: {
              grid: {
                display: false,
              },
              ticks: {
                color: "#252525",
                font: {
                  // family: "kanit,sans-serif",
                  size: 12.8,
                  weight: "normal",
                },
              },
            },
            y: {
              display: false,
            },
            B: {
              grid: {
                display: false,
              },

              min: 0,
              max: max1,
              ticks: {
                color: "#252525",
                callback: function (label, index, labels) {
                  return Math.round(label);
                  // if (label == 0) return label;
                  // else if (label <= 1000) return Math.round(label);
                  // else if (label < 100000)
                  //   return Math.round(label / 1000) + "k";
                  // else return Math.round(label / 100000) + "L";
                },
                font: {
                  // family: "kanit,sans-serif",
                  size: 12.8,
                  weight: "normal",
                },
                stepSize: Math.round(max1 / 2),
              },
            },
          },
          elements: {
            bar: {
              borderWidth: 0,
            },
          },
          plugins: {
            annotation: {
              annotations: {
                line1: {
                  id: 'targetLine',
                  type: 'line',
                  mode: 'horizontal',
                  borderColor: '#FF0000',
                  borderWidth: 2,
                  color: 'black',
                  scaleID: 'B',
                  value: targetRevenue,
                  borderDash: [7, 5],
                  label: {
                    display: false,
                    backgroundColor: "#f0f0f0",
                    drawTime: 'afterDatasetsDraw',
                    content: `Target Earning: ${targetRevenue}`,
                    borderWidth: 1,
                    borderColor: "",
                    color: "#252525",
                  },
                  enter({ element }, event) {
                    element.label.options.display = true;
                    return true;
                  },
                  leave({ element }, event) {
                    element.label.options.display = false;
                    return true;
                  }
                }
              }
            },
            tooltip: {
              backgroundColor: "#f0f0f0",
              titleColor: "#252525",
              borderWidth: 1,
              borderColor: "",
              bodyColor: "#252525",
            },
            legend: {
              display: false
            },
            datalabels: {
              anchor: 'end',
              align: 'top',
              formatter: Math.round
            },
          },
        },
      });
      if (is_gm) {
        myChart6 = new ChartJS(ctx6, {
          type: "bar",
          data: {
            labels: managerProgressData.label,
            datasets: [{
              type: "bar",
              label: 'Monthly Progress',
              data: managerProgressData.data,
              backgroundColor: managerProgressData.color,
              yAxisID: "B",
              borderRadius: 3,
              datalabels: { color: "#252525" },
              maxBarThickness: 200
            }]
          },
          options: {
            maintainAspectRatio: false,
            layout: {
              padding: {
                left: 20,
                right: 20,
                top: 30,
                bottom: 10,
              },
            },
            responsive: true,
            scales: {
              x: {
                grid: {
                  display: false,
                },
                ticks: {
                  color: "#252525",
                  font: {
                    size: 12.8,
                    weight: "normal",
                  },
                },
              },
              y: {
                display: false,
              },
              B: {
                grid: {
                  display: false,
                },

                min: 0,
                ticks: {
                  color: "#252525",
                  callback: function (label, index, labels) {
                    return label + "%";
                  },
                  font: {
                    size: 12.8,
                    weight: "normal",
                  },
                  stepSize: 20,
                },
              },
            },
            elements: {
              bar: {
                borderWidth: 0,
              },
            },
            plugins:
            {
              tooltip: {
                backgroundColor: "#f0f0f0",
                titleColor: "#252525",
                borderWidth: 1,
                borderColor: "",
                bodyColor: "#252525",
                callbacks: {
                  label: function (item) {
                    let index = item.dataIndex;
                    let target = managerProgressData.target[index];
                    return `Target: ${Math.round(target)}`;
                  },
                  afterLabel: function (item) {
                    let index = item.dataIndex;
                    let actualValue = managerProgressData.value[index];
                    return `Achieved: ${Math.round(actualValue)}`;
                  }
                },
              },
              legend: {
                display: false
              },
              datalabels: {
                anchor: 'end',
                align: 'top',
                formatter: function (value, context) {
                  return Math.round(value) + '%';
                }
              },
            },
          },
        });
      }
      if (hasSubTeam && Object.keys(contribution).length > 0) {
        myChart4 = new ChartJS(ctx4, {
          type: "bar",
          data: {
            labels: contribution[maxLen] ? contribution[maxLen].date : [],
            datasets: contriDatasets,
          },
          options: {
            datalabels: {
              anchor: 'start',
              align: 'top',
              font: {
                weight: 'bold'
              }
            },
            maintainAspectRatio: false,
            layout: {
              padding: {
                left: 20,
                right: 20,
                top: 30,
                bottom: 10,
              },
            },
            responsive: true,
            scales: {
              x: {
                grid: {
                  display: false,
                },
                ticks: {
                  color: "#252525",
                  padding: 15,
                  font: function (context) {
                    let val = context.tick.label;
                    if (isDataIncomplete(val)) {
                      return {
                        size: 12.8,
                        weight: 'bold'
                      };
                    } else {
                      return {
                        size: 12.8,
                        weight: 'normal'
                      };
                    }
                  },
                },
              },
              y: {
                display: false,
              },
              B: {
                grid: {
                  display: false,
                },

                min: 0,
                ticks: {
                  color: "#252525",
                  callback: function (label, index, labels) {
                    return label + "%";
                    // if (label == 0) return label;
                    // else if (label <= 1000) return Math.round(label);
                    // else if (label < 100000)
                    //   return Math.round(label / 1000) + "k";
                    // else return Math.round(label / 100000) + "L";
                  },
                  font: {
                    // family: "kanit,sans-serif",
                    size: 12.8,
                    weight: "normal",
                  },
                  stepSize: 20,
                },
              },
            },
            elements: {
              bar: {
                borderWidth: 0,
              },
            },
            plugins: {
              tooltip: {
                backgroundColor: "#f0f0f0",
                titleColor: "#252525",
                borderWidth: 1,
                borderColor: "",
                bodyColor: "#252525",
                callbacks: {
                  label: (item) =>
                    `${item.dataset.label}: ${Math.round(item.formattedValue * Number(targetMap.get(item.dataset.user_id)) / 100)}`,
                },
              },
              legend: {
                fullSize: false,
                position: "bottom",
                align: "center",
                labels: {
                  color: "#252525",
                  font: {
                    // family: "kanit, sans-serif",
                    size: 16,
                    weight: 400,
                  },
                  useBorderRadius: true,
                  borderRadius: 5,
                },
              },
              datalabels: {
                anchor: 'end',
                align: 'top',
                formatter: function (value, context) {
                  return Math.round(value) + '%';
                }
              },
            },
          },
        });
      }
      if (Object.keys(subContribution).length > 0)
        myChart5 = new ChartJS(ctx5, {
          type: "bar",
          data: {
            labels: subContribution[submaxLen] ? subContribution[submaxLen].date : [],
            datasets: subContriDatasets,
          },
          options: {
            datalabels: {
              anchor: 'start',
              align: 'top',
              font: {
                weight: 'bold'
              }
            },
            maintainAspectRatio: false,
            layout: {
              padding: {
                left: 20,
                right: 20,
                top: 30,
                bottom: 10,
              },
            },
            responsive: true,
            scales: {
              x: {
                grid: {
                  display: false,
                },
                ticks: {
                  color: "#252525",
                  padding: 15,
                  font: {
                    // family: "kanit,sans-serif",
                    size: 12.8,
                    weight: "normal",
                  },
                },
              },
              y: {
                display: false,
              },
              B: {
                grid: {
                  display: false,
                },

                min: 0,
                ticks: {
                  color: "#252525",
                  callback: function (label, index, labels) {
                    return label + "%";
                    // if (label == 0) return label;
                    // else if (label <= 1000) return Math.round(label);
                    // else if (label < 100000)
                    //   return Math.round(label / 1000) + "k";
                    // else return Math.round(label / 100000) + "L";
                  },
                  font: {
                    // family: "kanit,sans-serif",
                    size: 12.8,
                    weight: "normal",
                  },
                  stepSize: 20,
                },
              },
            },
            elements: {
              bar: {
                borderWidth: 0,
              },
            },
            plugins: {
              tooltip: {
                backgroundColor: "#f0f0f0",
                titleColor: "#252525",
                borderWidth: 1,
                borderColor: "",
                bodyColor: "#252525",
                callbacks: {
                  label: (item) =>
                    `${item.dataset.label}: ${Math.round(item.formattedValue * Number(targetMap.get(item.dataset.user_id)) / 100)}`,
                },
              },
              legend: {
                fullSize: false,
                position: "bottom",
                align: "center",
                labels: {
                  color: "#252525",
                  font: {
                    // family: "kanit, sans-serif",
                    size: 16,
                    weight: 400,
                  },
                  useBorderRadius: true,
                  borderRadius: 5,
                },
              },
              datalabels: {
                anchor: 'end',
                align: 'top',
                formatter: function (value, context) {
                  return Math.round(value) + '%';
                }
              },
            },
          },
        });
    }
    catch (error) {
      console.log(error);
    }
  }, [chartData3, chartData1, chartData2, contribution, subContribution, inCompleteData, channelMonthlyEarning]);

  const [activeSelect, setActiveSelect] = useState(false)
  useEffect(() => {
    document.addEventListener('click', e => {
      setActiveSelect(false);
    })

    return () => {
      document.removeEventListener('click', e => {
        setActiveSelect(false);
      })
    }
  }, [])

  const CardModal = () => {
    const [activeTab, setActiveTab] = useState('all');
    const data = differenceData[openCardModel];

    const renderManagers = (filter) => {
        let elements = [];
        let total = 0;
        if (data) {
         elements.push(
            <tr>
              <th>Account Manager</th>
              <th>{activeTab == 'all' ? 'Net Change' : activeTab == 'gain' ? 'Gain' : 'Drop'}</th>
            </tr>
          )
          data.forEach((item) => {
            let user = swappedMap.get(item.user_id.toString())
              if (user) {
                  const diff = Math.round(item.diff);
                  if ((filter === 'all') || (filter === 'gain' && diff > 0) || (filter === 'loss' && diff < 0)) {
                      total += diff
                      let username = user
                      const diffClass = diff > 0 ? 'gain' : 'dropData';
                      elements.push(<tr key={username} style={{ display:  'table-row' }}
                      ><td>{username}</td><td className={diffClass}>{diff}</td></tr>);
                  }
              }
          });
      }
    return { elements, total };
    }
    const { elements, total } = renderManagers(activeTab);

    return <Modal
        open={openCardModel != ''}
        onClose={() => { setOpenCardModel('') }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={modalStyle1}>
            <div>
                <div className="tabs">
                    <button onClick={() => setActiveTab('all')} className={activeTab === 'all' ? 'active' : ''}>Gain/Drop Summary</button>
                    <button onClick={() => setActiveTab('gain')} className={activeTab === 'gain' ? 'active' : ''}>Top Gains</button>
                    <button onClick={() => setActiveTab('loss')} className={activeTab === 'loss' ? 'active' : ''}>Top Drops</button>
                </div>
                <div className="tab-content">
                    <div className="total"><strong>{`Total ${activeTab == 'all' ? 'Change' : activeTab == 'gain' ? 'Gain' : 'Drop'}: ${total}`}</strong></div>
                    <table className="table1">
                        <tbody>
                            {elements}
                        </tbody>
                    </table>
                </div>
            </div>
        </Box>
    </Modal>
}

  const Bottom_Component = ({ data, width }) => {
    return (
      <div className="mt-6  mb-6 h-[140px] flex items-center justify-center "
        style={{ flexWrap: "wrap" }}>
        {data.map((item) => {
          const key = Object.keys(item)[0];
          const { type, data, date, prev, onClick } = item[key];

          return (
            <div style={{ width: "16rem", marginRight: "1.5rem", cursor: 'pointer'}}
              className={`flex-col items-center h-[100%] bg-white rounded-[10px]`}
              onClick={() => setOpenCardModel(onClick)}
            >
              <div className="flex flex-col items-center pt-6 px-4 w-full">
                <h2 className=" text-[16px]  text-[#331E38] ">
                  {type}
                </h2>
                <h3 className=" text-[12.8px]  text-[#A5A5A5] ">
                  ({date})
                </h3>
                <div className="flex items-center">
                  <h2 style={{ paddingRight: "5px" }} className=" text-[32px] text-[#331E38] ">{Math.round(data)}</h2>
                  {prev > 0 ? <UpArrow /> : <DownArrow />}
                  {prev > 0 ?
                    <h2 className=" text-[16px] text-[#0EAE00] ">{Math.round(Math.abs(prev))}</h2> :
                    <h2 className=" text-[16px] text-[#DF0000] ">{Math.round(Math.abs(prev))}</h2>}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return <div style={{ padding: "1rem 5rem" }}>
    <div style={{ justifyContent: "space-between", display: "flex" }}>
      <div className="teamName">
        {employee_type == "general_manager" || manager.length == 0 ? companyName : username} Summary
      </div>

      <div >
        <div className='v2filterRowContainer'>
          {filtersList && filtersList.map((filterItem, filterIndex) => {

            return <div style={{ borderRadius: "10px" }} className={'v2filterItemContainer' + (activeSelect === filterItem.value && filterItem.type === 'select' ? ' v2SelectComponentActive' : '')} id={'v2Filter' + filterItem.label} key={filterIndex}>
              <div className='v2FilterIcon'>
                {filterItem.icon && <filterItem.icon />}
              </div>
              {filterItem.type === 'select'
                &&
                <SelectComponent activeSelect={activeSelect} setActiveSelect={setActiveSelect} filterItem={filterItem} filterData={filterData} setFilterData={overrideSetFilterData} reorder={false} />
              }
            </div>
          })}
        </div>
      </div>

    </div>
    <div className="containerManik">
      <Bottom_Component
        width={"64"}
        data={
          [
            {
              1: {
                type: "Yesterday's Earnings",
                data: cardData.yesterday_earning,
                date: cardData.yesterday,
                prev: cardData.yes_diff,
                onClick: "day_wise"
              },
            },
            {
              2: {
                type: "Weekly Earnings",
                data: cardData.this_week,
                date: cardData.week_date,
                prev: cardData.week_diff,
                onClick: "week_wise"
              },
            },
            {
              3: {
                type: "Monthly Earnings",
                data: cardData.this_month,
                date: cardData.month_date,
                prev: cardData.month_diff,
                onClick: "month_wise"
              },
            },
          ]}
      />
          <CardModal />
    </div>
    <div className="containerManik" style={{ marginBottom: "0rem" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="title" style={{ marginBottom: "1rem", paddingLeft: "10px", display: "flex", alignItems: "center", gap: "10px" }}>
          <div> Daily Earning{" "}</div>
          <CustomSelect
            placeholder={`(Last ${days} Days)`}
            options={dayOptions}
            days={days}
            setDays={setDays} />
        </div>
        {/* <div style={{display:"flex",alignItems:"center",marginBottom:"1rem"}}>
      <input 
        checked={markWeekends}
        className={'v2SelectCheckboxIcon'} 
        style={{marginRight:'10px'}} 
        id={'mark_weekend'} 
        name={'mark_weekend'}
        type={'checkbox'}
        onClick={()=>setMarkWeekends(!markWeekends)}
    />
    Highlight Weekends
      </div> */}
      </div>
      <div className="monthly-synopsis">
        <canvas id="chart3"></canvas>
      </div>
      <PendingModal />
    </div>

    <div className="parent">
      <div className="containerManik child" style={{ width: "48%", marginBottom: "0rem" }}>
        <div className="title" style={{ marginBottom: "1rem", paddingLeft: "10px" }}>
          Weekly Earning{" "}
          <span
            style={{
              marginLeft: "0.2rem",
              marginTop: "0.5rem",
              color: "#A5A5A5",
            }}
          >
            (12 Weeks)
          </span>
        </div>
        <div style={{ aspectRatio: "1.5" }}>
          <canvas id="chart2"></canvas>
        </div>
      </div>
      <div className="containerManik child" style={{ marginLeft: "1.8rem", width: "48%", marginBottom: "0rem" }}>
        <div className="title" style={{ marginBottom: "1rem", paddingLeft: "10px" }}>
          Monthly Earning{" "}
          <span
            style={{
              marginLeft: "0.2rem",
              marginTop: "0.5rem",
              color: "#A5A5A5",
            }}
          >
            (Last 13 Months)
          </span>
        </div>
        <div style={{ aspectRatio: "1.5" }}>
          <canvas id="chart1"></canvas>
        </div>
      </div>
    </div>
    {(hasSubTeam && manager == "") || (manager != "" && !checkOtherManager(manager)) ? (
      <div className="containerManik">
        <div className="title" style={{ marginBottom: "1rem", paddingLeft: "10px", display: "flex", alignItems: "center", gap: '10px' }}>
          Contribution{" "}
          <CustomSelect
            placeholder={`(Last ${contriDays} Days)`}
            options={contriOptions}
            days={contriDays}
            setDays={setContriDays} />
        </div>
        <div className="monthly-synopsis">
          <canvas id="chart4"></canvas>
        </div>
      </div>) : (<></>)}
    {manager != "" && Object.keys(subContribution).length > 0 ? (
      <div className="containerManik">
        <div className="title" style={{ marginBottom: "1rem", paddingLeft: "10px" }}>
          Sub-Team Contribution{" "}
          <span
            style={{
              marginLeft: "0.2rem",
              marginTop: "0.5rem",
              color: "#A5A5A5",
            }}
          >
            {`(Last ${contriDays} Days)`}
          </span>
        </div>
        <div className="monthly-synopsis">
          <canvas id="chart5"></canvas>
        </div>
      </div>) : (<></>)}
    {is_gm &&
      <div className="containerManik" style={{ marginBottom: "0rem" }}>
        <div className="title" style={{ marginBottom: "1rem", paddingLeft: "10px", display: "flex", alignItems: "center", gap: '10px' }}>
          Monthly Progress{" "}
          <span
            style={{
              marginLeft: "0.2rem",
              // marginTop: "0.5rem",
              color: "#A5A5A5",
            }}
          >
            (Manager - Wise)
          </span>
          <CustomSelect
            placeholder={`(${obj[progressMonth]})`}
            options={progressMonthOptions}
            days={progressMonth}
            setDays={setProgressMonth} />
        </div>
        <div className="monthly-synopsis">
          <canvas id="chart6"></canvas>
        </div>
      </div>
    }
  </div>
}

export default AccountManagerSummary;