import React, { useState, useEffect, useCallback } from 'react';
import '../../../css/pages/v2/offer.css';
import '../../../css/pages/demandsource.css';

import SettingsIcon from '@mui/icons-material/Settings';
import TablePagination from '@mui/material/TablePagination';
import Skeleton from '@mui/material/Skeleton';

import axios from 'axios';
import * as Constants from '../../../data/constants';
import { NavLink } from 'react-router-dom';
import ArrangeColumns from '../../../components/arrangeColumns/arrangeColumns';

import Cookies from 'universal-cookie';
import { Resizable } from "re-resizable";

import Select from 'react-select';
import { increase_brightness } from '../../../components/customization/customization';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { Button, CircularProgress } from '@mui/material';
import { DemandOfferPopup } from './demandOfferPopup';
import { Store } from 'react-notifications-component';
import { getLocalStorageValue } from '../../../util/util';

function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    if(false){
        console.log(value)
    }
    return () => setValue(value => value + 1); // update the state to force render
  }

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
      color: localStorage.getItem('ambientColor'),
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: localStorage.getItem('ambientColor'),
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgb(200,200,200)',
      },
      '&:hover fieldset': {
        borderColor: localStorage.getItem('ambientColor'),
      },
      '&.Mui-focused fieldset': {
        borderColor: localStorage.getItem('ambientColor'),
      },
    },
  });
  
export const DemandOffers = (props) => {
    const updateComponent = useForceUpdate();
    const cookies = new Cookies();
        
    const [manualUseEffect, setManualUseEffect] = useState("")
    const [tableContentLoader, setTableContentLoader] = useState("")
    
    const [offersList, setOffersList] = useState([])
    const [totalOffersCount, setTotalOffersCount] = useState([])
    const [needToApplyOffersCheck, setNeedToApplyOffersCheck] = useState(false)

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    const [reloadPage, setReloadPage] = React.useState(false);
    
    var [advertisersList, setAdvertisersList] = useState([])

    const [offerIdSearched, setOfferIdSearched] = React.useState("");
    const [offerTitleSearched, setOfferTitleSearched] = React.useState("");
    const [importedStatusSelected, setImportedStatusSelected] = React.useState("");
    const [advertiserSelected, setAdvertiserSelected] = React.useState("");
    const [demandOfferPopup, setDemandOfferPopup] = React.useState(false);
    const [isPublishedFilter, setIsPublishedFilter] = React.useState(false);
    const [packageNameFilter, setPackageNameFilter] = React.useState(false);
    // const [showCustomization, setShowCustomization] = React.useState(false);
    
    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
            setDemandOfferPopup(false);
        }
      }, []);
      
    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
    
        return () => {
          document.removeEventListener("keydown", escFunction, false);
        };
    });

    var [offerFilterOptions,setOfferFilterOptions] = useState({
        "Status" : [{label:"Active",value:'active'},{label:"Paused",value:'paused'},{label:"Disabled",value:'disabled'}],
        "Privacy" : [{label:"Public",value:'public'},{label:"Private",value:'private'},{label:"Request",value:'protected'}],
        "Advertiser" : []
    })
    
    const handleChangePage = (event,newPage) => {
        setPage(newPage);
        offers_request(newPage,rowsPerPage)
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        offers_request(0,parseInt(event.target.value, 10))
      };    

    const [editColumnsPopup, setEditColumnsPopup] = useState(false);

    var [tableHeaders,setTableHeaders] = useState([
                                    {
                                        "id": "one",
                                        "show": true,
                                        "label": "Offer ID",
                                        "value": "offer_id",
                                        "width": 100,
                                        "minWidth": 100,
                                        "fixed": true
                                    },
                                    {
                                        "id": "two",
                                        "show": true,
                                        "label": "Offer Title",
                                        "value": "title",
                                        "width": 320,
                                        "minWidth": 320,
                                        "fixed": true
                                    },
                                    {
                                        "id": "three",
                                        "show": true,
                                        "label": "Payout",
                                        "value": "payout",
                                        "width": 90,
                                        "minWidth": 90,
                                        "fixed": true
                                    },
                                    {
                                        "id": "six",
                                        "show": true,
                                        "label": "Payment Model",
                                        "value": "payment_model",
                                        "width": 130,
                                        "minWidth": 130
                                    },
                                    {
                                        "id": "four",
                                        "show": true,
                                        "label": "Advertiser",
                                        "value": "advertiser",
                                        "width": 110,
                                        "minWidth": 110,
                                        "left": 20,
                                    },
                                    {
                                        "id": "five",
                                        "show": true,
                                        "label": "Fetched",
                                        "value": "fetched",
                                        "width": 85,
                                        "minWidth": 85
                                    },
                                    
                                    {
                                        "id": "fifteen",
                                        "show": true,
                                        "label": "Tracking URL",
                                        "value": "tracking_link",
                                        "width": 130,
                                        "minWidth": 130
                                    },
                                    {
                                        "id": "sixteen",
                                        "show": true,
                                        "label": "Preview URL",
                                        "value": "preview_url",
                                        "width": 110,
                                        "minWidth": 110
                                    },

                                    {
                                        "id": "seventeen",
                                        "show": true,
                                        "label": "Add Offer",
                                        "value": "add_offer",
                                        "width": 110,
                                        "minWidth": 110
                                    },
                                ])
    

    const offers_request = async(manual_page=false,manual_limit=false) => {
        setTableContentLoader(true);
        if(process.env.REACT_APP_OFFER_FETCH_API_V2==='live'){
            var url =  "".concat(Constants.PROTOCOL,Constants.HOST,"/api/admin/v2/","demandoffers/view")
        }
        else{
            url =  "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"demandoffers/view")
        }
        await axios({
            method: 'GET',
            url,
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params:{
                limit:(manual_limit ? manual_limit : rowsPerPage)+1,
                page:manual_page ? manual_page : page,
                offer_id:offerIdSearched ? offerIdSearched : null,
                advertiser_id:advertiserSelected ? advertiserSelected.value : null,
                imported_status : importedStatusSelected ? importedStatusSelected.value : null,
                offer_title : offerTitleSearched ? offerTitleSearched : null,
                published : isPublishedFilter ? isPublishedFilter.value : null,
                auto_apply_need: needToApplyOffersCheck ? needToApplyOffersCheck : undefined,
                package_name: packageNameFilter ? packageNameFilter : undefined
            }
        })
        .then(resp => {
            setTableContentLoader(false)
            setOffersList(resp.data.result)
            setTotalOffersCount(resp.data.count ? resp.data.count : resp.data.result.length)

        })
        .catch(err => {
            if(err.response){
                if(err.response.data.result){
                    setOffersList(err.response.data.result);
                    setTotalOffersCount(0)
                }
            }
            setTableContentLoader(false)
            console.error(err);
        })
    };
    
    const getAdvertisers = async() => {
        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.ADVERTISER,"/","details"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
        })
        .then(resp => {
            let advertiserlist = [{label:'All',value:null}]
            for (var item in resp.data.result){
                advertiserlist.push({label:resp.data.result[item][Object.keys(resp.data.result[item])[0]] + " - " + Object.keys(resp.data.result[item])[0],value:Object.keys(resp.data.result[item])[0]})
            }
            // sorting(advertiserlist,'label',false,true)
            var tempOfferFilterOptionsDict = offerFilterOptions
            if(!tempOfferFilterOptionsDict['Advertiser']){
                tempOfferFilterOptionsDict['Advertiser'] = []
            }
            tempOfferFilterOptionsDict['Advertiser'] = advertiserlist
            setOfferFilterOptions(tempOfferFilterOptionsDict)
            setAdvertisersList(advertiserlist)
        })
        .catch(err => {
            console.error(err);
        })
    };
    
    if(!manualUseEffect){
        setManualUseEffect(true);
        getAdvertisers();
        window.document.title = "Demand Source - Offers"
    }
    
    const addOfferToFeed = async(index) => {
        if(!offersList[index].is_published){
            await axios({
                method: 'POST',
                url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"offersfetched/migrate"),
                headers:{
                    Authorization:getLocalStorageValue('adminPanelLoginData').token
                },
                data:{
                    "action" : 'protected',
                    "offer_fetched_id" : offersList[index].id,
                }
            }
            )
            .then(function(resp){
                var tempOfferList = offersList
                tempOfferList[index].is_published = true;
                setOffersList(tempOfferList);
                updateComponent(index)
                Store.addNotification({
                    title: "Success!",
                    message: "Offer Successfully Made Request.",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 2000,
                      onScreen: true
                    },
                });
            })
            .catch(function (error) {
                if (error.response) {
                    // Request made and server responded
                    Store.addNotification({
                        title: "Error!",
                        message: typeof error.response.data.result === "string" ? error.response.data.result : "Some error occured",
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3000,
                            onScreen: true
                        }
                    }); 
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } 
                else {
                    // Something happened in setting up the request that triggered an Error
                    Store.addNotification({
                        title: "Error!",
                        message: typeof error.message === "string" ? error.message : "Some error occured",
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3000,
                            onScreen: true
                        }
                    }); 
                    console.log('Error', error.message);
                }
                
            })
        }
        else{
            Store.addNotification({
                title: "Error!",
                message: "Offer is already published.",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            }); 
        }
    }

    const closeEditColumnsPopup = (e) => {
        setEditColumnsPopup(false);
        if(reloadPage){
            offers_request(page,rowsPerPage);
            setReloadPage(false);
        }
        cookies.set('demandOfferPageColumns', JSON.stringify(tableHeaders), { path: '/' });
    }

    const [fixedColumnsWidth, setFixedColumnsWidth] = useState(510)
    const [variableColumnsWidth, setVariableColumnsWidth] = useState("calc(100% - 510)")

    const showTableHeadersV2 = () => {
        return <div style={{display:'flex',position:'sticky',minHeight:'35px',paddingTop:"5px",paddingBottom:'5px',top:(cookies.get("newSidebar")==='true' ? 0 : 60),transition:'top 0.5s linear',zIndex:12,width:'96%',marginLeft:'2%',background:'white',borderBottom:'2px solid rgb(200,200,200)',borderTop:'2px solid rgb(200,200,200)'}}>
                <div style={{display:'flex',width:fixedColumnsWidth,borderRight:'1px solid rgb(200,200,200)'}}>
                        {tableHeaders.map((tableHead,tableHeadIndex)=>{
                            if(tableHead.fixed){
                                return  <Resizable
                                            key={tableHeadIndex}
                                            id={tableHead.value}
                                            style={{borderRight:'1px solid rgb(220,220,220)',paddingLeft:'2%',cursor:'default',userSelect:'none',display:'flex',justifyContent:'center',alignItems:'center',color:'rgb(0,0,0)'}}
                                            size={{width:tableHead.width}}
                                            enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
                                            onResizeStop={(e, direction, ref, d) => {
                                                var tempEditColumnList = tableHeaders
                                                if(tableHead.minWidth < tempEditColumnList[tableHeadIndex].width + d.width){
                                                    tempEditColumnList[tableHeadIndex].width = tempEditColumnList[tableHeadIndex].width + d.width
                                                    setTableHeaders(tempEditColumnList);
                                                    setFixedColumnsWidth(parseInt(fixedColumnsWidth+d.width))
                                                    setVariableColumnsWidth('calc(100% - ' +parseInt(fixedColumnsWidth+ d.width)+ ')')
                                                    updateComponent(e);
                                                }
                                                else{
                                                    var tempfixedColumnsWidth = fixedColumnsWidth
                                                    tempfixedColumnsWidth = tempfixedColumnsWidth - tempEditColumnList[tableHeadIndex].width + tempEditColumnList[tableHeadIndex].minWidth
                                                    tempEditColumnList[tableHeadIndex].width = tempEditColumnList[tableHeadIndex].minWidth
                                                    setTableHeaders(tempEditColumnList);
                                                    setFixedColumnsWidth(parseInt(tempfixedColumnsWidth))
                                                    setVariableColumnsWidth('calc(100% - ' +parseInt(tempfixedColumnsWidth+ d.width)+ ')')
                                                    updateComponent(e);
                                                }
                                                }}
                                                >
                                            {tableHead.label}
                                    </Resizable>
                            }
                            else{
                                return null;
                            }
                        })}
                </div>
                <div id='offersV2HeadersVar' className='noscrollbar' style={{display:'flex',width:variableColumnsWidth,overflow:'auto'}} onScroll={(e)=>{document.getElementById('offersV2HeadersVar').scrollLeft = e.target.scrollLeft; for(var updateIndex in offersList){document.getElementById('offersV2Value'+updateIndex).scrollLeft = e.target.scrollLeft}}}>
                    {tableHeaders.map((tableHead,tableHeadIndex)=>{
                                if(!tableHead.fixed && tableHead.show){
                                    return <Resizable
                                                key={tableHeadIndex}
                                                id={tableHead.value}
                                                style={{borderRight:'1px solid rgb(220,220,220)',paddingLeft:'5px',paddingRight:'5px',cursor:'default',userSelect:'none',display:'flex',justifyContent:'center',alignItems:'center',color:'rgb(0,0,0)'}}
                                                size={{width:tableHead.width}}
                                                enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
                                                onResizeStop={(e, direction, ref, d) => {
                                                    var tempEditColumnList = tableHeaders
                                                    if(tableHead.minWidth < tempEditColumnList[tableHeadIndex].width + d.width){
                                                        tempEditColumnList[tableHeadIndex].width = tempEditColumnList[tableHeadIndex].width + d.width
                                                    }
                                                    else{
                                                        tempEditColumnList[tableHeadIndex].width = tempEditColumnList[tableHeadIndex].minWidth
                                                    }
                                                    setTableHeaders(tempEditColumnList);
                                                    updateComponent(e);
                                                    }}
                                                    >
                                                <div style={{display:'block',width:'100%'}}>
                                                    <div style={{display:'flex',justifyContent:'center',width:'100%'}}>{tableHead.label}</div>
                                                    {tableHead.children &&
                                                        <div style={{marginTop:'2%',marginBottom:'2%',cursor:'default',userSelect:'none',display:'flex',justifyContent:'space-between',color:'rgb(100,100,100)'}}>
                                                            {tableHead.children.map((item3,index3)=>{
                                                                return <div key={index3} style={{display:'flex',justifyContent:'center',fontSize:'10px'}}>
                                                                    {item3.label}
                                                                </div>
                                                            })}
                                                        </div>
                                                    }
                                                </div>
                                        </Resizable>
                                }
                                else{
                                    return null;
                                }
                            })}
                </div>
            </div>
    }

    const applyForOffer = async(item) => {
        await axios({
            method: 'POST',
                url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"request/offerapproval"),
                headers:{
                    Authorization:getLocalStorageValue('adminPanelLoginData').token
                },
                data:{
                    "offer_id" : item.offer_id,
                    "advertiser":item.advertiser
                }
        })
        .then(resp => {
        
            Store.addNotification({
                title: "Success!",
                message: resp.data.result,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                duration: 2000,
                onScreen: true
                },
            });
        })
        .catch(error => {
            if (error.response) {
                // Request made and server responded
                Store.addNotification({
                    title: "Error!",
                    message: typeof error.response.data.result === "string" ? error.response.data.result : "Some error occured",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true
                    }
                }); 
                console.log(error.response.status);
                console.log(error.response.headers);
            } 
        })
    }
    const showTableValuesV2 = () => {
        if(!tableContentLoader && typeof offersList === "object" && offersList.length>0){
            return offersList.map((item,index)=>{
    
                return <div className='offerV2Row' style={{display:'flex',minHeight:'30px',borderBottom:'1px solid rgb(230,230,230)',width:'96%',marginLeft:'2%',fontSize:'12px',paddingTop:'2px',paddingBottom:'2px'}}>
                            <div style={{display:'flex',width:fixedColumnsWidth,borderRight:'1px solid rgb(200,200,200)',alignItems:'center'}}>
                                {tableHeaders.map((tableHead,tableHeadIndex)=>{
                                    if(tableHead.value==='offer_id' || tableHead.value==='title' || tableHead.value==='payout'){
                                        return <div key={tableHeadIndex} style={{width:tableHead.width,wordWrap:'break-word',wordBreak:'break-all'}}>
                                                <div style={{cursor:(tableHead.value==='title' ? 'pointer' : 'default'),display:'flex',justifyContent:(tableHead.value === 'title' ? "flex-start" : 'center'),paddingLeft:(tableHead.value === 'title' ? "10px" : '0px'),paddingRight:(tableHead.value === 'title' ? "10px" : '0px'),color:'rgb(150,150,150)',alignItems:'center',wordBreak:'break-all'}}>
                                                        <div style={{textDecoration:"none",color:(tableHead.value === 'title' ? "blue" : 'black')}} onClick={()=>{if(tableHead.value==='title'){setDemandOfferPopup({id:item.id,title:item.title,offer_id:item.offer_id})}}}>
                                                            {item[tableHead.value]}
                                                        </div>
                                                </div>
                                            </div>
                                    }
                                    return null;
                                })}
                            </div>
                            <div id={'offersV2Value'+index} className='noscrollbar' style={{display:'flex',width:variableColumnsWidth,overflow:'auto',alignItems:'center'}} onScroll={(e)=>{document.getElementById('offersV2HeadersVar').scrollLeft = e.target.scrollLeft; for(var updateIndex in offersList){document.getElementById('offersV2Value'+updateIndex).scrollLeft = e.target.scrollLeft}}}>
                                {tableHeaders.map((tableHead,tableHeadIndex)=>{
                                    if(!tableHead.fixed && tableHead.show){
                                        return <div style={{minWidth:tableHead.width,width:tableHead.width,maxWidth:tableHead.width,paddingTop :'3px',paddingBottom:'3px',overflowX:'hidden',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>
                                            <div key={tableHeadIndex} style={{zIndex:0,width:'100%',fontSize:'12px',display:'flex',justifyContent:((tableHead.value==="advertiser" || tableHead.value==='categories') ? 'flex-start' : 'center'),alignItems:'center'}}>
                                                    <div style={{zIndex:0,paddingLeft:'5px',paddingRight:'5px',width:'100%',cursor:'default',userSelect:'none',display:'flex',justifyContent:((tableHead.value==="advertiser" || tableHead.value==='categories') ? 'flex-start' : 'center'),color:'rgb(150,150,150)'}}>
                                                        
                                                        {tableHead.value==="advertiser" && item.advertiser && 
                                                            <div>
                                                                {
                                                                    item.advertiser && item.advertiser.split("(")[1] && 
                                                                        <NavLink target="_blank" rel="noreferrer" to={"/advertiser/view/"+item.advertiser.split("(")[1].split(")")[0]} style={{textDecoration:"none",color:'black'}}>
                                                                            {item[tableHead.value]}
                                                                        </NavLink>
                                                                }
                                                                {
                                                                    !item.advertiser.split("(")[1] && 
                                                                    <div> {item.advertiser} </div>
                                                                }
                                                                {
                                                                    !item.advertiser && 
                                                                    <div> NA </div>
                                                                }
                                                            </div>
                                                        }
                                                        {tableHead.value === "fetched" &&
                                                            <div style={{display:'flex',justifyContent:'center',wordWrap:'break-word',wordBreak:'break-word',paddingLeft:'5px',paddingRight:'5px',color:'rgb(80,80,80)'}}>
                                                                
                                                                { 
                                                                    <div
                                                                        style={{
                                                                                padding:'5px',
                                                                                paddingLeft:"10px",
                                                                                paddingRight:"10px",
                                                                                fontSize:'10px',
                                                                                background:(item[tableHead.value] ? 'rgb(200,255,200)' : 'rgb(255,200,200)'),
                                                                                border:'2px solid ' + (item[tableHead.value] ? 'rgb(0,180,0)' : 'rgb(255,0,0)'),
                                                                                color:(item[tableHead.value] ? 'rgb(0,180,0)' : 'rgb(255,0,0)')
                                                                                }}
                                                                        >
                                                                        {item[tableHead.value] ? "Yes" : " No "}
                                                                    </div>
                                                                }
                                                            </div>
                                                        }

                                                        {
                                                            (tableHead.value === "preview_url" || tableHead.value === "tracking_link") &&
                                                            <div style={{display:'flex',justifyContent:'center',wordWrap:'break-word',wordBreak:'break-word',paddingLeft:'5px',paddingRight:'5px',color:'rgb(80,80,80)'}}>
                                                                { 
                                                                !item[tableHead.value]
                                                                &&
                                                                    <div href={item[tableHead.value]} target='_blank' rel='noreferrer'
                                                                        className="button-10"
                                                                        style={{background:item[tableHead.value] ? localStorage.getItem('ambientColor') : 'grey',cursor:item[tableHead.value] ? 'pointer' : 'not-allowed'}}
                                                                        >
                                                                            Link
                                                                    </div>
                                                                }
                                                                { 
                                                                !item[tableHead.value] && tableHead.value==='tracking_link' && process.env.REACT_APP_OFFER_FETCH_API_V2_AUTO_APPLY
                                                                &&
                                                                    <div href={item[tableHead.value]} target='_blank' rel='noreferrer'
                                                                        className="button-10"
                                                                        onClick={()=>{applyForOffer(item)}}
                                                                        style={{background:localStorage.getItem('ambientColor') ,cursor:'pointer'}}
                                                                        >
                                                                            Apply
                                                                    </div>
                                                                }
                                                                { 
                                                                item[tableHead.value]
                                                                &&
                                                                    <a href={item[tableHead.value]} target='_blank' rel='noreferrer'
                                                                        className="button-10"
                                                                        style={{background:item[tableHead.value] ? localStorage.getItem('ambientColor') : 'grey',cursor:item[tableHead.value] ? 'pointer' : 'not-allowed'}}
                                                                        >
                                                                            Link
                                                                    </a>
                                                                }
                                                            </div>
                                                        }

                                                        {(tableHead.value === "add_offer") &&
                                                            <div style={{display:'flex',justifyContent:'center',wordWrap:'break-word',wordBreak:'break-word',paddingLeft:'5px',paddingRight:'5px',color:'rgb(80,80,80)'}}>
                                                                
                                                                { 
                                                                    <div className="button-10" style={{background:item.is_published ? 'grey' : localStorage.getItem('ambientColor'),cursor:item.is_published ? 'not-allowed' : 'pointer'}}
                                                                        onClick={()=>{addOfferToFeed(index)}}
                                                                        >
                                                                            Add
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                        {!(tableHead.value === "add_offer" || tableHead.value === "preview_url" || tableHead.value === "tracking_link") && tableHead.value !== "fetched" && tableHead.value !== "advertiser" &&
                                                            <div style={{display:'flex',justifyContent:'center',wordWrap:'break-word',wordBreak:'break-word',paddingLeft:'5px',paddingRight:'5px',color:'rgb(80,80,80)'}}>
                                                                {typeof item[tableHead.value] === "object" && 
                                                                <div>
                                                                    {item[tableHead.value] ? JSON.stringify(item[tableHead.value]) : " - "}
                                                                </div>
                                                                
                                                                }
                                                                {typeof item[tableHead.value] !== "object" && 
                                                                    <div>
                                                                        {item[tableHead.value] ? item[tableHead.value] : " - "}
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                </div> 
                                            </div>
                                    }
                                    return null;
                                })}
                            </div>
                        </div>
            })
        }
        else if(tableContentLoader){
            return [1,2,3,4,5,6,7,8,9,10,11,12].map((item,index)=>{
                return <div className='offerV2Row' style={{display:'flex',paddingTop:"5px",paddingBottom:'5px',width:'96%',marginLeft:'2%',fontSize:'12px'}}>
                            <div style={{display:'flex',width:fixedColumnsWidth,borderRight:'1px solid rgb(200,200,200)',alignItems:'center'}}>
                                {tableHeaders.map((tableHead,tableHeadIndex)=>{
                                    if(tableHead.value==="checkbox"){
                                        return <div key={tableHeadIndex} style={{width:tableHead.width,display:'flex',justifyContent:'center',alignItems:'center',wordWrap:'break-word',wordBreak:'break-all'}}>
                                                    <Skeleton variant="circular" animation="wave" width={10} height={10} style={{zIndex:4}} />
                                                </div>
                                    }
                                    else if(tableHead.fixed){
                                        return <div key={tableHeadIndex} style={{width:tableHead.width,display:'flex',justifyContent:'center',alignItems:'center',wordWrap:'break-word',wordBreak:'break-all'}}>
                                                    <div style={{cursor:'default',userSelect:'none',display:'flex',justifyContent:'center',color:'rgb(150,150,150)'}}>
                                                        <Skeleton animation="wave"  width={parseInt(tableHead.width - 10)} style={{zIndex:4}} />
                                                    </div>
                                                </div>
                                    }
                                    return null;
                                })}
                            </div>
                            <div id={'offersV2LoaderValue'+index} className='noscrollbar' style={{display:'flex',width:variableColumnsWidth,overflow:'hidden',alignItems:'center'}}>
                                {tableHeaders.map((tableHead,tableHeadIndex)=>{
                                    if(!tableHead.fixed && tableHead.show){
                                        return <div style={{minWidth:tableHead.width,paddingTop :'3px',paddingBottom:'3px',overflowX:'hidden'}}>
                                            <div key={tableHeadIndex} style={{zIndex:0,width:'100%',fontSize:'12px',display:'flex',justifyContent:(tableHead.value==="advertiser" ? 'center' : 'center'),alignItems:'center'}}>
                                                    <div style={{zIndex:0,paddingLeft:'5px',paddingRight:'5px',width:'100%',cursor:'default',userSelect:'none',display:'flex',justifyContent:'center',color:'rgb(150,150,150)'}}>
                                                        <Skeleton animation="wave" width={40} style={{zIndex:0}} />
                                                    </div>
                                                </div> 
                                            </div>
                                    }
                                    return null;
                                })}
                            </div>
                        </div>
            })
        }
        else if(!tableContentLoader && typeof offersList === "object" && offersList.length===0){
            return <div style={{display:'flex',justifyContent:'center',marginTop:'2%',fontSize:'12px',color:'rgb(100,100,100)'}}>No Offers Found.</div>
        }
        else if(!tableContentLoader && typeof offersList === "string"){
            return <div style={{display:'flex',justifyContent:'center',marginTop:'2%',fontSize:'12px',color:'rgb(100,100,100)'}}>{offersList}</div>
        }
    }
    
    return (
        <div className='mainContainerV2' id="outside">
            {editColumnsPopup && <ArrangeColumns handleClose={closeEditColumnsPopup} editColumnsList={tableHeaders} setEditColumnsList={setTableHeaders} setReloadPage={setReloadPage}/>}
            {
                demandOfferPopup && <DemandOfferPopup offer={demandOfferPopup} closePopup={()=>{setDemandOfferPopup(false)}}/>
            }
            {<div className='mainContainerTopbar'>
                <div className="mainContainerTopbarTab" style={{fontSize:'12px'}}>
                    Found {totalOffersCount} Offers
                </div>
                <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center',width:'80%'}}>
                        
                    <div className="mainContainerTopbarIcons">
                        <SettingsIcon size='small' className='mainContainerIcon' onClick={()=>{setEditColumnsPopup(true);}}/>
                    </div>
    
                </div>
            </div>}
            <div style={{display:"flex",marginTop:"3%",width:"100%",flexWrap:'wrap'}}>
                    <div style={{width:"17%",marginLeft:"2%"}}>
                        <div style={{fontSize:"12px",fontWeight:"700",color:"grey",paddingBottom:"2px"}}>Offer Id</div>
                        <CssTextField 
                            size='small' 
                            fullWidth 
                            onChange={(e)=>{setOfferIdSearched(e.target.value);}} 
                            onKeyDown={(e)=>{if(e.key==="Enter" && !tableContentLoader){offers_request(0,rowsPerPage,tableHeaders)}}} style={{transform:'scale(0.82) translateX(-12%)'}}/>
                    </div>
                    <div style={{width:"22%",marginLeft:"2%"}}>
                        <div style={{fontSize:"12px",fontWeight:"700",color:"grey",paddingBottom:"2px"}}>Offer Title</div>
                        <CssTextField 
                            size='small' 
                            fullWidth 
                            onChange={(e)=>{setOfferTitleSearched(e.target.value);}} 
                            onKeyDown={(e)=>{if(e.key==="Enter" && !tableContentLoader){offers_request(0,rowsPerPage,tableHeaders)}}} 
                            style={{transform:'scale(0.82) translateX(-12%)'}}/>
                    </div>
                    {
                        process.env.REACT_APP_OFFER_FETCH_API_V2_AUTO_APPLY
                        &&
                        <div style={{width:"22%",marginLeft:"2%"}}>
                            <div style={{fontSize:"12px",fontWeight:"700",color:"grey",paddingBottom:"2px"}}>Package Name</div>
                            <CssTextField 
                                size='small' 
                                fullWidth 
                                onChange={(e)=>{setPackageNameFilter(e.target.value);}} 
                                onKeyDown={(e)=>{if(e.key==="Enter" && !tableContentLoader){offers_request(0,rowsPerPage,tableHeaders)}}} 
                                style={{transform:'scale(0.82) translateX(-12%)'}}/>
                        </div>
                    }

                    <div style={{width:"17%",marginLeft:"2%"}}>
                        <div style={{fontSize:"12px",fontWeight:"700",color:"grey",paddingBottom:"2px"}}>Imported Status</div>
                        <Select 
                            options={[{label:'None',value:null},{label:'Yes',value:1},{label:'No',value:0}]}
                            value={importedStatusSelected}
                            onChange={(e)=>{setImportedStatusSelected(e)}}
                            menuPortalTarget={document.body} 
                            styles={{ 
                                menuPortal: base => ({ ...base, zIndex: 9999 }),
                                control: (base,state) => ({
                                    ...base,
                                    // maxHeight: 100,
                                    minHeight: 30,
                                    transform:'scale(0.9) translateX(-6.5%)',
                                    overflowY: "auto",
                                    border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid rgb(200,200,200)',
                                    boxShadow: 0,
                                    '&:hover': {
                                        border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                        boxShadow: 0,
                                    }
                                }),
                                option: (styles, {isFocused, isSelected}) => ({
                                    ...styles,
                                    fontSize:'11px',
                                    background: isFocused
                                        ? localStorage.getItem('ambientColor')
                                        : isSelected
                                            ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                            : undefined,
                                    zIndex: 1
                                }),
                            }}/>
                    </div>

                    <div style={{width:"17%",marginLeft:"2%"}}>
                        <div style={{fontSize:"12px",fontWeight:"700",color:"grey",paddingBottom:"2px"}}>Is Published</div>
                        <Select 
                            options={[{label:'None',value:null},{label:'Yes',value:1},{label:'No',value:0}]}
                            value={isPublishedFilter}
                            onChange={(e)=>{setIsPublishedFilter(e)}}
                            menuPortalTarget={document.body} 
                            styles={{ 
                                menuPortal: base => ({ ...base, zIndex: 9999 }),
                                control: (base,state) => ({
                                    ...base,
                                    // maxHeight: 100,
                                    minHeight: 30,
                                    transform:'scale(0.9) translateX(-6.5%)',
                                    overflowY: "auto",
                                    border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid rgb(200,200,200)',
                                    boxShadow: 0,
                                    '&:hover': {
                                        border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                        boxShadow: 0,
                                    }
                                }),
                                option: (styles, {isFocused, isSelected}) => ({
                                    ...styles,
                                    fontSize:'11px',
                                    background: isFocused
                                        ? localStorage.getItem('ambientColor')
                                        : isSelected
                                            ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                            : undefined,
                                    zIndex: 1
                                }),
                            }}/>
                    </div>
                    <div style={{width:"17%",marginLeft:"2%"}}>
                        <div style={{fontSize:"12px",fontWeight:"700",color:"grey",paddingBottom:"2px"}}>Advertiser</div>
                        <Select 
                            options={advertisersList}
                            value={advertiserSelected}
                            onChange={(e)=>{setAdvertiserSelected(e)}}
                            isClearable
                            menuPortalTarget={document.body} 
                            styles={{ 
                                menuPortal: base => ({ ...base, zIndex: 9999 }),
                                control: (base,state) => ({
                                    ...base,
                                    // maxHeight: 100,
                                    minHeight: 30,
                                    transform:'scale(0.9) translateX(-6.5%)',
                                    overflowY: "auto",
                                    border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid rgb(200,200,200)',
                                    boxShadow: 0,
                                    '&:hover': {
                                        border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                        boxShadow: 0,
                                    }
                                }),
                                option: (styles, {isFocused, isSelected}) => ({
                                    ...styles,
                                    fontSize:'11px',
                                    background: isFocused
                                        ? localStorage.getItem('ambientColor')
                                        : isSelected
                                            ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                            : undefined,
                                    zIndex: 1
                                }),
                            }}/>
                    </div>
                </div>
                <div style={{width:"17%",marginLeft:"2%",marginTop:"1%",marginBottom:'3%'}}>
                    {
                        process.env.REACT_APP_OFFER_FETCH_API_V2_AUTO_APPLY
                        &&
                        <div style={{marginTop:'10px'}}>
                            <input onChange={(e)=>{setNeedToApplyOffersCheck(e.target.checked)}} id={'need_to_apply'} type={'checkbox'}/>
                            <label style={{fontSize:'12px'}} htmlFor='need_to_apply'>Show Need to Apply Offers</label>
                        </div>
                    }
                    <Button disabled={ tableContentLoader ? true : false} style={{marginTop:'18px',fontSize:'13px',fontWeight:500,paddingLeft:'10px',minWidth:'100px',paddingRight:'10px'}} sx={Constants.CONTAINEDBUTTON} onClick={()=>{offers_request(0,rowsPerPage,tableHeaders);}}>
                        {tableContentLoader ? <CircularProgress size={22} sx={{color:'white'}}/> : "Search"}
                    </Button>
                </div>
                {
                    typeof offersList==='object' && offersList.length!==0 &&
                        showTableHeadersV2()
                }
                {showTableValuesV2()}
                
            <div style={{display:'flex',marginTop:'0%',minHeight:'7vh'}}>
                <div style={{display:'flex',width:'50%'}}>
                </div>
                <div style={{display:'flex',width:'50%',justifyContent:'flex-end'}}>
                    { totalOffersCount>0 && totalOffersCount>rowsPerPage &&
                        <TablePagination
                        component="div"
                        count={totalOffersCount}
                        page={page}
                        rowsPerPageOptions={[25,50,75,100]}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    }
                </div>
            </div>
        </div>
    )
}

export default DemandOffers;