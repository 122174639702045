import axios from 'axios';
import React, { useEffect, useState } from 'react';
import * as Constants from './data/constants';
import AppAnalyticsLogo from './img/companies/appanalytics.png'
import MaintainanceGif from './img/maintainanceGif.gif'
import { getLocalStorageValue } from './util/util';
import { Button, CircularProgress } from '@mui/material';
const UpgradeAdvertiserLimit = () => {
    console.log(window.location.href,Constants.PROTOCOL)
    if(Constants.PROTOCOL !== window.location.protocol+'//'){
        window.location = window.location.href.replace(window.location.protocol+'//',Constants.PROTOCOL)
    }
    console.log(window.location.protocol)
    var url = new URL(window.location.href)
    var data = url.searchParams.get('data')

    var backend_url = atob(data)
    var backend_url_data = new URL(backend_url).searchParams

    const [inputText, setInputText] = useState('')
    const [response, setResponse] = useState('')
    const [loader, setLoader] = useState('')
    const getOfferRequestCounts = async() => {
        setLoader(true)
        if(backend_url.includes("http://")){
            backend_url = backend_url.replace("http://",Constants.PROTOCOL)
        }
        if(backend_url.includes("https://")){
            backend_url = backend_url.replace("https://",Constants.PROTOCOL)
        }
        await axios({
            method: 'GET',
            url: "".concat(backend_url),
            headers: {
                "Content-Type":"application/json",
            },
            params:{
                reason:inputText
            }
        })
        .then(resp => {
            setResponse(resp.data.result)
            window.open("about:blank", "_self");
            window.close();
        })
        .catch(err => {
            // props.setRequest3Done(true)
            console.error(err);
        })  
        setLoader(false)

    };
    if(response){
        return (
            <div style={{display:'flex',alignItems:'center',justifyContent:'center',padding:'20px',flexDirection:'column'}}>
                Advertiser Has been made active.
            </div>
        )
    }
    return (
        <div style={{display:'flex',alignItems:'center',justifyContent:'center',padding:'20px',flexDirection:'column'}}>
            Advertiser Name : {backend_url_data.get('adv_name') + '( ' + backend_url_data.get('adv_id')+ " )"}
            <div style={{transform:'scale(0.8)',width:'100%'}}>
                <Constants.CssTextField
                    size='small'
                    fullWidth
                    multiline
                    placeholder='Reason for upgrading limit'
                    minRows={4}
                    value={inputText}
                    onChange={(e)=>{setInputText(e.target.value);}}
                />
            </div>
            <Button
                disabled={!inputText || loader}
                sx={{...Constants.CONTAINEDBUTTON}}
                onClick={()=>{getOfferRequestCounts()}}
            >   
            {loader
            &&
            <CircularProgress size={'20px'} sx={{color:'white'}}/>
            }
            {
            !loader
            &&
            'Upgrade Limit'
            }
            </Button>
        </div>
    )
}

export default UpgradeAdvertiserLimit