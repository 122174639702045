import React,{useState} from 'react';
import axios from 'axios';

import { Resizable } from "re-resizable";
import CreatableSelect from 'react-select/creatable';
import ReactSelect from 'react-select';
import { DateRangePicker } from 'react-date-range';
import { Button, IconButton, Tooltip } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Store } from 'react-notifications-component';

import { CssTextField, ReactSelectStyles } from '../../../data/constants';
import * as Constants from '../../../data/constants';
import StatisticsRouter from '../statisticsRouter';
import '../../../css/pages/v2/statistics.css';
import { useForceUpdate,showHtml, getDateFromDateRange, sorting, capitalize, getLocalStorageValue } from '../../../util/util';
import ShowLoaderComponent from '../../../components/loader';
import NoResultsFound from '../../../components/noResultFound';
import SomeErrorOccured from '../../../components/someErrorOccured';
import TableModel from '../../../components/tableModel/tablemodel';
import { isTestingUser } from '../../../data/sidebarTabs';
// import { getAdvertisers } from '../../../apis/dropdown/dropdowns';

const FailedPostbackStatistics = () => {
    const updateComponent = useForceUpdate()
    var statsType = window.location.href.split("statistics/")[1]
    if(statsType.includes("?")){
        statsType = statsType.split("?")[0]
    }
    var fixTableHeadersMapping = {
        "failed/postback" : [
                    // {label:'',value:'checkbox',minWidth:30,width:30},
                    {label:'Postback Date',value:'postback_time',minWidth:130,width:200},
                    {label:'Click ID',value:'click',minWidth:90,width:200},
                ],
    }
    var fixTableHeaders = fixTableHeadersMapping[statsType]
    var url = new URL(window.location.href)
    
    if(url.searchParams.get('dateRange')){
        var startDate = new Date(url.searchParams.get('dateRange').split("_")[0])
        var endDate = new Date(url.searchParams.get('dateRange').split("_")[1])
    }
    const [dateRange, setDateRange] = useState(url.searchParams.get('dateRange') ? [startDate,endDate] : [new Date(new Date().getTime()-86400000*0),new Date()])

    var searchParamsList = [
        {label:'offerId',setValueList:'',},
        {label:'advertiser',setValueList:[],},
        {label:'affiliate',setValueList:[],},
        {label:'advertiserManager',setValueList:[],},
        {label:'affiliateManager',setValueList:[],},
    ]
    if(url.searchParams.get('offerId') || url.searchParams.get('advertiser') || url.searchParams.get('affiliate') || url.searchParams.get('affiliate_manager') || url.searchParams.get('advertiser_manager')){
        for(var searchParamsListIndex in searchParamsList){
            var elem = searchParamsList[searchParamsListIndex]
            if(url.searchParams.get(elem.label)){
                var selectList = []
                var queryTextList = url.searchParams.get(elem.label).split(",")
                for(var index in queryTextList){
                    if(elem.label!=='offerId'){
                        selectList.push({
                            label: queryTextList[index],
                            value: queryTextList[index].substring(queryTextList[index].lastIndexOf("-") + 1, queryTextList[index].length)
                        })
                    }
                    else{
                        selectList = queryTextList[index]
                    }
                }
                elem.setValueList = selectList
            }
        }
    }


    const [dateRangeState, setDateRangeState] = useState([{
                                                                startDate: new Date(dateRange[0]),
                                                                endDate: new Date(dateRange[1]),
                                                                key: 'selection'
                                                            }])
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [searchOfferList, setSearchOfferList] = useState(searchParamsList[0].setValueList)
    const [searchGoalId, setSearchGoalId] = useState(false)
    const [searchAdvertiserList, setSearchAdvertiserList] = useState(searchParamsList[4].setValueList)
    const [searchReason, setSearchReason] = useState(false)
    const [p_sub1, setP_sub1] = useState('')
    const [p_sub2, setP_sub2] = useState('')
    const [p_sub3, setP_sub3] = useState('')
    const [p_sub4, setP_sub4] = useState('')
    const [exportRequest, setExportRequest] = useState(false)
    const [groupBy, setGroupBy] = useState('raw')
    const [totalRow, setTotalRow] = useState('raw')
    // const [optionsAdvertiserList, setOptionsAdvertiserList] = useState([])

    const dateRangePickedMethod = (item) => {
        setDateRangeState([item.selection]);
        if (item.selection.endDate !== item.selection.startDate){
            // setShowDatePicker(false)
            var value = [item.selection.startDate,item.selection.endDate]
            if (value[0] && value[1]){
                setDateRange(value)
                setShowDatePicker(false)
                }
        }
    }

    const customSetGroupBy = (e) => {
        setGroupBy(e);
        if(e && e.value){
            getStatsDataGrouped(e.value)
        }
        else{
            getStatsData()
        }
    }
    const filtersList = [
        {label:'Date Range',value:getDateFromDateRange(dateRange),setMethod:dateRangePickedMethod,show:true},
        {label:'External Offer ID',value:searchOfferList,setMethod:setSearchOfferList,show:true},
        {label:'Advertiser ID',value:searchAdvertiserList,setMethod:setSearchAdvertiserList,show:true},
        {label:'Goal Value',value:searchGoalId,setMethod:setSearchGoalId,show:true},
        {label:'Reason',value:searchReason,setMethod:setSearchReason,options:[{label:'Invalid click_id',value:'invalid-click-id'},{label:'Blank click_id',value:'blank'},{label:'Duplicate click_id and goal',value:'duplicate-click-goal'}],show:true},
        {label:'p_sub1',type:'textfield',value:p_sub1,setMethod:setP_sub1,show:true},
        {label:'p_sub2',type:'textfield',value:p_sub2,setMethod:setP_sub2,show:true},
        {label:'p_sub3',type:'textfield',value:p_sub3,setMethod:setP_sub3,show:true},
        {label:'p_sub4',type:'textfield',value:p_sub4,setMethod:setP_sub4,show:true},
        {label:'Group By',value:groupBy,setMethod:customSetGroupBy,options:[{label:'Advertiser',value:'advertiser_id'},{label:'External Offer ID',value:'offer_id'},{label:'p_sub1',value:'p_sub1'}],show:true},
    
    ]
    
    const resetAllFields = (e) => {
        for(var index in filtersList){
            var filterItem = filtersList[index]
            if(filterItem.show){
                if(filterItem.label==='Date Range'){
                    var resetSelection = {selection : {
                                                            startDate: new Date(new Date().getTime()-86400000*6),
                                                            endDate: new Date(),
                                                            key: 'selection'
                                                        }}
                    filterItem.setMethod(resetSelection)
                }
                else{
                    filterItem.setMethod([])
                }
            }
        }
        var url = '/statistics/'+statsType
        window.history.pushState('Statistics - Failed Postbacks', 'Statistics -  Failed Postbacks', url);
        updateComponent(e);
    }

    const [fixedTableHeaders, setFixedTableHeaders] = useState(fixTableHeaders)

    var varTableHeadersConstants = [
        {label:'External Offer ID',value:'offer_id',minWidth:80,width:333},
        {label:'Advertiser ID',value:'advertiser_id',minWidth:80,width:333},
        {label:'Goal Value',value:'goal_value',minWidth:80,width:333},
        {label:'Reason',value:'reason',minWidth:130,width:333,textAlign:'left'},
        {label:'p_sub1',value:'p_sub1',type:'textfield',minWidth:110,width:333,textAlign:'left'},
        {label:'p_sub2',value:'p_sub2',type:'textfield',minWidth:110,width:333,textAlign:'left'},
        {label:'p_sub3',value:'p_sub3',type:'textfield',minWidth:110,width:333,textAlign:'left'},
        {label:'p_sub4',value:'p_sub4',type:'textfield',minWidth:110,width:333,textAlign:'left'},
    ]
    
    var varTableHeaders = varTableHeadersConstants
    const [variableTableHeaders, setVariableTableHeaders] = useState(varTableHeaders)
    
    var varTableHeadersMapping = {
        "failed/postback"  : [
                {label:'Click ID',value:'click',minWidth:90,width:333,textAlign:'left'},
                ],
    }
    const [mobileComponent, setMobileComponent] = useState(false)
    if(window.innerHeight > window.innerWidth && !mobileComponent){
        setMobileComponent(true)
        var tempVariableHeaders = variableTableHeaders
        tempVariableHeaders = [...varTableHeadersMapping[statsType],...varTableHeadersConstants]
        setVariableTableHeaders(tempVariableHeaders)
        var fixedHeadersTemp = []
        fixedHeadersTemp.push(fixedTableHeaders[0])
        setFixedTableHeaders(fixedHeadersTemp)
    }
    const [showLoader, setShowLoader] = useState(false)
    const [response, setResponse] = useState([])
    const [limit, setLimit] = useState(50);
    const [page, setPage] = useState(1);
    const [totalResults, setTotalResults] = useState(50);
    const [offset, setOffset] = useState(1);
    const [totalValue, setTotalValue] = useState({})

    const getStatsData = async(e,manual_limit,manual_page) => {
        setShowLoader(true);
        var filterData = {
        }

        for(var filterItem in filterData){
            var searchString = ""
            for(var index in filterData[filterItem].data){
                searchString += filterData[filterItem].data[index].value+","
            }
            searchString = searchString.slice(0,-1)
            if(searchString){
                filterData[filterItem].value = searchString
            }
        }

        var dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric'};
        var startDate = new Date(dateRange[0]).toLocaleDateString("sv-SE", dateOptions)
        var endDate = new Date(dateRange[1]).toLocaleDateString("sv-SE", dateOptions)
        var requestPage = manual_page ? manual_page : page
        var requestLimit = manual_limit ? manual_limit : limit
        setPage(requestPage);
        return await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL2,Constants.HOST2,Constants.API_USER_PATH,"failed/postback"),
            headers: {
                "Content-Type":"application/json",
                Authorization:getLocalStorageValue('adminPanelLoginData').token
              },
            params: {
                startDate,
                endDate,
                
                //TODO : need to add this
                limit:requestLimit,
                page:requestPage,
                offer_id:searchOfferList ? searchOfferList : undefined,
                advertiser_id:searchAdvertiserList ? searchAdvertiserList : undefined,
                reason:searchReason ? searchReason.value : undefined,
                goal:searchGoalId ? searchGoalId.value : undefined,
                p_sub1:p_sub1 ? p_sub1 : undefined,
                p_sub2:p_sub2 ? p_sub2 : undefined,
                p_sub3:p_sub3 ? p_sub3 : undefined,
                p_sub4:p_sub4 ? p_sub4 : undefined,
                // order_by : manual_orderby ? manual_orderby : requestOrderBy,
                // order_field : manual_orderField ? manual_orderField : requestOrder,


                // tz : "utc",
                // currency:(manualCurrency ? manualCurrency : currency).toLowerCase(),
              }
        })
        .then(resp => {
            setShowLoader(false);
            var response = resp.data.result
            setResponse(response)
            setTotalResults(resp.data.count)
            setOffset(requestPage*requestLimit - requestLimit + 1)
        })
        .catch((error) => {
            setShowLoader(false);
            setResponse([]);
            setTotalResults(0);
            setOffset(0);
            if (error.response) {
                console.log(error.response.data);
                setResponse(error.response.data.result);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        })
    };
    
    const [tableValues, setTableValues] = useState([])

    const getStatsDataGrouped = async(group_by) => {
        setShowLoader(true);
        
        var dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric'};
        var startDate = new Date(dateRange[0]).toLocaleDateString("sv-SE", dateOptions)
        var endDate = new Date(dateRange[1]).toLocaleDateString("sv-SE", dateOptions)

        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL2,Constants.HOST2,Constants.API_USER_PATH,"failed/postback/count"),
            headers: {
                "Content-Type":"application/json",
                Authorization:getLocalStorageValue('adminPanelLoginData').token
              },
            params: {
                startDate,
                endDate,
                group_by:group_by ? group_by : groupBy.value,
                offer_id:searchOfferList ? searchOfferList : undefined,
                advertiser_id:searchAdvertiserList ? searchAdvertiserList : undefined,
                reason:searchReason ? searchReason.value : undefined,
                goal:searchGoalId ? searchGoalId.value : undefined,
                p_sub1:p_sub1 ? p_sub1 : undefined,
                p_sub2:p_sub2 ? p_sub2 : undefined,
                p_sub3:p_sub3 ? p_sub3 : undefined,
                p_sub4:p_sub4 ? p_sub4 : undefined,
              }
        })
        .then(resp => {
            setTableValues(resp.data.result)
            var total_row = {advertiser_id:'Total',offer_id:'Total',p_sub1:'Total',count:0}
            for(var index in resp.data.result){
                total_row.count += resp.data.result[index].count
            }
            setTotalRow(total_row)
            setTotalResults(resp.data.count)
        })
        .catch((error) => {
            setResponse([]);
            setTotalResults(0);
            setOffset(0);
            if (error.response) {
                console.log(error.response.data);
                setResponse(error.response.data.result);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        })
        setShowLoader(false);
    };
    
    const exportStatsData = async() => {
        setExportRequest(true)
        var dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric'};
        var startDate = new Date(dateRange[0]).toLocaleDateString("sv-SE", dateOptions)
        var endDate = new Date(dateRange[1]).toLocaleDateString("sv-SE", dateOptions)
        
        await axios({
            method: 'POST',
            url: "".concat(Constants.PROTOCOL2,Constants.HOST2,Constants.API_USER_PATH,"failed/postback/export"),
            headers: {
                "Content-Type":"application/json",
                Authorization:getLocalStorageValue('adminPanelLoginData').token
              },
            data: {
                startDate,
                endDate,
                "columns_requested" : ["id","created_at", "click", "postback_time", "reason", "goal_value", "offer_id", "advertiser_id", "p_sub1", "p_sub2", "p_sub3", "p_sub4"],
                "stats_type" : "Failed-Postback",
                offer_id:searchOfferList && searchAdvertiserList.length>0 ? searchOfferList : undefined,
                advertiser_id:searchAdvertiserList && searchAdvertiserList.length>0 ? searchAdvertiserList : undefined,
                reason:searchReason.value ? searchReason.value : undefined,
                goal:searchGoalId.value ? searchGoalId.value : undefined,
                p_sub1:p_sub1 ? p_sub1 : undefined,
                p_sub2:p_sub2 ? p_sub2 : undefined,
                p_sub3:p_sub3 ? p_sub3 : undefined,
                p_sub4:p_sub4 ? p_sub4 : undefined,
            }
        })
        .then(resp => {
            Store.addNotification({
                title: "Success!",
                message: resp.data.result,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 2000,
                  onScreen: true
                },
            });
        })
        .catch((error) => {
            if (error.response) {
                console.log(error.response.data);
                setResponse(error.response.data.result);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        })
        setExportRequest(false)
    };
    

    const [manualUseState, setManualUseState] = useState(false)
    if(!manualUseState){
        setManualUseState(true);
        document.title = 'Statistics - Failed Postbacks'
        // getStatsData();
        // getRequests(getAdvertisers(),setOptionsAdvertiserList)
    }   

    const [sortingOrder, setSortingOrder] = useState(false)
    const [sortingOrderField, setSortingOrderField] = useState('key');
    const [selectAllConversions, setSelectAllConversions] = useState(false);
    const [selectedConversions, setSelectedConversions] = useState([]);

    const setSorting = (e,value,intValue=false) => {
        var unsortedResponse = response
        if(sortingOrderField===value){
            sorting(response,value,intValue,!sortingOrder)
            setSortingOrder(!sortingOrder)
        }
        else{
            sorting(response,value,intValue,false)
            setSortingOrder(false)
            setSortingOrderField(value)
        }
        setResponse(unsortedResponse)
        updateComponent(e)
    }

    const handleSearchButton = (e) => {
        if(!groupBy || !groupBy.value){
            getStatsData(e,limit,1);
        }
        else{
            getStatsDataGrouped()
        }
        var url = '/statistics/'+statsType+"?"
        url+= (dateRange  ? "dateRange=" + getDateFromDateRange(dateRange).replaceAll('/',"-").replaceAll(" ","").replace("->","_") +"&" : "")
        var searchFieldList = [
                                // {label:'offerId',value:searchOfferList},
                            ]

        for(var searchFieldIndex in searchFieldList){
            if(searchFieldList[searchFieldIndex].value.length>0){
                url+=searchFieldList[searchFieldIndex].label+"="
                for(var index in searchFieldList[searchFieldIndex].value){
                    if(searchFieldList[searchFieldIndex].label==='offerId'){
                        url+= searchFieldList[searchFieldIndex].value[index].value
                    }
                    else{
                        url+= searchFieldList[searchFieldIndex].value[index].label.split("-")[0]+"-"+searchFieldList[searchFieldIndex].value[index].value
                    }
                    if(parseInt(index)!==searchFieldList[searchFieldIndex].value.length-1){
                        url+=","
                    }
                }
                url+="&"
            }
        }        
        url = url.slice(0,url.length-1)
        window.history.pushState('Statistics - Failed Postbacks', 'Statistics - Failed Postbacks', url);
    }

    const showValues = () => {
        var totalValues = {}
        var rows = Object.keys(response).map((keyItem,keyIndex)=>{
            var height = 40
            if(response[keyItem].payouts && response[keyItem].payouts.length>1){
                height = 40 + response[keyItem].payouts.length*10
            }
            return <div id={'variableColumns'+keyIndex} style={{display:'flex',height}} key={keyIndex}>
                    {
                        variableTableHeaders.map((tableHeaderItem,tableHeaderIndex)=>{
                            if(!tableHeaderItem.disable){    
                                var showValue = ""           
                                showValue = response[keyItem][tableHeaderItem.value]
                                if(tableHeaderItem.value==='payout' || tableHeaderItem.value==='revenue'){
                                    if(response[keyItem]['currency'].toUpperCase() && Constants.CURRENCY_LIST_SYMBOLS[response[keyItem]['currency'].toUpperCase()]){
                                        showValue +=  " "+Constants.CURRENCY_LIST_SYMBOLS[response[keyItem]['currency'].toUpperCase()]
                                    }
                                    else{
                                        showValue += " "+ response[keyItem]['currency'].toUpperCase()
                                    }
                                }
                                return <div className='statsV2TableValueData overflowText' style={{justifyContent:(tableHeaderItem.value==='declined_reason' ? 'flex-start' : 'center'),minWidth:tableHeaderItem.minWidth,width:tableHeaderItem.width}} key={tableHeaderIndex}>
                                    {tableHeaderItem.value!=='declined_reason' && tableHeaderItem.value!=='payout_status' && (( showValue || showValue===0) ? showHtml(showValue) : "-")}
                                    {(tableHeaderItem.value==='declined_reason' || tableHeaderItem.value==='payout_status') && (( showValue || showValue===0) ? capitalize(showValue) : "-")}
                                    </div>
                            }
                            return null;
                        })
                    }
                </div>
        })
        if((Object.keys(totalValue).length===0 && Object.keys(totalValues).length>0) || (totalValue.clicks && totalValue.clicks!==totalValues.clicks)){
            setTotalValue(totalValues)
        }
        return rows;
    }

    const [changeMenu, setChangeMenu] = React.useState(false)
    const [changeMenuValue, setChangeMenuValue] = React.useState("")
    if(changeMenuValue && false){
        console.log(changeMenuValue)
    }

    const getTableHeaders = () => {
        var map = {
            'advertiser_id':'Advertiser',
            'offer_id':'External Offer ID',
            'p_sub1':'psub1',
        }
        return [
            {label:map[groupBy.value],value:groupBy.value,width:'50%'},
            {label:'Count',value:'count',width:'50%'},
        ]
    }
    return (
        <div className='mainContainerV2' onClick={()=>{if(showDatePicker){setShowDatePicker(false)};if(changeMenu.value){setChangeMenu(false);setChangeMenuValue("");}}}>
            <StatisticsRouter/>
            <div className='statsV2FilterContainer'>
                {filtersList.map((filterItem,filterIndex)=>{
                    if(filterItem.show){
                        if(filterItem.label==="Date Range"){
                            return <div key={filterIndex} className='statsV2DateRangeContainer'>
                                        <div className='statsV2FilterLabel'>{filterItem.label}</div>
                                        <CssTextField size='small' value={filterItem.value} style={{transform:'scale(0.8) translateX(-13%)',width:'200px'}} onClick={()=>{setShowDatePicker(true)}}/>
                                        <div style={{position:'absolute',zIndex:200,transform:'scale(1)'}} onClick={(e)=>{e.stopPropagation()}}>
                                            {showDatePicker && <DateRangePicker
                                                onChange={item => dateRangePickedMethod(item)}
                                                showSelectionPreview={true}
                                                moveRangeOnFirstSelection={false}
                                                preventSnapRefocus={true}
                                                months={2}
                                                rangeColors={[localStorage.getItem('ambientColor')]}
                                                ranges={dateRangeState}
                                                showDateDisplay={false}
                                                scroll={{ enabled: window.innerWidth<800 ? true : false }}
                                                direction={window.innerWidth<800 ? "vertical" : "horizontal"}
                                                calendarFocus="backwards"
                                                />
                                            }
                                        </div>
                                    </div>
                        }
                        else if(filterItem.label==='Advertiser ID' || filterItem.type==='textfield' || filterItem.label==='External Offer ID'){
                            return <div key={filterIndex} className='statsV2DateRangeContainer'>
                                        <div className='statsV2FilterLabel'>{filterItem.label}</div>
                                        <CssTextField size='small' value={filterItem.value} style={{transform:'scale(0.8) translateX(-13%)',width:'200px'}} onChange={e=>{filterItem.setMethod(e.target.value);}}/>
                                    </div>
                        }
                        else if(filterItem.label==='Goal Value'){
                            return <div key={filterIndex} className='statsV2DateRangeContainer'>
                                        <div className='statsV2FilterLabel'>{filterItem.label}</div>
                                        <div style={{minWidth:'200px',transform:'scale(0.779) translateX(-14%) translateY(-1.5%)'}}>
                                            <CreatableSelect
                                                isClearable
                                                isMulti={filterItem.isMulti}
                                                value={filterItem.value}
                                                onChange={(e)=>{filterItem.setMethod(e)}}
                                                menuPortalTarget={document.body} 
                                                styles={{...ReactSelectStyles}}
                                                />
                                        </div>
                                    </div>
                        }
                        else{
                            return <div key={filterIndex} className='statsV2DateRangeContainer'>
                                        <div className='statsV2FilterLabel'>{filterItem.label}</div>
                                        <div style={{minWidth:'200px',transform:'scale(0.779) translateX(-14%) translateY(-1.5%)'}}>               
                                            <ReactSelect 
                                                isClearable
                                                options={filterItem.options}
                                                value={filterItem.value}
                                                isMulti={filterItem.isMulti}
                                                onChange={(e)=>{filterItem.setMethod(e)}}
                                                menuPortalTarget={document.body} 
                                                styles={{...ReactSelectStyles}}/>
                                        </div>
                                    </div>
                        }
                    }
                    return null;
                })}
            </div>
            {((process.env.REACT_APP_STATS_V4_ENABLED==='true' && isTestingUser()) || process.env.REACT_APP_STATS_V4_ENABLED==='live') 
                &&
                <div style={{marginTop:'20px',fontSize:'12px',marginLeft:'20px',cursor:'pointer',width:'max-content'}} onClick={()=>{localStorage.setItem('statsV2',false);window.location.reload();}}>
                    Switch To New Design
                </div>
            }
            <div className='statsV2ButtonContainer'>
                <div style={{display:"flex",width:'50%',alignItems:'center',justifyContent:'flex-start'}}>
                    <Tooltip title="Export Data" style={{cursor:(exportRequest || (!searchAdvertiserList || searchAdvertiserList.length===0) ? 'not-allowed' : 'pointer')}}>
                        <IconButton className='settingsButton' size={'small'} onClick={()=>{if(!exportRequest && (searchAdvertiserList || searchAdvertiserList.length>0)){exportStatsData()}}}>
                            <FileDownloadIcon sx={{fontSize:'17px'}}/>
                        </IconButton>
                    </Tooltip>
                </div>
                <div style={{display:"flex",width:'50%',justifyContent:'flex-end',paddingLeft:"2%",paddingRight:"2%"}}>     
                    <Button sx={{...Constants.CONTAINEDBUTTON,height:'25px',fontSize:'12px',textTransform:'none'}} onClick={handleSearchButton}>Search</Button>
                    <Button sx={{...Constants.OUTLINEDBUTTON,marginLeft:'20px',height:'25px',fontSize:'12px',textTransform:'none'}} onClick={resetAllFields}>Reset</Button>
                </div>
            </div>
            <div style={{paddingLeft:'2%',paddingRight:'2%',marginBottom:'10px',fontSize:'12px',display:'flex',justifyContent:'space-between'}}>
                <div>
                    {!showLoader && "Showing "+offset+" - "+( (groupBy && groupBy.value) ? totalResults : (offset+limit-1 < totalResults ? offset+limit-1 : totalResults))+" out of "+totalResults}
                </div>
                <div>
                    {/* <select className='selectFailedPostbackGrouping' onChange={(e)=>{}}>
                        <option value={'raw'}>Raw Data</option>
                        <option value={'advertiser_id'}>Advertiser Grouped</option>
                        <option value={'offer_id'}>Offer Grouped</option>
                        <option value={'p_sub1'}>psub1 Grouped</option>
                    </select> */}
                </div>
                    {/* <div style={{cursor:'pointer'}} onClick={()=>{localStorage.setItem('oldStatsPage',true);window.location.reload();}}>
                    Switch to Old Design
                </div> */}
            </div>
            {showLoader && 
                <ShowLoaderComponent/>
            }
            {
                !showLoader && (groupBy && groupBy.value) &&
                <TableModel
                    totalRow={[totalRow]}
                    tableHeaders={getTableHeaders()}
                    tableValues={tableValues}
                    setTableValues={setTableValues}
                    valueRowHeight='40px'
                />
            }
            {
                !showLoader && (!groupBy || !groupBy.value) &&
                <>
                    {typeof response === 'object' && response.length>0 &&
                        <>
                            {/* TABLE HEADERS */}
                            <div className='statsV2TableHeader' style={selectedConversions.length>0 ? {top:50,transition:'all 0.5s linear'} : {top:0,transition:'all 0s linear'}}>
                                <div style={{display:'flex',justifyContent:'center',paddingRight:'0%',position:'sticky',left:0}}>           
                                    {
                                        fixedTableHeaders.map((tableHeaderItem,tableHeaderIndex)=>{
                                            if(!tableHeaderItem.disable){
                                                if(tableHeaderItem.value==='checkbox'){
                                                    return <div className='statsV2TableHeaderData' style={{justifyContent:'center',minWidth:tableHeaderItem.minWidth,width:tableHeaderItem.width}} key={tableHeaderIndex} >
                                                                <input type='checkbox' checked={selectAllConversions} onChange={(e)=>{if(selectAllConversions){setSelectedConversions([])};setSelectAllConversions(e.target.checked);updateComponent(e);}}/>
                                                            </div>
                                                }
                                                return <div className='statsV2TableHeaderData' style={{minWidth:tableHeaderItem.minWidth,width:tableHeaderItem.width}} key={tableHeaderIndex} onClick={(e)=>{setSorting(e,tableHeaderItem.value)}}>
                                                            <Resizable
                                                                id={tableHeaderItem.value}
                                                                style={{zIndex:(fixedTableHeaders.length-parseInt(tableHeaderIndex))}}
                                                                size={{width:"100%"}}
                                                                enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
                                                                onResizeStop={(e, direction, ref, d) => {
                                                                    var tempTableHeaders = fixedTableHeaders;
                                                                    if(tempTableHeaders[tableHeaderIndex].minWidth + d.width >= varTableHeaders[tableHeaderIndex].minWidth){
                                                                        tempTableHeaders[tableHeaderIndex].minWidth += d.width
                                                                    }
                                                                    else{
                                                                        tempTableHeaders[tableHeaderIndex].minWidth = varTableHeaders[tableHeaderIndex].minWidth
                                                                    }
                                                                    setFixedTableHeaders(tempTableHeaders)
                                                                    updateComponent(e);
                                                                    }}
                                                                    >
                                                                    <div className='overflowText' style={{display:'flex',justifyContent:'center'}}>
                                                                        {tableHeaderItem.label}
                                                                    </div>
                                                            </Resizable>
                                                        </div>
                                            }
                                            return null;
                                        })
                                    }
                                </div>
                                <div id = "statsV2TableHeader" style={{paddingLeft:'0%',display:'flex',overflowX:'auto'}} onScroll={(e)=>{document.getElementById('statsV2TableValue').scrollLeft = e.target.scrollLeft;}}>
                                    {
                                        variableTableHeaders.map((tableHeaderItem,tableHeaderIndex)=>{
                                            if(!tableHeaderItem.disable){
                                                return <div className='statsV2TableHeaderData' style={{minWidth:tableHeaderItem.minWidth,width:tableHeaderItem.width}} key={tableHeaderIndex} onClick={(e)=>{setSorting(e,tableHeaderItem.value,true)}}>
                                                        <Resizable
                                                            id={tableHeaderItem.value}
                                                            style={{zIndex:(variableTableHeaders.length-parseInt(tableHeaderIndex))}}
                                                            size={{width:"95%"}}
                                                            enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
                                                            onResizeStop={(e, direction, ref, d) => {
                                                                var tempTableHeaders = variableTableHeaders;
                                                                if(tempTableHeaders[tableHeaderIndex].minWidth + d.width >= varTableHeaders[tableHeaderIndex].minWidth){
                                                                    tempTableHeaders[tableHeaderIndex].minWidth += d.width
                                                                }
                                                                setVariableTableHeaders(tempTableHeaders)
                                                                updateComponent(e);
                                                                }}
                                                                >
                                                                <div style={{display:'flex',textAlign:'center',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>                                                        
                                                                    {tableHeaderItem.label}
                                                                </div>
                                                        </Resizable>
                                                    </div>
                                            }
                                            return null;
                                        })
                                    }
                                </div>
                            </div>
                            {/* TABLE VALUES */}
                            <div className='statsV2TableValue'>
                                <div>
                                    {   
                                        Object.keys(response).map((keyItem,keyIndex)=>{
                                            var height = 40
                                            if(response[keyItem].payouts && response[keyItem].payouts.length>1){
                                                height = 40 + (response[keyItem].payouts.length*10)
                                            }
                                            if(selectAllConversions){
                                                var tempSelectedConversion = selectedConversions
                                                if(!tempSelectedConversion.includes(response[keyItem].id)){
                                                    tempSelectedConversion.push(response[keyItem].id)
                                                    setSelectedConversions(tempSelectedConversion)
                                                }
                                            }
                                            return <div id={'fixedColums'+keyIndex} style={{display:'flex',height}} key={keyIndex}>           
                                                {
                                                    fixedTableHeaders.map((tableHeaderItem,tableHeaderIndex)=>{
                                                        var dateOptions = { year: 'numeric', month: 'short', day: 'numeric' ,hour:'numeric',minute:'numeric',second:'numeric'};
                                                        if(!tableHeaderItem.disable){
                                                            if(tableHeaderItem.value==='checkbox'){
                                                                return <div className='statsV2TableValueData' style={{minWidth:tableHeaderItem.minWidth,width:tableHeaderItem.width,maxWidth:tableHeaderItem.width}} key={tableHeaderIndex}>
                                                                        <input checked={selectedConversions.includes(response[keyItem].id)} type='checkbox' onChange={(e)=>{
                                                                                var tempSelectedConversion = selectedConversions
                                                                                if(!tempSelectedConversion.includes(response[keyItem].id)){
                                                                                    tempSelectedConversion.push(response[keyItem].id)
                                                                                }
                                                                                else{
                                                                                    setSelectAllConversions(false);
                                                                                    tempSelectedConversion = tempSelectedConversion.filter(e=>e!==response[keyItem].id)
                                                                                }
                                                                                setSelectedConversions(tempSelectedConversion)
                                                                                updateComponent(e);
                                                                            }}/>
                                                                    </div>
                                                            }
                                                            if(tableHeaderItem.value==='key'){        
                                                                return <div className='statsV2TableValueData' style={{minWidth:tableHeaderItem.minWidth,width:tableHeaderItem.width,maxWidth:tableHeaderItem.width}} key={tableHeaderIndex}>
                                                                            {response[keyItem][tableHeaderItem.value]}
                                                                        </div>
                                                            }
                                                            if(tableHeaderItem.value==='postback_time'){        
                                                                return <div className='statsV2TableValueData' style={{textAlign:'center',minWidth:tableHeaderItem.minWidth,width:tableHeaderItem.width,maxWidth:tableHeaderItem.width}} key={tableHeaderIndex}>
                                                                            {new Date(response[keyItem][tableHeaderItem.value]).toLocaleDateString("en-US", dateOptions)}
                                                                        </div>
                                                            }
                                                            return <div className='statsV2TableValueData' style={{justifyContent:'center',minWidth:tableHeaderItem.minWidth,width:tableHeaderItem.width}} key={tableHeaderIndex}>
                                                                <div className='overflowText' style={{paddingLeft:'3%',paddingRight:'3%'}}>
                                                                    {response[keyItem][tableHeaderItem.value]}
                                                                </div>
                                                            </div>
                                                        }
                                                        return null;
                                                    })
                                                }
                                            </div>
                                        })   
                                    }
                                </div>
                                <div id='statsV2TableValue' className='noscrollbar' style={{display:'flex',flexDirection:'column',overflow:'auto'}} onScroll={(e)=>{document.getElementById('statsV2TableHeader').scrollLeft = e.target.scrollLeft;}}>
                                    {showValues()}
                                </div>
                            </div>
                            {/* TABLE PAGINATION */}
                            <div style={{display:'flex',alignItems:'center',justifyContent:"space-between",paddingLeft:'20px',paddingRight:'20px',paddingBottom:'20px',position:'sticky',bottom:0,background:'white'}}>
                                <div>
                                    <label style={{paddingRight:"5px",fontSize:'14px'}}>Limit</label>
                                    <select value={limit} style={{outline:'none',border:'none'}} onChange={(e)=>{getStatsData(e,e.target.value,1);setLimit(parseInt(e.target.value));setPage(1);}}>
                                        <option value={25}>25</option>
                                        <option value={50}>50</option>
                                        <option value={75}>75</option>
                                        <option value={100}>100</option>
                                    </select>
                                </div>
                                <Pagination count={Math.ceil(totalResults/limit)} page={page} onChange={(event,value)=>{getStatsData(event,limit,value);setPage(value);updateComponent(event);window.scrollTo({top: 0,left: 0,behavior: 'smooth'});}} />
                            </div>
                        </>
                    }
                    {typeof response === 'object' && 
                        response.length===0
                        &&
                        <NoResultsFound/>
                    }
                    {
                        typeof response === 'string' &&
                        <SomeErrorOccured error={response}/>
                    }
                </>
            }
           
        </div>
    )
}

export default FailedPostbackStatistics