import React, { useEffect, useState } from 'react'
import { AdvertiserIcon, AdvertiserManagerIcon, AffiliateIcon, DateRangeIcon, SpiralIcon, TagIcon } from '../../data/icons';
import FilterRow from '../../components/filterV2/filtersRow';
import { getThisWeek } from '../../components/dateRangePicker/dateRangePicker';
import { getAdvertisers, getManager } from '../../apis/dropdown/dropdowns';
import { getLocalStorageValue, getRequests } from '../../util/util';
import '../demography/v2/demographyV2.css';
import TableModel from '../../components/tableModel/tablemodel';
import * as Constants from '../../data/constants';
import axios from 'axios';
import ShowLoaderComponent from '../../components/loader';
import { Tooltip } from '@mui/material';
import { Close } from '@mui/icons-material';
import arrowdown from "../billing/insightsManik/assets/arrow-down.svg";
import Select, { components } from "react-select";

const RealTimeValidations = () => {

    var { firstDayOfThisWeek, lastDayOfThisWeek } = getThisWeek()

    if (window.location.pathname.includes('realtimepayments')) {
        var paymentPage = true
    }
    else {
        paymentPage = false
    }
    var defaultFilterData = {
        from_date: firstDayOfThisWeek,
        end_date: lastDayOfThisWeek,
        show_converted_stats: { label: 'Yes', value: true },
    }
    const [filterData, setFilterData] = useState(defaultFilterData)
    const [optionsAdvertiserList, setOptionsAdvertiserList] = useState([])
    const [optionsManagersList, setOptionsManagerList] = useState([])
    const [loader, setLoader] = useState(false)
    const [limit, setLimit] = useState(500)
    const [page, setPage] = useState(1)
    const [tableValues, setTableValues] = useState([])
    const [totalRow, setTotalRow] = useState([])
    const [offset, setOffset] = useState(0);
    const [totalResults, setTotalResults] = useState(0);
    const [otherRespData, setOtherRespData] = useState({});
    const [groupBy, setGroupBy] = useState();
    const [groupBy1, setGroupBy1] = useState('account_manager');
    const [isPaymentLogging, setIsPaymentLogging] = useState(false);
    const [sortingData, setSortingData] = useState({ sortingKeyword: 'approved_revenue', sortingOrder: false })

    const [monthWise, setMonthWise] = useState(false)
    const [tableValues1, setTableValues1] = useState([])
    const [totalRow1, setTotalRow1] = useState([])
    const [originalData, setOriginalData] = useState([])
    const overrideSetFilterData = (value, filterItem) => {
        if (filterItem === 'dateRangePicker') {
            setFilterData({ ...filterData, ...value })
        }
        else {
            setFilterData({ ...filterData, [filterItem.value]: value })
        }
    }

    const getStatsData = async (e, manual_limit = null, manual_page = null, sortingKeyword = null, sortingOrder = null, manual_group_by = null) => {
        if ((e && e.target && e.target.value === 'month_wise') || groupBy == 'month_wise') {
            setMonthWise(true)
        }
        else {
            setMonthWise(false)
        }
        setLoader(true);
        var start_time = new Date().getTime()
        var from_date = filterData.from_date.toLocaleString('fr-CA', { year: 'numeric', month: 'numeric', day: 'numeric' })
        var end_date = filterData.end_date.toLocaleString('fr-CA', { year: 'numeric', month: 'numeric', day: 'numeric' })

        var filter_dict = {}
        for (var index in filtersList) {
            var item = filtersList[index]
            if (item.type === 'textfield') {
                if (filterData[item.value]) {
                    filter_dict[item.value] = filterData[item.value]
                }
            }
            if (item.type === 'select' && !item.isSingle) {
                var value = ''
                for (var xIndex in filterData[item.value]) {
                    var xItem = filterData[item.value][xIndex]
                    if (parseInt(xIndex) === filterData[item.value].length - 1) {
                        value += xItem.value
                    }
                    else {
                        value += xItem.value + ','
                    }
                }
                if (value || value === 0) {
                    filter_dict[item.value] = value
                }
            }
            if (item.type === 'select' && item.isSingle) {
                if (filterData[item.value] && filterData[item.value].value) {
                    filter_dict[item.value] = filterData[item.value].value
                }
            }

        }
        var group_by = undefined
        if (groupBy || (e && e.target && e.target.value ? e.target.value : groupBy)) {
            group_by = e && e.target && e.target.value ? e.target.value : groupBy
            if (group_by === 'Raw Data' || group_by === "month_wise") {
                group_by = undefined
            }
            if (group_by === 'advertiser') {
                group_by += '_id'
            }
        }
        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL, Constants.HOST, '/api/admin/v1/invoice/log'),
            headers: {
                "Content-Type": "application/json",
                Authorization: getLocalStorageValue('adminPanelLoginData').token
            },
            params: {
                from_date,
                end_date,
                group_by,
                payment_logs: paymentPage ? true : undefined,
                order_key: sortingKeyword ? sortingKeyword : sortingData.sortingKeyword ? sortingData.sortingKeyword : 'total_revenue',
                order_by: sortingOrder ? sortingOrder : sortingData.sortingOrder ? 'asc' : 'desc',
                ...filter_dict
            }
        })
            .then(resp => {
                if ((e && e.target && e.target.value === "month_wise") || (groupBy === 'month_wise' && e == undefined)) {
                    var items = resp.data.result
                    var total = resp.data.total_row;
                    var data = {};
                    var tableData = [];
                    var total_approved_revenue = total['manager_approved_revenue']
                    var total_payment_received = total['payment_received']
                    var advertiserMap = resp.data.advertiser_id_dict;
                    var managerMap = resp.data.advertiser_manager_dict
                    for (let i = 0; i < items.length; i++) {
                        var month = items[i].month;
                        if (!data[month]) {
                            data[month] = {
                                delivered_revenue: 0, manager_approved_revenue: 0, conversions_verified: 0,
                                highest_adv: '', highest_adv_rev: 0, highest_manager: '', highest_manager_rev: 0,
                                highest_package: '', highest_package_rev: 0, advertiser_list: [], package_list: [], manager_list: [],
                                payment_received: 0
                            }
                        }
                        data[month]['delivered_revenue'] += items[i].delivered_revenue
                        data[month]['manager_approved_revenue'] += items[i].manager_approved_revenue
                        data[month]['conversions_verified'] += items[i].conversions_verified
                        data[month]['payment_received'] += items[i].payment_received
                        var packageName = items[i].package_name
                        var manager = items[i].account_manager
                        var advertiser = items[i].advertiser
                        if (packageName != '' && !data[month]['package_list'].includes(packageName)) {
                            data[month]['package_list'].push(packageName)
                        }
                        if (advertiser != '' && !data[month]['advertiser_list'].includes(advertiserMap[advertiser].company)) {
                            data[month]['advertiser_list'].push(advertiserMap[advertiser].company)
                        }
                        if (manager != '' && !data[month]['manager_list'].includes(managerMap[manager].name)) {
                            data[month]['manager_list'].push(managerMap[manager].name)
                        }
                        if (items[i].manager_approved_revenue > data[month]['highest_adv_rev'] || (paymentPage && items[i].payment_received > data[month]['highest_adv_rev'])) {
                            data[month]['highest_adv_rev'] = items[i].manager_approved_revenue
                            data[month]['highest_adv'] = advertiser
                            if (advertiserMap[advertiser])
                                data[month]['highest_adv'] = advertiserMap[advertiser].company
                        }
                        if (items[i].manager_approved_revenue > data[month]['highest_manager_rev'] || (paymentPage && items[i].payment_received > data[month]['highest_manager_rev'])) {
                            data[month]['highest_manager_rev'] = items[i].manager_approved_revenue
                            data[month]['highest_manager'] = manager
                            if (managerMap[manager])
                                data[month]['highest_manager'] = managerMap[manager].name
                        }
                        if (items[i].manager_approved_revenue > data[month]['highest_package_rev'] || (paymentPage && items[i].payment_received > data[month]['highest_package_rev'])) {
                            data[month]['highest_package_rev'] = items[i].manager_approved_revenue
                            data[month]['highest_package'] = packageName
                        }
                    }
                    for (const key in data) {
                        if (data[key]['conversions_verified'] == 0)
                            data[key]['validation_percentage'] = 0
                        else
                            data[key]['validation_percentage'] = data[key]['manager_approved_revenue'] / data[key]['conversions_verified'] * 100

                        if (paymentPage)
                            data[key]['payment_percentage'] = data[key]['manager_approved_revenue'] ? (data[key]['payment_received'] * 100 / data[key]['manager_approved_revenue']) : data[key]['payment_received'] ? 100 : 0
                    }
                    for (const key in data) {
                        tableData.push({
                            'month': key,
                            manager_approved_revenue: data[key]['manager_approved_revenue'],
                            delivered_revenue: data[key]['delivered_revenue'],
                            conversions_verified: data[key]['conversions_verified'],
                            validation_percentage: data[key]['validation_percentage'],
                            contribution_percentage: paymentPage? data[key]['payment_received'] / total_payment_received *100: data[key]['manager_approved_revenue'] / total_approved_revenue * 100,
                            top_manager: data[key]['highest_manager'],
                            top_advertiser: data[key]['highest_adv'],
                            top_package: data[key]['highest_package'],
                            package_list: data[key]['package_list'],
                            advertiser_list: data[key]['advertiser_list'],
                            manager_list: data[key]['manager_list'],
                            payment_received: data[key]['payment_received'],
                            payment_percentage: data[key]['payment_percentage']
                        })
                    }
                    tableData.sort(
                        (a, b) => b.month.localeCompare(a.month)
                    );
                    console.log(tableData)
                    setTableValues(tableData)
                    setOriginalData(resp.data)
                    setTotalRow([{
                        total: true,
                        payment_received: total['payment_received'],
                        month: total['month'],
                        payment_percentage: total['manager_approved_revenue'] ? total['payment_received'] / total['manager_approved_revenue'] * 100 : 0,
                        manager_approved_revenue: total['manager_approved_revenue'],
                        conversions_verified: total['conversions_verified'],
                        delivered_revenue: total['delivered_revenue'],
                        validation_percentage: total['manager_approved_revenue'] / total['conversions_verified'] * 100,
                        contribution_percentage: '-',
                        top_manager: '-',
                        top_advertiser: '-',
                        top_package: '-',
                    }])
                }
                else {
                    var items = resp.data.result
                    var tempValues = []
                    for (var index in items) {
                        var item = items[index]
                        item.validation_percentage = item.conversions_verified ? (item.manager_approved_revenue * 100 / item.conversions_verified) : item.manager_approved_revenue ? 100 : 0
                        if (paymentPage) {
                            item.payment_percentage = item.manager_approved_revenue ? (item.payment_received * 100 / item.manager_approved_revenue) : item.payment_received ? 100 : 0
                        }
                        tempValues.push(item)
                    }
                    setTableValues(tempValues)
                    setOtherRespData({ account_manager: resp.data.advertiser_manager_dict, advertiser: resp.data.advertiser_id_dict })
                    setTotalRow([{
                        ...resp.data.total_row,
                        validation_percentage: resp.data.total_row.conversions_verified ? (resp.data.total_row.manager_approved_revenue * 100 / resp.data.total_row.conversions_verified) : resp.data.total_row.manager_approved_revenue ? 100 : 0,
                        payment_percentage: resp.data.total_row.manager_approved_revenue ? (resp.data.total_row.payment_received * 100 / resp.data.total_row.manager_approved_revenue) : resp.data.total_row.payment_received ? 100 : 0,
                    }
                    ])

                    // setTotalResults(resp.data.total_no_of_result)
                    const resSize = +resp?.headers?.['content-length']
                }
            })
            .catch((error) => {
                console.log(error)
                setTableValues([])
                if (error.response) {
                    console.log(error.response.data);
                    if (error.response.data) {
                        setTableValues(error.response.data.result);
                    }
                    else {
                        setTableValues('Some error occured at backend, please contact your Account Manager.');
                    }
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
                else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                    setTableValues('Some error occured at backend, please contact your Account Manager.');
                }
            })
        setLoader(false);
        var end_time = new Date().getTime()
    }

    useEffect(() => {
        getRequests(getAdvertisers(), setOptionsAdvertiserList)
        getRequests(getManager(), setOptionsManagerList)
        getStatsData()
        return () => {

        }
    }, [])

    var filtersList = [
        { label: 'Date Range', icon: DateRangeIcon, paramKey: 'dateRange', value: 'date_range', type: 'daterangepicker' },
        { label: 'Advertiser', icon: AdvertiserIcon, paramKey: 'advertiser', value: 'advertiser_id', type: 'select', multiple: true, options: optionsAdvertiserList },
        { label: 'Account Manager', icon: AdvertiserManagerIcon, paramKey: 'advertiserManager', value: 'advertiser_manager_id', type: 'select', multiple: true, options: optionsManagersList },
        { label: 'Package Name', icon: SpiralIcon, paramKey: 'packageName', value: 'package_name', type: 'textfield' },
        { label: 'Submit Reset Button', type: 'submit_reset_button', onSubmit: getStatsData, onReset: () => { setFilterData(defaultFilterData) } },
    ]

    const monthComponent = (valueItem, item) => {
        if (valueItem.total === true) {
            return valueItem[item.value] ? (valueItem[item.value] + ' Months') : '-'
        }
        if (item.groupBy) {
            return multipleDataComponent(valueItem, item)
        }
        return <div>
            {new Date(valueItem[item.value]).toLocaleDateString('en-US', { month: 'short', year: '2-digit' })}
        </div>
    }
    const dateTimeComponent = (valueItem, item) => {
        if (valueItem.total) {
            return '-'
        }
        return <div>
            {new Date(valueItem[item.value]).toLocaleDateString('en-US', { month: 'short', year: '2-digit', day: '2-digit' })}
        </div>
    }
    const parseFloatComponent = (valueItem, item) => {
        var value = valueItem[item.value] ? valueItem[item.value].toFixed(2) : 0
        return <div style={{ width: 'calc(100% - 25px)' }}>
            {value}
        </div>
    }
    const getNameComponent = (valueItem, item) => {
        if (valueItem.total === true) {
            return valueItem[item.value] ? (valueItem[item.value] + ' ' + item.label + 's') : '-'
        }
        var name = '-'
        var multipleList = []
        var kk = {
            advertiser: 'company',
            account_manager: 'name'
        }
        var dd = {
            advertiser: optionsAdvertiserList,
            account_manager: optionsManagersList
        }
        if (otherRespData[item.value] && otherRespData[item.value][valueItem[item.value]]) {
            name = otherRespData[item.value][parseInt(valueItem[item.value])][kk[item.value]]
        }
        else {
            if (valueItem[item.value]) {
                for (var advertiserIndex in dd[item.value]) {
                    var advertiserItem = dd[item.value][advertiserIndex]
                    if (valueItem[item.value] && typeof valueItem[item.value] === 'object' && valueItem[item.value].length === 1) {
                        var xx = valueItem[item.value][0]
                        if (advertiserItem.value.toString() === xx.toString()) {
                            name = advertiserItem.label.split(' - ')[0]
                            break;
                        }
                    }
                    else if (valueItem[item.value].includes(advertiserItem.value) || valueItem[item.value].includes(parseInt(advertiserItem.value))) {
                        multipleList.push(advertiserItem.label.split(' - ')[0])
                    }
                }
            }
        }
        if (multipleList.length > 0) {
            return <Tooltip title={",".concat(multipleList).slice(1)}>
                {multipleList.length + ' ' + item.label + 's'}
            </Tooltip>
        }
        return name
    }
    const multipleDataComponent = (valueItem, item) => {
        if (item.value === 'package_name') {
            if (item.groupBy) {
                item.value = 'package_name_list'
            }
        }
        if (valueItem.total === true) {
            return valueItem[item.value] ? (valueItem[item.value] + ' ' + item.label + 's') : '-'
        }
        if (typeof valueItem[item.value] === 'object') {
            if (valueItem[item.value].length === 1) {
                return valueItem[item.value][0]
            }
            return <Tooltip title={",".concat(valueItem[item.value]).slice(1)}>
                {valueItem[item.value].length + ' ' + item.label + 's'}
            </Tooltip>

        }
        return valueItem[item.value] ? valueItem[item.value] : '-'
    }

    const validationPercentageComponent = (valueItem, item, index, valueIndex, tableValues) => {

        if (valueItem.total === true && isNaN(valueItem[item.value])) {
            return '-'
        }
        var percent = valueItem[item.value] ? valueItem[item.value].toFixed(2) : 0.00

        if (percent < 25) {
            var color = '#ff0000'
            var highlightColor = '#ffcccc'
        }
        else if (percent < 50) {
            color = '#ff6200'
            highlightColor = '#ffe6cc'
        }
        else if (percent < 75) {
            color = '#6f9400'
            highlightColor = '#ebffc2'
        }
        else {
            color = '#0f8500'
            highlightColor = '#d2ffcc'
        }

        return <div style={{ color, fontWeight: 500, background: highlightColor, marginLeft: (valueItem.advertiser_id === 'Total' ? '5px' : '0px'), padding: '5px', width: 'max-content', borderRadius: '10px', float: 'right' }}>
            {percent.toString() + '%'}
        </div>
    }

    const [activeTab, setActiveTab] = useState(null)
    const showDetailSummaryComponent = (valueItem, item, index, valueIndex, tableValues) => {
        if (valueItem.package_name === 'Total') {
            return ''
        }
        return <div style={{ width: '80%', color: localStorage.getItem('ambientColor'), cursor: 'pointer' }}
            onClick={(e) => {
                setActiveTab(valueIndex);
                getSummarizedRows(groupBy1, valueIndex)
            }}>
            Show Summary
        </div>
    }

    const getSummarizedRows = (label, valueIndex) => {
        const advertiserMap = originalData.advertiser_id_dict;
        const managerMap = originalData.advertiser_manager_dict;
        const results = []
        const totalRow = []
        const data = originalData.result;
        const labelWiseMap = {};
        let total_delivered = 0
        let total_approved_revenue = 0
        let total_conversions_verified = 0
        let total_validation_percentage = 0
        let total_payment_received = 0
        let total_payment_percentage = 0
        for (const item of data) {
            if ((activeTab != null && item.month == tableValues[activeTab].month) || (valueIndex != null && item.month == tableValues[valueIndex].month)) {
                const key = item[label]
                if (!labelWiseMap[key]) {
                    labelWiseMap[key] = { 'delivered_revenue': 0, 'conversions_verified': 0, 'manager_approved_revenue': 0, 'payment_received': 0 }
                }
                labelWiseMap[key]['delivered_revenue'] += item['delivered_revenue']
                labelWiseMap[key]['conversions_verified'] += item['conversions_verified']
                labelWiseMap[key]['manager_approved_revenue'] += item['manager_approved_revenue']
                labelWiseMap[key]['payment_received'] += item['payment_received']
            }
        }
        for (const key in labelWiseMap) {
            const summary = labelWiseMap[key];
            total_delivered += summary.delivered_revenue
            total_payment_received += summary.payment_received
            total_approved_revenue += summary.manager_approved_revenue
            total_conversions_verified += summary.conversions_verified
            const obj = {
                'delivered_revenue': summary.delivered_revenue,
                'conversions_verified': summary.conversions_verified,
                'payment_received': summary.payment_received,
                'manager_approved_revenue': summary.manager_approved_revenue,
                'validation_percentage': summary.conversions_verified == 0 ? 0 : summary.manager_approved_revenue / summary.conversions_verified * 100,
                'payment_percentage': summary.manager_approved_revenue == 0 ? 0 : summary.payment_received / summary.manager_approved_revenue * 100
            }
            total_validation_percentage = total_conversions_verified == 0 ? 0 : total_approved_revenue / total_conversions_verified * 100
            total_payment_percentage = total_approved_revenue == 0 ? 0 : total_payment_received / total_approved_revenue * 100
            obj[label] = label == 'advertiser' ? advertiserMap[key].company : label == 'account_manager' ? managerMap[key].name : key
            results.push(obj);

        }
        const totalObj = {
            'delivered_revenue': total_delivered,
            'conversions_verified': total_conversions_verified,
            'manager_approved_revenue': total_approved_revenue,
            'validation_percentage': total_validation_percentage,
            'payment_received': total_payment_received,
            'payment_percentage': total_payment_percentage
        }
        totalObj[label] = 'Total'
        totalRow.push(totalObj)
        setTableValues1(results)
        setTotalRow1(totalRow)
        return { results, totalRow };
    }

    const summaryData = () => {

        const customStyles = {
            container: (base) => ({
                ...base,
                flexGrow: "1",
                margin: "0 1px",
                height: "48px",
                position: "relative",
            }),
            control: (base) => ({
                ...base,
                justifyContent: "start",
                alignItems: "center",
                border: '0',
                padding: '2px',
                boxShadow: "none",
                width: "13rem",
                fontFamily: "Oswald,sans-serif",
                marginLeft: "6px"
            }),
            valueContainer: (base) => ({
                ...base,
                flex: "unset",
                height: "48px",
                fontSize: "1.2rem",
            }),
            menu: (base) => ({
                ...base,
                position: "absolute",
                width: "13rem",
                zIndex: 10000,
            }),
            placeholder: (base) => ({
                ...base,
                color: "#252525",
            }),
            option: (styles, { isFocused, isSelected }) => {
                return {
                    ...styles,
                    color: "#252525",
                    backgroundColor: isSelected
                        ? "#E0F1FB"
                        : isFocused
                            ? "#E0F1FB"
                            : undefined,
                    textAlign: "left",
                    fontSize: "1rem",
                };
            },
        };

        const handleChange = (option) => {
            setGroupBy1(option.value)
            option.isSelected = true;
            getSummarizedRows(option.value)
        }
        var managerHeaders, advertiserHeaders, packageHeaders;
        managerHeaders = [
            { label: 'Account Manager', value: 'account_manager', width: 80, customComponent: textComponent, style: { textAlign: 'left' } },
            { label: 'Delivered Revenue ', value: 'delivered_revenue', width: 100, customComponent: parseFloatComponent, style: { textAlign: 'right' } },
            { label: 'Verified Revenue ', value: 'conversions_verified', width: 100, customComponent: parseFloatComponent, style: { textAlign: 'right' } },
            { label: 'Approved Revenue ', value: 'manager_approved_revenue', width: 100, customComponent: parseFloatComponent, style: { textAlign: 'right' } },
            { label: 'Validation % ', value: 'validation_percentage', width: 100, customComponent: validationPercentageComponent }
        ]
        advertiserHeaders = [
            { label: 'Advertiser Name', value: 'advertiser', width: 80, customComponent: textComponent, style: { textAlign: 'left' } },
            { label: 'Delivered Revenue ', value: 'delivered_revenue', width: 100, customComponent: parseFloatComponent, style: { textAlign: 'right' } },
            { label: 'Verified Revenue ', value: 'conversions_verified', width: 100, customComponent: parseFloatComponent, style: { textAlign: 'right' } },
            { label: 'Approved Revenue ', value: 'manager_approved_revenue', width: 100, customComponent: parseFloatComponent, style: { textAlign: 'right' } },
            { label: 'Validation % ', value: 'validation_percentage', width: 100, customComponent: validationPercentageComponent }
        ]
        packageHeaders = [
            { label: 'Package Name', value: 'package_name', width: 80, customComponent: textComponent, style: { textAlign: 'left' } },
            { label: 'Delivered Revenue ', value: 'delivered_revenue', width: 100, customComponent: parseFloatComponent, style: { textAlign: 'right' } },
            { label: 'Verified Revenue ', value: 'conversions_verified', width: 100, customComponent: parseFloatComponent, style: { textAlign: 'right' } },
            { label: 'Approved Revenue ', value: 'manager_approved_revenue', width: 100, customComponent: parseFloatComponent, style: { textAlign: 'right' } },
            { label: 'Validation % ', value: 'validation_percentage', width: 100, customComponent: validationPercentageComponent }
        ]

        if (paymentPage) {
            managerHeaders = [
                { label: 'Account Manager', value: 'account_manager', width: 80, customComponent: textComponent, style: { textAlign: 'left' } },
                { label: 'Payment_Received ', value: 'payment_received', width: 100, customComponent: parseFloatComponent, style: { textAlign: 'right' } },
                { label: 'Approved Revenue ', value: 'manager_approved_revenue', width: 100, customComponent: parseFloatComponent, style: { textAlign: 'right' } },
                { label: 'Payment Received % ', value: 'payment_percentage', width: 100, customComponent: validationPercentageComponent }
            ]
            advertiserHeaders = [
                { label: 'Advertiser Name', value: 'advertiser', width: 80, customComponent: textComponent, style: { textAlign: 'left' } },
                { label: 'Payment_Received ', value: 'payment_received', width: 100, customComponent: parseFloatComponent, style: { textAlign: 'right' } },
                { label: 'Approved Revenue ', value: 'manager_approved_revenue', width: 100, customComponent: parseFloatComponent, style: { textAlign: 'right' } },
                { label: 'Payment Received% ', value: 'payment_percentage', width: 100, customComponent: validationPercentageComponent }
            ]
            packageHeaders = [
                { label: 'Package Name', value: 'package_name', width: 80, customComponent: textComponent, style: { textAlign: 'left' } },
                { label: 'Payment_Received ', value: 'payment_received', width: 100, customComponent: parseFloatComponent, style: { textAlign: 'right' } },
                { label: 'Approved Revenue ', value: 'manager_approved_revenue', width: 100, customComponent: parseFloatComponent, style: { textAlign: 'right' } },
                { label: 'Payment Received % ', value: 'payment_percentage', width: 100, customComponent: validationPercentageComponent }
            ]
        }
        const options = [{ 'label': 'Manager-Wise', 'value': 'account_manager' },
        { 'label': 'Advertiser-Wise', 'value': 'advertiser' },
        { 'label': 'Package Name -Wise', 'value': 'package_name' }
        ]
        const IndicatorsContainer = () => {
            return <img className="icon1" src={arrowdown} alt="indicator" />;
        }
        return <div>
            <div style={{ fontSize: '1px', marginLeft: '5px', fontWeight: '400' }}>
                <Select
                    onChange={handleChange}
                    value={
                        options.find(option => option.value === groupBy1)
                    }
                    placeholder={'Manager-Wise'}
                    options={options}
                    isClearable={true}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    styles={customStyles}
                    isSearchable={false}
                    components={{
                        IndicatorsContainer: IndicatorsContainer,
                        SingleValue: components.SingleValue,
                    }}
                />
            </div>

            {groupBy1 == 'account_manager' &&
                <div className='v2StickRows' style={{ fontSize: '17px', fontWeight: '500', marginLeft: '10px' }}>
                    <div style={{ overflow: "scroll", maxHeight: "25rem" }}>
                        <TableModel
                            tableHeaders={managerHeaders}
                            tableValues={tableValues1}
                            style={{ background: 'rgb(245,245,245)' }}
                            tableValueRow={'rest'}
                            totalRow={totalRow1}
                            setTableValues={setTableValues1}
                        />
                    </div>
                </div>
            }

            {groupBy1 == 'advertiser' &&
                <div className='v2StickRows' style={{ fontSize: '17px', fontWeight: '500', marginLeft: '10px' }}>
                    <div style={{ overflow: "scroll", maxHeight: "25rem" }}>
                        <TableModel
                            tableHeaders={advertiserHeaders}
                            tableValues={tableValues1}
                            style={{ background: 'rgb(245,245,245)' }}
                            tableValueRow={'rest'}
                            totalRow={totalRow1}
                            setTableValues={setTableValues1}
                        />
                    </div>
                </div>
            }
            {groupBy1 == 'package_name' &&
                <div className='v2StickRows' style={{ fontSize: '17px', fontWeight: '500', marginLeft: '10px' }}>
                    <div style={{ overflow: "scroll", maxHeight: "25rem" }}>
                        <TableModel
                            tableHeaders={packageHeaders}
                            tableValues={tableValues1}
                            style={{ background: 'rgb(245,245,245)' }}
                            tableValueRow={'rest'}
                            setTableValues={setTableValues1}
                            totalRow={totalRow1}
                        />
                    </div>
                </div>
            }
        </div>
    }

    const textComponent = (valueItem, item) => {
        if (valueItem.total === true) {
            return '-'
        }
        return <div style={{ justifyContent: "left" }}> {valueItem[item.value]}</div>
    }

    var tempTableHeaders = [
        { label: 'Validation Date', value: 'validation_date', locked: true, width: 100, customComponent: dateTimeComponent, style: { textAlign: 'left' } },
        { label: 'Activity Month', value: 'month', groupBy, width: 80, customComponent: monthComponent, style: { textAlign: 'left' } },
        { label: 'Package ID', value: 'package_name', groupBy, width: 120, customComponent: multipleDataComponent, style: { textAlign: 'left' } },
        { label: 'Advertiser', value: 'advertiser', groupBy, width: 120, customComponent: getNameComponent, style: { textAlign: 'left' } },
        { label: 'Account Manager', value: 'account_manager', width: 85, customComponent: getNameComponent, style: { textAlign: 'left' } },
        { label: 'Offer ID', value: 'offer_id', width: 65, customComponent: multipleDataComponent, style: { textAlign: 'left' } },
        { label: 'External OID', value: 'external_offer_id', width: 120, customComponent: multipleDataComponent, style: { textAlign: 'left' } },
        { label: 'Validation Percentage', value: 'validation_percentage', width: 100, customComponent: validationPercentageComponent, style: { textAlign: 'left' } },
        { label: 'Delivered Revenue ', value: 'delivered_revenue', width: 100, customComponent: parseFloatComponent, style: { textAlign: 'right' } },
        { label: 'Verified Revenue ', value: 'conversions_verified', width: 100, customComponent: parseFloatComponent, style: { textAlign: 'right' } },
        { label: 'Approved Revenue ', value: 'manager_approved_revenue', width: 100, customComponent: parseFloatComponent, style: { textAlign: 'right' } },
        // {label:'Billing Panel Status',value:'total_quantity',width:100},
        // {label:'Total Revenue',value:'total_revenue',width:100},
        // {label:'Total Payouts',value:'total_payout',width:100},
        // {label:'Total Earnings',value:'total_earnings',width:100},
    ]
    if (paymentPage) {
        tempTableHeaders = [
            { label: 'Payment Date', value: 'validation_date', locked: true, width: 100, customComponent: dateTimeComponent, style: { textAlign: 'left' } },
            { label: 'Activity Month', value: 'month', groupBy, width: 80, customComponent: monthComponent, style: { textAlign: 'left' } },
            { label: 'Package ID', value: 'package_name', groupBy, width: 120, customComponent: multipleDataComponent, style: { textAlign: 'left' } },
            { label: 'Advertiser', value: 'advertiser', groupBy, width: 120, customComponent: getNameComponent, style: { textAlign: 'left' } },
            { label: 'Account Manager', value: 'account_manager', width: 85, customComponent: getNameComponent, style: { textAlign: 'left' } },
            { label: 'Offer ID', value: 'offer_id', width: 65, customComponent: multipleDataComponent, style: { textAlign: 'left' } },
            { label: 'External OID', value: 'external_offer_id', width: 120, customComponent: multipleDataComponent, style: { textAlign: 'left' } },
            { label: 'Payment Percentage', value: 'payment_percentage', width: 100, customComponent: validationPercentageComponent, style: { textAlign: 'left' } },
            { label: 'Approved Revenue ', value: 'manager_approved_revenue', width: 100, customComponent: parseFloatComponent, style: { textAlign: 'right' } },
            { label: 'Payment Received', value: 'payment_received', width: 100, customComponent: parseFloatComponent, style: { textAlign: 'right' } },
            // {label:'Billing Panel Status',value:'total_quantity',width:100},
            // {label:'Total Revenue',value:'total_revenue',width:100},
            // {label:'Total Payouts',value:'total_payout',width:100},
            // {label:'Total Earnings',value:'total_earnings',width:100},
        ]
    }

    if (groupBy) {
        var shiftIndex = tempTableHeaders.findIndex(resp => resp.value === groupBy)
        var tt = tempTableHeaders[shiftIndex]
        if(groupBy!=='manager'){
            tempTableHeaders.splice(shiftIndex, 1)
        }
        tempTableHeaders = [tt, ...tempTableHeaders]
        tempTableHeaders = tempTableHeaders.filter(e => (e && e.value !== 'validation_date'))
    }

    if (monthWise && groupBy == 'month_wise') {
        var tempTableHeaders = [
            { label: 'Activity Month', value: 'month', groupBy1, width: 80, customComponent: monthComponent, style: { textAlign: 'left' } },
            { label: 'Delivered Revenue ', value: 'delivered_revenue', width: 100, customComponent: parseFloatComponent, style: { textAlign: 'right' } },
            { label: 'Verified Revenue ', value: 'conversions_verified', width: 100, customComponent: parseFloatComponent, style: { textAlign: 'right' } },
            { label: 'Approved Revenue ', value: 'manager_approved_revenue', width: 100, customComponent: parseFloatComponent, style: { textAlign: 'right' } },
            { label: 'Validation % ', value: 'validation_percentage', width: 100, customComponent: validationPercentageComponent, style: { textAlign: 'right' } },
            { label: 'Contribution % ', value: 'contribution_percentage', width: 100, customComponent: validationPercentageComponent, style: { textAlign: 'right' } },
            { label: 'Top Manager', value: 'top_manager', width: 100, customComponent: textComponent, style: { textAlign: 'right' } },
            { label: 'Top Advertiser', value: 'top_advertiser', width: 100, customComponent: textComponent, style: { textAlign: 'right' } },
            { label: 'Top Package', value: 'top_package', width: 100, customComponent: textComponent, style: { textAlign: 'right', paddingRight: '10px' } },
            { label: '      ', value: 'view_details', width: 80, hideSorting: true, hide: false, customComponent: showDetailSummaryComponent },
        ]
        if (paymentPage) {
            tempTableHeaders = [
                { label: 'Activity Month', value: 'month', groupBy1, width: 80, customComponent: monthComponent, style: { textAlign: 'left' } },
                { label: 'Payment Received', value: 'payment_received', width: 100, customComponent: parseFloatComponent, style: { textAlign: 'right' } },
                { label: 'Approved Revenue ', value: 'manager_approved_revenue', width: 100, customComponent: parseFloatComponent, style: { textAlign: 'right' } },
                { label: 'Payment Received %', value: 'payment_percentage', width: 100, customComponent: validationPercentageComponent, style: { textAlign: 'right' } },
                { label: 'Contribution % ', value: 'contribution_percentage', width: 100, customComponent: validationPercentageComponent, style: { textAlign: 'right' } },
                { label: 'Top Manager', value: 'top_manager', width: 100, customComponent: textComponent, style: { textAlign: 'right' } },
                { label: 'Top Advertiser', value: 'top_advertiser', width: 100, customComponent: textComponent, style: { textAlign: 'right' } },
                { label: 'Top Package', value: 'top_package', width: 100, customComponent: textComponent, style: { textAlign: 'right', paddingRight: '10px' } },
                { label: '      ', value: 'view_details', width: 80, hideSorting: true, hide: false, customComponent: showDetailSummaryComponent, style: { textAlign: 'right', paddingRight: '10px' } },
            ]
        }
    }


    const sortingFunction = (keyword, sortingOrder) => {
        if (totalResults <= limit) {

        }
        else {
            getStatsData(null, null, null, keyword, sortingOrder)
        }
        setSortingData({ sortingKeyword: keyword, sortingOrder })
    }
    return (
        <div className='v2PageContainer'>
            <div className='v2StickRows'>
                <div className='v2Title' style={{ display: 'flex', alignItems: 'flex-end' }}>
                    Real Time {paymentPage ? 'Payments' : 'Validations'}
                    <div style={{ fontSize: '16px', marginLeft: '5px', fontWeight: '400' }}>
                        <select className='selectPending' style={{ marginBottom: '3px' }} value={groupBy} onChange={(e) => { getStatsData(e); setGroupBy(e.target.value !== 'Raw Data' ? e.target.value : undefined) }}>
                            <option value={undefined}>Raw Data</option>
                            <option value={'advertiser'}>Advertiser Wise</option>
                            <option value={'package_name'}>Package Name Wise</option>
                            <option value={'month_wise'}>Month Wise</option>
                            <option value={'manager'}>Advertiser Manager Wise</option>
                        </select>
                    </div>
                </div>
                <FilterRow
                    filtersList={filtersList}
                    filterData={filterData}
                    setFilterData={overrideSetFilterData}
                />
            </div>
            {
                !loader
                &&
                <TableModel
                    tableHeaders={tempTableHeaders}
                    // topRowStyles={{background:'rgb(230,230,230)'}}
                    serialOffset={offset}
                    // setTableHeaders={setTableHeaders}
                    tableValues={tableValues}
                    setTableValues={setTableValues}
                    style={{ background: 'rgb(245,245,245)' }}
                    tableValueRow={'rest'}
                    setTableValueRow={() => { console.log('test') }}
                    totalRow={totalRow.length > 0 ? totalRow : undefined}
                    setTotalRow={setTotalRow}
                    sortingFunction={sortingFunction}
                />
            }
            {
                loader
                &&
                <ShowLoaderComponent />
            }
            {
                (activeTab === 0 || activeTab) && tableValues.length > 0
                &&
                <div className='activeSummaryTabContainer'>
                    <div className='activeSummaryTabWrapper'>
                        <div className='activeSummaryHeaderRow'>
                            <div style={{ fontSize: '18px', fontWeight: '500' }}>
                                {monthComponent(tableValues[activeTab], { 'value': 'month' })}
                            </div>
                            <div style={{ width: '60px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Tooltip title={'Close'}>
                                    <Close sx={{ color: 'black' }} onClick={() => {
                                        setActiveTab(null)
                                    }} />
                                </Tooltip>
                            </div>
                        </div>
                        <div className='activeSummaryTabData'>
                            {summaryData()}
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default RealTimeValidations