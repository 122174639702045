import React, { useEffect, useState } from "react";
import arrowdown from "../../../billing/insightsManik/assets/arrow-down.svg";
import Select, { components } from "react-select";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import resetMain from "../../../billing/insightsManik/assets/resetMain.svg";
import "../../../billing/insightsManik/insightsCss.css";
import CustomSelect from "../../insightsManik/components/CustomSelect.jsx";
import CustomTable from "./PaymentTable.jsx";
import table from "../../../billing/insightsManik/assets/table.svg";
import graph from "../../../billing/insightsManik/assets/graph.svg";
import PercentageDiv from "../../insightsManik/components/Percentagediv.jsx";
import inactiveReset from "../../../billing/insightsManik/assets/inactiveReset.svg";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineController,
  BarElement,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import YearlyPaymentSynopsis from "./YearlyPaymentSynopsis.jsx";
import MonthlyPaymentSynopsis2 from "./MonthlyPaymentSynopsis2.jsx";
import { AdvertiserIcon, Cross, AppIcon, SpiralIcon, AdvertiserManagerIcon, MoneyIcon } from '../../../../data/icons.js';
ChartJS.register(
  CategoryScale,
  LinearScale,
  LineController,
  BarElement,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);
var myChart = null;
function useForceUpdate() {
  const [value, setValue] = useState(0);
  if (false) {
    console.log(value);
  }
  return () => setValue((value) => value + 1);
}
const PaymentTopAdvertisers = ({
  advapp,
  setAdvapp,
  chartData,
  tableData2,
  setTableData2,
  selectedRev,
  initdate,
  finaldate,
  advertiser,
  sortOrder,
  setSortOrder,
  sortColumn,
  setSortColumn,
  advData,
  reset,
  setReset,
  appPackageMap,
  manager,
  packageName,
  managerString,
  setAdvertiser,
  setPackageName,
  setManager,
  setSelectedRev,
  setFilterData,
  filterData,
  packageString,
  advertiserString
}) => {
  const refreshComponent = useForceUpdate();
  const [message, setMessage] = useState("table");
  const [open, setOpen] = useState(false);
  const [tableHead, setTableHead] = useState("Payment Received");
  const [sorHead, setSorHead] = useState("");
  const [advcat, setAdvcat] = useState("");
  const [pack, setPack] = useState("");
  const [tableData, setTableData] = useState();
  const [advName, setAdvName] = useState("");
  const [initial, setInitial] = useState(true);

  function getMonthAbbreviationFromDate(dateString) {
    const date = new Date(dateString + "-01");
    const options = { month: "short", year: "2-digit" };
    const monthAbbreviation = date.toLocaleDateString("en-US", options);
    return monthAbbreviation;
  }

  const begMonth = getMonthAbbreviationFromDate(initdate);
  const endMonth = getMonthAbbreviationFromDate(finaldate);

  const selectedData =
    selectedRev === "Panel Revenue"
      ? tableData2.panel_revenue
      : tableData2.manual_revenue;
  const handleOpen = (item, sortLabel) => {
    // return () => {
    if (typeof item === "number") {
      setAdvcat(item);
      setPack("");
    } else {
      setPack(item);
      setAdvcat("");
    }
    if (typeof sortLabel === "number") {
      if (sortLabel === 2) setTableHead("Approved Revenue");
      else if (sortLabel === 3) setTableHead("Payment Received");
      else if (sortLabel === 4) setTableHead("Payment Received %");
      else if (sortLabel === 5) setTableHead("Payment Pending");
    }
    setOpen(true);
    // };
  };

  const resetTable = () => {
    // setReset(!reset);
    var tableHead;
    if (advapp === 'Top Advertisers (Top 10)' || advapp === 'Top Apps (Top 10)') {
      setTableHead('Payment Received');
      tableHead = 'Payment Received';
    }
    else {
      setTableHead("Payment Payment");
      tableHead = "Payment Pending";
    }
    const columnIndex = columnHeadings.indexOf(tableHead);
    const sortOrder = -1;
    setTableData2((tableData) => {
      tableData.manual_revenue.sort(
        (a, b) => sortOrder * (a[columnIndex] - b[columnIndex])
      );
      tableData.panel_revenue.sort(
        (a, b) => sortOrder * (a[columnIndex] - b[columnIndex])
      );
      return tableData;
    });

    refreshComponent(123);
  };
  const handleClose = () => {
    setOpen(false);
    setInitial(true);
  };
  var max1 = 0;
  for (const key in chartData) {
    if (chartData.hasOwnProperty(key)) {
      if (key != "month") {
        const array = chartData[key];
        for (let i = 0; i < array.length; i++) {
          if (array[i] > max1) max1 = array[i];
        }
      }
    }
  }
  var columnHeadings;
  if (advapp === 'Top Advertisers (Top 10)' ||
    advapp === 'Pending Advertisers (Top 10)') {
    columnHeadings = [
      "Advertisers",
      "Approved Revenue",
      "Payment Received",
      "Payment Received %",
      "Payment Pending",
      "Payment Pending %"
    ];
  }
  else if (advapp === 'Top Apps (Top 10)'
    || advapp === 'Pending Apps (Top 10)') {
    columnHeadings = [
      "App Name",
      "Approved Revenue",
      "Payment Received",
      "Payment Received %",
      "Payment Pending",
      "Payment Pending %"
    ];
  }
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "80%",
    bgcolor: "background.paper",
    // border: "2px solid #000",
    overflowY: "auto",
    boxShadow: 24,
    p: 4,
  };
  var list;

  useEffect(() => {
    try {
      const canvas = document.getElementById("chart1");
      const ctx = canvas.getContext("2d");
      var gradient1 = ctx.createLinearGradient(0, 0, 0, 400)
      gradient1.addColorStop(0, "#8DA665")
      gradient1.addColorStop(0.5, "#A9C779")
      var gradient5 = ctx.createLinearGradient(0, 0, 0, 400)
      gradient5.addColorStop(0, "#639E8C")
      gradient5.addColorStop(0.5, "#6FC0A8")
      var gradient6 = ctx.createLinearGradient(0, 0, 0, 400)
      gradient6.addColorStop(0, "#DF5B63")
      gradient6.addColorStop(0.5, "#EB8A90")

      if (myChart) {
        myChart.clear();
        myChart.destroy();
      }

      myChart = new ChartJS(ctx, {
        type: "bar",
        data: {
          labels: chartData.month,
          datasets: [
            {
              type: "line",
              label: "Payment Pending %",
              data: chartData.payment_pending_percentage,
              borderColor: "#E01A4F",
              borderWidth: 2,
              backgroundColor: "#E01A4F",
              yAxisID: "A",
              fill: false,
            },
            {
              type: "bar",
              label: "Approved Revenue",
              data: chartData.validation_revenue,
              backgroundColor: gradient1,
              yAxisID: "B",
              borderRadius: 2,
            },
            {
              type: "bar",
              label: "Payment Received",
              data: chartData.payment_received,
              backgroundColor: gradient5,
              yAxisID: "B",
              borderRadius: 2,
            },
            {
              type: "bar",
              label: "Payment Pending",
              data: chartData.payment_pending,
              backgroundColor: gradient6,
              yAxisID: "B",
              borderRadius: 2,
            },
          ],
        },
        options: {
          onClick: function (c, i) {
            var e = i[0];
            var x_value = this.data.labels[e.index];
            const sortLabel = e.datasetIndex;
            if (advapp.endsWith("Apps (Top 10) ") || advapp.endsWith("( Apps Top 10 )")) {
              handleOpen(x_value, sortLabel);
            }
            else {
              setAdvName(x_value);
              handleOpen(parseInt(swappedMap.get(x_value)), sortLabel);
            }
          },
          maintainAspectRatio: false,
          layout: {
            padding: {
              left: 20,
              right: 20,
              top: 20,
              bottom: 10,
            },
          },
          responsive: true,
          scales: {
            x: {
              grid: {
                display: false,
              },
              ticks: {
                color: "#252525",
                font: {
                  // family: "kanit,sans-serif",
                  size: 16,
                  weight: "normal",
                },
              },
            },
            y: {
              display: false,
            },
            A: {
              display: false,
              grid: {
                display: false,
              },
              min: 0,
              max: 150,
              ticks: {
                stepSize: 10,
              },
            },
            B: {
              grid: {
                display: false,
              },

              min: 0,
              max: Math.round(max1),
              ticks: {
                color: "#252525",
                callback: function (label, index, labels) {
                  if (label == 0) return label;
                  else if (label < 100000)
                    return Math.round(label / 1000) + "k";
                  else return Math.round(label / 100000) + "L";
                },
                font: {
                  // family: "kanit,sans-serif",
                  size: 14,
                  weight: "normal",
                },
                stepSize: Math.round(max1 / 3),
              },
            },
          },
          elements: {
            bar: {
              borderWidth: 0,
            },
          },
          plugins: {
            tooltip: {
              backgroundColor: "#f0f0f0",
              titleColor: "#252525",
              borderWidth: 1,
              borderColor: "",
              bodyColor: "#252525",
            },
            legend: {
              fullSize: false,
              position: "bottom",
              align: "center",
              labels: {
                color: "#252525",
                font: {
                  // family: "kanit, sans-serif",
                  size: 16,
                  weight: 400,
                },
                useBorderRadius: true,
                borderRadius: 5,
              },
            },
            datalabels: {
              display: false,
            },
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  }, [chartData, message]);

  const func = () => {
    setMessage("graph");
  };

  const func2 = () => {
    setMessage("table");
  };

  const customStyles = {
    container: (base) => ({
      ...base,
      flexGrow: "1",
      margin: "0 1px",
      height: "56px",
      position: "relative",
    }),
    groupHeading: (base) => ({
      color: '#898989',
      fontSize: '0.9rem',
      paddingLeft: '0.4rem',
      // fontFamily:'kanit,sans-serif'
    }),
    control: (base) => ({
      ...base,
      justifyContent: "center",
      alignItems: "center",
      border: 0,
      boxShadow: "none",
      backgroundColor: "#F5F5F5",
    }),
    valueContainer: (base) => ({
      ...base,
      flex: "unset",
      height: "60px",
      fontSize: "1.8rem",
    }),
    menu: (base) => ({
      ...base,
      position: "absolute",
      width: "15rem",
    }),
    placeholder: (base) => ({
      ...base,
      color: "#252525",
    }),
    option: (styles, { isFocused, isSelected }) => {
      return {
        ...styles,
        color: "#252525",
        backgroundColor: isSelected
          ? "#E0F1FB"
          : isFocused
            ? "#E0F1FB"
            : undefined,
        textAlign: "left",
        fontSize: "1.5rem",
      };
    },
  };

  const customStyles2 = {
    container: (base) => ({
      ...base,
      flexGrow: "1",
      margin: "0 1px",
      height: "40px",
      position: "relative",
    }),
    control: (base) => ({
      ...base,
      justifyContent: "center",
      alignItems: "center",
      border: 0,
      boxShadow: "none",
      backgroundColor: "transparent",
    }),
    valueContainer: (base) => ({
      ...base,
      flex: "unset",
      height: "40px",
      fontSize: "1.1rem",
      maxHeight: "3rem",
    }),
    menu: (base) => ({
      ...base,
      position: "absolute",
      width: "15rem",
    }),
    placeholder: (base) => ({
      ...base,
      color: "#252525",
      fontSize: "1.1rem",
    }),
    option: (styles, { isFocused, isSelected }) => {
      return {
        ...styles,
        color: "#252525",
        backgroundColor: isSelected
          ? "#E0F1FB"
          : isFocused
            ? "#E0F1FB"
            : undefined,
        textAlign: "left",
        fontSize: "1rem",
      };
    },
  };
  const swappedMap = new Map();
  for (let i = 0; i < advData.length; i++) {
    if (advData[i]) {
      swappedMap.set(advData[i].label.split(' - ')[0], advData[i].value);
    }
  }
  const IndicatorsContainer = (props) => {
    return <img className="icon1" src={arrowdown} alt="indicator" />;
  };

  const { ValueContainer, Placeholder } = components;

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };
  const CustomSingleValue = ({ children, data, ...props }) => {
    var customLabel;
    if (data.value === "Top Advertisers" || data.value === "Top Apps") {
      if (data.value === "Top Advertisers")
        customLabel = message == "graph" ? "Top Advertisers (Top 10)" : data.value;
      else
        customLabel = message == "graph" ? "Top Apps (Top 10)" : "Top Apps";
    }
    else if (data.value === "Advertisers")
      customLabel = message == "graph" ? "Pending Advertisers (Top 10)" : "Pending Advertisers"
    else if (data.value === "Apps")
      customLabel = message == "graph" ? "Pending Apps (Top 10)" : "Pending Apps";

    return (
      <components.SingleValue {...props}>{customLabel}</components.SingleValue>
    );
  };
  const CustomOption = (placeholder) => {
    return (props) => {
      if (placeholder === tableHead) {
        return (
          <components.Option {...props} style={{ textAlign: "left" }}>
            <div style={{ textAlign: "left" }}>
              <input type="radio" defaultChecked={sorHead === props.label} />
              <label>{props.label}</label>
            </div>
          </components.Option>
        );
      } else {
        return (
          <components.Option {...props} style={{ textAlign: "left" }}>
            <div style={{ textAlign: "left" }}>
              <input type="radio" defaultChecked={false} />
              <label>{props.label}</label>
            </div>
          </components.Option>
        );
      }
    };
  };
  var label = "Pending";
  const options = [
    {
      label: "Top",
      options: [
        { value: "Top Advertisers", label: "Advertisers" },

        { value: "Top Apps", label: "Apps" },
      ],
    },
    {
      label: label,
      options: [
        { value: "Advertisers", label: "Advertisers" },
        { value: "Apps", label: "Apps" },
      ],
    },
  ];
  const options2 = [
    { value: "ascending", label: "Sort in Ascending order" },
    { value: "descending", label: "Sort in Descending order" },
  ];
  function CustomSelect1(props) {
    const handleChange = (option) => {
      const label = options[1].options.some(
        (item) => item.label == option.value
      )
        ? "Pending " + option.label + " (Top 10)"
        : option.value + " (Top 10)";
        console.log(label)
      setAdvapp(label);
      if (option.value === 'Top Advertisers' || option.value === 'Top Apps')
        setTableHead('Payment Received')
      else
        setTableHead('Payment Pending')
    };
    const handleChange2 = (option) => {
      const columnIndex = columnHeadings.indexOf(props.placeholder);
      setTableHead(props.placeholder);
      if (option.value === "ascending") setSorHead("Sort in Ascending order");
      else setSorHead("Sort in Descending order");
      const sortOrder = option.value === "ascending" ? 1 : -1;
      setTableData2((tableData) => {
        tableData.manual_revenue.sort(
          (a, b) => sortOrder * (a[columnIndex] - b[columnIndex])
        );
        tableData.panel_revenue.sort(
          (a, b) => sortOrder * (a[columnIndex] - b[columnIndex])
        );
        return tableData;
      });
      refreshComponent(123);
    };

    return (
      <Select
        onChange={
          props.placeholder.endsWith("Revenue") ||
            props.placeholder.endsWith("%") || props.placeholder.endsWith("Received")
            || props.placeholder.endsWith("Pending")
            ? handleChange2
            : handleChange
        }
        img={props.img}
        placeholder={props.placeholder}
        options={props.options}
        isClearable={true}
        components={{
          IndicatorsContainer: IndicatorsContainer,
          ValueContainer: props.valueContainer || components.ValueContainer,
          Option: props.option || components.Option,
          SingleValue: props.singleValue || components.SingleValue,
        }}
        className="react-select-container"
        classNamePrefix="react-select"
        styles={props.styles}
        isSearchable={false}
        closeMenuOnSelect={false}
      />
    );
  }
  var addColumnOptions;
  if (selectedRev === "Verified Revenue") {
    addColumnOptions = [
      { value: 0, label: "Approved Revenue" },
      { value: 1, label: "Payment Received" },
      { value: 2, label: "Payment Received %" },
      { value: 3, label: "Payment Pending" },
    ];
  } else {
    addColumnOptions = [
      { value: 0, label: "Approved Revenue" },
      { value: 1, label: "Payment Received" },
      { value: 2, label: "Payment Received %" },
      { value: 3, label: "Payment Pending" },
    ];
  }
  const initiallySelected = [true,
    true,
    true,
    true,
    true,
    false,];
  const [selectedColumns, setSelectedColumns] = useState(initiallySelected);
  const [btnClick, setBtnClick] = useState(0);

  if (message == "graph") {
    return (
      <div className="containerManik">
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <div className="teamName">{pack == "" ? advName : pack}</div>
            <YearlyPaymentSynopsis
              initdate={initdate}
              finaldate={finaldate}
              selectedRev={selectedRev}
              advertiserString={advcat}
              packageString={pack}
              manager={manager}
              managerString={managerString}
              setAdvertiser={setAdvertiser}
              setPackageName={setPackageName}
              setManager={setManager}
              setSelectedRev={setSelectedRev}
              setFilterData={setFilterData}
              filterData={filterData}
              advertiser={advcat}
              packageName={packageName}
              advData={advData}
            />
            <MonthlyPaymentSynopsis2
              initdate={initdate}
              finaldate={finaldate}
              selectedRev={selectedRev}
              advertiserString={advcat}
              packageString={pack}
              setTableData={setTableData}
              tableData={tableData}
              managerString={managerString}
            />
            <div className="addcolumn2">
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <div
                style={{
                  // fontFamily: "kanit",
                  fontSize: "11px",
                  color: "#A5A5A5",

                  marginRight: "auto",
                  marginTop: "1.2rem"
                }}
              >
                *Data is shown according to the monthly range selected
              </div>
              <button
                onClick={() => {
                  setBtnClick(btnClick + 1);
                  setInitial(true);
                  setSelectedColumns(initiallySelected);
                }}
                style={{
                  // width: "9rem",
                  // height: "1.5rem",
                  // marginLeft: "1rem",
                  color: "#0E6BA8",
                  backgroundColor: "#FFFFFF",
                  border: "none",
                  // fontFamily: "kanit,sans-serif",
                  fontSize: "1rem",
                  borderRadius: "5px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={resetMain}
                    style={{
                      width: "1.3rem",
                      height: "1.4rem",
                    }}
                  />
                  {/* <div>Reset Table</div> */}
                </div>
              </button>
              <CustomSelect
                placeholder={"Edit Columns"}
                options={addColumnOptions}
                isCheckboxOption={true}
                isPlaceholderFixed={true}
                selectedColumns={selectedColumns}
                setSelectedColumns={setSelectedColumns}
                width="2rem"
                color="#FFFFFF"
                height="3rem"
                // bgcolor="#0E6BA8"
                optcolor="#E7F1F7"
                menu={false}
              />
            </div>
            <CustomTable
              name={"topAdvertiser"}
              key={btnClick}
              selectedRev={selectedRev}
              teamName="Advertisers"
              selectedColumns={selectedColumns}
              group={"month"}
              Initdate={initdate}
              Finaldate={finaldate}
              advertiser={advcat}
              packageName={pack}
              advData={advData}
              className="main_table_month"
              initial={initial}
              setInitial={setInitial}
              appPackageMap={appPackageMap}
              // sortMonth={tableHead}
              managerString={managerString}
              // setAdvapp={setAdvName}
              advertiserString={advertiserString}
              packageString={packageString}
            />
          </Box>
        </Modal>
        <div>
          <div className="title tablegraphimg_main">
            <div style={{ display: "flex" }}>
              <CustomSelect1
                placeholder={advapp}
                options={options}
                singleValue={CustomSingleValue}
                styles={customStyles}
              />
              {begMonth === endMonth ? (
                <span
                  style={{
                    marginLeft: "0.2rem",
                    marginTop: "0.5rem",
                    color: "#A5A5A5",
                  }}
                >
                  ({begMonth})*
                </span>
              ) : (
                <span
                  style={{
                    marginLeft: "0.2rem",
                    marginTop: "0.5rem",
                    color: "#A5A5A5",
                  }}
                >
                  ({begMonth}-{endMonth})*
                </span>
              )}
              {advertiser != "" || manager != "" || packageName != "" || selectedRev != "Verified Revenue" ? (
                <div style={{
                  background: "#D9D9D9", borderRadius: "42px",
                  display: "inline-flex", marginLeft: "5px"
                }}>
                  {advertiser != "" ?
                    <div className="crossContainer" onClick={() => {
                      setAdvertiser("")
                      setFilterData({ ...filterData, ["advertiser_id"]: "" })
                    }}>
                      <div className="cross"><Cross /></div>
                      <AdvertiserIcon />
                    </div> : <></>}
                  {selectedRev != "Verified Revenue" ?
                    <div className="crossContainer" onClick={() => {
                      setSelectedRev("Verified Revenue")
                      setFilterData({ ...filterData, ["selectedRev"]: { label: 'Verified Revenue', value: 'Verified Revenue' } })
                    }}>
                      <div className="cross"><Cross /></div>
                      <MoneyIcon />
                    </div> : <></>}
                  {manager != "" ?
                    <div className="crossContainer" onClick={() => {
                      setManager("")
                      setFilterData({ ...filterData, ["advertiser_manager_id"]: "" })
                    }}>
                      <div className="cross"><Cross /></div>
                      <AdvertiserManagerIcon />
                    </div> : <></>}
                  {packageName != "" ?
                    <div className="crossContainer" onClick={() => {
                      setPackageName("")
                      setFilterData({ ...filterData, ["package_name"]: "", ["appData"]: "" })
                    }}>
                      <div className="cross"><Cross /></div>
                      <AppIcon />
                    </div> : <></>}
                </div>) : (<></>)}
            </div>

            <div className="tabim" style={{ marginTop: "0.5rem" }}>
              <div
                className={
                  message === "graph" ? "selected" : "tablegraphingimage"
                }
              >
                <img
                  style={{ width: "1.2rem", height: "1.2rem" }}
                  src={graph}
                  onClick={func}
                />
              </div>
              <div className="tablegraphimg">
                <img
                  style={{ width: "1.2rem", height: "1.2rem" }}
                  id="tbl"
                  src={table}
                  onClick={func2}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              // fontFamily: "kanit",
              fontSize: "11px",
              color: "#A5A5A5",
              paddingLeft: "10px",
            }}
          >
            *Data is shown according to the monthly range selected
          </div>
          <div className="red-flag-advertisers">
            <canvas id="chart1"></canvas>
          </div>
        </div>
      </div>
    );
  } else {
    const accumulate_on_column = (index) =>
      selectedData.reduce((acc, item) => acc + item[index], 0);
    let [
      total_validation_revenue,
      total_payment_received,
      total_payment_pending
    ] = [1, 2, 4].map(accumulate_on_column);
    var x = advapp.split(" ");
    return (
      <div className="containerManik" style={{ padding: "0" }}>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <div className="teamName">{pack == "" ? advName : pack}</div>
            <YearlyPaymentSynopsis
              initdate={initdate}
              finaldate={finaldate}
              selectedRev={selectedRev}
              advertiserString={advcat}
              packageString={pack}
              manager={manager}
              managerString={managerString}
              setAdvertiser={setAdvertiser}
              setPackageName={setPackageName}
              setManager={setManager}
              setSelectedRev={setSelectedRev}
              setFilterData={setFilterData}
              filterData={filterData}
              advertiser={advcat}
              packageName={packageName}
              advData={advData}
            />
            <MonthlyPaymentSynopsis2
              initdate={initdate}
              finaldate={finaldate}
              selectedRev={selectedRev}
              advertiserString={advcat}
              packageString={pack}
              setTableData={setTableData}
              tableData={tableData}
              managerString={managerString}
            />
            <div className="addcolumn2">
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <div
                style={{
                  // fontFamily: "kanit",
                  fontSize: "11px",
                  color: "#A5A5A5",
                  marginRight: "auto",
                  marginTop: "1.2rem"
                }}
              >
                *Data is shown according to the monthly range selected
              </div>
              <button
                onClick={() => {
                  setBtnClick(btnClick + 1);
                  setInitial(true);
                  setSelectedColumns(initiallySelected);
                }}
                style={{
                  // width: "9rem",
                  // height: "1.5rem",
                  // marginLeft: "1rem",
                  color: "#0E6BA8",
                  backgroundColor: "#FFFFFF",
                  border: "none",
                  // fontFamily: "kanit,sans-serif",
                  fontSize: "1rem",
                  borderRadius: "5px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={resetMain}
                    style={{
                      width: "1.3rem",
                      height: "1.4rem",
                    }}
                  />
                  {/* <div>Reset Table</div> */}
                </div>
              </button>
              <CustomSelect
                placeholder={"Edit Columns"}
                options={addColumnOptions}
                isCheckboxOption={true}
                isPlaceholderFixed={true}
                selectedColumns={selectedColumns}
                setSelectedColumns={setSelectedColumns}
                width="2rem"
                color="#FFFFFF"
                height="3rem"
                // bgcolor="#0E6BA8"
                optcolor="#E7F1F7"
                menu={false}
              />
            </div>
            <CustomTable
              key={btnClick}
              name={"topAdvertiser"}
              selectedRev={selectedRev}
              teamName="Advertisers"
              selectedColumns={selectedColumns}
              group={"month"}
              Initdate={initdate}
              Finaldate={finaldate}
              advertiser={advcat}
              packageName={pack}
              advData={advData}
              className="main_table_month"
              initial={initial}
              setInitial={setInitial}
              appPackageMap={appPackageMap}
              // sortMonth={tableHead}
              managerString={managerString}
              advertiserString={advertiserString}
              packageString={packageString}
            />
          </Box>
        </Modal>

        <div className="title tablegraphimg_main">
          <div
            className="title"
            style={{ marginTop: "1rem", marginLeft: "1rem", display: "flex" }}
          >
            <CustomSelect1
              placeholder={advapp.substring(0, advapp.indexOf("Top 10") - 1).concat(advapp.substring(0, advapp.indexOf("Top 10") - 1).includes("(") ? " )" : "")}
              options={options}
              styles={customStyles}
              singleValue={CustomSingleValue}
            />
            {begMonth === endMonth ? (
              <span
                style={{
                  marginLeft: "0.2rem",
                  marginTop: "0.5rem",
                  color: "#A5A5A5",
                }}
              >
                ({begMonth})*
              </span>
            ) : (
              <span
                style={{
                  marginLeft: "0.2rem",
                  marginTop: "0.6rem",
                  color: "#A5A5A5",
                }}
              >
                ({begMonth}-{endMonth})*
              </span>
            )}
            {advertiser != "" || manager != "" || packageName != "" || selectedRev != "Verified Revenue" ? (
              <div style={{
                background: "#D9D9D9", borderRadius: "42px",
                display: "inline-flex", marginLeft: "5px"
              }}>
                {advertiser != "" ?
                  <div className="crossContainer" onClick={() => {
                    setAdvertiser("")
                    setFilterData({ ...filterData, ["advertiser_id"]: "" })
                  }}>
                    <div className="cross"><Cross /></div>
                    <AdvertiserIcon />
                  </div> : <></>}
                {selectedRev != "Verified Revenue" ?
                  <div className="crossContainer" onClick={() => {
                    setSelectedRev("Verified Revenue")
                    setFilterData({ ...filterData, ["selectedRev"]: { label: 'Verified Revenue', value: 'Verified Revenue' } })
                  }}>
                    <div className="cross"><Cross /></div>
                    <MoneyIcon />
                  </div> : <></>}
                {manager != "" ?
                  <div className="crossContainer" onClick={() => {
                    setManager("")
                    setFilterData({ ...filterData, ["advertiser_manager_id"]: "" })
                  }}>
                    <div className="cross"><Cross /></div>
                    <AdvertiserManagerIcon />
                  </div> : <></>}
                {packageName != "" ?
                  <div className="crossContainer" onClick={() => {
                    setPackageName("")
                    setFilterData({ ...filterData, ["package_name"]: "", ["appData"]: "" })
                  }}>
                    <div className="cross"><Cross /></div>
                    <AppIcon />
                  </div> : <></>}
              </div>) : (<></>)}
          </div>

          <div
            className="tabim"
            style={{ marginTop: "1.5rem", marginRight: "1rem" }}
          >
            <div className="tablegraphimg">
              <img
                style={{ width: "1.2rem", height: "1.2rem" }}
                src={tableHead == "" ? inactiveReset : resetMain}
                onClick={resetTable}
              />
              <img
                style={{ width: "1.2rem", height: "1.2rem" }}
                src={graph}
                onClick={func}
              />
            </div>
            <div
              className={
                message === "table" ? "selected" : "tablegraphingimage"
              }
            >
              <img
                style={{ width: "1.2rem", height: "1.2rem" }}
                src={table}
                onClick={func2}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            // fontFamily: "kanit",
            fontSize: "11px",
            color: "#A5A5A5",
            marginLeft: "1.6rem",
            marginBottom: "10px"
          }}
        >
          *Data is shown according to the monthly range selected
        </div>
        <div className="main_table1" style={{ overflow: "auto", maxHeight: "28rem" }}>
          <table className="tab" style={{ textAlign: "center" }}>
            <thead>
              <tr style={{ height: "3.6rem", position: "sticky", top: "0" }}>
                {columnHeadings.map((heading, index) =>
                  index === 0 ? (
                    <th style={{ textAlign: "left", paddingLeft: "1.5rem" }}>
                      {heading}
                    </th>
                  ) : (
                    <th style={{ backgroundColor: tableHead === columnHeadings[index] ? "#AFC4D6" : "#CFE1EE" }}>
                      <CustomSelect1
                        placeholder={heading}
                        styles={customStyles2}
                        options={options2}
                        option={CustomOption(heading)}
                        singleValue={CustomSingleValue}
                      // width={"10rem"}
                      />
                    </th>
                  )
                )}
              </tr>
            </thead>
            <tbody>
              {selectedData.map((item, index) => {
                return (
                  <tr key={index}>
                    {item.map((item, index) => {
                      if (index === 3 || index==5) {
                        if (tableHead === columnHeadings[index]) {
                          return (
                            <td
                              key={index}
                              className="centre"
                              style={{
                                minHeight: "100rem",
                                // backgroundColor: "rgba(255, 111, 97,0.1)",
                              }}
                            >
                              <PercentageDiv percentage={item.toFixed(2)} />
                            </td>
                          );
                        } else {
                          return (
                            <td key={index}>
                              <PercentageDiv percentage={item.toFixed(2)} />
                            </td>
                          );
                        }
                      }
                      if (index != 0) {
                        if (tableHead === columnHeadings[index]) {
                          return (
                            <td
                              style={{
                                minHeight: "100rem",
                                // backgroundColor: "rgba(255, 111, 97,0.1)",
                              }}
                              key={index}
                            >
                              {Number(Math.round(item)).toLocaleString("en-US")}
                            </td>
                          );
                        } else
                          return (
                            <td key={index}>
                              {" "}
                              {Number(Math.round(item)).toLocaleString("en-US")}
                            </td>
                          );
                      }
                      if (index == 0) {
                        if (advapp.endsWith("Apps (Top 10) ") || advapp.endsWith("( Apps Top 10 )")) {
                          return (
                            <td
                              style={{
                                paddingLeft: "0.6rem",
                                textAlign: "Left",
                                maxWidth:"5.5rem",
                              }}
                              onClick={() => handleOpen(appPackageMap.get(item), tableHead)}
                              key={index}
                            >
                              {item}
                            </td>
                          );
                        } else {
                          return (
                            <td
                              style={{
                                paddingLeft: "0.6rem",
                                textAlign: "Left",
                                maxWidth:"5.5rem",
                              }}
                              onClick={() => {
                                setAdvName(item);
                                handleOpen(parseInt(swappedMap.get(item)), tableHead);
                              }
                              }
                              key={index}
                            >
                              {item}
                            </td>
                          );
                        }
                      }
                    })}
                  </tr>
                );
              })}
              <tr className="last-row">
                <td style={{ textAlign: "center" }}>Total</td>
                <td>
                  {Number(Math.round(total_validation_revenue)).toLocaleString(
                    "en-US"
                  )}
                </td>
                <td>
                  {Number(Math.round(total_payment_received)).toLocaleString(
                    "en-US"
                  )}
                </td>
                <td
                  className="centre"
                  style={{
                    minHeight: "100rem",
                  }}
                >
                  <PercentageDiv percentage={Number(Math.round(total_payment_received / total_validation_revenue * 100)).toLocaleString(
                    "en-US")} />
                </td>
                <td>
                  {Number(Math.round(total_payment_pending)).toLocaleString(
                    "en-US"
                  )}
                </td>
                <td
                  className="centre"
                  style={{
                    minHeight: "100rem",
                  }}
                >
                  <PercentageDiv percentage={Number(Math.round(total_payment_pending / total_validation_revenue * 100)).toLocaleString(
                    "en-US")} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
};

export default PaymentTopAdvertisers;
