import React, { useEffect, useState } from 'react'
import { getThisWeek } from '../../components/dateRangePicker/dateRangePicker'
import FilterRow from '../../components/filterRow/filterRow'
import { Button, Pagination, Tooltip } from '@mui/material'
import * as Constants from '../../data/constants';
import axios from 'axios';
import { bytesToSize, getLocalStorageValue } from '../../util/util';
import TableModel from '../../components/tableModel/tablemodel';
import ShowLoaderComponent from '../../components/loader';
import { getFilterDict, updateUrlFilter } from './filterSearchAndSet';

const GoalStatsV4 = (props) => {
    var {firstDayOfThisWeek,lastDayOfThisWeek} = getThisWeek()

    var optionsAdvertiserList= props.optionsAdvertiserList
    var optionsManagersList= props.optionsManagersList
    var optionsAffiliateList= props.optionsAffiliateList

    const [loader,setLoader] = useState(false)
    const [limit, setLimit] = useState(100);
    const [page, setPage] = useState(1);
    const [offset, setOffset] = useState(0);
    const [totalResults, setTotalResults] = useState(0);
    const [tableValues,setTableValues] = useState([])
    const [loadingSeconds, setLoadingSeconds] = useState(0)
    const [responseSize, setResponseSize] = useState(0)

    var filtersList = [
        {label:'Date Range',value:'date_range',type:'daterangepicker'},
        {label:'Offer Id*',value:'offer_id',type:'textfield'},
        // {label:'Advertiser',value:'advertiser_id',type:'select',multiple:true,options:optionsAdvertiserList},
        // {label:'Affiliate',value:'affiliate_id',type:'select',multiple:true,options:optionsAffiliateList},
        // {label:'Conversion Status',value:'type',type:'select',multiple:true,options:[{label:'All',value:undefined},{label:'Approved',value:'approved'},{label:'Declined',value:'declined'}]},
        {label:'Goal Value',value:'goal_value',type:'textfield'},
    ]
    
    var defaultFilterData = {
        from_date:new Date(),
        end_date:new Date(),
        show_converted_stats:{label:'Yes',value:true}
    }
    
    const [filterData, setFilterData] = useState({...defaultFilterData,...getFilterDict(filtersList)})

    const getStatsData = async(e,manual_limit,manual_page) => {
        setLoader(true)
        setOffset((manual_limit ? manual_limit : limit)*(manual_page ? (manual_page-1) : (page-1)))
        var start_time = new Date().getTime()
        var from_date = filterData.from_date.toLocaleString('fr-CA',{year:'numeric',month:'numeric',day:'numeric'})
        var end_date = filterData.end_date.toLocaleString('fr-CA',{year:'numeric',month:'numeric',day:'numeric'})
        
        var filter_dict = {}
        for(var index in filtersList){
            var item = filtersList[index]
            if(item.type==='textfield'){
                if(filterData[item.value]){
                    filter_dict[item.value] = filterData[item.value]
                }
            }
            if(item.type==='select' && !item.isSingle){
                var value = ''
                for(var xIndex in filterData[item.value]){
                    var xItem = filterData[item.value][xIndex]
                    if(parseInt(xIndex)===filterData[item.value].length-1){
                        value+= xItem.value
                    }
                    else{
                        value+= xItem.value + ','
                    }
                }
                if(value || value===0){
                    filter_dict[item.value] = value
                }
            }
            if(item.type==='select' && item.isSingle){
                if(filterData[item.value] && filterData[item.value].value){
                    filter_dict[item.value] = filterData[item.value].value
                }
            }
            
        }
        var requestPage = manual_page ? manual_page : page
        var requestLimit = manual_limit ? manual_limit : limit

        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST2,Constants.API_USER_PATH,"statistics/goal"),
            headers: {
                "Content-Type":"application/json",
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params: {
                startDate:from_date,
                endDate:end_date,
                limit:requestLimit,
                page:requestPage,
                offer_id:item.offer_id,
                ...filter_dict
                }
        })
        .then(resp => {
            var tempValues = []
            var response = resp.data.result.data
            for(var goalIndex in response){
                var goalItem = response[goalIndex]
                var dict_ = {
                    'offer_id':item.offer_id,
                    'goal_value':goalIndex,
                    'goal_title':goalItem.goal_title,
                    'conversions':goalItem.conversions,
                    'declined':goalItem.declined,
                    'approved_payout':goalItem.payouts[0].approved_payout,
                    'approved_revenue':goalItem.payouts[0].approved_revenue,
                    'declined_payout':goalItem.payouts[0].declined_payout,
                    'declined_revenue':goalItem.payouts[0].declined_revenue,
                    'currency':goalItem.payouts[0].currency
                }
                tempValues.push(dict_)
            }
            
            setTableValues(tempValues)
            setTotalResults(resp.data.result.count)
            const resSize = +resp?.headers?.['content-length']
            setResponseSize(resSize)
        })
        .catch((error) => {
            if (error.response) {
                console.log(error.response.data);
                if(error.response.data.result){
                }
                else{
                }
                console.log(error.response.status);
                console.log(error.response.headers);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        })
        setLoader(false)
        var end_time = new Date().getTime()
        setLoadingSeconds((end_time-start_time)/1000)
    };

    const overrideSetFilterData = (value,filterItem) => {
        if(filterItem==='dateRangePicker'){
            setFilterData({...filterData,...value})
        }
        else{
            setFilterData({...filterData,[filterItem.value]:value})
        }
    }

    

    const dateComponent = (valueItem,item) => {
        return new Date(valueItem[item.value]).toLocaleString('en-US',{month:'short',day:'numeric',hour:'2-digit',minute:'2-digit',second:'2-digit'})
    }
    const statusComponent = (valueItem,item) => {
        var status = {
            'declined':{
                highlightColor :'#ffcccc',
                color :'#ff0000',
                label:'Declined'
            },
            'approved':{
                highlightColor :'#d2ffcc',
                color :'#0f8500',
                label:'Approved'
            }
        }
        var highlightColor = status[valueItem[item.value]].highlightColor
        var color = status[valueItem[item.value]].color
        var label = status[valueItem[item.value]].label
        return <div style={{color,fontWeight:500,background:highlightColor,padding:'5px',width:'max-content',borderRadius:'10px',border:'1px solid'+color}}>
            {label}
        </div>
    }

    const tooltipComponent = (valueItem,item) => {
        var value = valueItem[item.value]
        if(value || value === 0){
            return <Tooltip title={value}>
                {value}
            </Tooltip>
        }
        else{
            return '-'
        }
    }
    var tempTableHeaders = [
        // {show:true,label:'Offer',value:'offer_id',minWidth:40,width:80},
        {locked:true,label:'Goal Value',value:'goal_value',minWidth:40,width:80},
        {label:'Goal Title',value:'goal_title',minWidth:40,width:80,customComponent:tooltipComponent},
        {label:'Delivered QTY',value:'conversions',minWidth:40,width:80,customComponent:tooltipComponent},
        {label:'Declined QTY',value:'declined',minWidth:40,width:80,customComponent:tooltipComponent},
        {label:'Currency',value:'currency',minWidth:40,width:80,customComponent:tooltipComponent},
        {label:'Approved Payout',value:'approved_payout',minWidth:40,width:80,customComponent:tooltipComponent},
        {label:'Approved Revenue',value:'approved_revenue',minWidth:40,width:80,customComponent:tooltipComponent},
        {label:'Declined Payout',value:'declined_payout',minWidth:40,width:80,customComponent:tooltipComponent},
        {label:'Declined Revenue',value:'declined_revenue',minWidth:40,width:80,customComponent:tooltipComponent},
    ]
    return (
        <>
            <div style={{position:'sticky',left:0,width:'100%',zIndex:100}}>
            {!loader && <div style={{position:'absolute',fontSize:'13px',left:5,bottom:-45}}>Showing {offset+1} - {(offset+limit < totalResults) ? offset+limit : totalResults} out of {totalResults}</div>}
                {/* {!loader && <div style={{position:'absolute',fontSize:'10px',left:5,bottom:-45}}>Data Loaded in {loadingSeconds} sec {responseSize ? '('+bytesToSize(responseSize)+')' : ''}</div>} */}
                <div className='demographyRouterWrapper'>
                    <div className='demographyFilterRow'>
                        <div className='demographyFilterAddRow'>
                            <FilterRow 
                            onEnterFunction={()=>{getStatsData(0,limit,1)}}
                            filtersList={filtersList} filterData={filterData} setFilterData={overrideSetFilterData}/>
                        </div>
                        <div>
                            <Button 
                                onClick={()=>{getStatsData(); window.history.pushState('Statistics - Goals', 'Statistics - Goals', updateUrlFilter(filtersList,filterData));}}
                                sx={{...Constants.CONTAINEDBUTTON,textTransform:'capitalize',transform:'scale(0.8)',transformOrigin:'left'}}>
                                Search
                            </Button>
                            <Button sx={{...Constants.OUTLINEDBUTTON,textTransform:'capitalize',transform:'scale(0.8)',transformOrigin:'left'}}>Reset</Button>
                        </div>
                    </div>
                </div>
            </div>
            {loader
            &&
            <ShowLoaderComponent/>
            }
            {!loader
                &&
                typeof tableValues === 'object' && 
                tableValues.length>0 
                &&
                <TableModel
                    tableHeaders={tempTableHeaders}
                    topRowStyles={{background:'rgb(230,230,230)'}}
                    serialOffset={offset}
                    // setTableHeaders={setTableHeaders}
                    tableValues={tableValues}
                    setTableValues={setTableValues}
                    style={{background:'rgb(245,245,245)'}}
                    tableValueRow={'rest'}
                    setTableValueRow={()=>{console.log('test')}}
                    // totalRow={totalRow.length>0 ? totalRow : undefined}
                    // setTotalRow={setTotalRow}
                    // sortingFunction={sortingFunction}
                    // exportFunction={()=>{getRevenueData(null,null,null,null,null,true)}}
                    />
                }
            {!loader && totalResults/limit>1 && typeof tableValues === 'object' && tableValues.length>0 && 
                <div style={{display:'flex',alignItems:'center',justifyContent:"space-between",paddingLeft:'20px',paddingRight:'20px',paddingBottom:'20px',position:'sticky',bottom:0,left:0,background:'white',zIndex:1000}}>
                    <div>
                        <label style={{paddingRight:"5px",fontSize:'14px'}}>Limit</label>
                        <select value={limit} style={{outline:'none',border:'none'}} onChange={(e)=>{getStatsData(e,e.target.value,1);setLimit(parseInt(e.target.value));setPage(1);}}>
                            {[5,10,50,100,200,300,500].map((item,index)=>{
                                return <option key={index} value={item}>{item}</option>
                            })}
                        </select>
                    </div>
                    <Pagination count={Math.ceil(totalResults/limit)} page={page} onChange={(event,value)=>{getStatsData(event,limit,value);setPage(value);window.scrollTo({top: 0,left: 0,behavior: 'smooth'});}} />
                </div>
            }
        </>
    )
}

export default GoalStatsV4