import React, { useState } from 'react';
import axios from "axios";

import '../../../../css/pages/v2/demandoffers.css';
import { capitalize, getLocalStorageValue } from '../../../../util/util';
import * as Constants from '../../../../data/constants';
import { Button } from '@mui/material';
import { Store } from 'react-notifications-component';
import { NavLink } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

// import FetchOfferPopup from './fetchOffersPopup';
import ShowFetchOffersPopup from '../showFetchOffersPopup';
const EditAutomationV2 = () => {
    const [manualUseStatus, setManualUseStatus] = useState(false)
    const [platformSelected, setPlatformSelected] = useState("")
    const [advertiserSelected, setAdvertiserSelected] = useState("")
    const [demandSourceId, setDemandSourceId] = useState("")
    
    // const [showLoader, setShowLoader] = useState(false)
    
    const [showFetchedOffers, setShowFetchOffers] = useState(false)
    
    const [resetSyncRules, setResetSyncRules] = useState({})
    const [resetAutoAdd, setResetAutoAdd] = useState(0)
    
    const defaultSyncRulesDict = {
        "title": 2,
        "previewurl": 2,
        "trackingurl": 2,
        "description": 2,
        "kpi": 2,
        "impressionlink": 2,
        "categories": 1,
        "payouts": 2,
        "caps": 2,
        "targeting": 2,
        "creatives": 2,
        "status": 2
    }

    const [syncRulesDict, setSyncRulesDict] = useState({
        "title": 2,
        "previewurl": 2,
        "trackingurl": 2,
        "description": 2,
        "kpi": 2,
        "impressionlink": 2,
        "categories": 1,
        "payouts": 2,
        "caps": 2,
        "targeting": 2,
        "creatives": 2,
        "status": 2
    })
    
    const [offerManualAddType, setOfferManualAddType] = useState("private")
    const [offerAutoActionCheck, setOfferAutoActionCheck] = useState(0)
    const [offerAutoApplyCheck, setOfferAutoApplyCheck] = useState(0)
    
    const getSyncRules = async(demandsourceid) => {
        // setShowLoader(true);
        await axios({
        method: 'GET',
        url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.DEMANDSOURCE,"/syncrules/",demandsourceid,"/view"),
        headers:{
            Authorization:getLocalStorageValue('adminPanelLoginData').token,
            "Content-Type":"application/json",
        },
        data:{
            }
        })
        .then((resp)=>{
            setSyncRulesDict(resp.data.result);
            setResetSyncRules(resp.data.result)
        })
        .catch(function (error) {
            if (error.response) {
              // Request made and server responded
              console.log(error.response.status);
              console.log(error.response.headers);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
        
          });
    }

    const getAutoPull = async(demandsourceid) => {
        await axios({
        method: 'GET',
        url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.DEMANDSOURCE,"/autopull/",demandsourceid,"/view"),
        headers:{
            Authorization:getLocalStorageValue('adminPanelLoginData').token,
            "Content-Type":"application/json",
        },
        data:{
            }
        })
        .then((resp)=>{
            setOfferAutoActionCheck(resp.data.result.status)
            setOfferAutoApplyCheck(resp.data.result.autoapply_status)
            setResetAutoAdd(resp.data.result)
        })
        .catch(function (error) {
            if (error.response) {
              // Request made and server responded
              console.log(error.response.status);
              console.log(error.response.headers);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
        
          });
    }

    if(!manualUseStatus){
        setManualUseStatus(true);
        document.title = "Edit Automation Demand Source"
        var url = new URL(window.location.href)
        var demandsourceid = url.searchParams.get('id')
        var advertiser = url.searchParams.get('advertisername')
        var platform = url.searchParams.get('platformname')
        if(demandsourceid){
            setDemandSourceId(demandsourceid)
        }
        if(advertiser){
            setAdvertiserSelected(advertiser)
        }
        if(platform){
            setPlatformSelected(platform)
        }
        getSyncRules(demandsourceid)
        getAutoPull(demandsourceid)
    }

    const changeSyncRules = async() => {
        var returnStringSyncRules = {}
        if(resetSyncRules!==syncRulesDict){
            returnStringSyncRules = await axios({
            method: 'POST',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.DEMANDSOURCE,"/syncrules/change"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
                "Content-Type":"application/json",
            },
            data:{
                "source_id" : demandSourceId,
                "sync_rules" : syncRulesDict,
                }
            })
            .then((resp)=>{
                setResetSyncRules(syncRulesDict)
                return {data:resp.data.result}
            })
            .catch(function (error) {
                return{error}
            });
        }
        else{
            returnStringSyncRules = {warning:"Sync Rules"}
        }

        var returnStringAutoAdd = {}
        if(true){
            returnStringAutoAdd = await axios({
                method: 'POST',
                url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.DEMANDSOURCE,"/autopull/change"),
                headers:{
                    Authorization:getLocalStorageValue('adminPanelLoginData').token,
                    "Content-Type":"application/json",
                },
                data:{
                    "source_id" : demandSourceId,
                    "autopull_status" :  parseInt(offerAutoActionCheck),
                    "autoapply_status" : parseInt(offerAutoApplyCheck)
                    }
            })
            .then((resp)=>{
                setResetAutoAdd(offerAutoActionCheck)
                return {data:resp.data.result}
            })
            .catch(function (error) {
                return {error}
            });
        }
        else{
            returnStringAutoAdd = {warning:"Auto Add"}
        }

        var returnString = ""
        var returnStringType = ""

        if(!returnStringSyncRules.error && !returnStringAutoAdd.error && (returnStringAutoAdd.data || returnStringSyncRules.data)){
            returnStringType = "success"
            if(returnStringSyncRules.data){
                returnString+=returnStringSyncRules.data.split("successfully updated")[0]
            }
            if(returnStringSyncRules.data && returnStringAutoAdd.data){
                returnString+= "and "
            }
            if(returnStringAutoAdd.data){
                returnString+=returnStringAutoAdd.data.split("successfully updated")[0]
            }
            returnString += "successfully updated"
        } 
        else if(returnStringSyncRules.error || returnStringAutoAdd.error){
            returnStringType = "danger"
            
            if(returnStringSyncRules.error){
                if(returnStringSyncRules.error.response && returnStringSyncRules.error.response.data && returnStringSyncRules.error.response.data.result){
                    returnString+=returnStringSyncRules.error.response.data.result
                }
            }
            else if(returnStringAutoAdd.error){
                if(returnStringAutoAdd.error.response && returnStringAutoAdd.error.response.data && returnStringAutoAdd.error.response.data.result){
                    returnString+=returnStringAutoAdd.error.response.data.result
                }
            }
            else{
                returnString += "Some Error Occured"
            }
        }
        else{
            returnStringType = "warning"
            returnString= "No changes detected"
        }

        Store.addNotification({
            title: returnStringType==="success" ? 'Success' : returnStringType==='error' ? "Error" : 'Warning',
            message: returnString,
            type: returnStringType,
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 3000,
                onScreen: true
            }
        }); 
    }
    const [refreshApiLoader, setRefreshApiLoader] = useState(false)
    const manualRefreshOffersApi = async() => {
        setRefreshApiLoader(true);
        await axios({
        method: 'POST',
        url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,'manual/api/referesh'),
        headers:{
            Authorization:getLocalStorageValue('adminPanelLoginData').token,
            "Content-Type":"application/json",
        },
        data:{
            "platform_name": platformSelected,
            "server_name" : "testing",
            "advertiser_id": parseInt(advertiserSelected.split(')')[0].split("(")[1])
        }
        })
        .then((resp)=>{

            Store.addNotification({
                title: 'Success',
                message: resp.data.result,
                type: 'success',
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            }); 
        })
        .catch(function (error) {
            if (error.response) {

                Store.addNotification({
                    title: 'Error',
                    message: error.response.data.result,
                    type: 'danger',
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true
                    }
                }); 
              // Request made and server responded
              console.log(error.response.status);
              console.log(error.response.headers);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
        
          });
        setRefreshApiLoader(false);

    }

    // const showImage = (item) => {
    //     try{
    //         return <img src={require('../../../../img/platforms/'+item.toLowerCase()+'.png')} alt='' width={"100%"} height={["mocatech","seanear","youmi"].includes(item.toLowerCase()) ? "80%" : ""} ></img>   
    //     }
    //     catch{
    //         return <label style={{color:'grey'}}>{item ? item : ""}</label>
    //     }
    // }

    return (

        <div className='mainContainerV2' id="outside" style={{background:'rgb(254,254,254)'}}>
            {showFetchedOffers && <ShowFetchOffersPopup handleClose={()=>{setShowFetchOffers(false);}} offerAction={offerManualAddType}/>}

            {/* {showFetchedOffers && <FetchOfferPopup closePopup={()=>{setShowFetchOffers(false);}}/>} */}
            <div className='demandOffersTopContainerV2' style={{maxHeight:'auto',position:'sticky',top:0,background:'rgb(254,254,254)',zIndex:'1'}}>
                <div style={{fontSize:'15px',fontWeight:'600',cursor:'default',display:'flex'}}>
                    Advertiser - &nbsp;&nbsp;<div style={{fontSize:'15px',fontWeight:'400',cursor:'default'}}>{advertiserSelected}</div>
                </div>
                <div style={{fontSize:'15px',cursor:'default',display:'flex'}}>
                    <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                        <div style={{fontWeight:'600'}}>Platform - &nbsp;</div>
                        <div >{capitalize(platformSelected)}</div>
                        {/* <div style={{marginLeft:'10px',height:'30px',width:'50px',display:'flex',alignItems:'center'}}>
                            {showImage(platformSelected)}
                        </div> */}
                    </div>
                </div>
            </div>
            <div className='demandOfferAutomationEditWrapper' style={{marginBottom:'50px',minHeight:'65vh'}}>
                <div style={{display:'flex',flexWrap:'wrap',justifyContent:'space-around'}}>
                    <div className='demandSourceDataContainer' style={{minWidth:'48vw'}}>
                        <div className='demandSourceDataContainerHeading'>
                            Sync Rules
                        </div>
                        <div className='demandSourceDataContainerData' style={{overflow:'auto',paddingBottom:'20px'}}>
                            <div style={{display:'flex',justifyContent:'center'}}>
                                <div style={{display:'grid'}}>
                                    {Object.keys(syncRulesDict).sort().slice(0,Object.keys(syncRulesDict).length/2).map((keyItem,keyIndex)=>{
                                        return <div key={keyIndex} style={{marginLeft:'5%',marginTop:'5px'}}>
                                                <div style={{fontWeight:600}}>{capitalize(keyItem)}</div>
                                                <div style={{width:'70%',padding:'5px',display:'flex',position:'relative'}}>
                                                    <div style={{display:'flex',justifyContent:'space-between',color:localStorage.getItem('ambientColor'),boxShadow:'inset 0 0 4px 1px '+localStorage.getItem('ambientColor'),width:'80px',height:'25px',position:'absolute',background:'rgb(0,0,0,0.02)',marginLeft:(syncRulesDict[keyItem]===0 ? 160 : syncRulesDict[keyItem]===2 ? 80 : 0),transition:'all 0.3s linear'}}>
                                                        <div>.</div>
                                                        <div>.</div>
                                                    </div>
                                                    <div style={{transition:'all 0.3s linear',color:(syncRulesDict[keyItem]===1 ? localStorage.getItem('ambientColor') : 'grey'),cursor:'pointer',minWidth:'80px',height:'25px',display:'flex',justifyContent:'center',alignItems:'center'}} onClick={()=>{setSyncRulesDict({...syncRulesDict,[keyItem]:1})}}>First Sync</div>
                                                    <div style={{transition:'all 0.3s linear',color:(syncRulesDict[keyItem]===2 ? localStorage.getItem('ambientColor') : 'grey'),cursor:'pointer',minWidth:'80px',height:'25px',display:'flex',justifyContent:'center',alignItems:'center'}} onClick={()=>{setSyncRulesDict({...syncRulesDict,[keyItem]:2})}}>Always</div>
                                                    <div style={{transition:'all 0.3s linear',color:(syncRulesDict[keyItem]===0 ? localStorage.getItem('ambientColor') : 'grey'),cursor:'pointer',minWidth:'80px',height:'25px',display:'flex',justifyContent:'center',alignItems:'center'}} onClick={()=>{setSyncRulesDict({...syncRulesDict,[keyItem]:0})}}>Disable</div>
                                                </div>
                                            </div>
                                    })}
                                </div>
                                <div style={{display:'grid',paddingLeft:'6%'}}>
                                    {Object.keys(syncRulesDict).sort().slice(Object.keys(syncRulesDict).length/2,Object.keys(syncRulesDict).length).map((keyItem,keyIndex)=>{
                                        return <div key={keyIndex} style={{marginLeft:'5%',marginTop:'5px'}}>
                                                <div style={{fontWeight:600}}>{capitalize(keyItem)}</div>
                                                <div style={{width:'70%',padding:'5px',display:'flex',position:'relative'}}>
                                                    <div style={{display:'flex',justifyContent:'space-between',color:localStorage.getItem('ambientColor'),boxShadow:'inset 0 0 4px 1px '+localStorage.getItem('ambientColor'),width:'80px',height:'25px',position:'absolute',background:'rgb(0,0,0,0.02)',marginLeft:(syncRulesDict[keyItem]===0 ? 160 : syncRulesDict[keyItem]===2 ? 80 : 0),transition:'all 0.3s linear'}}>
                                                        <div>.</div>
                                                        <div>.</div>
                                                    </div>
                                                    <div style={{transition:'all 0.3s linear',color:(syncRulesDict[keyItem]===1 ? localStorage.getItem('ambientColor') : 'grey'),cursor:'pointer',minWidth:'80px',height:'25px',display:'flex',justifyContent:'center',alignItems:'center'}} onClick={()=>{setSyncRulesDict({...syncRulesDict,[keyItem]:1})}}>First Sync</div>
                                                    <div style={{transition:'all 0.3s linear',color:(syncRulesDict[keyItem]===2 ? localStorage.getItem('ambientColor') : 'grey'),cursor:'pointer',minWidth:'80px',height:'25px',display:'flex',justifyContent:'center',alignItems:'center'}} onClick={()=>{setSyncRulesDict({...syncRulesDict,[keyItem]:2})}}>Always</div>
                                                    {["payouts","previewurl","status","title","trackingurl"].includes(keyItem) && 
                                                    <div style={{transition:'all 0.3s linear',color:'rgb(150,150,150)',textDecorationLine:'line-through',cursor:'not-allowed',minWidth:'80px',height:'25px',display:'flex',justifyContent:'center',alignItems:'center'}}>Disable</div>
                                                    }
                                                    {!["payouts","previewurl","status","title","trackingurl"].includes(keyItem) && 
                                                    <div style={{transition:'all 0.3s linear',color:(syncRulesDict[keyItem]===0 ? localStorage.getItem('ambientColor') : 'grey'),cursor:'pointer',minWidth:'80px',height:'25px',display:'flex',justifyContent:'center',alignItems:'center'}} onClick={()=>{setSyncRulesDict({...syncRulesDict,[keyItem]:0})}}>Disable</div>
                                                    }
                                                </div>
                                            </div>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>                 
                        <div className='demandSourceDataContainer' style={{minWidth:'24vw'}}>
                            <div className='demandSourceDataContainerHeading'>
                                Add Offers Manually
                            </div>
                            <div className='demandSourceDataContainerData'>
                                <div style={{width:'90%',padding:'10px',display:'flex',alignItems:'center'}}>
                                    <div style={{fontWeight:'600',fontSize:'13px',width:"30%",marginRight:'5%'}}>Set Offer Type</div>
                                    <select value={offerManualAddType} onChange={(e)=>{setOfferManualAddType(e.target.value)}} style={{fontSize:"11px",paddingLeft:"5px",width:"65%",height:"25px",border: "1px solid rgb(225,225,225)"}}>
                                        <option value={"private"} >{"Private"}</option>
                                        <option value={"public"} >{"Public"}</option>
                                        <option value={"protected"} >{"Request"}</option>
                                    </select>
                                </div>
                                <div style={{height:'40px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                    <Button sx={{...Constants.CONTAINEDBUTTON,height:'25px',fontSize:'12px',fontWeight:'500',textTransform:'none'}} onClick={(e)=>{setShowFetchOffers(true);}}>Get Offers</Button>
                                </div>
                            </div>
                        </div>
                                 
                        <div className='demandSourceDataContainer' style={{minWidth:'24vw'}}>
                            <div className='demandSourceDataContainerHeading'>
                                Enable Automatic Offers
                            </div>
                            <div className='demandSourceDataContainerData'>
                                <div style={{width:'90%',padding:'10px',display:'flex',alignItems:'center'}}>
                                    <div style={{fontWeight:'600',fontSize:'13px',width:"30%",marginRight:'5%'}}>Set Offer Type</div>
                                    <select disabled={!offerAutoActionCheck} value={offerAutoActionCheck} style={{fontSize:"11px",paddingLeft:"5px",width:"65%",height:"25px",border: "1px solid rgb(225,225,225)"}} onChange={(e)=>{setOfferAutoActionCheck(e.target.value);}}>
                                        <option value={3} >{"Private"}</option>
                                        <option value={2} >{"Request"}</option>
                                        <option value={1} >{"Public"}</option>
                                    </select>
                                </div>
                                <div style={{width:'90%',padding:'10px',display:'flex',alignItems:'center'}}>
                                    <div style={{fontWeight:'600',fontSize:'13px',width:"30%",marginRight:'5%'}}>Auto add</div>
                                    <div style={{width:'70%',padding:'5px',paddingLeft:'10px',display:'flex',position:'relative'}}>
                                        <div style={{marginLeft:offerAutoActionCheck ? 0 : 100,display:'flex',justifyContent:'space-between',color:(offerAutoActionCheck ? 'green' : 'red'),boxShadow:'inset 0 0 4px 1px '+(offerAutoActionCheck ? 'green' : 'red'),width:'100px',height:'25px',position:'absolute',background:'rgb(0,0,0,0.02)',transition:'all 0.3s linear'}}>
                                            <div>.</div>
                                            <div>.</div>
                                        </div>
                                        <div style={{transition:'all 0.3s linear',color:(offerAutoActionCheck ? 'green' : 'grey'),cursor:'pointer',minWidth:'100px',height:'25px',display:'flex',justifyContent:'center',alignItems:'center'}} onClick={()=>{setOfferAutoActionCheck(3)}}>Enabled</div>
                                        <div style={{transition:'all 0.3s linear',color:(offerAutoActionCheck===0 ? 'red' : 'grey'),cursor:'pointer',minWidth:'100px',height:'25px',display:'flex',justifyContent:'center',alignItems:'center'}} onClick={()=>{setOfferAutoActionCheck(0)}}>Disabled</div>
                                    </div>
                                </div>
                                {process.env.REACT_APP_OFFER_FETCH_API_V2_AUTO_APPLY
                                &&
                                <div style={{width:'90%',padding:'10px',display:'flex',alignItems:'center'}}>
                                    <div style={{fontWeight:'600',fontSize:'13px',width:"30%",marginRight:'5%'}}>Fetch Protected Offers</div>
                                    <div style={{width:'70%',padding:'5px',paddingLeft:'10px',display:'flex',position:'relative'}}>
                                        <div style={{marginLeft:offerAutoApplyCheck ? 0 : 100,display:'flex',justifyContent:'space-between',color:(offerAutoApplyCheck ? 'green' : 'red'),boxShadow:'inset 0 0 4px 1px '+(offerAutoApplyCheck ? 'green' : 'red'),width:'100px',height:'25px',position:'absolute',background:'rgb(0,0,0,0.02)',transition:'all 0.3s linear'}}>
                                            <div>.</div>
                                            <div>.</div>
                                        </div>
                                        <div style={{transition:'all 0.3s linear',color:(offerAutoApplyCheck ? 'green' : 'grey'),cursor:'pointer',minWidth:'100px',height:'25px',display:'flex',justifyContent:'center',alignItems:'center'}} onClick={()=>{setOfferAutoApplyCheck(1)}}>Enabled</div>
                                        <div style={{transition:'all 0.3s linear',color:(offerAutoApplyCheck===0 ? 'red' : 'grey'),cursor:'pointer',minWidth:'100px',height:'25px',display:'flex',justifyContent:'center',alignItems:'center'}} onClick={()=>{setOfferAutoApplyCheck(0)}}>Disabled</div>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                        {
                            process.env.REACT_APP_FEATURE_MANUAL_API_REFRESH_BUTTON==='true' &&
                                <div className='demandSourceDataContainer' style={{minWidth:'24vw'}}>
                                    <div className='demandSourceDataContainerHeading' style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                                        Refresh Offers
                                        <Button disabled={refreshApiLoader} sx={{...Constants.CONTAINEDBUTTON,height:'25px',fontSize:'12px',minWidth:'70px',fontWeight:'500',textTransform:'none'}} onClick={()=>{manualRefreshOffersApi()}}>
                                            {refreshApiLoader && 
                                            <CircularProgress sx={{color:'white'}} size={'12px'}/>}
                                            {
                                                !refreshApiLoader &&
                                                    'Refresh'
                                            }
                                        </Button> 
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>

            <div style={{height:'45px',position:'sticky',bottom:0,background:'rgb(253,253,253)',borderTop:'1px solid rgb(240,240,240)',paddingTop:'5px',paddingBottom:'20px',display:'flex',alignItems:'center',justifyContent:'center'}}>
                <Button sx={{...Constants.CONTAINEDBUTTON,height:'35px',fontSize:'12px',fontWeight:'500',textTransform:'none'}} onClick={()=>{changeSyncRules();}}>Save</Button> 
                <Button sx={{...Constants.OUTLINEDBUTTON,height:'35px',fontSize:'12px',fontWeight:'500',textTransform:'none',marginLeft:'20px'}} onClick={(e)=>{setSyncRulesDict(defaultSyncRulesDict);setOfferAutoActionCheck(resetAutoAdd);}}>Reset</Button> 
                <NavLink to={'/demandsource/management/'} style={{textDecoration:'none'}}><Button sx={{...Constants.CONTAINEDBUTTON,height:'35px',fontSize:'12px',fontWeight:'500',textTransform:'none',marginLeft:'20px'}}>Cancel</Button> </NavLink>
            </div>
            
        </div>
    )
}

export default EditAutomationV2;