import React, { useState } from 'react';
import axios from 'axios';

import '../css/login.css';
import TextField from '@mui/material/TextField';
// import companyLogo from "../img/company_logo.png";
import * as Constants from '../data/constants';

import { capitalize, getLocalStorageValue, isSuperUser, setLocalStorageValue, sidebartabAllowed } from '../util/util';
// import loginpage from '../img/loginpage.png';
import loginDoodle from '../img/loginDoodle.png';
import { CircularProgress } from '@mui/material';
import { sidebarTabs } from '../data/sidebarTabs';

export default function Login(props) {
    document.title = "Login - Admin"

    const [error, setError] = React.useState({data:"",status:""});
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [disableLoginButton, setDisableLoginButton] = useState();
        
    const permissionDict = {
        // permission_head:["permission"],
        "/statistics":["clicks","installs","aggrigatedstats","declinedpostbackevents","declinedpostbacks","trafficbackpartition"],
        "/offers":["offers","offerapprovalrequest","offerdeclined"],
        "/advertiser":["advertiser"],
        "/affiliates":["affiliate","affiliateapikey","localpostback","globalpostback"],
        "/demandsource":["offersync","demandsource","offersfetched"],
        "/account":["employee","group","user"],
        "/exportData":["exportstats"],
        // others_head:[,"others_head"]
    }

    async function loginUser(credentials) {
        setDisableLoginButton(true)
        return await axios({
            method: 'POST',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.AFFILIATE_LOGIN),
            headers: {
                'Content-Type': "application/json",
            },
            data: {
                "email": credentials.username,
                "password": credentials.password,
                "device_id": "1234"
                }
        })
        .then(async(resp) => {
            var currency = await axios({
                method: 'GET',
                url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"currency/min/amount/view"),
                headers:{
                    Authorization:"Token "+resp.data.token
                },
            }
            )
            .then((resp2)=>{
                if(!Object.keys(resp2.data.result).includes('USD')){
                    resp2.data.result['USD'] = 0
                }
                return resp2.data.result;
            })
            .catch(err=>{
                return {}
            })
            var paymentOptions = await axios({
                method: 'GET',
                url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"payment/options/view"),
                headers:{
                    Authorization:"Token "+resp.data.token
                },
            }
            )
            .then((resp2)=>{
                return resp2.data.result;
            })
            .catch(err => {
                return []
            })
            var panelSettings={currency,paymentOptions}
            return await axios({
                method: 'GET',
                url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"employee","/",resp.data.user.id,"/permissions"),
                headers:{
                    Authorization:"Token "+resp.data.token
                },
            }
            )
            .then((resp2)=>{
                return {token:resp.data.token,aff_id:resp.data.user.id,panelSettings,user:{...resp.data.user,permissions:resp2.data.result}};
            })
            })
        .catch(error => {
            setDisableLoginButton(false)
            if (error.response) {
                console.log(error)
                setError({data:error.response.data,status:error.response.status})
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                setError({data:error.message,status:error.status})
                console.log('Error', error.message);
            }
            console.error(error);
        })
    }
    
    async function forgotPassword() {
        setDisableLoginButton(true)
        await axios({
            method: 'POST',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,'forgot/password/send/url'),
            headers: {
                'Content-Type': "application/json",
            },
            data: {
                "email": username,
                }
        })
        .then(async(resp) => {
            setDisableLoginButton(false);
            setError({data:{detail:resp.data.result},status:resp.status})

        })
        .catch(error => {
            setDisableLoginButton(false);
            if (error.response) {
                console.log(error)
                setError({data:{detail:error.response.data.result},status:error.response.status})
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                setError({data:error.message,status:error.status})
                console.log('Error', error.message);
            }
            console.error(error);
        })
        setDisableLoginButton(false);
    }

    const handleSubmit = async e => {
        e.preventDefault();
        var response = await loginUser({
        username,
        password
        });
        setDisableLoginButton(false);

        if (response){
            var allowedPages =[]
            if(process.env.REACT_APP_ALL_SUPERUSER==='True' && response.user){
                response.user['superuser'] = true
            }
            if(response.user.superuser===true){
                allowedPages = ['/dashboard','/advertiser','/affiliates','/statistics','/demandsource','/account','/exportData','/offers','/billing']
            }
            else{
                for(var perm_key in response.user.permissions){
                    if(perm_key.startsWith('page_')){
                        allowedPages.push(perm_key)
                    }
                    if(perm_key === 'page_automation' && process.env.REACT_APP_FEATURE_RECHECK_CONTINUE === 'true'){
                        allowedPages.push('page_automation')
                    }
                }
            }
            if (response.user.first_name){
                var user_name = response.user.first_name
                user_name = response.user.first_name
            }
            else if (response.user.employee_type === "general_manager"){
                user_name = "Admin"
            }
            else{
                user_name = "User"
            }
            // allowedPages = ['/dashboard','/advertiser','/affiliates','/statistics','/demandsource','/account','/exportData','/offers','/smartlinks','/billing']

            var userDetails = {
                allowedPages,
                token:"Token " + response.token,
                superuser: response.user.superuser,
                loginTime:new Date().getTime(),
                userdata:response.user,
                username:user_name,
                panelSettings:response.panelSettings
            }

            setLocalStorageValue('adminPanelLoginData',userDetails)

            localStorage.setItem('allowedPages',allowedPages);

            localStorage.setItem('adminloginTime',new Date().getTime());
            localStorage.setItem('username',user_name);
            if(isSuperUser()){
                window.location="/dashboard"
            }
            if (allowedPages.includes("/dashboard")){
                if (window.location.href.includes("login")){
                    window.location="/dashboard"
                }
            }
            else if(allowedPages.includes('page'+window.location.pathname.replaceAll('/','_'))){
                window.location=window.location.href
            }
            else if(sidebartabAllowed(window.location.pathname)){
                window.location=window.location.href
            }
            else{
                window.location="/"+allowedPages[0].replace('page_','').replaceAll('_','/')
            }
        }
    }

    const showImage = () => {
        try{
            return <img src={require('../img/companies/'+process.env.REACT_APP_COMPANY_NAME.replaceAll(" ","").toLowerCase()+'.png')} alt='' width='150px'></img>
        }
        catch{
            return <label>{process.env.REACT_APP_COMPANY_NAME}</label>
        }
    }
    const [tncCheckboxSelected, setTncCheckboxSelected] = useState(false)
    return(
        <div className='loginPage'>
          <div className="mainBox">
            <div className="leftSideWrapper">
                <div className='leftSideDetails' style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                    {/* <div style={{paddingTop:"20%",fontSize:'25px',fontWeight:'600'}}>
                        <div>{process.env.REACT_APP_COMPANY_NAME}</div>
                        <div style={{fontSize:'12px',fontWeight:'300',paddingTop:'10px',wordWrap:'break-word',display:'flex',wordBreak:'break-all'}}>
                            Help businesses grow and generate revenue by introducing them to us.
                        </div>
                    </div> */}
                    <img src={loginDoodle} style={{height:'70%',width:'80%'}} alt="" className='loginDoodle'/>
                </div>
            </div>
            <div className="rightSideWrapper">
                <div className="login-wrapper">
                    <div style={{marginBottom:'30px'}}>{showImage()}</div>
                    <div className="loginLabel">Admin - Login</div>
                    <form style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',width:'100%',paddingTop:'50px'}} onSubmit={handleSubmit}>
                        <div className="emailTextBox">
                            <TextField
                                fullWidth
                                required
                                id="standard-required"
                                label="Email"
                                variant="standard"
                                color="warning"
                                size="normal"
                                sx={{transform:'scale(0.8)'}}
                                onChange={e => setUserName(e.target.value)} 
                                />
                        </div>
                        <div className="passwordTextBox">
                        <TextField
                            fullWidth
                            required
                            id="standard-password-input"
                            label="Password"
                            type="password"
                            autoComplete="current-password"
                            variant="standard"
                            color="warning"
                            sx={{transform:'scale(0.8)'}}
                            onChange={e => setPassword(e.target.value)}
                            />
                        </div>
                        <div style={{display:'flex',width:'65%',alignItems:"center"}}>
                            <input id='tncCheckbox' type='checkbox' value={tncCheckboxSelected} onChange={(e)=>{setTncCheckboxSelected(e.target.checked);}}/>
                            <label htmlFor='tncCheckbox' style={{textAlign:'left',fontSize:'12px'}}>I agree to the <a href='https://appanalytics.in/termsandconditions.html' target='_blank' rel='noreferrer'>Terms and Conditions</a>.</label>
                        </div>
                        <div style={{marginTop:'20px',marginBottom:'-20px'}} className={error.data!=="" ? 'showError' : 'hideError'}>{error.data.detail ? capitalize(error.data.detail) : "."}</div>
                        <div style={{width:'100%',transform:'scale(0.8)'}}>
                            <button disabled={!tncCheckboxSelected} className="submitButton" type="submit">
                                {disableLoginButton && <CircularProgress sx={{color:'white'}} size={12}/>}
                                {!disableLoginButton && <div>Login</div>}
                            </button>
                        </div>
                        <div style={{fontSize:'13px',cursor:'pointer'}} onClick={forgotPassword}>
                            Forgot Password?
                        </div>
                    </form>
                </div>
        
            </div>
          </div>     
        </div>
    )
}

// Login.propTypes = {
// };