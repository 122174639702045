import React, { useEffect, useState } from "react";
import table from "../assets/table.svg";
import graph from "../assets/graph.svg";
import Slider from "./Slider";
import PercentageDiv from "./Percentagediv";
import Select, { components } from "react-select";
import arrowdown from "../assets/arrow-down.svg";
import CustomSelect from "./CustomSelect";
import CustomTable from "./Addtable.jsx";
import axios from "axios";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import reset from "../assets/reset2.svg";
import resetMain from "../assets/resetMain.svg";
import resetNew from "../assets/resetNew.svg";
import inactiveReset from "../assets/inactiveReset.svg";
import back from "../assets/back.svg";
import * as Constants from '../../../../data/constants';
import { AdvertiserIcon,Cross,TeamIcon,AppIcon,SpiralIcon,AdvertiserManagerIcon,MoneyIcon} from '../../../../data/icons';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineController,
  BarElement,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
} from "chart.js";
import "../insightsCss.css";
import { getLocalStorageValue } from "../util/util";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineController,
  BarElement,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);
var myChart = null;
function MonthlySynopsis({
  modTeam,
  initdate,
  finaldate,
  selectedRev,
  advertiser,
  packageName,
  manager,
  advData,
  advertiserString,
  packageString,
  managerString,
  appPackageMap,
  setAdvertiser,
  setPackageName,
  setTeam,
  setManager,
  setSelectedRev,
  setFilterData,
  filterData
}) {
  const [message, setMessage] = useState("graph");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [sortMonth, setSortMonth] = useState("");
  const [internalSort, setInternalSort] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [sorHead, setSorHead] = useState("");
  const [initial,setInitial]=useState(true);
  const [advapp,setAdvapp]=useState("");
  function getMonthAbbreviationFromDate(dateString) {
    const date = new Date(dateString + "-01"); // Assuming the day is always the 1st
    const options = { month: "short",year:"2-digit" };
    const monthAbbreviation = date.toLocaleDateString("en-US", options);
    return monthAbbreviation;
  }

  const begMonth = getMonthAbbreviationFromDate(initdate);
  const endMonth = getMonthAbbreviationFromDate(finaldate);
  console.log("iambest", begMonth, endMonth);

  var sortKey = "month";
  var sort = true;
  if (internalSort === "Verified Revenue") {
    console.log("helooooo");
    if (selectedRev === "Panel Revenue") {
      sortKey = "total_revenue";
    } else {
      sortKey = "conversions_verified";
    }
    sort = sortOrder;
  } else if (internalSort === "Approved Revenue") {
    sortKey = "manager_approved_revenue";
    sort = sortOrder;
  } else if (internalSort === "Validation %") {
    if (selectedRev === "Panel Revenue") {
      sortKey = "validation_percentage";
    } else {
      sortKey = "validation_percentage_manual";
    }
    sort = sortOrder;
  } else if (internalSort === "Pending Validation") {
    if (selectedRev === "Panel Revenue") {
      sortKey = "pending_revenue";
    } else {
      sortKey = "pending_revenue_manual";
    }
    sort = sortOrder;
  } else if (internalSort === "Deduction") {
    if (selectedRev === "Panel Revenue") {
      sortKey = "deduction_revenue";
    } else {
      sortKey = "deduction_revenue_manual";
    }
    sort = sortOrder;
  } else if (internalSort === "Reset") {
    sortKey = "month";
    sort = true;
  }
  const resetTable = () => {
    console.log(internalSort);
    setInternalSort("Reset");
  };
  var sortColourclass = {
    total_revenue: "Verified Revenue",
    conversions_verified: "Verified Revenue",
    validation_percentage: "Validation % ",
    validation_percentage_manual: "Validation %",
    pending_revenue: "Pending Validation",
    pending_revenue_manual: "Pending Validation",
    deduction_revenue: "Deduction",
    deduction_revenue_manual: "Deduction",
    manager_approved_revenue: "Approved Revenue",
  };

  const [open, setOpen] = useState(false);
  const handleOpen = (sortLabel, label) => {
    setSelectedMonth(label);
    if (sortLabel === 2) setSortMonth("Verified Revenue");
    else if (sortLabel === 3) setSortMonth("Approved Revenue");
    else if (sortLabel === 1) setSortMonth("Validated Percentage");
    else if (sortLabel === 4) setSortMonth("Deduction");
    else if (sortLabel === 5) setSortMonth("Pending Validation");
    setOpen(true);
  };
  const handleClose = () =>{ setOpen(false);
    setInitial(true);};
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "90%",
    bgcolor: "background.paper",
    border: "none",
    overflowY: "auto",
    boxShadow: 24,
    p: 4,
    padding: "10px",
  };

  const [chartData, setChartData] = useState({
    panel_revenue: {
      month: [],
      pending_revenue: [],
      deduction_revenue: [],
      validation_revenue: [],
      validation_percentage: [],
      panel_revenue: [],
    },
    manual_revenue: {
      month: [],
      pending_revenue: [],
      deduction_revenue: [],
      validation_revenue: [],
      validation_percentage: [],
      panel_revenue: [],
    },
  });
  const API_ENDPOINT =
  "".concat(Constants.PROTOCOL,Constants.HOST,"/api/admin/v2/invoice/summary");
  const params = {
    team: modTeam,
    fromMonth: initdate,
    toMonth: finaldate,
    total_row_required: true,
    group_by: "month",
    sorting_key: sortKey,
    sorting_order: sort,
  };
  if (advertiser != "" && packageName != "") {
    params.advertiser_id = advertiserString;
    params.package_name = packageString;
  } else if (advertiser != "") {
    params.advertiser_id = advertiserString;
  } else {
    params.package_name = packageString;
  }
  if (manager != "") {
    params.manager_id = managerString;
  }
  console.log("aka2",params);
  const token = getLocalStorageValue("adminPanelLoginData").token;
  const [tableData, setTableData] = useState({
    panel_revenue: [],
    manual_revenue: [],
  });
  useEffect(() => {
    axios
      .get(API_ENDPOINT, {
        params: params,
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((response) => {
        const chartData = {
          panel_revenue: {
            month: [],
            pending_revenue: [],
            deduction_revenue: [],
            validation_revenue: [],
            validation_percentage: [],
            panel_revenue: [],
          },
          manual_revenue: {
            month: [],
            pending_revenue: [],
            deduction_revenue: [],
            validation_revenue: [],
            validation_percentage: [],
            panel_revenue: [],
          },
        };
        const tableData = { panel_revenue: [], manual_revenue: [] };
        const data = response.data.result;
        for (let i = 0; i < data.length; i++) {
          chartData.panel_revenue.month.push(
            new Date(data[i].month).toLocaleDateString("en-US", {
              month: "short",year:"2-digit"
            })
          );
          chartData.panel_revenue.deduction_revenue.push(
            data[i].deduction_revenue
          );
          chartData.panel_revenue.pending_revenue.push(data[i].pending_revenue);
          chartData.panel_revenue.panel_revenue.push(data[i].total_revenue);
          chartData.panel_revenue.validation_percentage.push(
            data[i].validation_percentage
          );
          chartData.panel_revenue.validation_revenue.push(
            data[i].manager_approved_revenue
          );
        }
        for (let i = 0; i < data.length; i++) {
          chartData.manual_revenue.month.push(
            new Date(data[i].month).toLocaleDateString("en-US", {
              month: "short",year:"2-digit"
            })
          );
          chartData.manual_revenue.deduction_revenue.push(
            data[i].deduction_revenue_manual
          );
          chartData.manual_revenue.pending_revenue.push(
            data[i].pending_revenue_manual
          );
          chartData.manual_revenue.panel_revenue.push(
            data[i].conversions_verified
          );
          chartData.manual_revenue.validation_revenue.push(
            data[i].manager_approved_revenue
          );
          chartData.manual_revenue.validation_percentage.push(
            data[i].validation_percentage_manual
          );
        }
        setChartData(chartData);

        for (let i = 0; i < data.length; i++) {
          tableData.panel_revenue.push([
            new Date(data[i].month).toLocaleDateString("en-US", {
              month: "short",year:"2-digit"
            }),
            data[i].total_revenue,
            data[i].manager_approved_revenue,
            data[i].validation_percentage,
            data[i].deduction_revenue,
            data[i].pending_revenue,
          ]);

          tableData.manual_revenue.push([
            new Date(data[i].month).toLocaleDateString("en-US", {
              month: "short",year:"2-digit"
            }),
            data[i].conversions_verified,
            data[i].manager_approved_revenue,
            data[i].validation_percentage_manual,
            data[i].deduction_revenue_manual,
            data[i].pending_revenue_manual,
          ]);
        }
        setTableData(tableData);
      })
      .catch((error) => {
        setChartData({ panel_revenue: [], manual_revenue: [] })
        setTableData({ panel_revenue: [], manual_revenue: [] })
        console.error(error);
      });
  }, [
    initdate,
    finaldate,
    modTeam,
    internalSort,
    sortOrder,
    reset,
    advertiser,
    packageName,
    manager
  ]);

  useEffect(() => {
    try {
      var max1 = 0;
      const selectedChart =
        selectedRev === "Panel Revenue"
          ? chartData.panel_revenue
          : chartData.manual_revenue;
      for (const key in selectedChart) {
        if (selectedChart.hasOwnProperty(key)) {
          if (key != "month") {
            const array = selectedChart[key];
            for (let i = 0; i < array.length; i++) {
              if (array[i] > max1) max1 = array[i];
            }
          }
        }
      }
      const canvas = document.getElementById("chart2");
      const ctx = canvas.getContext("2d");

      var gradient1 = ctx.createLinearGradient(0,0,0,400)
      gradient1.addColorStop(0, "#8DA665")
      gradient1.addColorStop(0.3, "#A9C779")
      var gradient2 = ctx.createLinearGradient(0,0,0,400)
      gradient2.addColorStop(0, "#E6695F")
      gradient2.addColorStop(0.5, "#DE9B89")
      var gradient3 = ctx.createLinearGradient(0,0,0,400)
      gradient3.addColorStop(0, "#A89F91")
      gradient3.addColorStop(0.5, "#C4BBAC")
      var gradient4 = ctx.createLinearGradient(0,0,0,400)
      gradient4.addColorStop(0, "#2e5077")
      gradient4.addColorStop(0.5, "#4A80BD")
      if (myChart) {
        myChart.clear();
        myChart.destroy();
      }

      myChart = new ChartJS(ctx, {
        type: "bar",
        data: {
          labels: selectedChart.month,
          datasets: [
            {
              label: " ",
              data: [1, 1, 0, 0],
              backgroundColor: "rgba(0, 0, 0, 0)",
            },
            {
              type: "line",
              label: "Validation %",
              data: selectedChart.validation_percentage,
              borderColor: "#E01A4F",
              borderWidth: 2,
              backgroundColor: "#E01A4F",
              yAxisID: "A",
              fill: false,
            },
            {
              type: "bar",
              label:
                selectedRev === "Verified Revenue"
                  ? "Verified Revenue"
                  : "Panel Revenue",
              data: selectedChart.panel_revenue,
              backgroundColor: gradient4,
              yAxisID: "B",
              borderRadius: 2,
            },
            {
              type: "bar",
              label: "Approved Revenue",
              data: selectedChart.validation_revenue,
              backgroundColor: gradient1,
              yAxisID: "B",
              borderRadius: 2,
            },
            {
              type: "bar",
              label: "Deduction",
              data: selectedChart.deduction_revenue,
              backgroundColor: gradient2,
              yAxisID: "B",
              borderRadius: 2,
            },
            {
              type: "bar",
              label: "Pending Validation",
              data: selectedChart.pending_revenue,
              backgroundColor: gradient3,
              yAxisID: "B",
              borderRadius: 2,
            },
          ],
        },
        options: {
          onClick: function (c, i, chart) {
            var e = i[0];
            const label = chart.data.labels[i[0].index];
            const sortLabel = i[0].datasetIndex;
            handleOpen(sortLabel, label);
          },
          maintainAspectRatio: false,
          layout: {
            padding: {
              left: 20,
              right: 20,
              top: 20,
              bottom: 10,
            },
          },
          responsive: true,
          scales: {
            x: {
              grid: {
                display: false,
              },
              ticks: {
                color: "#252525",
                font: {
                  // family: "kanit,sans-serif",
                  size: 16,
                  weight: "normal",
                },
              },
            },
            y: {
              display: false,
            },
            A: {
              display: false,
              grid: {
                display: false,
              },
              min: 0,
              max: 150,
              ticks: {
                stepSize: 10,
              },
            },
            B: {
              grid: {
                display: false,
              },

              min: 0,
              max: Math.round(max1),
              ticks: {
                color: "#252525",
                callback: function (label, index, labels) {
                  if (label == 0) return label;
                  else if (label < 1000) return Math.round(label);
                  else if (label < 100000)
                    return Math.round(label / 1000) + "k";
                  else return Math.round(label / 100000) + "L";
                },
                font: {
                  // family: "kanit,sans-serif",
                  size: 14,
                  weight: "normal",
                },
                stepSize: Math.round(max1 / 3),
              },
            },
          },
          elements: {
            bar: {
              borderWidth: 0,
            },
          },
          plugins: {
            tooltip: {
              backgroundColor: "#f0f0f0",
              titleColor: "#252525",
              borderWidth: 1,
              borderColor: "",
              bodyColor: "#252525",
            },
            legend: {
              fullSize: false,
              position: "bottom",
              align: "center",
              labels: {
                color: "#252525",
                font: {
                  // family: "kanit, sans-serif",
                  size: 16,
                  weight: 400,
                },
                useBorderRadius: true,
                borderRadius: 5,
              },
            },
            datalabels: {
              display: false,
            },
          },
        },
      });
    canvas.onclick = function(evt){ 
      console.log(evt);
      var activePoints = myChart.getElementsAtEventForMode(evt,'nearest', { intersect: true },
      true);
      console.log(activePoints);
    }
    }
    catch (error) {
      console.log(error);
    }
  }, [chartData, message, selectedRev]);

  const func = () => {
    setMessage("graph");
  };

  const func2 = () => {
    setMessage("table");
  };
  const customStyles = {
    container: (base) => ({
      ...base,
      flexGrow: "1",
      margin: "0 1px",
      height: "40px",
      position: "relative",
    }),
    control: (base) => ({
      ...base,

      justifyContent: "center",
      alignItems: "center",
      border: 0,
      boxShadow: "none",
      backgroundColor: "transparent",
    }),
    valueContainer: (base) => ({
      ...base,
      flex: "unset",
      height: "60px",
      maxHeight: "40px",
    }),
    menu: (base) => ({
      ...base,
      position: "absolute",
      width: "15rem",
    }),
    placeholder: (base) => ({
      ...base,
      color: "#252525",
    }),
    option: (styles, { isFocused, isSelected }) => {
      return {
        ...styles,
        color: "#252525",
        backgroundColor: isSelected
          ? "#E0F1FB"
          : isFocused
          ? "#E0F1FB"
          : undefined,
        textAlign: "left",
        fontSize: "1rem",
      };
    },
  };

  const IndicatorsContainer = (props) => {
    return <img className="icon1" src={arrowdown} alt="indicator" />;
  };
  const { ValueContainer, Placeholder } = components;

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };
  const CustomSingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>{null}</components.SingleValue>
  );
  const CustomOption = (placeholder) => {
    return (props) => {
      if (placeholder === internalSort) {
        return (
          <components.Option {...props} style={{ textAlign: "left" }}>
            <div style={{ textAlign: "left" }}>
              <input type="radio" defaultChecked={sorHead === props.label} />
              <label>{props.label}</label>
            </div>
          </components.Option>
        );
      } else {
        return (
          <components.Option {...props} style={{ textAlign: "left" }}>
            <div style={{ textAlign: "left" }}>
              <input type="radio" defaultChecked={false} />
              <label>{props.label}</label>
            </div>
          </components.Option>
        );
      }
    };
  };

  const options2 = [
    { value: "ascending", label: "Sort in Ascending order" },
    { value: "descending", label: "Sort in Descending order" },
  ];

  function CustomSelect1(props) {
    const handleChange = (option) => {
      setInternalSort(props.placeholder);
      if (option.label === "Sort in Ascending order") {
        setSortOrder(true);
        setSorHead("Sort in Ascending order");
      } else {
        setSortOrder(false);
        setSorHead("Sort in Descending order");
      }
    };

    return (
      <Select
        onChange={handleChange}
        img={props.img}
        placeholder={props.placeholder}
        options={props.options}
        isClearable={true}
        components={{
          IndicatorsContainer: IndicatorsContainer,
          ValueContainer: props.valueContainer || components.ValueContainer,
          Option: props.option,
          SingleValue: props.singleValue || components.SingleValue,
        }}
        className="react-select-container"
        classNamePrefix="react-select"
        styles={customStyles}
        isSearchable={false}
        closeMenuOnSelect={false}
      />
    );
  }
  var addColumnOptions;
  if (selectedRev === "Verified Revenue") {
    addColumnOptions = [
      { value: 0, label: "Verified Revenue" },
      { value: 1, label: "Offers Validated" },
      { value: 2, label: "Approved Revenue" },
      { value: 3, label: "Validation %" },
      { value: 4, label: "Pending Validation" },
      { value: 5, label: "Deduction" },
      { value: 6, label: "Difference in Revenue" },
      { value: 7, label: "Panel Revenue Accuracy" },
      { value: 8, label: "Delivered Revenue" },
    ];
  } else {
    addColumnOptions = [
      { value: 0, label: "Panel Revenue" },
      { value: 1, label: "Offers Validated" },
      { value: 2, label: "Approved Revenue" },
      { value: 3, label: "Validation %" },
      { value: 4, label: "Pending Validation" },
      { value: 5, label: "Deduction" },
      { value: 6, label: "Difference in Revenue" },
      { value: 7, label: "Panel Revenue Accuracy" },
      { value: 8, label: "Delivered Revenue" },
    ];
  }
  const initiallySelected=[true,
    false,
    true,
    true,
    true,
    true,
    false,
    false,
    false,
    false,];
  const [selectedColumns, setSelectedColumns] = useState(initiallySelected);
  const columnHeadings = [
    "Advertisers",
    "Verified Revenue",
    "Approved Revenue",
    "Validation %",
    "Deduction",
    "Pending Validation",
  ];

  const [selectedHead, setSelectedHead] = useState("Advertisers");
  const [btnClick, setBtnClick] = useState(0);
  if (message === "graph") {
    return (
      <div className="containerManik">
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <div className="addcolumn">
              {initial?(
              <CustomSelect
                placeholder={
                  selectedHead === "Advertisers"
                    ? "Revenue Synopsis ( Advertisers ) "
                    : "Revenue Synopsis ( Apps ) "
                }
                options={[
                  { value: "Advertisers", label: "Advertisers" },
                  { value: "Apps", label: "Apps" },
                ]}
                selectedHead={selectedHead}
                setSelectedHead={setSelectedHead}
                width="22rem"
                fontFam={"Oswald,sans-serif"}
                fontSize={"1.6rem"}
                fontWeight={"400"}
                menu={true}
                textAlign={"left"}
              />):(<div style={{ fontFamily:"Oswald,sans-serif",
              fontSize:"1.6rem",
              fontWeight:"400"}}>
              <button
               style={{
              color: "#0E6BA8",
              backgroundColor: "#FFFFFF",
              border: "none",
              // fontFamily: "kanit,sans-serif",
              fontSize: "1rem",
              borderRadius: "5px",
            }}
              onClick={()=>setInitial(!initial)}>
            <img
                src={back}
                style={{
                  width: "1.3rem",
                  height: "1.4rem",
                  // marginRight: "0.4rem",
                }}
              />
              </button>
            {advapp}
            {selectedHead=="Advertisers"? "'s Apps":"'s Advertisers"}
            </div>)}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >

            <div className="monthHeadDiv">
              <div className="monthhead">{selectedMonth}</div>
            </div>
              <button
                onClick={() =>{ setBtnClick(btnClick + 1);
                setSelectedColumns(initiallySelected);
                setInitial(true);}}
                style={{
                  // width: "9rem",
                  // height: "1.5rem",
                  // marginLeft: "auto",
                  color: "#0E6BA8",
                  backgroundColor: "#FFFFFF",
                  border: "none",
                  // fontFamily: "kanit,sans-serif",
                  fontSize: "1rem",
                  borderRadius: "5px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={resetNew}
                    style={{
                      width: "1.3rem",
                      height: "1.4rem",
                    }}
                  />
                  {/* <div>Reset Table</div> */}
                </div>
              </button>

              <CustomSelect
                placeholder={"Edit Columns"}
                options={addColumnOptions}
                isCheckboxOption={true}
                isPlaceholderFixed={true}
                selectedColumns={selectedColumns}
                setSelectedColumns={setSelectedColumns}
                width="2rem"
                height="3rem"
                color="#FFFFFF"
                // bgcolor="#0E6BA8"
                optcolor="#E7F1F7"
                menu={false}
              />

            </div>

            <CustomTable
              key={btnClick}
              modTeam={modTeam}
              selectedRev={selectedRev}
              selectedColumns={selectedColumns}
              group={selectedHead}
              date={selectedMonth}
              sortMonth={sortMonth}
              advData={advData}
              initial={initial}
            setAdvapp={setAdvapp}
            setInitial={setInitial}
            appPackageMap={appPackageMap}
            managerString={managerString}
            advertiser={advertiser}
            packageName={packageName}
            advertiserString={advertiserString}
            packageString={packageString}
            />
          </Box>
        </Modal>
        <div className="title tablegraphimg_main">
          <div className="title" style={{ marginBottom: "1rem",paddingLeft:"10px" }}>
            Monthly Validation Synopsis{" "}
            {begMonth === endMonth ? (
              <span
                style={{
                  marginLeft: "0.2rem",
                  marginTop: "0.5rem",
                  color: "#A5A5A5",
                }}
              >
                ({begMonth})*
              </span>
            ) : (
              <span
                style={{
                  marginLeft: "0.2rem",
                  marginTop: "0.5rem",
                  color: "#A5A5A5",
                }}
              >
                ({begMonth}-{endMonth})*
              </span>
            )}
              {advertiser!="" || manager!="" || modTeam!="" || packageName!="" || selectedRev!="Verified Revenue"?(
          <div style={{background:"#D9D9D9",borderRadius:"42px",
        display:"inline-flex",marginLeft:"5px"}}>
              {advertiser!=""?
              <div className="crossContainer" onClick={()=>{setAdvertiser("")
              setFilterData({...filterData,["advertiser_id"]:""})}}>
                <div className="cross"><Cross/></div>
              <AdvertiserIcon/>
              </div>:<></>}
              {modTeam!=""?
              <div className="crossContainer" onClick={()=>{setTeam("")
              setFilterData({...filterData,["team"]:""})}}>
                <div className="cross"><Cross/></div>
              <TeamIcon/>
              </div>:<></>}
              {selectedRev!="Verified Revenue"?
              <div className="crossContainer" onClick={()=>{setSelectedRev("Verified Revenue")
              setFilterData({...filterData,["selectedRev"]:{label:'Verified Revenue',value:'Verified Revenue'}})}}>
                <div className="cross"><Cross/></div>
              <MoneyIcon/>
              </div>:<></>}
              {manager!=""?
              <div className="crossContainer" onClick={()=>{setManager("")
              setFilterData({...filterData,["advertiser_manager_id"]:""})}}>
                <div className="cross"><Cross/></div>
              <AdvertiserManagerIcon/>
              </div>:<></>}
              {packageName!=""?
              <div className="crossContainer" onClick={()=>{setPackageName("")
              setFilterData({...filterData,["package_name"]:"",["appData"]:""})}}>
                <div className="cross"><Cross/></div>
              <AppIcon/>
              </div>:<></>}
          </div>):(<></>)}
            <div className="title-info"
              // style={{
              //   // fontFamily: "kanit",
              //   fontSize: "11px",
              //   color: "#A5A5A5",
              // }}
            >
              *Data is shown according to the monthly range selected
            </div>
          </div>
          <div className="tabim">
            <div
              className={
                message === "graph" ? "selected" : "tablegraphingimage"
              }
            >
              <img
                style={{ width: "1.5rem", height: "1.2rem" }}
                src={graph}
                onClick={func}
              />
            </div>
            <div className="tablegraphimg">
              <img
                style={{ width: "1.5rem", height: "1.2rem" }}
                src={table}
                onClick={func2}
              />
            </div>
          </div>
        </div>
        <div className="monthly-synopsis">
          <canvas id="chart2"></canvas>
        </div>
      </div>
    );
  } else {
    const selectedTable =
      selectedRev === "Panel Revenue"
        ? tableData.panel_revenue
        : tableData.manual_revenue;
    const accumulate_on_column = (index) =>
      selectedTable.reduce((acc, item) => acc + item[index], 0);
    let [
      total_manual_revenue,
      total_validation_revenue,
      total_deducted_revenue,
      total_pending_revenue,
    ] = [1, 2, 4, 5].map(accumulate_on_column);

    return (
      <div className="containerManik" style={{padding:"0"}}>
       <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <div className="addcolumn">
              {initial?(
              <CustomSelect
                placeholder={
                  selectedHead === "Advertisers"
                    ? "Revenue Synopsis ( Advertisers ) "
                    : "Revenue Synopsis ( Apps ) "
                }
                options={[
                  { value: "Advertisers", label: "Advertisers" },
                  { value: "Apps", label: "Apps" },
                ]}
                selectedHead={selectedHead}
                setSelectedHead={setSelectedHead}
                width="22rem"
                fontFam={"Oswald,sans-serif"}
                fontSize={"1.6rem"}
                fontWeight={"400"}
                menu={true}
                textAlign={"left"}
              />):(
                <div style={{ fontFamily:"Oswald,sans-serif",
                fontSize:"1.6rem",
                fontWeight:"400"}}>
                <button
                 style={{
                color: "#0E6BA8",
                backgroundColor: "#FFFFFF",
                border: "none",
                // fontFamily: "kanit,sans-serif",
                fontSize: "1rem",
                borderRadius: "5px",
              }}
                onClick={()=>setInitial(!initial)}>
              <img
                  src={back}
                  style={{
                    width: "1.3rem",
                    height: "1.4rem",
                    // marginRight: "0.4rem",
                  }}
                />
                </button>
              {advapp}
              {selectedHead=="Advertisers"? "'s Apps":"'s Advertisers"}
              </div>
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >

            <div className="monthHeadDiv">
              <div className="monthhead">{selectedMonth}</div>
            </div>
              <button
                onClick={() => { setBtnClick(btnClick + 1);
                  setSelectedColumns(initiallySelected);
                  setInitial(true);}}
                style={{
                  // width: "9rem",
                  // height: "1.5rem",
                  // marginLeft: "auto",
                  color: "#0E6BA8",
                  backgroundColor: "#FFFFFF",
                  border: "none",
                  // fontFamily: "kanit,sans-serif",
                  fontSize: "1rem",
                  borderRadius: "5px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={resetNew}
                    style={{
                      width: "1.3rem",
                      height: "1.4rem",
                    }}
                  />
                  {/* <div>Reset Table</div> */}
                </div>
              </button>

              <CustomSelect
                placeholder={"Edit Columns"}
                options={addColumnOptions}
                isCheckboxOption={true}
                isPlaceholderFixed={true}
                selectedColumns={selectedColumns}
                setSelectedColumns={setSelectedColumns}
                width="2rem"
                height="3rem"
                color="#FFFFFF"
                // bgcolor="#0E6BA8"
                optcolor="#E7F1F7"
                menu={false}
              />

            </div>

            <CustomTable
              key={btnClick}
              modTeam={modTeam}
              selectedRev={selectedRev}
              selectedColumns={selectedColumns}
              group={selectedHead}
              date={selectedMonth}
              sortMonth={sortMonth}
              advData={advData}
              initial={initial}
            setAdvapp={setAdvapp}
            setInitial={setInitial}
            appPackageMap={appPackageMap}
            managerString={managerString}
            advertiser={advertiser}
            packageName={packageName}
            advertiserString={advertiserString}
            packageString={packageString}
            />
          </Box>
        </Modal>

      <div className="table">
        <div className="title tablegraphimg_main">
          <div
            className="title"
            style={{
              marginTop: "1rem",
              marginLeft: "1.5rem",
              marginBottom: "6px",
            }}
          >
            Monthly Validation Synopsis
            {begMonth === endMonth ? (
              <span
                style={{
                  marginLeft: "0.5rem",
                  marginTop: "0.5rem",
                  color: "#A5A5A5",
                }}
              >
                ({begMonth})*
              </span>
            ) : (
              <span
                style={{
                  marginLeft: "0.5rem",
                  marginTop: "0.5rem",
                  color: "#A5A5A5",
                }}
              >
                ({begMonth}-{endMonth})*
              </span>
            )}
               {advertiser!="" || manager!="" || modTeam!="" || packageName!="" || selectedRev!="Verified Revenue"?(
          <div style={{background:"#D9D9D9",borderRadius:"42px",
        display:"inline-flex",marginLeft:"5px"}}>
              {advertiser!=""?
              <div className="crossContainer" onClick={()=>{setAdvertiser("")
              setFilterData({...filterData,["advertiser_id"]:""})}}>
                <div className="cross"><Cross/></div>
              <AdvertiserIcon/>
              </div>:<></>}
              {modTeam!=""?
              <div className="crossContainer" onClick={()=>{setTeam("")
              setFilterData({...filterData,["team"]:""})}}>
                <div className="cross"><Cross/></div>
              <TeamIcon/>
              </div>:<></>}
              {selectedRev!="Verified Revenue"?
              <div className="crossContainer" onClick={()=>{setSelectedRev("Verified Revenue")
              setFilterData({...filterData,["selectedRev"]:{label:'Verified Revenue',value:'Verified Revenue'}})}}>
                <div className="cross"><Cross/></div>
              <MoneyIcon/>
              </div>:<></>}
              {manager!=""?
              <div className="crossContainer" onClick={()=>{setManager("")
              setFilterData({...filterData,["advertiser_manager_id"]:""})}}>
                <div className="cross"><Cross/></div>
              <AdvertiserManagerIcon/>
              </div>:<></>}
              {packageName!=""?
              <div className="crossContainer" onClick={()=>{setPackageName("")
              setFilterData({...filterData,["package_name"]:"",["appData"]:""})}}>
                <div className="cross"><Cross/></div>
              <AppIcon/>
              </div>:<></>}
          </div>):(<></>)}
            <div className="title-info"
              // style={{
              //   // fontFamily: "kanit",
              //   fontSize: "11px",
              //   color: "#A5A5A5",
              // }}
            >
              *Data is shown according to the monthly range selected
            </div>
          </div>

          <div
            className="tabim"
            style={{ marginTop: "1rem", marginRight: "1rem" }}
          >
            <div className="tablegraphimg">
              <img
                style={{ width: "1.5rem", height: "1.2rem" }}
                src={internalSort=="" || internalSort=="Reset" ? inactiveReset:resetNew}
                onClick={resetTable}
              />
              <img
                style={{ width: "1.5rem", height: "1.2rem" }}
                src={graph}
                onClick={func}
              />
            </div>
            <div
              className={
                message === "table" ? "selected" : "tablegraphingimage"
              }
            >
              <img
                style={{ width: "1.5rem", height: "1.2rem" }}
                src={table}
                onClick={func2}
              />
            </div>
          </div>
        </div>
        <div className="main_table1">
          <table className="tab" style={{ textAlign: "center" }}>
            <tr style={{ height:"3.6rem"}}>
              <th style={{ textAlign: "left", paddingLeft: "1.5rem" }}>
                Month
              </th>
              <th style={{backgroundColor: internalSort === "Verified Revenue" || internalSort==="Panel Revenue" ? "#AFC4D6":"#CFE1EE"}}>
                <CustomSelect1
                  placeholder={
                    selectedRev === "Verified Revenue"
                      ? "Verified Revenue"
                      : "Panel Revenue"
                  }
                  valueContainer={CustomValueContainer}
                  options={options2}
                  option={CustomOption("Verified Revenue")}
                  singleValue={CustomSingleValue}
                />
              </th>
              <th style={{backgroundColor: internalSort === "Approved Revenue"? "#AFC4D6":"#CFE1EE"}}>
                <CustomSelect1
                  placeholder={"Approved Revenue"}
                  valueContainer={CustomValueContainer}
                  options={options2}
                  option={CustomOption("Approved Revenue")}
                  singleValue={CustomSingleValue}
                />
              </th>
              <th style={{backgroundColor: internalSort === "Validation %"? "#AFC4D6":"#CFE1EE"}}>
                <CustomSelect1
                  placeholder={"Validation %"}
                  valueContainer={CustomValueContainer}
                  options={options2}
                  option={CustomOption("Validation %")}
                  singleValue={CustomSingleValue}
                />
              </th>
              <th style={{backgroundColor: internalSort === "Deduction"? "#AFC4D6":"#CFE1EE"}}>
                <CustomSelect1
                  placeholder={"Deduction"}
                  valueContainer={CustomValueContainer}
                  options={options2}
                  option={CustomOption("Deduction")}
                  singleValue={CustomSingleValue}
                />
              </th>
              <th style={{backgroundColor: internalSort === "Pending Validation"? "#AFC4D6":"#CFE1EE"}}>
                <CustomSelect1
                  placeholder={"Pending Validation"}
                  valueContainer={CustomValueContainer}
                  options={options2}
                  option={CustomOption("Pending Validation")}
                  singleValue={CustomSingleValue}
                />
              </th>
            </tr>
            {selectedTable.map((item, index) => {
              return (
                <tr key={index}>
                  {item.map((item, index) => {
                    if (index === 3) {
                      if (sortColourclass[sortKey] === columnHeadings[index]) {
                        return (
                          <td
                            key={index}
                            className="centre"
                            style={{
                              minHeight: "100rem",
                              // backgroundColor: "rgba(255, 111, 97,0.1)",
                            }}
                          >
                            <PercentageDiv percentage={item.toFixed(2)} />
                          </td>
                        );
                      } else {
                        return (
                          <td key={index}>
                            <PercentageDiv percentage={item.toFixed(2)} />
                          </td>
                        );
                      }
                    }
                    if (index != 0) {
                      if (sortColourclass[sortKey] === columnHeadings[index]) {
                        return (
                          <td
                            style={{
                              minHeight: "100rem",
                              // backgroundColor: "rgba(255, 111, 97,0.1)",
                            }}
                            key={index}
                          >
                            {Number(Math.round(item)).toLocaleString("en-US")}
                          </td>
                        );
                      } else
                        return (
                          <td key={index}>
                            {" "}
                            {Number(Math.round(item)).toLocaleString("en-US")}
                          </td>
                        );
                    }
                    if (index == 0)
                      return (
                        <td
                          style={{ textAlign: "Left" ,paddingRight:"0.6rem"}}
                          onClick={ ()=>
                             handleOpen(sortKey=="month" || sortColourclass[sortKey]=="Verified Revenue"? 2:
                          sortColourclass[sortKey]=="Approved Revenue"?3:
                          sortColourclass[sortKey]=="Validation %"?1:
                          sortColourclass[sortKey]=="Deduction"?4:
                          5,
                            item
                            )
                          }
                          className="first_col"
                          key={index}
                        >
                          {item}
                        </td>
                      );
                  })}
                </tr>
              );
            })}
            <tr className="last-row">
              <td style={{ textAlign: "center" }}>Total</td>
              <td>
                {" "}
                {Number(Math.round(total_manual_revenue)).toLocaleString(
                  "en-US"
                )}
              </td>
              <td>
                {Number(Math.round(total_validation_revenue)).toLocaleString(
                  "en-US"
                )}
              </td>
                <td
                className="centre"
                style={{
                  minHeight: "100rem",
                }}
              >
                <PercentageDiv percentage= {Number(Math.round(total_validation_revenue/total_manual_revenue*100)).toLocaleString(
      "en-US" )} />
                </td>
            
              <td>
                {Number(Math.round(total_deducted_revenue)).toLocaleString(
                  "en-US"
                )}
              </td>
              <td>
                {Number(Math.round(total_pending_revenue)).toLocaleString(
                  "en-US"
                )}
              </td>
            </tr>
          </table>
        </div>
      </div>
      </div>
    );
  }
}

export default MonthlySynopsis;
