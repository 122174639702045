import React, { useEffect ,useState} from 'react';
import Select, {components} from 'react-select'
import arrowdown from "../../../billing/insightsManik/assets/arrow-down.svg";
import axios from 'axios'
import {
  Chart as ChartJS,
  LineController,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
} from 'chart.js';
import "../../../billing/insightsManik/insightsCss.css";
import { getLocalStorageValue } from "../../insightsManik/util/util";
import * as Constants from '../../../../data/constants.js';

ChartJS.register(
  CategoryScale,
  LineController,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
);
var myChart = null;
function MonthlyPaymentSynopsis2(
  {
   initdate,finaldate,selectedRev,item,advertiser,
   packageName,manager,tableData,setTableData,packageString,advertiserString,managerString,isOffer}) {
  const [message, setMessage] = useState("graph");  
  function compareByDate(a, b) {
    const dateA = a.month;
    const dateB = b.month;
    if (dateA < dateB) return -1;
    if (dateA > dateB) return 1;
    return 0;
  }
  var group='month';
  function getMonthAbbreviationFromDate(dateString) {
    const date = new Date(dateString + "-01"); // Assuming the day is always the 1st
    const options = { month: "short",year:"2-digit" };
    const monthAbbreviation = date.toLocaleDateString("en-US", options);
    return monthAbbreviation;
  }

  const begMonth = getMonthAbbreviationFromDate(initdate);
  const endMonth = getMonthAbbreviationFromDate(finaldate);
  const [chartData, setChartData] = useState({
    panel_revenue: {
      month: [],
      validation_revenue: [],
      payment_received:[],
      payment_pending:[],
      payment_received_percentage:[]
    },
    manual_revenue: {
      month: [],
      validation_revenue: [],
      payment_received:[],
      payment_pending:[],
      payment_received_percentage:[]
    },
  });
   const API_ENDPOINT =!isOffer?
   "".concat(Constants.PROTOCOL,Constants.HOST,"/api/admin/v2/invoice/summary"):
   "".concat(Constants.PROTOCOL,Constants.HOST,"/api/admin/v1/invoice/adv-data");
   const params = {
     fromMonth: initdate,
     toMonth: finaldate,
     total_row_required: true,
     group_by: "month",
   };
   if(isOffer){
    params.offer_id=advertiserString;
  }
  else{
    if (advertiser != "" && packageName != "") {
      params.advertiser_id = advertiserString;
      params.package_name = packageString;
    } else if (advertiser != "") {
      params.advertiser_id = advertiserString;
    } else {
      params.package_name = packageString;
    }
  }
    if (manager != "") {
      params.manager_id = managerString;
    }

  const token = getLocalStorageValue('adminPanelLoginData').token;

  useEffect(()=>{
    axios
      .get(API_ENDPOINT, {
        params: params,
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((response) => {
        const chartData = {
          panel_revenue: {
            month: [],
            validation_revenue: [],
            payment_received:[],
            payment_pending:[],
            payment_received_percentage:[]
          },
          manual_revenue: {
            month: [],
            validation_revenue: [],
            payment_received:[],
            payment_pending:[],
            payment_received_percentage:[]
          },
        };

        const data = response.data.result;
        data.sort(compareByDate);
        for (let i = 0; i < data.length; i++) {
          chartData.panel_revenue.month.push(
            new Date(data[i].month).toLocaleDateString("en-US", {
              month: "short",year:"2-digit"
            })
          );
          chartData.panel_revenue.validation_revenue.push(
            data[i].manager_approved_revenue
          );
          chartData.panel_revenue.payment_pending.push(data[i].payment_received_pending);
          chartData.panel_revenue.payment_received.push(data[i].payment_received);
          chartData.panel_revenue.payment_received_percentage.push(data[i].payment_received==0?0:(data[i].payment_received/data[i].manager_approved_revenue)*100);
        }
        for (let i = 0; i < data.length; i++) {
          chartData.manual_revenue.month.push(
            new Date(data[i].month).toLocaleDateString("en-US", {
              month: "short",year:"2-digit"
            })
          );
          chartData.manual_revenue.validation_revenue.push(
            data[i].manager_approved_revenue
          );
          chartData.manual_revenue.payment_pending.push(data[i].payment_received_pending);
          chartData.manual_revenue.payment_received.push(data[i].payment_received);
          chartData.manual_revenue.payment_received_percentage.push(data[i].payment_received==0?0:(data[i].payment_received/data[i].manager_approved_revenue)*100);
        }
        setChartData(chartData);
      })
      .catch((error) => {
        console.error(error);
      });

  }, [initdate, finaldate]);
  
  useEffect(() => {
    
    try {
      const selectedChart = selectedRev === 'Panel Revenue' ? chartData.panel_revenue : chartData.manual_revenue;
      var max1 = 0;
      for (const key in selectedChart) {
        if (selectedChart.hasOwnProperty(key)) {
          if (key != "month") {
            const array = selectedChart[key];
            for (let i = 0; i < array.length; i++) {
              if (array[i] > max1) max1 = array[i];
            }
          }
        }
      }
      const canvas = document.getElementById('chart3');
      const ctx = canvas.getContext('2d');
      var gradient1 = ctx.createLinearGradient(0,0,0,400)
      gradient1.addColorStop(0, "#8DA665")
      gradient1.addColorStop(0.3, "#A9C779")
      var gradient5 = ctx.createLinearGradient(0,0,0,400)
      gradient5.addColorStop(0, "#639E8C")
      gradient5.addColorStop(0.5, "#6FC0A8")
      var gradient6 = ctx.createLinearGradient(0,0,0,400)
      gradient6.addColorStop(0, "#DF5B63")
      gradient6.addColorStop(0.5, "#EB8A90")
    
      if (myChart) {
        myChart.clear();
        myChart.destroy();
      }
    
      myChart = new ChartJS(ctx, {
        type: "bar",
        data: {
          labels: selectedChart.month,
          datasets: [
            {
                type: "line",
                label: "Payment Received %",
                data: selectedChart.payment_received_percentage,
                borderColor: "#E01A4F",
                borderWidth: 2,
                backgroundColor: "#E01A4F",
                yAxisID: "A",
                fill: false,
              },
              {
                type: "bar",
                label: "Approved Revenue",
                data: selectedChart.validation_revenue,
                backgroundColor: gradient1,
                yAxisID: "B",
                borderRadius: 2,
              },
              {
                type: "bar",
                label: "Payment Received",
                data: selectedChart.payment_received,
                backgroundColor: gradient5,
                yAxisID: "B",
                borderRadius: 2,
              },
              {
                type: "bar",
                label: "Payment Pending",
                data: selectedChart.payment_pending,
                backgroundColor: gradient6,
                yAxisID: "B",
                borderRadius: 2,
              },
          ],
        },
        options: {
          maintainAspectRatio: false,
          layout: {
            padding: {
              left: 20,
              right: 20,
              top: 20,
              bottom: 10,
            },
          },
          responsive: true,
          scales: {
            x: {
              grid: {
                display: false,
              },
              ticks: {
                color: "#252525",
                font: {
                  // family: "kanit,sans-serif",
                  size: 16,
                  weight: "normal",
                },
              },
            },
            y: {
              display: false,
            },
            A: {
              display: false,
              grid: {
                display: false,
              },
              min: 0,
              max: 100,
              ticks: {
                stepSize: 10,
              },
            },
            B: {
              grid: {
                display: false,
              },

              min: 0,
              max: Math.round(max1),
              ticks: {
                color: "#252525",
                callback: function (label, index, labels) {
                  if (label == 0) return label;
                  else if (label < 1000) return Math.round(label);
                  else if (label < 100000) return Math.round(label / 1000) + "k";
                  else return Math.round(label / 100000) + "L";
                },
                font: {
                  // family: "kanit,sans-serif",
                  size: 14,
                  weight: "normal",
                },
                stepSize: Math.round(max1 / 3),
              },
            },
          },
          elements: {
            bar: {
              borderWidth: 0,
            },
          },
          plugins: {
            tooltip: {
              backgroundColor: "#f0f0f0",
              titleColor: "#252525",
              borderWidth: 1,
              borderColor: "",
              bodyColor: "#252525",
            },
            legend: {
              fullSize: false,
              position: "bottom",
              align: "center",
              labels: {
                color: "#252525",
                font: {
                  // family: "kanit, sans-serif",
                  size: 16,
                  weight: 400,
                },
                useBorderRadius: true,
                borderRadius: 5,
              },
            },
            datalabels: {
              display: false,
            },
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  }, [chartData, message, selectedRev]);


const func=()=>{
  setMessage('graph');
}

const func2=()=>{
  setMessage('table');
}
const customStyles = {
  container: base => ({
    ...base,
    flexGrow: '1',
    margin: '0 1px',
    height:'60px',
    position:'relative',
  }),
  control: base => ({
    ...base,
    
    justifyContent: 'center',
    alignItems: 'center',
    border: 0,
    boxShadow: 'none',
    backgroundColor: '#E9E9E9',
  }),
  valueContainer: base => ({
    ...base,
    flex: 'unset',
    height:'60px',
    maxHeight:'40px'

  }),
  menu: base => ({
    ...base,
    position:'absolute',
    width:'15rem'
  }),
  placeholder: base => ({
    ...base,
    color:"#252525",
  }),
  option: (styles, { isFocused, isSelected }) => {
    return {
      ...styles,
      color: '#252525',
      backgroundColor: isSelected ? '#E0F1FB' : isFocused ? '#E0F1FB' : undefined,
      textAlign: 'left',
      fontSize:'1rem'
    };
  },
}
  

const IndicatorsContainer = (props) => {
  return (
    <img className="icon1" src={arrowdown} alt="indicator" />
  );
};
const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
return (
  <ValueContainer {...props}>
    <Placeholder {...props} isFocused={props.isFocused}>
      {props.selectProps.placeholder}
    </Placeholder>
    {React.Children.map(children, child =>
      child && child.type !== Placeholder ? child : null
    )}
  </ValueContainer>
);
};
const CustomSingleValue = ( { children, ...props} ) => (
<components.SingleValue {...props}>
  {null}
</components.SingleValue>
);
const CustomOption = props => {
return (
  <components.Option {...props} style={{textAlign:'left'}} >
      <div style={{textAlign:'left'}}>
        <input type="radio" defaultChecked={false} />
        <label>{props.label}</label>
      </div>
  </components.Option>
);
};


const options2=[
{ value: 'ascending', label: 'Sort in Ascending order' },
{ value: 'descending', label: 'Sort in Descending order' },
];
function CustomSelect(props) {
return (
  <Select
    img={props.img}
    placeholder={props.placeholder}
    options={props.options} 
    isClearable={true}
    components={{ IndicatorsContainer: IndicatorsContainer, ValueContainer:props.valueContainer || components.ValueContainer,Option: props.option,SingleValue:props.singleValue || components.SingleValue }} 
    className='react-select-container' 
    classNamePrefix="react-select" 
    styles={customStyles} 
    isSearchable={false} 
    closeMenuOnSelect={false}
  />
);
}

{
  return (
    <div className="containerManik">
      <div className="title tablegraphimg_main">
        <div className="title">
          Monthly Payment Synopsis
          <span style={{ marginLeft: "0.2rem", color: "#A5A5A5" }}>
            ({begMonth}-{endMonth})
          </span>
        </div>
      </div>
      <div
        style={{
          // fontFamily: "kanit",
          fontSize: "11px",
          color: "#A5A5A5",
        }}
      >
        *Data is shown according to the monthly range selected
      </div>
      <div className="monthly-synopsis">
        <canvas id="chart3"></canvas>
      </div>
    </div>
  );
}

}

export default MonthlyPaymentSynopsis2;