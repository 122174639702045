import axios from 'axios'
import React, { useEffect, useState } from 'react'
import * as Constants from '../../../data/constants';
import { getLocalStorageValue, isSuperUser, sortingV2, zeroPad } from '../../../util/util';
import TableModel from '../../../components/tableModel/tablemodel';
import { Button, CircularProgress, IconButton, Pagination, Tooltip } from '@mui/material';
import { RefreshTwoTone } from '@mui/icons-material';
import {subMonths} from 'date-fns';

export const decideCap = (approved_revenue,limiter_status,last_approved_cap=500) => {
    if(approved_revenue<=500){
        var cap = 500
    }
    else if(approved_revenue<2000 ){
        if(limiter_status===0){
            cap=500
        }
        else{
            cap = 2000
        }  
    }
    else if(approved_revenue<5000){
        if(limiter_status===0){
            cap=2000
        }
        else{
            cap = 5000
        }
    }
    else if(approved_revenue<10000){
        if(limiter_status===0){
            cap=5000
        }
        else{
            cap = 10000
        }
    }
    else if(approved_revenue<20000){
        if(limiter_status===0){
            cap=10000
        }
        else{
            cap = 20000
        }
    }
    else if(approved_revenue<30000){
        if(limiter_status===0){
            cap=20000
        }
        else{
            cap = 30000
        }  
    }
    else{
        if(limiter_status===0){
            cap=30000
        }
        else{
            cap = 40000
        }  
    }
    if(limiter_status===1){
        if(last_approved_cap===500){
            cap = 2000
        }
        if(last_approved_cap===2000){
            cap = 5000
        }
        if(last_approved_cap===5000){
            cap = 10000
        }
        if(last_approved_cap===10000){
            cap = 20000
        }
        if(last_approved_cap===20000){
            cap = 30000
        }
        if(last_approved_cap===30000){
            cap = 40000
        }
    }
    return cap;
}
const TrafficLimitedAppWise = () => {
    const [responseData, setResponseData] = useState([])
    const [loader, setLoader] = useState(false)
    const [lastUpdatedAt, setLastUpdatedAt] = useState(false)
    const [limit, setLimit] = useState(50)
    const [offset, setOffset] = useState(0)
    const [totalResults, setTotalResults] = useState(50)
    const [page, setPage] = useState(1)
    const [showReasonPopup, setShowReasonPopup] = useState(false)

    const date = new Date();
    if(date.getDate()>15){
        var nMinusTwo = subMonths(new Date(),4)
        var nMinusOne = subMonths(new Date(),3)
        var nMinusZero = subMonths(new Date(),2)
    }
    else{
        var nMinusTwo = subMonths(new Date(),3)
        var nMinusOne = subMonths(new Date(),2)
        var nMinusZero = subMonths(new Date(),1)
    }
    var label = nMinusTwo.toLocaleString('default', { month: 'long' })+', '+nMinusOne.toLocaleString('default', { month: 'long' }) + ', '+nMinusZero.toLocaleString('default', { month: 'long' })
    const getTrafficLimitedAdvertiserList = async(hardRefresh,manual_limit=null,manual_page=null) => {
        setLoader(true)
        if(!manual_limit){
            manual_limit = limit
        }
        if(!manual_page){
            manual_page = page
        }
        var date = new Date();
        var dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric'};
        var startDate = new Date(date.getFullYear(), date.getMonth(), 1).toLocaleDateString("sv-SE", dateOptions);
        var endDate = new Date().toLocaleDateString("sv-SE", dateOptions); 
        await axios({
            method: 'GET',
            url:"".concat(Constants.PROTOCOL,Constants.HOST,'/api/admin/v4/statistics'),
            headers: {
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params: { 
                from_date:startDate,
                end_date:endDate,
                show_converted_stats:true,
                group_by : 'package_name',
                order_key : 'approved_revenue',
                order_by : 'desc',
                response_type:'dict'
            }
        })
        .then(async(resp)=>{
            if(resp.data && resp.data.result){
                var package_name_data = resp.data.result
                setLastUpdatedAt(parseInt(resp.data.result.updated_at))
                
                var date_temp = nMinusTwo.toLocaleString('en-US',{year:'numeric',month:'numeric'}).split(',')[0].split('/')
                var fromMonth = date_temp[1]+'-'+zeroPad(date_temp[0],2)
                date_temp = nMinusZero.toLocaleString('en-US',{year:'numeric',month:'numeric'}).split(',')[0].split('/')
                var toMonth = date_temp[1]+'-'+zeroPad(date_temp[0],2)
                
                await axios({
                    method: 'GET',
                    url: "".concat(Constants.PROTOCOL,Constants.HOST,'/api/admin/v2/invoice/summary'),
                    headers:{
                        Authorization:getLocalStorageValue('adminPanelLoginData').token,
                        "Content-Type":"application/json",
                    },
                    params:{
                        fromMonth,
                        toMonth,
                        group_by:'package_name',
                        total_row_required:true,
                        required_in_dict:true,

                    }
                })
                .then(async(first_resp)=>{
                    var response = []
                    for(var adv_id in resp.data.result){
                        var adv_data = package_name_data[adv_id]
                        var approved_revenue = Math.round(adv_data.approved_revenue)
                        if(approved_revenue>=1){
                            if(Object.keys(first_resp.data.result).includes(adv_id)){
                                var other_data = first_resp.data.result[adv_id]
                                var limiter_status = '9'
                                var cap = decideCap(approved_revenue,limiter_status,other_data.last_approved_cap)
                                var cap_percent = Math.round((approved_revenue/cap)*100)
                                response.push({...adv_data,limiter_status:9,cap_percent,cap,approved_revenue,adv_id,...other_data})
                            }
                            else{
                                var limiter_status = '9'
                                var cap = decideCap(approved_revenue,limiter_status)
                                response.push(
                                    {...adv_data,limiter_status:9,cap_percent:0,cap,approved_revenue,adv_id,
                                        "final": "",
                                    "app_name": "",
                                    "conversions": 0,
                                    "conversions_verified": 0,
                                    "deduction_revenue": 0,
                                    "deduction_revenue_manual": 0,
                                    "delivered_revenue": 0,
                                    "diff_manual_panel": 0,
                                    "diff_manual_panel_percent": 0,
                                    "manager_approved_revenue": 0,
                                    "offers_approved_percentage": 0,
                                    "pending_revenue": 0,
                                    "pending_revenue_manual": 0,
                                    "total_offers": 0,
                                    "validated": "0/0",
                                    "validated_offers": 0,
                                    "validation_percentage": 0,
                                    "validation_percentage_manual": 0,
                                    "tech_team": "",
                                    "tech_subteam": ""}
                                )
                            }
                        }
                    }
                    sortingV2(response,'approved_revenue',false)
                    setResponseData(response)
                })
                .catch(function (error) {
                    if (error.response) {
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else {
                        console.log('Error', error);
                    }        
                });
            }
        })
        .catch(function (error) {
            if (error.response) {
                console.log(error.response.status);
                console.log(error.response.headers);
            } else {
                console.log('Error', error);
            }        
        });
        setLoader(false)
    }
    String.prototype.rsplit = function(sep, maxsplit) {
        var split = this.split(sep);
        return maxsplit ? [ split.slice(0, -maxsplit).join(sep) ].concat(split.slice(-maxsplit)) : split;
    }

    const upgradeLimit = async(valueItem) => {
        setShowReasonPopup({...showReasonPopup,loader:true})
        var date = new Date();
        var dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric'};
        var startDate = new Date(date.getFullYear(), date.getMonth(), 1).toLocaleDateString("sv-SE", dateOptions);
        var year_month = startDate.rsplit('-',1)[0]
        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,'advertiser/approvealloffers'),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
                "Content-Type":"application/json",
            },
            params:{
                adv_id:valueItem.adv_id,
                adv_name:valueItem.company,
                cap : valueItem.cap,
                year_month,
                reason : showReasonPopup.reason,
            }
        })
        .then(async(first_resp)=>{
            getTrafficLimitedAdvertiserList()
            setShowReasonPopup(false)
        })
        .catch(function (error) {
            if (error.response) {
              console.log(error.response.status);
              console.log(error.response.headers);
            } else {
              console.log('Error', error.message);
            }        
        });
    }
    useEffect(() => {
        getTrafficLimitedAdvertiserList()
        
        return () => {
            
        }
    }, [])
    
    const advertiserNameComponent = (valueItem) => {
        return valueItem.adv_id 
    }

    var statusDictColor = {
        '0':{
            color:'orange',
            backgroundColor:'rgb(255, 234, 195)',
            label:'Traffic Limited',
        },
        '1':{
            color:'rgb(0, 150, 0)',
            backgroundColor:'rgb(217, 255, 200)',
            label:'Active'
        },
        '3':{
            color:'rgb(0, 150, 0)',
            backgroundColor:'rgb(217, 255, 200)',
            label:'Active'
        },
        '9':{
            color:'red',
            backgroundColor:'rgb(255, 200, 200)',
            label:'Not Enabled'
        }
        
    }
    const statusComponent = (valueItem,item) => {
        var value = valueItem[item.value]
        if(value===9){
            if(valueItem.status && valueItem.status.traffic_limiter){
                value='1'
            }
        }
        return <div style={{minWidth:'70px',textAlign:'center',width:'max-content',wordWrap:'break-word',color:(statusDictColor[value] ? statusDictColor[value].color : 'black'),border:'1px solid '+(statusDictColor[value] ? statusDictColor[value].color : 'black'),padding:'2px',backgroundColor:(statusDictColor[value] ? statusDictColor[value].backgroundColor : 'white'),borderRadius:'5px',paddingTop:'5px',paddingBottom:'5px'}}>
                    {statusDictColor[value] ? statusDictColor[value].label : value}
                </div>
    }
    const capPercentComponent = (valueItem,item) => {
        var percentColors = [
            { pct: 0, color: { r: 0x00, g: 0xff, b: 0 } },
            { pct: 50, color: { r: 0xff, g: 0xff, b: 0 } },
            { pct: 100, color: { r: 0xff, g: 0x00, b: 0 } }
        ]
        
        var getColorForPercentage = function(pct) {
            for (var i = 1; i < percentColors.length - 1; i++) {
                if (pct < percentColors[i].pct) {
                    break;
                }
            }
            var lower = percentColors[i - 1];
            var upper = percentColors[i];
            var range = upper.pct - lower.pct;
            var rangePct = (pct - lower.pct) / range;
            var pctLower = 1 - rangePct;
            var pctUpper = rangePct;
            var color = {
                r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
                g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
                b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
            };
            return 'rgb(' + [color.r, color.g, color.b].join(',') + ')';
            // or output as hex if preferred
        };
        var totalWidth = 100
        var width = (totalWidth * valueItem[item.value])/100
        if(width>100){
            width=100
        }
        return <Tooltip title={valueItem[item.value]}>
            <div style={{display:'flex',alignItems:'flex-end'}}>
                <div style={{height:'10px',position:'relative',width:totalWidth+'%',borderRadius:'7px',border:'1px solid rgb(200,200,200)'}}>
                    <div style={{background:getColorForPercentage(valueItem[item.value]),width:width+'%',height:'10px',borderRadius:'7px'}}>
                        
                        {/* <div style={{position:'absolute',right:0,top:-10}}>
                            {valueItem[item.value]}
                        </div> */}
                    </div>
                </div>
            </div>
        </Tooltip>
    }
    const emptyValueComponent = (valueItem,item) => {
        if(!valueItem[item.value]){
            return '-'
        }
        return valueItem[item.value]
    }

    const validationPercentageComponent = (valueItem,item,index,valueIndex,tableValues) => {
        var percent = valueItem[item.value] ? parseInt(valueItem[item.value]) : 0.00
        if (percent<25){
            var color = '#ff0000'
            var highlightColor = '#ffcccc'
        }
        else if (percent<50){
            color = '#ff6200'
            highlightColor = '#ffe6cc'
        }
        else if (percent<75){
            color = '#6f9400'
            highlightColor = '#ebffc2'
        }
        else{
            color = '#0f8500'
            highlightColor = '#d2ffcc'
        }
        return <div style={{color,fontWeight:500,background:highlightColor,marginLeft:(valueItem.advertiser_id==='Total' ? '5px' : '0px'),padding:'5px',width:'max-content',borderRadius:'10px'}}>
            {percent ? percent.toString()+'%' : '0%'}
        </div>
    }
    const intValueComponent = (valueItem,item) => {
        if(!valueItem[item.value]){
            return '0'
        }
        return Math.round(valueItem[item.value])
    }
    
    const approveCapComponent = (valueItem,item) => {
        var limiter_status = valueItem.limiter_status
        return <Tooltip title={limiter_status===0 ? 'Upgrade cap limit' : 'You can upgrate only when cap percentage is above 90%.'}>
            <div style={{cursor:limiter_status===0 ? 'pointer' : 'not-allowed',color:limiter_status===0 ? getLocalStorageValue('ambientColor') : 'grey'}} onClick={()=>{if(limiter_status===0){item.setShowReasonPopup(valueItem)}}}>
                Increase Limit
            </div>
        </Tooltip>
    }

    
    const tempTableHeaders = [
        {label:'Package Name',value:'adv_id',width:90,locked:true,showTooltip:true,hide:false,customComponent:advertiserNameComponent},
        {label:'App Name',value:'app_name',width:90,locked:false,showTooltip:true,hide:false},
        {label:'Tech Team',value:'tech_team',width:90,locked:false,showTooltip:true,hide:false},
        {label:'Status',value:'limiter_status',width:90,customComponent:statusComponent},
        {label:'Cap',value:'cap',width:90},
        {label:'Revenue',value:'approved_revenue',width:90},
        {label:'Percentage',value:'cap_percent',width:90,customComponent:capPercentComponent},
        {label:'Updated At',value:'last_approved_time',width:110,customComponent:emptyValueComponent},
        {label:label+' Data',width:180,
            child:[
                {label:'Validation Percentage',value:'validation_percentage_manual',width:50,customComponent:validationPercentageComponent},
                {label:'Revenue',value:'conversions_verified',width:50,customComponent:intValueComponent},
            ]},
        {label:'Last Approved Cap',value:'last_approved_cap',width:110,customComponent:emptyValueComponent},
        {label:'Last Approved By',value:'last_approved_by',width:130,customComponent:emptyValueComponent},
        {label:'Last Approved Message',value:'last_approved_message',width:150,customComponent:emptyValueComponent},
    ]
    if(isSuperUser()){
        tempTableHeaders.push(
            {label:'Approve',value:'approve',width:150,customComponent:approveCapComponent,setShowReasonPopup},
        )
    }
    return (
        <div className='advertiserBillingContainer'>
            <div style={{position:'sticky',left:0,width:'100%',zIndex:100}}>
                <div style={{fontSize:'24px',fontWeight:'700',padding:'10px',paddingLeft:'20px'}}>Traffic Limiter - App Wise</div>
                {/* <div style={{display:'flex',alignItems:'center',paddingLeft:'20px',fontSize:'13px'}}>
                   <div>Current Month Revenue Last Updated At - </div>
                   <div style={{marginLeft:'5px'}}>
                        {loader && 
                            <CircularProgress size={11} sx={{color:'orange'}}/>
                        }
                        {!loader &&
                        <div style={{display:'flex',alignItems:'center',fontFamily:'sans-serif'}}>
                            {new Date(lastUpdatedAt*1000).toLocaleDateString('en-US',{month:'short',day:'2-digit',hour:'2-digit',minute:'2-digit'})}
                            <Tooltip title={'Refresh Revenue Now'}>
                                <RefreshTwoTone onClick={()=>{getTrafficLimitedAdvertiserList(true)}} sx={{fontSize:'14px',color:getLocalStorageValue('ambientColor'),marginLeft:'5px'}}/>
                            </Tooltip>
                        </div>
                        }
                   </div>
                </div> */}
            </div>
            {
                showReasonPopup
                &&
                <div style={{position:'fixed',top:0,left:0,height:'100vh',width:'100vw',display:'flex',alignItems:'center',justifyContent:'center'}}>
                    <div style={{minHeight:'20vh',boxShadow:'0 0 5px 1px rgb(200,200,200)',width:'40vw',background:'white',border:'1px solid rgb(200,200,200)'}}>
                        <div style={{borderBottom:'1px solid rgb(200,200,200)',height:'50px',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                            <div style={{paddingLeft:'10px'}}>Reason For Upgrading Limit</div>
                            <div style={{paddingRight:'10px'}} onClick={()=>{setShowReasonPopup(false)}}>X</div>
                        </div>
                        <div style={{display:'flex',flexDirection:'column',alignItems:'center',minHeight:'10vh',justifyContent:'center',padding:'15px'}}>
                            <Constants.CssTextField fullWidth onChange={e=>{setShowReasonPopup({...showReasonPopup,reason:e.target.value})}}/>
                            <Button style={{...Constants.CONTAINEDBUTTON,marginTop:'20px'}} onClick={()=>{upgradeLimit(showReasonPopup)}}>
                                {showReasonPopup.loader &&
                                <CircularProgress size={14} sx={{color:'white'}}/>
                                }
                                {
                                    !showReasonPopup.loader &&
                                    'Submit'  
                                }
                            </Button>
                        </div>
                    </div>
                </div>
            }
            {
                loader
                &&
                <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'30vh'}}>
                    <CircularProgress size={50} sx={{color:getLocalStorageValue('ambientColor')}}/>
                </div>
            }
            {
                !loader
                &&
                <TableModel
                    tableHeaders={tempTableHeaders}
                    // setTableHeaders={setTableHeaders}
                    serialOffset={offset}
                    tableValues={responseData}
                    setTableValues={setResponseData}
                    style={{background:'rgb(245,245,245)'}}
                    tableValueRow={'rest'}
                    setTableValueRow={()=>{console.log('test')}}
                />
            }
            {!loader && totalResults/limit>1 && typeof responseData === 'object' && responseData.length>0 && 
                <div style={{display:'flex',alignItems:'center',justifyContent:"space-between",paddingLeft:'20px',paddingRight:'20px',paddingBottom:'20px',position:'sticky',bottom:0,left:0,background:'white',zIndex:899}}>
                    <div>
                        <label style={{paddingRight:"5px",fontSize:'14px'}}>Limit</label>
                        <select value={limit} style={{outline:'none',border:'none'}} onChange={(e)=>{getTrafficLimitedAdvertiserList(false,e.target.value,1);setLimit(parseInt(e.target.value));setPage(1);}}>
                            {[5,16,10,50,100,200,300,500].map((item,index)=>{
                                return <option key={index} value={item}>{item}</option>
                            })}
                            {/* {statsType!=='offer' && [25,50,75,100].map((item,index)=>{
                                return <option key={index} value={item}>{item}</option>
                            })} */}
                        </select>
                    </div>
                    <Pagination count={Math.ceil(totalResults/limit)} page={page} onChange={(event,value)=>{getTrafficLimitedAdvertiserList(false,limit,value);setPage(value);window.scrollTo({top: 0,left: 0,behavior: 'smooth'});}} />
                </div>
            }
        </div>
    )
}

export default TrafficLimitedAppWise