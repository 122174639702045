import React, { useState } from 'react';
import './filterRow.css';
import ReactSelect from 'react-select';
import { CssTextField, ReactSelectStyles } from '../../data/constants';
import * as Constants from '../../data/constants';
import { Button} from '@mui/material';
import { CircularProgress } from '@mui/material'
import DateRangePickerComponent from '../dateRangePicker/dateRangePicker';
import { increase_brightness } from '../customization/customization';

var selectStyles = { menuPortal: base => ({ ...base, zIndex: 9999 }),
control: (base,state) => ({
    ...base,
    // maxHeight: 100,
    transform: 'scale(0.8)',
    transformOrigin:'left',

    minHeight: 30,
    overflowY: "auto",
    border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid grey',
    boxShadow: state.isFocused ? '0 0 3px 1px '+localStorage.getItem('ambientColor') : 0,
    '&:hover': {
        border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
        boxShadow: state.isFocused ? '0 0 1px 1px '+localStorage.getItem('ambientColor') : '0 0 1px 1px '+localStorage.getItem('ambientColor'),
    }
}),
option: (styles, {isFocused, isSelected}) => ({
    ...styles,
    fontSize:'11px',
    background: isFocused
        ? localStorage.getItem('ambientColor')
        : isSelected
            ? increase_brightness(localStorage.getItem('ambientColor'),10)
            : undefined,
    zIndex: 1
}),
}

const FilterRow = (props) => {
    const [isFirstEnter, setIsFirstEnter] = useState(true)
    var filtersList = props.filtersList
    var filterData = props.filterData
    var setFilterData = props.setFilterData
    return (
        <div className='filterRowContainer'>
            {filtersList.map((filterItem,filterIndex)=>{
                return <div className='filterItem' style={filterItem.type==='select' ? {marginLeft:'0%',marginRight:'0px'} :{}} key={filterIndex}>
                        {filterItem.type !=='button' && <div className='filterItemLabel'>{filterItem.label}</div>}
                        {filterItem.type === 'daterangepicker'
                        &&
                        <DateRangePickerComponent propsParent={props} customDateRangeFunction={props.customDateRangeFunction}  filterData={filterData} setFilterData={setFilterData} filterItem={filterItem}/>

                        }
                        {filterItem.type === 'textfield'
                        &&
                        <CssTextField variant='standard' value={filterData[filterItem.value] ? filterData[filterItem.value] : ''} onChange={e=>{setFilterData(e.target.value,filterItem);}} onKeyDown={(e)=>{if(e.key==='Enter'){props.onEnterFunction()}}}/>}
                        {filterItem.type === 'select'
                        &&
                        <div style={{minWidth:180,maxWidth:180}}>
                            <ReactSelect 
                                isClearable={filterItem.hideClearable ? false : true}
                                options={filterItem.options}
                                value={filterData[filterItem.value] ? filterData[filterItem.value] : ''}
                                isMulti={filterItem.isSingle ? false : true}
                                onChange={(e)=>{setIsFirstEnter(true);setFilterData(e,filterItem)}}
                                onMenuOpen={()=>{setIsFirstEnter(false);}}
                                menuPortalTarget={document.body}
                                onKeyDown={(e)=>{if(e.key==='Enter' && isFirstEnter){props.onEnterFunction()}else{setIsFirstEnter(false)}}} 
                                styles={{...selectStyles}}/>
                            
                        </div>
                        }
                        {
                            filterItem.type === 'button'
                            &&
                            <div style={{marginTop:'21px'}}>
                                <Button id='testbutton' disabled={filterItem.disabled} onClick={filterItem.onSubmit} sx={{...(filterItem.variant && filterItem.variant==='outlined' ? Constants.OUTLINEDBUTTON : Constants.CONTAINEDBUTTON),fontSize:'14px',minWidth:'102px',minHeight:'40px'}}>
                                    {filterItem.disabled
                                    &&
                                    <CircularProgress size={'15px'} sx={{color:'white'}}/>
                                    }
                                    {!filterItem.disabled && filterItem.label}
                                </Button>
                            </div>
                        }
                    </div>
            })}
        </div>
    )
}

export default FilterRow