import axios from 'axios'
import React, { useEffect, useState } from 'react'
import * as Constants from '../../../data/constants';
import { getLocalStorageValue, getUserType, isSuperUser, sortingV2, zeroPad } from '../../../util/util';
import TableModel from '../../../components/tableModel/tablemodel';
import { Button, CircularProgress, IconButton, Pagination, Tooltip } from '@mui/material';
import { RefreshTwoTone } from '@mui/icons-material';
import ReactApexChart  from 'react-apexcharts';
import { Close } from '@mui/icons-material';

const TrafficLimitedAdvertiser = () => {
    const [responseData, setResponseData] = useState([])
    const [loader, setLoader] = useState(false)
    const [lastUpdatedAt, setLastUpdatedAt] = useState(false)
    const [limit, setLimit] = useState(50)
    const [offset, setOffset] = useState(1)
    const [totalResults, setTotalResults] = useState(50)
    const [page, setPage] = useState(1)
    const [showReasonPopup, setShowReasonPopup] = useState(false)
    const [showEnabledAdvertisers, setShowEnabledAdvertisers] = useState(false)

    var getHyphenYearMonth=(date)=>{
        var ll = date.toLocaleString('en-GB', { month: 'numeric',year:'numeric' })
        var month = ll.split('/')
        return month[1]+'-'+month[0]
    }
    const date = new Date();
    if(date.getDate()>15){
        var label = subtractMonths(new Date(),3).toLocaleString('default', { month: 'long' })
        label+=', '+subtractMonths(new Date(),2).toLocaleString('default', { month: 'long' })
        label+=', '+subtractMonths(new Date(),1).toLocaleString('default', { month: 'long' })
        var month_list = [getHyphenYearMonth(subtractMonths(new Date(),3)),getHyphenYearMonth(subtractMonths(new Date(),2)),getHyphenYearMonth(subtractMonths(new Date(),1))]
    }
    else{
        label = subtractMonths(new Date(),4).toLocaleString('default', { month: 'long' })
        label+=', '+subtractMonths(new Date(),3).toLocaleString('default', { month: 'long' })
        label+=', '+subtractMonths(new Date(),2).toLocaleString('default', { month: 'long' })
        var month_list = [getHyphenYearMonth(subtractMonths(new Date(),4)),getHyphenYearMonth(subtractMonths(new Date(),3)),getHyphenYearMonth(subtractMonths(new Date(),2))]
    }
    
    const getTrafficLimitedAdvertiserList = async(hardRefresh,manual_limit=null,manual_page=null,traffic_limiter_active_manual=null) => {
        setLoader(true)
        if(!manual_limit){
            manual_limit = limit
        }
        if(!manual_page){
            manual_page = page
        }

        if(!traffic_limiter_active_manual && traffic_limiter_active_manual!==false){
            traffic_limiter_active_manual = showEnabledAdvertisers
        }
        var date = new Date();
        var dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric'};
        var startDate = new Date(date.getFullYear(), date.getMonth(), 1).toLocaleDateString("sv-SE", dateOptions);
        var endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0).toLocaleDateString("sv-SE", dateOptions); 
        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,'statistics/advertiser/redis'),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
                "Content-Type":"application/json",
            },
            params:{
                startDate,
                endDate,
                limit:1000,
                // advertiser_id:Object.keys(first_resp.data.result).join(','),
                page:1,
                order_by:'DESC',
                order_field:'approved_revenue',
                refresh_key:hardRefresh ? true : undefined,
                traffic_limiter_active:traffic_limiter_active_manual ? traffic_limiter_active_manual : undefined,
            }
        })
        .then(async(resp)=>{
            if(resp.data && resp.data.result.data){
                setTotalResults(Object.keys(resp.data.result.data).length)
                setLastUpdatedAt(parseInt(resp.data.result.last_updated_at))
                setShowEnabledAdvertisers(resp.data.result.traffic_limiter_active === "true" ? true : false)
                var adv_id_list = Object.keys(resp.data.result.data).map((item)=>{return {id:item,approved_revenue:resp.data.result.data[item].payouts[0].approved_revenue}})
                sortingV2(adv_id_list,'approved_revenue',false)
                var offset = manual_limit*(manual_page-1)
                setOffset(offset)
                adv_id_list =adv_id_list.slice(offset,offset+manual_limit)
                adv_id_list = adv_id_list.map((item)=>{return item.id})

                adv_id_list = adv_id_list.filter(e=>e!=='None')
                await axios({
                    method: 'GET',
                    url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,'advertiser/trafficlimited'),
                    headers:{
                        Authorization:getLocalStorageValue('adminPanelLoginData').token,
                        "Content-Type":"application/json",
                    },
                    params:{
                        adv_id_list:adv_id_list.join(','),
                        month_list:month_list.join(',')
                    }
                })
                .then(async(first_resp)=>{
                    var response = []
                    for(var adv_id in resp.data.result.data){
                        if(Object.keys(first_resp.data.result).includes(adv_id)){
                            var adv_data = resp.data.result.data[adv_id]
                            var approved_revenue = Math.round(adv_data.payouts[0].approved_revenue)
                            var other_data = first_resp.data.result[adv_id]
                            var limiter_status = first_resp.data.result[adv_id].limiter_status
                            if(approved_revenue<=500){
                                var cap = 500
                            }
                            else if(approved_revenue<2000 ){
                                if(limiter_status===0){
                                    cap=500
                                }
                                else{
                                    cap = 2000
                                }  
                            }
                            else if(approved_revenue<5000){
                                if(limiter_status===0){
                                    cap=2000
                                }
                                else{
                                    cap = 5000
                                }
                            }
                            else if(approved_revenue<10000){
                                if(limiter_status===0){
                                    cap=5000
                                }
                                else{
                                    cap = 10000
                                }
                            }
                            else if(approved_revenue<20000){
                                if(limiter_status===0){
                                    cap=10000
                                }
                                else{
                                    cap = 20000
                                }
                            }
                            else if(approved_revenue<30000){
                                if(limiter_status===0){
                                    cap=20000
                                }
                                else{
                                    cap = 30000
                                }  
                            }
                            else{
                                if(limiter_status===0){
                                    cap=30000
                                }
                                else{
                                    cap = 40000
                                }  
                            }
                            if(limiter_status===1){
                                if(other_data.last_approved_cap===500){
                                    cap = 2000
                                }
                                if(other_data.last_approved_cap===2000){
                                    cap = 5000
                                }
                                if(other_data.last_approved_cap===5000){
                                    cap = 10000
                                }
                                if(other_data.last_approved_cap===10000){
                                    cap = 20000
                                }
                                if(other_data.last_approved_cap===20000){
                                    cap = 30000
                                }
                                if(other_data.last_approved_cap===30000){
                                    cap = 40000
                                }
                            }
                            var cap_percent = Math.round((approved_revenue/cap)*100)
                            response.push({...adv_data,cap_percent,cap,approved_revenue,adv_id,...other_data})
                        }
                    }
                    sortingV2(response,'approved_revenue',false)
                    setResponseData(response)
                })
            }
        })
        .catch(function (error) {
            if (error.response) {
                console.log(error.response.status);
                console.log(error.response.headers);
            } else {
                console.log('Error', error.message);
            }        
        });
        setLoader(false)
    }
    String.prototype.rsplit = function(sep, maxsplit) {
        var split = this.split(sep);
        return maxsplit ? [ split.slice(0, -maxsplit).join(sep) ].concat(split.slice(-maxsplit)) : split;
    }

    const upgradeLimit = async(valueItem) => {
        setShowReasonPopup({...showReasonPopup,loader:true})
        var date = new Date();
        var dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric'};
        var startDate = new Date(date.getFullYear(), date.getMonth(), 1).toLocaleDateString("sv-SE", dateOptions);
        var year_month = startDate.rsplit('-',1)[0]
        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,'advertiser/approvealloffers'),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
                "Content-Type":"application/json",
            },
            params:{
                adv_id:valueItem.adv_id,
                adv_name:valueItem.company,
                cap : valueItem.cap,
                year_month,
                reason : showReasonPopup.reason,
            }
        })
        .then(async(first_resp)=>{
            getTrafficLimitedAdvertiserList()
            setShowReasonPopup(false)
        })
        .catch(function (error) {
            if (error.response) {
              console.log(error.response.status);
              console.log(error.response.headers);
            } else {
              console.log('Error', error.message);
            }        
        });
    }
    useEffect(() => {
        getTrafficLimitedAdvertiserList()
        
        return () => {
            
        }
    }, [])
    
    const advertiserNameComponent = (valueItem) => {
        return valueItem.company + ' - ' + valueItem.adv_id 
    }

    var statusDictColor = {
        '0':{
            color:'orange',
            backgroundColor:'rgb(255, 234, 195)',
            label:'Traffic Limited',
        },
        '1':{
            color:'rgb(0, 150, 0)',
            backgroundColor:'rgb(217, 255, 200)',
            label:'Active'
        },
        '3':{
            color:'rgb(0, 150, 0)',
            backgroundColor:'rgb(217, 255, 200)',
            label:'Active'
        },
        '9':{
            color:'red',
            backgroundColor:'rgb(255, 200, 200)',
            label:'Not Enabled'
        }
        
    }
    const statusComponent = (valueItem,item) => {
        var value = valueItem[item.value]
        if(value===9){
            if(valueItem.status && valueItem.status.traffic_limiter){
                value='1'
            }
        }
        return <div style={{minWidth:'70px',textAlign:'center',width:'max-content',wordWrap:'break-word',color:(statusDictColor[value] ? statusDictColor[value].color : 'black'),border:'1px solid '+(statusDictColor[value] ? statusDictColor[value].color : 'black'),padding:'2px',backgroundColor:(statusDictColor[value] ? statusDictColor[value].backgroundColor : 'white'),borderRadius:'5px',paddingTop:'5px',paddingBottom:'5px'}}>
                    {statusDictColor[value] ? statusDictColor[value].label : value}
                </div>
    }
    const capPercentComponent = (valueItem,item) => {
        var percentColors = [
            { pct: 0, color: { r: 0x00, g: 0xff, b: 0 } },
            { pct: 50, color: { r: 0xff, g: 0xff, b: 0 } },
            { pct: 100, color: { r: 0xff, g: 0x00, b: 0 } }
        ]
        
        var getColorForPercentage = function(pct) {
            for (var i = 1; i < percentColors.length - 1; i++) {
                if (pct < percentColors[i].pct) {
                    break;
                }
            }
            var lower = percentColors[i - 1];
            var upper = percentColors[i];
            var range = upper.pct - lower.pct;
            var rangePct = (pct - lower.pct) / range;
            var pctLower = 1 - rangePct;
            var pctUpper = rangePct;
            var color = {
                r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
                g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
                b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
            };
            return 'rgb(' + [color.r, color.g, color.b].join(',') + ')';
            // or output as hex if preferred
        };
        var totalWidth = 100
        var width = (totalWidth * valueItem[item.value])/100
        if(width>100){
            width=100
        }
        return <Tooltip title={valueItem[item.value]}>
            <div style={{display:'flex',alignItems:'flex-end'}}>
                <div style={{height:'10px',position:'relative',width:totalWidth+'%',borderRadius:'7px',border:'1px solid rgb(200,200,200)'}}>
                    <div style={{background:getColorForPercentage(valueItem[item.value]),width:width+'%',height:'10px',borderRadius:'7px'}}>
                        
                        {/* <div style={{position:'absolute',right:0,top:-10}}>
                            {valueItem[item.value]}
                        </div> */}
                    </div>
                </div>
            </div>
        </Tooltip>
    }
    const emptyValueComponent = (valueItem,item) => {
        if(!valueItem[item.value]){
            return '-'
        }
        return valueItem[item.value]
    }


    const [popup, setPopup] = useState(false)
    const [popupData, setPopupData] = useState([])
    const getRevenueDataMonthlyForAdv = async(e,adv_id,valueIndex,sortby='month') => {
        await axios({
            method: 'GET',
            url:"".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"invoice/summary/adv-data"),
            headers: {
                "Content-Type":"application/json",
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params: { 
                //filters
                year:true,
                adv_id:adv_id,
                // currency:(manualCurrency ? manualCurrency : currency).toLowerCase(),
                }
        })
        .then(async(resp) => {
            var item_dict = resp.data.result
            sortingV2(item_dict,sortby,true)
            setPopupData(item_dict)
        })
        .catch((error) => {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
                }
        })
    }
    const validationPercentageComponent = (valueItem,item,index,valueIndex,tableValues) => {
        var percent = valueItem[item.value] ? parseInt(valueItem[item.value]) : 0.00
        if (percent<25){
            var color = '#ff0000'
            var highlightColor = '#ffcccc'
        }
        else if (percent<50){
            color = '#ff6200'
            highlightColor = '#ffe6cc'
        }
        else if (percent<75){
            color = '#6f9400'
            highlightColor = '#ebffc2'
        }
        else{
            color = '#0f8500'
            highlightColor = '#d2ffcc'
        }
        return <div style={{color,fontWeight:500,background:highlightColor,marginLeft:(valueItem.advertiser_id==='Total' ? '5px' : '0px'),padding:'5px',width:'max-content',borderRadius:'10px'}}  onClick={(e)=>{setPopup(valueItem.company);getRevenueDataMonthlyForAdv(e,valueItem.advertiser_id,valueIndex)}}>
            {percent ? percent.toString()+'%' : '0%'}
        </div>
    }
    const intValueComponent = (valueItem,item) => {
        if(!valueItem[item.value]){
            return '0'
        }
        return Math.round(valueItem[item.value])
    }
    
    const approveCapComponent = (valueItem,item) => {
        var limiter_status = valueItem.limiter_status
        var allowed = (limiter_status===0 || limiter_status===3)
        return <Tooltip title={allowed ? 'Upgrade cap limit' : 'You can upgrate only when cap percentage is above 90%.'}>
            <div style={{cursor:allowed ? 'pointer' : 'not-allowed',color:allowed ? getLocalStorageValue('ambientColor') : 'grey'}} onClick={()=>{if(allowed){item.setShowReasonPopup(valueItem)}}}>
                Increase Limit
            </div>
        </Tooltip>
    }

    function subtractMonths(date, months) {
        date.setMonth(date.getMonth() - months);
        return date;
    }

    const [offersPopup, setOffersPopup] = useState(false)
    const [pendingOffersData, setPendingOffersData] = useState([])
    const getRevenueData = async(e,adv_id) => {

        const getFromToMonth = () => {
            var date_temp = new Date(subtractMonths(new Date(),13)).toLocaleString('en-US',{year:'numeric',month:'numeric'}).split(',')[0].split('/')
            var fromMonth = date_temp[1]+'-'+zeroPad(date_temp[0],2)
            date_temp = new Date(subtractMonths(new Date(),2)).toLocaleString('en-US',{year:'numeric',month:'numeric'}).split(',')[0].split('/')
            var toMonth = date_temp[1]+'-'+zeroPad(date_temp[0],2)
            return {fromMonth,toMonth}
        }
        var {fromMonth,toMonth} = getFromToMonth()
        await axios({
            method: 'GET',
            url:"".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"invoice/adv-data"),
            headers: {
                "Content-Type":"application/json",
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params: { 
                fromMonth,
                toMonth,
                adv_id,
                sorting_key:'pending_revenue_manual',
                sorting_order:false
            }
        })
        .then(async(resp) => {
            var items = resp.data.result
            var list_ = []
            for(var x in items){
                if(items[x].pending_revenue_manual>0){
                    list_.push(items[x])
                }
            }
            if(list_.length===0){
                list_='No Pending Revenue'
            }
            setPendingOffersData(list_);
        })
        .catch((error) => {
            setPendingOffersData([])
            if (error.response) {
                console.log(error.response.data);
                if(error.response.data){
                    setPendingOffersData(error.response.data.result);
                }
                console.log(error.response.status);
                console.log(error.response.headers);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        })
    }
    const pendingRevenueComponent = (valueItem,item,index,valueIndex) => {
        if(!valueItem[item.value]){
            return '0'
        }
        return <div onClick={(e)=>{setOffersPopup(valueItem.company);getRevenueData(e,valueItem.advertiser_id)}}>
            {Math.round(valueItem[item.value])}
        </div>
    }
    
    
    const tempTableHeaders = [
        {label:'Advertiser',value:'adv_id',width:90,locked:true,showTooltip:true,hide:false,customComponent:advertiserNameComponent},
        {label:'Status',value:'limiter_status',width:90,customComponent:statusComponent},
        {label:'Cap',value:'cap',width:90},
        {label:'Revenue',value:'approved_revenue',width:90},
        {label:'Percentage',value:'cap_percent',width:90,customComponent:capPercentComponent},
        {label:'Updated At',value:'last_approved_time',width:110,customComponent:emptyValueComponent},
        {label:label+' Data',width:180,
            child:[
                {label:'Validation Percentage',value:'validation_percentage_manual',width:50,customComponent:validationPercentageComponent},
                {label:'Revenue',value:'conversion_verified',width:50,customComponent:intValueComponent},
            ]},
        {label:'Pending Revenue Total',value:'pending_revenue_manual_yearly',width:100,customComponent:pendingRevenueComponent},
        {label:'Last Approved Cap',value:'last_approved_cap',width:110,customComponent:emptyValueComponent},
        {label:'Last Approved By',value:'last_approved_by',width:130,customComponent:emptyValueComponent},
        {label:'Last Approved Message',value:'last_approved_message',width:150,customComponent:emptyValueComponent},
    ]
    if(getUserType()==='general_manager' || isSuperUser()){
        tempTableHeaders.push(
            {label:'Approve',value:'approve',width:150,customComponent:approveCapComponent,setShowReasonPopup},
        )
    }

    const summaryPendingOffers = () => {
       
        const roundComponent = (valueItem,item,index,valueIndex,tableValues) => {
            var value = Math.round(valueItem[item.value])
            return <div style={{marginLeft:(valueItem.advertiser_id === 'Total' ? '5px' : '0px')}}>
                {value}
            </div>
        }

        const roundApprovedComponent = (valueItem,item,index,valueIndex,tableValues) => {
            return Math.round(valueItem[item.value])
        }

        const appNameComponent = (valueItem,item,index,valueIndex,tableValues) => {
            if(valueItem.app_name){
                return valueItem.app_name
            }
            if(valueItem.package_name){
                return valueItem.package_name
            }
            if(valueItem.offer_name){
                return valueItem.offer_name
            }
        }

        const monthComponent = (valueItem,item) => {
            return <div style={{textDecoration:'none',color:'blue'}}>
                {new Date(valueItem[item.value]).toLocaleDateString('en-US',{year:'numeric',month:'long'})}
            </div>
        }
        var ytdTableHeaders = [
            {label:'App Name',value:'app_name',width:120,customComponent:appNameComponent,hideSorting:true},
            {label:'Month',value:'month',width:100,customComponent:monthComponent},
            {label:'Delivered Qty',value:'conversions',hide:true,width:100,hideSorting:true},
            {label:'Delivered Revenue',value:'delivered_revenue',width:100,customComponent:roundComponent,hideSorting:true},
            {label:'Declined Revenue',value:'declined_revenue',hide:true,width:100,customComponent:roundComponent,hideSorting:true},
            {label:'Total Revenue',value:'total_revenue',width:100,customComponent:roundComponent,hideSorting:true},
            {label:'Approved Revenue',value:'manager_approved_revenue',width:100,customComponent:roundApprovedComponent,hideSorting:true},
            {label:'Validation Percentage',value:'validation_percentage_manual',width:100,hide:false,customComponent:validationPercentageComponent},
            {label:'Deduction',value:'deduction_revenue_manual',width:100,hide:false,customComponent:roundComponent},
            {label:'Pending',value:'pending_revenue_manual',width:100,hide:false,customComponent:roundComponent},
        ]
        return <div className='summaryActiveTabData'>
             {
                pendingOffersData.length!==0
                &&
                <div style={{fontSize:'17px',fontWeight:'500',marginTop:'10px',marginBottom:'20px'}}>
                    Major Offers Pending of 2023
                </div>
            }
            {
                pendingOffersData.length===0
                &&
                <div style={{display:'flex',justifyContent:'center',alignItems:'center',minHeight:'200px'}}>
                    <CircularProgress sx={{color:localStorage.getItem('ambientColor')}}/>
                </div>
            }   
            {
                pendingOffersData.length!==0
                &&
                <TableModel
                    id={'ytdAdvertiserBilling'}
                    serialOffset={offset}
                    tableHeaders={ytdTableHeaders}
                    tableValues={pendingOffersData}
                    setTableValues={()=>{}}
                    style={{background:'rgb(245,245,245)'}}
                    tableValueRow={'rest'}
                    setTableValueRow={()=>{console.log('test')}}
                    />
            }
        </div>
    }
    const summaryActivePopupData = () => {
        const getSeries = (value) => {
            if(popupData){
                var series = popupData.map(item=>{
                    return item[value] ? item[value] : 0
                })
            }
            else{
                series = []
            }
            return series
        }
        const state = {
            series: [
                {
                    name: "Delivered Quantity",
                    type:"line",
                    data: getSeries('conversions')
                  },
                  {
                    name: "Validation Percentage",
                    type:"line",
                    data: getSeries('validation_percentage_manual')
                  },
                {
                  name: "Revenue",
                  type:"column",
                  data:getSeries('conversions_verified')
                },
                {
                  name: "Validated Revenue",
                  type:"column",
                  data: getSeries('manager_approved_revenue')
                },
                {
                  name: "Deduction Revenue",
                  type:"column",
                  data:getSeries('deduction_revenue_manual')
                },
                {
                  name: "Pending Revenue",
                  type:"column",
                  data:getSeries('pending_revenue_manual')
                },
              ],
            options: {
              plotOptions: {
                bar: {
                  columnWidth: "30%"
                }
              },
              chart: {
                height: 350,
                width: 850,
                type: 'line',
                  dropShadow: {
                    enabled: false,
                    color: '#000',
                    top: 18,
                    left: 7,
                    blur: 10,
                    opacity: 0.2
                  },
                  toolbar: {
                    show: false
                  },
                  zoom: {
                    enabled: false,
                  }
                },
                colors: ['#4E3697','#8a2900','#8B75D7','#50E7A6','#FF6378','#FEBC4A'],
                dataLabels: {
                enabled: false,
              },
              grid:{
                show:true
              },
              markers: {
                  size: 4
                },
    
              stroke:{
                width:2,
                curve: 'smooth',
              },
              xaxis: {
                  crosshairs:{
                        show:false,
                  },  
                  labels: {
                    
                  },
                  // type: ',
                  tickAmount: 'dataPoints',
                  categories:getSeries('month').map((item)=>{
                    return new Date(item).toLocaleDateString('en-US',{month:'long'})
                  }),
                  forceNiceScale:true,
    
              },
              yaxis: [
                { 
                  min:Math.min(0),
                  max:Math.max(...getSeries('conversions'))+100,
                    forceNiceScale:true,
                    show:false,
                    opposite: true,
                    tickAmount: 5,
                    labels:{
                        formatter: (value) => { return parseInt(Math.round(value)) },
                    },
                },
                { 
                    // min:Math.min(0),
                    // max:Math.max(...getSeries('validation_percentage')),
                      forceNiceScale:true,
                      show:false,
                      opposite: true,
                      tickAmount: 5,
                      labels:{
                          formatter: (value) => { return (value ? parseInt(Math.round(value)) : 0 )+'%'},
                      },
                  },
                  { 
                    min:Math.min(0),
                    max:Math.max(...getSeries('total_revenue'))+100,
                      forceNiceScale:true,
                      show:false,
                      opposite: true,
                      tickAmount: 5,
                      labels:{
                          formatter: (value) => { return value ? parseInt(Math.round(value)) : 0 },
                      },
                  },
                  { 
                    min:Math.min(0),
                    max:Math.max(...getSeries('total_revenue'))+100,
                      forceNiceScale:true,
                      show:false,
                      opposite: true,
                      tickAmount: 5,
                      labels:{
                          formatter: (value) => { return value ? parseInt(Math.round(value)) : 0 },
                      },
                  },
                  { 
                    min:Math.min(0),
                    max:Math.max(...getSeries('total_revenue'))+100,
                      forceNiceScale:true,
                      show:false,
                      opposite: true,
                      tickAmount: 5,
                      labels:{
                          formatter: (value) => { return value ? parseInt(Math.round(value)) : 0 },
                      },
                  },
                  { 
                    min:Math.min(0),
                    max:Math.max(...getSeries('total_revenue'))+100,
                      forceNiceScale:true,
                      show:false,
                      opposite: true,
                      tickAmount: 5,
                      labels:{
                          formatter: (value) => { return value ? parseInt(Math.round(value)) : 0 },
                      },
                  },
            ],
              
              legend: {
                position: 'top',
                horizontalAlign: 'right',
                floating: true,
                offsetY: 0,
                offsetX: -5
              }
            },
        };
        
        const monthComponent = (valueItem,item) => {
            return <div style={{textDecoration:'none',color:'blue'}}>
                {new Date(valueItem[item.value]).toLocaleDateString('en-US',{year:'numeric',month:'long'})}
            </div>
        }

        const validationComponent = (valueItem,item,index,valueIndex,tableValues) => {
            if(valueItem){
                var [validated_offers,total_offers] = valueItem['validated'].split('/')
                var percent = (validated_offers*100)/total_offers
                if (percent<25){
                    var color = '#ff0000'
                    var highlightColor = '#ffcccc'
                }
                else if (percent<50){
                    color = '#ff6200'
                    highlightColor = '#ffe6cc'
                }
                else if (percent<75){
                    color = '#6f9400'
                    highlightColor = '#ebffc2'
                }
                else{
                    color = '#0f8500'
                    highlightColor = '#d2ffcc'
                }
                return <div style={{textDecoration:'none',color,fontWeight:500,background:highlightColor,marginLeft:(valueItem.advertiser_id==='Total' ? '5px' : '0px'),padding:'5px',width:'max-content',borderRadius:'10px',cursor:'pointer'}}>
                    {valueItem['validated']}
                </div>
            }
        }
        
        const roundComponent = (valueItem,item,index,valueIndex,tableValues) => {
            var value = Math.round(valueItem[item.value])
            return <div style={{marginLeft:(valueItem.advertiser_id === 'Total' ? '5px' : '0px')}}>
                {value}
            </div>
        }

        const roundApprovedComponent = (valueItem,item,index,valueIndex,tableValues) => {
            if(valueItem.advertiser_id==='Total'){
                return <div style={{marginLeft:'5px'}}>
                    {Math.round(valueItem[item.value])}
                </div>
            }
            return (valueItem.validated.split("/")[0]!=="0" ? Math.round(valueItem[item.value]) : 'Pending')
        }
        var ytdTableHeaders = [
            {label:'Month',value:'month',width:120,customComponent:monthComponent,hideSorting:true},
            {label:'Offers Validated',value:'offers_approved_percentage',width:120,customComponent:validationComponent,hideSorting:true},
            {label:'Delivered Qty',value:'conversions',hide:true,width:100,hideSorting:true},
            {label:'Delivered Revenue',value:'delivered_revenue',width:100,customComponent:roundComponent,hideSorting:true},
            {label:'Declined Revenue',value:'declined_revenue',hide:true,width:100,customComponent:roundComponent,hideSorting:true},
            {label:'Total Revenue',value:'total_revenue',width:100,customComponent:roundComponent,hideSorting:true},
            {label:'Approved Revenue',value:'manager_approved_revenue',width:100,customComponent:roundApprovedComponent,hideSorting:true},
            {label:'Validation Percentage',value:'validation_percentage_manual',width:100,hide:false,customComponent:validationPercentageComponent},
            {label:'Deduction',value:'deduction_revenue_manual',width:100,hide:false,customComponent:roundComponent},
            {label:'Pending',value:'pending_revenue_manual',width:100,hide:false,customComponent:roundComponent},
        ]
        return <div className='summaryActiveTabData'>
            <div style={{fontSize:'17px',fontWeight:'500',marginTop:'10px',marginBottom:'20px'}}>
                Summary of 2023
            </div>
            {
                popupData.length===0
                &&
                <div style={{display:'flex',justifyContent:'center',alignItems:'center',minHeight:'200px'}}>
                    <CircularProgress sx={{color:localStorage.getItem('ambientColor')}}/>
                </div>
            }   
            {
                popupData.length!==0
                &&
                <ReactApexChart className="graphs" options={state.options} series={state.series} type="line" height={"350px"} width={"100%"}/>
            }
            {
                popupData.length!==0
                &&
                <TableModel
                    id={'ytdAdvertiserBilling'}
                    serialOffset={offset}
                    tableHeaders={ytdTableHeaders}
                    tableValues={popupData}
                    setTableValues={()=>{}}
                    style={{background:'rgb(245,245,245)'}}
                    tableValueRow={'rest'}
                    setTableValueRow={()=>{console.log('test')}}
                    />
            }
        </div>
    }

    return (
        <div className='advertiserBillingContainer'>
            <div style={{position:'sticky',left:0,width:'100%',zIndex:100}}>
                <div style={{fontSize:'24px',fontWeight:'700',padding:'10px',paddingLeft:'20px'}}>Traffic Limiter - Advertisers</div>
                <div style={{display:'flex',alignItems:'center',paddingLeft:'20px',fontSize:'13px'}}>
                   <div>Current Month Revenue Last Updated At - </div>
                   <div style={{marginLeft:'5px'}}>
                        {loader && 
                            <CircularProgress size={11} sx={{color:'orange'}}/>
                        }
                        {!loader &&
                        <div style={{display:'flex',alignItems:'center',fontFamily:'sans-serif'}}>
                            {new Date(lastUpdatedAt*1000).toLocaleDateString('en-US',{month:'short',day:'2-digit',hour:'2-digit',minute:'2-digit'})}
                            <Tooltip title={'Refresh Revenue Now'}>
                                <RefreshTwoTone onClick={()=>{getTrafficLimitedAdvertiserList(true)}} sx={{fontSize:'14px',color:getLocalStorageValue('ambientColor'),marginLeft:'5px'}}/>
                            </Tooltip>
                        </div>
                        }
                   </div>
                </div>
                {
                    !loader
                    &&
                    <div style={{padding:'10px',paddingLeft:'20px',fontSize:'13px'}}>
                        <input id='enabled_advertisers' checked={showEnabledAdvertisers} type='checkbox' onClick={(e)=>{setShowEnabledAdvertisers(e.target.checked);getTrafficLimitedAdvertiserList(true,50,1,e.target.checked)}}/>
                        <label htmlFor='enabled_advertisers'>Show Enabled Advertisers</label>
                    </div>
                }
            </div>
            {
                showReasonPopup
                &&
                <div style={{position:'fixed',top:0,left:0,height:'100vh',width:'100vw',display:'flex',alignItems:'center',justifyContent:'center',zIndex:'2000'}}>
                    <div style={{minHeight:'20vh',boxShadow:'0 0 5px 1px rgb(200,200,200)',width:'40vw',background:'white',border:'1px solid rgb(200,200,200)'}}>
                        <div style={{borderBottom:'1px solid rgb(200,200,200)',height:'50px',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                            <div style={{paddingLeft:'10px'}}>Reason For Upgrading Limit</div>
                            <div style={{paddingRight:'10px'}} onClick={()=>{setShowReasonPopup(false)}}>X</div>
                        </div>
                        <div style={{display:'flex',flexDirection:'column',alignItems:'center',minHeight:'10vh',justifyContent:'center',padding:'15px'}}>
                            <Constants.CssTextField fullWidth onChange={e=>{setShowReasonPopup({...showReasonPopup,reason:e.target.value})}}/>
                            <Button style={{...Constants.CONTAINEDBUTTON,marginTop:'20px'}} onClick={()=>{upgradeLimit(showReasonPopup)}}>
                                {showReasonPopup.loader &&
                                <CircularProgress size={14} sx={{color:'white'}}/>
                                }
                                {
                                    !showReasonPopup.loader &&
                                    'Submit'  
                                }
                            </Button>
                        </div>
                    </div>
                </div>
            }
            {
                loader
                &&
                <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'30vh'}}>
                    <CircularProgress size={50} sx={{color:getLocalStorageValue('ambientColor')}}/>
                </div>
            }
            {
                !loader
                &&
                <TableModel
                    tableHeaders={tempTableHeaders}
                    // setTableHeaders={setTableHeaders}
                    serialOffset={offset}
                    tableValues={responseData}
                    setTableValues={setResponseData}
                    style={{background:'rgb(245,245,245)'}}
                    tableValueRow={'rest'}
                    setTableValueRow={()=>{console.log('test')}}
                />
            }
            {!loader && totalResults/limit>1 && typeof responseData === 'object' && responseData.length>0 && 
                <div style={{display:'flex',alignItems:'center',justifyContent:"space-between",paddingLeft:'20px',paddingRight:'20px',paddingBottom:'20px',position:'sticky',bottom:0,left:0,background:'white',zIndex:899}}>
                    <div>
                        <label style={{paddingRight:"5px",fontSize:'14px'}}>Limit</label>
                        <select value={limit} style={{outline:'none',border:'none'}} onChange={(e)=>{getTrafficLimitedAdvertiserList(false,e.target.value,1);setLimit(parseInt(e.target.value));setPage(1);}}>
                            {[5,16,10,50,100,200,300,500].map((item,index)=>{
                                return <option key={index} value={item}>{item}</option>
                            })}
                            {/* {statsType!=='offer' && [25,50,75,100].map((item,index)=>{
                                return <option key={index} value={item}>{item}</option>
                            })} */}
                        </select>
                    </div>
                    <Pagination count={Math.ceil(totalResults/limit)} page={page} onChange={(event,value)=>{getTrafficLimitedAdvertiserList(false,limit,value);setPage(value);window.scrollTo({top: 0,left: 0,behavior: 'smooth'});}} />
                </div>
            }
            {offersPopup
                &&
                <div className='activeSummaryTabContainer'>
                    <div className='activeSummaryTabWrapper'>
                        <div className='activeSummaryHeaderRow'>
                            <div style={{fontSize:'18px',fontWeight:'500'}}>
                                {offersPopup}
                            </div>
                            <div style={{width:'60px',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                                <Tooltip title={'Close'}>
                                    <Close sx={{color:'black'}} onClick={()=>{
                                        setOffersPopup(false);
                                        setPendingOffersData([]);
                                    }}/>
                                </Tooltip>
                            </div>
                        </div>
                        <div className='activeSummaryTabData'>
                            {summaryPendingOffers()}
                        </div>
                    </div>
                </div>
            }
            {
                popup
                &&
                <div className='activeSummaryTabContainer'>
                    <div className='activeSummaryTabWrapper'>
                        <div className='activeSummaryHeaderRow'>
                            <div style={{fontSize:'18px',fontWeight:'500'}}>
                                {popup}
                            </div>
                            <div style={{width:'60px',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                                <Tooltip title={'Close'}>
                                    <Close sx={{color:'black'}} onClick={()=>{
                                        setPopup(false);
                                        setPopupData([]);
                                    }}/>
                                </Tooltip>
                            </div>
                        </div>
                        <div className='activeSummaryTabData'>
                            {summaryActivePopupData()}
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default TrafficLimitedAdvertiser