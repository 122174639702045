import React, { useState } from 'react';
import '../../css/components/v2/sidebarv2.css';
import { Sling as Hamburger } from 'hamburger-react'
import Cookies from 'universal-cookie';
import { sidebarTabs } from '../../data/sidebarTabs';
import { NavLink } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import TopbarV2 from '../topbarV2/topbarV2';
import { getLocalStorageValue, sidebartabAllowed } from '../../util/util';
export const SidebarV2 = (props) => {
    const cookies = new Cookies();

    /// update CSS
    var ambientColor = localStorage.getItem('ambientColor')  ? localStorage.getItem('ambientColor') : "rgb(0, 166, 178)"
    var reverse = cookies.get('nightmode')==='false' ? false : true
    if(!reverse){
        var activeBackgroundColor = 'white'
        var activeFontColor = 'black'
        var fontColor = 'white'
    }
    else{
        activeBackgroundColor = ambientColor
        activeFontColor = 'white'
        fontColor = 'rgb(50,50,50)'
    }
    
    var tempSidebarOpen = false;
    if(cookies.get('sidebarOpen')){
        if(cookies.get('sidebarOpen')==='false'){
            tempSidebarOpen = false
        }
    }   
    
    const [sidebarOpen,setSidebarOpen] = useState(tempSidebarOpen)
    const [showHover,setShowHover] = useState(false)
    const [subnavList,setSubnavList] = useState([])
    const [subnavActive,setSubnavActive] = useState(window.location.pathname ? window.location.pathname : "")

    var allowedPages = localStorage.getItem('allowedPages') ? localStorage.getItem('allowedPages').split(",") : []

    const menuBarClicked = () => {
        localStorage.setItem('sidebarOpen',!sidebarOpen)
        cookies.set('sidebarOpen', !sidebarOpen, { path: '/' })
        setSidebarOpen(!sidebarOpen)
        setShowHover(false)
        setSubnavList([])
        props.setSidebarOpen(!sidebarOpen)
    }
    
    const isActive = (subnavActive,link) => {
        if(subnavActive.split('/')[1] === link.split('/')[1]){
            return true
        }
        return false        
    }
    return (
        <>
            <TopbarV2 sidebarOpen={sidebarOpen} menuBarClicked={menuBarClicked}/>
            <div className={props.sidebarOpen ? 'sidebarContainerV2Open' : 'sidebarContainerV2Close'} style={{width:(props.sidebarOpen ? '220px' : '65px')}} onMouseLeave={(e)=>{setShowHover(false);}}>
                <div className='block'>
                    <div className='openCloseSidebar' style={{minHeight:'50px',maxHeight:'50px',width:'100%'}}>
                        <div className='hamburgerIcon' style={{marginLeft:(props.sidebarOpen ? "8px" : "8px")}}><Hamburger toggled={sidebarOpen} toggle={menuBarClicked} size={20} duration={0.8}/></div>
                        <div className={"companyNameV2"} style={{cursor:'pointer'}} onClick={()=>{sessionStorage.clear();window.location.href='/dashboard'}}>{process.env.REACT_APP_COMPANY_NAME}</div>
                    </div>
                    <div style={{minHeight:'80%',maxHeight:'80%',overflowY:'auto',overflowX:'hidden'}}>
                        {
                            sidebarTabs.map((item,index)=>{
                                if(sidebartabAllowed(item.link,item)){
                                    return <div key={index} onMouseLeave={(e)=>{if(!item.subNav){setShowHover(false);}}}>
                                            { !item.subNav && 
                                                <NavLink className='menuList' to={item.link} style={{textDecoration:'none'}} activeStyle={{
                                                    backgroundColor: activeBackgroundColor,color:activeFontColor,borderRadius:'10px'
                                                }}
                                                onMouseEnter={(e)=>{if((localStorage.getItem('hoverSidebarItem') && sidebarOpen) || !sidebarOpen){setShowHover(item.title)}}}
                                                onClick={(e)=>{setSubnavActive("")}}
                                                >  
                                                    <div className='menuItemIcon'>{item.icon}</div>
                                                    <div className={props.sidebarOpen ? "menuItemName" : "menuItemNameHidden"}>{item.title}</div>
                                                </NavLink>
                                            }
                                            {
                                                item.subNav && 
                                                <div className={isActive(subnavActive,item.link) ? 'menuList menuListActive' : 'menuList' } onMouseEnter={(e)=>{if((localStorage.getItem('hoverSidebarItem') && sidebarOpen) || !sidebarOpen){setShowHover(item.title)}}} onClick={()=>{if(subnavList.includes(item.title)){setShowHover(false);setSubnavList(subnavList.filter(e=>e!==item.title))}else{setSubnavList([...subnavList,item.title])}}}>  
                                                    <div style={{display:'flex',width:"90%",alignItems:'center'}}>
                                                        <div className='menuItemIcon'>{item.icon}</div>
                                                        <div className={props.sidebarOpen ? "menuItemName" : "menuItemNameHidden"}>{item.title}</div>
                                                    </div>
                                                    
                                                    {sidebarOpen && <div className={(subnavList.includes(item.title) || showHover===item.title) ? 'showSubnavIcon' : 'showSubnavIconOpen'}> {">"}</div>}
                                                </div>
                                            }
                                            {
                                                item.subNav && sidebarOpen &&
                                                <div id="subnavMenu" className={(subnavList.includes(item.title) || showHover===item.title) ? 'subnavOpenV2' : 'subnavCloseV2'}>
                                                        {item.subNav.map((subnavItem,subnavIndex)=>{
                                                            if(sidebartabAllowed(subnavItem.link,subnavItem) 
                                                                )
                                                            {
                                                                return <NavLink 
                                                                            key={subnavIndex}
                                                                            to={(window.location.href.includes('?') && window.location.href.includes('statistics') ? subnavItem.link+"?"+window.location.href.split("?")[1] : subnavItem.link)}                        
                                                                            className={(subnavList.includes(item.title) || showHover===item.title) ? 'subnavOpenTextV2' : 'subnavCloseTextV2'} 
                                                                            onClick={()=>{setSubnavActive(subnavItem.link)}}
                                                                            activeStyle={{
                                                                                backgroundColor: activeBackgroundColor,
                                                                                color:activeFontColor,
                                                                                borderRadius:'10px'
                                                                            }}
                                                                            >
                                                                                {subnavItem.title}
                                                                        </NavLink>
                                                            }
                                                            return null;
                                                        })}
                                                </div>
                                            }
                                            {
                                                showHover===item.title && !item.subNav && !sidebarOpen &&
                                                    <div className='menuContainer'>
                                                        <div className='menuHover'>
                                                                <NavLink 
                                                                    to={item.link} 
                                                                    className='subnavItemSelect' 
                                                                    activeStyle={{
                                                                            backgroundColor: activeBackgroundColor,
                                                                            color:activeFontColor,
                                                                            borderRadius:'10px'
                                                                        }} 
                                                                    onClick={(e)=>{setShowHover(false);setSubnavActive("");}}>
                                                                        {item.title}
                                                                </NavLink>
                                                        </div>
                                                    </div>
                                            }
                                            {
                                                showHover===item.title && item.subNav && !sidebarOpen &&
                                                <div className='menuContainer' style={{zIndex:'10000',marginTop:showHover==='Billing' ? '-25vh' : '-42.5px'}}>
                                                    <div className='subnavClosedHover'>
                                                        <div style={{display:'flex',justifyContent:'center',paddingTop:'5px',paddingBottom:'10px',userSelect:'none'}}>
                                                            {item.title}
                                                        </div> 
                                                        <div style={{borderBottom:'1px solid '+fontColor,marginLeft:'5%',marginRight:'5%',marginBottom:'2%'}}></div>
                                                        <div style={{maxHeight:'60vh',overflowY:'auto',display:'grid'}}>
                                                            {item.subNav.map((subnavItem,subnavIndex)=>{
                                                                if(sidebartabAllowed(subnavItem.link,subnavItem) 
                                                                    ){
                                                                        return <NavLink 
                                                                                    key={subnavIndex}
                                                                                    to={(window.location.href.includes('?') && window.location.href.includes('statistics') ? subnavItem.link+"?"+window.location.href.split("?")[1] : subnavItem.link)}                        
                                                                                    className='subnavItemSelect' 
                                                                                    activeStyle={{
                                                                                            backgroundColor: activeBackgroundColor,
                                                                                            color:activeFontColor,
                                                                                            borderRadius:'10px'
                                                                                        }} 
                                                                                    onClick={(e)=>{setShowHover(false);setSubnavActive(subnavItem.link)}}>
                                                                                        {subnavItem.title}
                                                                                </NavLink>
                                                                    }
                                                                    return null;
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                }
                                return null;
                            })
                        }
                    </div>
                    <div style={{minHeight:'7%',maxHeight:'7%',}}>
                        <NavLink to={"/profile"} style={{textDecoration:'none'}} className={'profilebar'} onMouseEnter={()=>{setShowHover('profile')}}>  
                            <div style={{textDecoration:'none',display:'flex',width:"90%",alignItems:'center'}}>
                                <div className='profileLogo'>{localStorage.getItem('username')[0]}</div>
                                <div className={props.sidebarOpen ? "menuItemName" : "menuItemNameHidden"}>{localStorage.getItem('username')}</div>
                            </div>
                            {sidebarOpen && <div className='profileThreeDotButton' onClick={(e)=>{e.stopPropagation();localStorage.clear();window.location.reload();}}> <LogoutIcon style={{height:'17px'}}/> </div>}
                        </NavLink>
                        {
                            showHover==="profile" && !sidebarOpen &&
                            <div className='profileContainer'>
                                <div className='profileClosedHover'>
                                    <div style={{display:'flex',justifyContent:'center',paddingTop:'5px',paddingBottom:'10px',userSelect:'none'}}>
                                        {"Profile"}
                                    </div> 
                                    <div style={{borderBottom:'1px solid '+fontColor,marginLeft:'5%',marginRight:'5%',marginBottom:'2%'}}></div>
                                    <NavLink 
                                        to={"/profile"} 
                                        className='subnavItemSelect' 
                                        activeStyle={{
                                                backgroundColor: activeBackgroundColor,
                                                color:activeFontColor,
                                                borderRadius:'10px'
                                            }} 
                                        onClick={(e)=>{setShowHover(false);}}>
                                            {"Profile"}
                                    </NavLink>
                                    <div 
                                        className='subnavItemSelect' 
                                        onClick={()=>{localStorage.clear();window.location.reload();}}>
                                            {"Logout"}
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default SidebarV2;

