import React, { useCallback, useEffect, useState } from 'react';
import '../../css/pages/v2/dashboard.css';
import CountUp from 'react-countup';
import { activeAdvertiserApi, activeAffiliatesApi, activeOffersApi, getAccountManager, getStats, getTopAffiliates, getTopOffers, runningOfferApi } from './dashboardApi';
import { graphStatsBoxes, summaryGraph } from './dashboardGraphs';
import TelegramIcon from '@mui/icons-material/Telegram';
import SkypeIcon from '../../img/skype.png';
import DraftsIcon from '@mui/icons-material/Drafts';
import PhoneIcon from '@mui/icons-material/Phone';
import { NavLink } from "react-router-dom";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DashboardLoader from './dashboardLoader';
import { getLocalStorageValue, setLocalStorageValue } from '../../util/util';
import SettingsIcon from '@mui/icons-material/Settings';
import customizationDashboard from '../../img/customizationDashboard.png'
//draganddrop
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import { CircularProgress } from '@mui/material';
import CreatableSelect from 'react-select/creatable';
import * as Constants from '../../data/constants';
import CachedIcon from '@mui/icons-material/Cached';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';

const DragItem = styled.div`
  padding-bottom: 10px;
  border-radius: 6px;
  margin: 0 0 8px 0;
  display: flex;
  align-items:center;
`;


function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    if(false){
        console.log(value)
    }
    return () => setValue(value => value + 1); // update the state to force render
}

const DashboardPageV2 = () => {
    document.title = 'Dashboard'
    const [counter, setCounter] = useState(0)
    const [fifteenDayData, setFifteenDayData] = useState({dates:[],clicks:[],conversions:[],revenue:[],earnings:[],payout:[]})
    
    var testData = (counter) => {
        var tempStart = [1500,1300,1100,900,700,500,300,100,300,500,700,900,1100,1300,1500]
        var emptyArray = tempStart.slice(counter,tempStart.length-1)
        for(var i=0; i<=counter; i++){
            emptyArray.push(tempStart[i])
        }
        return emptyArray
    }
    
    useEffect(() => {
        const timeout = setTimeout(() => {
            if((counter || counter===0)){
                var tempFifteenDayData = {
                    dates : [0,1,2,3,4],
                    clicks : testData(counter),
                    conversions : testData(counter),
                    revenue : testData(counter),
                    earnings : testData(counter),
                    payout : testData(counter),
                }
                if(counter<14){
                    setCounter(counter + 1);
                }
                else{
                    setCounter(0)
                }
                setFifteenDayData(tempFifteenDayData);
            }
        }, 150);
      
          return () => {
            clearTimeout(timeout);
          };
        }, [counter]);
    
    const refreshComponent = useForceUpdate()
    const [manualUseEffect, setManualUseEffect] = useState(false)
    const [firstRowColumns, setFirstRowColumns] = useState([
        {id:'clicks',label:'Clicks',value:'clicks',todayEnd:0,graphType:'area',difference:0,color:'#83D3D4'},
        {id:'conversions',label:'Conversion',value:'conversions',todayEnd:0,graphType:'area',difference:0,color:'#358183'},
        {id:'revenue',label:'Revenue',value:'revenue',todayEnd:0,graphType:'area',suffix:' $',difference:0,color:'#911E1B'},
        {id:'declined',label:'Declined Revenue',value:'declined',todayEnd:0,graphType:'area',suffix:' $',difference:0,color:'#911E1B'},
        {id:'payout',label:'Payouts',value:'payout',todayEnd:0,graphType:'area',suffix:' $',difference:0,color:'#358183'},
        {id:'earnings',label:'Earnings',value:'earnings',todayEnd:0,graphType:'area',suffix:' $',difference:0,color:'#F18052'},
    ])
    const [sevenDayData, setSevenDayData] = useState({dates:[],clicks:[],conversions:[],declined:[],revenue:[],earnings:[],payout:[]})
    
    const [accountManagerDetails, setAccountManagerDetails] = useState({first_name:"",last_name:"",contact_number:"",email:"",skype:"",telegram:""})
    const [topOffers, setTopOffers] = useState([])
    const [topAffiliates, setTopAffiliates] = useState([])
    const [spinnedElements, setSpinnedElements] = useState([])
    const [showCustomizationPopup, setCustomizationPopup] = useState(false)
    // const [activeOffers, setActiveOffers] = useState(0)
    // const [activeAffiliates, setActiveAffiliates] = useState(0)
    // const [activeAdvertiser, setActiveAdvertiser] = useState(0)
    // const [runningOffer, setRunningOffer] = useState(0)
   
    var defaultDateRanges = {
        'Main Graph':7,
        'Live Offers':7,
        'Top Offers':7,
        'Top Affiliates':7,
        'Active Affiliates':7,
        'Active Advertiser':7,
    }
    var tempDateRanges = defaultDateRanges
    if(localStorage.getItem('dashboardCustomization')){
        tempDateRanges = JSON.parse(localStorage.getItem('dashboardCustomization'))
    }
    const [customizeDateRanges, setCustomizeDateRanges] = useState(tempDateRanges)
    const [backupCustomizeDateRanges, setBackupCustomizeDateRanges] = useState(tempDateRanges)
    const [activeStatsList, setActiveStatsList] = useState([
        {id:'activeOffers', label:"Active Offers",value:0},
        {id:'runningOffers', label:"Live Offers",value:0},
        {id:'activeAffiliates', label:"Active Affiliates",value:0},
        {id:'activeAdvertiser', label:"Active Advertiser",value:0},
    ])

    const [loaderDict, setLoaderDict] = useState({
        oneDayData : true,
        sevenDayData : true,
        accountManager : true,
        topAffiliates : true,
        topOffers : true,
        activeOffers : true,
        activeAffiliates : true,
        activeAdvertiser : true,
        runningOffers : true,
    })

    const [activeTopTab, setActiveTopTab] = useState("offers")
    // const changeWidthOfStatBoxes = () => {
    //     if(document.getElementById('dashboard_row-one_column-one')){
    //         firstRowColumns.forEach(element => {
    //             document.getElementById('dashboard_'+element.label).style.height = document.getElementById('dashboard_'+element.label).getBoundingClientRect().width + "px"
    //         })
    //     }
    // }

    const updateStatsIndexes = (twoDaysData) => {
        var tempfirstRowColumns = firstRowColumns;
        tempfirstRowColumns.forEach(element => {

            var dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric'};
            for(var index in twoDaysData){
                if(twoDaysData[index].date === new Date().toLocaleDateString("sv-SE", dateOptions)){
                    element.todayEnd = twoDaysData[index][element.value]
                }
                else{
                    if(!element.todayEnd){
                        element.todayEnd = 0
                    }
                }
                if(twoDaysData[index].date === new Date(new Date().getTime()- 86400000).toLocaleDateString("sv-SE", dateOptions)){
                    element.yesterday = twoDaysData[index][element.value]
                }
                else{
                    if(!element.yesterday){
                        element.yesterday = 0
                    }
                }
            }
            element.difference = element.todayEnd - element.yesterday
        })
        refreshComponent(tempfirstRowColumns)
        setFirstRowColumns(tempfirstRowColumns)
    }

    const getSevenDaysData = (fifteenDayData) => {
        fifteenDayData = fifteenDayData.result
        var i = 0 
        var sevenDayDataGraph = {dates:[],clicks:[],conversions:[],revenue:[],earnings:[],payout:[],declined:[]}
        var fifteenDayDataGraph = {dates:[],clicks:[],conversions:[],revenue:[],earnings:[],payout:[],declined:[]}
        for(var date in fifteenDayData){
            if(i<=6 && fifteenDayData.length>6){
                sevenDayDataGraph.dates.push(fifteenDayData[fifteenDayData.length - 7 + i].date)
                sevenDayDataGraph.clicks.push(fifteenDayData[fifteenDayData.length - 7 + i].clicks)
                sevenDayDataGraph.conversions.push(fifteenDayData[fifteenDayData.length - 7 + i].conversions)
                sevenDayDataGraph.revenue.push(fifteenDayData[fifteenDayData.length - 7 + i].revenue)
                sevenDayDataGraph.earnings.push(fifteenDayData[fifteenDayData.length - 7 + i].earnings)
                sevenDayDataGraph.payout.push(fifteenDayData[fifteenDayData.length - 7 + i].payout)
                sevenDayDataGraph.declined.push(fifteenDayData[fifteenDayData.length - 7 + i].declined)
            }
            fifteenDayDataGraph.dates.push(fifteenDayData[date].date)
            fifteenDayDataGraph.clicks.push(fifteenDayData[date].clicks)
            fifteenDayDataGraph.conversions.push(fifteenDayData[date].conversions)
            fifteenDayDataGraph.revenue.push(fifteenDayData[date].revenue)
            fifteenDayDataGraph.earnings.push(fifteenDayData[date].earnings)
            fifteenDayDataGraph.payout.push(fifteenDayData[date].payout)
            fifteenDayDataGraph.declined.push(fifteenDayData[date].declined)
            i+=1
        }
        setCounter(false);
        setSevenDayData(sevenDayDataGraph)
        setFifteenDayData(fifteenDayDataGraph)
    }
    
    var dateRangeHelper = {
        1:'Today',
        2:'Yesterday And Today',
        7:'Last 7 days',
        30:'Last 30 days',
        15:'Last 15 days',
        60:'Last 60 days'
    }

    const initFunction = async(forced) => {
        setCounter(0);
        setLoaderDict({
            oneDayData : true,
            sevenDayData : true,
            accountManager : true,
            topAffiliates : true,
            topOffers : true,
            activeOffers : true,
            activeAffiliates : true,
            activeAdvertiser : true,
            runningOffers : true,
        })
        refreshComponent(1234)
        forced = true;
        try{
            if(forced){
                throw Error
            }
            var resp = getLocalStorageValue('dashboardStats')
            if(!sessionStorage.getItem('dbtimelapse') || new Date().getTime() - sessionStorage.getItem('dbtimelapse')>10*60*1000){
                throw Object.assign(
                    new Error('lets hit the api, 10 minute timer done.'),
                    { code: 1001 }
                );
            }
            setCounter(false);
            updateStatsIndexes(resp.oneDayData.result)
            if(resp.accountManagerDetails){
                setAccountManagerDetails(resp.accountManagerDetails)
            }
            if(resp.fifteenDayData){
                getSevenDaysData(resp.fifteenDayData)
            }
            if(resp.topOffers){
                setTopOffers(resp.topOffers)
            }
            if(resp.topAffiliates){
                setTopAffiliates(resp.topAffiliates)
            }
            // setActiveOffers(resp.tempActiveOffers)
            // setActiveAffiliates(resp.tempActiveAffiliates)
            // setActiveAdvertiser(resp.tempActiveAdvertiser)
            // setRunningOffer(resp.tempRunningOffer)
            setLoaderDict({
                oneDayData : false,
                sevenDayData : false,
                accountManager : false,
                topAffiliates : false,
                topOffers : false,
                activeOffers : false,
                activeAffiliates : false,
                activeAdvertiser : false,
                runningOffers : false,
            })
            if(resp.tempActiveOffers && resp.tempActiveAffiliates && resp.tempActiveAdvertiser && resp.tempRunningOffer){
                var tempActiveStats = []
                var statsCheck = {activeOffers:resp.tempActiveOffers,activeAffiliates:resp.tempActiveAffiliates,activeAdvertiser:resp.tempActiveAdvertiser,runningOffers:resp.tempRunningOffer}
                for(var index in activeStatsList){
                    tempActiveStats.push({...activeStatsList[index],value:statsCheck[activeStatsList[index].id]})
                }
                setActiveStatsList(tempActiveStats)
            }
        }
        catch(err){
            
            // var dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric'};
            // var startDate = new Date(new Date().getTime()-86400000*15).toLocaleDateString("sv-SE", dateOptions)
            // var lastDate = new Date(new Date().getTime()-86400000).toLocaleDateString("sv-SE", dateOptions)
            // var endDate = new Date().toLocaleDateString("sv-SE", dateOptions)
            
            var tempLoaderDict = loaderDict

            var oneDayData = await getStats(1)
            if(oneDayData){
                updateStatsIndexes(oneDayData.result)
            }
            tempLoaderDict["oneDayData"] = false
            setLoaderDict(tempLoaderDict)
            
            var accountManagerDetails = await getAccountManager()
            if(accountManagerDetails){
                setAccountManagerDetails(accountManagerDetails)
            }
            tempLoaderDict['accountManager'] = false
            setLoaderDict(tempLoaderDict)

            var fifteenDayData = await getStats(parseInt(customizeDateRanges['Main Graph']) - 1)
            if(fifteenDayData){
                getSevenDaysData(fifteenDayData)
            }
            else{
                getSevenDaysData({}) 
            }
            tempLoaderDict['sevenDayData'] = false
            setLoaderDict(tempLoaderDict)

            var topOffers = await getTopOffers(customizeDateRanges['Top Offers'])
            if(topOffers){
                setTopOffers(topOffers)
            }
            tempLoaderDict['topOffers'] = false
            setLoaderDict(tempLoaderDict)
            refreshComponent(topOffers)

            var topAffiliates = await getTopAffiliates(customizeDateRanges['Top Affiliates'])
            if(topAffiliates){
                setTopAffiliates(topAffiliates)
            }
            tempLoaderDict['topAffiliates'] = false
            setLoaderDict(tempLoaderDict)
            refreshComponent(topAffiliates)

            var tempActiveStatsList = activeStatsList

            var tempActiveOffers = await activeOffersApi()
            if(tempActiveOffers){
                tempActiveStatsList[0].value = tempActiveOffers
                setActiveStatsList(tempActiveStatsList)
            }
            // setActiveOffers(tempActiveOffers)
            tempLoaderDict['activeOffers'] = false
            setLoaderDict(tempLoaderDict)
            refreshComponent(tempActiveStatsList)

            var tempActiveAffiliates = await activeAffiliatesApi(customizeDateRanges['Active Affiliates'])
            if(tempActiveAffiliates){
                tempActiveStatsList[2].value = tempActiveAffiliates
                setActiveStatsList(tempActiveStatsList)
            }
            // setActiveAffiliates(tempActiveAffiliates)
            tempLoaderDict['activeAffiliates'] = false
            setLoaderDict(tempLoaderDict)
            refreshComponent(tempActiveStatsList)

            var tempActiveAdvertiser = await activeAdvertiserApi(customizeDateRanges['Active Advertiser'])
            if(tempActiveAdvertiser){
                tempActiveStatsList[3].value = tempActiveAdvertiser
                setActiveStatsList(tempActiveStatsList)
            }
            // if(tempActiveAdvertiser){
            //     setActiveAdvertiser(tempActiveAdvertiser)
            // }
            tempLoaderDict['activeAdvertiser'] = false
            setLoaderDict(tempLoaderDict)
            refreshComponent(tempActiveStatsList)


            var tempRunningOffer = await runningOfferApi(customizeDateRanges['Live Offers'])
            if(tempRunningOffer){
                tempActiveStatsList[1].value = tempRunningOffer
                setActiveStatsList(tempActiveStatsList)
            }
            // if(tempRunningOffer){
            //     setRunningOffer(tempRunningOffer)
            // }
            tempLoaderDict['runningOffers'] = false
            setLoaderDict(tempLoaderDict)
            refreshComponent(tempActiveStatsList)
            
            // var tempActiveStats = []
            // var statsCheck = {activeOffers:tempActiveOffers,activeAffiliates:tempActiveAffiliates,activeAdvertiser:tempActiveAdvertiser,runningOffers:tempRunningOffer}
            // for(var index in activeStatsList){
            //     tempActiveStats.push({...activeStatsList[index],value:statsCheck[activeStatsList[index].id]})
            // }
            // setActiveStatsList(tempActiveStats)

            setLocalStorageValue('dashboardStats',{oneDayData,accountManagerDetails,fifteenDayData,topOffers,topAffiliates,tempActiveOffers,tempActiveAffiliates,tempActiveAdvertiser,tempRunningOffer})
            sessionStorage.setItem('dbtimelapse',new Date().getTime())
        }
    }
    if(!manualUseEffect){
        setManualUseEffect(true)
        initFunction()
    }
    
    useEffect(() => {
        document.addEventListener('keydown',(e)=>{
            if(e.key==='Escape'){
                setCustomizationPopup(false);
            }
        })
        
        return () => {
            document.removeEventListener('keydown',(e)=>{
                if(e.key==='Escape'){
                    setCustomizationPopup(false);
                }
            })
        }
    }, [])
    const onDragEnd = (result,value,setValue) => {
        const newItems = Array.from(value);
        const [removed] = newItems.splice(result.source.index, 1);
        newItems.splice(result.destination.index, 0, removed);
        setValue(newItems);
    };

    const millionFunction = useCallback((value) => {
        if(value>0){
            var millionValue = value
            if(value > 1000000){
                millionValue = (millionValue/1000000).toFixed(2) + "M"
            }
            return millionValue
        }
        else{
            return 0;
        }
      },
      [],
    )

    const colOneRowOne = () => {
        return <DragDropContext onDragEnd={(result)=>{onDragEnd(result,firstRowColumns,setFirstRowColumns)}}>
                    <Droppable droppableId="droppable" direction='horizontal'>
                        {(provided) => (
                        <div className='dashboardV2ColumnOneRowOne' {...provided.droppableProps} ref={provided.innerRef}>
                            {firstRowColumns.map((item, index) => (
                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            snapshot={snapshot}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps} 
                                            key={index} id={"dashboard_"+item.label} className={spinnedElements.includes(item.value) ? 'dashboardStatsWrapper spinned' : 'dashboardStatsWrapper'} onClick={()=>{if(!spinnedElements.includes(item.value)){setSpinnedElements([...spinnedElements,item.value])}else{setSpinnedElements(spinnedElements.filter(e => e!==item.value))}}}>
                                            {index===0 && <div style={{fontWeight:600,position:'absolute',top:'-10px',background:'white',left:'25px',transform:(spinnedElements.includes(item.value) ? 'rotateY(180deg)' : 'rotateY(0deg)')}}>Today</div>}

                                            <div style={{position:'absolute',height:'70%',opacity:(spinnedElements.includes(item.value) ? '1' : '0.1'),transform:(spinnedElements.includes(item.value) ? 'rotateY(180deg)' : 'rotateY(0deg)')}}>
                                                {graphStatsBoxes(item.label,item.value,sevenDayData,item.graphType,spinnedElements.includes(item.value),item.color)}
                                            </div>
                                            <div className={spinnedElements.includes(item.value) ? 'hideElements' : ''} style={{fontSize:'37px'}}>
                                                <CountUp
                                                    start={0}
                                                    end={item.todayEnd}
                                                    separator=','
                                                    formattingFn = {millionFunction}
                                                    duration={1}
                                                    // preserveValue={true}
                                                />
                                                {item.suffix && getCurrencySymbol()}
                                            </div>
                                            
                                            <div className={spinnedElements.includes(item.value) ? 'hideElements' : ''}>{item.label}</div>
                                            <div className={spinnedElements.includes(item.value) ? '' : 'hideElements'} style={{position:"absolute",top:"10%",transform:'rotateY(180deg)'}}>{item.label}</div>
                                            <div className={spinnedElements.includes(item.value) ? 'hideElements' : ''} style={{fontSize:'20px',position:'absolute',bottom:"5%",display:'flex',alignItems:'flex-end',color:(item.difference >= 0 ? 'green' : "red")}}>
                                                {
                                                item.difference >= 0 ? 
                                                    <ArrowDownwardIcon sx={{transform:'rotate(-180deg)'}}/>
                                                : 
                                                    <ArrowDownwardIcon/>
                                                }
                                                {item.difference || item.difference===0 ? item.difference.toString().includes(".") ? Math.abs(item.difference.toFixed(2)) : millionFunction(Math.abs(item.difference)) : ""} 
                                                <div style={{fontSize:'13px',paddingLeft:'4px',color:'rgb(80,80,80)'}}>last Day</div>
                                            </div>
                                            
                                    </div>
                                    )}
                                </Draggable>
                            ))}
                        </div>
                        )}
                    </Droppable>
                </DragDropContext>
    }

    const colOneRowOneTwo = () => {
        return <DragDropContext onDragEnd={(result)=>{onDragEnd(result,firstRowColumns,setFirstRowColumns)}}>
                    <Droppable droppableId="droppable" direction='horizontal'>
                        {(provided) => (
                        <div className='dashboardV2ColumnOneRowOne' {...provided.droppableProps} ref={provided.innerRef}>
                            {firstRowColumns.map((item, index) => (
                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            snapshot={snapshot}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps} 
                                            key={index} id={"dashboard_"+item.label} className={spinnedElements.includes(item.value) ? 'dashboardStatsWrapper' : 'dashboardStatsWrapper'} style={{minHeight:'100px',height:"100px"}} onClick={()=>{if(!spinnedElements.includes(item.value)){setSpinnedElements([...spinnedElements,item.value])}else{setSpinnedElements(spinnedElements.filter(e => e!==item.value))}}}>
                                            {index===0 && <div style={{fontWeight:600,position:'absolute',top:'-10px',background:'white',left:'25px'}}>Yesterday</div>}
                                            <div style={{fontSize:'37px'}}>
                                                <CountUp
                                                    start={0}
                                                    end={item.yesterday}
                                                    separator=','
                                                    formattingFn = {millionFunction}
                                                    duration={1}
                                                    // preserveValue={true}
                                                />
                                                {item.suffix && getCurrencySymbol()}
                                            </div>
                                            
                                            <div className={spinnedElements.includes(item.value) ? '' : ''}>{item.label}</div>
                                    </div>
                                    )}
                                </Draggable>
                            ))}
                        </div>
                        )}
                    </Droppable>
                </DragDropContext>
        // return  <div className='dashboardV2ColumnOneRowOneTwo' style={{position:"relative"}}>
        //             <div style={{fontWeight:600,position:'absolute',top:'-13px',background:'white',left:'25px'}}>Yesterday</div>
        //             {firstRowColumns.map((item, index) => {
        //                     return <div key={index} id={"dashboard_"+item.label} className='dashboardV2ColumnOneRowOneTwoWrapper' style={{minHeight:'80px',marginTop:'0px'}}>
        //                             <div style={{fontSize:'28px'}}>
        //                                 <CountUp
        //                                     start={0}
        //                                     end={item.yesterday}
        //                                     separator=','
        //                                     formattingFn = {millionFunction}
        //                                     duration={1}
        //                                     // preserveValue={true}
        //                                 />
        //                                 {item.suffix}
        //                             </div>
        //                             <div style={{fontSize:'16px'}}>{item.label}</div>
        //                     </div>
        //                     })}
        //             </div>
    }
    const colOneRowTwo = () => {
        return <div className='fifteenDaysGraph'>
        <div className='fifteenDaysGraphWrapper'>  
            <div style={{display:'flex',justifyContent:'space-between',width:'100%',fontSize:'28px',paddingLeft:'15px',fontWeight:600}}>
                Statistics of {customizeDateRanges['Main Graph']} Days
                <div style={{display:"flex",flexWrap:'wrap',alignItems:'center'}}>
                    <select style={{marginRight:'10px',outline:'none',border:'none'}} value={localStorage.getItem('panelDefaultCurrency') ? localStorage.getItem('panelDefaultCurrency') : 'ALL/USD'} onChange={(e)=>{localStorage.setItem('panelDefaultCurrency',e.target.value);initFunction(true);refreshComponent(12)}}>
                        <option value={'ALL/USD'}>ALL/USD</option>
                        {getLocalStorageValue('adminPanelLoginData').panelSettings && Object.keys(getLocalStorageValue('adminPanelLoginData').panelSettings.currency).map((item,index)=>{
                            return <option value={item} key={index}>
                                {item}
                            </option>
                        })}
                    </select>
                    <SettingsIcon sx={{fontSize:'22px',marginRight:'35px',cursor:'pointer'}} onClick={()=>{setCustomizationPopup(true);setBackupCustomizeDateRanges(customizeDateRanges);}}/>
                </div>
            </div>
            <div style={{height:'45vh',width:'100%'}}>{summaryGraph(fifteenDayData)}</div>
        </div>
    </div>
    }

    const colOneRowThree = () => {
        return <DragDropContext onDragEnd={(result)=>{onDragEnd(result,activeStatsList,setActiveStatsList)}}>
                    <Droppable droppableId="droppable12" direction='horizontal'>
                        {(provided) => (
                        <div className='dashboardStatsRowThree' {...provided.droppableProps} ref={provided.innerRef}>
                            {activeStatsList.map((item, index) => (
                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            key={index}
                                            className='dashboardActiveStatsWrapper'
                                            ref={provided.innerRef}
                                            snapshot={snapshot}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps} 
                                        >
                                            <div className='dashboardActiveOffers'>
                                                {loaderDict[item.id] && <CircularProgress size={15} sx={{position:'absolute',top:10,right:20}}/>}
                                                <CountUp
                                                    start={0}
                                                    end={item.value}
                                                    duration={1}
                                                    preserveValue={true}
                                                />
                                            </div>
                                            {item.label}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                        </div>
                        )}
                    </Droppable>
                </DragDropContext>
    }
    var tempColOneRows = [
        {id:'one',value:colOneRowOne()},
        {id:'two',value:colOneRowTwo()},
        {id:'onetwo',value:colOneRowOneTwo()},
        {id:'three',value:colOneRowThree()},
    ]
    const [colOneRows, setColOneRows] = useState(tempColOneRows)

    const colOne = () => {
        return <DragDropContext onDragEnd={(result)=>{onDragEnd(result,colOneRows,setColOneRows)}}>
                    <Droppable droppableId="droppable">
                        {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {colOneRows.map((item, index) => (
                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                    {(provided, snapshot) => (
                                            <DragItem
                                                ref={provided.innerRef}
                                                snapshot={snapshot}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >   
                                                {item.id==='one' && colOneRowOne()}
                                                {item.id==='onetwo' && colOneRowOneTwo()}
                                                {item.id==='two' && colOneRowTwo()}
                                                {item.id==='three' && colOneRowThree()}
                                          </DragItem>
                                    )}
                                </Draggable>
                            ))}
                        </div>
                        )}
                    </Droppable>
                </DragDropContext>
    }

    const getDateRangeOptions = (item) => {
        var options = []
        for(var dateRangeItem in dateRangeHelper){
            if(item==='Main Graph' && dateRangeItem<7){

            }
            else{
                options.push({label:dateRangeHelper[dateRangeItem],value:dateRangeItem})
            }
        }
        return options
    }

    const dateRangeSelectFuntion = (e,item) => {
        if(item==='Main Graph' && e.value<7){
            e = {label:e.label,value:7}
        }
        else if(e.value>60){
            e = {label:e.label,value:60}
        }
        
        setCustomizeDateRanges({...customizeDateRanges,[item]:e.value})
    }

    const getCurrencySymbol = () => {
        if(localStorage.getItem('panelDefaultCurrency') && localStorage.getItem('panelDefaultCurrency')!=="ALL/USD"){
            return Constants.CURRENCY_LIST_SYMBOLS[localStorage.getItem('panelDefaultCurrency').toUpperCase()]
        }
        else{
            return Constants.CURRENCY_LIST_SYMBOLS['usd'.toUpperCase()]
        }
    }
    return (
        <div className='dashboardContainerV2'>
            <div className='dashboardV2ColumnOne'>
                {colOne()}
            </div>
            <div className='dashboardV2ColumnTwo'>
                <div className='dashboardStatsColumnTwoRowOne'>
                    <div className='dashboardAccountManagerWrapper'>
                        {
                            loaderDict.accountManager && 
                            <DashboardLoader/>
                        }
                        {
                            !loaderDict.accountManager && 
                            <>
                                <div className='accountManagerLabel'>Account Manager</div>
                                <div style={{display:'flex',alignItems:'center',marginBottom:'20px'}}>
                                    <div className='accountManagerLogo'>
                                        {accountManagerDetails && accountManagerDetails.first_name ? accountManagerDetails.first_name[0] : "U"}
                                    </div>
                                    <div className='accountManagerName'>
                                        {accountManagerDetails && accountManagerDetails.first_name + " " + accountManagerDetails.last_name}
                                    </div>
                                </div>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <PhoneIcon sx={{fontSize:'20px',color:'green'}}/>
                                            </td>
                                            <td className='accountManagerContactValue'>
                                                {accountManagerDetails.contact_number}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <DraftsIcon sx={{fontSize:'20px',color:'red'}}/>
                                            </td>
                                            <td className='accountManagerContactValue'>
                                                {accountManagerDetails.email}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <img style={{marginLeft:'-5px'}} src={SkypeIcon} alt='' width='33px'></img>
                                            </td>
                                            <td className='accountManagerContactValue'>
                                                {accountManagerDetails.skype}                                   
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <TelegramIcon sx={{fontSize:'23px',color:'purple'}}/>
                                            </td>
                                            <td className='accountManagerContactValue'>
                                                {accountManagerDetails.telegram}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </>
                        }
                    </div>
                </div>
                <div className='dashboardStatsColumnTwoRowTwo'>
                    <div className='topStatsContainer' style={{marginRight:'0px'}}> 
                        {
                            (loaderDict.topOffers || loaderDict.topAffiliates) && 
                            <DashboardLoader/>
                        }
                        {
                            !loaderDict.topOffers && !loaderDict.topAffiliates &&
                            <div style={{paddingTop:'10px',paddingLeft:'10px',paddingRight:'10px',paddingBottom:'30px'}}>
                                <div style={{display:'flex',justifyContent:'center'}}>
                                    {activeTopTab==='affiliates' ? dateRangeHelper[customizeDateRanges['Top Affiliates']] : dateRangeHelper[customizeDateRanges['Top Offers']]}
                                </div>
                                <div style={{display:'flex',alignItems:'center',marginBottom:'10px'}}>         
                                    <div className={activeTopTab==='offers' ? 'topTabs activeTopTab' : 'topTabs '} onClick={()=>{setActiveTopTab('offers')}} >
                                        Top Offers
                                    </div>
                                    <div className={activeTopTab==='affiliates' ? 'topTabs activeTopTab' : 'topTabs'} onClick={()=>{setActiveTopTab('affiliates')}} >
                                        Top Affiliates
                                    </div>
                                </div>
                                <div style={{fontSize:'13px'}}>
                                    
                                    {
                                        !loaderDict.topOffers && activeTopTab==='offers' && 
                                        <table>
                                            <thead>
                                                <tr>
                                                    <td width='80%'>
                                                        Offer Id - Title
                                                    </td>
                                                    <td>
                                                        Earnings
                                                    </td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {topOffers && topOffers.map((item,index)=>{
                                                    return <tr key={index} className='dashboardTopRow'>
                                                        <td style={{fontSize:'13px',padding:'10px',wordBreak:'break-all'}}>
                                                            <NavLink style={{textDecoration:"none",color:"black"}} to={"/offers/view/"+item.offer_id} target='_blank'>{item.offer_id} - {item.title}</NavLink>
                                                        </td>
                                                        <td style={{fontSize:'13px',color:'green'}}>
                                                            <NavLink style={{textDecoration:"none",color:"green"}} to={"/statistics/daily?offerId="+item.offer_id} target='_blank'>
                                                                {item.earning ? item.earning.toFixed(2)+"$" : ""}
                                                            </NavLink>
                                                        </td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                    }
                                    {
                                    !loaderDict.topAffiliates && activeTopTab==='affiliates' && 
                                        <table>
                                            <thead>
                                                <tr>
                                                    <td width='80%'>
                                                        Affiliate Id - Name
                                                    </td>
                                                    <td>
                                                        Earnings
                                                    </td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {topAffiliates && topAffiliates.map((item,index)=>{
                                                    return <tr key={index} className='dashboardTopRow'>
                                                        <td style={{fontSize:'13px',padding:'10px'}}>
                                                            <NavLink style={{textDecoration:"none",color:"black",wordBreak:'break-all'}} to={"/affiliates/management/view/"+item.affiliate_id} target='_blank'>{item.affiliate_id} - {item.company}</NavLink>
                                                        </td>
                                                        <td style={{fontSize:'13px',color:'green'}}>
                                                            <NavLink style={{textDecoration:"none",color:"green"}} to={"/statistics/daily?affiliate="+item.company +" -" + item.affiliate_id} target='_blank'>
                                                                {item.earning ? item.earning.toFixed(2)+"$" : ""}
                                                            </NavLink>
                                                        </td>
                        
                                                        </tr>
                                                })}
                                            </tbody>
                                        </table>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {showCustomizationPopup && 
            <div style={{position:'fixed',height:'100vh',width:'100vw',zIndex:'1001',display:'flex',backdropFilter:'blur(2px)',justifyContent:'center',alignItems:'center',top:0,left:0}}  onClick={(e)=>{setCustomizationPopup(false);setCustomizeDateRanges(backupCustomizeDateRanges);}}>
                <div className='customizationDashboardPopup' onClick={(e)=>{e.stopPropagation()}}>
                    <div className='customizationDashboardImage' style={{width:'50%',textAlign:'center'}}>
                        <img src={customizationDashboard} alt='' width='95%'/>
                    </div>
                    <div className='customizationDashboardImageSeparator' style={{height:'90%',borderLeft:'1px solid rgb(200,200,200)'}}/>
                    <div className='customizationDashboardDataWrapper'>
                        <div style={{marginBottom:'10px',fontSize:'20px',fontWeight:'600',display:'flex',justifyContent:'space-between',width:'90%',alignItems:'center'}}>
                            <div>Customize Dashboard!</div>
                            <div style={{display:'flex',alignItems:'center'}}>
                                <CachedIcon sx={{fontSize:'16px',cursor:'pointer'}} onClick={()=>{setCustomizeDateRanges(defaultDateRanges)}}/>
                                <CloseIcon sx={{marginLeft:'10px',fontSize:'16px',cursor:'pointer'}} onClick={()=>{setCustomizationPopup(false);setCustomizeDateRanges(backupCustomizeDateRanges)}}/>
                            </div>
                        </div>  
                        <div style={{maxHeight:'55vh',width:'100%',overflow:'auto'}}>
                            {
                                Object.keys(customizeDateRanges).map((item,index)=>{
                                    return <div key={index} className='customizationDashboardDataContainer'>
                                                <label className='customizationDashboardDataLabel'>{item} Date Range</label>
                                                <div className='customizationDashboardDataValue'>
                                                    <CreatableSelect
                                                        options={getDateRangeOptions(item)}
                                                        value={{label:dateRangeHelper[customizeDateRanges[item]] ? dateRangeHelper[customizeDateRanges[item]] : customizeDateRanges[item],value:customizeDateRanges[item]}}
                                                        onChange={(e)=>{dateRangeSelectFuntion(e,item)}}
                                                        menuPortalTarget={document.body} 
                                                        placeholder={"Select "+item + " date range."}
                                                        styles={{...Constants.ReactSelectStyles}}
                                                        />
                                                </div>
                                            </div>
                                })
                            }
                        </div>
                        <Button sx={{...Constants.CONTAINEDBUTTON,fontSize:'15px',paddingLeft:'15px',paddingRight:'15px',textTransform:'none',fontWeight:500,marginTop:'20px'}} onClick={()=>{sessionStorage.removeItem('dbtimelapse');initFunction();setCustomizationPopup(false);setBackupCustomizeDateRanges(customizeDateRanges);localStorage.setItem('dashboardCustomization',JSON.stringify(customizeDateRanges));refreshComponent(1);}}>Save</Button>
                        
                    </div>
                </div>
            </div>}
        </div>
    ) 
}

export default DashboardPageV2;