import React ,{useState} from "react";
import arrowdown from "../billing/insightsManik/assets/arrow-down.svg";
import Select, { components } from "react-select";

function CustomSelect(props) {
  const customStyles = {
    container: (base) => ({
      ...base,
      margin: "0 1px",
      height: props.height||"60px",
      width: props.width,
      position: "relative",
      marginLeft:props.marginLeft,
      marginTop:props.marginTop,
   
    }),
    control: (base) => ({
      ...base,
      justifyContent: props.textAlign||"center",
      alignItems: "center",
      border: 0,
      boxShadow: "none",
      backgroundColor: props.bgcolor,
      
    }),
    singleValue:(base)=>({
        ...base,
        color:'#A5A5A5'
    }),
    valueContainer: (base) => ({
      ...base,
      flex: "unset",
      height: props.height||"60px",
      maxHeight: props.maxHeight||"60px",
      fontFamily: props.fontFam,
      fontSize: props.fontSize,
      fontWeight: props.FontWeight,
      textAlign:"center",
      width: props.width_val,
      fontWeight: props.fontWeight2,
      paddingLeft:"0rem",
      marginRight:"0px",
      paddingRight:"0px",
      padding:"3px 0px",
    }),
    menu: (base) => ({
      ...base,
      position: "absolute",
      width: "10rem",
      backgroundColor: props.optcolor || "#FFFFFF",
      zIndex:"1000",
      marginLeft:props.placeholder==="Edit Columns"? "-13rem":"0rem"
    }),
    placeholder: (base) => ({
      ...base,
      color: "#A5A5A5",
      fontFamily: props.fontFam,
      fontSize: props.fontSize,
      fontWeight: props.FontWeight,
      textAlign: props.align||'center',
      paddingLeft:props.padLeft,
      paddingTop:props.paddingTop || "0rem"
    }),
    option: (styles, { isFocused, isSelected }) => {
      return {
        ...styles,
        color: "#252525",
        backgroundColor: isSelected
          ? "#E0F1FB"
          : isFocused
          ? "#E7F1F7"
          : undefined,
        textAlign: "left",
        fontSize: "1rem",
      };
    },
  };

     const IndicatorsContainer = (props) => {
       return <img className="icon1" src={arrowdown} alt="indicator" />;
     };

  const handleChange = (option) => {
    props.setDays(option.value);
  }
  const CustomSingleValue = ({ children,...props }) => {
    return(<components.SingleValue {...props}>
      { '(' + children + ')'}
    </components.SingleValue>);
    
   };

  return (
    <Select
      onChange={handleChange}
      img={props.img}
      placeholder={props.placeholder}
      options={props.options}
      isClearable={true}
      components={{
        IndicatorsContainer:IndicatorsContainer,
        ValueContainer: components.ValueContainer,
        Option: components.Option,
        SingleValue: CustomSingleValue,
        
      }}
      className="react-select-container"
      classNamePrefix="react-select"
      styles={customStyles}
      isSearchable={false}
      closeMenuOnSelect={props.menu}
    />
  );
}

export default CustomSelect;