import axios from "axios";
import * as Constants from '../data/constants';
import { Store } from 'react-notifications-component';
import { getLocalStorageValue } from "../util/util";

export const exportOffers = async(selectedFields,filterSearch,setLoader,setExportPopup) => {
    
    setLoader(true);
    var columns_required = ''
    for(var index in selectedFields){
        columns_required += selectedFields[index] + ","
    }
    if(columns_required){
        columns_required = columns_required.slice(0,-1)
    }
    var paramAdvertiser = ''
    if(filterSearch.advertiser){
        for(index in filterSearch.advertiser){
            paramAdvertiser += filterSearch.advertiser[index].value +","
        }
        paramAdvertiser = paramAdvertiser.slice(0,-1)
    }

    var paramAdvertiserManager = ''
    if(filterSearch.manager_id){
        for(index in filterSearch.manager_id){
          paramAdvertiserManager += filterSearch.manager_id[index].value +","
        }
        paramAdvertiserManager = paramAdvertiserManager.slice(0,-1)
    }
    var paramSmartlink = ''
    if(filterSearch.smartlink_id){
        for(index in filterSearch.smartlink_id){
          paramSmartlink += filterSearch.smartlink_id[index].value +","
        }
        paramSmartlink = paramSmartlink.slice(0,-1)
    }
    if(filterSearch.selectedElements && filterSearch.selectedElements.length>0){
      filterSearch.id = ''
      for(index in filterSearch.selectedElements){
        filterSearch.id += filterSearch.selectedElements[index] + ","
      }
      filterSearch.id = filterSearch.id.slice(0,-1)
    }
    var params = {
        "stats_type" : "OfferPage",
        offer_id : filterSearch.id ? filterSearch.id : null,  //Search
        offer_title : filterSearch.offer_title ? filterSearch.offer_title.toLowerCase() : null,  //Search
        external_offer_id : filterSearch.external_offer_id ? filterSearch.external_offer_id.toLowerCase() : null,  //Search
        offer_status : filterSearch.status ? filterSearch.status.value : null,  //Search
        offer_type : filterSearch.type ? filterSearch.type.value : null,  //Search
        advertiser_id : paramAdvertiser ? paramAdvertiser : null,  //Search
        smartlink_id : paramSmartlink ? paramSmartlink : null,  //Search
        columns_required: columns_required,
        offer_source : filterSearch.offer_source ? filterSearch.offer_source.value : null,  //Search
        manager_id : paramAdvertiserManager ? paramAdvertiserManager : null,  //Search

    }
    var url = "".concat(Constants.PROTOCOL2,Constants.HOST2,Constants.API_USER_PATH,"export/offer")
    if(filterSearch.pageType==='bookmarked'){
      params['stats_type'] = 'OfferBookmarks'
      url = "".concat(Constants.PROTOCOL2,Constants.HOST2,Constants.API_USER_PATH,"export/bookmark")
    }
    await axios({
        method: 'GET',
        url,
        headers: {
            "Content-Type":"application/json",
            Authorization:getLocalStorageValue('adminPanelLoginData').token
          },
        params
    })
    .then(resp => {
        Store.addNotification({
            title: "Success!",
            message: resp.data.result,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 1000,
              onScreen: true
            },
          });
        })
    .catch((error) => {
        if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            Store.addNotification({
                title: "Error!",
                message: error.response.data.result,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 2000,
                  onScreen: true
                }
              });

            } else if (error.request) {
            console.log(error.request);
            } else {
            console.log('Error', error.message);
            }
    }) 
    setLoader(false);
    setExportPopup(false);
}


export const exportAdvertiser = async(selectedFields,filterSearch,setLoader,setExportPopup) => {
    setLoader(true);
    var columns_required = ''
    for(var index in selectedFields){
        columns_required += selectedFields[index] + ","
    }
    if(columns_required){
        columns_required = columns_required.slice(0,-1)
    }
    var params = {
        stats_type:'advertiser',
        id : filterSearch.adv_name ? filterSearch.adv_name.value : null,  //Search
        name : filterSearch.adv_email ? filterSearch.adv_email.toLowerCase() : null,  //Search
        manager_id : filterSearch.manager ? filterSearch.manager.value : null,  //Search
        custom_columns: columns_required
    }

    await axios({
        method: 'GET',
        url:"".concat(Constants.PROTOCOL2,Constants.HOST2,Constants.API_USER_PATH,"advertiser/export"),
        headers: {
            "Content-Type":"application/json",
            Authorization:getLocalStorageValue('adminPanelLoginData').token
          },
        params
    })
    .then(resp => {
        Store.addNotification({
            title: "Success!",
            message: resp.data.result,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 1000,
              onScreen: true
            },
          });
        })
    .catch((error) => {
        if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            Store.addNotification({
                title: "Error!",
                message: error.response.data.result,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 2000,
                  onScreen: true
                }
              });

            } else if (error.request) {
            console.log(error.request);
            } else {
            console.log('Error', error.message);
            }
    }) 
    setLoader(false);
    setExportPopup(false);
}

export const exportAffiliate = async(selectedFields,filterSearch,setLoader,setExportPopup) => {
  setLoader(true);
  var columns_required = ''
  for(var index in selectedFields){
      columns_required += selectedFields[index] + ","
  }
  if(columns_required){
      columns_required = columns_required.slice(0,-1)
  }
  var params = {
      stats_type:'affiliate',
      id : filterSearch.adv_name ? filterSearch.adv_name.value : null,  //Search
      name : filterSearch.adv_email ? filterSearch.adv_email.toLowerCase() : null,  //Search
      manager_id : filterSearch.manager ? filterSearch.manager.value : null,  //Search
      custom_columns: columns_required
  }

  await axios({
      method: 'GET',
      url:"".concat(Constants.PROTOCOL2,Constants.HOST2,Constants.API_USER_PATH,"affiliate/export"),
      headers: {
          "Content-Type":"application/json",
          Authorization:getLocalStorageValue('adminPanelLoginData').token
        },
      params
  })
  .then(resp => {
      Store.addNotification({
          title: "Success!",
          message: resp.data.result,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 1000,
            onScreen: true
          },
        });
      })
  .catch((error) => {
      if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          Store.addNotification({
              title: "Error!",
              message: error.response.data.result,
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 2000,
                onScreen: true
              }
            });

          } else if (error.request) {
          console.log(error.request);
          } else {
          console.log('Error', error.message);
          }
  }) 
  setLoader(false);
  setExportPopup(false);
}