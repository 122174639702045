import jsonData from "../assets/data.json";
import ChartDataLabels from "chartjs-plugin-datalabels";
import TableView from "./TableView.jsx";
import CustomTable from "./Addtable.jsx";
import CustomSelect from "./CustomSelect";
import reset from "../assets/reset2.svg";
import resetNew from "../assets/resetNew.svg";
import back from "../assets/back.svg";
import { AdvertiserIcon,Cross,TeamIcon,AppIcon,SpiralIcon,AdvertiserManagerIcon,MoneyIcon} from '../../../../data/icons';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import axios from "axios";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import MonthlySynopsis2 from "./MonthlySynopsis2.jsx";
import { getLocalStorageValue } from "../util/util";
import { Today } from "@mui/icons-material";
import * as Constants from '../../../../data/constants';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);
function YearlySynopsis({
  initDate,
  finaldate,
  selectedRev,
  team,
  advertiser,
  manager,
  packageName,
  advData,
  advertiserString,
  packageString,
  managerString,
  isOffer,
  appPackageMap,
  setAdvertiser,
  setPackageName,
  setTeam,
  setManager,
  setSelectedRev,
  setFilterData,
  filterData
}) {
  const [sortOrder, setSortingOrder] = useState();
  var ded_rev, ded_rev_r;
  const [val, setVal] = useState({});
  const [initial,setInitial]=useState(true);
  const [advapp,setAdvapp]=useState("");
  const [advappmonth,setAdvappmonth]=useState("");
  const [monthly,setMonthly]=useState(false);
  var pend_rev, pend_rev_r, rev;
  var valid_rev, valid_rev_r;
  var sum_r, sum, increment1, increment2, increment3;
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () =>{ setOpen(false);
  setInitial(true);
  setMonthly(false);
  setAdvappmonth("");
};
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "90%",
    bgcolor: "background.paper",
    // border: "2px solid #000",
    overflowY: "auto",
    boxShadow: 24,
    p: 4,
    padding:"10px"
  };
  function getFormattedDate() {
    var currentDate = new Date();
    if(finaldate)
    currentDate=new Date(finaldate);
    const currentDay = currentDate.getDate();
    let targetMonth;

    if (currentDay > 7) {
      
      targetMonth = currentDate.getMonth()-1;
      if (targetMonth === 0) {
        targetMonth = 11; 
        targetMonth -= 1;
      }
    } else {

      targetMonth = currentDate.getMonth() - 2;
      if (targetMonth < 0) {
        targetMonth += 12;
      }
    }
    let diff=new Date().getMonth()-currentDate.getMonth();
    diff=(diff+12)%12;
    if(diff>0){
      targetMonth=currentDate.getMonth();
      if(targetMonth<0){
        targetMonth+=13;
      }
    }

    const targetYear = currentDate.getFullYear();

    const finalMonth = (targetMonth + 1).toString().padStart(2, "0");
    const formattedDate = `${targetYear}-${finalMonth}`;
    return formattedDate;
  }
  var result = getFormattedDate();

  var currentYear = new Date().getFullYear();
  if(initDate)
  currentYear=new Date(initDate).getFullYear();
  if(finaldate)
  currentYear=new Date(finaldate).getFullYear();

  if(new Date().getMonth()===0){
    currentYear = new Date().getFullYear() - 1
    result = `${currentYear}-12`
  }
  const januaryString = `${currentYear}-01`;

  const API_ENDPOINT =!isOffer?
  "".concat(Constants.PROTOCOL,Constants.HOST,"/api/admin/v2/invoice/summary"):
  "".concat(Constants.PROTOCOL,Constants.HOST,"/api/admin/v1/invoice/adv_data")
  const params ={
    team: team,
    fromMonth: januaryString,
    toMonth: result,
    total_row_required: true,
    group_by: "month",
  }
  if(isOffer){
    params.offer_id=advertiserString;
  }
  else{
  if (advertiser != "" && packageName != "") {
    params.advertiser_id = advertiserString;
    params.package_name = packageString;
    console.log("aka", "yoho");
  } else if (advertiser != "") {
    params.advertiser_id = advertiserString;
  } else {
    params.package_name = packageString;
  }
}
  if(manager != "")
  {
    params.manager_id=managerString;
  }
  console.log("aka", params);
  const token = getLocalStorageValue("adminPanelLoginData").token;
  useEffect(() => {
    axios
      .get(API_ENDPOINT, {
        params: params,
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((response) => {
        setVal(response.data.total_row);
      })
      .catch((error) => {
        setVal({})
        console.error("Error fetching data:", error);
      });
  }, [team, advertiser, packageName,manager,initDate,finaldate]);

  if (selectedRev === "Panel Revenue") {
    rev = val.total_revenue;
    ded_rev = val.deduction_revenue;
    pend_rev = val.pending_revenue;
    valid_rev = val.manager_approved_revenue;
    sum = ded_rev + pend_rev + valid_rev;
  } else {
    rev = val.conversions_verified;
    ded_rev = val.deduction_revenue_manual;
    pend_rev = val.pending_revenue_manual;
    valid_rev = val.manager_approved_revenue;
    sum = ded_rev + pend_rev + valid_rev;
  }
  ded_rev_r = Math.round(ded_rev);
  pend_rev_r = Math.round(pend_rev);
  valid_rev_r = Math.round(valid_rev);
  sum_r = Math.round(sum);
  increment1 = Math.round((valid_rev_r / sum_r) * 100);
  increment2 = Math.round((pend_rev_r / sum_r) * 100);
  increment3 = Math.round((ded_rev_r / sum_r) * 100);
 
  const currMonth = parseInt(result.split("-")[1]);
  var dateObj = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };
  var mon = dateObj[currMonth];
  const currentDate = new Date();
  const currMonthVal = currentDate.getMonth() + 1;
  var ctx = document.createElement('canvas').getContext('2d');
  var gradient1 = ctx.createLinearGradient(0,0,400,0)
  gradient1.addColorStop(0, "#8DA665")
  gradient1.addColorStop(0.01, "#8DA665")
  gradient1.addColorStop(0.9422, "#A9C779")
  var gradient2 = ctx.createLinearGradient(400,0,800,0)
  gradient2.addColorStop(0, "#E07A5F")
  gradient2.addColorStop(0.5, "#DE9B89")
  var gradient3 = ctx.createLinearGradient(800,0,1000,0)
  gradient3.addColorStop(0, "#A89F91")
  gradient3.addColorStop(0.4, "#C4BBAC")

  const data= {

    labels: ["Yearly"],
    datasets: [
      {
        label: "Approved Revenue",
        data: [valid_rev_r],
        // backgroundColor: "#8DA665",
        backgroundColor:gradient1
      },
      {
        label: "Deduction",
        data: [ded_rev_r],
        // backgroundColor: "#E07A5F",
        backgroundColor:gradient2
      },
      {
        label: "Pending Validation",
        data: [pend_rev_r],
        // backgroundColor: "#A89F91",
        backgroundColor:gradient3
      },
    ],
    
  };

  const options = {
    maintainAspectRatio: false,
    barPercentage: 1.2,

    onClick: function (c, i) {
      var e = i[0];
      setSortingOrder(i[0].datasetIndex);
      handleOpen();
      var x_value = this.data.labels[e._index];
      var y_value = this.data.datasets[0].data[e._index];
    },
    layout: {
      padding: {
        top: 10,
        left: 10,
        bottom: 25,
        right: 20,
      },
    },
    responsive: true,
    indexAxis: "y",
    scales: {
      x: {
        display: false,
        stacked: true,
        beginAtZero: true,
        min: 0,
        max: sum_r,
      },
      y: {
        display: false,
        stacked: true,
      },
    },
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    plugins: {
      tooltip: {
        backgroundColor: "#f0f0f0",
        titleColor: "#252525",
        borderWidth: 1,
        borderColor: "",
        bodyColor: "#252525",
      },
      legend: {
        fullSize: false,
        position: "bottom",
        align: "end",
        labels: {
          color: "#252525",
          font: {
            // family: "kanit, sans-serif",
            size: 16,
            weight: 400,
          },
          useBorderRadius: true,
          borderRadius: 5,
        },
      },
      datalabels: {
        color: "#000000",
        formatter: function (value, context) {
         let incrementPercentage = "";

         if (value === valid_rev_r) {
           if (value > 1000) {
             incrementPercentage = "\n(" + increment1 + "%)";
             return Math.round(value / 1000) + "k" + incrementPercentage;
           }
         } else if (value === pend_rev_r) {
           if (value > 1000) {
             incrementPercentage = "\n(" + increment2 + "%)";
             return Math.round(value / 1000) + "k" + incrementPercentage;
           }
         } else {
           if (value > 1000) {
             incrementPercentage = "\n(" + increment3 + "%)";
             return Math.round(value / 1000) + "k" + incrementPercentage;
           }
         }
        },
        font: {
          // family: "kanit, sans-serif",
          size: 16,
          weight: 600,
        },
      },
    },
  };
  var addColumnOptions;
  if (selectedRev === "Verified Revenue") {
    addColumnOptions = [
      { value: 0, label: "Verified Revenue" },
      { value: 1, label: "Offers Validated" },
      { value: 2, label: "Approved Revenue" },
      { value: 3, label: "Validation %" },
      { value: 4, label: "Pending Validation" },
      { value: 5, label: "Deduction" },
      { value: 6, label: "Difference in Revenue" },
      { value: 7, label: "Panel Revenue Accuracy" },
      { value: 8, label: "Delivered Revenue" },
    ];
  } else {
    addColumnOptions = [
      { value: 0, label: "Verified Revenue" },
      { value: 1, label: "Offers Validated" },
      { value: 2, label: "Approved Revenue" },
      { value: 3, label: "Validation %" },
      { value: 4, label: "Pending Validation" },
      { value: 5, label: "Deduction" },
      { value: 6, label: "Difference in Revenue" },
      { value: 7, label: "Panel Revenue Accuracy" },
      { value: 8, label: "Delivered Revenue" },
    ];
  }

  const initiallySelected=[true,
    false,
    true,
    true,
    true,
    true,
    false,
    false,
    false,
    false,];
  const [selectedColumns, setSelectedColumns] = useState(initiallySelected);
  const [selectedHead, setSelectedHead] = useState("Advertisers");
  const [btnClick, setBtnClick] = useState(0);
  return (
    <div className="containerManik">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <div className="addcolumn">
            {initial ?
            (<CustomSelect
              placeholder={
                selectedHead === "Advertisers"
                  ? "Revenue Synopsis ( Advertisers ) "
                  : "Revenue Synopsis ( Apps ) "
              }
              options={[
                { value: "Advertisers", label: "Advertisers" },
                { value: "Apps", label: "Apps" },
              ]}
              selectedHead={selectedHead}
              setSelectedHead={setSelectedHead}
              width="22rem"
              fontFam={"Oswald,sans-serif"}
              fontSize={"1.6rem"}
              fontWeight={"400"}
              menu={true}
              textAlign={"left"}
            />):(<div style={{ fontFamily:"Oswald,sans-serif",
            fontSize:"1.6rem",
            fontWeight:"400"}}>
                <button
                 style={{
                color: "#0E6BA8",
                backgroundColor: "#FFFFFF",
                border: "none",
                // fontFamily: "kanit,sans-serif",
                fontSize: "1rem",
                borderRadius: "5px",
              }}
                onClick={()=>{if(monthly){
                  setMonthly(false);
                  setAdvappmonth("");
                }
                  else{
                    // setMonthly(false);
                    setInitial(true);
                  }
                }}>
              <img
                  src={back}
                  style={{
                    width: "1.3rem",
                    height: "1.4rem",
                    // marginRight: "0.4rem",
                  }}
                />
                </button>
               {advapp}
              { monthly? selectedHead=="Advertisers"? "'s Apps":"'s Advertisers":""}
             
              </div>)}

          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <div
            className="monthhead"
            style={{ marginRight: "auto",}}
          >
            {advappmonth==""?
            `Jan ${currentYear.toString().substring(2)}-${mon} ${currentYear.toString().substring(2)}`: advappmonth
            }

          </div>
            <button
              onClick={() => {setBtnClick(btnClick + 1);
                setSelectedColumns(initiallySelected);
              setInitial(true);
            setMonthly(false);
          setAdvappmonth("");}}
              style={{
                // width: "9rem",
                // height: "1.5rem",
                // marginLeft: "auto",
                color: "#0E6BA8",
                backgroundColor: "#FFFFFF",
                border: "none",
                // fontFamily: "kanit,sans-serif",
                fontSize: "1rem",
                borderRadius: "5px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <img
                  src={resetNew}
                  style={{
                    width: "1.3rem",
                    height: "1.4rem",
                  }}
                />
                {/* <div>Reset Table</div> */}
              </div>
            </button>

            <CustomSelect
              placeholder={"Edit Columns"}
              options={addColumnOptions}
              isCheckboxOption={true}
              isPlaceholderFixed={true}
              selectedColumns={selectedColumns}
              setSelectedColumns={setSelectedColumns}
              width="2rem"
              height="3rem"
              color="#FFFFFF"
              // bgcolor="#0E6BA8"
              optcolor="#E7F1F7"
              menu={false}
            />

          </div>


          <CustomTable
            Initdate={januaryString}
            Finaldate={result}
            key={btnClick}
            modTeam={team}
            selectedRev={selectedRev}
            selectedColumns={selectedColumns}
            group={selectedHead}
            sortOrder={sortOrder}
            advData={advData}
            initial={initial}
            monthly={monthly}
            setAdvapp={setAdvapp}
            advappmonth={advappmonth}
            setAdvappmonth={setAdvappmonth}
            setInitial={setInitial}
            setMonthly={setMonthly}
            appPackageMap={appPackageMap}
            managerString={managerString}
            name="yearly"
            advertiser={advertiser}
            packageName={packageName}
            advertiserString={advertiserString}
            packageString={packageString}
          />
        </Box>
      </Modal>
      <div style={{ display: "flex", justifyContent: "space-between",alignItems:"center" }}>
        <div className="title" style={{ paddingLeft: "8px",display:"flex" }}>
          Validation Synopsis{" "}
          <span style={{marginLeft: "0.6rem",color:"#A5A5A5" }}>
            (Jan {currentYear.toString().substring(2)}-{mon} {currentYear.toString().substring(2)})*
            </span>
          {advertiser!="" || manager!="" || team!="" || packageName!="" || selectedRev!="Verified Revenue"?(
          <div style={{background:"#D9D9D9",borderRadius:"42px",
        display:"inline-flex",marginLeft:"5px"}}>
              {advertiser && advertiser!=""?
              <div className="crossContainer" onClick={()=>{setAdvertiser("")
              setFilterData({...filterData,["advertiser_id"]:""})}}>
                <div className="cross"><Cross/></div>
              <AdvertiserIcon/>
              </div>:<></>}
              {team && team!=""?
              <div className="crossContainer" onClick={()=>{setTeam("")
              setFilterData({...filterData,["team"]:""})}}>
                <div className="cross"><Cross/></div>
              <TeamIcon/>
              </div>:<></>}
              {selectedRev!="Verified Revenue"?
              <div className="crossContainer" onClick={()=>{setSelectedRev("Verified Revenue")
              setFilterData({...filterData,["selectedRev"]:{label:'Verified Revenue',value:'Verified Revenue'}})}}>
                <div className="cross"><Cross/></div>
              <MoneyIcon/>
              </div>:<></>}
              {manager && manager!=""?
              <div className="crossContainer" onClick={()=>{setManager("")
              setFilterData({...filterData,["advertiser_manager_id"]:""})}}>
                <div className="cross"><Cross/></div>
              <AdvertiserManagerIcon/>
              </div>:<></>}
              {packageName && packageName!=""?
              <div className="crossContainer" onClick={()=>{setPackageName("")
              setFilterData({...filterData,["package_name"]:"",["appData"]:""})}}>
                <div className="cross"><Cross/></div>
              <AppIcon/>
              </div>:<></>}
          </div>):(<></>)}
          {/* <div
            style={{ fontSize: "11px", color: "#A5A5A5" }}
          >
            *Yearly data is shown for the month
            <span style={{ marginLeft: "2px" }}>
              {/* { (currMonthVal-currMonth)<0? (currMonthVal-currMonth+12)===1? "n-1" : "n-2": (currMonthVal - currMonth)%12 === 1 ? "n-1" : "n-2"} */}
              {/* { (new Date().getDate())>7? "n-1" : "n-2"} */}
            {/* </span> */}
          {/* </div> */} 
        </div>
        <div style={{ marginRight: "1.2rem",marginBottom:"1rem" }}>
          {selectedRev} - {Math.round(rev).toLocaleString("en-US")}
        </div>
      </div>
      <div
            style={{ fontSize: "11px", color: "#A5A5A5",paddingLeft:"8px" }}
          >
            *Yearly data is shown for the month
            <span style={{ marginLeft: "2px" }}>
              {/* { (currMonthVal-currMonth)<0? (currMonthVal-currMonth+12)===1? "n-1" : "n-2": (currMonthVal - currMonth)%12 === 1 ? "n-1" : "n-2"} */}
              { (new Date().getDate())>7? "n-1" : "n-2"}
            </span>
          </div>
      <div className="yearly-synopsis">
        <Bar options={options} data={data} />
      </div>
    </div>
  );
}

export default YearlySynopsis;
