import React ,{useState}from 'react'
import Select, {components} from 'react-select'
import arrowdown from '../assets/arrow-down.svg'
import revenue from '../assets/rev.svg'
import team_img from '../assets/teamimg.svg'
import advertiser_img from '../assets/AdvImg.svg'
import group from  '../assets/group.svg'
import package_n from '../assets/package_n.svg'
import app_img from "../assets/AppName.svg";
import acc_img from "../assets/accManager.svg"
import '../insightsCss.css'
import MonthRangePicker from './MonthRangePicker'
import Input from './Input'
import Input2 from "./Input2";
import Input3 from "./Input3";
var chosenRevenue="Manual Revenue";
var chosenTeam="";
function NavBar({ selectedRev,setSelectedRev,initdate,setInitdate,finaldate,setFinaldate,team,setTeam,advertiser,setAdvertiser,advData,setAdvData,packageName,setPackageName,appData,setAppData,manager,setManager}) {
  const currentYear = new Date().getFullYear();
  const [iniDate, setIniDate] = useState(new Date(currentYear, 0));
  const [finDate,setFinDate]=useState(new Date());
  const [adv,setAdv]=useState([]);
  const [pack,setPack]=useState([]);
  const [manag,setManag]=useState([]);
  const names=["Manual Revenue", "Aug' 23-Nov'23","Team name","Advertiser name","Package name"]
  const handleClick=()=>{
    setSelectedRev(chosenRevenue);
    setTeam(chosenTeam);
    setInitdate(iniDate);
    setFinaldate(finDate);
    setAdvertiser(adv);
    setPackageName(pack);
    setManager(manag);
  }
  console.log("om",advertiser,packageName);
  const options = [
    { value: 'panel_revenue', label: 'Panel Revenue' },
    { value: 'manual_revenue', label: 'Manual Revenue' },
    
  ]
  const options2=[
    { value: 'Team 1', label: 'Team 1' },
    { value: 'Team 2', label: 'Team 2' },
    { value: 'Team 3', label: 'Team 3' },
  ]

  const customStyles = {
    container: (base) => ({
      ...base,
      flexGrow: "1",
      margin: "0 1px",
      height: "60px",

    }),
    control: (base) => ({
      ...base,
      alignItems: "center",
      border: 0,
      boxShadow: "none",
      backgroundColor: "#F0F0F0",
      padding: "0px 6px 0px 0px"
    }),
    valueContainer: (base) => ({
      ...base,
      flex: "unset",
      height: "60px",
      minWidth: "9.08rem",
      padding: "0px 1px"
    }),

    placeholder: (base) => ({
      ...base,
      color: "#252525",
      marginLeft:"0px"
   
    }),
    option: (styles, { isFocused, isSelected }) => {
      return {
        ...styles,
        color: "#252525",
        backgroundColor: isSelected
          ? "#E0F1FB"
          : isFocused
          ? "#E0F1FB"
          : undefined,
        textAlign: "center",
      };
    },
  };

  const IndicatorsContainer = (props) => {
    return (
      <img className="icon" src={arrowdown} alt="indicator" />
    );
  };

  const CustomOption = props => {
    return (
      <components.Option {...props} style={{textAlign:'left'}} >
          <div style={{textAlign:'left'}}>
            <input type="radio" defaultChecked={chosenRevenue == props.label} />
            <label>{props.label}</label>
          </div>
      </components.Option>
    );
  };

  const CustomSelectHandleChange = props => {
    return option => {
      option.isSelected = true
      if (props.isRevenueSelect) chosenRevenue = option.label;
      if (props.isTeamSelect) chosenTeam = option.label;
    }
  }

  function placeholderWithIcon(props) {
    return (
      <>
        <img className="icon" src={props.img} alt="indicator" style={{ height: '1.2rem', width: '1.2rem', transform: 'translateY(0.24rem)', marginRight: '0.6rem',marginLeft:'1rem' }}/>
        {props.placeholder}
      </>
    )
  }

  function CustomSelect(props) {
    return (
      <Select
        onChange={CustomSelectHandleChange(props)}
        img={props.img}
        placeholder={placeholderWithIcon(props)}
        options={props.options}
        components={{
          IndicatorsContainer: IndicatorsContainer,
          SingleValue: props.singleValue || components.SingleValue,
          Option: props.isRevenueSelect? CustomOption: components.Option,
          ValueContainer: props.valueContainer || components.ValueContainer,
        }}
        className="react-select-container"
        classNamePrefix="react-select"
        styles={customStyles}
        isSearchable={false}
      />
    ); 
  }

  const CustomSingleValue = ( { children, ...props} ) => (
    <components.SingleValue {...props}>
      <img className="icon" src={props.selectProps.img} alt="indicator" style={{ height: '1.2rem', width: '1.2rem', transform: 'translateY(0.24rem)', marginRight: '0.6rem' ,marginLeft:'1rem'}}/>
      {children}
    </components.SingleValue>
  );

  
  return (
    <div className="mainNavbar">
    <div className='navbar' style={{alignItems:"stretch"}}>
      {/* <button onClick={handleClick}  style={{width:"24.25%",border:'none',borderRadius:"5px"}}> */}
      <MonthRangePicker initdate={iniDate} setInitdate={setIniDate} finaldate={finDate} setFinaldate={setFinDate} onChange={handleClick}/>
      {/* </button> */}
      <button className="packagebtn" onClick={handleClick}  style={{width:"24.25%",border:'none',borderRadius:"5px"}}>
        <CustomSelect
          isTeamSelect={true}
          placeholder={chosenTeam===""?"Team name":chosenTeam}
          singleValue={CustomSingleValue}
          img={team_img}
          options={options2}
          type={"team"} />
        </button>
      <Input2 name={'App name'}  image={app_img}  pack={pack} setPack={setPack} advData={appData} setAppData={setAppData} onChange={handleClick}/>
      <Input2 name={'Package name'} image={package_n}  pack={pack} setPack={setPack} appData={appData} setAppData={setAppData} onChange={handleClick}/>
      </div>
      <div className='navbar' style={{alignItems:"stretch"}}>
      <button className="packagebtn" onClick={handleClick}  style={{width:"24.25%",border:'none',borderRadius:"5px"}}>
        <CustomSelect
          isRevenueSelect={true}
          singleValue={CustomSingleValue}
          placeholder={chosenRevenue}
          img={revenue}
          options={options}
           />
      </button>
      <Input name={'Advertiser name'} image={advertiser_img} advertiser={adv} setAdvertiser={setAdv} advData={advData} setAdvData={setAdvData} onChange={handleClick}/>
      <Input3 name={'Account Manager'} image={acc_img}  manager={manag} setManager={setManag} onChange={handleClick} />
      <div style={{display:"flex",flexBasis:"12.30%",textAlign:"center", alignItems:"center", justifyContent:"center", backgroundColor:"#FFFFFF",borderRadius:"2px", border:'1px solid #0E6BA8',marginLeft:"3.5px"}}><button  onClick={() => window.location.reload()} style={{width:"70%",height:"70%",backgroundColor:"#FFFFFF",color:"#0E6BA8",border:'none',borderRadius:"5px"}}>Reset</button></div>
      <div style={{display:"flex",flexBasis:"11.50%",textAlign:"center", alignItems:"center", justifyContent:"center", backgroundColor:"#0E6BA8",borderRadius:"2px",marginLeft:"3px"}}><button onClick={handleClick} style={{width:"70%",height:"70%",backgroundColor:"#0E6BA8",color:"#FFFFFF",border:'none',borderRadius:"5px"}}>Apply</button></div>
      </div>
    </div>
  )
}

export default NavBar;