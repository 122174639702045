import React ,{useState} from "react";
import arrowdown from "../assets/arrow-down.svg";
import Select, { components } from "react-select";
import whiteArrow from "../assets/whiteArrow.svg";
import editColumn from "../assets/editColumn.svg";

function CustomSelect(props) {
  console.log("ab",props.placeholder);
 const sorHead=props.sorHead;
 const setSorHead=props.setSorHead;
  const customStyles = {
    container: (base) => ({
      ...base,
      margin: "0 1px",
      height: props.height||"60px",
      width: props.width,
      position: "relative",
      // minWidth: "8rem",
      marginLeft:props.marginLeft,
      marginTop:props.marginTop,
   
    }),
    control: (base) => ({
      ...base,
      justifyContent: props.textAlign||"center",
      alignItems: "center",
      border: 0,
      boxShadow: "none",
      backgroundColor: props.bgcolor,
      
    }),
    valueContainer: (base) => ({
      ...base,
      flex: "unset",
      height: props.height||"60px",
      maxHeight: props.maxHeight||"60px",
      fontFamily: props.fontFam,
      fontSize: props.fontSize,
      fontWeight: props.FontWeight,
      textAlign:"center",
      width: props.width_val,
      fontWeight: props.fontWeight2,
      paddingLeft:"0rem",
      marginRight:"0px",
      paddingRight:"0px",
      padding:"3px 0px",
    }),
    menu: (base) => ({
      ...base,
      position: "absolute",
      width: "15rem",
      backgroundColor: props.optcolor || "#FFFFFF",
      zIndex:"1000",
      marginLeft:props.placeholder==="Edit Columns"? "-13rem":"0rem"
    }),
    placeholder: (base) => ({
      ...base,
      color: props.color || "#252525",
      fontFamily: props.fontFam,
      fontSize: props.fontSize,
      fontWeight: props.FontWeight,
      textAlign: props.align||'center',
      paddingLeft:props.padLeft,
      paddingTop:props.paddingTop || "0rem"
    }),
    option: (styles, { isFocused, isSelected }) => {
      return {
        ...styles,
        color: "#252525",
        backgroundColor: isSelected
          ? "#E0F1FB"
          : isFocused
          ? "#E7F1F7"
          : undefined,
        textAlign: "left",
        fontSize: "1rem",
      };
    },
  };
  const RadioOption = (placeholder,sortTableHead) => {
    console.log("manik",sorHead,props.label,sorHead===props.label,placeholder);
    return (props) => {
    if(placeholder === sortTableHead){
      return(
      <components.Option {...props} style={{ textAlign: "left" }}>
        <div style={{ textAlign: "left" }}>
          <input type="radio" checked={sorHead===props.label} />
          <label>{props.label}</label>
        </div>
      </components.Option>
      );
    }
    else{
      return(<components.Option {...props} style={{ textAlign: "left" }}>
        <div style={{ textAlign: "left" }}>
          <input type="radio" defaultChecked={false} />
          <label>{props.label}</label>
        </div>
      </components.Option>
      );
    }
   };
  };

  const CheckboxOption = (selectedColumns) => {
    return (props) => {
      return (
        <components.Option {...props} style={{ textAlign: "left" }}>
          <div style={{ textAlign: "left" }}>
            <input
              type="checkbox"
              defaultChecked={selectedColumns[props.value]}
            />
            <label>{props.label}</label>
          </div>
        </components.Option>
      );
    };
  };

  const IndicatorsContainer1 = (props) => {
       return <img className="icon1" style={{marginTop:"4px",height:"1rem"}}  src={whiteArrow} alt="indicator" />;
  };

  const IndicatorsContainer3 = (props) => {
    return <img className="icon1" src={editColumn} alt="indicator" />;
};
  const IndicatorsContainer2 = (props)=>{
     return <img className="icon1" src={arrowdown} alt="indicator" />;
  }
     const IndicatorsContainer = (props) => {
       return <img className="icon1" src={arrowdown} alt="indicator" />;
     };
  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <components.ValueContainer {...props}>
        <components.Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </components.Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== components.Placeholder ? child : null
        )}
      </components.ValueContainer>
    );
  };

  const CustomSingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>{null}</components.SingleValue>
  );
   const CustomSingleValue2 = ({ children,...props }) => {
    console.log("abc",children);
    return(<components.SingleValue {...props}>
      { 'Revenue Synopsis ( ' + children + ' )'}
    </components.SingleValue>);
    
   };

  const handleChange = (option) => {
    if(option.label==='Advertisers' || option.label==='Apps') {
      props.setSelectedHead(option.label);
      const label = "Revenue Synopsis ( " + option.label + " )" 
    }
    else {
      console.log(option);
      const data = JSON.parse(JSON.stringify(props.selectedColumns));
      data[option.value] = !data[option.value];
      console.log(data);
      props.setSelectedColumns(data);
    }

  }
  const handleChange2=(option)=>{
    console.log(option.label);
    props.setItemsOnPage(option.value);
    if(props.setCurrentPage){
      props.setCurrentPage(0)
    }
    if(props.setOffset){
      props.setOffset(0)
    }
  }
  const sortingChangeHandler=(option)=>{
    if(option.label==='Sort in Ascending order'){
        setSorHead('Sort in Ascending order');
        props.setSortHeadOrder(true);
    }
    else{
        setSorHead("Sort in Descending order");
        props.setSortHeadOrder(false);
    }
    option.isSelected = true;
    props.setSortTableHead(props.placeholder);
  }
  return (
    <Select
      onChange={
        props.placeholder === "Data Limit"
          ? handleChange2
          : props.placeholder === "Edit Columns" ||
            props.placeholder === "Revenue Synopsis ( Advertisers ) " ||
            props.placeholder === "Revenue Synopsis ( Apps ) "
          ? handleChange
          : sortingChangeHandler
      }
      img={props.img}
      placeholder={props.placeholder==="Edit Columns"? "":props.placeholder}
      options={props.options}
      isClearable={true}
      components={{
        IndicatorsContainer:
          props.placeholder === "Revenue Synopsis ( Advertisers ) " ||
          props.placeholder === "Revenue Synopsis ( Apps ) "
            ? IndicatorsContainer2
            : props.placeholder === "Edit Columns" ? IndicatorsContainer3:
              props.placeholder === "Data Limit"
            ? IndicatorsContainer1
            : IndicatorsContainer,
        ValueContainer: props.isPlaceholderFixed
          ? CustomValueContainer
          : components.ValueContainer,
        Option:
          props.isRadioOption || props.isCheckboxOption
            ? props.isRadioOption
              ? RadioOption(props.placeholder, props.sortTableHead)
              : CheckboxOption(props.selectedColumns)
            : components.Option,
        SingleValue:
          props.placeholder === "Revenue Synopsis ( Advertisers ) " ||
          props.placeholder === "Revenue Synopsis ( Apps ) "
            ? CustomSingleValue2
            : props.placeholder === "Edit Columns" ||
              props.placeholder === "Data Limit"
            ? CustomSingleValue
            : CustomSingleValue,
      }}
      className="react-select-container"
      classNamePrefix="react-select"
      styles={customStyles}
      isSearchable={false}
      closeMenuOnSelect={props.menu}
    />
  );
}

export default CustomSelect;