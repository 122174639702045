import React,{useEffect, useState} from 'react';
import axios from 'axios';

import { Resizable } from "re-resizable";
import CreatableSelect from 'react-select/creatable';
import ReactSelect from 'react-select';
import { DateRangePicker } from 'react-date-range';
import { Button, CircularProgress } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Pagination from '@mui/material/Pagination';
import SettingsIcon from '@mui/icons-material/Settings';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import TableRowsIcon from '@mui/icons-material/TableRows';
import BarChartIcon from '@mui/icons-material/BarChart';
import ShareIcon from '@mui/icons-material/Share';

import Zoom from '@mui/material/Zoom';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';

import { CssTextField, CURRENCY_LIST_SYMBOLS, ReactSelectStyles } from '../../../data/constants';
import * as Constants from '../../../data/constants';
import StatisticsRouter from '../statisticsRouter';
import '../../../css/pages/v2/statistics.css';
import { useForceUpdate,showHtml, getRequests, getDateFromDateRange, sorting, getLocalStorageValue, setLocalStorageValue } from '../../../util/util';
import { getAdvertisers, getAffiliates, getManager } from '../../../apis/dropdown/dropdowns';
import ShowLoaderComponent from '../../../components/loader';
import DownloadStats from '../downloadStats';
import ArrangeColumns from '../../../components/arrangeColumns/arrangeColumns';
import NoResultsFound from '../../../components/noResultFound';
import SomeErrorOccured from '../../../components/someErrorOccured';
import TableToGraph from './tableToGraph';
import { QueryStats } from '@mui/icons-material';
import { isTestingUser } from '../../../data/sidebarTabs';

const StatisticsAll = () => {
    const updateComponent = useForceUpdate()
    var statsType = window.location.href.split("statistics/")[1]
    if(statsType.includes("?")){
        statsType = statsType.split("?")[0]
    }
    var fixTableHeadersMapping = {
        daily : [{show:true,label:'Date',value:'key',minWidth:60,width:80},],
        weekly : [{show:true,label:'Week',value:'key',minWidth:200,width:200},],
        offer : [
                    {show:true,label:'Offer Id',value:'key',minWidth:60,width:80},
                    {show:true,label:'Offer Title',value:'title',minWidth:120,width:120,link:'/offers/view/'},
                    {show:true,label:'Advertiser',value:'advertiser_id',minWidth:60,width:80},
                    {show:true,label:'Advertiser Manager',value:'manager_id',minWidth:60,width:100},
                    {show:true,label:'External Offer Id',value:'external_offer_id',minWidth:120,width:120},
                ],
        adv :   [
                    {show:true,label:'Advertiser Id',value:'key',minWidth:80,width:90},
                    {show:true,label:'Advertiser Name',value:'company',minWidth:100,width:130,link:'/advertiser/view/'},
                    {show:true,label:'Advertiser Manager',value:'manager_id',minWidth:60,width:100},
                ],
        "adv-manager" : [
                            {show:true,label:'Manager Id',value:'key',minWidth:50,width:60},
                            {show:true,label:'Manager Name',value:'name',minWidth:100,width:130,link:'/account/employees/view/'}
                        ],
        aff : [
                    {show:true,label:'Affiliate Id',value:'key',minWidth:50,width:60},
                    {show:true,label:'Affiliate Name',value:'company',minWidth:100,width:130,link:'/affiliates/management/view/'}
                ],
        "aff-manager" : [
                            {show:true,label:'Manager Id',value:'key',minWidth:50,width:60},
                            {show:true,label:'Manager Name',value:'name',minWidth:100,width:130,link:'/account/employees/view/'}
                        ],

        goals : [
                    {show:true,label:'Index',value:'key',minWidth:70,width:130},
                    {show:true,label:'Goal Title',value:'goal_title',minWidth:150,width:220}
                ],
        trafficback : [
                            {show:true,label:'Trafficback Reason',value:'key',minWidth:200,width:300},
                        ],
        smartlinks : [
            {show:true,label:'ID',value:'key',minWidth:60,width:80},
            {show:true,label:'Name',value:'name',minWidth:150,width:200,link:'/smartlinks/edit/'},
        ],

    }
    var fixTableHeaders = fixTableHeadersMapping[statsType]
    var url = new URL(window.location.href)
    
    if(url.searchParams.get('dateRange')){
        var startDate = new Date(url.searchParams.get('dateRange').split("_")[0])
        var endDate = new Date(url.searchParams.get('dateRange').split("_")[1])
    }
    const [dateRange, setDateRange] = useState(url.searchParams.get('dateRange') ? [startDate,endDate] : [new Date(new Date().getTime()-86400000*(statsType==='offer' ? 0 : 6)),new Date()])

    var searchParamsList = [
        {label:'offerId',setValueList:[],},
        {label:'advertiser',setValueList:[],},
        {label:'affiliate',setValueList:[],},
        {label:'advertiserManager',setValueList:[],},
        {label:'affiliateManager',setValueList:[],},
        {label:'timezone',setValueList:getLocalStorageValue('panelTimezone') ? getLocalStorageValue('panelTimezone') : {label : "UTC (UTC+00:00)" , value : "UTCUTC+00:00"},},
        {label:'externalOfferId',setValueList:[],},
    ]
    if(url.searchParams.get('offerId') || url.searchParams.get('externalOfferId') || url.searchParams.get('advertiser') || url.searchParams.get('affiliate') || url.searchParams.get('affiliateManager') || url.searchParams.get('advertiserManager')){
        for(var searchParamsListIndex in searchParamsList){
            var elem = searchParamsList[searchParamsListIndex]
            if(url.searchParams.get(elem.label)){
                var selectList = []
                var queryTextList = url.searchParams.get(elem.label).split(",")
                for(var index in queryTextList){
                    if(elem.label!=='offerId' && elem.label!=='externalOfferId'){
                        selectList.push({
                            label: queryTextList[index],
                            value: queryTextList[index].substring(queryTextList[index].lastIndexOf("-") + 1, queryTextList[index].length)
                        })
                    }
                    else{
                        selectList.push({
                            label: queryTextList[index],
                            value: queryTextList[index],
                        })
                    }
                }
                elem.setValueList = selectList
            }
        }
    }

    const [searchOfferList, setSearchOfferList] = useState(searchParamsList[0].setValueList)
    const [searchSmartlinkList, setSearchSmartlinkList] = useState([])
    const [searchGoalList, setSearchGoalList] = useState([])
    const [searchExternalOfferIdList, setSearchExternalOfferIdList] = useState(searchParamsList[6].setValueList)
    const [searchAdvertiserList, setSearchAdvertiserList] = useState(searchParamsList[1].setValueList)
    const [searchAffiliateList, setSearchAffiliateList] = useState(searchParamsList[2].setValueList)
    const [searchAdvertiserManagerList, setSearchAdvertiserManagerList] = useState(searchParamsList[3].setValueList)
    const [searchAffiliateManagerList, setSearchAffiliateManagerList] = useState(searchParamsList[4].setValueList)
    const [searchOfferSource, setSearchOfferSource] = useState(null)
    const [searchPackageName, setPackageName] = useState('')
    const [timezone, setTimezone] = useState(searchParamsList[5].setValueList)
    const [offerSourceSelected, setOfferSourceSelected] = useState(false)
    
    var tempShowConversion = false
    if(url.searchParams.get('showConversion') && url.searchParams.get('showConversion')==='1'){
        tempShowConversion = true
    }
    const [showConversionOnly, setShowConversionOnly] = useState(tempShowConversion)

    const [dateRangeState, setDateRangeState] = useState([{
                                                                startDate: new Date(dateRange[0]),
                                                                endDate: new Date(dateRange[1]),
                                                                key: 'selection'
                                                            }])
    const [showDatePicker, setShowDatePicker] = useState(false)
    
    const [optionsAdvertiserList, setOptionsAdvertiserList] = useState([])
    const [optionsAffiliateList, setOptionsAffiliateList] = useState([])
    const [optionsManagerList, setOptionsManagerList] = useState([])
    var tempSortingField = {label:'Delivered Revenue',value:'approved_revenue'}
    if(statsType==='daily'){
        tempSortingField = {label:'Date',value:'date'}
    }
    if(statsType==='weekly'){
        tempSortingField = {label:'Week',value:'week'}
    }
    if(url.searchParams.get('useNewApi')==='false'){
        tempSortingField = {label:'Clicks',value:'clicks'}
    }
    const [sortingField, setSortingField] = useState(tempSortingField)
    const [sortingOrderBy, setSortingOrderBy] = useState(false) //false for DESC
    const [showRedirectPopup, setShowRedirectPopup] = useState(false)
    
    var useNewApiTemp = true
    var currencyTemp = null
    
    if(url.searchParams.get('useNewApi')){
        if(url.searchParams.get('useNewApi')==='false'){
            useNewApiTemp = false
        }
    }
    if(url.searchParams.get('currency')){
        if(url.searchParams.get('currency')==="null"){
            currencyTemp = null
        }
        else{
            currencyTemp = url.searchParams.get('currency')
        }
    }

    if(statsType!=='daily' && statsType!=='weekly' && statsType!=='offer' && statsType!=='adv' && statsType!=='aff' && statsType!=='adv-manager' && statsType!=='aff-manager' && statsType!=='goals' && statsType!=='smartlinks'){
        useNewApiTemp = false
        currencyTemp = null
    }
    const [useNewApi, setUseNewApi] = useState(useNewApiTemp)
    const [selectedCurrency, setSelectedCurrency] = useState(currencyTemp)


    const dateRangePickedMethod = (item) => {
        setDateRangeState([item.selection]);
        if (item.selection.endDate !== item.selection.startDate){
            // setShowDatePicker(false)
            var value = [item.selection.startDate,item.selection.endDate]
            if (value[0] && value[1]){
                setDateRange(value)
                // setShowDatePicker(false)
                }
        }
    }

    var timezoneList = Constants.timezoneList
    const filtersList = [
        {label:'Date Range',value:getDateFromDateRange(dateRange),setMethod:dateRangePickedMethod,show:true},
        {label:'Timezone',value:timezone,isSingle:true,setMethod:setTimezone,options:timezoneList,show:(statsType==='failed/postback' || statsType==='conversions' || statsType==='goals' || statsType==='trafficback' ? false : true)},
        {label:'Offer ID',mandatory:(statsType==='goals' ? true : false),isSingle:(statsType==='goals' ? true : false),value:searchOfferList,setMethod:setSearchOfferList,show:(statsType==='failed/postback' ? false : true)},
        {label:'Smartlink ID',value:searchSmartlinkList,setMethod:setSearchSmartlinkList,show:(statsType==='smartlinks' ? true : false)},
        {label:'Goal Value',value:searchGoalList,setMethod:setSearchGoalList,show:(statsType==='goals' ? true : false)},
        {label:'External Offer Id',value:searchExternalOfferIdList,setMethod:setSearchExternalOfferIdList,show:(statsType==='failed/postback' || statsType==='conversions' || statsType==='goals' || statsType==='trafficback' ? false : true)},
        {label:'Package Name',value:searchPackageName,isSingle:true,setMethod:setPackageName,show:(statsType==='failed/postback' || statsType==='conversions' || statsType==='goals' || statsType==='trafficback' ? false : true)},
        {label:'Advertiser',value:searchAdvertiserList,isSingle:offerSourceSelected,setMethod:setSearchAdvertiserList,options:optionsAdvertiserList,show:(statsType==='failed/postback' || statsType==='conversions' ? false : true)},
        {label:'Affiliate',value:searchAffiliateList,setMethod:setSearchAffiliateList,options:optionsAffiliateList,show:(statsType==='failed/postback' || statsType==='conversions' ? false : true)},
        {label:'Advertiser Manager',value:searchAdvertiserManagerList,setMethod:setSearchAdvertiserManagerList,options:optionsManagerList,show:(statsType==='failed/postback' || statsType==='conversions' || statsType==='trafficback' ? false : true)},
        {label:'Affiliate Manager',value:searchAffiliateManagerList,setMethod:setSearchAffiliateManagerList,options:optionsManagerList,show:(statsType==='failed/postback' || statsType==='conversions' || statsType==='trafficback' ? false : true)},
        {label:'Offer Source',value:searchOfferSource,setMethod:setSearchOfferSource,options:[{label:'All',value:undefined},{label:'Automated',value:'automated'},{label:'Manual',value:'manual'}],show:searchAdvertiserList.length===1 && !(statsType==='failed/postback' || statsType==='conversions' || statsType==='goals' || statsType==='trafficback') ? true : false,isSingle:true},
    ]
    
    const resetAllFields = (e) => {
        for(var index in filtersList){
            var filterItem = filtersList[index]
            if(filterItem.show){
                if(filterItem.label==='Date Range'){
                    var resetSelection = {selection : {
                                                            startDate: new Date(new Date().getTime()-86400000*6),
                                                            endDate: new Date(),
                                                            key: 'selection'
                                                        }}
                    filterItem.setMethod(resetSelection)
                }
                else{
                    filterItem.setMethod([])
                }
            }
        }
        var url = '/statistics/'+statsType
        window.history.pushState('Statistics - '+statsType, 'Statistics - ' + statsType, url);
        updateComponent(e);
    }

    const [fixedTableHeaders, setFixedTableHeaders] = useState(fixTableHeaders)

    var varTableHeaders = []
    var varTableHeadersMapping = {
        daily : [],
        weekly : [],
        offer : [
                    {show:true,label:'Offer Title',value:'title',minWidth:90,width:90,link:'/offers/view/'},
                    {show:true,label:'Advertiser',value:'advertiser_id',minWidth:60,width:80},
                    {show:true,label:'Advertiser Manager',value:'manager_id',minWidth:60,width:100},
                    {show:true,label:'External Offer Id',value:'external_offer_id',minWidth:90,width:90},
                ],
        adv :   [
                    {show:true,label:'Advertiser Name',value:'company',minWidth:100,width:130,link:'/advertiser/view/'},
                    {show:true,label:'Advertiser Manager',value:'manager_id',minWidth:60,width:100},
                ],
        "adv-manager" : [
                            {show:true,label:'Manager Name',value:'name',minWidth:100,width:130,link:'/account/employees/view/'}
                        ],
        aff : [
                    {show:true,label:'Affiliate Name',value:'company',minWidth:100,width:130,link:'/affiliates/management/view/'}
                ],
        "aff-manager" : [
                            {show:true,label:'Manager Name',value:'name',minWidth:100,width:130,link:'/account/employees/view/'}
                        ],

        goals : [
                    {show:true,label:'Goal Title',value:'goal_title',minWidth:150,width:220}
                ],
        trafficback : [],
        smartlinks : [
            {show:true,label:'Name',value:'name',minWidth:150,width:200},
        ],

    }
    if(process.env.REACT_APP_COMPANY_NAME==='BAYOFADS'){
        var varTableHeadersConstants = [
            {id:'threezero',show:true,label:'Redirections',value:'redirections',minWidth:90,width:100,disable:((statsType==='offer' && process.env.REACT_APP_COMPANY_NAME==='BAYOFADS') ? false : true)},
        ]
    }
    else{
        varTableHeadersConstants = []
    }

    varTableHeadersConstants = [
        ...varTableHeadersConstants,
        {id:'threeone',show:true,label:'Impressions',value:'impressions',minWidth:90,width:100,disable:(statsType==='goals' ? true : false)},
        {id:'one',show:true,label:'Clicks',value:'clicks',parent:'Traffic',minWidth:70,width:100,disable:(statsType==='goals' ? true : false)},
        {id:'two',show:true,label:'Trafficback',value:'trafficback',parent:'Traffic',minWidth:90,width:110,link:"/statistics/trafficback?",disable:(statsType==='goals' ? true : false)},
        {id:'twofour',label:'Caps',value:'caps',minWidth:70,width:90,show:(statsType==='offer' ? true : false)},
        {id:'three',show:true,label:'Delivered QTY',value:'conversions',minWidth:70,width:110,link:'/statistics/conversions?type=approved&'},
        {id:'twothree',label:'Delivered Postback',value:'postback',minWidth:90,width:110,show:(statsType==='goals' || statsType==='trafficback' ? false : true)},
        {id:'four',show:true,label:'Delivered Revenue',value:'approved_revenue',minWidth:70,width:110},
        {id:'five',show:true,label:'Declined QTY',value:'declined',minWidth:70,width:110,link:'/statistics/conversions?type=declined&groupBy=goal_value&'},
        {id:'six',show:true,label:'Declined Revenue',value:'declined_revenue',minWidth:70,width:110},
        {id:'seven',show:true,label:'CR',value:'cr',minWidth:50,width:110,disable:(statsType==='goals' ? true : false)},
        {id:'eight',show:true,label:'EPC',value:'epc',minWidth:50,width:110,disable:(statsType==='goals' ? true : false)},
        {id:'nine',show:true,label:'Total QTY',value:'total_qty',minWidth:50,width:110},
        {id:'ten',show:true,label:'Total Revenue',value:'approved_revenue',minWidth:70,width:110},
        {id:'eleven',show:true,label:'Total Payout',value:'approved_payout',minWidth:60,width:110},
        {id:'twelve',show:true,label:'Total Earnings',value:'total_earnings',minWidth:90,width:110},
    ]
    varTableHeaders = varTableHeadersConstants
    if(statsType==='trafficback'){
        varTableHeaders = [
            {id:'thirteen',show:true,label:'Number',value:'value',minWidth:200,width:300},
        ]
    }

    const [variableTableHeaders, setVariableTableHeaders] = useState(localStorage.getItem(statsType+'tableContents') ? JSON.parse(localStorage.getItem(statsType+'tableContents')) : varTableHeaders)
    const [mobileComponent, setMobileComponent] = useState(false)
    if(window.innerHeight > window.innerWidth && !mobileComponent){
        setMobileComponent(true)
        var tempVariableHeaders = variableTableHeaders
        tempVariableHeaders = [...varTableHeadersMapping[statsType],...varTableHeadersConstants]
        setVariableTableHeaders(tempVariableHeaders)
        var fixedHeadersTemp = []
        fixedHeadersTemp.push(fixedTableHeaders[0])
        setFixedTableHeaders(fixedHeadersTemp)
    }
    
    const [editColumnsPopup, setEditColumnsPopup] = React.useState(false);

    const closeEditColumnsPopup = (e) => {
        setEditColumnsPopup(false);
        localStorage.setItem(statsType+'tableContents',JSON.stringify(variableTableHeaders))
    }

    if(false){
        setUseNewApi(true);
    }
    const [showLoader, setShowLoader] = useState(false)
    const [showExportPopup, setShowExportPopup] = useState(false)
    const [response, setResponse] = useState(statsType==='goals' ? statsType : [])
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [totalResults, setTotalResults] = useState(50);
    const [offset, setOffset] = useState(1);
    const [totalValue, setTotalValue] = useState({})
    const [shareApiLoader, setShareApiLoader] = useState(false)
    const [shareApiData, setShareApiData] = useState("")

    const getStatsData = async(e,manual_limit,manual_page,shareApi) => {
        setShareApiData(false)
        if(!shareApi){
            setShowLoader(true);
        }
        else{
            setShareApiLoader(true);
        }

        var filterData = {
            offer_id:{value:null,data:statsType==='goals' && searchOfferList.value ? [searchOfferList] : searchOfferList},
            goal_id:{value:null,data:searchGoalList},
            external_offer_id:{value:null,data:searchExternalOfferIdList},
            advertiser_id:{value:null,data:searchAdvertiserList},
            advertiser_manager_id:{value:null,data:searchAdvertiserManagerList},
            affiliate_id:{value:null,data:searchAffiliateList},
            affiliate_manager_id:{value:null,data:searchAffiliateManagerList},
            smartlink_id:{value:null,data:searchSmartlinkList},
        }
        var urlEndMapping = {
            daily : useNewApi ? "date/new" : "date",
            weekly : useNewApi ? "week" : "week",
            offer : useNewApi ? "offer/new" : "offer",
            adv : useNewApi ? "advertiser/new" : "advertiser",
            "adv-manager" : useNewApi ? "advertiser/manager/new" : "advertiser/manager",
            aff : useNewApi ? "affiliate/new" : "affiliate",
            "aff-manager" : useNewApi ? "affiliate/manager/new" : "affiliate/manager",
            goals : "goal",
            trafficback : "trafficback",
            smartlinks : "smartlink",
        }
        for(var filterItem in filterData){
            var searchString = ""
            for(var index in filterData[filterItem].data){
                searchString += filterData[filterItem].data[index].value+","
            }
            searchString = searchString.slice(0,-1)
            if(searchString){
                filterData[filterItem].value = searchString
            }
        }
        var dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric'};
        var startDate = new Date(dateRange[0]).toLocaleDateString("sv-SE", dateOptions)
        var endDate = new Date(dateRange[1]).toLocaleDateString("sv-SE", dateOptions)
        var requestPage = manual_page ? manual_page : page
        var requestLimit = manual_limit ? manual_limit : limit
        var url ="".concat(Constants.PROTOCOL2,Constants.HOST2,Constants.API_USER_PATH,Constants.GETSTATS,"/",urlEndMapping[statsType])
        if(shareApi){
            var urlEndingShareApi = urlEndMapping[statsType]
            if(urlEndingShareApi.includes("/new")){
                urlEndingShareApi = urlEndingShareApi.split('/new')[0]
            }
            url = "".concat(Constants.PROTOCOL2,Constants.HOST2,"/api/report/v1/share/",urlEndingShareApi)
        }
        return await axios({
            method: 'GET',
            url,
            headers: {
                "Content-Type":"application/json",
                Authorization:getLocalStorageValue('adminPanelLoginData').token
              },
            params: { 
                //filters
                startDate,
                endDate,
                offer_id:filterData.offer_id.value,
                external_offer_id:filterData.external_offer_id.value,
                package_name:searchPackageName ? searchPackageName.value : undefined,
                advertiser_id:filterData.advertiser_id.value,
                offer_source: searchOfferSource && (filterData.advertiser_id.value && !filterData.advertiser_id.value.includes(','))  ? searchOfferSource.value : undefined,
                advertiser_manager_id: filterData.advertiser_manager_id.value,
                affiliate_id:filterData.affiliate_id.value,
                affiliate_manager_id : filterData.affiliate_manager_id.value,
                goal_id : filterData.goal_id.value,
                smartlink_id : filterData.smartlink_id.value,
                converted_stats : showConversionOnly ? '1' : undefined,
                currency:selectedCurrency && selectedCurrency.toUpperCase()==='ALL/USD' ? undefined : selectedCurrency,                
                //pagination and orderby
                limit:requestLimit,
                page:requestPage,
                order_by : sortingOrderBy ? "ASC" : "DESC",
                order_field : statsType==='weekly' ? 'date' : sortingField.value,

                utc_offset : timezone.value ? timezone.value.split(timezone.label.split(" (UTC")[0])[timezone.value.split(timezone.label.split(" (UTC")[0]).length-1].split("UTC")[1] : "+0:00",
                // currency:(manualCurrency ? manualCurrency : currency).toLowerCase(),
              }
        })
        .then(resp => {
            setShareApiLoader(false);
            setShowLoader(false);
            if(!shareApi){
                setPage(requestPage);
                setOffset(requestPage*requestLimit - requestLimit + 1);
                var responseList = []
                var response = resp.data.result.data
                if(statsType==='trafficback'){
                    response = resp.data.result
                    for(var index in response){
                        var item = response[index]
                        responseList.push({
                            key:index,
                            value:item
                        })
                        // responseList.push({...response[index],key:index})
                    } 
                }
                else{
                    for(index in response){
                        item = response[index]
    
                        responseList.push({
                            ...response[index],
                            key:index,
                            cr:Math.round((((item.conversions + item.declined)/item.clicks) + Number.EPSILON) * 10000)*100 / 10000,
                            total_earnings:(item.payouts[0].approved_revenue - item.payouts[0].approved_payout),
                            approved_payout:item.payouts[0].approved_payout,
                            approved_revenue:item.payouts[0].approved_revenue,
                            declined_revenue:item.payouts[0].declined_revenue,
                            declined_payout:item.payouts[0].declined_payout,
                            total_qty:parseInt(item.conversions)+parseInt(item.declined),
                            currency:item.payouts[0].currency,
                            epc:(item.payouts[0].approved_revenue/item.clicks).toFixed(3)
                        })
                        // responseList.push({...response[index],key:index})
                    }
                    if((statsType==='daily' && sortingField.value==='date') || (statsType==='weekly' && sortingField.value==='week') || (statsType==='offer' && sortingField.value==='offer_id')){
                        sorting(responseList,'key',(statsType==='daily' ? false : true),sortingOrderBy)
                    }
                    else{
                        sorting(responseList,sortingField.value,true,sortingOrderBy)   
                    }
                }
                setResponse(responseList)
                if(statsType==='trafficback'){
                    setTotalResults(Object.keys(resp.data.result).length)
                }
                else{
                    setTotalResults(resp.data.result.count)
                }
            }
            else{
                setShareApiData(resp.data.result)
            }
        })
        .catch((error) => {
            setResponse([]);
            setTotalResults(0);
            setOffset(0);
            setShowLoader(false);
            setShareApiLoader(false);
            if (error.response) {
                console.log(error.response.data);
                setResponse(error.response.data.result);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        })
    };

    const [manualUseState, setManualUseState] = useState(false)
    var documentTitleMapping =  {
        daily : 'Daily',
        weekly : 'Weekly',
        offer : 'Offer',
        adv :   'Advertiser',
        "adv-manager" :'Advertiser Manager',
        aff : 'Affiliate',
        "aff-manager" : 'Affiliate Manager',
        goals : 'Goals',
        trafficback : 'Trafficback',
        smartlinks : 'Smartlinks',
    }
    if(!manualUseState){
        document.title = 'Statistics - '+ documentTitleMapping[statsType]
        setManualUseState(true);
        if(statsType!=='goals'){
            getStatsData();
        }
        getRequests(getAdvertisers(),setOptionsAdvertiserList)
        getRequests(getAffiliates(),setOptionsAffiliateList)
        
        if(statsType!=='trafficback'){
            getRequests(getManager(),setOptionsManagerList)
        }
    }   

    const [sortingOrder, setSortingOrder] = useState(false)
    const [sortingOrderField, setSortingOrderField] = useState('key')

    const setSorting = (e,value,intValue=false) => {
        var unsortedResponse = response
        if(sortingOrderField===value){
            sorting(response,value,intValue,!sortingOrder)
            setSortingOrder(!sortingOrder)
        }
        else{
            sorting(response,value,intValue,false)
            setSortingOrder(false)
            setSortingOrderField(value)
        }
        setResponse(unsortedResponse)
        updateComponent(e)
    }

    const handleSearchButton = (e) => {
        getStatsData(e,limit,1);

        var url = '/statistics/'+statsType+"?"
        url+= (dateRange  ? "dateRange=" + getDateFromDateRange(dateRange).replaceAll('/',"-").replaceAll(" ","").replace("->","_") +"&" : "")
        var searchFieldList = [
                                {label:'offerId',value:searchOfferList},
                                {label:'externalOfferId',value:searchExternalOfferIdList},
                                {label:'advertiser',value:searchAdvertiserList},
                                {label:'affiliate',value:searchAffiliateList},
                                {label:'affiliateManager',value:searchAffiliateManagerList},
                                {label:'advertiserManager',value:searchAdvertiserManagerList},
                            ]

        for(var searchFieldIndex in searchFieldList){
            if(searchFieldList[searchFieldIndex].value.length>0){
                url+=searchFieldList[searchFieldIndex].label+"="
                for(var index in searchFieldList[searchFieldIndex].value){
                    if(searchFieldList[searchFieldIndex].label==='offerId' || searchFieldList[searchFieldIndex].label==='externalOfferId'){
                        url+= searchFieldList[searchFieldIndex].value[index].value
                    }
                    else{
                        url+= searchFieldList[searchFieldIndex].value[index].label.split("-")[0]+"-"+searchFieldList[searchFieldIndex].value[index].value
                    }
                    if(parseInt(index)!==searchFieldList[searchFieldIndex].value.length-1){
                        url+=","
                    }
                }
                url+="&"
            }
        }      
        if(showConversionOnly){
            url += "showConversion="+showConversionOnly+"&"
        }  
        // url += "useNewApi="+useNewApi+"&"
        if(useNewApi){
        }

        url = url.slice(0,url.length-1)
        window.history.pushState('Statistics - '+documentTitleMapping[statsType], 'Statistics - ' + documentTitleMapping[statsType], url);
    }

    const getLink = (keyItem,tableHeaderItem) => {
        var link = tableHeaderItem.link

        if(statsType==='daily'){
            link+= "dateRange="+getDateFromDateRange([response[keyItem]['key'],response[keyItem]['key']]).replaceAll('/',"-").replaceAll(" ","").replace("->","_") +"&"
        }
        if(statsType==='weekly'){
            console.log()
            link+= "dateRange="+response[keyItem]['key'].split("/")[0].replaceAll(" ","")+"_"+response[keyItem]['key'].split("/")[1].replaceAll(" ","") +"&"
        }
        else{
            link+= "dateRange="+getDateFromDateRange(dateRange).replaceAll('/',"-").replaceAll(" ","").replace("->","_") +"&"
        }
        if(statsType==='offer'){
            if(response[keyItem]){
                link+= "offerId="+ response[keyItem]['key'] +"&"
            }
        }
        else if(searchOfferList && searchOfferList.length>0){
            var offerSearchText = searchOfferList.map((item,index)=>{return item.value}).toString()
            link+= "offerId="+ offerSearchText +"&"
        }
        var searchFieldList = [
            {statsType:'adv',label:'advertiser',value:searchAdvertiserList,name:'company'},
            {statsType:'adv-manager',label:'advertiserManager',value:searchAdvertiserManagerList,name:'name'},
            {statsType:'aff',label:'affiliate',value:searchAffiliateList,name:'company'},
            {statsType:'aff-manager',label:'affiliateManager',value:searchAffiliateManagerList,name:'name'},
            {statsType:'showConversion',label:'showConversionsOnly',value:showConversionOnly,name:'name'},
        ]
        searchFieldList.forEach(searchFieldItem => {
            if(statsType===searchFieldItem.statsType){
                link+= searchFieldItem.label+"="+ response[keyItem][searchFieldItem.name]+" - " + response[keyItem]['key'] +"&"
            }
            else if(searchFieldItem.value.length>0){
                var searchText = searchFieldItem.value.map((item,index)=>{return (item.label.includes(" -") ? item.label.split(" -")[0] : item.label) + " - " + item.value}).toString()
                link+= searchFieldItem.label+"="+ searchText +"&"
            }
        })
        return link
    }
    useEffect(() => {
        document.addEventListener('keydown',e=>{
            if(e.key==='Escape'){
                setShowRedirectPopup(false);
            }
        })
        
        return () => {
            document.removeEventListener('keydown',e=>{
                if(e.key==='Escape'){
                    setShowRedirectPopup(false);
                }
            })
        }
    }, [])
    
    const showValues = () => {
        var totalValues = {}
        var rows = Object.keys(response).map((keyItem,keyIndex)=>{
            var height = 30
            if(response[keyItem].payouts && response[keyItem].payouts.length>1){
                height = 30 + response[keyItem].payouts.length*10
            }
            return <div id={'variableColumns'+keyIndex} style={{display:'flex',height}} key={keyIndex}>
                    {
                        variableTableHeaders.map((tableHeaderItem,tableHeaderIndex)=>{
                            if(!tableHeaderItem.disable && tableHeaderItem.show){    
                                if(["clicks","conversions","declined","postback","trafficback",'total_qty','impressions'].includes(tableHeaderItem.value)){
                                    if(totalValues[tableHeaderItem.value]){
                                        totalValues[tableHeaderItem.value]+= response[keyItem][tableHeaderItem.value]
                                    }
                                    else{
                                        totalValues[tableHeaderItem.value] = response[keyItem][tableHeaderItem.value]
                                    }
                                }
                                var showValue = ""
                                if(tableHeaderItem.value==='approved_revenue' || tableHeaderItem.value==='declined_revenue' || tableHeaderItem.value==='approved_payout'){
                                    for(var payoutIndex in response[keyItem].payouts){
                                        var payoutItem = response[keyItem].payouts[payoutIndex]
                                        var currencySymbol = ""
                                        if(response[keyItem].payouts[payoutIndex].currency && CURRENCY_LIST_SYMBOLS[response[keyItem].payouts[payoutIndex].currency.toUpperCase()]){
                                            currencySymbol = CURRENCY_LIST_SYMBOLS[response[keyItem].payouts[payoutIndex].currency.toUpperCase()]
                                        }
                                        else{
                                            currencySymbol = response[keyItem].payouts[payoutIndex].currency.toUpperCase()
                                        }
                                        showValue += response[keyItem].payouts[payoutIndex][tableHeaderItem.value].toFixed(2) + " " + currencySymbol
                                        if(parseInt(payoutIndex)!==response[keyItem].payouts.length-1){
                                            showValue += "<br/>"
                                        }
                                        if(totalValues[tableHeaderItem.value] && totalValues[tableHeaderItem.value][payoutItem.currency] ){
                                            for(var innerPayoutKey in payoutItem){
                                                if(tableHeaderItem.value===innerPayoutKey){
                                                    totalValues[tableHeaderItem.value][payoutItem.currency] += payoutItem[innerPayoutKey]
                                                }
                                            }
                                        }
                                        else{
                                            totalValues[tableHeaderItem.value] = {...totalValues[tableHeaderItem.value],[payoutItem.currency]:payoutItem[tableHeaderItem.value]}
                                        }
                                    }
                                }
                                else if(tableHeaderItem.value==='cr'){
                                    if(response[keyItem][tableHeaderItem.value]>0 && response[keyItem][tableHeaderItem.value]!==Infinity){
                                        showValue = response[keyItem][tableHeaderItem.value]
                                        var rowItem = response[keyItem]
                                        showValue += ((rowItem.conversions + rowItem.declined)===0 ? " (0:0)" : " (1:" + parseInt(rowItem.clicks/(rowItem.conversions + rowItem.declined)) + ")")
                                    }
                                    else{
                                        showValue = 0
                                    }
                                }
                                else if(tableHeaderItem.value==='epc'){
                                    if(response[keyItem]['payouts'].length>0){
                                        if(response[keyItem][tableHeaderItem.value]>0 && response[keyItem][tableHeaderItem.value]!=='Infinity'){
                                            showValue = response[keyItem][tableHeaderItem.value]
                                        }
                                        else{
                                            showValue = 0
                                        }
                                    }
                                    else{
                                        showValue = 0
                                    }
                                }
                                else if(tableHeaderItem.value==='total_earnings'){
                                    for(payoutIndex in response[keyItem].payouts){
                                        payoutItem = response[keyItem].payouts[payoutIndex]
                                        currencySymbol = ""
                                        if(response[keyItem].payouts[payoutIndex].currency && CURRENCY_LIST_SYMBOLS[response[keyItem].payouts[payoutIndex].currency.toUpperCase()]){
                                            currencySymbol = CURRENCY_LIST_SYMBOLS[response[keyItem].payouts[payoutIndex].currency.toUpperCase()]
                                        }
                                        else{
                                            currencySymbol = response[keyItem].payouts[payoutIndex].currency.toUpperCase()
                                        }
                                        showValue += (response[keyItem].payouts[payoutIndex]['approved_revenue'] - response[keyItem].payouts[payoutIndex]['approved_payout']).toFixed(2) + " " + currencySymbol
                                        if(parseInt(payoutIndex)!==response[keyItem].payouts.length-1){
                                            showValue += "<br/>"
                                        }
                                        if(totalValues[tableHeaderItem.value] && totalValues[tableHeaderItem.value][payoutItem.currency] ){
                                            totalValues[tableHeaderItem.value][payoutItem.currency] += payoutItem['approved_revenue'] - payoutItem['approved_payout']
                                        }
                                        else{
                                            totalValues[tableHeaderItem.value] = {...totalValues[tableHeaderItem.value],[payoutItem.currency]:payoutItem['approved_revenue'] - payoutItem['approved_payout']}
                                        }
                                    }
                                }
                                else if(tableHeaderItem.value==='total_qty'){
                                    showValue = response[keyItem]['conversions'] + response[keyItem]['declined']
                                }
                                else{
                                    showValue = response[keyItem][tableHeaderItem.value]
                                }
                                if(tableHeaderItem.value==='redirections'){
                                    return <div className='statsV2TableValueData overflowText' style={{minWidth:tableHeaderItem.minWidth,width:tableHeaderItem.width}} key={tableHeaderIndex}>
                                        <Tooltip title={response[keyItem]['conversions']>0 || response[keyItem]['declined']>0 ? 'Click to show redirections.' : 'Cannot show redirections for offers where Delivered Qty is less than 1.'}>
                                            <IconButton sx={{cursor:response[keyItem]['conversions']>0 || response[keyItem]['declined'] ? 'pointer' : 'not-allowed'}} onClick={()=>{if(response[keyItem]['conversions']>0){setShowRedirectPopup({...response[keyItem]});}}}>
                                                <QueryStats sx={{color:response[keyItem]['conversions']>0 || response[keyItem]['declined'] ? localStorage.getItem('ambientColor') : 'grey'}}/>
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                }
                                if(tableHeaderItem.link){
                                    var link = getLink(keyItem,tableHeaderItem)
                                    return <div className='statsV2TableValueData overflowText' style={{minWidth:tableHeaderItem.minWidth,width:tableHeaderItem.width}} key={tableHeaderIndex}>
                                            <a className='statsV2AnchorLink' href={link} target='_blank' rel='noreferrer'>
                                                { showHtml(showValue)}
                                            </a>
                                        </div>
                                }
                                return <div className='statsV2TableValueData overflowText' style={{minWidth:tableHeaderItem.minWidth,width:tableHeaderItem.width}} key={tableHeaderIndex}>
                                    { showHtml(showValue)}
                                    </div>
                            }
                            return null;
                        })
                    }
                </div>
        })
        if((Object.keys(totalValue).length===0 && Object.keys(totalValues).length>0) 
                || (totalValue.clicks!==totalValues.clicks) 
                || (totalValue.trafficback!==totalValues.trafficback) 
                || (totalValue.conversions!==totalValues.conversions) 
                || (totalValue.declined!==totalValues.declined) 
                || (totalValue.total_qty!==totalValues.total_qty) 
                || (totalValue.approved_revenue && totalValues.approved_revenue && Object.keys(totalValue.approved_revenue).length>0 && Object.keys(totalValues.approved_revenue).length>0 ? Object.keys(totalValue.approved_revenue)[0]!==Object.keys(totalValues.approved_revenue)[0] : false)
            ){
            setTotalValue(totalValues)
        }
        return rows;
    }
    var exportDataMapping = {
        daily : "Daily",
        weekly : "Weekly",
        offer : "Offer",
        adv :  "Advertiser",
        "adv-manager" : "AdvertiserManager",
        aff : "Affiliate",
        "aff-manager" : "AffiliateManager",
        "goals" : "Goal",
        smartlinks: 'Smartlink',
        
    }
    const [graphMode, setGraphMode] = useState(false)


    const getSortingOptions = () => {
        var sortingOptions = []
        if(statsType==='daily'){
            sortingOptions.push({label:'Date',value:'date'})
        }
        else if(statsType==='weekly'){
            sortingOptions.push({label:'Weekly',value:'week'})
        }
        else if(statsType==='offer'){
            sortingOptions.push({label:'Offer ID',value:'offer_id'})
        }
        sortingOptions.push({label:'Clicks',value:'clicks'})
        if(useNewApi){
            sortingOptions.push({label:'Trafficback',value:'trafficback'})
            sortingOptions.push({label:'Delivered QTY',value:'conversions'})
            sortingOptions.push({label:'Delivered Revenue',value:'approved_revenue'})
            sortingOptions.push({label:'Declined QTY',value:'declined'})
            sortingOptions.push({label:'Declined Revenue',value:'declined_revenue'})
            sortingOptions.push({label:'Total Payout',value:'approved_payout'})
            // sortingOptions.push({label:'Declined Payout',value:'declined_payout'})
        }
        return sortingOptions
    }
    var panelCurrencies = [{label:'ALL/USD',value:null}]
    if(getLocalStorageValue('adminPanelLoginData').panelSettings && getLocalStorageValue('adminPanelLoginData').panelSettings.currency){
        for(index in getLocalStorageValue('adminPanelLoginData').panelSettings.currency){
            panelCurrencies.push({label:index,value:index})
        }
    }
    var listedCurrencies = panelCurrencies

    const [showTooltip,setShowToolTip] = React.useState(false);

    const handleCopyContent = () => {
        navigator.clipboard.writeText(window.location.host+'/shared/'+shareApiData)
        setShowToolTip(true)
    }
    return (
        <div className='mainContainerV2' onClick={()=>{if(showDatePicker){setShowDatePicker(false)}}}>
            <StatisticsRouter/>
            {showExportPopup && 
                <DownloadStats 
                    handleClose={()=>{setShowExportPopup(false)}}
                    type={exportDataMapping[statsType]}
                    datelist={dateRange}
                    // order_field={requestOrder}
                    // order_by={requestOrderBy}
                    currency={selectedCurrency && selectedCurrency.toUpperCase()==='ALL/USD' ? undefined : selectedCurrency}
                    timezone={timezone}
                    external_offer_id={searchExternalOfferIdList}
                    offer_id={statsType==='goals' ? searchOfferList.value : searchOfferList.map((item,index)=>{return item.value}).toString()}
                    goal_id={searchGoalList.map((item,index)=>{return item.value}).toString()}
                    advertiser_id={searchAdvertiserList}
                    advertiser_manager_id={searchAdvertiserManagerList}
                    affiliate_id={searchAffiliateList}
                    affiliate_manager_id={searchAffiliateManagerList}
                    smartlink_id={searchSmartlinkList}
                    show_conversion_stats={showConversionOnly}
                    offerSourceSelected={offerSourceSelected}
                /> }
            {editColumnsPopup && <ArrangeColumns handleClose={closeEditColumnsPopup} editColumnsList={variableTableHeaders} setEditColumnsList={setVariableTableHeaders}/>}
            
            <div className='statsV2FilterContainer'>
                {filtersList.map((filterItem,filterIndex)=>{
                    if(filterItem.show){
                        if(filterItem.label==="Date Range"){
                            return <div key={filterIndex} className='statsV2DateRangeContainer'>
                                        <div className='statsV2FilterLabel'>{filterItem.label}</div>
                                        <CssTextField size='small' value={filterItem.value} style={{transform:'scale(0.8) translateX(-13%)',width:'200px'}} onClick={()=>{setShowDatePicker(true)}}/>
                                        <div style={{position:'absolute',zIndex:2,transform:'scale(1)'}} onClick={(e)=>{e.stopPropagation()}}>
                                            {showDatePicker && <DateRangePicker
                                                onChange={item => dateRangePickedMethod(item)}
                                                showSelectionPreview={true}
                                                moveRangeOnFirstSelection={false}
                                                months={2}
                                                preventSnapRefocus={true}
                                                rangeColors={[localStorage.getItem('ambientColor')]}
                                                ranges={dateRangeState}
                                                showDateDisplay={false}
                                                scroll={{ enabled: window.innerWidth<800 ? true : false }}
                                                direction={window.innerWidth<800 ? "vertical" : "horizontal"}
                                                calendarFocus="backwards"
                                                />
                                            }
                                        </div>
                                    </div>
                        }
                        else if(filterItem.type==='checkbox'){
                            return <div key={filterIndex} >
                                <label htmlFor={filterItem.value+'_checkbox'}>{filterItem.label}</label>
                                <input id={filterItem.value+'_checkbox'} type='checkbox' onChange={(e)=>{filterItem.setMethod(e.target.checked)}}/>
                                </div>
                        }
                        else if(filterItem.label==='Offer ID' || filterItem.label==='Package Name' || filterItem.label==='External Offer Id' || filterItem.label === 'Goal ID' || filterItem.label === 'Smartlink ID'){
                            return <div key={filterIndex} className='statsV2DateRangeContainer'>
                                        <div className='statsV2FilterLabel'>{filterItem.label}{filterItem.mandatory ? "*" : ""}</div>
                                        <div style={{minWidth:'200px',transform:'scale(0.779) translateX(-14%) translateY(-1.5%)'}}>
                                            <CreatableSelect
                                                isClearable
                                                isMulti={filterItem.isSingle ? false : true}
                                                value={filterItem.value}
                                                onChange={(e)=>{filterItem.setMethod(e)}}
                                                menuPortalTarget={document.body} 
                                                styles={{...ReactSelectStyles}}
                                                />
                                        </div>
                                    </div>
                        }
                        else{
                            return <div key={filterIndex} className='statsV2DateRangeContainer'>
                                        <div className='statsV2FilterLabel'>{filterItem.label}</div>
                                        <div style={{minWidth:'200px',transform:'scale(0.779) translateX(-14%) translateY(-1.5%)'}}>               
                                            <ReactSelect 
                                                options={filterItem.options}
                                                value={filterItem.value}
                                                isMulti={filterItem.isSingle ? false : true}
                                                onChange={(e)=>{filterItem.setMethod(e);updateComponent(123);}}
                                                menuPortalTarget={document.body} 
                                                styles={{...ReactSelectStyles}}/>
                                        </div>
                                    </div>
                        }
                    }
                    return null;
                })}
            </div>

            {/* <div style={{marginLeft:'12px',marginTop:'10px',marginBottom:'2px',display:'flex',alignItems:'center'}}>
                <input id='offerSource' type='checkbox' checked={offerSourceSelected} onChange={(e)=>{setSearchAdvertiserList([]);setOfferSourceSelected(e.target.checked);}}/>
                <label htmlFor='offerSource' style={{fontSize:'12px'}}>Get Only Manual Offers</label>
                {process.env.REACT_APP_FEATURE_STATS_SHOW_CONVERSION_ONLY==='true' &&
                    <div style={{display:'flex',alignItems:'center',marginLeft:'20px'}}>
                        <input id={'showconversiononly_checkbox'} checked={showConversionOnly} type='checkbox' onChange={(e)=>{setShowConversionOnly(e.target.checked)}}/>
                        <label style={{fontSize:'12px'}} htmlFor={'showconversiononly_checkbox'}>{'Show Conversions Only'}</label>
                    </div>
                }
            </div> */}
            {((process.env.REACT_APP_STATS_V4_ENABLED==='true' && isTestingUser()) || process.env.REACT_APP_STATS_V4_ENABLED==='live') 
            &&
            <div style={{marginTop:'20px',fontSize:'12px',marginLeft:'20px',cursor:'pointer',width:'max-content'}} onClick={()=>{localStorage.setItem('statsV2',false);window.location.reload();}}>
                Switch To New Design
            </div>
            }
            <div className='statsV2ButtonContainer'>

                <div style={{display:"flex",width:'70%',alignItems:'center',justifyContent:'flex-start'}}>
                    
                    
                    {/* {statsType!=='goals' && statsType!== 'trafficback' && 
                        <Tooltip title="Use this for using new sort functions">
                            <div style={{fontSize:'12px',display:'flex',alignItems:'center',paddingRight:'15px'}}>
                                <input checked={useNewApi} onChange={(e)=>{setUseNewApi(e.target.checked);if(!e.target.checked){setSelectedCurrency(null);setSortingField({label:"Clicks",value:'clicks'})}}} style={{transform:'scale(0.8)'}} id='newApi' type='checkbox'/>
                                <label for='newApi'>Use New API</label>
                            </div>     
                        </Tooltip>
                    } */}
                    {statsType !== 'trafficback' && statsType !== 'goals' &&
                        <Tooltip title="Share Data">
                            <IconButton className='settingsButton' size={'small'} onClick={(e)=>{getStatsData(e,limit,page,true)}}>
                                {shareApiLoader && <CircularProgress sx={{color:localStorage.getItem('ambientColor')}} size={15}/>}
                                {!shareApiLoader && <ShareIcon sx={{fontSize:'17px'}}/>}
                            </IconButton>
                        </Tooltip>
                    }
                    {statsType !== 'trafficback'
                        &&
                        <Tooltip title="Export Data">
                            <IconButton className='settingsButton' size={'small'} onClick={()=>{setShowExportPopup(true)}}>
                                <FileDownloadIcon sx={{fontSize:'17px'}}/>
                            </IconButton>
                        </Tooltip>
                    }

                    <Tooltip title="Reset Table Settings">
                        <IconButton className='settingsButton' size={'small'} onClick={()=>{setVariableTableHeaders(varTableHeaders);localStorage.removeItem(statsType+'tableContents');}}>
                            <RestartAltIcon sx={{fontSize:'17px'}}/>
                        </IconButton>
                    </Tooltip>
                    
                    <Tooltip title="Table Settings">
                        <IconButton className='settingsButton' size={'small'} onClick={()=>{setEditColumnsPopup(true);}}>
                            <SettingsIcon sx={{fontSize:'17px'}}/>
                        </IconButton>
                    </Tooltip>
                    {shareApiData &&
                        <div style={{display:'flex',width:'200px',height:'25px',fontSize:'12px',border:'1px solid rgb(200,200,200)',alignItems:'center',paddingLeft:'5px'}}>
                            <div className='noscrollbar' style={{height:'25px',maxWidth:'90%',overflowX:'auto'}}>
                                <a href={'/shared/'+shareApiData} target='_blank' rel='noreferrer' style={{textDecoration:'none',color:'rgb(150,150,150)'}}>
                                    {window.location.host+'/shared/'+shareApiData}
                                </a>            
                            </div>
                            <IconButton aria-label="delete" onClick={handleCopyContent} onMouseLeave={()=>{setShowToolTip(false);}} >
                                <Tooltip  TransitionComponent={Zoom} title={showTooltip ? 'Copied' : 'Copy'}>
                                    <ContentCopyRoundedIcon sx={{fontSize:'12px'}}/>
                                </Tooltip>
                            </IconButton>
                        </div>
                    }
                </div>
                <div style={{display:"flex",width:'50%',justifyContent:'flex-end',paddingLeft:"2%",paddingRight:"2%"}}>     
                    <Button disabled={showLoader || (offerSourceSelected && searchAdvertiserList.length===0)} sx={{...Constants.CONTAINEDBUTTON,height:'30px',minWidth:'70px',fontSize:'12px',textTransform:'none'}} onClick={handleSearchButton}>
                        {showLoader && <CircularProgress size={'15px'} sx={{color:'white'}}/>}
                        {!showLoader && 'Search'}
                    </Button>
                    <Button sx={{...Constants.OUTLINEDBUTTON,marginLeft:'20px',height:'30px',fontSize:'12px',textTransform:'none'}} onClick={resetAllFields}>Reset</Button>
                </div>                
            </div>
            {
                
                (statsType!== 'trafficback') && 
                <div style={{height:'25px',paddingLeft:'2%',paddingRight:'2%',marginBottom:'10px',fontSize:'12px',display:'flex',alignItems:'center',transform:(useNewApi ? 'translateY(0%)' :'translateY(-100%)'),opacity:(useNewApi ? '1' :'0'),transition:'all 0.5s linear'}}>
                    <div style={{display:'flex',background:'rgb(255,255,255)',zIndex:'1'}}>Currency:</div>
                    <div  style={{display:'flex',alignItems:'center',transform:(useNewApi ? 'translateX(0%)' :'translateX(-100%)'),opacity:(useNewApi ? '1' :'0'),transition:'all 0.5s linear'}}>
                        {/* {listedCurrencies.splice(0,1).map((currencyItem,currencyIndex)=>{
                            return <div style={{display:'flex',cursor:'pointer',color:(selectedCurrency === currencyItem.value ? localStorage.getItem('ambientColor') : 'grey'),paddingLeft:'5px',paddingRight:'5px'}} onClick={()=>{setSelectedCurrency(currencyItem.value)}} key={currencyIndex}>
                                    {currencyItem.label}
                                </div>
                        })} */}
                        <select style={{border:'0px solid transparent',fontSize:'12px',}} onChange={(e)=>{setSelectedCurrency(e.target.value)}}>
                            {/* <option disabled selected value>-- Select Another Currency --</option> */}
                            {listedCurrencies.map((currencyItem,currencyIndex)=>{
                            return <option selected={selectedCurrency === currencyItem.value ? 'selected' : false} value={currencyItem.value} key={currencyIndex}>
                                    {currencyItem.label}
                                </option>
                            })}
                        </select>
                    </div>
                </div>
            }

            {/* //TODO remove statsType from marginTop */}
            <div style={{paddingLeft:'2%',paddingRight:'2%',marginTop:((statsType!=='goals' && statsType!== 'trafficback') ? useNewApi ? '0%' : '-35px' : '0%'),transition:'all 0.5s linear',marginBottom:'10px',fontSize:'12px',display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                <div style={{display:'flex',alignItems:'center'}}>
                    <div style={{background:'rgb(255,255,255)',zIndex:'1'}}>
                        {!showLoader && typeof response === 'object' && response.length>0 &&  "Showing "+offset+" - "+(offset+limit-1 < totalResults ? offset+limit-1 : totalResults)+" out of "+totalResults}
                    </div>
                </div>
                <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
                    {/* <div style={{cursor:'pointer'}} onClick={()=>{localStorage.setItem('oldStatsPage',true);window.location.reload();}}>
                        Switch to Old Design
                    </div> */}
                    {statsType!=='goals' && statsType!=='trafficback' && 
                        <div style={{display:'flex',alignItems:'center',transform:'scale(0.8) translateY(-8%) translateX(15%)'}}>
                            <div>
                                <label>Sort by:</label>
                                <div style={{width:'120px'}}>
                                    <ReactSelect 
                                        options={getSortingOptions()}
                                        value={sortingField}
                                        onChange={(e)=>{setSortingField(e)}}
                                        placeholder={'Sort By'}
                                        menuPortalTarget={document.body} 
                                        styles={{...ReactSelectStyles}}/>
                                </div>
                            </div>
                            <div className='sortingOrder' style={{transform:'scale(1.5) translateX(18%) translateY(10%)'}} onClick={()=>{setSortingOrderBy(!sortingOrderBy)}}>
                                {!sortingOrderBy ? <div>&darr;</div> : <div>&uarr;</div>}
                            </div>
                        </div>
                    }
                    {!['trafficback','goals'].includes(statsType) && 
                        <div style={{cursor:'pointer',display:'flex',alignItems:'center',marginLeft:'20px'}}>
                            <Tooltip sx={{zIndex:0}} style={{zIndex:0}} title={!graphMode ? "Showing Table View" : "Switch to Table View"}>
                                <IconButton onClick={()=>{setGraphMode(false);}}>
                                    <TableRowsIcon sx={{fontSize:'20px',color:(!graphMode ? localStorage.getItem('ambientColor') : 'rgb(200,200,200)')}}/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip sx={{zIndex:0}} style={{zIndex:0}} title={graphMode ? "Showing Graph View" : "Switch to Graph View"}>
                                <IconButton  onClick={()=>{setGraphMode(true);}}>
                                    <BarChartIcon sx={{fontSize:'20px',color:(graphMode ? localStorage.getItem('ambientColor') : 'rgb(200,200,200)')}}/>
                                </IconButton>
                            </Tooltip>
                        </div>
                    }
                </div>
            </div>
            {showLoader && 
                <ShowLoaderComponent/>
            }
            {
                showRedirectPopup
                &&
                <div style={{height:'100vh',width:'100vw',position:'fixed',top:0,left:0,background:'rgb(100,100,100,0.4)',backdropFilter:'blur(1px)',zIndex:999999,display:'flex',alignItems:'center',justifyContent:'center'}} onClick={(e)=>{e.stopPropagation();setShowRedirectPopup(false);}}>
                    <div style={{minHeight:"300px",minWidth:'600px',width:'60vw',background:'white',maxHeight:'80vh',maxWidth:'90vh'}}>
                        <div style={{padding:'10px',display:'flex',justifyContent:"space-between",height:'20px',borderBottom:'1px solid rgb(220,220,220)'}}>
                            <div style={{fontSize:'14px'}}>Redirections for Offer Id: {showRedirectPopup.key}</div>
                            <div style={{cursor:"pointer"}} onClick={(e)=>{e.stopPropagation();setShowRedirectPopup(false);}}>X</div>
                        </div>
                        <div style={{margin:'10px',overflow:'auto',maxHeight:'calc(80vh - 60px)',position:'relative'}} onClick={(e)=>{e.stopPropagation();}}>
                            <table style={{tableLayout:'fixed',width:"100%",borderSpacing:'0px'}}>
                                <thead>
                                    <tr style={{position:'sticky',top:0,background:'white'}}>
                                        <td style={{width:'15%',fontSize:'13px',fontWeight:'500',padding:'5px',border:'1px solid rgb(50,50,50)'}}>
                                            Country
                                        </td>
                                        <td style={{width:'85%',fontSize:'13px',fontWeight:'500',padding:'5px',border:'1px solid rgb(50,50,50)',borderLeft:'none'}}>
                                            Redirection
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.keys(showRedirectPopup.redirection_url).map((item,index)=>{
                                        return <tr key={index}>
                                            <td style={{width:'15%',fontSize:'13px',fontWeight:'300',padding:'5px',border:'1px solid rgb(50,50,50)',borderTop:'none'}}>{item}</td>
                                            <td  style={{width:'85%',fontSize:'13px',fontWeight:'300',padding:'5px',border:'1px solid rgb(50,50,50)',borderLeft:'none',borderTop:'none'}}>
                                                {showRedirectPopup.redirection_url[item].map((childItem,childIndex)=>{
                                                    return <div style={{display:'flex',alignItems:'center',marginTop:'5px',marginBottom:'5px'}} key={childIndex}>
                                                        <div style={{width:'70px'}}>{childItem[1]+" : "}</div>
                                                        <div style={{width:'calc(100% - 100px)',wordBreak:'break-all'}}>{childItem[0]}</div>
                                                    </div>
                                                })}
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }
            {
                !showLoader && 
                <>
                {typeof response === 'object' && response.length>0 && graphMode && <TableToGraph data={response} statsType={statsType}/>}

                {typeof response === 'object' && response.length>0 && !graphMode &&
                    <>
                        {/* TABLE HEADERS */}
                        <div className='statsV2TableHeader' style={{top:0}}>
                            <div style={{display:'flex',paddingRight:'0%',position:'sticky',left:0}}>           
                                {
                                    fixedTableHeaders.map((tableHeaderItem,tableHeaderIndex)=>{
                                        if(!tableHeaderItem.disable && tableHeaderItem.show){
                                            return <div className='statsV2TableHeaderData' style={{minWidth:tableHeaderItem.minWidth,width:tableHeaderItem.width}} key={tableHeaderIndex} onClick={(e)=>{setSorting(e,tableHeaderItem.value)}}>
                                                        <Resizable
                                                            id={tableHeaderItem.value}
                                                            style={{zIndex:(fixedTableHeaders.length-parseInt(tableHeaderIndex))}}
                                                            size={{width:"100%"}}
                                                            enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
                                                            onResizeStop={(e, direction, ref, d) => {
                                                                var tempTableHeaders = fixedTableHeaders;
                                                                if(tempTableHeaders[tableHeaderIndex].minWidth + d.width >= fixTableHeaders[tableHeaderIndex].minWidth){
                                                                    tempTableHeaders[tableHeaderIndex].minWidth += d.width
                                                                }
                                                                else{
                                                                    tempTableHeaders[tableHeaderIndex].minWidth = fixTableHeaders[tableHeaderIndex].minWidth
                                                                }
                                                                setFixedTableHeaders(tempTableHeaders)
                                                                updateComponent(e);
                                                                }}
                                                                >
                                                                <div style={{display:'flex',justifyContent:'center',textAlign:'center'}}>
                                                                    {tableHeaderItem.label}
                                                                </div>
                                                        </Resizable>
                                                    </div>
                                        }
                                        return null;
                                    })
                                }
                            </div>
                            <div id = "statsV2TableHeader" style={{paddingLeft:'0%',display:'flex',overflowX:'auto'}} onScroll={(e)=>{document.getElementById('statsV2TableValue').scrollLeft = e.target.scrollLeft;document.getElementById('statsV2TableTotalValue').scrollLeft = e.target.scrollLeft;}}>
                                {
                                    variableTableHeaders.map((tableHeaderItem,tableHeaderIndex)=>{
                                        if(!tableHeaderItem.disable  && tableHeaderItem.show){
                                            return <div className='statsV2TableHeaderData' style={{minWidth:tableHeaderItem.minWidth,width:tableHeaderItem.width}} key={tableHeaderIndex} onClick={(e)=>{setSorting(e,tableHeaderItem.value,true)}}>
                                                    <Resizable
                                                        id={tableHeaderItem.value}
                                                        style={{zIndex:(variableTableHeaders.length-parseInt(tableHeaderIndex))}}
                                                        size={{width:"95%"}}
                                                        enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
                                                        onResizeStop={(e, direction, ref, d) => {
                                                            var tempTableHeaders = variableTableHeaders;
                                                            if(tempTableHeaders[tableHeaderIndex].minWidth + d.width >= varTableHeaders[tableHeaderIndex].minWidth){
                                                                tempTableHeaders[tableHeaderIndex].minWidth += d.width
                                                            }
                                                            setVariableTableHeaders(tempTableHeaders)
                                                            updateComponent(e);
                                                            }}
                                                            >
                                                            <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',textAlign:'center'}}>
                                                                {tableHeaderItem.label}
                                                            </div>
                                                    </Resizable>
                                                </div>
                                        }
                                        return null;
                                    })
                                }
                            </div>
                        </div>

                        {/* TABLE VALUES */}
                            
                        <div className='statsV2TableValue'>
                            <div>
                                {   
                                    Object.keys(response).map((keyItem,keyIndex)=>{
                                        var height = 30
                                        if(response[keyItem].payouts && response[keyItem].payouts.length>1){
                                            height = 30 + (response[keyItem].payouts.length*10)
                                        }
                                        return <div id={'fixedColums'+keyIndex} style={{display:'flex',height}} key={keyIndex}>           
                                            {
                                                fixedTableHeaders.map((tableHeaderItem,tableHeaderIndex)=>{
                                                    if(!tableHeaderItem.disable && tableHeaderItem.show){
                                                        if(tableHeaderItem.value==='key'){        
                                                            return <div className='statsV2TableValueData' style={{minWidth:tableHeaderItem.minWidth,width:tableHeaderItem.width,maxWidth:tableHeaderItem.width}} key={tableHeaderIndex}>
                                                                        {response[keyItem][tableHeaderItem.value]}
                                                                    </div>
                                                        }
                                                        if(tableHeaderItem.link){
                                                            return <div className='statsV2TableValueData' style={{justifyContent:'flex-start',minWidth:tableHeaderItem.minWidth,width:tableHeaderItem.width}} key={tableHeaderIndex}>
                                                                        <div className='overflowText' style={{paddingLeft:'3%',paddingRight:'3%'}}>
                                                                            <a className='statsV2AnchorLink' href={tableHeaderItem.link+response[keyItem]['key']} target='_blank' rel='noreferrer'>
                                                                                {response[keyItem][tableHeaderItem.value]}
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                        }
                                                        return <div className='statsV2TableValueData' style={{justifyContent:'flex-start',minWidth:tableHeaderItem.minWidth,width:tableHeaderItem.width}} key={tableHeaderIndex}>
                                                            <div className='overflowText' style={{paddingLeft:'3%',paddingRight:'3%'}}>
                                                                {response[keyItem][tableHeaderItem.value]}
                                                            </div>
                                                        </div>
                                                    }
                                                    return null;
                                                })
                                            }
                                        </div>
                                    })   
                                }
                            </div>
                            <div id='statsV2TableValue' className='noscrollbar' style={{display:'flex',flexDirection:'column',overflow:'auto'}} onScroll={(e)=>{document.getElementById('statsV2TableHeader').scrollLeft = e.target.scrollLeft;document.getElementById('statsV2TableTotalValue').scrollLeft = e.target.scrollLeft;}}>
                                {showValues()}
                            </div>
                        </div>
                    
                        {/* TABLE TOTAL VALUES */}
                        <div className='statsV2TableTotalValueStyles'>
                            <div style={{display:'flex',paddingRight:'0%',position:'sticky',left:0}}>           
                                {
                                    fixedTableHeaders.map((tableHeaderItem,tableHeaderIndex)=>{
                                        if(!tableHeaderItem.disable && tableHeaderItem.show){
                                            return <div className='statsV2TableTotalValueData' style={{minWidth:tableHeaderItem.minWidth,width:tableHeaderItem.width}} key={tableHeaderIndex}>
                                                        <div style={{display:'flex',justifyContent:'center',fontSize:'14px'}}>
                                                            {tableHeaderIndex===0 ? 'Total' : ''}
                                                        </div>
                                                    </div>
                                        }
                                        return null;
                                    })
                                }
                            </div>
                            <div id = "statsV2TableTotalValue" style={{paddingLeft:'0%',display:'flex',overflowX:'auto'}} onScroll={(e)=>{document.getElementById('statsV2TableValue').scrollLeft = e.target.scrollLeft;document.getElementById('statsV2TableHeader').scrollLeft = e.target.scrollLeft;}}>
                                {
                                    variableTableHeaders.map((tableHeaderItem,tableHeaderIndex)=>{
                                        if(!tableHeaderItem.disable && tableHeaderItem.show){
                                            return <div className='statsV2TableTotalValueData' style={{minWidth:tableHeaderItem.minWidth,width:tableHeaderItem.width}} key={tableHeaderIndex}>
                                                    {totalValue[tableHeaderItem.value] || totalValue[tableHeaderItem.value]===0 ? 
                                                    <div>
                                                        
                                                        {typeof totalValue[tableHeaderItem.value] === 'object' && Object.keys(totalValue[tableHeaderItem.value]).map((currencyItem,currencyIndex)=>{
                                                            var currencySymbol = ""
                                                            if(CURRENCY_LIST_SYMBOLS[currencyItem.toUpperCase()]){
                                                                currencySymbol = CURRENCY_LIST_SYMBOLS[currencyItem.toUpperCase()]
                                                            }
                                                            else{
                                                                currencySymbol = currencyItem.toUpperCase()
                                                            }
                                                            return <div>
                                                                {tableHeaderItem.value==="approved_revenue" && 
                                                                (totalValue[tableHeaderItem.value][currencyItem]/2).toFixed(2) + " " + currencySymbol
                                                                }
                                                                { tableHeaderItem.value!=="approved_revenue" &&
                                                                totalValue[tableHeaderItem.value][currencyItem].toFixed(2) + " " + currencySymbol}
                                                            </div>
                                                        })}
                                                        {typeof totalValue[tableHeaderItem.value] !== 'object' &&
                                                            <div>
                                                                {totalValue[tableHeaderItem.value]}
                                                            </div>
                                                        }
                                                    </div>
                                                    :
                                                    ""
                                                    }
                                                </div>
                                        }
                                        return null;
                                    })
                                }
                            </div>
                        </div>

                        {/* PAGINATION */}
                    </>
                }
                {typeof response === 'object' && response.length>0 && 
                    <div style={{display:'flex',alignItems:'center',justifyContent:"space-between",paddingLeft:'20px',paddingRight:'20px',paddingBottom:'20px',position:'sticky',bottom:0,background:'white'}}>
                        <div>
                            <label style={{paddingRight:"5px",fontSize:'14px'}}>Limit</label>
                            <select value={limit} style={{outline:'none',border:'none'}} onChange={(e)=>{getStatsData(e,e.target.value,1);setLimit(parseInt(e.target.value));setPage(1);}}>
                                {[5,10,50,100,200,300,500].map((item,index)=>{
                                    return <option key={index} value={item}>{item}</option>
                                })}
                                {/* {statsType!=='offer' && [25,50,75,100].map((item,index)=>{
                                    return <option key={index} value={item}>{item}</option>
                                })} */}
                            </select>
                        </div>
                        <Pagination count={Math.ceil(totalResults/limit)} page={page} onChange={(event,value)=>{getStatsData(event,limit,value);setPage(value);updateComponent(event);window.scrollTo({top: 0,left: 0,behavior: 'smooth'});}} />
                    </div>
                }
                {typeof response === 'object' && 
                    response.length===0 &&
                    response !== statsType
                    &&
                    <NoResultsFound/>
                }
                {
                    typeof response === 'string' && response !== statsType &&
                    <SomeErrorOccured error={response}/>
                }
                </>
            }
            <br/>
        </div>
    )
}

export default StatisticsAll
