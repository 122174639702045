import React, { useState } from "react";
import "../../../css/pages/demandsource.css";


import Button from '@mui/material/Button';
import * as Constants from '../../../data/constants';

import NativeSelect from '@mui/material/NativeSelect';
import Pagination from '@mui/material/Pagination';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';


import axios from 'axios';
import { getLocalStorageValue } from "../../../util/util";

const ShowFetchOffersPopup = props => {

    const [manualUseState, setManualUseState] = React.useState(false);
    const [showLoader, setShowLoader] = React.useState(true);
    var [demandSourceId, setDemandSourceId] = React.useState(false);
    
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(25);
    const [offset, setOffset] = React.useState(0);
    const [totalOffers, setTotalOffers] = React.useState(0);
    
    const [offersFetchedList, setOffersFetchedList] = React.useState([]);
    
    const [searchOfferId,setSearchedOfferId] = React.useState("")
    const [searchTitle,setSearchedTitle] = React.useState("")
    const [selectAllOffers,setSelectAllOffers] = React.useState(false)
    const [selectedOffers,setSelectedOffers] = React.useState([])

    const [showResponsePopup, setShowResponsePopup] = React.useState(false);
    const [responsePopupList, setResponsePopupList] = React.useState([]);
    const [publishedOfferButtonEnabled, setPublishedOfferButtonEnabled] = React.useState(false);
    const [disableRequestButton, setDisableRequestButton] = React.useState(false);
    const [isPublishedFilter, setIsPublishedFilter] = useState('0')
    const [isImportedFilter, setIsImportedFilter] = useState('1')

    const handlePageChange = (event, value) => {
        setPage(value);
        setOffset((value-1)*limit);
        getOffersList(value,limit);
        setSelectAllOffers(false);
        setSelectedOffers([]);
    };
    
    const handleLimitChange = (e) =>{
        setLimit(parseInt(e.target.value));
        setPage(1);
        getOffersList(1,e.target.value);
    };

    const getOffersList = async(manual_page=false,manual_limit=false,tempIsPublished) => {
        if (!demandSourceId){
                
            const url = new URL(
                window.location.href
                );
            demandSourceId = url.searchParams.get('id')
            setDemandSourceId(demandSourceId)
        }
        setShowLoader(true)
        if(process.env.REACT_APP_OFFER_FETCH_API_V2==='live'){
            var url =  "".concat(Constants.PROTOCOL,Constants.HOST,"/api/admin/v2/","offersfetched/view/",demandSourceId)
        }
        else{
            url =  "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"offersfetched/view/",demandSourceId)
        }
        await axios({
            method: 'GET',
            url,
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params:{
                limit:manual_limit ? manual_limit : limit,
                page:manual_page ? manual_page : page,
                offer_id : searchOfferId ? searchOfferId : null,
                offer_title: searchTitle ? searchTitle : null,
                published:(isPublishedFilter || isPublishedFilter===0) && isPublishedFilter!=='all' ? isPublishedFilter : undefined,
                fetched: (isImportedFilter || isImportedFilter===0) && isImportedFilter!=='all' ? isImportedFilter : undefined,
            }
        }
        )
        .then((resp) => {
            var responseResult = resp.data.result
            setOffersFetchedList(responseResult)
            if (responseResult.length===0){
                setOffersFetchedList("No Offers found.")
            }
            if (searchOfferId){
                setTotalOffers(1)
            }
            else{
                setTotalOffers(resp.data.count)
            }
            setShowLoader(false)
        })
        .catch(function (error) {
            setShowLoader(false)
            if (error.response) {
              // Request made and server responded
              setOffersFetchedList(error.response.data.result);
              setShowLoader(false);
              setTotalOffers(1)
              console.log(error.response.status);
              console.log(error.response.headers);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
        
          });
    };
    if (!manualUseState){
        getOffersList();
        setManualUseState(true);
    }

    const offerFetchRequest = async(offer_list=false) => {
        setDisableRequestButton(true)
        if (!offer_list){
            offer_list = selectedOffers
        }
        var responsePopupListVariable = responsePopupList
        offer_list.forEach(
            (async(item,index)=>{
            await axios({
                method: 'POST',
                url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"offersfetched/migrate"),
                headers:{
                    Authorization:getLocalStorageValue('adminPanelLoginData').token
                },
                data:{
                    "action" : props.offerAction,
                    "offer_fetched_id" : offer_list[index].id,
                }
            }
            )
            .then(function(resp){
                setShowLoader(false)
                if (resp.data.result && item){
                    responsePopupListVariable.push({offer_id:offer_list[index].offer_id,resp:resp.data.result})
                }
                setResponsePopupList(responsePopupListVariable)
                responsePopupListVariable.push("Done")
                setResponsePopupList(responsePopupListVariable)
            })
            .catch(function (error) {

                if (error.response) {
                    responsePopupListVariable.push({offer_id:offer_list[index].offer_id,resp:error.response.data.result})
                    setResponsePopupList(responsePopupListVariable)
                    // Request made and server responded
                    // setResponsePopup(error.response.data.result)
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                    }
                
            })
            if (parseInt(index)===(offer_list.length-1)){
                setShowResponsePopup(true);
                setDisableRequestButton(false);
                setSelectedOffers([]);
            }
        }))
        setSelectedOffers([])
        setSelectAllOffers(false)
    };
    
    const offerChecked = (offer_dict) => {
        var tempSelectedOffersList = selectedOffers

        if (document.getElementById("offerCheckBox"+offer_dict.id.toString()+"offerid"+offer_dict.offer_id).checked){
            document.getElementById("offerCheckBox"+offer_dict.id.toString()+"offerid"+offer_dict.offer_id).checked = true;
            tempSelectedOffersList.push(offer_dict)
            setPublishedOfferButtonEnabled(true)
            setSelectedOffers(tempSelectedOffersList)
        }
        else{
            document.getElementById("selectAllOffersCheckBox").checked = false;
            document.getElementById("offerCheckBox"+offer_dict.id.toString()+"offerid"+offer_dict.offer_id).checked = false;
            setSelectAllOffers(false)
            for (var item in tempSelectedOffersList){
                if (tempSelectedOffersList[item].id===offer_dict.id){
                    tempSelectedOffersList.splice(item, item+1);
                }
            }
            if (tempSelectedOffersList.length===0){
                setPublishedOfferButtonEnabled(false)
            }
            setSelectedOffers(tempSelectedOffersList)
        }
    }

    const offerCheckedAll = (value) => {
        setSelectAllOffers(value)
        const elements = document.querySelectorAll(`[id^="offerCheckBox"]`);
        for (var item in elements){
            if (elements[item].localName==="input"){
                if (!value){
                    elements[item].checked = false
                    setSelectedOffers([])
                }
                else{
                    elements[item].checked = true

                    var tempSelectedOffersList = selectedOffers
                    console.log()
                    tempSelectedOffersList.push({id:elements[item].id.split("offerCheckBox")[1].split("offerid")[0],offer_id:elements[item].id.split("offerid")[1]})
                    setSelectedOffers(tempSelectedOffersList)
                }
            }
        }
    }
    
    const firstColumn = "5%"
    const secondColumn = "13%"
    const thirdColumn = "30%"
    const fourthColumn = "18%"
    const fifthColumn = "7%"
    const sixColumn = "10%"
    const seventhColumn = "13%"

    const showOffers = () => {
        if (typeof offersFetchedList==="string" ){
            return <div>
                <div style={{fontSize:"13px",textAlign:"center"}}>{offersFetchedList}</div> 
            </div>
        }
        return offersFetchedList.map((item,index)=>{
            return <div key={index} style={index%2===0 ? {display:'flex',width:'100%',background:"rgb(235, 235, 235)",minHeight:"30px"} : {display:'flex',width:'100%',background:"white",minHeight:"28px"}}>
                    <div style={{minWidth:firstColumn,maxWidth:firstColumn,display:'flex',justifyContent:'center',alignItems:'center'}}>                            
                        <input type="checkbox" id={!item.is_published ? "offerCheckBox"+item.id+"offerid"+item.offer_id : ""} defaultChecked={item.is_published || selectAllOffers ? true : false } onChange={()=>{offerChecked({id:item.id,offer_id:item.offer_id})}} disabled={item.is_published ? true : false}/>
                    </div>
                    <div style={{minWidth:secondColumn,maxWidth:secondColumn,fontSize:"13px",display:'flex',justifyContent:'flex-start',alignItems:'center',wordBreak:'break-all'}}>{item.offer_id}</div>
                    <div style={{minWidth:thirdColumn,maxWidth:thirdColumn,fontSize:"13px",paddingLeft:"1%",display:'flex',justifyContent:'flex-start',alignItems:'center'}}>{item.title}</div>
                    <div style={{minWidth:fourthColumn,maxWidth:fourthColumn,wordBreak:'break-all',wordWrap:'break-word',fontSize:"13px",display:'flex',alignItems:'center',marginRight:'2%'}}>
                        {item.geo && 
                            item.geo.map((geoitem,geoindex)=>{
                                if (geoitem.includes(",")){
                                    
                                    return geoitem.split(",").map((singleGeoItem,singleGeoIndex)=>{
                                        if (geoitem.split(",").length!==singleGeoIndex+1){
                                            return singleGeoItem+","
                                        }
                                        else{
                                            return singleGeoItem
                                        }
                                    })
                                }
                                else{
                                    if (item.geo.length!==geoindex+1){
                                        return geoitem+","
                                    }
                                    else{
                                        return geoitem
                                    }
                                }
                            })
                        }
                    </div>
                    <div style={{minWidth:fifthColumn,maxWidth:fifthColumn,fontSize:"13px",display:'flex',alignItems:'center'}}>{item.payout}</div>
                    <div style={{minWidth:sixColumn,maxWidth:sixColumn,fontSize:"13px",display:'flex',justifyContent:'center',textAlign:'center',alignItems:'center'}}>
                        <div style={{color:(item.fetched ? 'green' : 'red'),fontWeight:600}}>{(item.fetched ? 'Yes' : 'No')}</div>                    
                    </div>
                    <div style={{minWidth:seventhColumn,maxWidth:seventhColumn,fontSize:"13px",display:'flex',justifyContent:'center',textAlign:'center',alignItems:'center'}}>
                        <div className={(item.is_published || disableRequestButton) ? "offerFetched" : "offerNotFetched"} onClick={()=>{if(!disableRequestButton){offerFetchRequest([{id:item.id,offer_id:item.offer_id}])}}}> + </div>                      
                    </div>
                </div>
        })
    }

    const handleOfferIdSearched = (published) => {
        getOffersList(1,limit)
    }
    
    const handlePressEnter = (event) => {
        if (event.key === 'Enter') {
            setPage(1)
            setOffset(0)
            getOffersList(1,limit)
          }
    }

    const showResponseOffersMoved = () => {

        return responsePopupList.map((item,index)=>{
            if (item!=="Done"){
                return <div key={index}>
                        <label style={{fontSize:"11px"}}>{item.offer_id} : {item.resp ? JSON.stringify(item.resp) : "some error occured"}</label>
                        <br/>
                    </div>
            }
            return null;
        })
    }
  return (

        <div className="popup-box">
            <div className="box-offers">
                <div className="popup-offer-header">
                    Offers
                </div>      
                <div className="popup-offer-results">
                    <div>Showing <b>{totalOffers!==0 ? offset+1 : totalOffers}-{page*limit<=totalOffers ? page*limit : totalOffers}</b> of <b>{totalOffers}</b> items.</div>
                    
                    <div style={{display:"flex",alignItems:"center",textAlign:"center",justifyContent:"flex-end",transform:"scale(0.8)"}}>
                        <label style={{paddingLeft:"20px",paddingRight:"20px"}}>LIMIT</label>
                                <NativeSelect
                                    defaultValue={5}
                                    inputProps={{
                                        name: 'LIMIT',
                                        id: 'uncontrolled-native',
                                    }}
                                    color="warning"
                                    onChange={handleLimitChange}
                                    >
                                    <option>25</option>
                                    <option>50</option>
                                    <option>75</option>
                                    <option>100</option>
                                </NativeSelect>
                    </div>  
                </div>   
                <div style={{height:"82%",backgroundColor:"white"}}>     
                    <>
                        <div style={{paddingLeft:"2%",paddingRight:"2%",paddingTop:"1%"}}>
                            <div style={{display:'flex',width:'100%'}}>
                                <div style={{minWidth:firstColumn,maxWidth:firstColumn,display:'flex',justifyContent:'center',fontSize:'13px',fontWeight:'500'}}>
                                    <input id="selectAllOffersCheckBox" type="checkbox" checked={selectAllOffers} onChange={()=>offerCheckedAll(!selectAllOffers)}/>
                                </div>
                                <div style={{minWidth:secondColumn,maxWidth:secondColumn,display:'flex',justifyContent:'center',alignItems:'center',fontSize:'13px',fontWeight:'500'}}>
                                    Offer ID
                                </div>
                                <div style={{minWidth:thirdColumn,maxWidth:thirdColumn,display:'flex',justifyContent:'center',alignItems:'center',fontSize:'13px',fontWeight:'500'}}>
                                    Title
                                </div>
                                <div style={{minWidth:fourthColumn,maxWidth:fourthColumn,display:'flex',alignItems:'center',fontSize:'13px',fontWeight:'500',paddingLeft:'2%'}}>
                                    Geo
                                </div>
                                <div style={{minWidth:fifthColumn,maxWidth:fifthColumn,display:'flex',alignItems:'center',fontSize:'13px',fontWeight:'500'}}>
                                    Payout
                                </div>
                                <div style={{minWidth:sixColumn,maxWidth:sixColumn,display:'flex',alignItems:'center',justifyContent:'center',fontSize:'13px',fontWeight:'500'}}>
                                    Availability
                                </div>
                                <div style={{minWidth:seventhColumn,maxWidth:seventhColumn,display:'flex',justifyContent:'center',fontSize:'13px',fontWeight:'500'}}>
                                    <div style={{display:"flex",width:"100%",justifyContent:"center",marginLeft:"5%"}}>
                                        <Button variant="outlined" color="warning" size="small" onClick={handleOfferIdSearched} style={{transform:"scale(0.7)"}}>Search</Button>
                                        <Button variant="outlined" color="warning" size="small" disabled={disableRequestButton || (selectAllOffers || publishedOfferButtonEnabled || selectedOffers.length>0 ? false : true)} onClick={()=>{offerFetchRequest()}} style={{transform:"scale(0.7)",minWidth:'80px'}}>
                                            {disableRequestButton && <CircularProgress size={'18px'} sx={{color:'orange'}}/>}
                                            {!disableRequestButton && 'Publish'}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div style={{display:'flex',width:'100%',borderBottom:"1px solid grey",minHeight:'1vh'}}>
                            </div>
                            <div style={{display:'flex',width:'100%',marginTop:'1vh'}}>
                                <div style={{minWidth:firstColumn,maxWidth:firstColumn,display:'flex',justifyContent:'center'}}>

                                </div>
                                <div style={{minWidth:secondColumn,maxWidth:secondColumn,display:'flex',justifyContent:'center'}}>
                                    <input type="text" name="name" value={searchOfferId} style={{height:"20px",width:"90%"}} onChange={(e)=>{setSearchedOfferId(e.target.value)}} onKeyDown={handlePressEnter}/>
                                </div>
                                <div style={{minWidth:thirdColumn,maxWidth:thirdColumn,display:'flex',justifyContent:'center'}}>
                                    {/* {false && setSearchedTitle('')} */}
                                    <input type="text" value={searchTitle} name="name" style={{height:"20px",width:"90%"}} onChange={(e)=>{setSearchedTitle(e.target.value)}} onKeyDown={handlePressEnter}/>
                                    
                                </div>
                                <div style={{minWidth:fourthColumn,maxWidth:fourthColumn,display:'flex',justifyContent:'center'}}>
                                    
                                </div>
                                <div style={{minWidth:fifthColumn,maxWidth:fifthColumn,display:'flex',justifyContent:'center'}}>
                                    
                                </div>
                                <div style={{minWidth:sixColumn,maxWidth:sixColumn,display:'flex',justifyContent:'center',marginLeft:'2%'}}>
                                    <FormControl sx={{transform:'scale(0.8)'}} fullWidth>
                                        <InputLabel id="demo-simple-select-label">Availability</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={isImportedFilter}
                                            label="Availability"
                                            size="small"
                                            onChange={(e)=>{setIsImportedFilter(e.target.value)}}
                                            MenuProps={{
                                                style: {zIndex: 35001}
                                            }}
                                        >
                                            <MenuItem value={'all'}>All</MenuItem>
                                            <MenuItem value={"1"}>Yes</MenuItem>
                                            <MenuItem value={"0"}>No</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div style={{minWidth:seventhColumn,maxWidth:seventhColumn,display:'flex',justifyContent:'center'}}>
                                    <FormControl sx={{transform:'scale(0.8)'}} fullWidth>
                                        <InputLabel id="demo-simple-select-label">Published</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={isPublishedFilter}
                                            label="Availability"
                                            size="small"
                                            onChange={(e)=>{setIsPublishedFilter(e.target.value);}}
                                            MenuProps={{
                                                style: {zIndex: 35001}
                                            }}
                                        >
                                            {/* <MenuItem value={'all'}>All</MenuItem> */}
                                            <MenuItem value={"1"}>Yes</MenuItem>
                                            <MenuItem value={"0"}>No</MenuItem>
                                        </Select>
                                    </FormControl>

                                </div>
                            </div>
                        </div>
                        {!showLoader &&
                            <>
                                <div style={{paddingLeft:"2%",paddingRight:"2%",paddingTop:"1%",overflowY:"auto",height:"auto",maxHeight:"78%"}}>
                                    <table style={{width:'100%'}}>
                                        <tbody>
                                            {showOffers()}
                                        </tbody>
                                    </table>
                                </div>

                                <div style={{display:"flex",alignItems:"center",textAlign:"center",justifyContent:"center",transform:"scale(0.8)"}}>
                                    <Pagination count={Math.ceil(totalOffers/limit)} page={page} onChange={handlePageChange} />
                                </div>
                            </>
                        }
                    </>
                    {showLoader && 
                        <div className="loaderWrapper" style={{height:'50vh'}}>  
                            <div className="loader">  
                                <CircularProgress color="warning"/> 
                            </div>
                        </div>
                    }        
                </div>
                {showResponsePopup && 
                    <div className="popup-box">
                        <div className="box-response" style={{width:"50%"}}>
                        <div className="popupHeader" >{"RESULT"}</div>
                        <div style={{borderBottom:"2px solid orange"}}></div>
                            <div className="popupData" style={{paddingTop:"2%"}}>
                                {responsePopupList.length>0 
                                    ? 
                                    showResponseOffersMoved()
                                    : 
                                "Some error occured."}
                            </div>
                        <div className="close-icon-response" onClick={()=>{setShowResponsePopup(false);setResponsePopupList([]);setSelectAllOffers(false);setSelectedOffers([]);getOffersList();}}>X</div>
                        </div>
                    </div>
                }
            <div className="close-icon-offers" onClick={props.handleClose}>X</div>
            </div>
        </div>
  );
};
 
export default ShowFetchOffersPopup;