import { Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import React, { useEffect ,useState} from 'react';
import CustomSelect from "./CustomSelect";
import table from '../assets/table.svg';
import Slider from './Slider'
import PercentageDiv from './Percentagediv';
import dough from '../assets/dough.svg';
import '../insightsCss.css'
import Select, {components} from 'react-select'
import arrowdown from '../assets/arrow-down.svg'
import axios from 'axios';
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import YearlySynopsis from "./YearlySynopsis";
import MonthlySynopsis2 from "./MonthlySynopsis2";
import CustomTable from "./Addtable.jsx";
import resetImg from "../assets/reset2.svg";
import resetMain from "../assets/resetMain.svg";
import { getLocalStorageValue } from '../util/util';
import resetNew from "../assets/resetNew.svg";
import inactiveReset from "../assets/inactiveReset.svg";
import back from "../assets/back.svg";
import * as Constants from '../../../../data/constants';
import { AdvertiserIcon,Cross,TeamIcon,AppIcon,SpiralIcon,AdvertiserManagerIcon,MoneyIcon} from '../../../../data/icons';
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend
);
function useForceUpdate() {
  const [value, setValue] = useState(0); 
  if (false) {
    console.log(value);
  }
  return () => setValue((value) => value + 1); 
}
function TeamWiseAnatomy({
  initdate,
  finaldate,
  advertiser,
  packageName,
  manager,
  selectedRev,
  advData,
  advertiserString,
  packageString,
  managerString,
  appPackageMap,
  setAdvertiser,
  setPackageName,
  setManager,
  setSelectedRev,
  setFilterData,
  filterData
}) {
  const [tableHead, setTableHead] = useState("Approved Revenue");
  const [sorHead, setSorHead] = useState("");
  const refreshComponent = useForceUpdate();
  const [reset, setReset] = useState(true);
  const [initial,setInitial]=useState(true);
  const [monthly,setMonthly]=useState(false);
  const [advapp,setAdvapp]=useState("");
  const [advappmonth,setAdvappmonth]=useState("");
  function getMonthAbbreviationFromDate(dateString) {
    const date = new Date(dateString + "-01");
    const options = { month: "short",year:"2-digit" };
    const monthAbbreviation = date.toLocaleDateString("en-US", options);
    return monthAbbreviation;
  }

  const begMonth = getMonthAbbreviationFromDate(initdate);
  const endMonth = getMonthAbbreviationFromDate(finaldate);
  const API_ENDPOINT = "".concat(Constants.PROTOCOL,Constants.HOST,"/api/admin/v2/invoice/summary");
  const token = getLocalStorageValue("adminPanelLoginData").token;
  const [tableData, setTableData] = useState({
    panel_revenue: [],
    manual_revenue: [],
  });
  const resetTable = () => {
    setReset(!reset);
    setTableHead("");
    console.log(reset);
  };

  const [team, setTeam] = useState("");
  useEffect(() => {
    ["Team 1", "Team 2", "Team 3"].map((teamName, index) => {
      console.log("don", packageName);
      const params = {
        team: teamName.replace(" ", ""),
        fromMonth: initdate,
        toMonth: finaldate,
        page: 1,
        total_row_required: true,
        group_by: "month",
        sorting_key: "conversions_verified",
        sorting_order: false,
      };
      if (advertiser != "" && packageName != "") {
        params.advertiser_id = advertiserString;
        params.package_name = packageString;
        console.log("aka", "yoho");
      } else if (advertiser != "") {
        params.advertiser_id = advertiserString;
      } else {
        params.package_name = packageString;
      }
      if (manager != "") {
        params.manager_id = managerString;
      }
      console.log("manteam",params);
      axios
        .get(API_ENDPOINT, {
          params: params,
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((response) => {
          const data = response.data.total_row;
          tableData.panel_revenue[index] = [
            teamName,
            data.total_revenue,
            data.manager_approved_revenue,
            data.validation_percentage,
            data.deduction_revenue,
            data.pending_revenue,
          ];

          tableData.manual_revenue[index] = [
            teamName,
            data.conversions_verified,
            data.manager_approved_revenue,
            data.validation_percentage_manual,
            data.deduction_revenue_manual,
            data.pending_revenue_manual,
          ];
          setTableData(tableData);
          refreshComponent(123);
        })
        .catch((error) => {
          setTableData({
            panel_revenue: [],
            manual_revenue: [],
          })
          console.error("Error fetching data:", error);
        });
    });
  }, [advertiser, packageName, initdate, finaldate, manager,reset]);

  const selectedData =
    selectedRev === "Panel Revenue" && tableData
      ? (() => tableData.panel_revenue)()
      : (() => tableData.manual_revenue)();

  const customStyles = {
    container: (base) => ({
      ...base,

      flexGrow: "1",
      margin: "0 1px",
      height: "40px",
      position: "relative",
    }),
    control: (base) => ({
      ...base,

      justifyContent: "center",
      alignItems: "center",
      border: 0,
      boxShadow: "none",
      backgroundColor: "transparent",
    }),
    valueContainer: (base) => ({
      ...base,
      flex: "unset",
      height: "60px",
      fontSize: "1.1rem",
      maxHeight: "2rem",
    }),
    menu: (base) => ({
      ...base,
      position: "absolute",
      width: "15rem",
    }),
    placeholder: (base) => ({
      ...base,
      color: "#252525",
    }),
    option: (styles, { isFocused, isSelected }) => {
      return {
        ...styles,
        color: "#252525",
        backgroundColor: isSelected
          ? "#E0F1FB"
          : isFocused
          ? "#E0F1FB"
          : undefined,
        textAlign: "left",
        fontSize: "1rem",
      };
    },
  };

  const IndicatorsContainer = (props) => {
    return <img className="icon1" src={arrowdown} alt="indicator" />;
  };
  const { ValueContainer, Placeholder } = components;

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };
  const CustomSingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>{null}</components.SingleValue>
  );
  const CustomOption = (placeholder) => {
    return (props) => {
      if (placeholder === tableHead) {
        return (
          <components.Option {...props} style={{ textAlign: "left" }}>
            <div style={{ textAlign: "left" }}>
              <input type="radio" defaultChecked={sorHead === props.label} />
              <label>{props.label}</label>
            </div>
          </components.Option>
        );
      } else {
        return (
          <components.Option {...props} style={{ textAlign: "left" }}>
            <div style={{ textAlign: "left" }}>
              <input type="radio" defaultChecked={false} />
              <label>{props.label}</label>
            </div>
          </components.Option>
        );
      }
    };
  };
  const options2 = [
    { value: "ascending", label: "Sort in Ascending order" },
    { value: "descending", label: "Sort in Descending order" },
  ];
  function CustomSelect1(props) {
    const handleChange = (option) => {
      const columnIndex = columnHeadings.indexOf(props.placeholder);
      setTableHead(props.placeholder);
      if (option.value === "ascending") setSorHead("Sort in Ascending order");
      else setSorHead("Sort in Descending order");
      const sortOrder = option.value === "ascending" ? 1 : -1;
      setTableData((tableData) => {
        tableData.manual_revenue.sort(
          (a, b) => sortOrder * (a[columnIndex] - b[columnIndex])
        );
        tableData.panel_revenue.sort(
          (a, b) => sortOrder * (a[columnIndex] - b[columnIndex])
        );
        return tableData;
      });
      refreshComponent(123);
    };

    return (
      <Select
        onChange={handleChange}
        img={props.img}
        placeholder={props.placeholder}
        options={options2}
        isClearable={true}
        components={{
          IndicatorsContainer: IndicatorsContainer,
          ValueContainer: CustomValueContainer,
          Option: CustomOption(props.placeholder),
          SingleValue: CustomSingleValue,
        }}
        className="react-select-container"
        classNamePrefix="react-select"
        styles={customStyles}
        isSearchable={false}
        closeMenuOnSelect={false}
      />
    );
  }

  const [message, setMessage] = useState("graph");

  const func = () => {
    setMessage("graph");
  };

  const func2 = () => {
    setMessage("table");
  };
  const [open, setOpen] = useState(false);
  const handleOpen = (x) => {
    return () => {
      setOpen(true);
      x = x.replace(" ", "");
      setTeam(x);
    };
  };

  const handleClose = () => {setOpen(false);
    setInitial(true);
  setMonthly(false)};
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "80%",
    bgcolor: "background.paper",
    // border: "2px solid #000",
    overflowY: "auto",
    boxShadow: 24,
    p: 4,
  };

  var label1, label2, label3;

  var ctx = document.createElement('canvas').getContext('2d');
  var gradient1 = ctx.createRadialGradient(0, 0, 0, 0, 0, 70);
  gradient1.addColorStop(0, "#E07A5F")
  gradient1.addColorStop(0.5, "#DE9B89")
  var gradient2 = ctx.createRadialGradient(0, 0, 0, 0, 0, 70);
  gradient2.addColorStop(0, "#A89F91")
  gradient2.addColorStop(0.5, "#C4BBAC")
  var gradient3 = ctx.createRadialGradient(0, 0, 0, 0, 0, 70);
  gradient3.addColorStop(0, "#8DA665")
  gradient3.addColorStop(0.5, "#A9C779")

  function TeamWiseAnatomyDoughnut(props) {
    label1 =
      "Approved Revenue - " + Math.round(props.data[2]).toLocaleString("en-US");
    label2 =
      " Pending Validation - " + Math.round(props.data[5]).toLocaleString("en-US");
    label3 =
      "Deduction - " + Math.round(props.data[4]).toLocaleString("en-US");

    const data = {
      labels: [label1 + "", label2 + " ", label3],
      datasets: [
        {
          data: [props.data[2], props.data[5], props.data[4]],
          backgroundColor: [gradient3, gradient2, gradient1],
          borderWidth: 1,
        },
      ],
    };
    const options = {
      layout: {
        padding: {
          top: 10,
          left: 10,
          bottom: 10,
          right: 10,
        },
      },
      cutout: 70,
      plugins: {
        tooltip: {
          backgroundColor: "#f0f0f0",
          titleColor: "#252525",
          borderWidth: 1,
          borderColor: "",
          bodyColor: "#252525",
        },
        legend: {
          fullSize: true,
          position: "bottom",
          align: "start",
          title: { display: true},
          labels: {
            color: "#252525",
            font: {
              // family: "kanit, sans-serif",
              size: 16,
              weight: 400,
            },
            usePointStyle: true,
            textAlign: "start",
            pointstyle: "circle",
            padding: 10,
          },
        },
        datalabels: {
          display: false,
        },
      },
    };
    return (
      <div className="doughnut">
        <Doughnut
          onClick={handleOpen(props.data[0])}
          data={data}
          options={options}
        />
      </div>
    );
  }
  var addColumnOptions;
  if (selectedRev === "Verified Revenue") {
    addColumnOptions = [
      { value: 0, label: "Verified Revenue" },
      { value: 1, label: "Offers Validated" },
      { value: 2, label: "Approved Revenue" },
      { value: 3, label: "Validation %" },
      { value: 4, label: "Pending Validation" },
      { value: 5, label: "Deduction" },
      { value: 6, label: "Difference in Revenue" },
      { value: 7, label: "Panel Revenue Accuracy" },
      { value: 8, label: "Delivered Revenue" },
    ];
  } else {
    addColumnOptions = [
      { value: 0, label: "Verified Revenue" },
     { value: 1, label: "Offers Validated" },
     { value: 2, label: "Approved Revenue" },
     { value: 3, label: "Validation %" },
     { value: 4, label: "Pending Validation" },
     { value: 5, label: "Deduction" },
     { value: 6, label: "Difference in Revenue" },
     { value: 7, label: "Panel Revenue Accuracy" },
     { value: 8, label: "Delivered Revenue" },
    ];
  }
  const initiallySelected=[true,
    false,
    true,
    true,
    true,
    true,
    false,
    false,
    false,
    false,];
  const [selectedColumns, setSelectedColumns] = useState(initiallySelected);
  var columnHeadings = [
    "Team",
    "Verified Revenue",
    "Approved Revenue",
    "Validation %",
    "Deduction",
    "Pending Validation",
  ];
  if(selectedRev==="Panel Revenue")
    columnHeadings[1]="Panel Revenue";
  const [selectedHead, setSelectedHead] = useState("Advertisers");
  var total1,
    total2,
    total3,
    total4 = 0;
  let originalString = team;
  selectedData.forEach((item, index) => {
    if (item[0] === "Team 1") {
      total1 = item[1];
      total4 += total1;
    } else if (item[0] === "Team 2") {
      total2 = item[1];
      total4 += total2;
    } else {
      total3 = item[1];
      total4 += total3;
    }
  });
  let modifiedTeam = originalString.replace(/(\d+)/, " $1");
 
  const [btnClick, setBtnClick] = useState(0);
  const percentage1 = (total1 / total4) * 100;
  const percentage2 = (total2 / total4) * 100;
  const percentage3 = (total3 / total4) * 100;
  const minPercentage = Math.min(percentage1, percentage2, percentage3);
  const maxPercentage = Math.max(percentage1, percentage2, percentage3);
  const getColor = (percentage) => {
    if (percentage === minPercentage) return "#487DBA";
    if (percentage === maxPercentage) return "#2E5077";
    return "#396394";
  };

  if (message === "graph") {
    return (
      <div className="containerManik team-wise-anatomy">
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <div className="teamName">{modifiedTeam}</div>
            <YearlySynopsis
              team={team}
              setTeam={setTeam}
              setFilterData={setFilterData}
              setAdvertiser={setAdvertiser}
              setPackageName={setPackageName}
              setManager={setManager}
              setSelectedRev={setSelectedRev}
              filterData={filterData}
              initdate={initdate}
              finaldate={finaldate}
              selectedRev={selectedRev}
              advertiser={advertiser}
              packageName={packageName}
              manager={manager}
              advData={advData}
              advertiserString={advertiserString}
              packageString={packageString}
              managerString={managerString}
            />
            <MonthlySynopsis2
              modTeam={team}
              initdate={initdate}
              finaldate={finaldate}
              selectedRev={selectedRev}
              packageName={packageName}
              advertiser={advertiser}
              manager={manager}
              advertiserString={advertiserString}
              packageString={packageString}
              managerString={managerString}
            />
            <div className="addcolumn2">
            {initial ?(<div></div>):
            (<div style={{
              display: "flex",
              width: "100%",
            }}>
              <button
              style={{
             color: "#0E6BA8",
             backgroundColor: "#FFFFFF",
             border: "none",
            //  fontFamily: "kanit,sans-serif",
             fontSize: "1rem",
             borderRadius: "5px",
           }}
             onClick={()=>{if(monthly){
              //  setAdvappmonth("");
               setMonthly(false);
             }
               else{
                 // setMonthly(false);
                 setInitial(true);
               }
             }}>
           <img
               src={back}
               style={{
                 width: "1.3rem",
                 height: "1.4rem",
                 marginBottom:"-0.5rem",
                 marginLeft:"-0.5rem"
               }}
             />
             </button>
            {!monthly?
            (<CustomSelect
              placeholder={
                selectedHead === "Advertisers"
                  ? "Revenue Synopsis ( Advertisers ) "
                  : "Revenue Synopsis ( Apps ) "
              }
              options={[
                { value: "Advertisers", label: "Advertisers" },
                { value: "Apps", label: "Apps" },
              ]}
              selectedHead={selectedHead}
              setSelectedHead={setSelectedHead}
              width="22rem"
              fontFam={"Oswald,sans-serif"}
              fontSize={"1.6rem"}
              fontWeight={"400"}
              menu={true}
              textAlign={"left"}
            />): (<div style={{ fontFamily:"Oswald,sans-serif",
            fontSize:"1.6rem",
            fontWeight:"400"}}>
                
               {advapp}
              { monthly? selectedHead=="Advertisers"? "'s Apps":"'s Advertisers":""}
              </div>)}
              </div>)
              }
              </div>
         
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            </div>
            <div
              style={{
                display: "flex",
                // justifyContent: "flex-end",
                width: "100%",
              }}
            >
            {initial ?
             ( <div
              style={{
                // fontFamily: "kanit",
                fontSize: "11px",
                color: "#A5A5A5",
                
                marginRight:"auto",
                marginTop:"1.2rem"
              }}
            >
              *Data is shown according to the monthly range selected
            </div>):
            ( <div
              className="monthhead"
              style={{ marginRight: "auto",}}
            >
              {advappmonth}
  
              </div>)}
              <div style={{
                 display: "flex",
                 justifyContent: "flex-end",
                 alignItems: "center",
                 marginLeft:"auto"
              }}>
              <button
                onClick={() =>{ setBtnClick(btnClick + 1)
                  setSelectedColumns(initiallySelected);
                  setInitial(true);
                setMonthly(false);}}
                style={{
                  // width: "9rem",
                  // height: "1.5rem",
                  // marginLeft: "1rem",
                  color: "#0E6BA8",
                  backgroundColor: "#FFFFFF",
                  border: "none",
                  // fontFamily: "kanit,sans-serif",
                  fontSize: "1rem",
                  borderRadius: "5px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={resetNew}
                    style={{
                      width: "1.3rem",
                      height: "1.4rem",
                    }}
                  />
                  {/* <div>Reset Table</div> */}
                </div>
              </button>
              </div>

              <CustomSelect
                placeholder={"Edit Columns"}
                options={addColumnOptions}
                isCheckboxOption={true}
                isPlaceholderFixed={true}
                selectedColumns={selectedColumns}
                setSelectedColumns={setSelectedColumns}
                width="2rem"
                height="3rem"
                color="black"
                // bgcolor="#0E6BA8"
                optcolor="#E7F1F7"
                menu={false}
              />
            </div>
            <CustomTable
              key={btnClick}
              modTeam={team}
              selectedRev={selectedRev}
              selectedColumns={selectedColumns}
              group={initial? "month":selectedHead}
              Initdate={initdate}
              Finaldate={finaldate}
              advData={advData}
              className="main_table_month"
              advertiserString={advertiserString}
              packageString={packageString}
              managerString={managerString}
              name={"TeamWise"}
              initial={initial}
              setAdvapp={setAdvapp}
            advappmonth={advappmonth}
            setAdvappmonth={setAdvappmonth}
            setInitial={setInitial}
            setMonthly={setMonthly}
            monthly={monthly}
            appPackageMap={appPackageMap}
            sortMonth={initial? "no-sort":tableHead}
            />
          </Box>
        </Modal>
        <div
          className="title tablegraphimg_main"
          style={{ marginBottom: "10px" }}
        >
          <div className="title" style={{ paddingLeft: "10px" }}>
            Team Wise Anatomy{" "}
            {begMonth === endMonth ? (
              <span
                style={{
                  marginLeft: "0.1rem",
                  marginTop: "0.5rem",
                  color: "#A5A5A5",
                }}
              >
                ({begMonth})*
              </span>
            ) : (
              <span
                style={{
                  marginLeft: "0.1rem",
                  marginTop: "0.5rem",
                  color: "#A5A5A5",
                }}
              >
                ({begMonth}-{endMonth})*
              </span>
            )}
               {advertiser!="" || manager!="" || packageName!="" || selectedRev!="Verified Revenue"?(
          <div style={{background:"#D9D9D9",borderRadius:"42px",
        display:"inline-flex",marginLeft:"5px"}}>
              {advertiser!=""?
              <div className="crossContainer" onClick={()=>{setAdvertiser("")
              setFilterData({...filterData,["advertiser_id"]:""})}}>
                <div className="cross"><Cross/></div>
              <AdvertiserIcon/>
              </div>:<></>}
              {selectedRev!="Verified Revenue"?
              <div className="crossContainer" onClick={()=>{setSelectedRev("Verified Revenue")
              setFilterData({...filterData,["selectedRev"]:{label:'Verified Revenue',value:'Verified Revenue'}})}}>
                <div className="cross"><Cross/></div>
              <MoneyIcon/>
              </div>:<></>}
              {manager!=""?
              <div className="crossContainer" onClick={()=>{setManager("")
              setFilterData({...filterData,["advertiser_manager_id"]:""})}}>
                <div className="cross"><Cross/></div>
              <AdvertiserManagerIcon/>
              </div>:<></>}
              {packageName!=""?
              <div className="crossContainer" onClick={()=>{setPackageName("")
              setFilterData({...filterData,["package_name"]:"",["appData"]:""})}}>
                <div className="cross"><Cross/></div>
              <AppIcon/>
              </div>:<></>}
          </div>):(<></>)}
            <div className='title-info'
              style={{
                // fontFamily: "kanit",
                marginTop:"0.2rem"
              }}
            >
              *Data is shown according to the monthly range selected
            </div>
          </div>
          <div className="team1">Team 1</div>
          <div className="team2">Team 2</div>
          <div className="team3">Team 3</div>
          <div className="tabim">
            <div
              className={
                message === "graph" ? "selected" : "tablegraphingimage"
              }
            >
              <img
                style={{ width: "1.5rem", height: "1.2rem" }}
                src={dough}
                onClick={func}
              />
            </div>
            <div className="tablegraphimg">
              <img
                style={{ width: "1.5rem", height: "1.2rem" }}
                src={table}
                onClick={func2}
              />
            </div>
          </div>
        </div>
        <div className="team-wise-anatomy-graphs">
          {selectedData.map((item, index) => {
            return <TeamWiseAnatomyDoughnut data={item} key={index} />;
          })}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "1rem",
          }}
        >
          <div
            style={{
              fontFamily: "Oswald,sans-serif",
              fontSize: "28px",
              marginBottom: "10px",
            }}
          >
            Revenue Contribution
          </div>
          <div style={{ marginTop: "1rem", marginRight: "1rem" }}>
            {selectedRev} - {Math.round(total4).toLocaleString("en-US")}
          </div>
        </div>
        <div className="doughnutfooter">
          <div
            className="doughTeamTotal"
            style={{
              // width: `${percentage1.toFixed(2)}%`,
              width:"100%",
              backgroundColor: getColor(percentage1),
            }}
          >
            <span style={{ fontWeight: "600", marginRight: "4px" }}>
              Team 1
            </span>{" "}
            - {Number(Math.round(total1)).toLocaleString("en-US")} (
            {percentage1.toFixed(2)}%)
          </div>
          <div
            className="doughTeamTotal"
            style={{
              // width: `${percentage2.toFixed(2)}%`,
              width:"100%",
              backgroundColor: getColor(percentage2),
            }}
          >
            <span style={{ fontWeight: "600", marginRight: "4px" }}>
              Team 2
            </span>{" "}
            - {Number(Math.round(total2)).toLocaleString("en-US")} (
            {percentage2.toFixed(2)}%)
          </div>
          <div
            className="doughTeamTotal"
            style={{
              // width: `${percentage3.toFixed(2)}%`,
              width:"100%",
              backgroundColor: getColor(percentage3),
            }}
          >
            <span style={{ fontWeight: "600", marginRight: "4px" }}>
              Team 3
            </span>{" "}
            - {Number(Math.round(total3)).toLocaleString("en-US")} (
            {percentage3.toFixed(2)}%)
          </div>
        </div>
      </div>
    );
  } else {
    const accumulate_on_column = (index) =>
      selectedData.reduce((acc, item) => acc + item[index], 0);
    let [
      total_manual_revenue,
      total_validation_revenue,
      total_deducted_revenue,
      total_pending_revenue,
    ] = [1, 2, 4, 5].map(accumulate_on_column);
    return (
      <div className="containerManik"  style={{padding:"0"}}>
      <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <div className="teamName">{modifiedTeam}</div>
            <YearlySynopsis
              team={team}
              setTeam={setTeam}
              initdate={initdate}
              finaldate={finaldate}
              selectedRev={selectedRev}
              advertiser={advertiser}
              packageName={packageName}
              manager={manager}
              advData={advData}
              advertiserString={advertiserString}
              packageString={packageString}
              managerString={managerString}
              setFilterData={setFilterData}
              setAdvertiser={setAdvertiser}
              setPackageName={setPackageName}
              setManager={setManager}
              setSelectedRev={setSelectedRev}
              filterData={filterData}
            />
            <MonthlySynopsis2
              modTeam={team}
              initdate={initdate}
              finaldate={finaldate}
              selectedRev={selectedRev}
              packageName={packageName}
              advertiser={advertiser}
              manager={manager}
              advertiserString={advertiserString}
              packageString={packageString}
              managerString={managerString}
            />
            <div className="addcolumn2">
            {initial ?(<div></div>):
            (<div style={{
              display: "flex",
              width: "100%",
            }}>
              <button
              style={{
             color: "#0E6BA8",
             backgroundColor: "#FFFFFF",
             border: "none",
            //  fontFamily: "kanit,sans-serif",
             fontSize: "1rem",
             borderRadius: "5px",
           }}
             onClick={()=>{if(monthly){
              //  setAdvappmonth("");
               setMonthly(false);
             }
               else{
                 // setMonthly(false);
                 setInitial(true);
               }
             }}>
           <img
               src={back}
               style={{
                 width: "1.3rem",
                 height: "1.4rem",
                 marginBottom:"-0.5rem",
                 marginLeft:"-0.5rem"
               }}
             />
             </button>
            {!monthly?
            (<CustomSelect
              placeholder={
                selectedHead === "Advertisers"
                  ? "Revenue Synopsis ( Advertisers ) "
                  : "Revenue Synopsis ( Apps ) "
              }
              options={[
                { value: "Advertisers", label: "Advertisers" },
                { value: "Apps", label: "Apps" },
              ]}
              selectedHead={selectedHead}
              setSelectedHead={setSelectedHead}
              width="22rem"
              fontFam={"Oswald,sans-serif"}
              fontSize={"1.6rem"}
              fontWeight={"400"}
              menu={true}
              textAlign={"left"}
            />): (<div style={{ fontFamily:"Oswald,sans-serif",
            fontSize:"1.6rem",
            fontWeight:"400"}}>
                
               {advapp}
              { monthly? selectedHead=="Advertisers"? "'s Apps":"'s Advertisers":""}
              </div>)}
              </div>)
              }
              </div>
         
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            </div>
            <div
              style={{
                display: "flex",
                // justifyContent: "flex-end",
                width: "100%",
              }}
            >
            {initial ?
             ( <div
              style={{
                // fontFamily: "kanit",
                fontSize: "11px",
                color: "#A5A5A5",
                
                marginRight:"auto",
                marginTop:"1.2rem"
              }}
            >
              *Data is shown according to the monthly range selected
            </div>):
            ( <div
              className="monthhead"
              style={{ marginRight: "auto",}}
            >
              {advappmonth}
  
              </div>)}
              <div style={{
                 display: "flex",
                 justifyContent: "flex-end",
                 alignItems: "center",
                 marginLeft:"auto"
              }}>
              <button
                onClick={() =>{ setBtnClick(btnClick + 1)
                  setSelectedColumns(initiallySelected);
                  setInitial(true);
                setMonthly(false);}}
                style={{
                  // width: "9rem",
                  // height: "1.5rem",
                  // marginLeft: "1rem",
                  color: "#0E6BA8",
                  backgroundColor: "#FFFFFF",
                  border: "none",
                  // fontFamily: "kanit,sans-serif",
                  fontSize: "1rem",
                  borderRadius: "5px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={resetNew}
                    style={{
                      width: "1.3rem",
                      height: "1.4rem",
                    }}
                  />
                  {/* <div>Reset Table</div> */}
                </div>
              </button>
              </div>

              <CustomSelect
                placeholder={"Edit Columns"}
                options={addColumnOptions}
                isCheckboxOption={true}
                isPlaceholderFixed={true}
                selectedColumns={selectedColumns}
                setSelectedColumns={setSelectedColumns}
                width="2rem"
                height="3rem"
                color="black"
                // bgcolor="#0E6BA8"
                optcolor="#E7F1F7"
                menu={false}
              />
            </div>
            <CustomTable
              key={btnClick}
              modTeam={team}
              selectedRev={selectedRev}
              selectedColumns={selectedColumns}
              group={initial? "month":selectedHead}
              Initdate={initdate}
              Finaldate={finaldate}
              advData={advData}
              className="main_table_month"
              advertiserString={advertiserString}
              packageString={packageString}
              managerString={managerString}
              name={"TeamWise"}
              initial={initial}
              setAdvapp={setAdvapp}
            advappmonth={advappmonth}
            setAdvappmonth={setAdvappmonth}
            setInitial={setInitial}
            setMonthly={setMonthly}
            monthly={monthly}
            appPackageMap={appPackageMap}
            sortMonth={initial? "no-sort":tableHead}
            />
          </Box>
        </Modal>
      <div className="table">
        <div className="title tablegraphimg_main">
          <div
            className="title"
            style={{ marginTop: "1rem", paddingLeft: "1.5rem" }}
          >
            Team Wise Anatomy{" "}
            {begMonth === endMonth ? (
              <span
                style={{
                  marginLeft: "0.1rem",
                  marginTop: "0.5rem",
                  color: "#A5A5A5",
                }}
              >
                ({begMonth})*
              </span>
            ) : (
              <span
                style={{
                  marginLeft: "0.2rem",
                  marginTop: "0.5rem",
                  color: "#A5A5A5",
                }}
              >
                ({begMonth}-{endMonth})*
              </span>
            )}
               {advertiser!="" || manager!="" || packageName!="" || selectedRev!="Verified Revenue"?(
          <div style={{background:"#D9D9D9",borderRadius:"42px",
        display:"inline-flex",marginLeft:"5px"}}>
              {advertiser!=""?
              <div className="crossContainer" onClick={()=>{setAdvertiser("")
              setFilterData({...filterData,["advertiser_id"]:""})}}>
                <div className="cross"><Cross/></div>
              <AdvertiserIcon/>
              </div>:<></>}
              {selectedRev!="Verified Revenue"?
              <div className="crossContainer" onClick={()=>{setSelectedRev("Verified Revenue")
              setFilterData({...filterData,["selectedRev"]:{label:'Verified Revenue',value:'Verified Revenue'}})}}>
                <div className="cross"><Cross/></div>
              <MoneyIcon/>
              </div>:<></>}
              {manager!=""?
              <div className="crossContainer" onClick={()=>{setManager("")
              setFilterData({...filterData,["advertiser_manager_id"]:""})}}>
                <div className="cross"><Cross/></div>
              <AdvertiserManagerIcon/>
              </div>:<></>}
              {packageName!=""?
              <div className="crossContainer" onClick={()=>{setPackageName("")
              setFilterData({...filterData,["package_name"]:"",["appData"]:""})}}>
                <div className="cross"><Cross/></div>
              <AppIcon/>
              </div>:<></>}
          </div>):(<></>)}
            <div className='title-info'
              style={{
                // fontFamily: "kanit",
                marginTop:"0.2rem",
                marginBottom:"0.2rem"
              }}
            >
              *Data is shown according to the monthly range selected
            </div>
          </div>

          <div
            className="tabim"
            style={{ marginTop: "1rem", marginRight: "1rem" }}
          >
            <div className="tablegraphimg">
              <img
                style={{ width: "1.5rem", height: "1.2rem" }}
                src={tableHead=="" ? inactiveReset:resetNew}
                onClick={resetTable}
              />
              <img
                style={{ width: "1.5rem", height: "1.2rem" }}
                src={dough}
                onClick={func}
              />
            </div>
            <div
              className={
                message === "table" ? "selected" : "tablegraphingimage"
              }
            >
              <img
                style={{ width: "1.5rem", height: "1.2rem" }}
                src={table}
                onClick={func2}
              />
            </div>
          </div>
        </div>
        <div className="main_table">
          <table className="tab" style={{ textAlign: "center" }}>
            <tr style={{ height:"3.6rem"}}>
              {columnHeadings.map((heading, index) =>
                index === 0 ? (
                  <th style={{extAlign: "left", paddingRight: "0.2rem"}}>{heading}</th>
                ) : (
                  <th style={{backgroundColor: tableHead === columnHeadings[index]? "#AFC4D6":"#CFE1EE"}}>
                    <CustomSelect1 
                    styles={customStyles}
                    placeholder={heading} />
                  </th>
                )
              )}
            </tr>
            {selectedData.map((item, index) => {
              return (
                <tr key={index}>
                  {item.map((item, index) => {
                    if (index === 3) {
                      if (tableHead === columnHeadings[index]) {
                        return (
                          <td
                            key={index}
                            className="centre"
                            style={{
                              minHeight: "100rem",
                              // backgroundColor: "rgba(255, 111, 97,0.1)",
                            }}
                          >
                            <PercentageDiv percentage={item.toFixed(2)} />
                          </td>
                        );
                      } else {
                        return (
                          <td key={index}>
                            <PercentageDiv percentage={item.toFixed(2)} />
                          </td>
                        );
                      }
                    }
                    if (index != 0) {
                      if (tableHead === columnHeadings[index]) {
                        return (
                          <td
                            style={{
                              minHeight: "100rem",
                              // backgroundColor: "rgba(255, 111, 97,0.1)",
                            }}
                            key={index}
                          >
                            {Number(Math.round(item)).toLocaleString("en-US")}
                          </td>
                        );
                      } else
                        return (
                          <td key={index}>
                            {" "}
                            {Number(Math.round(item)).toLocaleString("en-US")}
                          </td>
                        );
                    }
                    if (index == 0)
                      return (
                        <td className="first_col" key={index} onClick={handleOpen(item)}>
                          {item}
                        </td>
                      );
                  })}
                </tr>
              );
            })}
            <tr className="last-row">
              <td>Total</td>
              <td>
                {Number(Math.round(total_manual_revenue)).toLocaleString(
                  "en-US"
                )}
              </td>
              <td>
                {Number(Math.round(total_validation_revenue)).toLocaleString(
                  "en-US"
                )}
              </td>
                <td
                className="centre"
                style={{
                  minHeight: "100rem",
                }}
              >
                <PercentageDiv percentage= {Number(Math.round(total_validation_revenue/total_manual_revenue*100)).toLocaleString(
      "en-US" )} />
                </td>
           
              <td>
                {Number(Math.round(total_deducted_revenue)).toLocaleString(
                  "en-US"
                )}
              </td>
              <td>
                {Number(Math.round(total_pending_revenue)).toLocaleString(
                  "en-US"
                )}
              </td>
            </tr>
          </table>
        </div>
      </div>
      </div>
    );
  }
}
export default TeamWiseAnatomy;