import React,{useState} from 'react';
import axios from 'axios';

import { Resizable } from "re-resizable";
import CreatableSelect from 'react-select/creatable';
import ReactSelect from 'react-select';
import { DateRangePicker } from 'react-date-range';
import { Button,  CircularProgress } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SettingsIcon from '@mui/icons-material/Settings';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { CssTextField, ReactSelectStyles } from '../../../data/constants';
import * as Constants from '../../../data/constants';
import StatisticsRouter from '../statisticsRouter';
import '../../../css/pages/v2/statistics.css';
import { useForceUpdate,showHtml, getDateFromDateRange, sorting, capitalize, getRequests, getLocalStorageValue } from '../../../util/util';
import ShowLoaderComponent from '../../../components/loader';
import { Store } from 'react-notifications-component';
import ArrangeColumns from '../../../components/arrangeColumns/arrangeColumns';
import NoResultsFound from '../../../components/noResultFound';
import SomeErrorOccured from '../../../components/someErrorOccured';
import { getAdvertisers, getAffiliates } from '../../../apis/dropdown/dropdowns';
import DownloadStats from '../downloadStats';
import changeConversionImage from '../../../img/changeConversion.png'
import TableModel from '../../../components/tableModel/tablemodel';
import { isTestingUser } from '../../../data/sidebarTabs';
const ConversionStatistics = () => {
    const updateComponent = useForceUpdate()
    var statsType = window.location.href.split("statistics/")[1]
    if(statsType.includes("?")){
        statsType = statsType.split("?")[0]
    }
    var fixTableHeadersMapping = {
        conversions : [
                    {show:true,label:'',value:'checkbox',minWidth:30,width:30},
                    {show:true,label:'Date',value:'created_at',minWidth:130,width:170},
                    {show:true,label:'Offer',value:'offer_id',minWidth:40,width:50},
                    {show:true,label:'Click ID',value:'click_id',minWidth:90,width:170},
                ],
        'conversions/events' : [
            {show:true,label:'',value:'checkbox',minWidth:30,width:30},
            {show:true,label:'Date',value:'created_at',minWidth:130,width:170},
            {show:true,label:'Offer',value:'offer_id',minWidth:40,width:50},
            {show:true,label:'Click ID',value:'click_id',minWidth:90,width:170},
        ],
    }
    var fixTableHeaders = fixTableHeadersMapping[statsType]
    var url = new URL(window.location.href)
    
    if(url.searchParams.get('dateRange')){
        var startDate = new Date(url.searchParams.get('dateRange').split("_")[0])
        var endDate = new Date(url.searchParams.get('dateRange').split("_")[1])
    }
    const [dateRange, setDateRange] = useState(url.searchParams.get('dateRange') ? [startDate,endDate] : [new Date(new Date().getTime()-86400000*0),new Date()])

    var searchParamsList = [
        {label:'type',setValueList:[],},
        {label:'offerId',setValueList:[],},
        {label:'advertiser',setValueList:[],},
        {label:'affiliate',setValueList:[],},
        {label:'advertiserManager',setValueList:[],},
        {label:'affiliateManager',setValueList:[],},
        {label:'goalValue',setValueList:[],},
        {label:'groupBy',setValueList:null},
    ]

    var mapMM = {
        'goal_value':'Goal Value',
        'declined_reason':'Declined Reason',
    }
    if(url.searchParams.get('type') || url.searchParams.get('offerId') || url.searchParams.get('advertiser') || url.searchParams.get('affiliate') || url.searchParams.get('affiliate_manager') || url.searchParams.get('advertiser_manager') ||  url.searchParams.get('goalValue') ||  url.searchParams.get('groupBy') ){
        for(var searchParamsListIndex in searchParamsList){
            var elem = searchParamsList[searchParamsListIndex]
            if(url.searchParams.get(elem.label)){
                var selectList = []
                var queryTextList = url.searchParams.get(elem.label).split(",")
                for(var index in queryTextList){
                    if(elem.label==='type'){
                        selectList = {
                            label: capitalize(queryTextList[index]),
                            value: queryTextList[index]
                        }
                    }
                    else if(elem.label==='groupBy'){
                        console.log(queryTextList)
                        selectList = {
                            label: mapMM[queryTextList[index]],
                            value: queryTextList[index]
                        }
                    }
                    else if(elem.label!=='offerId'){
                        selectList.push({
                            label: queryTextList[index],
                            value: queryTextList[index].substring(queryTextList[index].lastIndexOf("-") + 1, queryTextList[index].length)
                        })
                    }
                    else{
                        selectList.push({
                            label: queryTextList[index],
                            value: queryTextList[index],
                        })
                    }
                }
                elem.setValueList = selectList
            }
        }
    }
    const [optionsAffiliateList, setOptionsAffiliateList] = useState([])
    const [optionsAdvertiserList, setOptionsAdvertiserList] = useState([])

    const [timezone, setTimezone] = useState(getLocalStorageValue('panelTimezone') ? getLocalStorageValue('panelTimezone') :{label : "UTC (UTC+00:00)" , value : "UTCUTC+00:00"})

    const [searchConverionsType, setSearchConversionType] = useState(searchParamsList[0].setValueList)
    const [searchOfferList, setSearchOfferList] = useState(searchParamsList[1].setValueList)
    const [searchAdvertiserList, setSearchAdvertiserList] = useState(searchParamsList[2].setValueList)
    const [searchAffiliateList, setSearchAffiliateList] = useState(searchParamsList[3].setValueList)
    const [searchGoalList, setSearchGoalList] = useState(searchParamsList[6].setValueList)
    const [groupBy, setGroupBy] = useState(searchParamsList[7].setValueList)
    const [declinedReason, setDeclinedReason] = useState()

    const [dateRangeState, setDateRangeState] = useState([{
                                                                startDate: new Date(dateRange[0]),
                                                                endDate: new Date(dateRange[1]),
                                                                key: 'selection'
                                                            }])
    const [showDatePicker, setShowDatePicker] = useState(false);

    const dateRangePickedMethod = (item) => {
        setDateRangeState([item.selection]);
        if (item.selection.endDate !== item.selection.startDate){
            // setShowDatePicker(false)
            var value = [item.selection.startDate,item.selection.endDate]
            if (value[0] && value[1]){
                setDateRange(value)
                setShowDatePicker(false)
                }
        }
    }

    var timezoneList = Constants.timezoneList

    const filtersList = [
        {label:'Date Range',value:getDateFromDateRange(dateRange),setMethod:dateRangePickedMethod,show:true},
        {label:'Timezone',value:timezone,isSingle:true,setMethod:setTimezone,options:timezoneList,show:true},
        {label:'Offer ID',value:searchOfferList,setMethod:setSearchOfferList,show:true},
        {label:'Conversion Status',value:searchConverionsType,setMethod:setSearchConversionType,options:[{label:"All",value:null},{label:"Approved",value:"approved"},{label:"Declined",value:"declined"}],show:true},
        {label:'Advertiser',value:searchAdvertiserList,setMethod:setSearchAdvertiserList,options:optionsAdvertiserList,show:true},
        {label:'Affiliate',value:searchAffiliateList,setMethod:setSearchAffiliateList,options:optionsAffiliateList,show:true},
        {label:'Goal Value',value:searchGoalList,setMethod:setSearchGoalList,show:true},
        {label:'Declined Reason',isSingle:true,isClearable:true,value:declinedReason,setMethod:setDeclinedReason,options:[{label:"Caps Limit Reached",value:'cap-reached'},{label:"Non Payable Goal",value:"payment-not-found"},{label:"By Manager",value:"by-manager"},],show:true},
        {label:'Group By',isSingle:true,isClearable:true,value:groupBy,setMethod:setGroupBy,options:[{label:"Declined Reason",value:'declined_reason'},{label:"Goal Value",value:"goal_value"}],show:true},
    ]
    
    const resetAllFields = (e) => {
        for(var index in filtersList){
            var filterItem = filtersList[index]
            if(filterItem.show){
                if(filterItem.label==='Date Range'){
                    var resetSelection = {selection : {
                                                            startDate: new Date(new Date().getTime()-86400000*6),
                                                            endDate: new Date(),
                                                            key: 'selection'
                                                        }}
                    filterItem.setMethod(resetSelection)
                }
                else{
                    filterItem.setMethod([])
                }
            }
        }
        var url = '/statistics/'+statsType
        window.history.pushState('Statistics - Conversions', 'Statistics -  Conversions', url);
        updateComponent(e);
    }

    const [fixedTableHeaders, setFixedTableHeaders] = useState(fixTableHeaders)

    const renameComponent = (valueItem,item) => {
        var renameDict = {
            'payment-not-found':'Non Payable Goal',
            'expiredOffer':'Offer Expired',
            'cap-reached' : 'Caps Limit Reached'
        } 
        if(renameDict[valueItem[item.value]]){
            return renameDict[valueItem[item.value]]
        }
        return valueItem[item.value]
    }
    var varTableHeadersConstants = [
        {show:true,id:'eight',label:'Payout Status',value:'payout_status',minWidth:80,width:110},
        {show:true,id:'nine',label:'Declined Reason',value:'declined_reason',customComponent:renameComponent,minWidth:110,width:130},
        {show:true,id:'one',label:'Advertiser',value:'advertiser_id',minWidth:80,width:110},
        {show:true,id:'two',label:'Advertiser Manager',value:'advertiser_manager_id',minWidth:80,width:110},
        {show:true,id:'three',label:'Affiliate',value:'affiliate_id',minWidth:80,width:110},
        {show:true,id:'four',label:'Affiliate Manager',value:'affiliate_manager_id',minWidth:80,width:110},
        {show:true,id:'five',label:'Goal Value',value:'goal_value',minWidth:80,width:110},
        // {label:'Currency',value:'currency',minWidth:80,width:110},
        {show:true,id:'six',label:'Payout',value:'payout',minWidth:80,width:110},
        {show:true,id:'seven',label:'Revenue',value:'revenue',minWidth:80,width:110},
        {show:true,id:'ten',label:'User Agent',value:'user_agent',textAlign:'left',minWidth:80,width:110},
        {show:true,id:'eleven',label:'IP',value:'ip',minWidth:80,width:110},
        {show:true,id:'twelve',label:'sub1',value:'sub1',minWidth:80,width:110},
        {show:true,id:'thirteen',label:'sub2',value:'sub2',minWidth:80,width:110},
        {show:true,id:'fourteen',label:'sub3',value:'sub3',minWidth:80,width:110},
        {show:true,id:'fourteen1',label:'sub4',value:'sub4',minWidth:80,width:110},
        {show:true,id:'fourteen2',label:'sub5',value:'sub5',minWidth:80,width:110},
        {show:true,id:'fourteen3',label:'sub6',value:'sub6',minWidth:80,width:110},
        {show:true,id:'fourteen4',label:'sub7',value:'sub7',minWidth:80,width:110},
        {show:true,id:'fourteen5',label:'sub8',value:'sub8',minWidth:80,width:110},
        {show:true,id:'twelve0',label:'p_offer_id',value:'p_offer_id',minWidth:130,width:160},
        {show:true,id:'twelve1',label:'p_advertiser_id',value:'p_advertiser_id',minWidth:130,width:160},
        {show:true,id:'twelve2',label:'p_sub1',value:'p_sub1',minWidth:80,width:110},
        {show:true,id:'thirteen1',label:'p_sub2',value:'p_sub2',minWidth:80,width:110},
        {show:true,id:'fourteen1',label:'p_sub3',value:'p_sub3',minWidth:80,width:110},
        {show:true,id:'fourteen11',label:'p_sub4',value:'p_sub4',minWidth:80,width:110},
    ]
    var varTableHeaders = varTableHeadersConstants
    var varTableHeadersMapping = {
        conversions : [
                    {show:true,label:'Click ID',value:'click_id',minWidth:90,width:170,textAlign:'left'},
                    {show:true,label:'Date',value:'created_at',minWidth:130,width:170,textAlign:'left'},
                    {show:true,label:'Offer',value:'offer_id',minWidth:40,width:50,textAlign:'center'},
                ],
    }
    const [variableTableHeaders, setVariableTableHeaders] = useState(localStorage.getItem(statsType+'tableContents') ? JSON.parse(localStorage.getItem(statsType+'tableContents')) : varTableHeaders)
    const [mobileComponent, setMobileComponent] = useState(false)
    if(window.innerHeight > window.innerWidth && !mobileComponent){
        setMobileComponent(true)
        var tempVariableHeaders = variableTableHeaders
        tempVariableHeaders = [...varTableHeadersMapping[statsType],...varTableHeadersConstants]
        setVariableTableHeaders(tempVariableHeaders)
        var fixedHeadersTemp = []
        fixedHeadersTemp.push(fixedTableHeaders[0])
        setFixedTableHeaders(fixedHeadersTemp)
    }
    const [editColumnsPopup, setEditColumnsPopup] = React.useState(false);

    const closeEditColumnsPopup = (e) => {
        setEditColumnsPopup(false);
        localStorage.setItem(statsType+'tableContents',JSON.stringify(variableTableHeaders))
    }

    const [showLoader, setShowLoader] = useState(false)
    const [response, setResponse] = useState([])
    const [limit, setLimit] = useState(50);
    const [page, setPage] = useState(1);
    const [totalResults, setTotalResults] = useState(50);
    const [offset, setOffset] = useState(1);
    const [groupByRequested, setGroupByRequest] = useState(false);
    const [totalValue, setTotalValue] = useState({})
    const [totalRow, setTotalRow] = useState({})

    const getStatsData = async(e,manual_limit,manual_page) => {
        setShowLoader(true);
        
        var filterData = {
            offer_id:{value:null,data:searchOfferList},
            type:{value:null,data:searchConverionsType,type:'dict'},
            affiliate_id:{value:null,data:searchAffiliateList},
            advertiser_id:{value:null,data:searchAdvertiserList},
            goal_value:{value:null,data:searchGoalList},
        }
        for(var filterItem in filterData){
            var searchString = ""
            if(Object.keys(filterData[filterItem].data).length>0 && filterData[filterItem].type==='dict'){
                if(filterData[filterItem].data.value){
                    searchString += filterData[filterItem].data.value+","
                }
            }
            else{
                for(var index in filterData[filterItem].data){
                    searchString += filterData[filterItem].data[index].value+","
                }
            }
            searchString = searchString.slice(0,-1)
            if(searchString){
                filterData[filterItem].value = searchString
            }
        }

        var dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric'};
        var startDate = new Date(dateRange[0]).toLocaleDateString("sv-SE", dateOptions)
        var endDate = new Date(dateRange[1]).toLocaleDateString("sv-SE", dateOptions)
        var requestPage = manual_page ? manual_page : page
        var requestLimit = manual_limit ? manual_limit : limit
        var url = "".concat(Constants.PROTOCOL2,Constants.HOST2,Constants.API_USER_PATH,"statistics/conversion/view")
        if(groupBy){
            url = "".concat(Constants.PROTOCOL2,Constants.HOST2,Constants.API_USER_PATH,"statistics/conversion/count")
        }
        await axios({
            method: 'GET',
            url: url,
            headers: {
                "Content-Type":"application/json",
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params: {
                startDate,
                endDate,
                offer_id:filterData.offer_id.value,
                type:filterData.type.value,
                affiliate_id:filterData.affiliate_id.value,
                advertiser_id:filterData.advertiser_id.value,
                goal_value : filterData.goal_value.value,
                //TODO : need to add this
                limit:requestLimit,
                page:requestPage,
                group_by:groupBy ? groupBy.value : undefined,
                declined_reason:declinedReason ? declinedReason.value : undefined,
                // order_by : manual_orderby ? manual_orderby : requestOrderBy,
                // order_field : manual_orderField ? manual_orderField : requestOrder,

                utc_offset : timezone.value ? timezone.value.split(timezone.label.split(" (UTC")[0])[timezone.value.split(timezone.label.split(" (UTC")[0]).length-1].split("UTC")[1] : "+0:00",
                // tz : "utc",
                // currency:(manualCurrency ? manualCurrency : currency).toLowerCase(),
              }
        })
        .then(resp => {
            var response = resp.data.result
            setResponse(response)
            setTotalResults(resp.data.count)
            setOffset(requestPage*requestLimit - requestLimit + 1)
            if(groupBy){
                if(groupBy.value==='goal_value'){
                    var totalRow = {'goal_value':'Total',approved_count:0,declined_count:0,total_count:0}
                }
                else{
                    totalRow = {'declined_reason':'Total',count:0}
                }
                for(var index in response){
                    if(groupBy.value==='goal_value'){
                        totalRow.declined_count += response[index].declined_count
                        totalRow.approved_count += response[index].approved_count
                        totalRow.total_count += response[index].declined_count+response[index].approved_count
                    }
                    else{
                        totalRow.count+=response[index].count
                    }
                }
                setTotalRow(totalRow)
            }
        })
        .catch((error) => {
            setResponse([]);
            setTotalResults(0);
            setOffset(0);
            if (error.response) {
                console.log(error.response.data);
                if(error.response.data.result){
                    setResponse(error.response.data.result);
                }
                else{
                    setResponse(error.response.data.detail);
                }
                console.log(error.response.status);
                console.log(error.response.headers);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        })
        if(groupBy){
            setGroupByRequest(groupBy);
            setShowLoader(false);
        }
        else{
            setGroupByRequest(false);
            setShowLoader(false);
        }
    };
    

    const [manualUseState, setManualUseState] = useState(false)
    if(!manualUseState){
        setManualUseState(true);
        document.title = 'Statistics - Conversions'
        if(url.searchParams.get('dateRange')){
            getStatsData();
        }
        getRequests(getAffiliates(),setOptionsAffiliateList)
        getRequests(getAdvertisers(),setOptionsAdvertiserList)

    }   

    const [sortingOrder, setSortingOrder] = useState(false)
    const [sortingOrderField, setSortingOrderField] = useState('key');
    const [selectAllConversions, setSelectAllConversions] = useState(false);
    const [selectedConversions, setSelectedConversions] = useState([]);

    const setSorting = (e,value,intValue=false) => {
        var unsortedResponse = response
        if(sortingOrderField===value){
            sorting(response,value,intValue,!sortingOrder)
            setSortingOrder(!sortingOrder)
        }
        else{
            sorting(response,value,intValue,false)
            setSortingOrder(false)
            setSortingOrderField(value)
        }
        setResponse(unsortedResponse)
        updateComponent(e)
    }

    const handleSearchButton = (e) => {
        getStatsData(e,limit,1);

        var url = '/statistics/'+statsType+"?"
        url+= (dateRange  ? "dateRange=" + getDateFromDateRange(dateRange).replaceAll('/',"-").replaceAll(" ","").replace("->","_") +"&" : "")
        var searchFieldList = [
                                {label:'offerId',value:searchOfferList},
                                {label:'groupBy',value:groupBy ? [groupBy] : []},
                            ]

        for(var searchFieldIndex in searchFieldList){
            if(searchFieldList[searchFieldIndex].value && searchFieldList[searchFieldIndex].value.length>0){
                url+=searchFieldList[searchFieldIndex].label+"="
                for(var index in searchFieldList[searchFieldIndex].value){
                    if(searchFieldList[searchFieldIndex].label==='offerId' || searchFieldList[searchFieldIndex].label==='groupBy'){
                        url+= searchFieldList[searchFieldIndex].value[index].value
                    }
                    else{
                        url+= searchFieldList[searchFieldIndex].value[index].label.split("-")[0]+"-"+searchFieldList[searchFieldIndex].value[index].value
                    }
                    if(parseInt(index)!==searchFieldList[searchFieldIndex].value.length-1){
                        url+=","
                    }
                }
                url+="&"
            }
        }        
        url = url.slice(0,url.length-1)
        window.history.pushState('Statistics - Conversion', 'Statistics - Conversions', url);
    }

    const showValues = () => {
        var totalValues = {}
        var rows = Object.keys(response).map((keyItem,keyIndex)=>{
            var height = 40
            if(response[keyItem].payouts && response[keyItem].payouts.length>1){
                height = 40 + response[keyItem].payouts.length*10
            }
            var dateOptions = { year: 'numeric', month: 'short', day: 'numeric' ,hour:'numeric',minute:'numeric',second:'numeric'};
            return <div id={'variableColumns'+keyIndex} style={{display:'flex',height}} key={keyIndex}>
                    {
                        variableTableHeaders.map((tableHeaderItem,tableHeaderIndex)=>{
                            if(!tableHeaderItem.disable && tableHeaderItem.show){    
                                var showValue = ""           
                                showValue = response[keyItem][tableHeaderItem.value]
                                if(tableHeaderItem.value==='payout' || tableHeaderItem.value==='revenue'){
                                    if(response[keyItem]['currency'].toUpperCase() && Constants.CURRENCY_LIST_SYMBOLS[response[keyItem]['currency'].toUpperCase()]){
                                        showValue +=  " "+Constants.CURRENCY_LIST_SYMBOLS[response[keyItem]['currency'].toUpperCase()]
                                    }
                                    else{
                                        showValue += " "+ response[keyItem]['currency'].toUpperCase()
                                    }
                                }
                                
                                if(tableHeaderItem.customComponent){
                                    showValue = tableHeaderItem.customComponent(response[keyItem],tableHeaderItem)
                                }
                                if(tableHeaderItem.value==='created_at'){    
                                    return <div className='statsV2TableValueData overflowText' style={{textAlign:'left',justifyContent:(tableHeaderItem.textAlign ? tableHeaderItem.textAlign : tableHeaderItem.value==='declined_reason' ? 'flex-start' : 'center'),minWidth:tableHeaderItem.minWidth,width:tableHeaderItem.width,maxWidth:tableHeaderItem.width}} key={tableHeaderIndex}>
                                                {new Date(new Date(response[keyItem][tableHeaderItem.value]).getTime()).toLocaleDateString("en-US", dateOptions)}
                                            </div>
                                }
                                return <div className='statsV2TableValueData overflowText' style={{justifyContent:(tableHeaderItem.textAlign ? tableHeaderItem.textAlign : tableHeaderItem.value==='declined_reason' ? 'flex-start' : 'center'),minWidth:tableHeaderItem.minWidth,width:tableHeaderItem.width}} key={tableHeaderIndex}>
                                    {tableHeaderItem.value!=='declined_reason' && tableHeaderItem.value!=='payout_status' && (( showValue || showValue===0) ? showHtml(showValue) : "-")}
                                    {(tableHeaderItem.value==='declined_reason' || tableHeaderItem.value==='payout_status') && (( showValue || showValue===0) ? capitalize(showValue) : "-")}
                                    </div>
                            }
                            return null;
                        })
                    }
                </div>
        })
        if((Object.keys(totalValue).length===0 && Object.keys(totalValues).length>0) || (totalValue.clicks && totalValue.clicks!==totalValues.clicks)){
            setTotalValue(totalValues)
        }
        return rows;
    }

    const [changeMenu, setChangeMenu] = React.useState(false)
    const [changeMenuValue, setChangeMenuValue] = React.useState("")
    const [changeMenuLoader, setChangeMenuLoader] = React.useState(false)
    var number =  /^-?\d+\.?\d*$/;
    const [searchCurrency, setSearchCurrency] = useState("")

    const updateConversions = async(e) => {
        setChangeMenuLoader(true);
        await axios({
            method: 'POST',
            url:"".concat(Constants.PROTOCOL2,Constants.HOST2,Constants.API_USER_PATH,"conversions/update"),
            headers: {
                "Content-Type":"application/json",
                Authorization:getLocalStorageValue('adminPanelLoginData').token
              },
            data: {
                "type": changeMenu.value,
                "ids": selectedConversions,
                "value": changeMenu.value==='revenue' || changeMenu.value==='payout' ? parseFloat(changeMenuValue) : changeMenuValue
              }
        })
        .then(resp => {
            setChangeMenu(false);
            setSelectedConversions([]);
            setSelectAllConversions(false);
            getStatsData(e,limit,1)
            Store.addNotification({
                title: "Success!",
                message: resp.data.result,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 2000,
                  onScreen: true
                },
              });
            })
        .catch((error) => {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                Store.addNotification({
                    title: "Error!",
                    message: error.response.data.result,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 2000,
                      onScreen: true
                    }
                  });
    
                } else if (error.request) {
                console.log(error.request);
                } else {
                console.log('Error', error.message);
                }
        })   
        setChangeMenuLoader(false);

    };
    const [showExportPopup, setShowExportPopup] = useState(false)
    const [multipleActions, setMultipleActions] = useState(false)
    const [multipleActionLoader, setMultipleActionLoader] = useState(false)
    const handleMultipleActions = async() => {
        setMultipleActionLoader(true)
        var offer_id_affiliate_id_list = []
        for(var index in response){
            var item = response[index]
            if(selectedConversions.includes(item.id)){
                offer_id_affiliate_id_list.push(item.offer_id+'_'+item.affiliate_id)
            }
        }

        await axios({
            method: 'POST',
            url:"".concat(Constants.PROTOCOL2,Constants.HOST2,Constants.API_USER_PATH,"conversions/update/new"),
            headers: {
                "Content-Type":"application/json",
                Authorization:getLocalStorageValue('adminPanelLoginData').token
              },
            data: {
                "type" : {
                        "status" : multipleActions.status ? multipleActions.status : undefined,     
                        "revenue":multipleActions.revenue ? parseFloat(multipleActions.revenue) : undefined,     
                        "payout" : multipleActions.payout ? parseFloat(multipleActions.payout)  : undefined, 
                        "value_currency":multipleActions.currency ? multipleActions.currency : undefined
                    },  
                "ids": selectedConversions,
                "offer_id_affiliate_id_list":offer_id_affiliate_id_list
              }
        })
        .then(resp => {
            setSelectedConversions([]);
            setSelectAllConversions(false);
            getStatsData(1,limit,1)
            setChangeMenu(false);
            setMultipleActions(false);
            Store.addNotification({
                title: "Success!",
                message: resp.data.result,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 2000,
                  onScreen: true
                },
              });
            })
        .catch((error) => {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                Store.addNotification({
                    title: "Error!",
                    message: error.response.data.result,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 2000,
                      onScreen: true
                    }
                  });
    
                } else if (error.request) {
                console.log(error.request);
                } else {
                console.log('Error', error.message);
                }
        })   
        setMultipleActionLoader(false)
    }

    var tableHeaders = [
        {label:(groupByRequested.value==='goal_value' ? 'Goal Value' : 'Declined Reason'),value:(groupByRequested.value==='goal_value' ? 'goal_value' : 'declined_reason'),customComponent:(groupByRequested.value==='goal_value' ? false : renameComponent)}
        ,
        ...(
            groupByRequested.value==='goal_value' ? 
                [{label:'Approved Count',value:'approved_count'},
                {label:'Declined Count',value:'declined_count'},
                {label:'Total Count',value:'total_count'}] 
                :
                [{label:'Declined Count',value:'count'},
                ]
            )
    ]
    return (
        <div className='mainContainerV2' onClick={()=>{if(showDatePicker){setShowDatePicker(false)};if(changeMenu.value){setChangeMenu(false);setChangeMenuValue("");}}}>
            <StatisticsRouter/>
            {multipleActions && <div style={{position:'fixed',top:0,left:0,zIndex:5000,height:'100vh',width:'100vw',display:'flex',alignItems:'center',justifyContent:'center'}} onClick={()=>{setMultipleActions(false);}}>
                <div style={{background:'white',border:'1px solid rgb(220,220,220)',height:'60vh',width:'60vw',display:'flex',alignItems:'center'}} onClick={(e)=>{e.stopPropagation()}}>
                    <img src={changeConversionImage} alt='' width='40%'/>
                    <div style={{borderLeft:'1px solid rgb(220,220,220)',display:'flex',flexDirection:'column',paddingLeft:'30px',paddingRight:'30px',width:'100%'}}>
                        <div style={{display:'flex',justifyContent:"space-between",width:'100%'}}>

                            <label style={{fontSize:'20px',fontWeight:'500',marginBottom:'30px'}}>
                                Change Postback.
                            </label>
                            <div style={{fontSize:'13px',cursor:'pointer'}} onClick={()=>{setMultipleActions(false)}}>
                                X
                            </div>
                        </div>
                        <div style={{width:'60%',marginBottom:'10px'}}>
                            <label style={{fontSize:'12px',fontWeight:'500'}}>Status</label>
                            <div style={{fontSize:'13px'}}>
                                <ReactSelect 
                                    options={[{label:'Approve',value:'approved'},{label:'Decline',value:'declined'}]}
                                    defaultValue={multipleActions.status}
                                    onChange={(e)=>{setMultipleActions({...multipleActions,status:e.value});updateComponent(12);}}
                                    menuPortalTarget={document.body} 
                                    styles={{...ReactSelectStyles}}/>
                            </div>
                        </div>
                        {/* <div style={{width:'60%',marginBottom:'10px'}}>
                            <label style={{fontSize:'12px',fontWeight:'500'}}>Currency</label>
                            <div style={{fontSize:'13px',maxHeight:'30vh'}}>
                                <ReactSelect 
                                    options={Object.keys(Constants.CURRENCY_LIST).map((currencyItem,currencyIndex)=>{return {label:Constants.CURRENCY_LIST[currencyItem],value:currencyItem}})}
                                    defaultValue={multipleActions.currency}
                                    onChange={(e)=>{setMultipleActions({...multipleActions,currency:e.value})}}
                                    menuPortalTarget={document.body} 
                                    styles={{...ReactSelectStyles}}/>
                            </div>
                        </div> */}
                        <div style={{width:'60%',marginBottom:'10px'}}>
                            <label style={{fontSize:'12px',fontWeight:'500'}}>Revenue</label>
                            <CssTextField placeholder='Revenue' fullWidth size='small' sx={{'& .MuiOutlinedInput-root':{fontSize:'13px'}}}
                                onChange={(e)=>{setMultipleActions({...multipleActions,revenue:e.target.value})}}
                            />
                        </div>
                        <div style={{width:'60%',marginBottom:'30px'}}>
                            <label style={{fontSize:'12px',fontWeight:'500'}}>Payout</label>
                            <CssTextField placeholder='Payout' fullWidth size='small' sx={{'& .MuiOutlinedInput-root':{fontSize:'13px'}}}
                                onChange={(e)=>{setMultipleActions({...multipleActions,payout:e.target.value})}}
                            />
                        </div>
                        <Button disabled={multipleActionLoader} sx={{...Constants.CONTAINEDBUTTON,height:'35px',fontSize:'15px',width:'100px',textTransform:'none'}} onClick={handleMultipleActions}>Save</Button>
                    </div>
                </div>
                </div>}
            {showExportPopup && 
                <DownloadStats 
                    handleClose={()=>{setShowExportPopup(false)}}
                    type={'Postback'}
                    datelist={dateRange}
                    // order_field={requestOrder}
                    // order_by={requestOrderBy}
                    // currency={selectedCurrency}
                    timezone={timezone}
                    conversionType = {searchConverionsType}
                    goal_value={searchGoalList}
                    offer_id={searchOfferList.map((item,index)=>{return item.value}).toString()}
                    advertiser_id={searchAdvertiserList}
                    declinedReason={declinedReason}
                    // advertiser_manager_id={searchAdvertiserManagerList}
                    affiliate_id={searchAffiliateList}
                    // affiliate_manager_id={searchAffiliateManagerList}
                /> }
            {editColumnsPopup && <ArrangeColumns handleClose={closeEditColumnsPopup} editColumnsList={variableTableHeaders} setEditColumnsList={setVariableTableHeaders}/>}
            <div className='statsV2FilterContainer'>
                {filtersList.map((filterItem,filterIndex)=>{
                    if(filterItem.show){
                        if(filterItem.label==="Date Range"){
                            return <div key={filterIndex} className='statsV2DateRangeContainer'>
                                        <div className='statsV2FilterLabel'>{filterItem.label}</div>
                                        <CssTextField size='small' value={filterItem.value} style={{transform:'scale(0.8) translateX(-13%)',width:'200px'}} onClick={()=>{setShowDatePicker(true)}}/>
                                        <div style={{position:'absolute',zIndex:20000,transform:'scale(1)'}} onClick={(e)=>{e.stopPropagation()}}>
                                            {showDatePicker && <DateRangePicker
                                                onChange={item => dateRangePickedMethod(item)}
                                                showSelectionPreview={true}
                                                moveRangeOnFirstSelection={false}
                                                preventSnapRefocus={true}
                                                months={2}
                                                rangeColors={[localStorage.getItem('ambientColor')]}
                                                ranges={dateRangeState}
                                                showDateDisplay={false}
                                                scroll={{ enabled: window.innerWidth<800 ? true : false }}
                                                direction={window.innerWidth<800 ? "vertical" : "horizontal"}
                                                calendarFocus="backwards"
                                                />
                                            }
                                        </div>
                                    </div>
                        }
                        else if(filterItem.label==='Offer ID' || filterItem.label==='Goal Value'){
                            return <div key={filterIndex} className='statsV2DateRangeContainer'>
                                        <div className='statsV2FilterLabel'>{filterItem.label}</div>
                                        <div style={{minWidth:'200px',transform:'scale(0.779) translateX(-14%) translateY(-1.5%)'}}>
                                            <CreatableSelect
                                                isClearable
                                                isMulti
                                                value={filterItem.value}
                                                onChange={(e)=>{filterItem.setMethod(e)}}
                                                menuPortalTarget={document.body} 
                                                styles={{...ReactSelectStyles}}
                                                />
                                        </div>
                                    </div>
                        }
                        else{
                            return <div key={filterIndex} className='statsV2DateRangeContainer'>
                                        <div className='statsV2FilterLabel'>{filterItem.label}</div>
                                        <div style={{minWidth:'200px',transform:'scale(0.779) translateX(-14%) translateY(-1.5%)'}}>               
                                            <ReactSelect 
                                                options={filterItem.options}
                                                value={filterItem.value}
                                                isClearable={filterItem.isClearable}
                                                isMulti = {filterItem.label==="Conversion Status" || filterItem.isSingle ? false : true}
                                                onChange={(e)=>{filterItem.setMethod(e)}}
                                                menuPortalTarget={document.body} 
                                                styles={{...ReactSelectStyles}}/>
                                        </div>
                                    </div>
                        }
                    }
                    return null;
                })}
            </div>
            {((process.env.REACT_APP_STATS_V4_ENABLED==='true' && isTestingUser()) || process.env.REACT_APP_STATS_V4_ENABLED==='live') 
                &&
                <div style={{marginTop:'20px',fontSize:'12px',marginLeft:'20px',cursor:'pointer',width:'max-content'}} onClick={()=>{localStorage.setItem('statsV2',false);window.location.reload();}}>
                    Switch To New Design
                </div>
            }
            <div className='statsV2ButtonContainer'>

                {   !groupByRequested
                    &&
                    <div style={{width:'50%'}}>
                        <Tooltip title="Export Data">
                            <IconButton className='settingsButton' size={'small'} onClick={()=>{setShowExportPopup(true)}}>
                                <FileDownloadIcon sx={{fontSize:'17px'}}/>
                            </IconButton>
                        </Tooltip>
                        
                        <Tooltip title="Reset Table Settings">
                            <IconButton className='settingsButton' size={'small'} onClick={()=>{setVariableTableHeaders(varTableHeaders);localStorage.removeItem(statsType+'tableContents')}}>
                                <RestartAltIcon sx={{fontSize:'17px'}}/>
                            </IconButton>
                        </Tooltip>
                        
                        <Tooltip title="Table Settings">
                            <IconButton className='settingsButton' size={'small'} onClick={()=>{setEditColumnsPopup(true);}}>
                                <SettingsIcon sx={{fontSize:'17px'}}/>
                            </IconButton>
                        </Tooltip>
                    </div>
                }   
                
                <div style={{display:"flex",width:groupByRequested ? '100%' : '50%',justifyContent:'flex-end',paddingLeft:"2%",paddingRight:"2%"}}>     
                    <Button disabled={showLoader} sx={{...Constants.CONTAINEDBUTTON,height:'30px',minWidth:'70px',fontSize:'12px',textTransform:'none'}} onClick={handleSearchButton}>
                        {showLoader && <CircularProgress size={'15px'} sx={{color:'white'}}/>}
                        {!showLoader && 'Search'}
                    </Button>
                    <Button sx={{...Constants.OUTLINEDBUTTON,marginLeft:'20px',height:'30px',fontSize:'12px',textTransform:'none'}} onClick={resetAllFields}>Reset</Button>
                </div>
            </div>
            {
                groupByRequested
                &&
                showLoader && 
                <ShowLoaderComponent/>
            }
                {
                    groupByRequested
                    &&
                    !showLoader 
                    && 
                    <div className='v2PageContainer'>
                        <TableModel
                            totalRow={[totalRow]}
                            tableHeaders={tableHeaders}
                            tableValues={response}
                            setTableValues={setResponse}
                            valueRowHeight={'40px'}
                        />
                    </div>

                }
            {
                !groupByRequested
                &&
                <>  
                    {typeof response === 'object' && response.length>0
                    &&
                    <div style={{paddingLeft:'2%',paddingRight:'2%',marginBottom:'10px',fontSize:'12px',display:'flex',justifyContent:'space-between'}}>
                        <div>
                            {!showLoader && "Showing "+offset+" - "+(offset+limit-1 < totalResults ? offset+limit-1 : totalResults)+" out of "+totalResults}
                        </div>
                        {/* <div style={{cursor:'pointer'}} onClick={()=>{localStorage.setItem('oldStatsPage',true);window.location.reload();}}>
                            Switch to Old Design
                        </div> */}
                    </div>
                    }
                    {showLoader && 
                        <ShowLoaderComponent/>
                    }
                    {
                        !showLoader && 
                        <>
                            {typeof response === 'object' && response.length>0 &&
                                <>
                                    {   selectedConversions.length>0 && 
                                        <div className='selectedConversionsV2' style={{backgroundColor:localStorage.getItem('ambientColor')}}>
                                            <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',width:'100%'}}>
                                                <div style={{fontSize:'12px',color:'white',fontWeight:'500'}}>Selected {selectedConversions.length} conversions.</div>
                                                <div style={{padding:"10px",fontSize:'13px',color:'white',fontWeight:'600',display:'flex',justifyContent:'space-between'}}>
                                                    <div id='status' onClick={(e)=>{e.stopPropagation();setMultipleActions({});}} style={{cursor:'pointer',paddingLeft:'10px',paddingRight:'10px'}}>Change Postback Data</div>
                                                    {/* <div id='status' onClick={(e)=>{e.stopPropagation();setChangeMenuValue("");setChangeMenu({value:'status',left:e.pageX})}} style={{cursor:'pointer',paddingLeft:'10px',paddingRight:'10px'}}>Change Status</div>
                                                    <div id='revenue' onClick={(e)=>{e.stopPropagation();setChangeMenuValue("");setChangeMenu({value:'revenue',left:e.pageX})}} style={{cursor:'pointer',paddingLeft:'10px',paddingRight:'10px'}}>Change Revenue</div>
                                                    <div id='payout' onClick={(e)=>{e.stopPropagation();setChangeMenuValue("");setChangeMenu({value:'payout',left:e.pageX})}} style={{cursor:'pointer',paddingLeft:'10px',paddingRight:'10px'}}>Change Payout</div>
                                                    <div id='currency' onClick={(e)=>{e.stopPropagation();setChangeMenuValue("");setChangeMenu({value:'currency',left:e.pageX})}} style={{cursor:'pointer',paddingLeft:'10px',paddingRight:'10px'}}>Change Currency</div> */}
                                                </div>
                                            </div>
                                            {changeMenu.value && 
                                            <div onClick={e=>{e.stopPropagation()}} style={{position:'absolute',
                                                height:'250px',
                                                width:'200px',
                                                marginTop:'53px',
                                                boxShadow:'0 0 1px 1px rgb(200,200,200)',
                                                background:'rgb(250,250,250)',
                                                left:(document.getElementById(changeMenu.value).getBoundingClientRect().left - 150)}}>

                                                    <div style={{display:'flex',fontSize:'14px',fontWeight:500,height:'30px',color:'rgb(100,100,100)',justifyContent:'center',alignItems:'center',borderBottom:'1px solid rgb(220,220,220)'}}>
                                                        {"Change " + capitalize(changeMenu.value)}
                                                    </div>
                                                    <div style={{height:(changeMenu.value==="currency" ? '185px' :'155px'),display:'flex',flexDirection:'column',marginTop:(changeMenu.value==="currency" ? '0px' :'30px'),paddingLeft:'30px',overflowY:'auto'}} onClick={(e)=>{e.stopPropagation();}}>
                                                        {
                                                            changeMenu.value==="status" &&
                                                                <>
                                                                    <div style={{display:'flex',alignItems:'center'}}>
                                                                        <input type="radio" id="approvedStatus" name="conversion_status" value="approved" onChange={(e)=>{if(e.target.checked){setChangeMenuValue("approved")}}}/>
                                                                        <label style={{fontSize:'12px',marginTop:'2px'}} for="approvedStatus">Approve</label><br/>
                                                                    </div>
                                                                    <div style={{display:'flex',marginTop:'15px',alignItems:'center'}}>
                                                                        <input type="radio" id="declinedStatus" name="conversion_status" value="declined" onChange={(e)=>{if(e.target.checked){setChangeMenuValue("declined")}}}/>
                                                                        <label style={{fontSize:'12px',marginTop:'2px'}} for="declinedStatus">Decline</label><br/>
                                                                    </div>
                                                                </>
                                                        }
                                                        {
                                                            (changeMenu.value==="revenue" || changeMenu.value==="payout") &&
                                                                <>
                                                                    <div style={{display:'flex',alignItems:'center'}}>
                                                                        <CssTextField label={capitalize(changeMenu.value)} autoComplete='off' fullWidth size='small' style={{transform:'scale(0.7) translateX(-15%)'}} onChange={(e)=>{setChangeMenuValue(e.target.value)}}/>
                                                                    </div>
                                                                    {changeMenuValue!=="" && (!changeMenuValue.match(number) || !parseFloat(changeMenuValue)) &&
                                                                    <div style={{display:'flex',alignItems:'center',fontSize:'10px',color:'red',marginLeft:'5%'}}>Invalid {changeMenu.value}</div>}
                                                                </>
                                                        }

                                                        {
                                                            changeMenu.value==="currency" &&
                                                                <>
                                                                    <div style={{display:'flex',alignItems:'center',paddingTop:'5px',height:'30px'}}>
                                                                        <CssTextField autoComplete='off' value={searchCurrency} label={"Search Currency"} variant='standard' fullWidth size='small' style={{transform:'scale(0.7) translateX(-15%)'}} onChange={(e)=>{setSearchCurrency(e.target.value)}}/>
                                                                    </div>
                                                                    <div style={{maxHeight:'155px',overflowY:'auto'}}>

                                                                        {Object.keys(Constants.CURRENCY_LIST).map((currencyItem,currencyIndex)=>{
                                                                            if(searchCurrency==="" || currencyItem.toLowerCase().includes(searchCurrency.toLowerCase()) || Constants.CURRENCY_LIST[currencyItem].toLowerCase().includes(searchCurrency.toLowerCase())){
                                                                                return <div key={currencyIndex} style={{display:'flex',alignItems:'center',marginTop:'10px'}}>
                                                                                            <input type="radio" id={currencyItem} name="conversion_currency" value={currencyItem} onChange={(e)=>{if(e.target.checked){setChangeMenuValue(currencyItem)}}}/>
                                                                                            <label style={{fontSize:'12px',marginTop:'2px'}} htmlFor={currencyItem}>{currencyItem}</label><br/>
                                                                                        </div>
                                                                            }
                                                                            return null;
                                                                        })}
                                                                    </div>
                                                                </>
                                                        }
                                                    </div>
                                                <div style={{height:'35px',borderTop:'1px solid rgb(220,220,220)',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                                    <Button disabled={changeMenuValue==="" || changeMenuLoader} 
                                                        sx={{...Constants.CONTAINEDBUTTON,height:'25px',width:'50px',fontSize:'10px',fontWeight:'600'}} onClick={updateConversions}>
                                                        {changeMenuLoader && <CircularProgress size={'15px'} sx={{color:'white'}}/>}
                                                        {!changeMenuLoader && 'Apply'}
                                                    </Button>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    }
                                    {/* Table HEADERS */}
                                    <div className='statsV2TableHeader' style={selectedConversions.length>0 ? {top:50,transition:'all 0.5s linear'} : {top:0,transition:'all 0s linear'}}>
                                        <div style={{display:'flex',justifyContent:'center',paddingRight:'0%',position:'sticky',left:0}}>           
                                            {
                                                fixedTableHeaders.map((tableHeaderItem,tableHeaderIndex)=>{
                                                    if(!tableHeaderItem.disable && tableHeaderItem.show){
                                                        if(tableHeaderItem.value==='checkbox'){
                                                            return <div className='statsV2TableHeaderData' style={{justifyContent:'center',minWidth:tableHeaderItem.minWidth,width:tableHeaderItem.width}} key={tableHeaderIndex} >
                                                                        <input type='checkbox' checked={selectAllConversions} onChange={(e)=>{if(selectAllConversions){setSelectedConversions([])};setSelectAllConversions(e.target.checked);updateComponent(e);}}/>
                                                                    </div>
                                                        }
                                                        return <div className='statsV2TableHeaderData' style={{minWidth:tableHeaderItem.minWidth,width:tableHeaderItem.width}} key={tableHeaderIndex} onClick={(e)=>{setSorting(e,tableHeaderItem.value)}}>
                                                                    <Resizable
                                                                        id={tableHeaderItem.value}
                                                                        style={{zIndex:(fixedTableHeaders.length-parseInt(tableHeaderIndex))}}
                                                                        size={{width:"100%"}}
                                                                        enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
                                                                        onResizeStop={(e, direction, ref, d) => {
                                                                            var tempTableHeaders = fixedTableHeaders;
                                                                            if(tempTableHeaders[tableHeaderIndex].minWidth + d.width >= varTableHeaders[tableHeaderIndex].minWidth){
                                                                                tempTableHeaders[tableHeaderIndex].minWidth += d.width
                                                                            }
                                                                            else{
                                                                                tempTableHeaders[tableHeaderIndex].minWidth = varTableHeaders[tableHeaderIndex].minWidth
                                                                            }
                                                                            setFixedTableHeaders(tempTableHeaders)
                                                                            updateComponent(e);
                                                                            }}
                                                                            >
                                                                            <div className='overflowText' style={{display:'flex',justifyContent:'center'}}>
                                                                                {tableHeaderItem.label}
                                                                            </div>
                                                                    </Resizable>
                                                                </div>
                                                    }
                                                    return null;
                                                })
                                            }
                                        </div>
                                        <div id = "statsV2TableHeader" style={{paddingLeft:'0%',display:'flex',overflowX:'auto'}} onScroll={(e)=>{document.getElementById('statsV2TableValue').scrollLeft = e.target.scrollLeft;}}>
                                            {
                                                variableTableHeaders.map((tableHeaderItem,tableHeaderIndex)=>{
                                                    if(!tableHeaderItem.disable && tableHeaderItem.show){
                                                        return <div className='statsV2TableHeaderData' style={{minWidth:tableHeaderItem.minWidth,width:tableHeaderItem.width}} key={tableHeaderIndex} onClick={(e)=>{setSorting(e,tableHeaderItem.value,true)}}>
                                                                <Resizable
                                                                    id={tableHeaderItem.value}
                                                                    style={{zIndex:(variableTableHeaders.length-parseInt(tableHeaderIndex))}}
                                                                    size={{width:"95%"}}
                                                                    enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
                                                                    onResizeStop={(e, direction, ref, d) => {
                                                                        var tempTableHeaders = variableTableHeaders;
                                                                        if(tempTableHeaders[tableHeaderIndex].minWidth + d.width >= varTableHeaders[tableHeaderIndex].minWidth){
                                                                            tempTableHeaders[tableHeaderIndex].minWidth += d.width
                                                                        }
                                                                        setVariableTableHeaders(tempTableHeaders)
                                                                        updateComponent(e);
                                                                        }}
                                                                        >
                                                                        <div style={{display:'flex',textAlign:'center',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>                                                        
                                                                            {tableHeaderItem.label}
                                                                        </div>
                                                                </Resizable>
                                                            </div>
                                                    }
                                                    return null;
                                                })
                                            }
                                        </div>
                                    </div>
                                    {/* Table VALUES */}
                                    <div className='statsV2TableValue'>
                                        <div>
                                            {   
                                                Object.keys(response).map((keyItem,keyIndex)=>{
                                                    var height = 40
                                                    if(response[keyItem].payouts && response[keyItem].payouts.length>1){
                                                        height = 40 + (response[keyItem].payouts.length*10)
                                                    }
                                                    if(selectAllConversions){
                                                        var tempSelectedConversion = selectedConversions
                                                        if(!tempSelectedConversion.includes(response[keyItem].id)){
                                                            tempSelectedConversion.push(response[keyItem].id)
                                                            setSelectedConversions(tempSelectedConversion)
                                                        }
                                                    }
                                                    return <div id={'fixedColums'+keyIndex} style={{display:'flex',height}} key={keyIndex}>           
                                                        {
                                                            fixedTableHeaders.map((tableHeaderItem,tableHeaderIndex)=>{
                                                                var dateOptions = { year: 'numeric', month: 'short', day: 'numeric' ,hour:'numeric',minute:'numeric',second:'numeric'};
                                                                if(!tableHeaderItem.disable && tableHeaderItem.show){
                                                                    if(tableHeaderItem.value==='checkbox'){
                                                                        return <div className='statsV2TableValueData' style={{minWidth:tableHeaderItem.minWidth,width:tableHeaderItem.width,maxWidth:tableHeaderItem.width}} key={tableHeaderIndex}>
                                                                                <input checked={selectedConversions.includes(response[keyItem].id)} type='checkbox' onChange={(e)=>{
                                                                                        var tempSelectedConversion = selectedConversions
                                                                                        if(!tempSelectedConversion.includes(response[keyItem].id)){
                                                                                            tempSelectedConversion.push(response[keyItem].id)
                                                                                        }
                                                                                        else{
                                                                                            setSelectAllConversions(false);
                                                                                            tempSelectedConversion = tempSelectedConversion.filter(e=>e!==response[keyItem].id)
                                                                                        }
                                                                                        setSelectedConversions(tempSelectedConversion)
                                                                                        updateComponent(e);
                                                                                    }}/>
                                                                            </div>
                                                                    }
                                                                    if(tableHeaderItem.value==='key'){        
                                                                        return <div className='statsV2TableValueData' style={{minWidth:tableHeaderItem.minWidth,width:tableHeaderItem.width,maxWidth:tableHeaderItem.width}} key={tableHeaderIndex}>
                                                                                    {response[keyItem][tableHeaderItem.value]}
                                                                                </div>
                                                                    }
                                                                    if(tableHeaderItem.value==='created_at'){        
                                                                        return <div className='statsV2TableValueData' style={{textAlign:'center',minWidth:tableHeaderItem.minWidth,width:tableHeaderItem.width,maxWidth:tableHeaderItem.width}} key={tableHeaderIndex}>
                                                                                    {new Date(new Date(response[keyItem][tableHeaderItem.value]).getTime()).toLocaleDateString("en-US", dateOptions)}
                                                                                </div>
                                                                    }
                                                                    return <div className='statsV2TableValueData' style={{justifyContent:'flex-start',minWidth:tableHeaderItem.minWidth,width:tableHeaderItem.width}} key={tableHeaderIndex}>
                                                                        <div className='overflowText' style={{paddingLeft:'3%',paddingRight:'3%'}}>
                                                                            {response[keyItem][tableHeaderItem.value]}
                                                                        </div>
                                                                    </div>
                                                                }
                                                                return null;
                                                            })
                                                        }
                                                    </div>
                                                })   
                                            }
                                        </div>
                                        <div id='statsV2TableValue' className='noscrollbar' style={{display:'flex',flexDirection:'column',overflow:'auto'}} onScroll={(e)=>{document.getElementById('statsV2TableHeader').scrollLeft = e.target.scrollLeft;}}>
                                            {showValues()}
                                        </div>
                                    </div>
                                    {/* Table PAGINATION */}
                                    <div style={{display:'flex',alignItems:'center',justifyContent:"space-between",paddingLeft:'20px',paddingRight:'20px',paddingBottom:'20px',position:'sticky',bottom:0,background:'white'}}>
                                        <div>
                                            <label style={{paddingRight:"5px",fontSize:'14px'}}>Limit</label>
                                            <select value={limit} style={{outline:'none',border:'none'}} onChange={(e)=>{getStatsData(e,e.target.value,1);setLimit(parseInt(e.target.value));setPage(1);}}>
                                                <option value={25}>25</option>
                                                <option value={50}>50</option>
                                                <option value={75}>75</option>
                                                <option value={100}>100</option>
                                                <option value={250}>250</option>
                                                <option value={500}>500</option>
                                            </select>
                                        </div>
                                        <Pagination count={Math.ceil(totalResults/limit)} page={page} onChange={(event,value)=>{getStatsData(event,limit,value);setPage(value);updateComponent(event);window.scrollTo({top: 0,left: 0,behavior: 'smooth'});}} />
                                    </div>
                                </>
                            }
                            {typeof response === 'object' && 
                                response.length===0
                                &&
                                <NoResultsFound/>
                            }
                            {
                                typeof response === 'string' &&
                                <SomeErrorOccured error={response}/>
                            }
                        </>
                    }
                </>
            }
           
        </div>
    )
}

export default ConversionStatistics