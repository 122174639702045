import axios from "axios";
import React, { useEffect, useState } from "react";
import '../billing/insightsManik/insightsCss.css';
import { getLocalStorageValue } from "../billing/insightsManik/util/util";
import annotationPlugin from 'chartjs-plugin-annotation';
import 'chartjs-plugin-datalabels';
import chartTrendline from 'chartjs-plugin-trendline';
import { ManagerIcon, TeamIcon, UpArrow, DownArrow } from '../../data/icons';
import SelectComponent from '../../components/filterV2/selectComponent';
import * as Constants from '../../data/constants';
import './managerStatsCss.css';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineController,
  BarElement,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
  Chart,
} from "chart.js";
import CustomSelect from "./CustomSelect";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineController,
  BarElement,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
);
Chart.register(annotationPlugin)
Chart.register(chartTrendline);

var myChart1 = null;
var myChart2 = null;
var myChart3 = null;
var myChart4 = null;
var myChart5 = null;
var myChart7 = null;
var myChart8 = null;
var managerString = "";
const AdopsSummary = () => {
  const token = getLocalStorageValue('adminPanelLoginData').token;
  const user_id = getLocalStorageValue('adminPanelLoginData').userdata.id;
  const username = getLocalStorageValue('adminPanelLoginData').username;
  const employee_type = getLocalStorageValue('adminPanelLoginData').userdata.employee_type;
  const adopsTeam = JSON.parse(process.env.REACT_APP_ADOPS_TEAM);
  const custom_token = process.env.REACT_APP_CUSTOM_TOKEN
  const [inCompleteData, setIncompleteData] = useState({});
  const [isModalOpen, setisModalOpen] = useState(false);
  const [isModalOpen1, setisModalOpen1] = useState(false);
  const [openCardModel, setOpenCardModel] = useState('');
  const [differenceData, setDifferenceData] = useState({ 'day_wise': {}, 'week_wise': {}, 'month_wise': {} });
  const [clickedDate, setclickedDate] = useState("");
  const [chartData3, setchartData3] = useState({
    date: [],
    data: [],
    color: []
  });
  const [chartData2, setchartData2] = useState({
    date: [],
    data: [],
  });
  const [chartData1, setchartData1] = useState({
    date: [],
    data: [],
  });
  const [channelDataDay, setChannelDataDay] = useState({})
  const [channelDataWeek, setChannelDataWeek] = useState({})
  const [channelDataMonth, setChannelDataMonth] = useState({})
  const [channelCardData, setChannelCardData] = useState({})
  const [totalContribution, setTotalContribution] = useState({})
  const [contribution, setContribution] = useState({});
  const [cardData, setCardData] = useState({ yesterday: "", yesterday_earning: 0, this_week: 0, week_date: "", this_month: 0, month_date: "", yes_diff: 0, week_diff: 0, month_diff: 0 })
  const [trendData3, settrendData3] = useState([]);
  const [trendData2, settrendData2] = useState([]);
  const [trendData1, settrendData1] = useState([]);
  const [maxLen, setMaxLen] = useState("");
  const [submaxLen, setSubMaxLen] = useState("");
  const [filterData, setFilterData] = useState({})
  const [subContribution, setSubContribution] = useState({})
  const [markWeekends, setMarkWeekends] = useState(true);
  const [hideContriChart, setHideContriChart] = useState(false);
  const [singleAMId, setSingleAMId] = useState("");
  const [channelMonthlyEarning, setChannelMonthlyEarning] = useState({})
  const is_gm = employee_type == "general_manager";
  const obj = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec"
  };

  const modalStyle1 = {
    position: "absolute",
    top: '50%',
    left: '50%',
    transform: "translate(-50%, -50%)",
    width: "40%",
    bgcolor: "background.paper",
    overflowY: "auto",
    boxShadow: 24,
    padding: "0",
    borderRadius: "5px",
    border: 'none',
    maxHeight: '80%',
    scrollBarWidth: 'none'
  };

  const modalStyle = {
    position: "absolute",
    top: '30rem',
    left: '70rem',
    transform: "translate(-50%, -50%)",
    width: "18%",
    bgcolor: "background.paper",
    overflowY: "auto",
    boxShadow: 24,
    padding: "5px",
    borderRadius: "5px",
    border: 'none'
  };
  const dayOptions = [
    { value: 7, label: 'Last 7 Days' },
    { value: 14, label: 'Last 14 Days' },
    { value: 30, label: 'Last 30 Days' },
    { value: 45, label: 'Last 45 Days' },
    { value: 60, label: 'Last 60 Days' }]

  const [days, setDays] = useState(30);
  const contriOptions = [
    { value: 7, label: 'Last 7 Days' },
    { value: 14, label: 'Last 14 Days' }
  ]

  const [contriDays, setContriDays] = useState(7);
  const [progressMonth, setProgressMonth] = useState(new Date().getMonth() + 1)
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];
  const progressMonthOptions = [];

  for (let i = currentMonth; i >= 0; i--) {
    progressMonthOptions.push({ label: monthNames[i], value: i + 1 });
  }

  const [manager, setManager] = useState([]);
  const [channel, setChannel] = useState("");
  const [optionsManagersList, setOptionsManagerList] = useState([])
  const channelList = [];
  const domainMap = {};
  adopsTeam.map((item => {
    channelList.push({ label: item.channel, value: item.channel })
    domainMap[item.channel] = item.backend;
  }
  ))
  const [teamData, setTeamData] = useState({})

  var filtersList = channel == "" ? [
    { label: 'Channel', icon: TeamIcon, paramKey: 'channel', value: 'channel_id', type: 'select', hideValueInLabel: true, multiple: false, options: channelList },
  ] :
    [
      { label: 'Channel', icon: TeamIcon, paramKey: 'channel', value: 'channel_id', type: 'select', hideValueInLabel: true, multiple: false, options: channelList },
      { label: 'Account Manager', icon: ManagerIcon, paramKey: 'accountManager', value: 'manager_id', type: 'select', hideValueInLabel: true, multiple: false, options: optionsManagersList },
    ]
  for (let i = 0; i < manager.length; i++) {
    if (i === manager.length - 1) managerString += manager[i];
    else managerString += manager[i] + ",";
  }

  const [targetRevenue, setTargetRevenue] = useState(0);
  const [teamTarget, setteamTarget] = useState(0);

  const swappedMap = new Map();
  for (let i = 0; i < optionsManagersList.length; i++) {
    if (optionsManagersList[i]) {
      swappedMap.set((optionsManagersList[i].value).toString(), optionsManagersList[i].label.split(' - ')[0]);
    }
  }
  const [targetMap, setTargetMap] = useState({})
  const [managerTargetMap, setManagerTargetMap] = useState({})
  const [allChannelManager, setAllChannelManager] = useState({})
  var target = 0;
  useEffect(() => {
    channelList.map((channel, index) => {
      allChannelManager[channel.value] = {}
      axios.get(
        "".concat(domainMap[channel.value], "/api_am/get_team/"),
        {
          // headers: { Authorization: `${token}`, },
          params: { user_id: user_id, is_gm: employee_type === "general_manager", custom_token: custom_token }
        }
      ).then((response) => {
        targetMap[channel.value] = response.data.team_target
        teamData[channel.value] = response.data
        target += response.data.team_target
        var d = response.data.result
        setTargetMap(targetMap)
        setTeamData(teamData)
        var singleAM = ''
        for (let i = 0; i < d.length; i++) {
          if (d[i].active) {
            if (d[i].sub_team && d[i].team_members.length + 1 == d.length) {
              singleAM = d[i].user_id
            }
            allChannelManager[channel.value][d[i].user_id] = { 'username': d[i].username, 'target': d[i].target_revenue, 'other_manager': d[i].other_manager }
          }
        }
        if (singleAM != "") {
          for (const key in allChannelManager[channel.value]) {
            allChannelManager[channel.value][key].other_manager = false
            if (key == singleAM) {
              allChannelManager[channel.value][key].other_manager = true
              allChannelManager[channel.value][key].singleAM = true
            }
          }
        }
        setTargetRevenue(target)
        setteamTarget(target)
      })
    })
  }, [])

  useEffect(() => {
    if (manager == "")
      sumAllChannel()
    else
      getData(channel, new Map(), new Map(), new Map(), new Map(), manager, days)
  }, [days])

  useEffect(() => {
    getMonthlyEarning()
  }, [progressMonth])


  const getManager = (channel) => {
    swappedMap.clear()
    let managerlist = []
    const targetMap = {};
    var singleAMId = ''
    var teamMembers = teamData[channel].result
    teamMembers.sort((a, b) => b.target_revenue == a.target_revenue ? a.user_id - b.user_id : b.target_revenue - a.target_revenue)
    for (var i in teamMembers) {
      targetMap[teamMembers[i].user_id] = teamMembers[i].target_revenue
      if (teamMembers[i].sub_team && teamMembers[i].team_members.length + 1 == teamMembers.length) {
        singleAMId = teamMembers[i].user_id
        setSingleAMId(singleAMId)
      }
      else {
        if (teamMembers[i].active)
          managerlist.push({ label: teamMembers[i].username ? teamMembers[i].username + " - " + teamMembers[i].user_id : 'user' + " - " + teamMembers[i].user_id, value: teamMembers[i].user_id })
        swappedMap.set((teamMembers[i].user_id).toString(), teamMembers[i].username ? teamMembers[i].username : `user-${teamMembers[i].user_id}`);
      }
    }
    if (singleAMId == '')
      setSingleAMId('')
    setOptionsManagerList(managerlist)
    setManagerTargetMap(targetMap)
    getContribution(channel, targetMap, singleAMId, false)
  };


  const overrideSetFilterData = async (value, filterItem) => {
    if (filterItem.value === 'manager_id') {
      var __list = []
      if (value.value) {
        __list.push(value.value)
      }
      setManager(__list)
      var target = 0;
      var teamMembers = teamData[channel].result
      for (let i = 0; i < teamMembers.length; i++) {
        var user = (teamMembers[i]["user_id"])
        if (__list.includes(user))
          target += teamMembers[i]["target_revenue"]
      }
      if (__list.length == 0) {
        target = targetMap[channel]
        setSubContribution({})
        setContribution(contribution)
        setHideContriChart(false)
      }
      setTargetRevenue(target)
      setFilterData({ ...filterData, [filterItem.value]: value })
      getData(channel, new Map(), new Map(), new Map(), new Map(), __list, Math.max(days, contriDays))
      checkSubContribution(__list)
    }
    if (filterItem.value === 'channel_id') {
      if (filterData[filterItem.value] && filterData[filterItem.value].value == value.value) {
        value = { label: '', value: undefined }
        setChannel("")
        setTargetRevenue(teamTarget)
        setContribution(totalContribution)
      }
      else {
        setChannel(value.value)
        setTargetRevenue(targetMap[value.value])
        getManager(value.value)
      }
      if (filterData.hasOwnProperty("manager_id")) {
        setManager([])
        setHideContriChart(false)
        setSubContribution({})
        setFilterData({ ...filterData, "manager_id": { value: undefined, label: '' }, [filterItem.value]: value })
      }
      else {
        setFilterData({ ...filterData, [filterItem.value]: value })
      }
    }
  }
  const checkSubContribution = (manager, days) => {
    const team = teamData[channel].result
    for (let i = 0; i < team.length; i++) {
      if (team[i].user_id == manager[0]) {
        if (team[i].sub_team) {
          getContribution(channel, managerTargetMap, manager[0], true, days)
          setHideContriChart(false)
        }
        else if (team[i].other_manager && team[i].managed_by[0] != singleAMId) {
          getContribution(channel, managerTargetMap, team[i].managed_by[0], true, days)
          setHideContriChart(true)
        }
        else {
          setSubContribution({})
          setHideContriChart(false)
        }
      }
    }
  }

  const getContribution = (channel, managerTargetMap, singleAMId, subContrib, days) => {
    const API =
      "".concat(domainMap[channel], "/api_am/contribution/");
    axios.get(API, {
      headers: {
        Authorization: `${token}`,
      },
      params: { user_id: user_id, manager_id: singleAMId, is_gm: employee_type === "general_manager", days: days ? days : contriDays }
    })
      .then((response) => {
        const contribution = {
        }
        const data = response.data;
        var max = 0;
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            var user = swappedMap.get(key.toString())
            contribution[user] = { date: [], data: [] };
            const userData = data[key];
            if (userData.length > max) {
              max = userData.length;
            }
            const target = managerTargetMap[key]
            for (let i = 0; i < userData.length; i++) {
              contribution[user].date.push(
                new Date(userData[i].date).getDate() + '-' + obj[new Date(userData[i].date).getMonth() + 1]
              )
              contribution[user].data.push(
                userData[i].sum / target * 100
              )
            }
          }
        }
        if (subContrib) {
          setSubContribution(contribution)
        }
        else {
          console.log(contribution)
          setContribution(contribution)
        }
      });
  }

  useEffect(() => {
    if (Object.keys(channelDataDay).length > 0 && Object.keys(channelDataWeek).length > 0 && Object.keys(channelDataMonth).length > 0) {
      if (channel === "" && channelDataDay.total && channelDataMonth.total && channelDataWeek.total && channelCardData.total) {
        setchartData3(channelDataDay.total);
        setchartData2(channelDataWeek.total);
        setchartData1(channelDataMonth.total);
        setCardData(channelCardData.total)
        const movingAvgData3 = calculateMovingAverage(channelDataDay.total.data, 3);
        const movingAvgData2 = calculateMovingAverage(channelDataWeek.total.data, 2);
        const movingAvgData1 = calculateMovingAverage(channelDataMonth.total.data, 2);
        settrendData3(movingAvgData3)
        settrendData2(movingAvgData2)
        settrendData1(movingAvgData1)
        setTotalContribution(contribution)
      }
      if (channel != "" && channelDataDay[channel] && channelDataMonth[channel] && channelDataWeek[channel] && channelCardData[channel]) {
        setchartData3(channelDataDay[channel]);
        setchartData2(channelDataWeek[channel]);
        setchartData1(channelDataMonth[channel]);
        setCardData(channelCardData[channel])
        const movingAvgData3 = calculateMovingAverage(channelDataDay[channel].data, 3);
        const movingAvgData2 = calculateMovingAverage(channelDataWeek[channel].data, 2);
        const movingAvgData1 = calculateMovingAverage(channelDataMonth[channel].data, 2);
        settrendData3(movingAvgData3)
        settrendData2(movingAvgData2)
        settrendData1(movingAvgData1)
      }
    }
  }, [channelDataDay, channelDataWeek, channelDataMonth, channelCardData, channel])

  useEffect(() => {
    if (chartData3.data && chartData2.data && chartData1.data) {
      const movingAvgData3 = calculateMovingAverage(chartData3.data, 3);
      const movingAvgData2 = calculateMovingAverage(chartData2.data, 2);
      const movingAvgData1 = calculateMovingAverage(chartData1.data, 2);
      settrendData3(movingAvgData3)
      settrendData2(movingAvgData2)
      settrendData1(movingAvgData1)
    }
  }, [chartData3, chartData2, chartData1])

  const sumAllChannel = async () => {
    const chartData3 = {
      date: [],
      data: [],
      color: []
    };
    const chartData2 = {
      date: [],
      data: [],
    };
    const chartData1 = {
      date: [],
      data: [],
    };

    const cardData = {
      yesterday: "",
      yesterday_earning: 0,
      this_week: 0,
      week_date: "",
      this_month: 0,
      month_date: "",
      prev_yes: 0,
      prev_week: 0,
      prev_month: 0
    }
    const totalChartData1 = new Map();
    const totalChartData2 = new Map();
    const totalChartData3 = new Map();
    const fetchData = channelList.map(alias => getData(alias.value, totalChartData1, totalChartData2, totalChartData3, cardData, []));
    const responses = await Promise.all(fetchData);
    const fetchStatus = channelList.map(alias => getStatus(alias.value));
    // const fetchMonthlyEarning = channelList.map(alias => getMonthlyEarning(alias.value));
    const results = await Promise.all(fetchStatus);
    const fetchStatus2 = channelList.map(alias => getDifference(alias.value));
    const results2 = await Promise.all(fetchStatus2);
    const data = responses[0]
    var dateMap = data[0]
    var weekMap = data[1]
    var monthMap = data[2]
    var cardData1 = data[3]
    for (let [key, value] of dateMap) {
      chartData3.date.push(new Date(key).getDate() + '-' + obj[new Date(key).getMonth() + 1])
      chartData3.data.push(value)
      markWeekends ?
        chartData3.color.push(new Date(key).getDay() == 0 || new Date(key).getDay() == 6 ? "#DE9B89" : "#A9C779")
        :
        chartData3.color.push("#A9C779")
    }
    for (let [key, value] of weekMap) {
      chartData2.date.push(key)
      chartData2.data.push(value)
    }
    for (let [key, value] of monthMap) {
      chartData1.date.push(key)
      chartData1.data.push(value)
    }
    var totalCardData = {
      yesterday: cardData1.yesterday,
      yesterday_earning: cardData1.yesterday_earning,
      this_week: cardData1.this_week,
      week_date: cardData1.week_date,
      this_month: cardData1.this_month,
      month_date: cardData1.month_date,
      yes_diff: cardData1.yesterday_earning - cardData1.prev_yes,
      week_diff: cardData1.this_week - cardData1.prev_week,
      month_diff: cardData1.this_month - cardData1.prev_month
    }
    setChannelDataDay((prevData) => ({ ...prevData, "total": chartData3 }))
    setChannelDataWeek((prevData) => ({ ...prevData, "total": chartData2 }))
    setChannelDataMonth((prevData) => ({ ...prevData, "total": chartData1 }))
    setChannelCardData((prevData) => ({ ...prevData, "total": totalCardData }))
    let newCompiledData = {};
    results.forEach((resp, index) => {
      let channel = resp[0];
      let data = resp[1];
      data.forEach(({ date, users }) => {
        if (!date)
          date = new Date(new Date().setDate(new Date().getDate() - 1))
        else
          date = new Date(date)
        var date1 = date.getDate() + '-' + obj[date.getMonth() + 1]
        if (!newCompiledData[date1]) {
          newCompiledData[date1] = {};
        }
        if (!users)
          newCompiledData[date1][channel] = data;
        else
          newCompiledData[date1][channel] = users;
      });
    });
    setIncompleteData(newCompiledData);
    let newCompiledData2 = { 'day_wise': {}, 'month_wise': {}, 'week_wise': {} };
    results2.forEach((resp) => {
      let channel = resp[0];
      let data = resp[1];
      if (data) {
        newCompiledData2['day_wise'][channel] = data['day_wise']
        newCompiledData2['week_wise'][channel] = data['week_wise']
        newCompiledData2['month_wise'][channel] = data['month_wise'];
      }
    });
    setDifferenceData(newCompiledData2)
  }


  const getStatus = async (alias) => {
    let data;
    const API_ENDPOINT = "".concat(domainMap[alias], "/api_am/daily_status/")
    await axios
      .get(API_ENDPOINT, {
        headers: {
          // Authorization: `${token}`,
        },
        params: {
          custom_token: custom_token,
        }
      })
      .then((response) => {
        data = response.data.result;

      })
      .catch((err) => {
        console.log(err)
      })
    return [alias, data];
  }

  const getDifference = async (alias) => {
    let data;
    const API_ENDPOINT = "".concat(domainMap[alias], "/api_am/get_difference/")
    await axios
      .get(API_ENDPOINT, {
        headers: {
          // Authorization: `${token}`,
        },
        params: {
          custom_token: custom_token,
        }
      })
      .then((response) => {
        data = response.data;
      })
      .catch((err) => {
      })
    return [alias, data];
  }

  const getMonthlyEarning = async () => {
    let monthlyEarning = {}
    const apiRequests = adopsTeam.map((alias) => {
      const API_ENDPOINT = "".concat(alias.backend, "/api_am/get_monthly_value/")
      return axios
        .get(API_ENDPOINT, {
          headers: {
            // Authorization: `${token}`,
          },
          params: {
            custom_token: custom_token,
            is_gm: employee_type === "general_manager",
            month: progressMonth
          }
        })
        .then((response) => {
          const data = response.data;
          monthlyEarning[alias.channel] = data
        })
        .catch((err) => {
          monthlyEarning[alias.channel] = {}
        })
    })
    await Promise.all(apiRequests);
    setChannelMonthlyEarning(monthlyEarning)
  }

  const isDataIncomplete = (val, type) => {
    const date = val;
    if (inCompleteData[date]) {
      for (const key in inCompleteData[date]) {
        if (manager != '' && key === channel) {
          for (const item in inCompleteData[date][channel]) {
            let user = inCompleteData[date][channel][item]['user_id'];
            if (manager.includes(user.toString()))
              return true;
          }
          return false;
        }
        else if ((type === "all" || type.includes(key)) && inCompleteData[date][key] && inCompleteData[date][key].length > 0)
          return true;
      }
      return false;
    }
    return false;
  }

  const handleContriDayChange = (days) => {
    setContriDays(days)
    if (channel == "")
      changeContriDays(days);
    else {
      getContribution(channel, managerTargetMap, singleAMId, false, days);
      checkSubContribution(manager, days)
    }
  }

  const getData = async (alias, totalChartData1, totalChartData2, totalChartData3, cardData, manager, contri) => {
    const API_ENDPOINT =
      "".concat(domainMap[alias], "/api_am/data/")
    await axios
      .get(API_ENDPOINT, {
        headers: {
          // Authorization: `${token}`,
        },
        params: {
          user_id: user_id,
          manager_id: manager.toString() == user_id ? "" : manager.toString(),
          is_gm: employee_type === "general_manager",
          custom_token: custom_token,
          days: contri ? contri : days
        }
      })
      .then((response) => {
        const yes = response.data["yesterday"]
        const yesterday_earn = response.data["yesterday_earning"]
        const this_week_earn = response.data["this_week_earning"]
        const this_month_earn = response.data["this_month_earning"]
        const month_date = response.data["month"]
        const week_date = response.data["this_week"]
        const prev_yesterday = response.data["prev_yes_earning"]
        const prev_week = response.data["last_week_earning"]
        const prev_month = response.data["prev_month_earning"]
        const yesterday_diff = yesterday_earn - prev_yesterday
        const week_diff = this_week_earn - prev_week
        const month_diff = this_month_earn - prev_month

        const data3 = response.data["last_30_days"];
        const contribution1 = { date: [], data: [] };
        const target = targetMap[alias];
        const dayChart = { date: [], data: [], color: [] }
        const weekChart = { date: [], data: [] }
        const monthChart = { date: [], data: [] }
        const cardValues = {
          yesterday: "",
          yesterday_earning: 0, this_week: 0, week_date: "",
          this_month: 0, month_date: "", yes_diff: 0, week_diff: 0, month_diff: 0
        }
        for (let i = 0; i < data3.length; i++) {
          data3[i].sum = Math.round(data3[i].sum)
          var date = data3[i].date
          dayChart.date.push(
            new Date(date).getDate() + '-' + obj[new Date(date).getMonth() + 1]
          )
          dayChart.data.push(
            data3[i].sum
          )
          markWeekends ?
            dayChart.color.push(
              new Date(date).getDay() == 0 || new Date(date).getDay() == 6 ? "#DE9B89" : "#A9C779"
            ) :
            dayChart.color.push("#A9C779")
          if (!totalChartData1.has(date)) {
            totalChartData1.set(date, data3[i].sum)
          }
          else {
            totalChartData1.set(date, totalChartData1.get(date) + data3[i].sum)
          }
          if (Math.floor((new Date().getTime() - new Date(date).getTime()) / (1000 * 3600 * 24)) <= contriDays) {
            contribution1.date.push(
              new Date(date).getDate() + '-' + obj[new Date(date).getMonth() + 1]
            )
            contribution1.data.push(
              data3[i].sum / target * 100
            )
          }
        }

        const data2 = response.data["last_12_weeks"];
        for (let i = 0; i < data2.length; i++) {
          data2[i].data = Math.round(data2[i].data)
          var date = obj[data2[i].month] + "'W" + data2[i].week;
          weekChart.date.push(
            date
          )
          weekChart.data.push(
            data2[i].data
          )
          if (!totalChartData2.has(date)) {
            totalChartData2.set(date, data2[i].data)
          }
          else {
            totalChartData2.set(date, totalChartData2.get(date) + data2[i].data)
          }
        }
        const data1 = response.data["last_12_months"];
        for (let i = 0; i < data1.length; i++) {
          data1[i].sum = Math.round(data1[i].sum)
          var date = obj[data1[i].month] + "'" + data1[i].year.toString().substr(2)
          var num = data1[i].c ? data1[i].sum / data1[i].c : data1[i].sum
          monthChart.date.push(
            date
          )
          monthChart.data.push(
            num
          )
          if (!totalChartData3.has(date)) {
            totalChartData3.set(date, num)
          }
          else {
            totalChartData3.set(date, totalChartData3.get(date) + num)
          }
        }

        cardData["yesterday_earning"] += Math.round(yesterday_earn)
        cardData["this_week"] += Math.round(this_week_earn)
        cardData["this_month"] += Math.round(this_month_earn)
        cardData["yesterday"] = yes
        cardData["week_date"] = week_date
        cardData["month_date"] = month_date
        cardData["prev_yes"] += Math.round(prev_yesterday)
        cardData["prev_month"] += Math.round(prev_month)
        cardData["prev_week"] += Math.round(prev_week)

        cardValues.yesterday_earning = yesterday_earn
        cardValues.this_week = this_week_earn
        cardValues.this_month = this_month_earn
        cardValues.yesterday = yes
        cardValues.week_date = week_date
        cardValues.month_date = month_date
        cardValues.yes_diff = yesterday_diff
        cardValues.month_diff = month_diff
        cardValues.week_diff = week_diff
        if (manager != "") {
          setchartData3(dayChart)
          setchartData2(weekChart)
          setchartData1(monthChart)
          setCardData(cardValues)
        }
        else {
          setChannelDataDay((prevData) => ({ ...prevData, [alias]: dayChart }))
          setChannelDataWeek((prevData) => ({ ...prevData, [alias]: weekChart }))
          setChannelDataMonth((prevData) => ({ ...prevData, [alias]: monthChart }))
          setChannelCardData((prevData) => ({ ...prevData, [alias]: cardValues }))
          if (channel == "")
            setContribution((prevContri) => ({ ...prevContri, [alias]: contribution1 }))
        }
      })
    return [totalChartData1, totalChartData2, totalChartData3, cardData];
  }


  const changeContriDays = async (value) => {
    let totalContribution = {}
    let totalContriNum = {}

    const apiRequests = adopsTeam.map((alias) => {
      const API_ENDPOINT = "".concat(alias.backend, "/api_am/data/");
      return axios.get(API_ENDPOINT, {
        headers: {
          // Authorization: `${token}`,
        },
        params: {
          user_id: user_id,
          manager_id: manager.toString() == user_id ? "" : manager.toString(),
          is_gm: employee_type === "general_manager",
          custom_token: custom_token,
          days: value
        }
      })
        .then((response) => {
          const data3 = response.data["last_30_days"];
          const contribution1 = { date: [], data: [] };
          const target = targetMap[alias.channel];
          for (let i = 0; i < data3.length; i++) {
            data3[i].sum = Math.round(data3[i].sum);
            var date = data3[i].date;

            contribution1.date.push(
              new Date(date).getDate() + '-' + obj[new Date(date).getMonth() + 1]
            );
            contribution1.data.push(
              data3[i].sum / target * 100
            );
          }
          totalContribution[alias.channel] = contribution1;
        });
    });
    await Promise.all(apiRequests);
    setContribution(totalContribution)
  }

  const calculateMovingAverage = (data, windowSize) => {
    let movingAvg = [];
    for (let i = 0; i < data.length - windowSize + 1; i++) {
      let sum = 0;
      for (let j = 0; j < windowSize; j++) {
        sum += data[i + j];
      }
      movingAvg.push(sum / windowSize);
    }
    return movingAvg;
  };

  function clickableScales(canvas, click) {
    let resetCoordinates = canvas.getBoundingClientRect();
    const x = click.clientX - resetCoordinates.left;
    const y = click.clientY - resetCoordinates.top;

    const xScale = myChart3.scales.x;
    const tickCount = xScale.ticks.length;
    const tickWidth = xScale.width / tickCount;

    for (let i = 0; i < tickCount; i++) {
      const tickLeft = xScale.left + i * tickWidth;
      const tickRight = tickLeft + tickWidth;
      if (y >= xScale.top && y <= xScale.bottom) {
        if (x >= tickLeft && x <= tickRight) {
          const label = myChart3.data.labels[i];
          if (isDataIncomplete(label, channel == "" ? 'all' : [channel])) {
            setclickedDate(label)
            setisModalOpen(true);
            break;
          }
        }
      }
    }
  }
  function clickableScalesContri(canvas, click) {
    let resetCoordinates = canvas.getBoundingClientRect();
    const x = click.clientX - resetCoordinates.left;
    const y = click.clientY - resetCoordinates.top;

    const xScale = myChart4.scales.x;
    const tickCount = xScale.ticks.length;
    const tickWidth = xScale.width / tickCount;

    for (let i = 0; i < tickCount; i++) {
      const tickLeft = xScale.left + i * tickWidth;
      const tickRight = tickLeft + tickWidth;
      if (y >= xScale.top && y <= xScale.bottom) {
        if (x >= tickLeft && x <= tickRight) {
          const label = myChart4.data.labels[i];
          if (isDataIncomplete(label, channel == "" ? 'all' : [channel])) {
            setclickedDate(label)
            setisModalOpen1(true);
            break;
          }
        }
      }
    }
  }
  function getProgressColor(progress) {
    let color;
    if (progress < 70) {
      color = "#cc4125";
    } else if (progress < 90) {
      color = "#e6b8af";
    } else if (progress < 110) {
      color = "#d9ead3";
    } else if (progress < 120) {
      color = "#b6d7a8";
    } else {
      color = "#93c47d";
    }
    return color;
  }
  useEffect(() => {
    try {
      var max3 = 0;
      for (const key in chartData3) {
        if (chartData3.hasOwnProperty(key)) {
          if (key != "date") {
            const array = chartData3[key];
            for (let i = 0; i < array.length; i++) {
              if (array[i] > max3) max3 = array[i];
            }
          }
        }
      }
      var max2 = 0;
      for (const key in chartData2) {
        if (chartData2.hasOwnProperty(key)) {
          if (key != "date") {
            const array = chartData2[key];
            for (let i = 0; i < array.length; i++) {
              array[i] = Number(array[i])
              if (array[i] > max2) max2 = array[i];
            }
          }
        }
      }
      var max1 = 0;
      for (const key in chartData1) {
        if (chartData1.hasOwnProperty(key)) {
          if (key != "date") {
            const array = chartData1[key];
            for (let i = 0; i < array.length; i++) {
              array[i] = Number(array[i])
              if (array[i] > max1) max1 = array[i];
            }
          }
        }
      }
      var maxDateLength = 0;
      var maxLen = ""
      for (const key in contribution) {
        if (contribution.hasOwnProperty(key)) {
          if (contribution[key].date.length > maxDateLength) {
            maxLen = key;
            maxDateLength = contribution[key].date.length
          }
        }
      }
      var maxDateLength1 = 0;
      var submaxLen = ""
      for (const key in subContribution) {
        if (subContribution.hasOwnProperty(key)) {
          if (subContribution[key].date.length > maxDateLength1) {
            submaxLen = key;
            maxDateLength1 = subContribution[key].date.length
          }
        }
      }
      var ctx4;
      var ctx5;
      const canvas3 = document.getElementById("chart3");
      const ctx3 = canvas3.getContext("2d");
      canvas3.addEventListener('click', (e) => { clickableScales(canvas3, e) })
      const canvas2 = document.getElementById("chart2");
      const ctx2 = canvas2.getContext("2d");
      const canvas1 = document.getElementById("chart1");
      const ctx1 = canvas1.getContext("2d");
      const canvas7 = document.getElementById("chart7");
      const ctx7 = canvas7.getContext("2d");
      const canvas8 = document.getElementById("chart8");
      const ctx8 = canvas8.getContext("2d");

      var contriDatasets = [];
      var subContriDatasets = [];
      var progressData = { 'label': [], 'data': [], 'color': [], 'value': [], 'target': [] }
      var managerProgressData = { 'label': [], 'data': [], 'color': [], 'value': [], 'target': [] }
      var curr_month_days = new Date().getDate() - 1;

      if (!hideContriChart && Object.keys(contribution).length > 0) {
        const canvas4 = document.getElementById("chart4");
        ctx4 = canvas4.getContext("2d");
        canvas4.addEventListener('click', (e) => { clickableScalesContri(canvas4, e) })
      }
      if (Object.keys(subContribution).length > 0) {
        const canvas5 = document.getElementById("chart5");
        ctx5 = canvas5.getContext("2d");
      }
      var bg_colors2 = chartData2["date"].map((item, ind) => ind == chartData2["date"].length - 1 ? "#4A80BD" : "#A9C779");
      var bg_colors1 = chartData1["date"].map((item, ind) => ind == chartData1["date"].length - 1 ? "#0E6BA8" : "#A9C779");
      var bg_colors4 = ["#DCBF00", "#9E4770", "#70A0AF", "#A9C779", "#6F4778", "#69B19C", "#EB8A90", "#EAAC8B", "#C3979F", "#E07A5F"]
      let i = 0;
      const list = Object.keys(contribution).sort((a, b) => targetMap[a] ? targetMap[b] - targetMap[a] : managerTargetMap[b] - managerTargetMap[a])
      for (const key in list) {
        contriDatasets.push(
          {
            type: "bar",
            label: list[key],
            data: contribution[list[key]].data,
            backgroundColor: bg_colors4[i % 10],
            yAxisID: "B",
            borderRadius: 3,
            datalabels: { color: "#252525" },
            user_id: Array.from(swappedMap.keys()).find(key1 => swappedMap.get(key1) === list[key])
          })
        i++;
      }
      for (const key in subContribution) {
        subContriDatasets.push(
          {
            type: "bar",
            label: key,
            data: subContribution[key].data,
            backgroundColor: bg_colors4[i % 10],
            yAxisID: "B",
            borderRadius: 3,
            datalabels: { color: "#252525" },
            user_id: Array.from(swappedMap.keys()).find(key1 => swappedMap.get(key1) === key)
          })
        i++;
      }
      channelList.map((ch) => {
        const name = ch.label;
        let team_earning = channelMonthlyEarning[name] ? channelMonthlyEarning[name].team_earning ? channelMonthlyEarning[name].team_earning : 0 : 0
        let channelTarget = targetMap[name]
        var progress = team_earning / channelTarget * 100
        if (channel == "" || channel == name) {
          progressData.label.push(name)
          progressData.data.push(progress)
          progressData.color.push(getProgressColor(progress))
          progressData.value.push(team_earning)
          progressData.target.push(channelTarget)

          let managers_earning = channelMonthlyEarning[name] ? channelMonthlyEarning[name].manager_wise ? channelMonthlyEarning[name].manager_wise : 0 : 0
          for (const key in managers_earning) {
            if (key in allChannelManager[name] && !allChannelManager[name][key].singleAM) {
              let earning = managers_earning[key]
              let manager_progress = earning / allChannelManager[name][key].target * 100
              managerProgressData.label.push(allChannelManager[name][key].username)
              managerProgressData.data.push(manager_progress)
              managerProgressData.color.push(getProgressColor(manager_progress))
              managerProgressData.value.push(earning)
              managerProgressData.target.push(allChannelManager[name][key].target)
            }
          }
        }
      })
      let sortedIndices = progressData.data.map((value, index) => ({ value, index })).sort((a, b) => b.value - a.value).map(({ index }) => index);
      progressData = {
        label: sortedIndices.map(index => progressData.label[index]),
        data: sortedIndices.map(index => progressData.data[index]),
        color: sortedIndices.map(index => progressData.color[index]),
        value: sortedIndices.map(index => progressData.value[index]),
        target: sortedIndices.map(index => progressData.target[index])
      };
      let sortedIndices1 = managerProgressData.data.map((value, index) => ({ value, index })).sort((a, b) => b.value - a.value).map(({ index }) => index);
      managerProgressData = {
        label: sortedIndices1.map(index => managerProgressData.label[index]),
        data: sortedIndices1.map(index => managerProgressData.data[index]),
        color: sortedIndices1.map(index => managerProgressData.color[index]),
        value: sortedIndices1.map(index => managerProgressData.value[index]),
        target: sortedIndices1.map(index => managerProgressData.target[index])
      };
      if (myChart1) {
        myChart1.clear();
        myChart1.destroy();
      }
      if (myChart2) {
        myChart2.clear();
        myChart2.destroy();
      }
      if (myChart3) {
        myChart3.clear();
        myChart3.destroy();
      }
      if (myChart7) {
        myChart7.clear();
        myChart7.destroy();
      }
      if (myChart8) {
        myChart8.clear();
        myChart8.destroy();
      }
      if (!hideContriChart && Object.keys(contribution).length > 0) {
        if (myChart4) {
          myChart4.clear();
          myChart4.destroy();
        }
      }
      if (Object.keys(subContribution).length > 0) {
        if (myChart5) {
          myChart5.clear();
          myChart5.destroy();
        }
      }

      myChart3 = new ChartJS(ctx3, {
        type: "bar",
        data: {
          labels: chartData3.date,
          datasets: [
            {
              type: 'line',
              label: 'Trendline',
              data: [null, null, ...trendData3],
              borderColor: "#4a4a4a",
              borderWidth: 2,
              backgroundColor: "#4a4a4a",
              yAxisID: "B",
              fill: false,
              datalabels: { display: false },
              pointRadius: 0,
            },
            {
              type: "bar",
              label: "Earning Achieved",
              data: chartData3.data,
              backgroundColor: chartData3.color,
              yAxisID: "B",
              borderRadius: 2,
              datalabels: { color: "#252525" }
            },


          ],
        },
        options: {
          maintainAspectRatio: false,
          layout: {
            padding: {
              left: 20,
              right: 20,
              top: 30,
              bottom: 10,
            },
          },
          responsive: true,
          scales: {
            x: {
              grid: {
                display: false,
              },
              ticks: {
                color: "#252525",
                font: function (context) {
                  let val = context.tick.label;
                  if (isDataIncomplete(val, channel == "" ? 'all' : [channel])) {
                    return {
                      size: 12.8,
                      weight: 'bold'
                    };
                  } else {
                    return {
                      size: 12.8,
                      weight: 'normal'
                    };
                  }
                },
              },
            },
            y: {
              display: false,
            },
            B: {
              grid: {
                display: false,
              },

              min: 0,
              max: Math.max(targetRevenue, max3) + 200,
              ticks: {
                color: "#252525",
                callback: function (label, index, labels) {
                  return Math.round(label / 100) * 100;
                  // if (label == 0) return label;
                  // else if (label <= 1000) return Math.round(label);
                  // else if (label < 100000)
                  //   return Math.round(label / 1000) + "k";
                  // else return Math.round(label / 100000) + "L";
                },
                font: {
                  // family: "kanit,sans-serif",
                  size: 12.8,
                  weight: "normal",
                },
                stepSize: Math.round(max3 / 2),
              },
            },
          },

          elements: {
            bar: {
              borderWidth: 0,
            },
          },
          plugins: {
            annotation: {
              annotations: {
                line1: {
                  id: 'targetLine',
                  type: 'line',
                  mode: 'horizontal',
                  borderColor: '#FF0000',
                  borderWidth: 2,
                  color: 'black',
                  scaleID: 'B',
                  value: targetRevenue,
                  borderDash: [7, 5],
                  label: {
                    display: false,
                    backgroundColor: "#f0f0f0",
                    drawTime: 'afterDatasetsDraw',
                    content: `Target Earning: ${targetRevenue}`,
                    borderWidth: 1,
                    borderColor: "",
                    color: "#252525",
                  },
                  enter({ element }, event) {
                    element.label.options.display = true;
                    return true;
                  },
                  leave({ element }, event) {
                    element.label.options.display = false;
                    return true;
                  }
                },
              }
            },
            tooltip: {
              backgroundColor: "#f0f0f0",
              titleColor: "#252525",
              borderWidth: 1,
              borderColor: "",
              bodyColor: "#252525",
            },
            legend: {
              display: false
            },
            datalabels: {
              anchor: 'end',
              align: 'top',
              formatter: Math.round,
              font: { size: days == 45 || days == 60 ? 10.5 : 12 }
            },
          }
        },
      });
      myChart2 = new ChartJS(ctx2, {
        type: "bar",
        data: {
          labels: chartData2.date,
          datasets: [
            {
              type: 'line',
              label: 'Trendline',
              data: [null, ...trendData2, null],
              borderColor: "#4a4a4a",
              borderWidth: 2,
              backgroundColor: "#4a4a4a",
              yAxisID: "B",
              fill: false,
              datalabels: { display: false },
              pointRadius: 0,
            },
            {
              type: "bar",
              label: "Earnning Achieved",
              data: chartData2.data,
              backgroundColor: bg_colors2,
              yAxisID: "B",
              borderRadius: 2,
              datalabels: { color: "#252525" }
            },

          ],
        },
        options: {
          maintainAspectRatio: false,
          layout: {
            padding: {
              left: 20,
              right: 20,
              top: 30,
              bottom: 10,
            },
          },
          responsive: true,
          scales: {
            x: {
              grid: {
                display: false,
              },
              ticks: {
                color: "#252525",
                font: {
                  size: 12.8,
                  weight: "normal",
                },
              },
            },
            y: {
              display: false,
            },
            B: {
              grid: {
                display: false,
              },

              min: 0,
              max: Math.max(targetRevenue, max2) + 200,
              ticks: {
                color: "#252525",
                callback: function (label, index, labels) {
                  return Math.round(label / 100) * 100;
                  // if (label == 0) return label;
                  // else if (label <= 1000) return Math.round(label);
                  // else if (label < 100000)
                  //   return Math.round(label / 1000) + "k";
                  // else return Math.round(label / 100000) + "L";
                },
                font: {
                  // family: "kanit,sans-serif",
                  size: 12.8,
                  weight: "normal",
                },
                stepSize: Math.round(max2 / 2),
              },
            },
          },
          elements: {
            bar: {
              borderWidth: 0,
            },
          },
          plugins:
          {
            annotation: {
              annotations: {
                line1: {
                  id: 'targetLine',
                  type: 'line',
                  mode: 'horizontal',
                  borderColor: '#FF0000',
                  borderWidth: 2,
                  color: 'black',
                  scaleID: 'B',
                  value: targetRevenue,
                  borderDash: [7, 5],
                  label: {
                    display: false,
                    backgroundColor: "#f0f0f0",
                    drawTime: 'afterDatasetsDraw',
                    content: `Target Earning: ${targetRevenue}`,
                    borderWidth: 1,
                    borderColor: "",
                    color: "#252525",
                  },
                  enter({ element }, event) {
                    element.label.options.display = true;
                    return true;
                  },
                  leave({ element }, event) {
                    element.label.options.display = false;
                    return true;
                  }
                }
              }
            },
            tooltip: {
              backgroundColor: "#f0f0f0",
              titleColor: "#252525",
              borderWidth: 1,
              borderColor: "",
              bodyColor: "#252525",
            },
            legend: {
              display: false
            },
            datalabels: {
              anchor: 'end',
              align: 'top',
              formatter: Math.round
            },
          },
        },
      });
      myChart1 = new ChartJS(ctx1, {
        type: "bar",
        data: {
          labels: chartData1.date,
          datasets: [
            {
              type: 'line',
              label: 'Trendline',
              data: [null, ...trendData1, null],
              borderColor: "#4a4a4a",
              borderWidth: 2,
              backgroundColor: "#4a4a4a",
              yAxisID: "B",
              fill: false,
              datalabels: { display: false },
              pointRadius: 0,
            },
            {
              type: "bar",
              label: "Earning Achieved",
              data: chartData1.data,
              backgroundColor: bg_colors1,
              yAxisID: "B",
              borderRadius: 2,
              datalabels: { color: "#252525" }
            },
          ],
        },
        options: {
          datalabels: {
            anchor: 'start',
            align: 'top',
            font: {
              weight: 'bold'
            }
          },
          maintainAspectRatio: false,
          layout: {
            padding: {
              left: 20,
              right: 20,
              top: 30,
              bottom: 10,
            },
          },
          responsive: true,
          scales: {
            x: {
              grid: {
                display: false,
              },
              ticks: {
                color: "#252525",
                font: {
                  // family: "kanit,sans-serif",
                  size: 12.8,
                  weight: "normal",
                },
              },
            },
            y: {
              display: false,
            },
            B: {
              grid: {
                display: false,
              },

              min: 0,
              max: Math.max(targetRevenue, max1) + 200,
              ticks: {
                color: "#252525",
                callback: function (label, index, labels) {
                  return Math.round(label / 100) * 100;
                  // if (label == 0) return label;
                  // else if (label <= 1000) return Math.round(label);
                  // else if (label < 100000)
                  //   return Math.round(label / 1000) + "k";
                  // else return Math.round(label / 100000) + "L";
                },
                font: {
                  // family: "kanit,sans-serif",
                  size: 12.8,
                  weight: "normal",
                },
                stepSize: Math.round(max1 / 2),
              },
            },
          },
          elements: {
            bar: {
              borderWidth: 0,
            },
          },
          plugins: {
            annotation: {
              annotations: {
                line1: {
                  id: 'targetLine',
                  type: 'line',
                  mode: 'horizontal',
                  borderColor: '#FF0000',
                  borderWidth: 2,
                  color: 'black',
                  scaleID: 'B',
                  value: targetRevenue,
                  borderDash: [7, 5],
                  label: {
                    display: false,
                    backgroundColor: "#f0f0f0",
                    drawTime: 'afterDatasetsDraw',
                    content: `Target Earning: ${targetRevenue}`,
                    borderWidth: 1,
                    borderColor: "",
                    color: "#252525",
                  },
                  enter({ element }, event) {
                    element.label.options.display = true;
                    return true;
                  },
                  leave({ element }, event) {
                    element.label.options.display = false;
                    return true;
                  }
                }
              }
            },
            tooltip: {
              backgroundColor: "#f0f0f0",
              titleColor: "#252525",
              borderWidth: 1,
              borderColor: "",
              bodyColor: "#252525",
            },
            legend: {
              display: false
            },
            datalabels: {
              anchor: 'end',
              align: 'top',
              formatter: Math.round
            },
          },
        },
      });
      myChart7 = new ChartJS(ctx7, {
        type: "bar",
        data: {
          labels: progressData.label,
          datasets: [{
            type: "bar",
            label: 'Monthly Progress',
            data: progressData.data,
            backgroundColor: progressData.color,
            yAxisID: "B",
            borderRadius: 3,
            datalabels: { color: "#252525" },
            maxBarThickness: 200,
          }]
        },
        options: {
          maintainAspectRatio: false,
          layout: {
            padding: {
              left: 20,
              right: 20,
              top: 30,
              bottom: 10,
            },
          },
          responsive: true,
          scales: {
            x: {
              grid: {
                display: false,
              },
              ticks: {
                color: "#252525",
                font: {
                  size: 12.8,
                  weight: "normal",
                },
              },
            },
            y: {
              display: false,
            },
            B: {
              grid: {
                display: false,
              },

              min: 0,
              ticks: {
                color: "#252525",
                callback: function (label, index, labels) {
                  return label + "%";
                },
                font: {
                  size: 12.8,
                  weight: "normal",
                },
                stepSize: 20,
              },
            },
          },
          elements: {
            bar: {
              borderWidth: 0,
            },
          },
          plugins:
          {
            tooltip: {
              backgroundColor: "#f0f0f0",
              titleColor: "#252525",
              borderWidth: 1,
              borderColor: "",
              bodyColor: "#252525",
              callbacks: {
                label: function (item) {
                  let index = item.dataIndex;
                  let target = progressData.target[index];
                  return `Target: ${Math.round(target)}`;
                },
                afterLabel: function (item) {
                  let index = item.dataIndex;
                  let actualValue = progressData.value[index];
                  return `Achieved: ${Math.round(actualValue)}`;
                }
              },
            },
            legend: {
              display: false
            },
            datalabels: {
              anchor: 'end',
              align: 'top',
              formatter: function (value, context) {
                return Math.round(value) + '%';
              }
            },
          },
        },
      });
      myChart8 = new ChartJS(ctx8, {
        type: "bar",
        data: {
          labels: managerProgressData.label,
          datasets: [{
            type: "bar",
            label: 'Monthly Progress',
            data: managerProgressData.data,
            backgroundColor: managerProgressData.color,
            yAxisID: "B",
            borderRadius: 3,
            datalabels: { color: "#252525" },
            maxBarThickness: 200,
          }]
        },
        options: {
          maintainAspectRatio: false,
          layout: {
            padding: {
              left: 20,
              right: 20,
              top: 30,
              bottom: 10,
            },
          },
          responsive: true,
          scales: {
            x: {
              grid: {
                display: false,
              },
              ticks: {
                color: "#252525",
                font: {
                  size: 12.8,
                  weight: "normal",
                },
              },
            },
            y: {
              display: false,
            },
            B: {
              grid: {
                display: false,
              },

              min: 0,
              ticks: {
                color: "#252525",
                callback: function (label, index, labels) {
                  return label + "%";
                },
                font: {
                  size: 12.8,
                  weight: "normal",
                },
                stepSize: 20,
              },
            },
          },
          elements: {
            bar: {
              borderWidth: 0,
            },
          },
          plugins:
          {
            tooltip: {
              backgroundColor: "#f0f0f0",
              titleColor: "#252525",
              borderWidth: 1,
              borderColor: "",
              bodyColor: "#252525",
              callbacks: {
                label: function (item) {
                  let index = item.dataIndex;
                  let target = managerProgressData.target[index];
                  return `Target: ${Math.round(target)}`;
                },
                afterLabel: function (item) {
                  let index = item.dataIndex;
                  let actualValue = managerProgressData.value[index];
                  return `Achieved: ${Math.round(actualValue)}`;
                }
              },
            },
            legend: {
              display: false
            },
            datalabels: {
              anchor: 'end',
              align: 'top',
              formatter: function (value, context) {
                return Math.round(value) + '%';
              }
            },
          },
        },
      });
      if (!hideContriChart && Object.keys(contribution).length > 0) {
        myChart4 = new ChartJS(ctx4, {
          type: "bar",
          data: {
            labels: contribution[maxLen] ? contribution[maxLen].date : [],
            datasets: contriDatasets,
          },
          options: {
            datalabels: {
              anchor: 'start',
              align: 'top',
              font: {
                weight: 'bold'
              }
            },
            maintainAspectRatio: false,
            layout: {
              padding: {
                left: 20,
                right: 20,
                top: 30,
                bottom: 10,
              },
            },
            responsive: true,
            scales: {
              x: {
                grid: {
                  display: false,
                },
                ticks: {
                  color: "#252525",
                  padding: 15,
                  font: function (context) {
                    let val = context.tick.label;
                    if (isDataIncomplete(val, channel == "" ? 'all' : [channel])) {
                      return {
                        size: 12.8,
                        weight: 'bold'
                      };
                    } else {
                      return {
                        size: 12.8,
                        weight: 'normal'
                      };
                    }
                  },
                },
              },
              y: {
                display: false,
              },
              B: {
                grid: {
                  display: false,
                },

                min: 0,
                ticks: {
                  color: "#252525",
                  callback: function (label, index, labels) {
                    return label + "%";
                  },
                  font: {
                    size: 12.8,
                    weight: "normal",
                  },
                  stepSize: 20,
                },
              },
            },
            elements: {
              bar: {
                borderWidth: 0,
              },
            },
            plugins: {
              tooltip: {
                backgroundColor: "#f0f0f0",
                titleColor: "#252525",
                borderWidth: 1,
                borderColor: "",
                bodyColor: "#252525",
                callbacks: {
                  label: (item) => {
                    var target = channel == "" ? targetMap[item.dataset.label] : managerTargetMap[item.dataset.user_id];
                    return `${item.dataset.label}: ${Math.round(item.formattedValue * target / 100)}`;
                  }
                },
              },
              legend: {
                fullSize: false,
                position: "bottom",
                align: "center",
                labels: {
                  color: "#252525",
                  font: {
                    // family: "kanit, sans-serif",
                    size: 16,
                    weight: 400,
                  },
                  useBorderRadius: true,
                  borderRadius: 5,
                },
              },
              datalabels: {
                anchor: 'end',
                align: 'top',
                formatter: function (value, context) {
                  return Math.round(value) + '%';
                }
              },
            },
          },
        });
      }
      if (Object.keys(subContribution).length > 0)
        myChart5 = new ChartJS(ctx5, {
          type: "bar",
          data: {
            labels: subContribution[submaxLen] ? subContribution[submaxLen].date : [],
            datasets: subContriDatasets,
          },
          options: {
            datalabels: {
              anchor: 'start',
              align: 'top',
              font: {
                weight: 'bold'
              }
            },
            maintainAspectRatio: false,
            layout: {
              padding: {
                left: 20,
                right: 20,
                top: 30,
                bottom: 10,
              },
            },
            responsive: true,
            scales: {
              x: {
                grid: {
                  display: false,
                },
                ticks: {
                  color: "#252525",
                  padding: 15,
                  font: {
                    // family: "kanit,sans-serif",
                    size: 12.8,
                    weight: "normal",
                  },
                },
              },
              y: {
                display: false,
              },
              B: {
                grid: {
                  display: false,
                },

                min: 0,
                ticks: {
                  color: "#252525",
                  callback: function (label, index, labels) {
                    return label + "%";
                  },
                  font: {
                    size: 12.8,
                    weight: "normal",
                  },
                  stepSize: 20,
                },
              },
            },
            elements: {
              bar: {
                borderWidth: 0,
              },
            },
            plugins: {
              tooltip: {
                backgroundColor: "#f0f0f0",
                titleColor: "#252525",
                borderWidth: 1,
                borderColor: "",
                bodyColor: "#252525",
                callbacks: {
                  label: (item) =>
                    `${item.dataset.label}: ${Math.round(item.formattedValue * managerTargetMap[item.dataset.user_id] / 100)}`
                },
              },
              legend: {
                fullSize: false,
                position: "bottom",
                align: "center",
                labels: {
                  color: "#252525",
                  font: {
                    // family: "kanit, sans-serif",
                    size: 16,
                    weight: 400,
                  },
                  useBorderRadius: true,
                  borderRadius: 5,
                },
              },
              datalabels: {
                anchor: 'end',
                align: 'top',
                formatter: function (value, context) {
                  return Math.round(value) + '%';
                }
              },
            },
          },
        });
    }
    catch (error) {
      console.log(error);
    }
  }, [chartData3, chartData2, chartData1, contribution, inCompleteData, channelMonthlyEarning]);
  const PendingModal = () => {
    const renderIncompleteStatus = () => {
      let elements = [];
      adopsTeam.forEach((channelData) => {
        const name = channelData.channel;
        if (inCompleteData[clickedDate] && inCompleteData[clickedDate][name] && inCompleteData[clickedDate][name].length > 0) {
          if (channel == "") {
            elements.push(<div>{name + ' - Pending'}</div>);
          }
          else if (channel == name) {
            inCompleteData[clickedDate][name].forEach((item) => {
              elements.push(<div key={item.username}>{item.username + '(' + name + ')' + ' - Pending'}</div>);
            });
          }
        }
      });

      return elements;
    };
    return <Modal
      open={isModalOpen}
      onClose={() => { setisModalOpen(!isModalOpen) }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={modalStyle}>
        <div>Data status</div>
        {renderIncompleteStatus()}
      </Box>
    </Modal>
  }

  const PendingModal1 = () => {
    const renderIncompleteStatus = () => {
      let elements = [];
      adopsTeam.forEach((channelData) => {
        const name = channelData.channel;
        if (inCompleteData[clickedDate] && inCompleteData[clickedDate][name] && inCompleteData[clickedDate][name].length > 0) {
          if (channel == "" || channel == name) {
            inCompleteData[clickedDate][name].forEach((item) => {
              elements.push(<div key={item.username}>{item.username + '(' + name + ')' + ' - Pending'}</div>);
            });
          }
        }
      });

      return elements;
    };
    return <Modal
      open={isModalOpen1}
      onClose={() => { setisModalOpen1(!isModalOpen1) }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={modalStyle}>
        <div>Data status</div>
        {renderIncompleteStatus()}
      </Box>
    </Modal>
  }

  const [activeSelect, setActiveSelect] = useState(false)
  useEffect(() => {
    document.addEventListener('click', e => {
      setActiveSelect(false);
    })

    return () => {
      document.removeEventListener('click', e => {
        setActiveSelect(false);
      })
    }
  }, [])


  const CardModal = () => {
    const [activeTab, setActiveTab] = useState('all');
    const data = differenceData[openCardModel];
    const [expandedChannels, setExpandedChannels] = useState({});

    const toggleChannel = (name) => {
      setExpandedChannels((prev) => ({
        ...prev,
        [name]: !prev[name],
      }));
    };

    const renderChannelRow = (channelName, channelSum) => (
      <tr className='channel-title' key={channelName} style={{ display: 'table-row' }}>
        <td colSpan="2" onClick={() => toggleChannel(channelName)} style={{ cursor: 'pointer' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', paddingLeft: '10px' }}>
            <div>{channelName}</div>
            <div className={`toggle-icon ${expandedChannels[channelName] ? 'expanded' : 'collapsed'}`}>{expandedChannels[channelName] ? <ExpandLessIcon /> : <ExpandMoreIcon />}</div>
          </div>
        </td>
        <td className={channelSum > 0 ? 'gain' : 'dropData'}>{`${channelSum}`}</td>
      </tr>
    );

    const renderManagers = (filter) => {
      let elements = [];
      let total = 0;
      channelList.forEach((ch) => {
        const name = ch.label;
        let channel_elements = []
        if (channel == "" || channel == name) {
          let channel_sum = 0;
          if (data && data[name] && data[name].length > 0) {
            channel_elements.push(
              <tr style={{ display: expandedChannels[name] ? 'table-row' : 'none' }}>
                <th>Account Manager</th>
                <th>{activeTab == 'all' ? 'Net Change' : activeTab == 'gain' ? 'Gain' : 'Drop'}</th>
              </tr>
            )
            data[name].forEach((item) => {
              if (allChannelManager[name][item.user_id]) {
                const diff = Math.round(item.diff);
                if ((filter === 'all') || (filter === 'gain' && diff > 0) || (filter === 'loss' && diff < 0)) {
                  channel_sum += diff
                  let username = allChannelManager[name][item.user_id].username
                  const diffClass = diff > 0 ? 'gain' : 'dropData';
                  channel_elements.push(<tr key={username} style={{ display: expandedChannels[name] ? 'table-row' : 'none' }}
                  ><td>{username}</td><td className={diffClass}>{diff}</td></tr>);
                }
              }
            });
            if (channel_elements.length > 1) {
              elements.push(renderChannelRow(name, channel_sum));
              elements = [...elements, channel_elements]
              total += channel_sum
            }
          }
        }
      })
      return { elements, total };
    }
    const { elements, total } = renderManagers(activeTab);

    return <Modal
      open={openCardModel != ''}
      onClose={() => { setOpenCardModel('') }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={modalStyle1}>
        <div>
          <div className="tabs">
            <button onClick={() => setActiveTab('all')} className={activeTab === 'all' ? 'active' : ''}>Gain/Drop Summary</button>
            <button onClick={() => setActiveTab('gain')} className={activeTab === 'gain' ? 'active' : ''}>Top Gains</button>
            <button onClick={() => setActiveTab('loss')} className={activeTab === 'loss' ? 'active' : ''}>Top Drops</button>
          </div>
          <div className="tab-content">
            <div className="total"><strong>{`Total ${activeTab == 'all' ? 'Change' : activeTab == 'gain' ? 'Gain' : 'Drop'}: ${total}`}</strong></div>
            <table className="table1">
              <tbody>
                {elements}
              </tbody>
            </table>
          </div>
        </div>
      </Box>
    </Modal>
  }

  const Bottom_Component = ({ data, width }) => {
    return (
      <div className="mt-6  mb-6 h-[140px] flex items-center justify-center "
        style={{ flexWrap: "wrap" }}>
        {data.map((item) => {
          const key = Object.keys(item)[0];
          const { type, data, date, prev, onClick } = item[key];

          return (
            <div style={{ width: "16rem", marginRight: "1.5rem", cursor: 'pointer' }}
              className={`flex-col items-center h-[100%] bg-white rounded-[10px]`}
              onClick={() => setOpenCardModel(onClick)}
            >
              <div className="flex flex-col items-center pt-6 px-4 w-full">
                <h2 className=" text-[16px]  text-[#331E38] ">
                  {type}
                </h2>
                <h3 className=" text-[12.8px]  text-[#A5A5A5] ">
                  ({date})
                </h3>
                <div className="flex items-center">
                  <h2 style={{ paddingRight: "5px" }} className=" text-[32px] text-[#331E38] ">{Math.round(data)}</h2>
                  {prev > 0 ? <UpArrow /> : <DownArrow />}
                  {prev > 0 ?
                    <h2 className=" text-[16px] text-[#0EAE00] ">{Math.round(Math.abs(prev))}</h2> :
                    <h2 className=" text-[16px] text-[#DF0000] ">{Math.round(Math.abs(prev))}</h2>}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return <div style={{ padding: "1rem 5rem" }}>
    <div style={{ justifyContent: "space-between", display: "flex" }}>
      <div className="teamName">
        Adops Summary
      </div>

      <div >
        <div className='v2filterRowContainer'>
          {filtersList && filtersList.map((filterItem, filterIndex) => {

            return <div style={{ borderRadius: "10px" }} className={'v2filterItemContainer' + (activeSelect === filterItem.value && filterItem.type === 'select' ? ' v2SelectComponentActive' : '')} id={'v2Filter' + filterItem.label} key={filterIndex}>
              <div className='v2FilterIcon'>
                {filterItem.icon && <filterItem.icon />}
              </div>
              {filterItem.type === 'select'
                &&
                <SelectComponent activeSelect={activeSelect} setActiveSelect={setActiveSelect} filterItem={filterItem} filterData={filterData} setFilterData={overrideSetFilterData} reorder={false} />
              }
            </div>
          })}
        </div>
      </div>

    </div>
    <div className="containerManik">
      <Bottom_Component
        width={"64"}
        data={
          [
            {
              1: {
                type: "Yesterday's Earnings",
                data: cardData.yesterday_earning,
                date: cardData.yesterday,
                prev: cardData.yes_diff,
                onClick: "day_wise"
              },
            },
            {
              2: {
                type: "Weekly Earnings",
                data: cardData.this_week,
                date: cardData.week_date,
                prev: cardData.week_diff,
                onClick: "week_wise"
              },
            },
            {
              3: {
                type: "Monthly Earnings",
                data: cardData.this_month,
                date: cardData.month_date,
                prev: cardData.month_diff,
                onClick: "month_wise"
              },
            },
          ]}
      />
      <CardModal />
    </div>
    <div className="containerManik" style={{ marginBottom: "0rem" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="title" style={{ marginBottom: "1rem", paddingLeft: "10px", display: "flex", alignItems: "center", gap: '10px' }}>
          <div>Daily Earning{" "}</div>
          <CustomSelect
            placeholder={`(Last ${days} Days)`}
            options={dayOptions}
            days={days}
            setDays={setDays} />
        </div>
        {/* <div style={{display:"flex",alignItems:"center",marginBottom:"1rem"}}>
      <input 
        checked={markWeekends}
        className={'v2SelectCheckboxIcon'} 
        style={{marginRight:'10px'}} 
        id={'mark_weekend'} 
        name={'mark_weekend'}
        type={'checkbox'}
        onClick={()=>setMarkWeekends(!markWeekends)}
    />
    Highlight Weekends
      </div> */}
      </div>
      <PendingModal />
      <div className="monthly-synopsis">
        <canvas id="chart3"></canvas>
      </div>
    </div>

    <div className="parent">
      <div className="containerManik child" style={{ width: "48%", marginBottom: "0rem" }}>
        <div className="title" style={{ marginBottom: "1rem", paddingLeft: "10px" }}>
          Weekly Earning{" "}
          <span
            style={{
              marginLeft: "0.2rem",
              marginTop: "0.5rem",
              color: "#A5A5A5",
            }}
          >
            (12 Weeks)
          </span>
        </div>
        <div style={{ aspectRatio: "1.5" }}>
          <canvas id="chart2"></canvas>
        </div>
      </div>
      <div className="containerManik child" style={{ marginLeft: "1.8rem", width: "48%", marginBottom: "0rem" }}>
        <div className="title" style={{ marginBottom: "1rem", paddingLeft: "10px" }}>
          Monthly Earning{" "}
          <span
            style={{
              marginLeft: "0.2rem",
              marginTop: "0.5rem",
              color: "#A5A5A5",
            }}
          >
            (Last 13 Months)
          </span>
        </div>
        <div style={{ aspectRatio: "1.5" }}>
          <canvas id="chart1"></canvas>
        </div>
      </div>
    </div>
    {!hideContriChart && Object.keys(contribution).length > 0 ? (
      <div className="containerManik">
        <div className="title" style={{ marginBottom: "1rem", paddingLeft: "10px", display: "flex", alignItems: "center", gap: '10px' }}>
          Contribution{" "}
          <CustomSelect
            placeholder={`(Last ${contriDays} Days)`}
            options={contriOptions}
            days={contriDays}
            setDays={handleContriDayChange} />
        </div>
        <div className="monthly-synopsis">
          <canvas id="chart4"></canvas>
        </div>
        <PendingModal1 />
      </div>) : (<></>)}
    {manager != "" && Object.keys(subContribution).length > 0 ? (
      <div className="containerManik">
        <div className="title" style={{ marginBottom: "1rem", paddingLeft: "10px" }}>
          Sub-Team Contribution{" "}
          <span
            style={{
              marginLeft: "0.2rem",
              marginTop: "0.5rem",
              color: "#A5A5A5",
            }}
          >
            {`(Last ${contriDays} Days)`}
          </span>
        </div>
        <div className="monthly-synopsis">
          <canvas id="chart5"></canvas>
        </div>
      </div>) : (<></>)}
    <div className="containerManik" style={{ marginBottom: "0rem" }}>
      <div className="title" style={{ marginBottom: "1rem", paddingLeft: "10px", display: "flex", alignItems: "center", gap: '10px' }}>
        Monthly Progress{" "}
        <span
          style={{
            marginLeft: "0.2rem",
            // marginTop: "0.5rem",
            color: "#A5A5A5",
          }}
        >
          (Channel - Wise)
        </span>
        <CustomSelect
          placeholder={`(${obj[progressMonth]})`}
          options={progressMonthOptions}
          days={progressMonth}
          setDays={setProgressMonth} />
      </div>
      <div className="monthly-synopsis">
        <canvas id="chart7"></canvas>
      </div>
    </div>
    <div className="containerManik" style={{ marginBottom: "0rem" }}>
      <div className="title" style={{ marginBottom: "1rem", paddingLeft: "10px", display: "flex", alignItems: "center", gap: '10px' }}>
        Monthly Progress{" "}
        <span
          style={{
            marginLeft: "0.2rem",
            // marginTop: "0.5rem",
            color: "#A5A5A5",
          }}
        >
          (Manager - Wise)
        </span>
        <CustomSelect
          placeholder={`(${obj[progressMonth]})`}
          options={progressMonthOptions}
          days={progressMonth}
          setDays={setProgressMonth} />
      </div>
      <div className="monthly-synopsis">
        <canvas id="chart8"></canvas>
      </div>
    </div>
  </div>
}

export default AdopsSummary;