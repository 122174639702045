import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import * as Constants from '../../../data/constants';
import parse from 'html-react-parser';
import { capitalize, getLocalStorageValue, useForceUpdate } from '../../../util/util';
// import Zoom from '@mui/material/Zoom';
import Select from 'react-select';
// import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
// import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import Checkbox from '@mui/material/Checkbox';
import { sorting } from "../../../util/util";
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import '../../../css/pages/v2/offer.css'

import Button from '@mui/material/Button';
import { Store } from 'react-notifications-component';
import NavigationPrompt from "react-router-navigation-prompt";

import { increase_brightness } from '../../../components/customization/customization';
import CopyButton from '../../../components/copyButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import Logo from '../../../components/logoUpdater/logo';
import ShareIcon from '@mui/icons-material/Share';
import { IconButton } from '@mui/material';

export const ViewOfferPageV2 = () => {
    const refreshComponent = useForceUpdate()
    const offerid = window.location.href.split("/")[window.location.href.split("/").length-1];
    
    const [manualUseEffect,setManualUseEffect] = React.useState(false);
    // const [requestDone,setRequestDone] = React.useState(false);

    const [offerID,setOfferID] = React.useState("");
    const [offerTitle,setOfferTitle] = React.useState("");
    const [offerAdvertiser,setOfferAdvertiser] = React.useState("");
    const [offerCaps,setOfferCaps] = React.useState([]);
    const [offerLinkTest,setOfferTestLinkStatus] = React.useState([]);
    const [offerCategories,setOfferCategories] = React.useState([]);
    const [offerCreatives,setOfferCreatives] = React.useState([]);
    const [offerDescription,setOfferDescription] = React.useState("");
    const [offerImpressionLink,setOfferImpressionLink] = React.useState("");
    const [offerKpi,setOfferKpi] = React.useState("");
    const [offerSource,setOfferSource] = React.useState("");
    const [offerExpiry,setOfferExpiry] = React.useState("");
    const [offerPayouts,setOfferPayouts] = React.useState([]);
    const [externalOfferId,setExternalOfferId] = React.useState([]);
    const [offerPackageName,setOfferPackageName] = React.useState([]);
    const [offerPreviewUrl,setOfferPreviewUrl] = React.useState("");
    // const [offerStopAt,setOfferStopAt] = React.useState("");
    const [offerTargetingCountries,setOfferTargetingCountries] = React.useState([]);
    const [offerTrackingUrl,setOfferTrackingUrl] = React.useState("");
    const [offerStatus,setOfferStatus] = React.useState("");
    const [offerType,setOfferType] = React.useState("");
    const [affiliateList,setAffiliateList] = React.useState([]);
    const [enableAffiliateList,setEnableAffiliateList] = React.useState(false);
    const [disableAffiliateList,setDisableAffiliateList] = React.useState(false);
    const [changesDone,setChangesDone] = React.useState(false);
    // const [showLoader,setShowLoader] = React.useState(false);
    // const [targetingUrlAffiliates, setTargettingUrlAffiliates] = React.useState([]);
  

    const [top, setTop] = React.useState(0)
    // const [up, setUp] = React.useState(false)

    const controlNavbar = (e) => {
        var tempScrollPosition = 0
        if(window.scrollY>60 && window.scrollY<=120 ){
            tempScrollPosition = 60 - (window.scrollY - 60)
        }
        else if(window.scrollY>120 ){
            tempScrollPosition = -2

        }
        else{
            tempScrollPosition = 60
        }
        setTop(tempScrollPosition)
        // setPrevScrollPosition(window.scrollY)
    }

    React.useEffect(() => {
        window.addEventListener('scroll', controlNavbar)
        return () => {
            window.removeEventListener('scroll', controlNavbar)
        }
    })

    const [offerData, setOfferData] = useState(false)

    const offers_request = async() => {
        // setShowLoader(true);
        axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.GETOFFERS,"/",offerid),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
        })
        .then(resp => {
            // setShowLoader(false);
            if(resp.data.result.length===0){
                Store.addNotification({
                    title: "Error!",
                    message: "'No such offer found.'",
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 2000,
                      onScreen: true
                    },
                    onRemoval: (id, removedBy) => {
                        window.location.href = "/offers"
                    }
                });
            }
            setOfferData(resp.data.result[0]);
            setOfferPackageName(resp.data.result[0].package_name);
            setOfferSource(resp.data.result[0].offer_source);
            setOfferExpiry(resp.data.result[0].offer_expiry);
            setOfferID(resp.data.result[0].id);
            setOfferTitle(resp.data.result[0].title)
            setOfferAdvertiser({label:resp.data.result[0].advertiser ? resp.data.result[0].advertiser.split(") ")[1] : "",value: resp.data.result[0].advertiser ? resp.data.result[0].advertiser.split(")")[0].split("(")[1] : ""})
            setOfferCaps(resp.data.result[0].caps)
            setOfferTestLinkStatus(resp.data.result[0].link_status)
            setOfferCategories(resp.data.result[0].categories)
            setOfferCreatives(resp.data.result[0].creatives)
            setOfferDescription(resp.data.result[0].description)
            setOfferImpressionLink(resp.data.result[0].impression_link)
            setOfferKpi(resp.data.result[0].kpi)
            setOfferPayouts(resp.data.result[0].payouts)
            setOfferPreviewUrl(resp.data.result[0].preview_url)
            setOfferType(resp.data.result[0].offer_type==='protected' ? 'Request' : resp.data.result[0].offer_type)
            setOfferStatus(resp.data.result[0].offer_status)
            // setOfferStopAt(resp.data.result[0].stop_at)
            setOfferTargetingCountries(resp.data.result[0].targeting)
            setOfferTrackingUrl(resp.data.result[0].tracking_url)
            setExternalOfferId(resp.data.result[0].external_offer_id)
            // setRequestDone(true)
        })
        .catch(err => {
            // setShowLoader(false);
            console.error(err);
        })
        
    };
     
    const getAffiliates = async() => {
        axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"affiliate/details"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
        })
        .then(resp => {
            var affiliatelist = [];
            for (var affiliate in resp.data.result){
                affiliatelist.push({label:resp.data.result[affiliate][Object.keys(resp.data.result[affiliate])[0]] + " - " + Object.keys(resp.data.result[affiliate])[0] ,value:Object.keys(resp.data.result[affiliate])[0]});
            }
            sorting(affiliatelist,'label',false,true)
            setAffiliateList(affiliatelist);
        })
        .catch(err => {
            console.error(err);
        }) 
    };   
    
    const getOfferApprovalRequest = async(action) => {
        axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"offerapprovalrequests/affiliate/status"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params:{
                offer_id:offerid,
                status:action,
            }
        })
        .then(resp => {
            var affiliateIdList = []
            for (var affiliate in resp.data.result){
                affiliateIdList.push({label:resp.data.result[affiliate].split(") ")[1] + " - " + resp.data.result[affiliate].split(") ")[0].split("(")[1],value:resp.data.result[affiliate].split(") ")[0].split("(")[1]})
            }
            if (action==="approved"){
                setEnableAffiliateList(affiliateIdList)  
                // setTargettingUrlAffiliates(affiliateIdList)
            }
            if (action==="denied"){
                setDisableAffiliateList(affiliateIdList)  
            }
        })
        .catch(err => {
            console.error(err);
        }) 
    }; 

    const showOfferBookmarksRequest = async() => {
        await axios({
            method: 'POST',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"bookmark/status"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
                "Content-Type":'application/json'
            },
            data:{
                offer_id:[parseInt(offerid)]
            }
        }
        )
        .then((resp) => {
            setBookmark(resp.data.result[offerid])
        })
        .catch(function (error) {
            console.log(error)
        });
    }

    if (!manualUseEffect){
        setManualUseEffect(true);
        offers_request().then(resp=>{
            getAffiliates().then(resp=>{
                getOfferApprovalRequest("approved").then(resp=>{
                    getOfferApprovalRequest("denied");
                });
            });
        })
        showOfferBookmarksRequest();
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        }
        );
    }

    const handleEnableOffer = async(action) => {
        var affiliateIdList = []
        if (enableAffiliateList && action==="approved"){
            for (var affiliate in enableAffiliateList){
                affiliateIdList.push(enableAffiliateList[affiliate].value)
            }
        }
        
        if (disableAffiliateList && action==="denied"){
            for (var disableaffiliate in disableAffiliateList){
                affiliateIdList.push(disableAffiliateList[disableaffiliate].value)
            }
        }
        await axios({
            method: 'POST',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"offers/acess"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
                "Content-Type":"application/json",
            },
            data:{
                    "offer_id" : offerid,
                    "status" : action,
                    "affiliates" : affiliateIdList
                }
            })
          .then((resp)=>{
                    
                Store.addNotification({
                    title: "Success!",
                    message: resp.data.result,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 2000,
                      onScreen: true
                    },
                });
            })
          .catch(function (error) {
                if (error.response) {
                // Request made and server responded
                    Store.addNotification({
                        title: "Error!",
                        message: error.response.data.result,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                        duration: 2000,
                        onScreen: true
                        },
                    });
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else {
                // Something happened in setting up the request that triggered an Error
                    Store.addNotification({
                        title: "Error!",
                        message: "Server Error",
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                        duration: 2000,
                        onScreen: true
                        },
                    });
                   console.log('Error', error.message);
                }
            
          });
    }
    const handleOfferLinkTest = async() => {
        await axios({
            method: 'POST',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"offerlinktest/bulk/add"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
                "Content-Type":"application/json",
            },
            data:{
                    "offer_id" : offerid,
                    "test_type": "atomic"
                }
            })
          .then((resp)=>{
                    
                Store.addNotification({
                    title: "Success!",
                    message: resp.data.result,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 2000,
                      onScreen: true
                    },
                });
            })
          .catch(function (error) {
                if (error.response) {
                // Request made and server responded
                    Store.addNotification({
                        title: "Error!",
                        message: error.response.data.result,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                        duration: 2000,
                        onScreen: true
                        },
                    });
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else {
                // Something happened in setting up the request that triggered an Error
                    Store.addNotification({
                        title: "Error!",
                        message: "Server Error",
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                        duration: 2000,
                        onScreen: true
                        },
                    });
                   console.log('Error', error.message);
                }
            
          });
    }

    const showHtml = (offerKpiManual) => {
        try{
            // while(offerKpiManual.includes("&lt;") || offerKpiManual.includes("&gt;")){
            //     offerKpiManual = offerKpiManual.replace("&lt;","<")
            //     offerKpiManual = offerKpiManual.replace("&gt;",">")
            // }
            return parse(offerKpiManual)
        }
        catch{
            return offerKpiManual
        }
    }

    const [affiliateForLink, setAffiliateForLink] = React.useState(false);
    const [generatedTrackingLink,setGeneratedTrackingLink] = React.useState('');
    const [generatedPixelLink,setGeneratedPixelLink] = React.useState('');
    const [addTestLink,setAddTestLink] = React.useState(false);
    const [addPixelTags,setAddPixelTags] = React.useState(false);
    const [bookmark,setBookmark] = React.useState(false);

    const changeBookmark = async() =>{
        await axios({
            method: 'POST',
            url : "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,'bookmark/change '),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
                "Content-Type":"application/json",
            },
            data:{
                offer_id:parseInt(offerid)
            }
        })
        .then(resp => {
        })
        .catch(error => {
            console.log(error)
        })
    }
    const changeTestLink = (e,impression_link) => {
        if(generatedTrackingLink){
            if(e.target.checked){
                setAddTestLink(true)
                var targettingLink = Constants.OFFERAFFILIATELINK
                targettingLink+="?aff_id="+affiliateForLink.value+"&offer_id="+offerid
                targettingLink+="&sub1=testofferlink"
                setGeneratedTrackingLink(targettingLink)
            }
            else{
                setAddTestLink(false)
                targettingLink = Constants.OFFERAFFILIATELINK
                targettingLink+="?aff_id="+affiliateForLink.value+"&offer_id="+offerid
                setGeneratedTrackingLink(targettingLink)
            }
        } 
    }

    const duplicateOfferApi = async(item) => {
        await axios({
                method: 'POST',
                url : "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.GETOFFERS,"/add"),
                headers:{
                    Authorization:getLocalStorageValue('adminPanelLoginData').token,
                    "Content-Type":"application/json",
                },
                data:item
        })
        .then(resp => {
            Store.addNotification({
                title: "Success!",
                message: "Offer Duplicated",
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 2000,
                  onScreen: true
                },
            });
        })
        .catch(error => {
            console.log(error)
        })

    }
    const generateTargettingLink = (e,testlink=false) => {
        var targettingLink = Constants.OFFERAFFILIATELINK
        targettingLink+="?aff_id="+affiliateForLink.value+"&offer_id="+offerid
        if (addTestLink){
            targettingLink+="&sub1=testofferlink"
        }
        setGeneratedTrackingLink(targettingLink)
    }
    const generatePixel = (e) => {
        var targettingLink = ''
        if(offerImpressionLink){
            if(e.target.checked || (e.target.checked===undefined && addPixelTags)){
                targettingLink = '<img src="'
            }
            targettingLink+= process.env.REACT_APP_IMPRESSION_LINK
            targettingLink+="?aff_id="+affiliateForLink.value+"&offer_id="+offerid
            if(e.target.checked || (e.target.checked===undefined && addPixelTags)){
                targettingLink+= '" alt="" />'
            }
            setGeneratedPixelLink(targettingLink)
        }
        else{
            setGeneratedPixelLink('No Impression URL for this offer.')
        }
        setAddPixelTags(e.target.checked || (e.target.checked===undefined && addPixelTags))
        refreshComponent(123)
    }

    

    const [showCopyDataPopup, setShowCopyDataPopup] = useState(false)
    const [editOffer, setEditOffer] = useState(false)
    const printDivToPDF = () => {  
        let mywindow = window.open('', '_blank', 'height=650,width=900,top=100,left=150');
        mywindow.document.write(`<html><head><title>${'Print Offer Data'}</title>`);
        mywindow.document.write('</head><body >');
        mywindow.document.write(document.getElementById('copyOfferTableData').innerHTML);
        mywindow.document.write('</body></html>');
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/
        mywindow.print();
        mywindow.close();
    }
    const editOfferFunction = () => {
        setEditOffer({
            ...editOffer,
            allowEdit:editOffer.allowEdit ? false : true,
        })
    }

    // var vkbeautify = require('vkbeautify')
    useEffect(() => {
        document.addEventListener('keydown',e=>{
            if(e.key==='Escape'){
                setShowCopyDataPopup(false);setEditOffer(false);
            }
        })
        
        return () => {
            document.removeEventListener('keydown',e=>{
                if(e.key==='Escape'){
                    setShowCopyDataPopup(false);setEditOffer(false);
                }
            })
            
        }
    }, [])
    
    return (
        <>
            <NavigationPrompt 
                when={changesDone} >
                {({ onConfirm, onCancel }) => (
                    <div style={{position:'fixed',padding:'0%',zIndex:10000,width:'100vw',height:'100vh',top:0,left:0,background:'rgb(150,150,150,0.2)',backdropFilter:'blur(2px)',alignItems:'center',justifyContent:'center',display:'flex'}}>
                        <div className='stopRedirectPopup'>
                            <div className='stopRedirectPopupHeader'>
                                <div style={{fontSize:'13px',fontWeight:'500',paddingLeft:'5px'}}>Unsave changes</div>
                                <div style={{marginRight:'10px',cursor:'pointer'}} onClick={onCancel}>
                                    X
                                </div>
                            </div>
                            <div className='stopRedirectPopupBody'>
                                <div style={{fontSize:'12px',paddingLeft:'5px'}}>
                                    You have unsaved changes. Are you sure you want to continue?
                                </div>
                                <div style={{display:'flex',marginTop:'10%',justifyContent:'center',marginBottom:'15px'}}>
                                    <Button sx={{...Constants.CONTAINEDBUTTON,height:"30px",fontWeight:'900',fontSize:10,marginRight:'20px'}} onClick={onConfirm}>Ok</Button>
                                    <Button sx={{...Constants.OUTLINEDBUTTON,height:"30px",fontWeight:'900',fontSize:10,marginRight:'20px'}} onClick={onCancel}>Cancel</Button>
                                </div>

                            </div>
                        </div>
                    </div>
                )} 
            </NavigationPrompt>
            <div className='mainContainerV2' style={{padding:'2%'}} id="outside" onClick={()=>{setShowCopyDataPopup(false);setEditOffer(false);}}>
                {showCopyDataPopup &&
                <div style={{position:'fixed',height:'80vh',width:'70vw',top:'10vh',left:'15vw',background:'white',zIndex:101,boxShadow:'0 30px 30px 1px rgb(220,220,220,0.5), 0 0px 10px 1px rgb(220,220,220,0.5)'}} onClick={(e)=>{e.stopPropagation();}}>
                    <div style={{display:'flex',justifyContent:'space-between',padding:'15px',paddingLeft:'20px',paddingRight:'20px'}}>
                        <div>Offer Data - {offerid}</div>
                        <div style={{cursor:'pointer'}} onClick={()=>{setShowCopyDataPopup(false);setEditOffer(false);}}>X</div>
                    </div>
                    <div style={{paddingLeft:'30px',paddingRight:'30px',paddingTop:'10px',fontSize:'12px',height:'65vh',overflow:'auto'}}>
                        <div id='copyOfferTableData'>
                            <table>
                                <tr>
                                    <td style={{paddingTop:'10px',width:'30%'}}>Offer ID</td>
                                    <td style={{paddingTop:'10px'}}>
                                        {
                                        editOffer.allowEdit
                                        &&
                                        <input value={editOffer.offerID} onChange={(e)=>{setEditOffer({...editOffer,offerID:e.target.value})}}/>
                                        }
                                        {
                                        !editOffer.allowEdit 
                                            && 
                                        editOffer.offerID
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{paddingTop:'10px'}}>Offer Title</td>
                                    <td style={{paddingTop:'10px'}}>
                                        {
                                        editOffer.allowEdit
                                        &&
                                        <input value={editOffer.offerTitle} onChange={(e)=>{setEditOffer({...editOffer,offerTitle:e.target.value})}}/>
                                        }
                                        {
                                        !editOffer.allowEdit 
                                            && 
                                        editOffer.offerTitle
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{paddingTop:'10px'}}>Status </td>
                                    <td style={{paddingTop:'10px'}}>
                                        {
                                        editOffer.allowEdit
                                        &&
                                        <input value={editOffer.offerStatus} onChange={(e)=>{setEditOffer({...editOffer,offerStatus:e.target.value})}}/>
                                        }
                                        
                                        {
                                        !editOffer.allowEdit 
                                            && 
                                        editOffer.offerStatus
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{paddingTop:'10px'}}>Preview URL</td>
                                    <td style={{paddingTop:'10px'}}>
                                        {
                                        editOffer.allowEdit
                                        &&
                                        <input value={editOffer.offerPreviewUrl} onChange={(e)=>{setEditOffer({...editOffer,offerPreviewUrl:e.target.value})}}/>
                                        }
                                        {
                                        !editOffer.allowEdit 
                                            && 
                                        editOffer.offerPreviewUrl
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{paddingTop:'10px'}}>Tracking URL</td>
                                    <td style={{paddingTop:'10px'}}>
                                        {
                                        editOffer.allowEdit
                                        &&
                                        <input value={editOffer.offerTrackingUrl} onChange={(e)=>{setEditOffer({...editOffer,offerTrackingUrl:e.target.value})}}/>
                                        }
                                        {
                                        !editOffer.allowEdit 
                                            && 
                                        editOffer.offerTrackingUrl
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{paddingTop:'10px'}}>Impression URL</td>
                                    <td style={{paddingTop:'10px'}}>
                                        {
                                        editOffer.allowEdit
                                        &&
                                        <input value={editOffer.offerImpressionLink} onChange={(e)=>{setEditOffer({...editOffer,offerImpressionLink:e.target.value})}}/>
                                        }
                                        {
                                        !editOffer.allowEdit 
                                            && 
                                        (editOffer.offerImpressionLink ? editOffer.offerImpressionLink : 'NA')
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{paddingTop:'10px'}}>Targeting Countries</td>
                                    <td style={{paddingTop:'10px'}}>
                                        {offerTargetingCountries.map((item,index)=>{
                                            return <div style={{display:'flex',marginBottom:'3px',flexWrap:'wrap'}} key={index}>
                                                        {item.countries.map((item2,index2)=>{
                                                            return <div style={{marginLeft:'2px',marginRight:'2px',border:'1px solid grey',background:'rgb(245,245,245)',width:'max-content',paddingLeft:'5px',paddingRight:'5px'}} key={index2}>
                                                                { item2.toUpperCase() ? Constants.COUNTRIES_LIST_WITH_CODE[item2.toUpperCase()] ? Constants.COUNTRIES_LIST_WITH_CODE[item2.toUpperCase()] : item2.toUpperCase() : item2}
                                                                </div>
                                                        })}
                                                    </div>
                                        })}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{paddingTop:'10px'}}>Offer Description</td>
                                    <td style={{paddingTop:'10px'}}>
                                        {
                                        editOffer.allowEdit
                                        &&
                                        <textarea value={editOffer.offerDescription} onChange={(e)=>{setEditOffer({...editOffer,offerDescription:e.target.value})}}/>
                                        }
                                        {
                                        !editOffer.allowEdit 
                                            && 
                                        (showHtml(editOffer.offerDescription))
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{paddingTop:'10px'}}>Offer KPI</td>
                                    <td style={{paddingTop:'10px'}}>
                                        {
                                        editOffer.allowEdit
                                        &&
                                        <textarea value={editOffer.offerKpi} onChange={(e)=>{setEditOffer({...editOffer,offerKpi:e.target.value})}}/>
                                        }
                                        {
                                        !editOffer.allowEdit 
                                            && 
                                        (showHtml(editOffer.offerKpi))
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{paddingTop:'10px'}}>Offer Payouts</td>
                                    <td style={{paddingTop:'10px'}}>
                                        {
                                        editOffer.allowEdit
                                        &&
                                        <input value={editOffer.offerPayouts} onChange={(e)=>{setEditOffer({...editOffer,offerPayouts:e.target.value})}}/>
                                        }
                                        {
                                        !editOffer.allowEdit 
                                            && 
                                        (editOffer.offerPayouts)
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{paddingTop:'10px'}}>Offer Caps</td>
                                    <td style={{paddingTop:'10px'}}>
                                        {
                                        editOffer.allowEdit
                                        &&
                                        <input value={editOffer.offerCaps} onChange={(e)=>{setEditOffer({...editOffer,offerCaps:e.target.value})}}/>
                                        }
                                        {
                                        !editOffer.allowEdit 
                                            && 
                                        (editOffer.offerCaps)
                                        }
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div style={{display:'flex',alignItems:'center',position:'absolute',bottom:'10px',justifyContent:"flex-end",width:'100%'}}>
                        <Button style={{...Constants.OUTLINEDBUTTON,transform:'scale(0.8)'}} onClick={printDivToPDF}>Print</Button>
                        <Button style={{...Constants.OUTLINEDBUTTON,transform:'scale(0.8)',marginRight:'30px'}} onClick={editOfferFunction}>
                            {!editOffer.allowEdit && 'Edit'}
                            {editOffer.allowEdit && 'Save'}
                        </Button>
                    </div>
                </div>
                }
                <div style={{position:'sticky',alignItems:'center',padding:'10px',top:top,display:'flex',background:'white',borderBottom:'1px solid rgb(200,200,200)',fontSize:'13px',zIndex:100,}}>
                    <div style={{width:'50%',display:'flex',fontWeight:'500',wordWrap:'break-word',wordBreak:'break-all',alignItems:'center'}}>
                        {offerTitle}
                        {offerTitle && <div style={{marginLeft:'10px',marginTop:'2px'}}>
                            <Tooltip title={bookmark ? 'Remove Bookmark' : 'Add Bookmark'} onClick={()=>{setBookmark(!bookmark);changeBookmark()}}>
                                <div>
                                    <StarIcon sx={{position:'absolute',color:'orange',transform:(bookmark ? 'scale(1)' : 'scale(0)'),transition:'all 0.5s linear'}}/>
                                    <StarBorderIcon sx={{color:'orange'}}/>
                                </div>
                            </Tooltip>
                        </div>}
                    </div>
                    <div style={{width:'50%',display:'flex',justifyContent:'flex-end',alignItems:'center',flexWrap:'wrap'}}>
                        <div style={{marginRight:'10px'}} onClick={(e)=>{e.stopPropagation();setShowCopyDataPopup(true);setEditOffer({
                                        offerID,
                                        offerTitle,
                                        offerStatus,
                                        offerPreviewUrl,
                                        offerTrackingUrl,
                                        offerImpressionLink,
                                        offerTargetingCountries,
                                        offerDescription,
                                        offerKpi,
                                        offerPayouts:offerPayouts.length>0 ? offerPayouts[0].payout : 'NA',
                                        offerCaps:offerCaps.length>0 ? offerCaps[0].value : 'NA',
                                        allowEdit:false,
                                    })}}>
                            <Tooltip title='Share Offer'>
                                <IconButton>
                                    <ShareIcon sx={{fontSize:'15px'}}/>
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div>
                            <Button disabled={!offerData} sx={{...Constants.OUTLINEDBUTTON,height:"30px",fontWeight:'900',fontSize:10,marginRight:'20px',textTransform:'capitalize'}} onClick={()=>{duplicateOfferApi(offerData)}}><ContentCopyIcon sx={{fontSize:'16px',marginRight:'5px'}}/>Duplicate Offer</Button>
                        </div>
                        
                        <NavLink to={"/statistics/daily?offerId="+offerID} target="_blank" style={{textDecoration:"none"}}>
                            <Button disabled={!offerData} sx={{...Constants.OUTLINEDBUTTON,height:"30px",fontWeight:'900',fontSize:10,marginRight:'20px'}}><BarChartOutlinedIcon/>{/*View in Stats*/}</Button>
                        </NavLink>
                        <NavLink to={"/offers/edit/"+offerid} style={{textDecoration:"none"}}>
                            <Button disabled={!offerData} sx={{...Constants.CONTAINEDBUTTON,height:"30px",fontWeight:'900',fontSize:10,}}>Edit</Button>
                        </NavLink>
                    </div>
                </div>
                <div style={{display:'flex',marginTop:'3%'}}>            
                    <div style={{width:'65%'}}>
                        <div className='offerViewV2Container' style={{position:'relative'}}>
                            <div style={{position:'absolute',top:'15%',right:'0'}}>
                                <Logo page='offer' hideEdit={true} id={offerid} size={'80px'}/>
                            </div>

                            <div className='titleOfferViewV2'> Offer Details </div>
                            <div className='labelValueOfferView'>
                                <div className='labelOfferView'>
                                    Offer ID
                                </div>
                                <div style={{marginLeft:'1%',marginRight:'1%'}}> : </div>
                                <div className='valueOfferView'>
                                    {offerID}
                                </div>
                            </div>
                            <div className='labelValueOfferView'>
                                <div className='labelOfferView'>
                                    External Offer ID
                                </div>
                                <div style={{marginLeft:'1%',marginRight:'1%'}}> : </div>
                                <div className='valueOfferView'>
                                    {externalOfferId ? externalOfferId : '-'}
                                </div>
                            </div>

                            <div className='labelValueOfferView'>
                                <div className='labelOfferView'>
                                    Package Name
                                </div>
                                <div style={{marginLeft:'1%',marginRight:'1%'}}> : </div>
                                <div className='valueOfferView'>
                                    {offerPackageName ? offerPackageName : '-'}
                                </div>
                            </div>
                            <div className='labelValueOfferView' style={{alignItems:'center'}}>
                                <div className='labelOfferView'>
                                    Status
                                </div>
                                <div style={{marginLeft:'1%',marginRight:'1%'}}> : </div>
                                <div className='valueOfferView'>
                                    <div
                                        style={{
                                            color:(offerStatus==='active' ? 'green' : offerStatus==='paused' ? 'orange' : 'red'),
                                            background:(offerStatus==='active' ? 'rgb(176, 239, 176)' : offerStatus==='paused' ? 'rgb(255, 234, 195)' : 'rgb(255, 188, 188)'),
                                            border: "1px solid "+(offerStatus==='active' ? 'green' : offerStatus==='paused' ? 'orange' : 'red'),
                                            borderRadius:"5px",
                                            padding:'2px',
                                            width:'max-content',
                                            paddingLeft:"10px",
                                            paddingRight:"10px",
                                            // display:'flex',
                                            // justifyContent:'center'
                                        }}
                                    >
                                        {offerStatus ? capitalize(offerStatus) : '-'}
                                    </div>
                                </div>
                            </div>

                            <div className='labelValueOfferView'>
                                <div className='labelOfferView'>
                                    Offer Source
                                </div>
                                <div style={{marginLeft:'1%',marginRight:'1%'}}> : </div>
                                <div className='valueOfferView'>
                                    {offerSource ? capitalize(offerSource) : '-'}
                                </div>
                            </div>
                            <div className='labelValueOfferView'>
                                <div className='labelOfferView'>
                                    Offer Expiry
                                </div>
                                <div style={{marginLeft:'1%',marginRight:'1%'}}> : </div>
                                <div className='valueOfferView'>
                                    {offerExpiry ? offerExpiry : '-'}
                                </div>
                            </div>
                            <div className='labelValueOfferView'>
                                <div className='labelOfferView'>
                                    Privacy Level
                                </div>
                                <div style={{marginLeft:'1%',marginRight:'1%'}}> : </div>
                                <div className='valueOfferView'>
                                    {offerType ? capitalize(offerType) : '-'}
                                </div>
                            </div>
                            <div className='labelValueOfferView'>
                                <div className='labelOfferView'>
                                    Advertiser
                                </div>
                                <div style={{marginLeft:'1%',marginRight:'1%'}}> : </div>
                                <div className='valueOfferView'>
                                    {offerAdvertiser ? 
                                        capitalize(offerAdvertiser.label) + " (" + offerAdvertiser.value + ")"
                                        :
                                        "-"
                                    }
                                </div>
                            </div>
                            <div className='labelValueOfferView'>
                                <div className='labelOfferView'>
                                    Tracking URL
                                </div>
                                <div style={{marginLeft:'1%',marginRight:'1%'}}> : </div>
                                <div className='valueOfferView'>
                                    {offerTrackingUrl ? offerTrackingUrl : "-"}
                                </div>
                            </div>
                            <div className='labelValueOfferView'>
                                <div className='labelOfferView'>
                                    Preview URL
                                </div>
                                <div style={{marginLeft:'1%',marginRight:'1%'}}> : </div>
                                <div className='valueOfferView'>
                                    {offerPreviewUrl ? <a href={offerPreviewUrl} target='_blank' rel='noreferrer' style={{textDecoration:'none'}}>{offerPreviewUrl}</a> : "-"}
                                </div>
                            </div>
                            <div className='labelValueOfferView'>
                                <div className='labelOfferView'>
                                    Impression Link
                                </div>
                                <div style={{marginLeft:'1%',marginRight:'1%'}}> : </div>
                                <div className='valueOfferView'>
                                    {offerImpressionLink ? offerImpressionLink : "-"}
                                </div>
                            </div>

                            <div className='labelValueOfferView'  style={{alignItems:'center'}}>
                                <div className='labelOfferView'>
                                    Categories
                                </div>
                                <div style={{marginLeft:'1%',marginRight:'1%'}}> : </div>
                                <div className='valueOfferView' style={{display:'flex',flexWrap:'wrap'}}>
                                    {offerCategories.map((item,index)=>{
                                        return <div className='offerCategoriesViewV2' key={index}>
                                                {item}
                                            </div>
                                    })}
                                    {offerCategories.length===0 && "-"}
                                </div>
                            </div>

                            <div className='labelValueOfferView'>
                                <div className='labelOfferView'>
                                    OS
                                </div>
                                <div style={{marginLeft:'1%',marginRight:'1%'}}> : </div>
                                <div className='valueOfferView' style={{display:'flex',flexWrap:'wrap'}}>
                                    {offerPreviewUrl ? offerPreviewUrl.includes("apps.apple") ? "IOS" : offerPreviewUrl.includes("play.google") ? "Android" : "NA" : "NA"}
                                </div>
                            </div>

                            <div className='labelValueOfferView'>
                                <div className='labelOfferView'>
                                    Countries
                                </div>
                                <div style={{marginLeft:'1%',marginRight:'1%'}}> : </div>
                                <div className='valueOfferView' style={{display:'block',}}>
                                    {offerTargetingCountries.map((item,index)=>{
                                        return <div style={{display:'flex',marginBottom:'3px',flexWrap:'wrap'}} key={index}>
                                                {item.countries.map((item2,index2)=>{
                                                    return <div style={{marginLeft:'2px',marginRight:'2px',border:'1px solid grey',background:'rgb(245,245,245)',width:'max-content',paddingLeft:'5px',paddingRight:'5px'}} key={index2}>
                                                        { item2.toUpperCase() ? Constants.COUNTRIES_LIST_WITH_CODE[item2.toUpperCase()] ? Constants.COUNTRIES_LIST_WITH_CODE[item2.toUpperCase()] : item2.toUpperCase() : item2}
                                                        </div>
                                                })}
                                            </div>
                                    })}
                                    {offerTargetingCountries.length===0 && "-"}
                                </div>
                            </div>

                            {/* <div className='labelValueOfferView'>
                                <div className='labelOfferView'>
                                    Description
                                </div>
                                <div style={{marginLeft:'1%',marginRight:'1%'}}> : </div>
                                <div className='valueOfferView' style={{display:'flex',flexWrap:'wrap'}}>
                                    {offerDescription ? showHtml(offerDescription) : "-"}
                                </div>
                            </div> */}

                            {/* <div className='labelValueOfferView'>
                                <div className='labelOfferView'>
                                    KPI
                                </div>
                                <div style={{marginLeft:'1%',marginRight:'1%'}}> : </div>
                                <div className='valueOfferView' style={{display:'flex',flexWrap:'wrap'}}>
                                    {offerDescription ? showHtml(offerKpi) : "-"}
                                </div>
                            </div> */}
                        </div>
                        <div className='offerViewV2Container'>
                            <div className='titleOfferViewV2'> Offer KPI </div>
                            <div className='labelValueOfferView valueOfferView' style={{display:'flex',flexWrap:'wrap',wordWrap:'break-word',wordBreak:'break-all',width:'96%',marginLeft:'2%',marginRight:'2%'}}>
                                <div style={{wordWrap:'break-word',wordBreak:'break-all',width:'96%'}}>
                                    {offerKpi ? showHtml(offerKpi) : "-"}
                                </div>
                            </div>
                        </div>
                        <div className='offerViewV2Container'>
                            <div className='titleOfferViewV2'> Offer Description </div>
                            <div className='labelValueOfferView valueOfferView' style={{display:'flex',flexWrap:'wrap',wordWrap:'break-word',wordBreak:'break-all',width:'96%',marginLeft:'2%',marginRight:'2%'}}>
                                <div style={{wordWrap:'break-word',wordBreak:'break-all',width:'96%'}}>
                                    {offerDescription ? showHtml(offerDescription) : "-"}
                                </div>
                            </div>
                        </div>

                        <div className='offerViewV2Container'>
                            <div className='titleOfferViewV2'> Offer Creatives </div>
                            <div className='labelValueOfferView valueOfferView' style={{display:'block',width:'96%',marginLeft:'2%',marginRight:'2%'}}>
                                {
                                    offerCreatives.length>0 && offerCreatives.map((item,index)=>{
                                        return <div style={{paddingLeft:"1%",textAlign:"left",maxWidth:"100%",wordWrap:"break-word"}}>{index+1}. {item}</div>
                                    })
                                }
                                {
                                    offerCreatives.length===0 && <div>{"-"}</div>
                                }
                            </div>
                        </div>
                    </div>
                    <div  style={{width:'35%'}}>

                        {/* <div className='offerViewV2Container'>
                            <div className='titleOfferViewV2'> Offer Stats (last 3 days) </div>
                            <OfferStatsGraph offer_id={offerid}/>
                        </div> */}
                        {process.env.REACT_APP_LINK_TEST_DOMAIN &&
                        <div className='offerViewV2Container'>
                        <div className='titleOfferViewV2'> Offer Link Test Status </div>
                        <div style={{ padding: '12px', paddingLeft: '10px', paddingRight: '10px' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    fontWeight: '500',
                                    fontSize: '12px',
                                    background: 'rgb(250,250,250)',
                                    border: '1px solid rgb(230,230,230)',
                                }}
                            >
                                <div
                                    style={{
                                        width: '35%',
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                        borderRight: '1px solid rgb(230,230,230)',
                                        textAlign: 'center',
                                        wordWrap: 'break-word',
                                        wordBreak: 'break-all',
                                    }}
                                >
                                    &nbsp;&nbsp;Last Tested At
                                </div>
                                <div
                                    style={{
                                        width: '40%',
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                        borderRight: '1px solid rgb(230,230,230)',
                                        wordWrap: 'break-word',
                                        wordBreak: 'break-all',
                                    }}
                                >
                                    &nbsp;&nbsp;Status
                                </div>
                                <div
                                    style={{
                                        width: '25%',
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                        borderRight: '1px solid rgb(230,230,230)',
                                        wordWrap: 'break-word',
                                        wordBreak: 'break-all',
                                    }}
                                >
                                    &nbsp;&nbsp;Test Type
                                </div>
                            </div>
                    
                            <div
                                style={{
                                    maxHeight: '109px', // Adjust this height to show only 3 rows
                                    overflowY: 'auto', // Enable scrolling for additional rows
                                    border: '1px solid rgb(230,230,230)', 
                                    borderTop: 'none',
                                }}
                            >
                                {offerLinkTest && offerLinkTest.map((item, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            display: 'flex',
                                            fontSize: '11px',
                                            borderBottom: '1px solid rgb(230,230,230)',
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: '35%',
                                                wordWrap: 'break-word',
                                                wordBreak: 'break-all',
                                                paddingTop: '5px',
                                                paddingBottom: '5px',
                                                borderRight: '1px solid rgb(230,230,230)',
                                                textAlign: 'center',
                                            }}
                                        >
                                            <div style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                                                {item.updated_at}
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                width: '40%',
                                                wordWrap: 'break-word',
                                                wordBreak: 'break-all',
                                                paddingTop: '5px',
                                                paddingBottom: '5px',
                                                borderRight: '1px solid rgb(230,230,230)',
                                            }}
                                        >
                                            <div style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                                                {Constants.offerWorkingLinkStatusComponent(item.working_status)}
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                width: '25%',
                                                wordWrap: 'break-word',
                                                wordBreak: 'break-all',
                                                paddingTop: '5px',
                                                paddingBottom: '5px',
                                                borderRight: '1px solid rgb(230,230,230)',
                                                textAlign: 'center',
                                            }}
                                        >
                                            <div style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                                                {item.test_type}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {offerLinkTest.length === 0 && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '25px',
                                            fontSize: '11px',
                                            border: '1px solid rgb(230,230,230)',
                                            borderTop: '0px solid transparent',
                                        }}
                                    >
                                        No Link Test Status Available
                                    </div>
                                )}
                            </div>
                            
                            <div
                                style={{
                                    marginTop: '10px',
                                    marginBottom: '10px',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    justifyContent: 'center',
                                }}
                            >
                                <Button
                                    sx={{
                                        ...Constants.OUTLINEDBUTTON,
                                        height: '25px',
                                        width: 'max-content',
                                        fontWeight: '600',
                                        fontSize: 10,
                                        marginRight: '20px',
                                    }}
                                    onClick={() => {
                                        handleOfferLinkTest();
                                    }}
                                >
                                    Test Now
                                </Button>
                            </div>
                        </div>
                    </div>
                    
                        }
                        <div className='offerViewV2Container'>
                            <div className='titleOfferViewV2'> Offer Affiliates </div>
                                <div style={{transform:'scale(0.9)',marginTop:'10px'}}>
                                    <div style={{marginBottom:'5px',fontWeight:'500',fontSize:'12px'}}>Enabled Affiliates</div>
                                    <Select
                                        isMulti
                                        options={affiliateList}
                                        value={enableAffiliateList}
                                        onChange={(e)=>{setEnableAffiliateList(e);setChangesDone(true);}}
                                        menuPortalTarget={document.body} 
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }),
                                                    control: (base,state) => ({
                                                        ...base,
                                                        // maxHeight: 100,
                                                        minHeight: 30,
                                                        overflowY: "auto",
                                                        border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid grey',
                                                        boxShadow: state.isFocused ? '0 0 3px 1px '+localStorage.getItem('ambientColor') : 0,
                                                        '&:hover': {
                                                            border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                                            boxShadow: state.isFocused ? '0 0 1px 1px '+localStorage.getItem('ambientColor') : '0 0 1px 1px '+localStorage.getItem('ambientColor'),
                                                        }
                                                    }),
                                                    option: (styles, {isFocused, isSelected}) => ({
                                                        ...styles,
                                                        fontSize:'11px',
                                                        background: isFocused
                                                            ? localStorage.getItem('ambientColor')
                                                            : isSelected
                                                                ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                                                : undefined,
                                                        zIndex: 1
                                                    }),
                                                }}
                                    />
                                </div>
                                {
                                <div style={{transform:'scale(0.9)',marginTop:'10px'}}>
                                    <div style={{marginBottom:'5px',fontWeight:'500',fontSize:'12px'}}>Disabled Affiliates</div>
                                    <Select
                                        // defaultValue={}
                                        isMulti
                                        options={affiliateList}
                                        value={disableAffiliateList}
                                        onChange={(e)=>{setDisableAffiliateList(e);setChangesDone(true);}}
                                        menuPortalTarget={document.body} 
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }),
                                                    control: (base,state) => ({
                                                        ...base,
                                                        // maxHeight: 100,
                                                        minHeight: 30,
                                                        overflowY: "auto",
                                                        border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid grey',
                                                        boxShadow: state.isFocused ? '0 0 3px 1px '+localStorage.getItem('ambientColor') : 0,
                                                        '&:hover': {
                                                            border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                                            boxShadow: state.isFocused ? '0 0 3px 1px '+localStorage.getItem('ambientColor') : '0 0 3px 1px '+localStorage.getItem('ambientColor'),
                                                        }
                                                    }),
                                                    option: (styles, {isFocused, isSelected}) => ({
                                                        ...styles,
                                                        fontSize:'11px',
                                                        background: isFocused
                                                            ? localStorage.getItem('ambientColor')
                                                            : isSelected
                                                                ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                                                : undefined,
                                                        zIndex: 1
                                                    }),
                                                }}
                                    />
                                </div>
                                }
                            <div style={{marginTop:'10px',marginBottom:'10px',display:'flex',flexWrap:'wrap',justifyContent:'center'}}>
                                <Button disabled={!enableAffiliateList} sx={{...Constants.OUTLINEDBUTTON,height:"25px",width:'max-content',fontWeight:'600',fontSize:10,marginRight:'20px'}} onClick={()=>{handleEnableOffer("approved");setChangesDone(false);}}>Enable Affiliates</Button>
                                <Button disabled={!disableAffiliateList} sx={{...Constants.OUTLINEDBUTTON,height:"25px",width:'max-content',fontWeight:'600',fontSize:10,marginRight:'20px'}} onClick={()=>{handleEnableOffer("denied");setChangesDone(false);}}>Disable Affiliates</Button>
                            </div>
                        </div>
                        <div className='offerViewV2Container'>
                            <div className='titleOfferViewV2'>Affiliate Targeting Url 
                                {process.env.REACT_APP_IMPRESSION_LINK
                                    &&  
                                '/ Pixel'} 
                            </div>
                            <div style={{padding:'12px',paddingLeft:'20px',paddingRight:'20px'}}>
                                <div style={{display:'flex',alignItems:'center'}}>
                                    <div style={{fontWeight:'500',fontSize:'12px'}}>Affiliate</div>
                                    <Select
                                        options={affiliateList}
                                        value={affiliateForLink}
                                        onChange={(e)=>{setAffiliateForLink(e);setGeneratedTrackingLink("");}}
                                        menuPortalTarget={document.body} 
                                        styles={{   menuPortal: base => ({ ...base, zIndex: 9999 }),
                                                    option: styles => ({...styles,fontSize:"12px"}),
                                                    container: provided => ({
                                                        ...provided,
                                                        width: "100%",
                                                        transform:'scale(0.7)'
                                                        }),
                                                }}
                                    />
                                </div>
                                <div style={{marginTop:'5px'}}>
                                    <div style={{fontWeight:'500',fontSize:'12px',display:'flex',alignItems:'center',justifyContent:'space-between',width:'100%'}}>
                                        <div>Link</div>
                                        <div style={{transform:'scale(0.8)'}}><CopyButton idToCopy={'generatedTrackingLink'}/></div>
                                        
                                    </div>
                                    <div id='generatedTrackingLink' style={{minHeight:'25px',fontSize:'11px',padding:'10px',wordWrap:'break-word',wordBreak:'break-all',border:'1px solid rgb(220,220,220)',background:'rgb(250,250,250)'}}>
                                        {generatedTrackingLink}
                                    </div>
                                </div>

                                <div style={{display:"flex",alignItems:'center',justifyContent:'space-between',marginTop:'5px',flexWrap:'wrap'}}>
                                    <div style={{display:'flex',flexWrap:'wrap'}}>
                                        <div style={{display:"flex",fontSize:"13px",fontWeight:"500",alignItems:"center"}}>
                                            Test Link
                                        </div>
                                        <div style={{transform:"scale(0.7)",display:"flex",alignItems:"start"}}>
                                            <Checkbox
                                                disabled={!generatedTrackingLink}
                                                onChange={changeTestLink}
                                                />
                                        </div>
                                    </div>
                                    <div style={{display:"flex",height:"30px",marginTop:'10px'}}>
                                        <Button disabled={!affiliateForLink} sx={{...Constants.OUTLINEDBUTTON,height:"20px",fontWeight:'900',fontSize:10,marginRight:'20px'}} onClick={generateTargettingLink}>Generate</Button>
                                    </div>
                                </div>
                                {process.env.REACT_APP_IMPRESSION_LINK
                                &&    
                                <>
                                    <div style={{marginTop:'5px'}}>
                                        <div style={{fontWeight:'500',fontSize:'12px',display:'flex',alignItems:'center',justifyContent:'space-between',width:'100%'}}>
                                            <div>Impression Pixel</div>
                                            <div style={{transform:'scale(0.8)'}}><CopyButton idToCopy={'generatedPixelLink'}/></div>
                                            
                                        </div>
                                        <div id='generatedPixelLink' style={{minHeight:'25px',fontSize:'11px',padding:'10px',wordWrap:'break-word',wordBreak:'break-all',border:'1px solid rgb(220,220,220)',background:'rgb(250,250,250)'}}>
                                            {generatedPixelLink}
                                        </div>
                                    </div>
                                    
                                    <div style={{display:"flex",alignItems:'center',justifyContent:'space-between',marginTop:'5px',flexWrap:'wrap'}}>
                                        <div style={{display:'flex',flexWrap:'wrap'}}>
                                            <div style={{display:"flex",fontSize:"13px",fontWeight:"500",alignItems:"center"}}>
                                                Pixel Tag
                                            </div>
                                            <div style={{transform:"scale(0.7)",display:"flex",alignItems:"start"}}>
                                                <Checkbox
                                                    checked={addPixelTags}
                                                    disabled={!generatedPixelLink}
                                                    onChange={generatePixel}
                                                    />
                                            </div>
                                        </div>
                                        <div style={{display:"flex",height:"30px",marginTop:'10px'}}>
                                            <Button disabled={!affiliateForLink} sx={{...Constants.OUTLINEDBUTTON,height:"20px",fontWeight:'900',fontSize:10,marginRight:'20px'}} onClick={generatePixel}>Generate Impression</Button>
                                        </div>
                                    </div>
                                </>
                                }

                            </div>
                        </div>
                        <div className='offerViewV2Container'>
                            <div className='titleOfferViewV2'> Offer Caps </div>
                            <div style={{padding:'12px',paddingLeft:'10px',paddingRight:'10px'}}>
                                <div style={{display:'flex',fontWeight:'500',fontSize:'12px',background:'rgb(250,250,250)',border:'1px solid rgb(230,230,230)'}}>
                                    <div style={{width:"15%",paddingTop:'5px',paddingBottom:'5px',borderRight:'1px solid rgb(230,230,230)',wordWrap:'break-word',wordBreak:'break-all'}}>&nbsp;&nbsp;Affiliate</div>
                                    <div style={{width:"15%",paddingTop:'5px',paddingBottom:'5px',borderRight:'1px solid rgb(230,230,230)',wordWrap:'break-word',wordBreak:'break-all'}}>&nbsp;&nbsp;Period</div>
                                    <div style={{width:"20%",paddingTop:'5px',paddingBottom:'5px',borderRight:'1px solid rgb(230,230,230)',wordWrap:'break-word',wordBreak:'break-all'}}>&nbsp;&nbsp;Type</div>
                                    <div style={{width:"15%",paddingTop:'5px',paddingBottom:'5px',borderRight:'1px solid rgb(230,230,230)',wordWrap:'break-word',wordBreak:'break-all'}}>&nbsp;&nbsp;Value</div>
                                    <div style={{width:"15%",paddingTop:'5px',paddingBottom:'5px',borderRight:'1px solid rgb(230,230,230)',wordWrap:'break-word',wordBreak:'break-all'}}>&nbsp;&nbsp;Goal</div>
                                    <div style={{width:"20%",paddingTop:'5px',paddingBottom:'5px',wordWrap:'break-word',wordBreak:'break-all'}}>&nbsp;&nbsp;Countries</div>
                                </div>
                                {offerCaps.map((item,index)=>{
                                    return <div style={{display:'flex',fontSize:'11px',border:'1px solid rgb(230,230,230)',borderTop:'none'}}>
                                                <div style={{width:"15%",wordWrap:'break-word',wordBreak:'break-all',paddingTop:'5px',paddingBottom:'5px',borderRight:'1px solid rgb(230,230,230)'}}><div style={{paddingLeft:'5px',paddingRight:'5px'}}>{item.affiliate}</div></div>
                                                <div style={{width:"15%",wordWrap:'break-word',wordBreak:'break-all',paddingTop:'5px',paddingBottom:'5px',borderRight:'1px solid rgb(230,230,230)'}}><div style={{paddingLeft:'5px',paddingRight:'5px'}}>{item.period}</div></div>
                                                <div style={{width:"20%",wordWrap:'break-word',wordBreak:'break-all',paddingTop:'5px',paddingBottom:'5px',borderRight:'1px solid rgb(230,230,230)'}}><div style={{paddingLeft:'5px',paddingRight:'5px'}}>{item.type}</div></div>
                                                <div style={{width:"15%",wordWrap:'break-word',wordBreak:'break-all',paddingTop:'5px',paddingBottom:'5px',borderRight:'1px solid rgb(230,230,230)'}}><div style={{paddingLeft:'5px',paddingRight:'5px'}}>{item.value}</div></div>
                                                <div style={{width:"15%",wordWrap:'break-word',wordBreak:'break-all',paddingTop:'5px',paddingBottom:'5px',borderRight:'1px solid rgb(230,230,230)'}}><div style={{paddingLeft:'5px',paddingRight:'5px'}}>{item.goal}</div></div>
                                                <div style={{width:"20%",wordWrap:'break-word',wordBreak:'break-all',paddingTop:'5px',paddingBottom:'5px'}}>
                                                    <div style={{paddingLeft:'5px',paddingRight:'5px',display:'flex',flexWrap:'wrap'}}>
                                                        {item.countries.length!==0 ? item.countries.map((item2,index)=>{return <div key={index}>{index!==(item.countries.length-1) ? item2 + "," : item2}</div>}) : "-"}
                                                    </div>
                                                </div>
                                            </div>
                                })}
                                {offerCaps.length===0 && <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'25px',fontSize:'11px',border:'1px solid rgb(230,230,230)',borderTop:'0px solid transparent'}}>
                                                No Caps Available
                                            </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className='offerViewV2Container'>
                    <div className='titleOfferViewV2'> Offer Payouts </div>
                    <div style={{padding:'12px',paddingLeft:'10px',paddingRight:'10px'}}>
                        <div style={{display:'flex',fontWeight:'500',fontSize:'12px',background:'rgb(250,250,250)',border:'1px solid rgb(230,230,230)'}}>
                            <div style={{width:"15%",paddingTop:'5px',paddingBottom:'5px',borderRight:'1px solid rgb(230,230,230)',wordWrap:'break-word',wordBreak:'break-all'}}>&nbsp;&nbsp;Affiliate</div>
                            <div style={{width:"15%",paddingTop:'5px',paddingBottom:'5px',borderRight:'1px solid rgb(230,230,230)',wordWrap:'break-word',wordBreak:'break-all'}}>&nbsp;&nbsp;Country</div>
                            <div style={{width:"11%",paddingTop:'5px',paddingBottom:'5px',borderRight:'1px solid rgb(230,230,230)',wordWrap:'break-word',wordBreak:'break-all'}}>&nbsp;&nbsp;Payment Type</div>
                            <div style={{width:"11%",paddingTop:'5px',paddingBottom:'5px',borderRight:'1px solid rgb(230,230,230)',wordWrap:'break-word',wordBreak:'break-all'}}>&nbsp;&nbsp;Currency</div>
                            <div style={{width:"8%",paddingTop:'5px',paddingBottom:'5px',borderRight:'1px solid rgb(230,230,230)',wordWrap:'break-word',wordBreak:'break-all'}}>&nbsp;&nbsp;Payout</div>
                            <div style={{width:"8%",paddingTop:'5px',paddingBottom:'5px',borderRight:'1px solid rgb(230,230,230)',wordWrap:'break-word',wordBreak:'break-all'}}>&nbsp;&nbsp;Revenue</div>
                            <div style={{width:"8%",paddingTop:'5px',paddingBottom:'5px',borderRight:'1px solid rgb(230,230,230)',wordWrap:'break-word',wordBreak:'break-all'}}>&nbsp;&nbsp;Goal Value</div>
                            <div style={{width:"24%",paddingTop:'5px',paddingBottom:'5px',wordWrap:'break-word',wordBreak:'break-all'}}>&nbsp;&nbsp;Goal Title</div>
                        </div>
                        {offerPayouts.map((item,index)=>{
                            return <div style={{display:'flex',fontSize:'11px',border:'1px solid rgb(230,230,230)',borderTop:'none'}}>
                                        <div style={{width:"15%",wordWrap:'break-word',wordBreak:'break-all',paddingTop:'5px',paddingBottom:'5px',borderRight:'1px solid rgb(230,230,230)'}}>
                                            <div style={{paddingLeft:'5px',paddingRight:'5px'}}>
                                                {item.affiliate_id ? item.affiliate_id : "-"}
                                            </div>
                                        </div>
                                        <div style={{width:"15%",wordWrap:'break-word',wordBreak:'break-all',paddingTop:'5px',paddingBottom:'5px',borderRight:'1px solid rgb(230,230,230)'}}>
                                            <div style={{paddingLeft:'5px',paddingRight:'5px',display:'flex',flexWrap:'wrap'}}>
                                                {item.countries.length!==0 ? item.countries.map((item2,index)=>{return <div key={index}>{index!==(item.countries.length-1) ? item2 + "," : item2}</div>}) : "-"}
                                            </div>
                                        </div>
                                        <div style={{width:"11%",wordWrap:'break-word',wordBreak:'break-all',paddingTop:'5px',paddingBottom:'5px',borderRight:'1px solid rgb(230,230,230)'}}>
                                            <div style={{paddingLeft:'5px',paddingRight:'5px'}}>
                                                {item.payment_type ? item.payment_type : "-"}
                                            </div>
                                        </div>
                                        <div style={{width:"11%",wordWrap:'break-word',wordBreak:'break-all',paddingTop:'5px',paddingBottom:'5px',borderRight:'1px solid rgb(230,230,230)'}}>
                                            <div style={{paddingLeft:'5px',paddingRight:'5px'}}>
                                                {item.currency ? item.currency : "-"}
                                            </div>
                                        </div>
                                        <div style={{width:"8%",wordWrap:'break-word',wordBreak:'break-all',paddingTop:'5px',paddingBottom:'5px',borderRight:'1px solid rgb(230,230,230)'}}>
                                            <div style={{paddingLeft:'5px',paddingRight:'5px'}}>
                                                {item.payout ? item.payout : "-"}
                                            </div>
                                        </div>
                                        <div style={{width:"8%",wordWrap:'break-word',wordBreak:'break-all',paddingTop:'5px',paddingBottom:'5px',borderRight:'1px solid rgb(230,230,230)'}}>
                                            <div style={{paddingLeft:'5px',paddingRight:'5px'}}>
                                                {item.revenue ? item.revenue : "-"}
                                            </div>
                                        </div>
                                        <div style={{width:"8%",wordWrap:'break-word',wordBreak:'break-all',paddingTop:'5px',paddingBottom:'5px',borderRight:'1px solid rgb(230,230,230)'}}>
                                            <div style={{paddingLeft:'5px',paddingRight:'5px'}}>
                                                {item.goal ? item.goal : "-"}
                                            </div>
                                        </div>
                                        <div style={{width:"24%",wordWrap:'break-word',wordBreak:'break-all',paddingTop:'5px',paddingBottom:'5px'}}>
                                            <div style={{paddingLeft:'5px',paddingRight:'5px'}}>
                                                {item.title ? item.title : "-"}
                                            </div>
                                        </div>
                                    </div>
                        })}
                        {offerPayouts.length===0 && <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'25px',fontSize:'11px',border:'1px solid rgb(230,230,230)',borderTop:'0px solid transparent'}}>
                                No Payouts Available
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default ViewOfferPageV2;