export const getFilterDict = (filtersList) => {
    const dict_ = {}
    var url = new URL(window.location.href)
    if(url.searchParams.get('dateRange') || url.searchParams.get('date_range')){
        if(url.searchParams.get('dateRange')){
            var fd = url.searchParams.get('dateRange')
        }
        else{
            fd = url.searchParams.get('date_range')
        }
        dict_.from_date = new Date(fd.split("_")[0])
        dict_.end_date = new Date(fd.split("_")[1])
    }
    for(var index in filtersList){
        var item = filtersList[index]
        if((item.paramKey && item.paramKey!=='dateRange') || item.value){
            if(url.searchParams.get(item.paramKey) || url.searchParams.get(item.value)){
                if(url.searchParams.get(item.paramKey)){
                    var dd = url.searchParams.get(item.paramKey)
                }
                else{
                    dd = url.searchParams.get(item.value)
                }
                if(item.type==='textfield'){
                    dict_[item.value] = dd
                }
                if(item.type==='select'){
                    if(!item.isSingle){
                        var filterValueList = dd.split(',') 
                        var valueList = []
                        for(var filterValueIndex in filterValueList){
                            var filterValueItem = filterValueList[filterValueIndex]
                            valueList.push({
                                label:filterValueItem.split('-')[0] + ' - '+ filterValueItem.split('-')[1],
                                value:filterValueItem.split('-')[1],
                            })
                        }
                        dict_[item.value] = valueList
                    }
                    else{
                        for(var optionIndex in item.options){
                            var optionItem = item.options[optionIndex]
                            if(optionItem.value===null){
                                optionItem.value = null
                            }
                            else if(optionItem.value===undefined){
                                optionItem.value = undefined
                            }
                            else if(optionItem.value===false){
                                optionItem.value = 'false'
                            }
                            if(dd && optionItem.value && dd.toString() === optionItem.value.toString()){
                                if(optionItem.value==='false'){
                                    optionItem.value = false
                                }
                                dict_[item.value] = optionItem
                                break;
                            }
                        }
                    }
                    
                }
            }
        }
    }
    return dict_
}

export const updateUrlFilter = (filtersList,filterData) => {
    var url = window.location.pathname
    url+='?'
    for(var index in filtersList){
        var item = filtersList[index]
        if((item.paramKey==='dateRange' || item.value==='date_range') && (filterData.from_date && filterData.end_date)){
            url+= 'date_range='+filterData.from_date.toLocaleString('fr-CA',{year:'numeric',month:'numeric',day:'numeric'})+'_'+filterData.end_date.toLocaleString('fr-CA',{year:'numeric',month:'numeric',day:'numeric'})+'&'
        }
        if(filterData[item.value]){
            if((item.paramKey && item.paramKey!=='dateRange') || (item.value && item.value!=='date_range')){
                var dd = filterData[item.value]
                if(item.type==='textfield'){
                    url += [item.value] + '='+ dd+'&'
                }
                if(item.type==='select'){
                    if(!item.isSingle){
                        var str__ = ''
                        for(var filterValueIndex in dd){
                            var filterValueItem = dd[filterValueIndex]
                            str__+=filterValueItem.label.replace(' ','')+','
                        }
                        if(str__.length>0){
                            str__ = str__.slice(0,-1)
                        }
                        url += [item.value] + '='+ str__+'&'
                    }
                    else{
                        if(filterData[item.value].value || filterData[item.value].value===false){
                            url += [item.value] + '='+ filterData[item.value].value.toString()+'&'
                        }
                    }
                    
                }
            }
        }
    }
    return url;
}


export const lastQuery = (item) => {
    if(item){
        if(window.location.pathname.includes('statisticsV6')){
            var initPath = '/statisticsV6/'
        }
        else{
            initPath = '/statistics/'
        }
        if(window.location.href.includes('?')){
            return initPath+item.pathname+'?'+window.location.href.split('?')[1]
        }
        else{
            return initPath+item.pathname
        }
    }
}