import { useState, useEffect } from "react";
import PaymentHeader from "./components/PaymentHeader";
import { getLocalStorageValue } from "../insightsManik/util/util.js"
import FilterRow from "../../../components/filterV2/filtersRow";
import { getAdvertisers, getManager } from '../../../apis/dropdown/dropdowns';
import { AdvertiserIcon, AdvertiserManagerIcon, AppIcon, DateRangeIcon, MoneyIcon, SpiralIcon } from '../../../data/icons';
import { getRequests } from "../../../util/util";
import * as Constants from '../../../data/constants';
import axios from "axios";
import YearlyPaymentSynopsis from "./components/YearlyPaymentSynopsis.jsx";
import MonthlyPaymentSynopsis from "./components/MonthlyPaymentSynopsis.jsx";
import PaymentTopAdvertisers from "./components/PaymentTopAdvertisers.jsx";

function PaymentInsights() {

  var defaultFilterData = {
    startMonth: new Date(new Date().getFullYear(), new Date().getMonth() - 3, 1),
    endMonth: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    selectedRev: { label: 'Verified Revenue', value: 'Verified Revenue' }
  }


  const [selectedRev, setSelectedRev] = useState(defaultFilterData.selectedRev.value);
  const [initdate, setInitdate] = useState(new Date(defaultFilterData.startMonth));
  const [finaldate, setFinaldate] = useState(new Date(defaultFilterData.endMonth));
  const [advapp, setAdvapp] = useState("Top Advertisers (Top 10)");
  const [advertiser, setAdvertiser] = useState([]);
  const [sortOrder, setSortOrder] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [appData, setAppData] = useState({})
  const [packageName, setPackageName] = useState([]);
  const [manager, setManager] = useState([]);
  const [reset, setReset] = useState(true);

  const [optionsAdvertiserList, setOptionsAdvertiserList] = useState([])
  const [optionsManagersList, setOptionsManagerList] = useState([])
  var advData = optionsAdvertiserList
  var advertiserString = "";
  var packageString = "";
  var managerString = "";

  const appPackageMap = new Map();
  for (let key in appData) {
    appPackageMap.set(appData[key].app_name, key);
  }
  for (let i = 0; i < advertiser.length; i++) {
    if (i === advertiser.length - 1)
      advertiserString += advertiser;
    else
      advertiserString += advertiser + ",";
  }
  for (let i = 0; i < packageName.length; i++) {
    if (i === packageName.length - 1)
      packageString += packageName;
    else
      packageString += packageName + ",";
  }
  for (let i = 0; i < manager.length; i++) {
    if (i === manager.length - 1) managerString += manager;
    else managerString += manager + ",";
  }

  const swappedMap = new Map();
  for (let i = 0; i < advData.length; i++) {
    if (advData[i]) {
      swappedMap.set(advData[i].value, advData[i].label.split(' - ')[0]);
    }
  }

  const [chartData2, setChartData2] = useState({
    panel_revenue: {
      month: [],
      validation_revenue: [],
      payment_received: [],
      payment_pending: [],
      payment_received_percentage: [],
      payment_pending_percentage:[]
    },
    manual_revenue: {
      month: [],
      validation_revenue: [],
      payment_received: [],
      payment_pending: [],
      payment_received_percentage: [],
      payment_pending_percentage:[]
    },
  });

  const [tableData2, setTableData2] = useState({
    panel_revenue: [],
    manual_revenue: [],
  });
  const getFormatedDate = (date) => {
    const originalDate = new Date(date);
    const year = originalDate.getFullYear();
    const month = (originalDate.getMonth() + 1).toString().padStart(2, "0");
    return `${year}-${month}`;
  };

  const [optionsAppList, setOptionsAppList] = useState([])
  const [optionsPackageNameList, setOptionsPackageNameList] = useState([])

  const getAllManager = async() => {
    try{
      const response = await axios({
        method: 'GET',
        url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"employees"),
        headers:{
          Authorization:getLocalStorageValue('adminPanelLoginData').token
        },
      });
      const managerlist = {};
      const data = response.data.result;
      for (const item of data) {
        managerlist[item.id] = item['employee_type'];
      }
      setOptionsManagerList(prevOptionsManagersList => {
        return prevOptionsManagersList.filter(manager => {
          const id = manager.value;
          return managerlist[id] === 'affiliate_manager' || managerlist[id] === 'account_manager';
        });
      });
    }
    catch (err) {
      console.error(err);
      return {};
    }
  };  

  useEffect(() => {

    getRequests(getAdvertisers(), setOptionsAdvertiserList)
    getRequests(getManager(), setOptionsManagerList)
    getAllManager()
    axios.get(
      "".concat(Constants.PROTOCOL, Constants.HOST, "/api/admin/v1/packagename_appname"),
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    )
      .then((response) => {
        const data = response.data.result;
        var packageNameList = []
        var appNameList = []
        for (var package_name in data) {
          var item = data[package_name]
          if (item.app_name) {
            appNameList.push({ label: item.app_name, value: package_name })
          }
          packageNameList.push({ label: package_name, value: package_name })
        }
        setAppData(data)
        setOptionsAppList(appNameList)
        setOptionsPackageNameList(packageNameList)
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const token = getLocalStorageValue('adminPanelLoginData').token;
  var sort = "deduction_revenue_manual";
  var sorting = false;
  var group = "advertiser_id";
  if (advapp === "Top Advertisers (Top 10)") {
    sort = "payment_received";
    group = "advertiser_id";
  }
  if (advapp === "Top Apps (Top 10)") {
    sort = "payment_received";
    group = "package_name";
  }
  if (advapp === "Pending Advertisers (Top 10)") {
    group = "advertiser_id";
    sort = "payment_received_pending";
  }

  if (advapp === "Pending Apps (Top 10)") {
    group = "package_name";
    sort = "payment_received_pending";
  }
  const getStatsData = async () => {
    await axios
      .get(
        "".concat(Constants.PROTOCOL, Constants.HOST, "/api/admin/v2/invoice/summary"),
        {
          headers: {
            Authorization: `${token}`,
          },
          params: {
            fromMonth: getFormatedDate(initdate),
            toMonth: getFormatedDate(finaldate),
            // limit:10,
            page: 1,
            total_row_required: true,
            group_by: group,
            sorting_key: sort,
            sorting_order: sorting,
            manager_id: managerString,
            advertiser_id: advertiser != "" ? advertiserString : null,
            package_name: packageName != "" ? packageString : null
          }
        }
      )
      .then((response) => {
        const chartData = {
          panel_revenue: {
            month: [],
            validation_revenue: [],
            payment_received: [],
            payment_pending: [],
            payment_received_percentage: [],
            payment_pending_percentage:[]
          },
          manual_revenue: {
            month: [],
            validation_revenue: [],
            payment_received: [],
            payment_pending: [],
            payment_received_percentage: [],
            payment_pending_percentage:[]
          },
        };
        const tableData = { panel_revenue: [], manual_revenue: [] };
        const data = response.data.result;
        if (group === "advertiser_id") {
          for (let i = 0; i < data.length; i++) {
            if (i < 10) {
              chartData.panel_revenue.month.push(
                swappedMap.get(data[i].advertiser_id.toString())
              );
              chartData.panel_revenue.validation_revenue.push(
                data[i].manager_approved_revenue
              );
              chartData.panel_revenue.payment_pending.push(data[i].payment_received_pending);
              chartData.panel_revenue.payment_received.push(data[i].payment_received);
              chartData.panel_revenue.payment_received_percentage.push(data[i].payment_received == 0 ? 0 : (data[i].payment_received / data[i].manager_approved_revenue) * 100);
              chartData.panel_revenue.payment_pending_percentage.push(data[i].payment_received_pending_percent);
            }
          }
          for (let i = 0; i < data.length; i++) {
            if (i < 10) {
              chartData.manual_revenue.month.push(
                swappedMap.get(data[i].advertiser_id.toString()) ? swappedMap.get(data[i].advertiser_id.toString()) : data[i].advertiser_id.toString()
              );
              chartData.manual_revenue.validation_revenue.push(
                data[i].manager_approved_revenue
              );
              chartData.manual_revenue.payment_pending.push(data[i].payment_received_pending);
              chartData.manual_revenue.payment_received.push(data[i].payment_received);
              chartData.manual_revenue.payment_received_percentage.push(data[i].payment_received == 0 ? 0 : (data[i].payment_received / data[i].manager_approved_revenue) * 100);
              chartData.manual_revenue.payment_pending_percentage.push(data[i].payment_received_pending_percent);
            }
          }
          setChartData2(chartData);
        } else if (group === "package_name") {
          for (let i = 0; i < data.length; i++) {
            if (i < 10) {
              chartData.panel_revenue.month.push(data[i].package_name);
              chartData.panel_revenue.validation_revenue.push(
                data[i].manager_approved_revenue
              );
              chartData.panel_revenue.payment_pending.push(data[i].payment_received_pending);
              chartData.panel_revenue.payment_received.push(data[i].payment_received);
              chartData.panel_revenue.payment_received_percentage.push(data[i].payment_received == 0 ? 0 : (data[i].payment_received / data[i].manager_approved_revenue) * 100);
              chartData.panel_revenue.payment_pending_percentage.push(data[i].payment_received_pending_percent);
            }
          }
          for (let i = 0; i < data.length; i++) {
            if (i < 10) {
              chartData.manual_revenue.month.push(data[i].package_name);
              chartData.manual_revenue.validation_revenue.push(
                data[i].manager_approved_revenue
              );
              chartData.manual_revenue.payment_pending.push(data[i].payment_received_pending);
              chartData.manual_revenue.payment_received.push(data[i].payment_received);
              chartData.manual_revenue.payment_received_percentage.push(data[i].payment_received == 0 ? 0 : (data[i].payment_received / data[i].manager_approved_revenue) * 100);
              chartData.manual_revenue.payment_pending_percentage.push(data[i].payment_received_pending_percent);
            }
          }
          setChartData2(chartData);
        } else {
          for (let i = 0; i < data.length; i++) {
            if (i < 10) {
              chartData.panel_revenue.month.push(data[i].offer_id);
              chartData.panel_revenue.validation_revenue.push(
                data[i].manager_approved_revenue
              );
              chartData.panel_revenue.payment_pending.push(data[i].payment_received_pending);
              chartData.panel_revenue.payment_received.push(data[i].payment_received);
              chartData.panel_revenue.payment_received_percentage.push(data[i].payment_received == 0 ? 0 : (data[i].payment_received / data[i].manager_approved_revenue) * 100);
              chartData.panel_revenue.payment_pending_percentage.push(data[i].payment_received_pending_percent);
            }
          }
          for (let i = 0; i < data.length; i++) {
            if (i < 10) {
              chartData.manual_revenue.month.push(data[i].offer_id);
              chartData.manual_revenue.validation_revenue.push(
                data[i].manager_approved_revenue
              );
              chartData.manual_revenue.payment_pending.push(data[i].payment_received_pending);
              chartData.manual_revenue.payment_received.push(data[i].payment_received);
              chartData.manual_revenue.payment_received_percentage.push(data[i].payment_received == 0 ? 0 : (data[i].payment_received / data[i].manager_approved_revenue) * 100);
              chartData.manual_revenue.payment_pending_percentage.push(data[i].payment_received_pending_percent);
            }
          }
          setChartData2(chartData);
        }
        if (group === "advertiser_id") {
          for (let i = 0; i < data.length; i++) {
            tableData.panel_revenue.push([
              swappedMap.get(data[i].advertiser_id.toString()) ? swappedMap.get(data[i].advertiser_id.toString()) : data[i].advertiser_id.toString(),
              data[i].manager_approved_revenue,
              data[i].payment_received,
              data[i].payment_received == 0 ? 0 : (data[i].payment_received / data[i].manager_approved_revenue) * 100,
              data[i].payment_received_pending,
              data[i].payment_received_pending_percent
            ]);

            tableData.manual_revenue.push([
              swappedMap.get(data[i].advertiser_id.toString()) ? swappedMap.get(data[i].advertiser_id.toString()) : data[i].advertiser_id.toString(),
              data[i].manager_approved_revenue,
              data[i].payment_received,
              data[i].payment_received == 0 ? 0 : (data[i].payment_received / data[i].manager_approved_revenue) * 100,
              data[i].payment_received_pending,
              data[i].payment_received_pending_percent
            ]);
          }
          setTableData2(tableData);
        } else if (group === "package_name") {
          for (let i = 0; i < data.length; i++) {
            tableData.panel_revenue.push([
              data[i].app_name,
              data[i].manager_approved_revenue,
              data[i].payment_received,
              data[i].payment_received == 0 ? 0 : (data[i].payment_received / data[i].manager_approved_revenue) * 100,
              data[i].payment_received_pending,
              data[i].payment_received_pending_percent
            ]);

            tableData.manual_revenue.push([
              data[i].app_name,
              data[i].manager_approved_revenue,
              data[i].payment_received,
              data[i].payment_received == 0 ? 0 : (data[i].payment_received / data[i].manager_approved_revenue) * 100,
              data[i].payment_received_pending,
              data[i].payment_received_pending_percent
            ]);
          }
          setTableData2(tableData);
        } else {
          for (let i = 0; i < data.length; i++) {
            tableData.panel_revenue.push([
              data[i].offer_id,
              data[i].manager_approved_revenue,
              data[i].payment_received,
              data[i].payment_received == 0 ? 0 : (data[i].payment_received / data[i].manager_approved_revenue) * 100,
              data[i].payment_received_pending,
              data[i].payment_received_pending_percent
            ]);

            tableData.manual_revenue.push([
              data[i].offer_id,
              data[i].manager_approved_revenue,
              data[i].payment_received,
              data[i].payment_received == 0 ? 0 : (data[i].payment_received / data[i].manager_approved_revenue) * 100,
              data[i].payment_received_pending,
              data[i].payment_received_pending_percent
            ]);
          }
          setTableData2(tableData);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  useEffect(() => {
    if (advData) {
      getStatsData()
    }
  }, [initdate, finaldate, advapp, sortColumn, sortOrder, advData, reset, manager, advertiser, packageName]);


  const [filterData, setFilterData] = useState(defaultFilterData)
  var filtersList = [
    { label: 'Date Range', icon: DateRangeIcon, paramKey: 'dateRange', value: 'date_range', type: 'monthPicker' },
    { label: 'App Name', icon: AppIcon, paramKey: 'appData', value: 'appData', type: 'select', multiple: true, hideValueInLabel: true, options: optionsAppList },
    { label: 'Package Name', icon: SpiralIcon, paramKey: 'packageName', value: 'package_name', type: 'select', multiple: true, hideValueInLabel: true, options: optionsPackageNameList },
    { label: 'Revenue', icon: MoneyIcon, paramKey: 'selectedRev', value: 'selectedRev', type: 'select', hideValueInLabel: true, options: [{ label: 'Verified Revenue', value: 'Verified Revenue' }, { label: 'Panel Revenue', value: 'Panel Revenue' }] },
    { label: 'Advertiser', icon: AdvertiserIcon, paramKey: 'advertiser', value: 'advertiser_id', type: 'select', multiple: true, options: optionsAdvertiserList },
    { label: 'Account Manager', icon: AdvertiserManagerIcon, paramKey: 'advertiserManager', value: 'advertiser_manager_id', type: 'select', multiple: true, options: optionsManagersList },
    { label: 'Submit Reset Button', type: 'submit_reset_button', onSubmit: getStatsData, onReset: () => { setFilterData(defaultFilterData); setPackageName([]); setSelectedRev('Verified Revenue'); setAdvertiser([]); setManager([]); setReset(!reset) } },
  ]
  const overrideSetFilterData = (value, filterItem) => {
    if (filterItem.value === 'appData' || filterItem.value === 'package_name') {
      var __list = []
      for (var index in value) {
        var item = value[index]
        __list.push(item.value)
      }
      setPackageName(__list)
    }
    if (filterItem.value === 'selectedRev') {
      setSelectedRev(value.value)
    }
    if (filterItem.value === 'advertiser_id') {
      var __list = []
      for (var index in value) {
        var item = value[index]
        __list.push(item.value)
      }
      setAdvertiser(__list)
    }
    if (filterItem.value === 'advertiser_manager_id') {
      var __list = []
      for (var index in value) {
        var item = value[index]
        __list.push(item.value)
      }
      setManager(__list)
    }


    if (filterItem === 'dateRangePicker') {
      setFilterData({ ...filterData, ...value })
    }
    else if (filterItem.type === 'monthPicker') {
      setInitdate(value[0])
      setFinaldate(value[1] ? value[1] : value[0])
      setFilterData({ ...filterData, startMonth: value[0], endMonth: value[1] })
    }
    else {
      setFilterData({ ...filterData, [filterItem.value]: value })
    }
  }


  return <div className="insightsManik">
    <PaymentHeader />
    <FilterRow
      filtersList={filtersList}
      filterData={filterData}
      setFilterData={overrideSetFilterData}
      filterItemWidth={'25'}
    />
    <YearlyPaymentSynopsis
      selectedRev={selectedRev}
      advertiser={advertiser}
      packageName={packageName}
      manager={manager}
      advData={advData}
      advertiserString={advertiserString}
      packageString={packageString}
      managerString={managerString}
      appPackageMap={appPackageMap}
      setAdvertiser={setAdvertiser}
      setPackageName={setPackageName}
      setManager={setManager}
      setSelectedRev={setSelectedRev}
      setFilterData={setFilterData}
      filterData={filterData}
      initdate={getFormatedDate(initdate)}
      finaldate={getFormatedDate(finaldate)}
    />
    <MonthlyPaymentSynopsis
      initdate={getFormatedDate(initdate)}
      finaldate={getFormatedDate(finaldate)}
      selectedRev={selectedRev}
      advertiser={advertiser}
      packageName={packageName}
      advData={advData}
      manager={manager}
      advertiserString={advertiserString}
      packageString={packageString}
      managerString={managerString}
      appPackageMap={appPackageMap}
      setAdvertiser={setAdvertiser}
      setPackageName={setPackageName}
      setManager={setManager}
      setSelectedRev={setSelectedRev}
      setFilterData={setFilterData}
      filterData={filterData}
    />
    <PaymentTopAdvertisers
      advapp={advapp}
      setAdvapp={setAdvapp}
      chartData={
        selectedRev === "Verified Revenue"
          ? chartData2.manual_revenue
          : chartData2.panel_revenue
      }
      tableData2={tableData2}
      setTableData2={setTableData2}
      selectedRev={selectedRev}
      advertiser={advertiser}
      packageName={packageName}
      initdate={getFormatedDate(initdate)}
      finaldate={getFormatedDate(finaldate)}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      sortColumn={sortColumn}
      setSortColumn={setSortColumn}
      advData={advData}
      manager={manager}
      appPackageMap={appPackageMap}
      managerString={managerString}
      setAdvertiser={setAdvertiser}
      setPackageName={setPackageName}
      setManager={setManager}
      setSelectedRev={setSelectedRev}
      setFilterData={setFilterData}
      filterData={filterData}
      advertiserString={advertiserString}
      packageString={packageString}
    />
  </div>
}

export default PaymentInsights

