import React, { useState } from 'react'
import '../../css/pages/exports.css';
import * as Constants from '../../data/constants';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { Button } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { getLocalStorageValue } from '../../util/util';

export const ExportPage = () => {
    const [manualUsestate, setManualUsestate] = useState(false)
    const [exportList, setExportList] = useState([])
    const [showLoader, setShowLoader] = useState(false)

    const getExportsList = async() => {
        setShowLoader(true)
        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL2,Constants.HOST2,Constants.API_USER_PATH,Constants.GETSTATS,"/","export/view"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
        })
        .then(resp => {
            if(resp.data.result){
                setExportList(resp.data.result)
            }
            setShowLoader(false)
        })
        .catch(err => {
            console.error(err);
        })
    };

    if(!manualUsestate){
        window.document.title="Export Data"
        setManualUsestate(true) 
        getExportsList()
    }

    const status = ["Started","Finished","In Progress","Aborted"]
    const showRows = () => {
        var options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour:'numeric',minute:'numeric', };

        return exportList.map((item,index)=>{
            return <tr key={index}>
                        <td className='tableData'>
                            <div style={{color:(item.csv_status==='0' ? 'rgb(31, 181, 240)' : item.csv_status==='1' ? 'green' : item.csv_status==='2' ? 'orange' : 'red')}}>{status[item.csv_status]}</div>
                        </td>
                        <td className='tableData'>
                            {item.type}
                        </td>
                        <td className='tableData'>
                            {new Date(item.created_at).toLocaleDateString("en-US", options)}
                        </td>
                        <td className='tableData'>
                            {new Date(item.updated_at).toLocaleDateString("en-US", options)}
                        </td>
                        <td className='tableData' style={{display:'flex',justifyContent:'center'}}>
                            <NavLink to={'/exportData/downloadData/?filename='+item.filename} style={{textDecoration:'none'}} target="_blank" rel="noopener noreferrer">
                                <Button variant='contained' color='warning' size='small'>
                                    Download
                                </Button>
                            </NavLink>
                            
                        </td>
                    </tr>
        })    
    }

    const showExports = () => {
        return <table style={{width:'100%'}}>
            <thead>
                <tr>
                    <td className='tableHead' style={{minWidth:'10%'}}>
                        Status
                    </td>
                    <td className='tableHead' style={{minWidth:'10%'}}>
                        Type
                    </td>
                    <td className='tableHead' style={{minWidth:'10%'}}>
                        Created At
                    </td>
                    <td className='tableHead' style={{minWidth:'10%'}}>
                        Updated At
                    </td>
                    <td className='tableHead' style={{minWidth:'10%',display:'flex',justifyContent:'center'}}>
                        Download
                    </td>
                </tr>
            </thead>
            <tbody>
                {showRows()}
            </tbody>
        </table>
    }
        
    return (
        <div className='containerExport'>
            <div style={{display:'block',padding:'2%',width:'98%'}}>
                <div style={{display:'flex',width:'100%'}}>
                    <div style={{display:'flex',justifyContent:'flex-start',width:'50%',fontSize:'12px'}}>
                        Found {exportList.length} exports
                    </div>
                </div>
                    
                {showLoader && 
                    <div style={{display:'flex',width:'100%',height:'500px',justifyContent:'center',alignItems:'center'}}>
                        <CircularProgress color="warning"/> 
                    </div>
                }
                {!showLoader && 
                    <div style={{width:'100%',marginTop:'5%'}}>
                        {showExports()}
                    </div>
                }
            </div>
        </div>
    )
}

export default ExportPage;