import axios from 'axios'
import React, { useEffect, useState } from 'react'
import * as Constants from '../../../data/constants';
import { getLocalStorageValue } from '../../../util/util';
import FilterRow from '../../../components/filterV2/filtersRow';
import { DateRangeIcon } from '@mui/x-date-pickers';
import { RoomService } from '@mui/icons-material';
import DashboardCards from '../../../components/dashboardCards/dashboardCards';

const LinkTestResults = () => {

    const [filterData, setFilterData] = useState({
        from_date:new Date(new Date().getTime()),
        end_date:new Date(new Date().getTime()),
    })
    const [responseData, setResponseData] = useState({})
    const getSummaryData = async() => {
        var from_date = filterData.from_date.toLocaleString('fr-CA',{year:'numeric',month:'numeric',day:'numeric'})
        var end_date = filterData.end_date.toLocaleString('fr-CA',{year:'numeric',month:'numeric',day:'numeric'})
        await axios({
            method:'GET',
            url:"".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,'offerlinktest/results'),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params:{
                start_date:from_date,
                end_date:end_date,
            }
        })
        .then(resp => {
            setResponseData({
                '1':resp.data.result['1'] ? resp.data.result['1'] : 0,
                '0':resp.data.result['0'] ? resp.data.result['0'] : 0,
                'null':resp.data.result['null'] ? resp.data.result['null'] : 0,
                '2':resp.data.result['2'] ? resp.data.result['2'] : 0,
                'total':resp.data.result['total'] ? resp.data.result['total'] : 0,
            })
        })
    }
    useEffect(() => {
        getSummaryData()
        
        return () => {
            getSummaryData()
        }
    }, [])


    const dummyComponent = () => {
        return <div className='v2filterItemContainer' style={{width:'calc(20% - 4px)',background:'white'}}>
            </div>
    }

    var tabOptions = [
        {label:'Not Working',value:'0,2'},
        {label:'Working',value:'1'},
    ]

    var filtersList = [
        {label:'Date Range',paramKey:'dateRange',value:'date_range',type:'daterangepicker',icon:DateRangeIcon},
        {label:'',paramKey:'',value:'',type:'select',options:[],hideValueInLabel:true,icon:RoomService},
        {label:'Submit Reset Button',type:'submit_reset_button',onSubmit:()=>{getSummaryData()},onReset:()=>{}},
        {label:'',paramKey:'',value:'',type:'',customComponent:dummyComponent},
        {label:'',paramKey:'',value:'',type:'',customComponent:dummyComponent},
    ]
    
    const getNotWorking = () => {
        if(responseData && responseData['0']){
            if(responseData['2']){
                return responseData['0']+responseData['2']
            }
            return responseData['0']
        }
        return 0
    }
    return (
        <div style={{padding:'20px',paddingTop:'0px'}}>
            <div style={{fontFamily:'Oswald',fontSize:'42px'}}>Linktest Service</div>
            <FilterRow
                filtersList={filtersList}
                filterData={filterData}
                setFilterData={setFilterData}
                filterItemWidth={33}
            />
            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
               <DashboardCards styles={{borderRadius:'30px',background:"url('success_tile_bg.png')"}} value={responseData['total'] ? responseData['total'] : 0} label={'Total Links'}  cardWidth={'20%'} cardHeight={'80px'}/>
               <DashboardCards styles={{borderRadius:'30px',background:"url('success_tile_bg.png')"}} value={responseData['1'] ? responseData['1'] : 0} label={'Working Links'}  cardWidth={'20%'} cardHeight={'80px'}/>
               <DashboardCards styles={{borderRadius:'30px',background:"url('success_tile_bg.png')"}} value={getNotWorking()} label={'Not Working Links'}  cardWidth={'20%'} cardHeight={'80px'}/>
               <DashboardCards styles={{borderRadius:'30px',background:"url('success_tile_bg.png')"}} value={responseData['null'] ? responseData['null'] : 0} label={'Lost Links'}  cardWidth={'20%'} cardHeight={'80px'}/>
            </div>
        </div>
    )
}

export default LinkTestResults