import React from 'react';
import '../insightsCss.css';
function PercentageDiv({ percentage }) {
  
  let backgroundColor, borderColor, textColor,borderWidth,borderRadius;

  if (percentage < 30) {
    backgroundColor = 'rgba(255,0, 0, 0.1)';
    borderColor = '#FF0000';
    borderWidth = '1px'; 
    textColor = '#FF0000';
    borderRadius='3px';
  } else if (percentage < 70) {
    backgroundColor = 'rgba(220, 191, 0, 0.1)';
    borderColor = '#DCBF00';
    borderWidth = '1px'; 
    textColor = '#DCBF00';
    borderRadius='3px';
  } else {
    backgroundColor = 'rgba(14, 174, 0, 0.1)';
    borderColor = '#0EAE00';
    textColor = '#0EAE00';
    borderWidth = '1px'; 
    borderRadius='3px';
  }

  const divStyle = {
    backgroundColor,
    borderColor,
    color: textColor,
    borderWidth, 
    borderRadius,
    borderStyle: 'solid'
  };

  return (
    <div className="percentage-div" style={divStyle}>
      {percentage}%
    </div>
  );
}

export default PercentageDiv;
