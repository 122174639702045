import React, { useState,forwardRef ,useRef,useEffect} from 'react'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './monthRangePicker.css';

import {format} from 'date-fns';


const MonthRangePickerComponent=({initdate,setInitdate,finaldate,setFinaldate,onChange}) => {
    const [startDate, setStartDate] = useState(new Date(initdate));
    const [endDate, setEndDate] = useState(finaldate);
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const handleChange = ([newStartDate, newEndDate]) => {
        if(newEndDate){
            setIsOpen(!isOpen);
        }
        setStartDate(newStartDate);
        setEndDate(newEndDate);
        setInitdate(newStartDate);
        if(newEndDate)
            setFinaldate(newEndDate);
        else
            setFinaldate(newStartDate);
    };

    const handleClick = (e) => {
        e.preventDefault();
        setIsOpen(!isOpen);
    };
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [initdate,finaldate]);

    return (
        <div ref={dropdownRef} onClick={e=>{e.stopPropagation()}}>
            <button
                onClick={handleClick}
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    background:'white',
                    padding:'10px',
                    outline:'none',
                    border:'1px solid rgb(200,200,200)'
                }}
            >
                <div style={{ display: "flex", alignItems: "center" }}>
                {startDate && format(startDate, "MMM-yy")}
                {endDate && ` - `}
                {endDate && format(endDate, "MMM-yy")}
                </div>
            </button>
            {isOpen && (
                <div style={{ position: "absolute", marginLeft: "0rem" }}>
                <DatePicker
                    selected={startDate}
                    onChange={handleChange}
                    selectsRange
                    startDate={startDate}
                    endDate={endDate}
                    showMonthYearPicker
                    maxDate={new Date()}
                    showFourColumnMonthYearPicker
                    inline
                />
                </div>
            )}
        </div>
    );
};
export default MonthRangePickerComponent;