import React from 'react'
import '../css/router.css';
// import Topbar from './topbar';
import Sidebar from './sidebarMain';
import SidebarV2 from './sidebarV2/sidebarV2';
// import TopbarV2 from './topbarV2/topbarV2';
import {BrowserRouter,Route,Switch,Redirect} from 'react-router-dom';
import {HelperTabs,sidebarTabs} from '../data/sidebarTabs';
import PageNotFound from '../components/pageNotFound.js';
import Cookies from 'universal-cookie';
import RightBar from './rightBar';
import { getLocalStorageValue, sidebartabAllowed } from '../util/util';

export const Router = (props) => {
    var sidebarOpen = props.sidebarOpen
    const cookies = new Cookies();
    var allowedPages = localStorage.getItem('allowedPages') ? localStorage.getItem('allowedPages').split(",") : []
    return (
        <div>
            <BrowserRouter>
                { (cookies.get('newSidebar')==='false' || !cookies.get('newSidebar')) &&
                    <>
                        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={props.setSidebarOpen}/>
                    </>
                }
                {
                    (cookies.get('newSidebar')==='true') &&
                    <div style={{zIndex:'1000'}}>
                        {/* <TopbarV2 sidebarOpen={sidebarOpen} /> */}
                        <SidebarV2 sidebarOpen={sidebarOpen} setSidebarOpen={props.setSidebarOpen}/>
                        <RightBar/>
                    </div>
                }
                <div id='main' className={sidebarOpen ? 'mainWindowSidebarOpen' : 'mainWindowSidebarClose'} style={{
                        background:"rgb(255, 255, 255)",
                        transition: "all 0.7s ease-in-out",paddingTop:((cookies.get('newSidebar')==='false' || !cookies.get('newSidebar')) ? '60px' : '0px'),marginTop:((cookies.get('newSidebar')==='false' || !cookies.get('newSidebar')) ? '0px' : '60px')}}>
                    <Switch>
                        <Route exact path="/">
                            {<Redirect to="/dashboard" />}
                        </Route>
                        {sidebarTabs.map((item,index) => {
                            if(sidebartabAllowed(item.link,item)){
                                if (item.subNav){
                                    return item.subNav.map((subNavItem,index)=>{
                                        if(sidebartabAllowed(item.link,item)){
                                            return (
                                                <Route exact key={subNavItem.link} path={subNavItem.link} component={subNavItem.page}/>
                                            )
                                        }
                                        return null;
                                    }
                                    )}
                                return (
                                    <Route exact key={item.link} path={item.link} component={item.page}/>
                                ) 
                            }
                            return null;
                               
                        })}
                        {HelperTabs.map((item,index) => {
                                return (
                                    <Route key={item.link} path={item.link} component={item.page}/>
                                )    
                        })} 
                        <Route path='*' exact={true} component={PageNotFound} ></Route>   
                </Switch>
            </div>

            </BrowserRouter>
        </div>
    )
}


export default Router;
