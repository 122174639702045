import img from '../assets/menu.svg';

function Header() {
  return (
    <div className='header1'>
      {/* <img src={img} alt="menu" className='menu-img'/> */}
      <div style={{fontFamily:"Oswald,sans-serif",fontSize:"2rem"}}>Insights</div>
    </div>
  );
}

export default Header;