import React, { useEffect, useState } from 'react'
import { getThisWeek } from '../../components/dateRangePicker/dateRangePicker'
import FilterRow from '../../components/filterRow/filterRow'
import { Button, Pagination, Tooltip } from '@mui/material'
import * as Constants from '../../data/constants';
import axios from 'axios';
import { bytesToSize, getLocalStorageValue } from '../../util/util';
import TableModel from '../../components/tableModel/tablemodel';
import ShowLoaderComponent from '../../components/loader';
import { Store } from 'react-notifications-component';
import ReactSelect from 'react-select';
import changeConversionImage from '../../img/changeConversion.png'
import { getFilterDict, updateUrlFilter } from './filterSearchAndSet';
import { InfoRounded } from '@mui/icons-material';

const FailedPostbacksV4 = (props) => {

    var optionsAdvertiserList= props.optionsAdvertiserList
    var optionsManagersList= props.optionsManagersList
    var optionsAffiliateList= props.optionsAffiliateList

    const [loader,setLoader] = useState(false)
    const [limit, setLimit] = useState(50);
    const [page, setPage] = useState(1);
    const [offset, setOffset] = useState(0);
    const [totalResults, setTotalResults] = useState(0);
    const [tableValues,setTableValues] = useState([])
    const [loadingSeconds, setLoadingSeconds] = useState(0)
    const [responseSize, setResponseSize] = useState(0)
    const [tableValuesSelected,setTableValueSelected] = useState([])
    const [groupByRequested, setGroupByRequest] = useState(false);
    const [summaryCheckbox, setSummaryCheckbox] = useState(process.env.REACT_APP_FAILED_POSTBACK_SUMMARY ? true : false)
    const [summaryCheckboxApiDone, setSummaryCheckboxApiDone] = useState(process.env.REACT_APP_FAILED_POSTBACK_SUMMARY ? true : false)
    var filtersList = [
        {label:'Date Range',value:'date_range',type:'daterangepicker'},
        {label:'Offer Id',value:'offer_id',type:'textfield'},
        {label:'Advertiser',value:'advertiser_id',type:'textfield',multiple:true,options:optionsAdvertiserList},
        {label:'Goal Value',value:'goal',type:'textfield'},
        {label:'Reason',value:'reason',type:'select',isClearable:true,isSingle:true,options:[{label:'Invalid click_id',value:'invalid-click-id'},{label:'Blank click_id',value:'blank'},{label:'Duplicate click_id and goal',value:'duplicate-click-goal'}]},
        {label:'p_sub1',value:'p_sub1',type:'textfield'},
        {label:'p_sub2',value:'p_sub2',type:'textfield'},
        {label:'p_sub3',value:'p_sub3',type:'textfield'},
        {label:'p_sub4',value:'p_sub4',type:'textfield'},
        {label:'Group By',value:'group_by',type:'select',isClearable:true,isSingle:true,options:[{label:'Advertiser',value:'advertiser_id'},{label:'External Offer ID',value:'offer_id'},{label:'p_sub1',value:'p_sub1'},{label:'Reason',value:'reason'},{label:'Goal',value:'goal_value'}],show:true},
    ]
    
    var defaultFilterData = {
        from_date:new Date(),
        end_date:new Date(),
        show_converted_stats:{label:'Yes',value:true},
    }
    const [filterData, setFilterData] = useState({...defaultFilterData,...getFilterDict(filtersList)})
    
    const getStatsData = async(e,manual_limit,manual_page) => {
        setLoader(true)
        setSummaryCheckboxApiDone(false)
        setOffset((manual_limit ? manual_limit : limit)*(manual_page ? (manual_page-1) : (page-1)))
        var start_time = new Date().getTime()
        var from_date = filterData.from_date.toLocaleString('fr-CA',{year:'numeric',month:'numeric',day:'numeric'})
        var end_date = filterData.end_date.toLocaleString('fr-CA',{year:'numeric',month:'numeric',day:'numeric'})
        
        var filter_dict = {}
        for(var index in filtersList){
            var item = filtersList[index]
            if(item.type==='textfield'){
                if(filterData[item.value]){
                    filter_dict[item.value] = filterData[item.value]
                }
            }
            if(item.type==='select' && !item.isSingle){
                var value = ''
                for(var xIndex in filterData[item.value]){
                    var xItem = filterData[item.value][xIndex]
                    if(parseInt(xIndex)===filterData[item.value].length-1){
                        value+= xItem.value
                    }
                    else{
                        value+= xItem.value + ','
                    }
                }
                if(value || value===0){
                    filter_dict[item.value] = value
                }
            }
            if(item.type==='select' && item.isSingle){
                if(filterData[item.value] && filterData[item.value].value){
                    filter_dict[item.value] = filterData[item.value].value
                }
            }
            
        }
        var requestPage = manual_page ? manual_page : page
        var requestLimit = manual_limit ? manual_limit : limit

        if(filter_dict.group_by){
            var url = "".concat(Constants.PROTOCOL2,Constants.HOST2,Constants.API_USER_PATH,"failed/postback/count")
        }
        else if(summaryCheckbox){
            setSummaryCheckboxApiDone(true)
            url =  "".concat(Constants.PROTOCOL2,Constants.HOST2,Constants.API_USER_PATH,"failedpostbacksummary")
        }
        else{
            url = "".concat(Constants.PROTOCOL,Constants.HOST2,Constants.API_USER_PATH,"failed/postback")
        }
        await axios({
            method: 'GET',
            url,
            headers: {
                "Content-Type":"application/json",
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params: {
                startDate:from_date,
                endDate:end_date,
                limit:requestLimit,
                page:requestPage,
                events_only: window.location.href.includes('conversions/events') ? 1 : undefined,
                ...filter_dict
              }
        })
        .then(resp => {
            var response = resp.data.result
            setTableValues(response)
            setTotalResults(resp.data.count)
            const resSize = +resp?.headers?.['content-length']
            setResponseSize(resSize)
        })
        .catch((error) => {
            if (error.response) {
                console.log(error.response.data);
                if(error.response.data.result){
                }
                else{
                }
                console.log(error.response.status);
                console.log(error.response.headers);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        })

        if(filter_dict.group_by){
            setGroupByRequest(filter_dict.group_by);
            setLoader(false);
        }
        else{
            setGroupByRequest(false);
            setLoader(false);
        }
        setLoader(false)
        var end_time = new Date().getTime()
        setLoadingSeconds((end_time-start_time)/1000)
    };
    const overrideSetFilterData = (value,filterItem) => {
        if(filterItem==='dateRangePicker'){
            setFilterData({...filterData,...value})
        }
        else{
            setFilterData({...filterData,[filterItem.value]:value})
            setSummaryCheckbox(false)
        }
    }

    useEffect(() => {
        getStatsData()
        
        return () => {
        }
    }, [])
    

    const dateComponent = (valueItem,item) => {
        return new Date(valueItem[item.value]).toLocaleString('en-US',{month:'short',day:'numeric',hour:'2-digit',minute:'2-digit',second:'2-digit'})
    }
    const statusComponent = (valueItem,item) => {
        var status = {
            'declined':{
                highlightColor :'#ffcccc',
                color :'#ff0000',
                label:'Declined'
            },
            'approved':{
                highlightColor :'#d2ffcc',
                color :'#0f8500',
                label:'Approved'
            }
        }
        var highlightColor = status[valueItem[item.value]].highlightColor
        var color = status[valueItem[item.value]].color
        var label = status[valueItem[item.value]].label
        return <div style={{color,fontWeight:500,background:highlightColor,padding:'5px',width:'max-content',borderRadius:'10px',border:'1px solid'+color}}>
            {label}
        </div>
    }

    const tooltipComponent = (valueItem,item) => {
        var value = valueItem[item.value]
        if(value || value === 0){
            return <Tooltip title={value}>
                {value}
            </Tooltip>
        }
        else{
            return '-'
        }
    }
    var tempTableHeaders = [
        {show:true,label:'Date',locked:true,value:'created_at',width:130,customComponent:dateComponent},
        {label:'Click ID',value:'click',width:90,textAlign:'left',customComponent:tooltipComponent},
        {label:'Offer ID',value:'offer_id',width:80,customComponent:tooltipComponent},
        {label:'Advertiser ID',value:'advertiser_id',width:80,customComponent:tooltipComponent},
        {label:'Goal Value',value:'goal_value',width:80,customComponent:tooltipComponent},
        {label:'Reason',value:'reason',width:130,textAlign:'left',customComponent:tooltipComponent},
        {label:'p_sub1',value:'p_sub1',type:'textfield',width:110,textAlign:'left',customComponent:tooltipComponent},
        {label:'p_sub2',value:'p_sub2',type:'textfield',width:110,textAlign:'left',customComponent:tooltipComponent},
        {label:'p_sub3',value:'p_sub3',type:'textfield',width:110,textAlign:'left',customComponent:tooltipComponent},
        {label:'p_sub4',value:'p_sub4',type:'textfield',width:110,textAlign:'left',customComponent:tooltipComponent},
    ]
    const [multipleActions, setMultipleActions] = useState(false)
    const [multipleActionLoader, setMultipleActionLoader] = useState(false)
    const handleMultipleActions = async() => {
        setMultipleActionLoader(true)
        var id_list = tableValuesSelected.map((item)=>{return tableValues[item].id})
        await axios({
            method: 'POST',
            url:"".concat(Constants.PROTOCOL,Constants.HOST2,Constants.API_USER_PATH,"conversions/update/new"),
            headers: {
                "Content-Type":"application/json",
                Authorization:getLocalStorageValue('adminPanelLoginData').token
              },
            data: {
                "type" : {
                        "status" : multipleActions.status ? multipleActions.status : undefined,     
                        "revenue":multipleActions.revenue ? parseFloat(multipleActions.revenue) : undefined,     
                        "payout" : multipleActions.payout ? parseFloat(multipleActions.payout)  : undefined, 
                        "currency":multipleActions.currency ? multipleActions.currency : undefined
                    },  
                "ids": id_list,
              }
        })
        .then(resp => {
            setTableValueSelected([]);
            // getStatsData(1,limit,1)
            setMultipleActions(false);
            Store.addNotification({
                title: "Success!",
                message: resp.data.result,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 2000,
                  onScreen: true
                },
              });
            })
        .catch((error) => {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                Store.addNotification({
                    title: "Error!",
                    message: error.response.data.result,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 2000,
                      onScreen: true
                    }
                  });
    
                } else if (error.request) {
                console.log(error.request);
                } else {
                console.log('Error', error.message);
                }
        })   
        setMultipleActionLoader(false)
    }


    const getTableHeaders = () => {
        var map = {
            'advertiser_id':'Advertiser',
            'offer_id':'External Offer ID',
            'p_sub1':'psub1',
        }
        return [
            {label:map[groupByRequested],value:groupByRequested,width:'50%'},
            {label:'Count',value:'count',width:'50%'},
        ]
    }
    if(groupByRequested){
        tempTableHeaders = getTableHeaders()
    }
    if(summaryCheckboxApiDone){
        tempTableHeaders = [
            {show:true,label:'Date',locked:true,value:'date',width:130},
            {label:'Total Failed Postback',hideSorting:true,value:'total_count'},
            {label:'Blank Click ID Count',hideSorting:true,value:'blank_click_id_count'},
            {label:'Invalid Click ID Count',hideSorting:true,value:'invalid_click_id_count'},
            {label:'Duplicate Click Goal',hideSorting:true,value:'duplicate_click_goal_count'},
        ]
    }
    return (
        <>
            <div style={{position:'sticky',left:0,width:'100%',zIndex:100}}>
                {!loader && tableValuesSelected.length===0 && <div style={{position:'absolute',fontSize:'13px',left:5,bottom:-45}}>Showing {offset+1} - {(offset+limit < totalResults) ? offset+limit : totalResults} out of {totalResults}</div>}
                {/* {!loader && <div style={{position:'absolute',fontSize:'10px',left:5,bottom:-45}}>Data Loaded in {loadingSeconds} sec {responseSize ? '('+bytesToSize(responseSize)+')' : ''}</div>} */}
                <div className='demographyRouterWrapper'>
                    <div className='demographyFilterRow'>
                        <div className='demographyFilterAddRow'>
                            <FilterRow filtersList={filtersList} 
                            onEnterFunction={()=>{getStatsData(0,limit,1)}}
                            filterData={filterData} setFilterData={overrideSetFilterData}/>
                        </div>
                        {process.env.REACT_APP_FAILED_POSTBACK_SUMMARY
                        &&
                        <div>
                            <input checked={summaryCheckbox} id='summaryCheckbox' type='checkbox' onChange={(e)=>{setFilterData({from_date:filterData.from_date,end_date:filterData.end_date});setSummaryCheckbox(e.target.checked)}}/>
                            <label style={{fontSize:'14px'}} htmlFor='summaryCheckbox'>Show Reason Summary</label>
                            <Tooltip title={"This will group the raw data on the basis of reason and date."}>
                                <InfoRounded sx={{fontSize:'12px'}}/>
                            </Tooltip>
                        </div>
                        }
                        <br/>
                        <br/>
                        <div>
                            <Button 
                                onClick={()=>{getStatsData();window.history.pushState('Statistics - Failed Postback', 'Statistics - Failed Postback', updateUrlFilter(filtersList,filterData));}}
                                sx={{...Constants.CONTAINEDBUTTON,textTransform:'capitalize',transform:'scale(0.8)',transformOrigin:'left'}}>
                                Search
                            </Button>
                            <Button sx={{...Constants.OUTLINEDBUTTON,textTransform:'capitalize',transform:'scale(0.8)',transformOrigin:'left'}}>Reset</Button>
                        </div>
                        <div style={{marginTop:'20px',fontSize:'12px',cursor:'pointer',width:'max-content'}} onClick={()=>{localStorage.setItem('statsV2',true);window.location.reload();}}>
                            Switch To Old Design
                        </div>
                    </div>
                </div>
            </div>
            {   tableValuesSelected
                    &&
                    tableValuesSelected.length>0
                    &&
                    <div className='valueSelectedChangesWrapper'>
                        <div style={{margin:'20px'}}>
                            {tableValuesSelected.length +' rows selected.'}
                        </div>
                        <div style={{margin:'20px',cursor:'pointer',display:'flex',alignItems:'center'}}>
                            <div id='status' onClick={(e)=>{e.stopPropagation();setMultipleActions({});}} style={{cursor:'pointer',paddingLeft:'10px',paddingRight:'10px',color:'white'}}>
                                Multiple Actions
                            </div>
                        </div>
                    </div>
                }
            {loader
            &&
            <ShowLoaderComponent/>
            }
            {!loader
                &&
                typeof tableValues === 'object' && 
                tableValues.length>0 
                &&
                <TableModel
                    // showCheckbox={true}
                    // valuesCheckbox={tableValuesSelected}
                    // setValuesCheckbox={setTableValueSelected}
                    tableHeaders={tempTableHeaders}
                    topRowStyles={{background:'rgb(230,230,230)'}}
                    serialOffset={offset}
                    // setTableHeaders={setTableHeaders}
                    tableValues={tableValues}
                    setTableValues={setTableValues}
                    style={{background:'rgb(245,245,245)'}}
                    tableValueRow={'rest'}
                    setTableValueRow={()=>{console.log('test')}}
                    // totalRow={totalRow.length>0 ? totalRow : undefined}
                    // setTotalRow={setTotalRow}
                    // sortingFunction={sortingFunction}
                    // exportFunction={()=>{getRevenueData(null,null,null,null,null,true)}}
                    />
                }
            {!loader && totalResults/limit>1 && typeof tableValues === 'object' && tableValues.length>0 && 
                <div style={{display:'flex',alignItems:'center',justifyContent:"space-between",paddingLeft:'20px',paddingRight:'20px',paddingBottom:'20px',position:'sticky',bottom:0,left:0,background:'white',zIndex:1000}}>
                    <div>
                        <label style={{paddingRight:"5px",fontSize:'14px'}}>Limit</label>
                        <select value={limit} style={{outline:'none',border:'none'}} onChange={(e)=>{getStatsData(e,e.target.value,1);setLimit(parseInt(e.target.value));setPage(1);}}>
                            {[25,50,75,100].map((item,index)=>{
                                return <option key={index} value={item}>{item}</option>
                            })}
                        </select>
                    </div>
                    <Pagination count={Math.ceil(totalResults/limit)} page={page} onChange={(event,value)=>{getStatsData(event,limit,value);setPage(value);window.scrollTo({top: 0,left: 0,behavior: 'smooth'});}} />
                </div>
            }
            {multipleActions && <div style={{position:'fixed',top:0,left:0,zIndex:5000,height:'100vh',width:'100vw',display:'flex',alignItems:'center',justifyContent:'center'}} onClick={()=>{setMultipleActions(false);}}>
                <div style={{background:'white',border:'1px solid rgb(220,220,220)',height:'60vh',width:'60vw',display:'flex',alignItems:'center'}} onClick={(e)=>{e.stopPropagation()}}>
                    <img src={changeConversionImage} alt='' width='40%'/>
                    <div style={{borderLeft:'1px solid rgb(220,220,220)',display:'flex',flexDirection:'column',paddingLeft:'30px',paddingRight:'30px',width:'100%'}}>
                        <div style={{display:'flex',justifyContent:"space-between",width:'100%'}}>

                            <label style={{fontSize:'20px',fontWeight:'500',marginBottom:'30px'}}>
                                Change Postback.
                            </label>
                            <div style={{fontSize:'13px',cursor:'pointer'}} onClick={()=>{setMultipleActions(false)}}>
                                X
                            </div>
                        </div>
                        <div style={{width:'60%',marginBottom:'10px'}}>
                            <label style={{fontSize:'12px',fontWeight:'500'}}>Status</label>
                            <div style={{fontSize:'13px'}}>
                                <ReactSelect 
                                    options={[{label:'Approve',value:'approved'},{label:'Decline',value:'declined'}]}
                                    defaultValue={multipleActions.status}
                                    onChange={(e)=>{setMultipleActions({...multipleActions,status:e.value});}}
                                    menuPortalTarget={document.body} 
                                    styles={{...Constants.ReactSelectStyles}}/>
                            </div>
                        </div>
                        <div style={{width:'60%',marginBottom:'10px'}}>
                            <label style={{fontSize:'12px',fontWeight:'500'}}>Currency</label>
                            <div style={{fontSize:'13px',maxHeight:'30vh'}}>
                                <ReactSelect 
                                    options={Object.keys(Constants.CURRENCY_LIST).map((currencyItem,currencyIndex)=>{return {label:Constants.CURRENCY_LIST[currencyItem],value:currencyItem}})}
                                    defaultValue={multipleActions.currency}
                                    onChange={(e)=>{setMultipleActions({...multipleActions,currency:e.value})}}
                                    menuPortalTarget={document.body} 
                                    styles={{...Constants.ReactSelectStyles}}/>
                            </div>
                        </div>
                        <div style={{width:'60%',marginBottom:'10px'}}>
                            <label style={{fontSize:'12px',fontWeight:'500'}}>Revenue</label>
                            <Constants.CssTextField placeholder='Revenue' fullWidth size='small' sx={{'& .MuiOutlinedInput-root':{fontSize:'13px'}}}
                                onChange={(e)=>{setMultipleActions({...multipleActions,revenue:e.target.value})}}
                            />
                        </div>
                        <div style={{width:'60%',marginBottom:'30px'}}>
                            <label style={{fontSize:'12px',fontWeight:'500'}}>Payout</label>
                            <Constants.CssTextField placeholder='Payout' fullWidth size='small' sx={{'& .MuiOutlinedInput-root':{fontSize:'13px'}}}
                                onChange={(e)=>{setMultipleActions({...multipleActions,payout:e.target.value})}}
                            />
                        </div>
                        <Button disabled={multipleActionLoader} sx={{...Constants.CONTAINEDBUTTON,height:'35px',fontSize:'15px',width:'100px',textTransform:'none'}} onClick={handleMultipleActions}>Save</Button>
                    </div>
                </div>
                </div>}
        </>
    )
}

export default FailedPostbacksV4