import { Button, CircularProgress } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react'
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { increase_brightness } from '../../components/customization/customization';
import '../../css/pages/v2/advertiser.css';
import '../../css/pages/v2/users.css';
import * as Constants from '../../data/constants';
import { getLocalStorageValue, useForceUpdate, capitalize } from '../../util/util';
import { Store } from 'react-notifications-component';
import { NavLink } from 'react-router-dom';
import CopyButton from '../../components/copyButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Logo from '../../components/logoUpdater/logo';
import Validations from '../../util/validations';
import { sidebarTabs } from '../../data/sidebarTabs';

const AddEditUser = () => {
    
    var refreshComponent = useForceUpdate()
    if(window.location.href.includes('/edit')){
        var tempEmployeeId = window.location.href.split('/edit/')[1]
    }
    else{
        tempEmployeeId = false
    }
    if(tempEmployeeId){
        document.title = 'Edit User - '+tempEmployeeId
    }
    else{
        document.title = 'Add User'
    }

    const employeeTypeOptions = [
        {label:'General Manager',value:'general_manager'},
        {label:'Account Manager',value:'account_manager'},
        {label:'Affiliate Manager',value:'affiliate_manager'},
        {label:'Billing Manager',value:'billing_manager'},
    ]

    const employeePermissionMapping = {
        Permissions:["permission"],
        Statistics:["dailystatistics","weeklystatistics","offerstatistics","daywiseoffersstatistics","advertiserstatistics","advertisermanagerstatistics","affiliatestatistics","affiliatemanagerstatistics","postbacksstatistics","failedpostbacksstatistics","smartlinksstatistics","goalsstatistics","trafficbackstatistics","customstatistics"],
        Offers:["offers","offerapprovalrequest","offerdeclined",'offerbookmarks'],
        Advertisers:["advertiser"],
        Affiliates:["affiliate","affiliatepassword","loginaffiliatepanel","affiliateapikey","localpostback","globalpostback","disabledadvertiser","affiliateofferfilteration"],
        Automation:["recheckstatus"],
        Offersync:["offersync","demandsource","offersfetched"],
        Smartlinks:["smartlinks"],
        'Manager Stats':["manager_stats"],
        User:["employee",'employeepassword',"accountinfo","login_affiliate","group","user","userlogs"],
        Exports:['exportstats'],
        Others:['app_name','team1','team2','team3','billing_only']
    }
    const [employeeId, setEmployeeId] = useState(tempEmployeeId)
    const [manualUseEffect, setManualUseEffect] = useState(false)
    const [response, setResponse] = useState({})
    const [buttonLoader, setButtonLoader] = useState(false)

    const [changesDone,setChangesDone] = useState({})
    const [validationFields, setValidationFields] = useState({
        first_name : false,
        last_name : false,
        email : employeeId ? true : false,
        password : false,
    })
    const getEmployeeDetails = async() => {
        setButtonLoader(true)
        var url = "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"employee/",employeeId,"/view")

        await axios({
            method: 'GET',
            url,
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
        })
        .then(async(resp) => {
            var responseResult = resp.data.result[0]
            setEmployeeId(responseResult.id)
            for(var employeeTypeIndex in employeeTypeOptions){
                if(responseResult['employee_type'] === employeeTypeOptions[employeeTypeIndex].value){
                    responseResult['employee_type'] = employeeTypeOptions[employeeTypeIndex]
                    break;
                }
            }
            responseResult['password'] = ''
            var tempValidationFields = validationFields
            for(var index in responseResult){
                if(responseResult[index] || index==='password'){
                    tempValidationFields = checkValidation(index,responseResult[index],tempValidationFields)
                }
            }
            setResponse(responseResult)
        })
        .catch(async(error) => {
            console.log(error)
            if (error.response) {
                // Request made and server responded
                console.log(error.response.status);
                console.log(error.response.headers);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
        });
        setButtonLoader(false);
    }

    const changePassword = async() => {
        if(response.password){
            return await axios({
                method: 'POST',
                url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"employee/changepassword"),
                headers:{
                    Authorization:getLocalStorageValue('adminPanelLoginData').token,
                    "Content-Type":"application/json",
                },
                data:{
                    email : response.email,
                    new_password: response.password,
                }
            })
            .then((resp) => {
                return true
            })
            .catch(err => {
                if(err.response && err.response.data.result){
                    Store.addNotification({
                        title: "Error!",
                        message: err.response.data.result,
                        type: "warning",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                          duration: 2000,
                          onScreen: true
                        }
                      });
                }
                return false
            });
        }
        else{
            return true;
        }
    }
    const [allPermissions, setAllPermissions] = useState({})
    const [valueKeyAllPermission, setValueKeyAllPermission] = useState({})
    const [permissionResponse, setPermissionResponse] = useState({})
    const getAllPermissions = async() => {
        setButtonLoader(true)
        var url = "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"permissions")

        await axios({
            method: 'GET',
            url,
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
        })
        .then(async(resp) => {
            var resultPermissions = {}
            var mappingValueKey = {}
            for(var permissionIndex in resp.data.result){
                if(!resp.data.result[permissionIndex].startsWith('page_')){
                    mappingValueKey[resp.data.result[permissionIndex]] = permissionIndex
                    var foundFlag = false;
                    var permissionName = resp.data.result[permissionIndex].replace('add_','').replace('change_','').replace('delete_','').replace('view_','')
                    var permissionNameAsItIs = resp.data.result[permissionIndex]
                    for(var definedPermissionIndex in employeePermissionMapping){
                        console.log(definedPermissionIndex)
                        if(!resultPermissions[definedPermissionIndex]){
                            resultPermissions[definedPermissionIndex] = []
                        }
                        if(employeePermissionMapping[definedPermissionIndex].includes(permissionName)){
                            foundFlag = true;
                            break;
                        }
                        else{
                            foundFlag = false;
                        }
                    }
                    if(foundFlag){
                        if(!resultPermissions[definedPermissionIndex].includes(permissionNameAsItIs)){
                            resultPermissions[definedPermissionIndex].push(permissionNameAsItIs)
                        }
                    }
                    else{
                        if(!resultPermissions['Others'].includes(permissionNameAsItIs)){
                            resultPermissions['Others'].push(permissionNameAsItIs)
                        }
                    }
                }
            }
            setValueKeyAllPermission(mappingValueKey)
            setAllPermissions(resultPermissions)
            setPermissionResponse(resp.data.result)

        })
        .catch(async(error) => {
            console.log(error)
        });
        setButtonLoader(false);
    }

    const showEmployeePermissionsRequest = async() => {
        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"employee","/",employeeId,"/permissions"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
        }
        )
        .then((resp) => {
            var response = resp.data.result
            var selectedpermission = []
            for (var permission in response){
                selectedpermission.push(permission)
            }
            setPermissionSelected(selectedpermission)
        })
        .catch(err => {
            console.error(err);
        })
    };
    if(!manualUseEffect){
        setManualUseEffect(true);
        if(employeeId){
            getEmployeeDetails()
            showEmployeePermissionsRequest()
        }
        getAllPermissions()
    }

    var formDataGeneral = [
        {
            label:'Login Credentials',
            width:'90%',
            child:[
                {label:'Email',value:'email',type:'textfield',mandatory:true,disabled:(employeeId ? true : false)},
                {label:'Password',value:'password',type:'textfield',mandatory:(employeeId ? false : true)},
            ]
        },
        {
            label:'General',
            width:'90%',
            child:[
                {label:'First Name',value:'first_name',type:'textfield',mandatory:true},
                {label:'Last Name',value:'last_name',type:'textfield',mandatory:true},
                {label:'User Type',value:'employee_type',type:'select',mandatory:true,options:employeeTypeOptions},
                {label:'Skype',value:'skype_id',type:'textfield'},
                {label:'Contact Number',value:'contact',type:'textfield'},
            ]
        },

    ]

    const addEditAdvertiserRequest = async() => {
        
        setButtonLoader(true)
        var permissions = []
        var url = "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,'employee/add')
        if(employeeId){
            url = "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"employee/",employeeId,"/",Constants.CHANGE)
            for(var permissionIndex in  permissionSelected){
                if(permissionSelected[permissionIndex]){
                    permissions.push(permissionSelected[permissionIndex])
                }
            }
        }
        else{
            if(response.employee_type.value==='billing_manager'){
                permissions = ["page_billing_summary_advertiser", "page_billing_package", "page_billing_advertiser", "view_employee", "view_advertiser", "view_offers", "view_offerstatistics","view_advertiserstatistics","view_accountinfo","view_user"]
            }
        }
        
        await axios({
            method: 'POST',
            url,
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
                "Content-Type":"application/json",
            },
            data:{
                "email":response.email,        
                "password":employeeId ? undefined : response.password,        
                "first_name" : response.first_name,
                "last_name" : response.last_name,
                "user_type": response.employee_type ? response.employee_type.value : 'affiliate_manager',    
                "groups" : [],
                "skype_id" : response.skype_id ? response.skype_id : null,
                "contact" : response.contact ? response.contact : null,
                permissions  
            }})
            .then(async (resp)=>{
                if(employeeId){
                    changePassword(employeeId)
                }
                if(typeof resp.data.result === 'string'){
                    Store.addNotification({
                        title: "Warning!",
                        message: resp.data.result,
                        type: "warning",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3000,
                            onScreen: true
                        }
                    });
                }
                else{
                    Store.addNotification({
                        title: "Success!",
                        message: 'Employee ' + (employeeId ? 'edited' : 'added') + ' successfully.',
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3000,
                            onScreen: true
                        }
                    });
                    setEmployeeId(resp.data.result.employee.id)
                }
                console.log(resp)
            })
            .catch(function (error) {
                if (error.response) {
                    if(error.response.data){
                        Store.addNotification({
                            title: "Error!",
                            message: typeof error.response.data.result === "string" ? error.response.data.result : "Some error occured",
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 3000,
                                onScreen: true
                            }
                        });
                    }
                    // Request made and server responded
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    Store.addNotification({
                        title: "Error!",
                        message: typeof error.message === "string" ? error.message : "Some error occured",
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3000,
                            onScreen: true
                        }
                    });
                }
        });
        setButtonLoader(false);

    };

    const [disableButton, setDisableButton] = useState(true)
   
    const checkValidation = (key,value,returnPlease) => {
        return Validations(key,value,returnPlease,validationFields,setValidationFields,setDisableButton)
    }
    const createContent = (e,childItem) => {
        if(childItem.value==='enabled_offers' || childItem.value==='disabled_offers'){
            var changePreviousKey = (childItem.value==='enabled_offers' ? 'disabled_offers' : 'enabled_offers')
            var tempDisabledAffiliates = response[changePreviousKey]
            for(var index in tempDisabledAffiliates){
                var item = tempDisabledAffiliates[index]
                if(e[e.length-1].value === item.value){
                    tempDisabledAffiliates.splice(index,1)
                }
            }  
            setResponse({...response,[childItem.value]:e,[tempDisabledAffiliates]:tempDisabledAffiliates})

        }
        else{
            setResponse({...response,[childItem.value]:e})
        }
    }
    const getFormData = (formData) => {
        return formData.map((item,index)=>{
            return <div key={index} className='advertiserPageDataWrapperMobile' style={{width:item.width}}>
                        <div className='advertiserPageDataWrapper'>
                            <div className='advertiserPageDataHeader'>{item.label}</div>
                            <div className='advertiserPageGeneralDataContainer'>
                                {item.child.map((childItem,childIndex) => {
                                    if(!childItem.hide){
                                        return <div className='advertiserPageGeneralDataWrapper' key={childIndex}>
                                                {!childItem.hideLabel && <div className='addEditAdvertiserPageLabel'>{childItem.label}{childItem.mandatory ? '*' : ''}</div>}
                                                {childItem.type==='textfield' && 
                                                    <input id={childItem.value} className={(childItem.mandatory && !response[childItem.value]) || (childItem.mandatory && validationFields[childItem.value]===false) || (!childItem.mandatory && response[childItem.value] && validationFields[childItem.value]===false) ? 'addEditAdvertiserPageTextField invalidAddEditAdvertiserPageTextField' : 'addEditAdvertiserPageTextField'} style={ !childItem.hideLabel ? {} : {width:'100%'}} disabled={childItem.disabled || buttonLoader} value={response[childItem.value] ? response[childItem.value] : ''} onChange={(e)=>{checkValidation(childItem.value,e.target.value);setResponse({...response,[childItem.value]:e.target.value});setChangesDone({...changesDone,[childItem.value]:true});}}/>
                                                }
                                                {childItem.type==='select' && 
                                                    <div id={childItem.value} className='addEditAdvertiserPageSelectField' style={{width:(childItem.fullWidth ? '91%' : '66.5%')}}>
                                                        {childItem.isCreatable && 
                                                        <CreatableSelect
                                                            isDisabled={buttonLoader}
                                                            options={childItem.options}
                                                            isMulti={childItem.isMulti}
                                                            value={response[childItem.value]}
                                                            onChange={(e)=>{createContent(e,childItem);setChangesDone({...changesDone,[childItem.value]:true})}}
                                                            menuPortalTarget={document.body} 
                                                            placeholder={"Select "+childItem.label}
                                                            styles={{ menuPortal: base => ({ ...base, zIndex: 1000000 }),
                                                                        control: (base,state) => ({
                                                                            ...base,
                                                                            // maxHeight: 100,
                                                                            minHeight: 30,
                                                                            color:'rgb(200,200,200)',
                                                                            overflowY: "auto",
                                                                            fontSize:12,
                                                                            border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid rgb(200,200,200)',
                                                                            boxShadow: state.isFocused ? '0 0 3px 1px '+localStorage.getItem('ambientColor') : 0,
                                                                            '&:hover': {
                                                                                border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                                                                boxShadow: state.isFocused ? '0 0 1px 1px '+localStorage.getItem('ambientColor') : '0 0 1px 1px '+localStorage.getItem('ambientColor'),
                                                                            }
                                                                        }),
                                                                        placeholder: (defaultStyles) => {
                                                                            return {
                                                                                ...defaultStyles,
                                                                                color: 'rgb(150,150,150)',
                                                                                marginTop:'-2px'
                                                                            }
                                                                        },
                                                                        option: (styles, {isFocused, isSelected}) => ({
                                                                            ...styles,
                                                                            fontSize:'11px',
                                                                            background: isFocused
                                                                                ? localStorage.getItem('ambientColor')
                                                                                : isSelected
                                                                                    ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                                                                    : undefined,
                                                                            zIndex: 1
                                                                        }),
                                                                    }}
                                                        />
                                                        }
                                                        {!childItem.isCreatable && 
                                                            <ReactSelect
                                                                isDisabled={buttonLoader}
                                                                options={childItem.options}
                                                                isMulti={childItem.isMulti}
                                                                value={response[childItem.value]}
                                                                onChange={(e)=>{setResponse({...response,[childItem.value]:e});setChangesDone({...changesDone,[childItem.value]:true})}}
                                                                menuPortalTarget={document.body} 
                                                                placeholder={"Select "+childItem.label}
                                                                styles={{ menuPortal: base => ({ ...base, zIndex: 1000000 }),
                                                                            control: (base,state) => ({
                                                                                ...base,
                                                                                // maxHeight: 100,
                                                                                minHeight: 30,
                                                                                color:'rgb(200,200,200)',
                                                                                overflowY: "auto",
                                                                                fontSize:12,
                                                                                border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid rgb(200,200,200)',
                                                                                boxShadow: state.isFocused ? '0 0 3px 1px '+localStorage.getItem('ambientColor') : 0,
                                                                                '&:hover': {
                                                                                    border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                                                                    boxShadow: state.isFocused ? '0 0 1px 1px '+localStorage.getItem('ambientColor') : '0 0 1px 1px '+localStorage.getItem('ambientColor'),
                                                                                }
                                                                            }),
                                                                            placeholder: (defaultStyles) => {
                                                                                return {
                                                                                    ...defaultStyles,
                                                                                    color: 'rgb(150,150,150)',
                                                                                    marginTop:'-2px'
                                                                                }
                                                                            },
                                                                            option: (styles, {isFocused, isSelected}) => ({
                                                                                ...styles,
                                                                                fontSize:'11px',
                                                                                background: isFocused
                                                                                    ? localStorage.getItem('ambientColor')
                                                                                    : isSelected
                                                                                        ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                                                                        : undefined,
                                                                                zIndex: 1
                                                                            }),
                                                                        }}
                                                            />}
                                                    </div>
                                                }
                                                {childItem.type==='textarea' && 
                                                    <textarea placeholder={childItem.label} id={childItem.value} className='addEditAdvertiserPageTextField' style={ !childItem.hideLabel ? {height:'200px'} : {width:'99%',height:'200px'}} value={response[childItem.value] ? response[childItem.value] : ''} onChange={(e)=>{setResponse({...response,[childItem.value]:e.target.value})}} disabled={childItem.disabled}/>
                                                }
                                                {childItem.copyButton &&
                                                    <div style={{position:'absolute',right:'10px',transform:'scale(0.8)',display:'flex',alignItems:'center'}}>
                                                        <CopyButton idToCopy={childItem.value}/>
                                                        {childItem.info &&
                                                            <div onMouseEnter={()=>{setInfoHovered(true);}} onMouseLeave={()=>{setInfoHovered(false)}}>
                                                                <InfoOutlinedIcon sx={{color:'rgb(100,100,100)'}}/>
                                                                {infoHovered && 
                                                                    <div style={{position:'absolute',width:'100px',padding:'10px',background:'white',textAlign:'center',boxShadow:'0 0 5px 1px rgb(200,200,200)'}} onMouseEnter={()=>{setInfoHovered(true);}}>
                                                                        <div style={{marginBottom:'5px'}}>Params: </div>
                                                                        {childItem.info.map((infoItem,infoIndex)=>{
                                                                            return <div style={{fontSize:'14px'}} key={infoIndex}>
                                                                                {infoItem}
                                                                                </div>
                                                                        })}
                                                                    </div>}
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                {childItem.customComponent && childItem.customComponent(childItem)}
                                            </div>
                                    }
                                    return null;
                                })}
                            </div>
                        </div>
                    </div>
        })
    }
    const [infoHovered, setInfoHovered] = useState(false)
    const [activeTab, setActiveTab] = useState('general')

    const getPermissionName = (permission) => {
        
        if(Object.keys(valueKeyAllPermission).includes('view_'+permission)){
            var resp = capitalize(valueKeyAllPermission['view_'+permission].replace("Can view ","").replace("Can View ",""))
            return resp
        } 
        if(Object.keys(valueKeyAllPermission).includes('add_'+permission)){
            resp = capitalize(valueKeyAllPermission['add_'+permission].replace("Can add ",""))
            return resp
        } 
        if(Object.keys(valueKeyAllPermission).includes('change_'+permission)){
            resp = capitalize(valueKeyAllPermission['change_'+permission].replace("Can change ",""))
            return resp
        } 
    }
    const [openedPermissionTabs, setOpenedPermissionTabs] = useState([])
    const [searchedPermission, setSearchedPermission] = useState({permissionHead:[],foundPermissions:[]})
    const [permissionSelected, setPermissionSelected] = useState([])
    const handlePermissionSearch = (e) => {
        var searchedPermission = e.target.value.toLowerCase()
        var foundPermissions = []
        var permissionHead = []
        if(searchedPermission){
            var permissionList = permissionResponse
            for(var permissionIndex in permissionList){
                var permissionItem = permissionList[permissionIndex]
                if(permissionIndex.toLowerCase().includes(searchedPermission)){
                    foundPermissions.push(permissionItem)
                }
            }
            for(var permissionHeadIndex in allPermissions){
                for(permissionIndex in allPermissions[permissionHeadIndex]){
                    permissionItem = allPermissions[permissionHeadIndex][permissionIndex]
                    if(foundPermissions.includes(permissionItem)){
                        if(!permissionHead.includes(permissionHeadIndex)){
                            permissionHead.push(permissionHeadIndex)
                        }
                    }
                }
            }
        }
        setSearchedPermission({foundPermissions,permissionHead})
        setOpenedPermissionTabs(permissionHead)
    }

    const getPagePermissions = () => {
        return <div style={{width:'100%',position:'relative'}}>
            <div style={{padding:'10px',display:'flex',alignItems:'center',background:'white',position:'sticky',top:50,borderBottom:'1px solid rgb(220,220,220)'}}>
                <div style={{width:'70%'}}>
                    Page Name
                </div>
                <div style={{width:'30%',textAlign:'center'}}>
                    Allowed To View
                </div>
            </div>
            {sidebarTabs.map((item,index)=>{
                if(!item.hideForPerm){
                return <div key={index} style={{padding:'10px'}}>
                        <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',paddingBottom:'10px'}}>
                            <div style={{fontSize:'14px',width:'70%',fontWeight:'500'}}>{item.title}</div>
                            {!item.subNav && 
                                <div style={{fontSize:'14px',width:'30%',textAlign:'center'}}>
                                    <input type='checkbox' checked={permissionSelected.includes('page'+item.link.replaceAll('/','_'))}  onChange={()=>{var temp_perms = permissionSelected; if(!temp_perms.includes('page'+item.link.replaceAll('/','_'))){temp_perms.push('page'+item.link.replaceAll('/','_'));temp_perms.push(item.requiredPermission);} else{temp_perms = temp_perms.filter(e=> e!=='page'+item.link.replaceAll('/','_'));temp_perms = temp_perms.filter(e=> e!==item.requiredPermission);};setPermissionSelected(temp_perms);refreshComponent(12);}}></input>
                                </div>
                            }
                        </div>
                        {item.subNav && item.subNav.map((childItem,childIndex)=>{
                            if(item.link=='/accountManager'){
                                if(permissionSelected.includes('view_manager_stats') && childItem.requiredPermission==='view_manager_stats'){
                                    return
                                }
                                if(permissionSelected.includes('change_manager_stats') && childItem.requiredPermission==='change_manager_stats'){
                                    return 
                                }
                            }
                            return <div style={{display:'flex',alignItems:'center'}} key={childIndex}>
                                    <div style={{fontSize:'13px',width:'calc(70% - 20px)',padding:'10px'}}>{childItem.title}</div>
                                    <div style={{fontSize:'13px',width:'30%',textAlign:'center'}}>
                                        <input type='checkbox' checked={permissionSelected.includes('page'+childItem.link.replaceAll('/','_'))} onChange={()=>{var temp_perms = permissionSelected; if(!temp_perms.includes('page'+childItem.link.replaceAll('/','_'))){temp_perms.push('page'+childItem.link.replaceAll('/','_'));temp_perms.push(childItem.requiredPermission);} else{temp_perms = temp_perms.filter(e=> e!=='page'+childItem.link.replaceAll('/','_'));temp_perms = temp_perms.filter(e=> e!==childItem.requiredPermission);};setPermissionSelected(temp_perms);refreshComponent(12);}}></input>
                                    </div>
                                
                            </div>
                        })}
                        </div>
                }
            })}
        </div>
    }
    const getPermissions = () => {
        return <div style={{width:'100%'}}>
            <div style={{margin:'20px'}}>
                <TextField fullWidth size='small' onChange={handlePermissionSearch} label={'Search Permission'}/>
            </div>
            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                <div style={{display:'flex',alignItems:'center',justifyContent:'flex-end',fontSize:'13px',marginLeft:'20px',marginRight:'20px',marginBottom:'10px',cursor:'pointer'}}>
                    <div onClick={()=>{setOpenedPermissionTabs(Object.keys(allPermissions))}}>Show all </div>
                    <div style={{marginLeft:'4px'}} onClick={()=>{setOpenedPermissionTabs([])}}>Hide all </div>
                </div>
                <div style={{display:'flex',alignItems:'center',justifyContent:'flex-end',fontSize:'13px',marginLeft:'20px',marginRight:'20px',marginBottom:'10px',cursor:'pointer'}}>
                    <div onClick={()=>{setPermissionSelected(Object.keys(valueKeyAllPermission))}}>Select all </div>
                    <div style={{marginLeft:'4px'}} onClick={()=>{var tempPermissions = []; for(var index in permissionSelected){var item = permissionSelected[index]; if(item.startsWith('page_')){tempPermissions.push(item);}}; setPermissionSelected(tempPermissions)}}>Unselect all </div>
                </div>
               
            </div>
            {Object.keys(allPermissions).map((permissionHead,permissionHeadIndex)=>{
                if(searchedPermission.permissionHead.length === 0 || searchedPermission.permissionHead.includes(permissionHead)){
                    return <div className='permissionContainer' key={permissionHeadIndex}>
                        <div style={{position:'sticky',top:50,zIndex:'1',display:'flex',padding:'10px',paddingLeft:'20px',paddingRight:'20px',background:'rgb(245,245,245)',border:'1px solid rgb(220,220,220)'}} onClick={()=>{if(openedPermissionTabs.includes(permissionHead)){var tempOpened = openedPermissionTabs;tempOpened = tempOpened.filter(e => e!==permissionHead);setOpenedPermissionTabs(tempOpened)}else{setOpenedPermissionTabs([...openedPermissionTabs,permissionHead])}}}>
                            <div style={{width:'30%',fontWeight:500}}>{permissionHead}</div>
                            {openedPermissionTabs.includes(permissionHead) 
                                &&
                                <div style={{width:'70%',display:'flex',justifyContent:'space-around'}}>
                                    <div style={{fontSize:'14px',fontWeight:500}}>View</div>
                                    <div style={{fontSize:'14px',fontWeight:500}}>Add</div>
                                    <div style={{fontSize:'14px',fontWeight:500}}>Change</div>
                                </div>
                            }
                        </div>
                        <div className={openedPermissionTabs.includes(permissionHead) ? 'openedPermissionContainer' : 'closedPermissionContainer'} style={{padding:'10px',paddingLeft:'20px',paddingRight:'20px'}}>
                            {employeePermissionMapping[permissionHead].map((permission,permissionIndex)=>{
                                if(searchedPermission.foundPermissions.length === 0 || searchedPermission.foundPermissions.includes("add_"+permission) || searchedPermission.foundPermissions.includes("view_"+permission) || searchedPermission.foundPermissions.includes("change_"+permission)){
                                    if(permission==='app_name'){
                                        return <div style={{display:'flex',alignItems:'center'}}>
                                                <input id='rehah' type='checkbox' checked={permissionSelected.includes('can_view_app_name')} onChange={(e)=>{if(e.target.checked){setPermissionSelected([...permissionSelected,'can_view_app_name'])}else{setPermissionSelected(permissionSelected.filter(e => e!==('can_view_app_name')))}; refreshComponent('123')}}></input>
                                                <label htmlFor='rehah'>Can View App Name</label>
                                            </div>
                                    }
                                    if(permission==='team1' || permission==='team2' || permission==='team3'){
                                        var teamValue = permission.split('team')[1]
                                        return <div style={{display:'flex',alignItems:'center'}}>
                                                <input id={'team'+teamValue} type='checkbox' checked={permissionSelected.includes('can_view_team'+teamValue)} onChange={(e)=>{if(e.target.checked){setPermissionSelected([...permissionSelected,'can_view_team'+teamValue])}else{setPermissionSelected(permissionSelected.filter(e => e!==('can_view_team'+teamValue)))}; refreshComponent('123')}}></input>
                                                <label htmlFor={'team'+teamValue}>Can View Validations of Team {teamValue} Only</label>
                                            </div>
                                    }
                                    if(permission==='billing_only'){
                                        return <div style={{display:'flex',alignItems:'center'}}>
                                                <input id={'billing_only'} type='checkbox' checked={permissionSelected.includes('can_view_billing_only')} onChange={(e)=>{if(e.target.checked){setPermissionSelected([...permissionSelected,'can_view_billing_only'])}else{setPermissionSelected(permissionSelected.filter(e => e!==('can_view_billing_only')))}; refreshComponent('123')}}></input>
                                                <label htmlFor={'billing_only'}>Can Read Validations Only</label>
                                            </div>
                                    }
                                    return <div style={{width:'100%',display:'flex',alignItems:'center'}} key={permissionIndex} >
                                                <div style={{width:'30%',fontSize:'14px'}}>
                                                    {getPermissionName(permission)}
                                                </div>
                                                <div style={{width:'70%',display:'flex'}}>
                                                    {['view_','add_','change_'].map((item,index)=>{
                                                        if(allPermissions[permissionHead].includes(item+permission)){
                                                            return <div key={index} style={{transform:'scale(0.7)',width:'33%',justifyContent:'center',display:'flex'}}>
                                                                        <Switch checked={permissionSelected.includes(item+permission)} onChange={(e)=>{if(e.target.checked){setPermissionSelected([...permissionSelected,item+permission])}else{setPermissionSelected(permissionSelected.filter(e => e!==(item+permission)))}; refreshComponent('123')}} color="warning" />
                                                                    </div>
                                                        }
                                                        return <div style={{display:'flex',minWidth:'33%'}}>
                                                        </div>;
                                                    })}
                                                </div>
                                                
                                            </div>
                                }
                                return null;
                            })}
                        </div>
                    </div>
                }
                return null;
            })}
        </div>
    }

    // const [selectedFirstTab, setSelectedFirstTab] = useState('Permissions')
    // const [selectedSecondTab, setSelectedSecondTab] = useState(false)
    // const getPermissionsV2 = () => {
    //     return <div className='permissionContainerV3'>
    //         <table style={{tableLayout:'fixed',width:'80%'}}>
    //             <thead>
    //                 <tr>
    //                     <td width={'20%'}>
    //                         {Object.keys(employeePermissionMapping).map((item,index)=>{
    //                             return <div style={{paddingLeft:'40px',fontSize:'13px',padding:'8px',cursor:'pointer',background:(selectedFirstTab===item ? localStorage.getItem('ambientColor') : '')}} key={index} onClick={()=>{setSelectedFirstTab(item)}}>
    //                                 {item}
    //                             </div>
    //                         })}
    //                     </td>
    //                     <td width={'20%'} style={{verticalAlign:'top'}}>
    //                         {selectedFirstTab && employeePermissionMapping[selectedFirstTab].map((item,index)=>{
    //                             return <div style={{paddingLeft:'40px',cursor:'pointer',fontSize:'13px',padding:'8px',background:(selectedSecondTab===item ? increase_brightness(localStorage.getItem('ambientColor'),90) : '')}} key={index} onClick={()=>{setSelectedSecondTab(item)}}>
    //                                 {item}
    //                             </div>
    //                         })}
    //                     </td>
    //                     <td width={'60%'}>

    //                     </td>
    //                 </tr>
    //             </thead>
    //         </table>
    //     </div>
    // }
    
    return (
        <div className='advertiserPageV2'>
            <div style={{fontSize:'18px',fontWeight:600,display:'flex',alignItems:'center'}}>
                {employeeId ? 'Edit ' : 'Add '} User
                {buttonLoader && <CircularProgress sx={{color:localStorage.getItem('ambientColor'),marginLeft:'20px'}} size={17}/>}
            </div>
            <div className='advertiserPageV2TabsContainer'>
                <div className='advertiserPageV2Tabs' style={{color:(activeTab==='general' ? localStorage.getItem('ambientColor') : 'black')}} onClick={()=>{setActiveTab('general')}}>General</div>
                <div className='advertiserPageV2Tabs' style={{color:(activeTab==='page_permissions' ? localStorage.getItem('ambientColor') : 'black')}} onClick={()=>{setActiveTab('page_permissions')}}>Page Permissions</div>
                <div className='advertiserPageV2Tabs' style={{color:(activeTab==='permissions' ? localStorage.getItem('ambientColor') : 'black')}} onClick={()=>{setActiveTab('permissions')}}>Api Permissions</div>
            </div>
            
            <div className='advertiserPageDataContainer'>
                {
                    activeTab==='general' && 
                    <div style={{display:'flex',alignItems:'flex-start',width:'100%'}}>
                        <div style={{width:'10%',padding:'5%',paddingTop:'3%'}}>
                            <Logo page='manager' addText={'photo'} id={employeeId}/>
                        </div>
                        <div style={{width:'90%'}}>
                            {getFormData(formDataGeneral)}
                        </div>
                    </div>
                }
                {
                    activeTab==='page_permissions' && getPagePermissions()
                }
                {
                    activeTab==='permissions' && getPermissions()
                }
            </div>
            <div className='advertiserPageButtonPallete' style={{zIndex:'100'}}>
                <Button disabled={buttonLoader || disableButton} sx={{...Constants.CONTAINEDBUTTON,fontSize:'15px',paddingLeft:'15px',paddingRight:'15px',textTransform:'none',fontWeight:500,marginLeft:'15px',width:'40px',height:'30px'}} onClick={addEditAdvertiserRequest}>
                    {!buttonLoader && (employeeId ? 'Edit' : 'Add')}
                    {buttonLoader && <CircularProgress sx={{color:'white'}} size={18}/>}
                </Button>
                <NavLink to={'/account/employees'} style={{textDecoration:'none'}}>
                    <Button sx={{...Constants.OUTLINEDBUTTON,fontSize:'15px',paddingLeft:'15px',paddingRight:'15px',textTransform:'none',fontWeight:500,marginLeft:'15px',height:'30px'}}>Cancel</Button>
                </NavLink>
            </div>
        </div>
    )
}

export default AddEditUser