import React,{useEffect, useState} from "react";
import axios from 'axios';
import { NavLink } from "react-router-dom";
import PropTypes from 'prop-types';
import Select from 'react-select';
import { capitalize, getLocalStorageValue } from '../../../util/util';

import * as Constants from '../../../data/constants';
// import "../../../css/pages/offers.css";
import "../../../css/pages/v2/offer.css";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { Store } from 'react-notifications-component';
import { sorting } from "../../../util/util";
import CreatableSelect from 'react-select/creatable';

import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import HelpIcon from '@mui/icons-material/Help';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { increase_brightness } from "../../../components/customization/customization";


import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState,convertToRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import NavigationPrompt from "react-router-navigation-prompt";
import Logo from "../../../components/logoUpdater/logo";
import { CircularProgress } from "@mui/material";
import { InfoSharp } from "@mui/icons-material";
import { DateRangePicker } from 'react-date-range';
import { createGlobalStyle } from 'styled-components';

const DatePickerWrapperStyles = createGlobalStyle`
    .rdrDefinedRangesWrapper {
        display: none;
    }
`;
const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
      color: localStorage.getItem('ambientColor'),
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: localStorage.getItem('ambientColor'),
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgb(200,200,200)',
      },
      '&:hover fieldset': {
        borderColor: localStorage.getItem('ambientColor'),
      },
      '&.Mui-focused fieldset': {
        borderColor: localStorage.getItem('ambientColor'),
      },
    },
  });
  
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function useForceUpdate(){
  const [value, setValue] = React.useState(0); // integer state
  if(false){
    console.log(value)
  }
  return () => setValue(value => value + 1); // update the state to force render
}

const NewOfferPageV2 = (props) => {

    const forceUpdate = useForceUpdate()
    const [tabValue, setTabValue] = React.useState(0);
    const [manualUseEffect,setManualUseEffect] = React.useState(false);
    
    const [isEdit,setIsEdit] = React.useState(false);
    const [changesDone,setChangesDone] = React.useState(false);
    const [requestDone,setRequestDone] = React.useState(false);
    const [offerID,setOfferID] = React.useState("");
    const [offerTitle,setOfferTitle] = React.useState("");
    const [offerExternalOfferId,setOfferExternalOfferId] = React.useState("");
    const [offerAdvertiser,setOfferAdvertiser] = React.useState("");
    const [offerCaps,setOfferCaps] = React.useState([{
                                                        "period": "day",
                                                        "type": "conversions",
                                                        "value": 0,
                                                        "countries": [],
                                                        "goal": []
                                                        }]);
    const [offerCategories,setOfferCategories] = React.useState([]);
    const [offerDescription,setOfferDescription] = React.useState("");
    const [offerImpressionLink,setOfferImpressionLink] = React.useState("");
    const [offerKpi,setOfferKpi] = React.useState("");
    const [offerSource,setOfferSource] = React.useState();
    const [offerStopAt,setOfferStopAt] = React.useState("");
    const [offerExpiry,setOfferExpiry] = React.useState();
    const [offerPayouts,setOfferPayouts] = React.useState([{
                                                                "affiliate_id": null,
                                                                "countries": [],
                                                                "currency": "USD",
                                                                "goal": "",
                                                                "payment_type": "CPI",
                                                                "payout": "",
                                                                "revenue": "",
                                                                "title": ""
                                                            }]);

    const [offerCutback,setOfferCutback] = React.useState([{
                                                                "affiliate_id": null,
                                                                "offer_id": '',
                                                                "goal_value": "",
                                                                "cutback_value": ''
                                                            }]);
    const [saveButtonDisabled,setSaveButtonDisabled] = React.useState(false);
    const [offerPreviewUrl,setOfferPreviewUrl] = React.useState("");
    const [offerStatus,setOfferStatus] = React.useState({label:'Active',value:'active'});
    const [offerType,setOfferType] = React.useState({label:'Private',value:'private'});
    const [offerCreatives,setOfferCreatives] = React.useState([]);
    // const [offerStopAt,setOfferStopAt] = React.useState("");
    const [offerTargetingCountries,setOfferTargetingCountries] = React.useState([{"countries": ["ALL"]}]);
    const [offerTrackingUrl,setOfferTrackingUrl] = React.useState("");
    const [offerRepeatPostback,setOfferRepeatPostback] = React.useState("");
    const [advertisersList,setAdvertisersList] = React.useState([])
    const [offerPackageName,setOfferPackageName] = React.useState("");
    const [offerPostbacks,setOfferPostbacks] = React.useState([{
                                                                "action":'add',
                                                                "id": "",
                                                                "postback_url": "",
                                                                "offer_id": "",
                                                                "goal_value": "",
                                                                "payout_status": "",
                                                                "affiliate_id": ""
                                                            }])
    
    const [affiliateList,setAffiliateList] = React.useState([]);
    const [overcapsGoal,setOvercapsGoal] = React.useState(false);
    const [selectCountries,setSelectCountries] = React.useState([{label:'All',value:'ALL'}]);
    var [categoriesOptions,setCategoriesOptions] = React.useState([]);

                                            
    const [top, setTop] = React.useState(0)
    // const [up, setUp] = React.useState(false)

    const controlNavbar = (e) => {
        var tempScrollPosition = 0
        if(window.scrollY>60 && window.scrollY<=100 ){
            tempScrollPosition = 60 - (window.scrollY - 50)
        }
        else if(window.scrollY>100 ){
            tempScrollPosition = -2

        }
        else{
            tempScrollPosition = 50
        }
        setTop(tempScrollPosition)
        // setPrevScrollPosition(window.scrollY)
    }

    React.useEffect(() => {
        window.addEventListener('scroll', controlNavbar)
        return () => {
            window.removeEventListener('scroll', controlNavbar)
        }
    })
    
    const handleTabValue = (event, newValue) => {
        setTabValue(newValue);
    };

    const showHtml = (offerKpiManual) => {
        try{
            while(offerKpiManual.includes("&lt;") || offerKpiManual.includes("&gt;")){
                offerKpiManual = offerKpiManual.replace("&lt;","<")
                offerKpiManual = offerKpiManual.replace("&gt;",">")
            }
            return offerKpiManual
        }
        catch{
            return offerKpiManual
        }
      }
       
    const getAdvertisers = async() => {
        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.ADVERTISER,"/","details"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
        })
        .then(resp => {
            let advertiserlist = []
            for (var item in resp.data.result){
                advertiserlist.push({label:resp.data.result[item][Object.keys(resp.data.result[item])[0]],value:Object.keys(resp.data.result[item])[0]})
                }
            sorting(advertiserlist,'label',false,true)  
            setAdvertisersList(advertiserlist)
        })
        .catch(err => {
            console.error(err);
        })
    };
        
    const getAffiliates = async() => {
        axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"affiliate/details"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
        })
        .then(resp => {
            var affiliatelist = [];
            for (var affiliate in resp.data.result){
                affiliatelist.push({label:resp.data.result[affiliate][Object.keys(resp.data.result[affiliate])[0]] + " - " + Object.keys(resp.data.result[affiliate])[0] ,value:Object.keys(resp.data.result[affiliate])[0]});
            }
            sorting(affiliatelist,'label',false,true)
            affiliatelist = [{label:"All",value:null},...affiliatelist]
            setAffiliateList(affiliatelist);
        })
        .catch(err => {
            console.error(err);
        }) 
    };  
  
    const getConversionCapsStatus = async(offer_id) => {
        axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"offer/caps/allowedstatus/",offer_id),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
        })
        .then(resp => {
            var tempConversionCapStatus = []
            for(var index in resp.data.result){
                var dict = {label:resp.data.result[index]==='approved' ? 'Approved' : 'Declined',
                            value:resp.data.result[index]
                        }
                tempConversionCapStatus.push(dict)
            }
            setConversionCapsStatus(tempConversionCapStatus)
        })
        .catch(err => {
            console.error(err);
        }) 
    };  
    const offerRequest = async(offerid) => {
        setSaveButtonDisabled(true);
        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.GETOFFERS,"/",offerid),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
        })
        .then(resp => {
            if(resp.data.result.length===0){
                Store.addNotification({
                    title: "Error!",
                    message: "No such offer found.",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    },
                    onRemoval: (id, removedBy) => {
                        window.location.href = "/offers"
                    }
                  });
            }
            else{
               
                setOfferID(resp.data.result[0].id)
                setOfferPackageName(resp.data.result[0].package_name)
                setOfferExternalOfferId(resp.data.result[0].external_offer_id ? resp.data.result[0].external_offer_id : "")
                setOfferTitle(resp.data.result[0].title)
                setOfferStatus({label: capitalize(resp.data.result[0].offer_status),value:resp.data.result[0].offer_status})
                setOfferType({label: capitalize(resp.data.result[0].offer_type==='protected' ? 'request' : resp.data.result[0].offer_type),value:resp.data.result[0].offer_type})
                if(resp.data.result[0].advertiser){
                    setOfferAdvertiser({label: capitalize(resp.data.result[0].advertiser),value:resp.data.result[0].advertiser})
                }
                
                var tempCategoriesList = []
                for(var categoryIndex in resp.data.result[0].categories){
                    tempCategoriesList.push({label: capitalize(resp.data.result[0].categories[categoryIndex]),value:resp.data.result[0].categories[categoryIndex]})
                }
                setOfferCategories(tempCategoriesList)


                setOfferDescription(resp.data.result[0].description)
                if(resp.data.result[0].description){
                    var description = resp.data.result[0].description
                    description = "<div>"+description+"</div>"
                    const blocksFromHTML = htmlToDraft(showHtml(description))
                    setOfferDescription(EditorState.createWithContent(ContentState.createFromBlockArray(
                    blocksFromHTML.contentBlocks,
                    blocksFromHTML.entityMap
                    )))
                }
                setOfferKpi(resp.data.result[0].kpi)
                if(resp.data.result[0].offer_expiry){
                    setDateRangeState([{
                        startDate: new Date(resp.data.result[0].offer_expiry),
                        endDate: new Date(resp.data.result[0].offer_expiry),
                        key: 'selection'
                    }])
                }
                setOfferSource(resp.data.result[0].offer_source ? resp.data.result[0].offer_source : 'manual')

                setOfferExpiry(resp.data.result[0].offer_expiry)
                if(resp.data.result[0].kpi){
                    var kpi = resp.data.result[0].kpi
                    kpi = "<div>"+kpi+"</div>"
                    const blocksFromHTML = htmlToDraft(showHtml(kpi))
                    setOfferKpi(EditorState.createWithContent(ContentState.createFromBlockArray(
                    blocksFromHTML.contentBlocks,
                    blocksFromHTML.entityMap
                    )))
                }

                setOfferTrackingUrl(resp.data.result[0].tracking_url)
                setOfferPreviewUrl(resp.data.result[0].preview_url)
                setOfferImpressionLink(resp.data.result[0].impression_link)
                setOfferCutback(resp.data.result[0].cutback ? resp.data.result[0].cutback : [])
                setOfferRepeatPostback(resp.data.result[0].offer_repeat_goal ? resp.data.result[0].offer_repeat_goal : '')
                setOfferTargetingCountries(resp.data.result[0].targeting)
                setDefaultCountries(resp.data.result[0].targeting ? resp.data.result[0].targeting : []);
                setOvercapsAllowed(resp.data.result[0].overcaps ? resp.data.result[0].overcaps : false)
                if(resp.data.result[0].payouts && resp.data.result[0].payouts.length>0){
                    for(var payoutsIndex in resp.data.result[0].payouts){
                        if(resp.data.result[0].payouts[payoutsIndex].goal === resp.data.result[0].overcaps_goal){
                            setOvercapsGoal({label:resp.data.result[0].payouts[payoutsIndex].title +" ("+ resp.data.result[0].payouts[payoutsIndex].goal+")", value:resp.data.result[0].payouts[payoutsIndex].goal})
                            break;
                        }
                        else{
                            setOvercapsGoal(false)
                        }
                    }
                }
                if(resp.data.result[0].payouts.length>0){
                    setOfferPayouts(resp.data.result[0].payouts);
                }
                if(resp.data.result[0].caps.length>0){            
                    setOfferCaps(resp.data.result[0].caps);
                }

                setOfferCreatives(resp.data.result[0].creatives)
                var gaid = []
                if(resp.data.result[0].gaid){
                    gaid = resp.data.result[0].gaid.map((item,index)=>{return {label:item,value:item}})
                }
                setGaidSelected(gaid)
                setOfferStopAt(resp.data.result[0].stop_at)
                setSubIdSelected(resp.data.result[0].sub_id ? resp.data.result[0].sub_id : [])
            }
            setRequestDone(true)
            setSaveButtonDisabled(false);

        })
        .catch(err => {
            setSaveButtonDisabled(false);
            setRequestDone(true)
            console.error(err);
        })
    }
 
    const getEventPostbacks = async(offerid) => {
        axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"eventpostbacks"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params:{
                offer_id:offerid
            }
        })
        .then(resp => {
            if(resp.data.result.length>0){            
                var postback = []
                for(var index in resp.data.result){
                    postback.push({...resp.data.result[index],action:'edit'})
                }
                setOfferPostbacks(postback)
            }
        })
        .catch(err => {
            console.error(err);
        }) 
    };  

    if (!manualUseEffect){
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        }
        );
        setManualUseEffect(true);
        if(window.location.href.includes('/offers/add')){
            setIsEdit(false);
        }
        if(window.location.href.includes('/offers/edit')){
            setIsEdit(true);
            offerRequest(window.location.href.split('offers/edit/')[1])
            getConversionCapsStatus(window.location.href.split('offers/edit/')[1])
            getEventPostbacks(window.location.href.split('offers/edit/')[1]);
        }
        else{
            setRequestDone(true)
        }
        getAdvertisers();
        getAffiliates();
        for (var index in Constants.OFFER_CATEGORIES){
            categoriesOptions.push({label:Constants.OFFER_CATEGORIES[index],value:Constants.OFFER_CATEGORIES[index]})
            setCategoriesOptions(categoriesOptions)
        }
    }

    var date = new Date()
    const [dateRangeState, setDateRangeState] = useState([{
        startDate: new Date(date.getTime()+86400000*365),
        endDate: new Date(date.getTime()+86400000*365),
        key: 'selection'
    }])
    const [showDatePicker, setShowDatePicker] = useState(false)

    const dateRangePickedMethod = (item) => {
        setDateRangeState([item.selection]);
        setOfferExpiry([item.selection])
        setShowDatePicker(false)
    }


    useEffect(() => {
        document.addEventListener('keydown',(e)=>{
            if(e.key==='Escape'){
                setShowDatePicker(false)
            }
        })
        document.addEventListener('click',(e)=>{
            if(showDatePicker){
                setShowDatePicker(false)
            }
        })
        
    }, [showDatePicker,setShowDatePicker])
    
    const general = () =>{
        if(!requestDone){
            return <div style={{display:'flex',justifyContent:'center',alignItems:"center",height:'70vh'}}>
                <Loader
                type="Bars"
                color={localStorage.getItem('ambientColor')}
                height={50}
                width={100}
                //timeout={1000} //3 secs
            />
            </div>
        }
        else{
            return <div className='offerEditContainerV2' style={{position:'relative'}}>
                    <div style={{width:'80%'}}>
                        {isEdit &&<div className='offerEditLabelValueV2'>
                            <div className="offerEditLabelV2">
                                Offer ID
                            </div>
                            <CssTextField size='small' disabled fullWidth defaultValue={offerID} sx={{transform:'scale(0.7) translateX(-15%)'}}/>
                        </div>
                        }
                        <div className='offerEditLabelValueV2'>
                            <div className="offerEditLabelV2">
                                External Offer ID
                            </div>
                            <CssTextField size='small' fullWidth defaultValue={offerExternalOfferId} onChange={(e)=>{setOfferExternalOfferId(e.target.value)}} sx={{transform:'scale(0.7) translateX(-15%)'}}/>
                        </div>
                        <div className='offerEditLabelValueV2'>
                            <div className="offerEditLabelV2" style={offerTitle==="" ? {color:'red'} : {}}>
                                Offer Title
                            </div>
                            <CssTextField size='small' fullWidth defaultValue={offerTitle} onChange={(e)=>{setChangesDone(true);setOfferTitle(e.target.value)}} sx={{transform:'scale(0.7) translateX(-15%)'}}/>
                        </div>

                        <div className='offerEditLabelValueV2'>
                            <div className="offerEditLabelV2">
                                Offer Package Name
                            </div>
                            <CssTextField size='small' fullWidth defaultValue={offerPackageName} onChange={(e)=>{setChangesDone(true);setOfferPackageName(e.target.value)}} sx={{transform:'scale(0.7) translateX(-15%)'}}/>
                        </div>
                        {(offerSource==='manual' 
                        ||
                        !isEdit)
                        &&
                        <div className='offerEditLabelValueV2'>
                            <div className="offerEditLabelV2">
                                Offer Expiry
                            </div>
                            <Constants.CssTextField size='small' fullWidth value={offerExpiry!=="" && dateRangeState[0].startDate ? dateRangeState[0].startDate.toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }) : 'No Expiry'} style={{width:'200px',transform:'scale(0.75) translateX(-14%)'}} onClick={(e)=>{e.stopPropagation();setShowDatePicker(true)}}/>
                            <div style={{position:'absolute',zIndex:2,transform:'scale(1)',marginTop:'32%'}} onClick={(e)=>{e.stopPropagation()}}>
                                {showDatePicker && <DateRangePicker
                                    onChange={item => dateRangePickedMethod(item)}
                                    showSelectionPreview={true}
                                    minDate={new Date(new Date().getTime() - 86400000)}
                                    moveRangeOnFirstSelection={false}
                                    months={1}
                                    preventSnapRefocus={true}
                                    rangeColors={[localStorage.getItem('ambientColor')]}
                                    ranges={dateRangeState}
                                    showDateDisplay={false}
                                    staticRanges={[]}
                                    inputRanges={[]}
                                    scroll={{ enabled: window.innerWidth<800 ? true : false }}
                                    direction={window.innerWidth<800 ? "vertical" : "horizontal"}
                                    calendarFocus="backwards"
                                    />
                                }
                                <DatePickerWrapperStyles/>
                            </div>
                        </div>
                        }
                        <div className='offerEditLabelValueV2' style={{marginTop:'5px'}}>
                            <div className="offerEditLabelV2">
                                Offer Status
                            </div>
                            <div style={{width:'50%',fontSize:'14px'}}>
                                <Select
                                        options={[{label:'Active',value:'active'},{label:'Paused',value:'paused'},{label:'Disabled',value:'disabled'}]}
                                        value={offerStatus}
                                        onChange={(e)=>{setChangesDone(true);setOfferStatus(e)}}
                                        menuPortalTarget={document.body} 
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }),
                                                    control: (base,state) => ({
                                                        ...base,
                                                        // maxHeight: 100,
                                                        minHeight: 30,
                                                        transform:'scale(0.8) translateX(-11.5%)',
                                                        overflowY: "auto",
                                                        border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid rgb(200,200,200)',
                                                        boxShadow: 0,
                                                        '&:hover': {
                                                            border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                                            boxShadow: 0,
                                                        }
                                                    }),
                                                    option: (styles, {isFocused, isSelected}) => ({
                                                        ...styles,
                                                        fontSize:'11px',
                                                        background: isFocused
                                                            ? localStorage.getItem('ambientColor')
                                                            : isSelected
                                                                ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                                                : undefined,
                                                        zIndex: 1
                                                    }),
                                                }}
                                    />
                            </div>
                        </div>
                        <div className='offerEditLabelValueV2' style={{marginTop:'5px'}}>
                            <div className="offerEditLabelV2">
                                Offer Privacy
                            </div>
                            <div style={{width:'50%',fontSize:'14px'}}>
                                <Select
                                        options={[{label:'Private',value:'private'},{label:'Public',value:'public'},{label:'Request',value:'protected'},]}
                                        value={offerType}
                                        onChange={(e)=>{setChangesDone(true);setOfferType(e)}}
                                        menuPortalTarget={document.body} 
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }),
                                                    control: (base,state) => ({
                                                        ...base,
                                                        // maxHeight: 100,
                                                        minHeight: 30,
                                                        transform:'scale(0.8) translateX(-11.5%)',
                                                        overflowY: "auto",
                                                        border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid rgb(200,200,200)',
                                                        boxShadow: 0,
                                                        '&:hover': {
                                                            border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                                            boxShadow: 0,
                                                        }
                                                    }),
                                                    option: (styles, {isFocused, isSelected}) => ({
                                                        ...styles,
                                                        fontSize:'11px',
                                                        background: isFocused
                                                            ? localStorage.getItem('ambientColor')
                                                            : isSelected
                                                                ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                                                : undefined,
                                                        zIndex: 1
                                                    }),
                                                }}
                                    />
                            </div>
                        </div>
                        <div className='offerEditLabelValueV2' style={{marginTop:'5px'}}>
                            <div className="offerEditLabelV2"  style={offerAdvertiser==="" ? {color:'red'} : {}}>
                                Offer Advertiser
                            </div>
                            <div style={{width:'50%',fontSize:'14px'}}>
                                <Select
                                        options={advertisersList}
                                        value={offerAdvertiser}
                                        onChange={(e)=>{setChangesDone(true);setOfferAdvertiser(e);getDemandSourceManual(e.value)}}
                                        menuPortalTarget={document.body} 
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }),
                                                    control: (base,state) => ({
                                                        ...base,
                                                        // maxHeight: 100,
                                                        minHeight: 30,
                                                        transform:'scale(0.8) translateX(-11.5%)',
                                                        overflowY: "auto",
                                                        border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid rgb(200,200,200)',
                                                        boxShadow: 0,
                                                        '&:hover': {
                                                            border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                                            boxShadow: 0,
                                                        }
                                                    }),
                                                    option: (styles, {isFocused, isSelected}) => ({
                                                        ...styles,
                                                        fontSize:'11px',
                                                        background: isFocused
                                                            ? localStorage.getItem('ambientColor')
                                                            : isSelected
                                                                ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                                                : undefined,
                                                        zIndex: 1
                                                    }),
                                                }}
                                    />
                            </div>
                        </div>
                        <div className='offerEditLabelValueV2' style={{marginTop:'5px'}}>
                            <div className="offerEditLabelV2">
                                Categories
                            </div>
                            <div style={{width:'50%',fontSize:'14px'}}>
                                <CreatableSelect
                                    isMulti
                                    options={categoriesOptions}
                                    value={offerCategories}
                                    onChange={(e)=>{setChangesDone(true);setOfferCategories(e)}}
                                    menuPortalTarget={document.body} 
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }),
                                                control: (base,state) => ({
                                                    ...base,
                                                    // maxHeight: 100,
                                                    minHeight: 30,
                                                    transform:'scale(0.8) translateX(-11.5%)',
                                                    overflowY: "auto",
                                                    border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid rgb(200,200,200)',
                                                    boxShadow: 0,
                                                    '&:hover': {
                                                        border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                                        boxShadow: 0,
                                                    }
                                                }),
                                                option: (styles, {isFocused, isSelected}) => ({
                                                    ...styles,
                                                    fontSize:'11px',
                                                    background: isFocused
                                                        ? localStorage.getItem('ambientColor')
                                                        : isSelected
                                                            ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                                            : undefined,
                                                    zIndex: 1
                                                }),
                                            }}
                                    />
                            </div>
                        </div>
                        <div className='offerEditLabelValueV2' style={{marginTop:'10px',alignItems:'flex-start'}}>
                            <div className="offerEditLabelV2">
                                KPI
                            </div>
                            <div style={{width:'70%',fontSize:'14px'}}>
                                <Editor
                                    editorState={offerKpi}
                                    onEditorStateChange={(resp)=>{setOfferKpi(resp)}}
                                    editorStyle={{minHeight:"300px",maxHeight:"300px",paddingLeft:"2%",paddingRight:"2%",fontSize:'12px'}}   
                                    wrapperStyle={{border:"0.01px solid rgb(200,200,200)"}}
                                />
                            </div>
                        </div>
                        <div className='offerEditLabelValueV2' style={{marginTop:'10px',alignItems:'flex-start'}}>
                            <div className="offerEditLabelV2">
                                Description
                            </div>
                            <div style={{width:'70%',fontSize:'14px'}}>
                                <Editor
                                    editorState={offerDescription}
                                    onEditorStateChange={(resp)=>{setOfferDescription(resp)}}
                                    editorStyle={{minHeight:"300px",maxHeight:"300px",paddingLeft:"2%",paddingRight:"2%"}}   
                                    wrapperStyle={{border:"0.01px solid rgb(200,200,200)"}}
                                />
                            </div>
                        </div>
                    </div>
                    {
                        isEdit &&
                        <div style={{position:'absolute',top:'20px',right:'20px'}}>
                            <Logo page='offer' addText={'Logo'} id={offerID}/>
                        </div>
                    }
            </div>
        }
    }
    var [showMacrosClicked,setShowMacrosClicked] = React.useState(false);
    var macrosDetails = {
        "{click_id}" :  "Click ID",
        "{sub1}" :  "SubId #1",
        "{sub2}" :  "SubId #2",
        "{sub3}" :  "SubId #3",
        "{sub4}" :  "SubId #4",
        "{sub5}" :  "SubId #5",
        "{sub6}" :  "SubId #6",
        "{sub7}" :  "SubId #7",
        "{sub8}" :  "SubId #8",
        "{offer_id}" :  "Offer ID",
        "{aff_id}" :  "Affiliate ID",
        "{offer_name}" :  "Offer title",
        }
    
    const trackingUrlChecker = () => {
        var allowed_macros = [
            'click_id',
            'sub1',
            'sub2',
            'sub3',
            'sub4',
            'sub5',
            'sub6',
            'sub7',
            'sub8',
            'offer_id',
            'aff_id',
            'offer_name'
        ]
        if(offerTrackingUrl.includes('?')){
            var queryPart = offerTrackingUrl.split('?')[1]
            var query = queryPart.split('&')
            for(var index in query){
                var item = query[index]
                if(item.includes('{') && item.includes('}')){
                    var stringInBetween = item.substring(
                        item.indexOf("{") + 1, 
                        item.lastIndexOf("}")
                    );
                    if(!allowed_macros.includes(stringInBetween)){
                        return false
                    }
                }
            }
        }
        return true
    }
    const tracking_link = () =>{
        if(!requestDone){
            return <div style={{display:'flex',justifyContent:'center',alignItems:"center",height:'70vh'}}>
                <Loader
                type="Bars"
                color={localStorage.getItem('ambientColor')}
                height={50}
                width={100}
                //timeout={1000} //3 secs
                />
            </div>
        }
        else{
            return <div className='offerEditContainerV2'>
                    <div style={{width:'80%'}}>
                        <div className='offerEditLabelValueV2'>
                            <div className="offerEditLabelV2" style={offerTrackingUrl==="" ? {color:'red'} : {}}>
                                Tracking URL
                                <Tooltip TransitionComponent={Zoom} placement="right" title={"This link is provided by your advertiser and used to track user activity. You should add our macro to this link for s2s integration. {clickid} is mandatory"}>
                                    <HelpIcon style={{fontSize:"13px",marginLeft:'10px',color:'black'}}/>
                                </Tooltip>
                            </div>
                            <div style={{width:'100%'}}>
                                <CssTextField size='small' multiline rows={5} maxRows={10} fullWidth value={offerTrackingUrl.replaceAll('%20',' ')} onChange={(e)=>{setChangesDone(true);setOfferTrackingUrl(e.target.value.replaceAll(' ','%20'))}} sx={{transform:'scale(0.7) translateX(-15%) '}}/>
                                {!trackingUrlChecker() 
                                &&
                                <div style={{fontSize:'10px',color:'red',marginLeft:'27px',marginTop:'-18px'}}>Some of the macros used in the link are not supported by our panel. Kindly verify once again.</div>
                                }
                            </div>
                            <div style={{position:'absolute',right:'20%',cursor:'pointer'}} onClick={()=>{setShowMacrosClicked(!showMacrosClicked)}}>
                                <div style={{fontSize:'13px',color:localStorage.getItem('ambientColor')}}>Show Macros</div>
                                {showMacrosClicked && 
                                    <div style={{position:'absolute',paddingTop:'10px',marginLeft:'-50%',}}>
                                        <div style={{minHeight:'200px',minWidth:'100px',width:'max-content',padding:'10px',background:'white',boxShadow:'0 0 5px 1px grey'}} onClick={(e)=>{e.stopPropagation();}}>
                                            <table>
                                                <tbody>
                                                {Object.keys(macrosDetails).map((item,index)=>{
                                                    return <tr style={{fontSize:'12px'}} key={index}>
                                                                <td style={{textAlign:'center',paddingTop:'2px'}}>{item}</td> 
                                                                <td>-</td> 
                                                                <td style={{paddingLeft:'10px'}}>{macrosDetails[item]}</td>
                                                            </tr>
                                                })}
                                                </tbody>
                                            </table>
                                            
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className='offerEditLabelValueV2'>
                            <div className="offerEditLabelV2">
                                Preview URL
                                <Tooltip TransitionComponent={Zoom} placement="right" title={"Direct link to the landing page of the offer so your affiliates can see the offer’s page example"}>
                                    <HelpIcon style={{fontSize:"13px",marginLeft:'10px',color:'black'}}/>
                                </Tooltip>
                            </div>
                            <CssTextField size='small' multiline rows={5} maxRows={10} fullWidth defaultValue={offerPreviewUrl} onChange={(e)=>{setChangesDone(true);setOfferPreviewUrl(e.target.value.replace(/^\s+|\s+$/gm,''))}} sx={{transform:'scale(0.7) translateX(-15%)'}}/>
                        </div>
                        <div className='offerEditLabelValueV2'>
                            <div className="offerEditLabelV2">
                                Impression URL
                                <Tooltip TransitionComponent={Zoom} placement="right" title={"Link for impressions."}>
                                    <HelpIcon style={{fontSize:"13px",marginLeft:'10px',color:'black'}}/>
                                </Tooltip>
                            </div>
                            <CssTextField size='small' multiline rows={5} maxRows={10} fullWidth defaultValue={offerImpressionLink} onChange={(e)=>{setChangesDone(true);setOfferImpressionLink(e.target.value.replace(/^\s+|\s+$/gm,''))}} sx={{transform:'scale(0.7) translateX(-15%)'}}/>
                        </div>
                    </div>
            </div>
        }
    }

    // const [excludeCountriesCheck, setExcludeCountriesCheck] = useState(false)
    // const [selectedCountries, setSelectedCountries] = useState([])

    const countriesList = Object.keys(Constants.COUNTRIES_LIST_WITH_CODE).map((item,index)=>{
        return {label:Constants.COUNTRIES_LIST_WITH_CODE[item],value:item}
    })

    const setDefaultCountries = (countriesListParam) => {
        var countriesList = []
        var temp = countriesListParam ? countriesListParam : offerTargetingCountries
        for (var item in temp){
            for (var item2 in temp[item].countries){
                var countryCode = temp[item].countries[item2].toUpperCase()
                countriesList.push({value:countryCode,label:Constants.COUNTRIES_LIST_WITH_CODE[countryCode] ? Constants.COUNTRIES_LIST_WITH_CODE[countryCode] : countryCode})
            }
        }
        setSelectCountries(countriesList)
        return countriesList;
    }
    const changeTargettingCountries = (e) => {
        var countriesSelected = []
        if (e.length>0){
            if (e[0].value==="ALL" && e.length>1){
                for (var item in e){
                    if (item!=="0"){
                        countriesSelected.push(e[item].value.toUpperCase())
                    }
                }
            }
            else if(e[e.length-1].value==="ALL"){
                countriesSelected.push("ALL".toUpperCase())
            }
            else{
                for (var item2 in e){
                    countriesSelected.push(e[item2].value.toUpperCase())
                }
            }
        }
        setDefaultCountries([{countries:countriesSelected}])
        setOfferTargetingCountries([{countries:countriesSelected}])
        // offerTargetingCountriesFunction(countriesSelected,excludeCountriesCheck)
        // setSelectedCountries(countriesSelected)
    }
    // const offerTargetingCountriesFunction = (countriesSelected,checkbox) => {
    //     if(checkbox){
    //         var tempCountriesSelected = []
    //         for(var index in countriesList){
    //             if(!countriesSelected.includes(countriesList[index].value) && !tempCountriesSelected.includes(countriesList[index].value.toUpperCase())){
    //                 tempCountriesSelected.push(countriesList[index].value.toUpperCase())
    //             }
    //         }
    //         countriesSelected = tempCountriesSelected
    //     }
    //     setOfferTargetingCountries([{countries:countriesSelected}])
    // }
    const gaidOptions = [{label:'sub1',value:'sub1'},{label:'sub2',value:'sub2'},{label:'sub3',value:'sub3'},{label:'sub4',value:'sub4'},{label:'sub5',value:'sub5'},{label:'sub6',value:'sub6'},{label:'sub7',value:'sub7'},{label:'sub8',value:'sub8'}]
    const [gaidSelected, setGaidSelected] = useState([])
    const [subIdSelected, setSubIdSelected] = useState([])

    const getSubs = () => {
        if(subIdSelected.length>0){
            return subIdSelected.map((item,index)=>{
                return <div key={index}>
                    <div style={{display:'flex',alignItems:'center',marginBottom:'5px'}}>
                    <Select
                        options={[{label:'Enabled',value:'enabled'},{label:'Disabled',value:'disabled'}]}
                        value={{label:capitalize(item.type),value:item.type}}
                        onChange={(e)=>{setChangesDone(true);
                                        var tempSubIdSelected = subIdSelected;
                                        tempSubIdSelected[index].type = e.value;
                                        setSubIdSelected(tempSubIdSelected)
                                        forceUpdate(e);
                                            }}
                        menuPortalTarget={document.body} 
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }),
                                    control: (base,state) => ({
                                        ...base,
                                        minWidth:120,
                                        marginRight:10,
                                        // maxHeight: 100,
                                        minHeight: 30,
                                        fontSize:'10px',
                                        // transform:'scale(0.8) translateX(-11.5%)',
                                        overflowY: "auto",
                                        border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid rgb(200,200,200)',
                                        boxShadow: 0,
                                        '&:hover': {
                                            border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                            boxShadow: 0,
                                        }
                                    }),
                                    option: (styles, {isFocused, isSelected}) => ({
                                        ...styles,
                                        fontSize:'11px',
                                        background: isFocused
                                            ? localStorage.getItem('ambientColor')
                                            : isSelected
                                                ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                                : undefined,
                                        zIndex: 1
                                    }),
                                }}
                            />
                        <Select
                            options={[{label:'sub1',value:'sub1'},{label:'sub2',value:'sub2'},{label:'sub3',value:'sub3'},{label:'sub4',value:'sub4'},{label:'sub5',value:'sub5'},{label:'sub6',value:'sub6'},{label:'sub7',value:'sub7'},{label:'sub8',value:'sub8'}]}
                            value={{label:item.field,value:item.field}}
                            onChange={(e)=>{setChangesDone(true);
                                            var tempSubIdSelected = subIdSelected;
                                            tempSubIdSelected[index].field = e.value;
                                            setSubIdSelected(tempSubIdSelected)
                                            forceUpdate(e);
                                                }}
                            menuPortalTarget={document.body} 
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }),
                                        control: (base,state) => ({
                                            ...base,
                                            minWidth:100,
                                            // maxHeight: 100,
                                            minHeight: 30,
                                            fontSize:'10px',
                                            // transform:'scale(0.8) translateX(-11.5%)',
                                            overflowY: "auto",
                                            border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid rgb(200,200,200)',
                                            boxShadow: 0,
                                            '&:hover': {
                                                border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                                boxShadow: 0,
                                            }
                                        }),
                                        option: (styles, {isFocused, isSelected}) => ({
                                            ...styles,
                                            fontSize:'11px',
                                            background: isFocused
                                                ? localStorage.getItem('ambientColor')
                                                : isSelected
                                                    ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                                    : undefined,
                                            zIndex: 1
                                        }),
                                    }}
                            />
                        <CssTextField value={item.value} placeholder="value" size='small' sx={{marginLeft:'10px','& .MuiOutlinedInput-root':{fontSize:'13px'}}} onChange={(e)=>{var tempSubIdSelected = subIdSelected;
                                            tempSubIdSelected[index].value = e.target.value;
                                            setSubIdSelected(tempSubIdSelected)
                                            forceUpdate(e);
                                            }}/>
                        <DeleteIcon className="payoutIconsV2" sx={{fontSize:"18px",marginLeft:'10px',color:'rgb(150,150,150)','&:hover':{color:'black'}}} onClick={(e)=>{var tempSubIdSelected = subIdSelected;tempSubIdSelected.splice(index,1);setSubIdSelected(tempSubIdSelected);forceUpdate(e);}}/>
                        
                    </div>
                    {index===subIdSelected.length-1 &&
                        <div className='addButtonV2' onClick={(e)=>{
                                        setSubIdSelected([...subIdSelected,{
                                            "type": 'enabled',
                                            "field": 'sub1',
                                            "value": "",
                                            }]);
                                        forceUpdate(e);
                                        }}>
                        +
                        </div>
                    }
                </div>
            })
        }
        else{
            return <div className='addButtonV2' onClick={(e)=>{
                                    setSubIdSelected([...subIdSelected,{
                                        "type": 'enabled',
                                        "field": 'sub1',
                                        "value": "",
                                        }]);
                                    forceUpdate(e);
                                    }}>
                    +
                    </div>
        }
    }
    const targetting = () =>{
        if(!requestDone){
            return <div style={{display:'flex',justifyContent:'center',alignItems:"center",height:'70vh'}}>
                <Loader
                type="Bars"
                color={localStorage.getItem('ambientColor')}
                height={50}
                width={100}
                //timeout={1000} //3 secs
                />
            </div>
        }
        else{
            return <div className='offerEditContainerV2'>
                    <div style={{width:'80%'}}>
                        <div className='offerEditLabelValueV2' style={{marginTop:'5px'}}>
                            <div className="offerEditLabelV2">
                                Countries
                            </div>
                            <div style={{width:'50%',fontSize:'14px'}}>
                                <Select
                                    isMulti
                                    options={countriesList}
                                    defaultValue
                                    value={selectCountries}
                                    onChange={(e)=>{setChangesDone(true);changeTargettingCountries(e)}}
                                    menuPortalTarget={document.body} 
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }),
                                                control: (base,state) => ({
                                                    ...base,
                                                    // maxHeight: 100,
                                                    minHeight: 30,
                                                    fontSize:'10px',
                                                    // transform:'scale(0.8) translateX(-11.5%)',
                                                    overflowY: "auto",
                                                    border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid rgb(200,200,200)',
                                                    boxShadow: 0,
                                                    '&:hover': {
                                                        border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                                        boxShadow: 0,
                                                    }
                                                }),
                                                option: (styles, {isFocused, isSelected}) => ({
                                                    ...styles,
                                                    fontSize:'11px',
                                                    background: isFocused
                                                        ? localStorage.getItem('ambientColor')
                                                        : isSelected
                                                            ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                                            : undefined,
                                                    zIndex: 1
                                                }),
                                            }}
                                    />
                            </div>
                            {/* <div style={{marginLeft:'15px',display:'flex',alignItems:'center'}}>
                                <input checked={excludeCountriesCheck} onChange={(e)=>{setExcludeCountriesCheck(e.target.checked);offerTargetingCountriesFunction(selectedCountries,e.target.checked)}} type='checkbox' id='exclude-targeting-countries' style={{height:'10px',width:'10px',marginTop:'5px'}}/>
                                <label htmlFor='exclude-targeting-countries' style={{fontSize:'12px',color:'rgb(50,50,50)'}}>Exclude</label>
                            </div> */}
                        </div>
                        <div className='offerEditLabelValueV2' style={{marginTop:'20px'}}>
                            <div className="offerEditLabelV2">
                                Block Traffic {"(if empty)"}
                            </div>
                            <div style={{width:'50%',fontSize:'14px'}}>
                                <Select
                                    isMulti
                                    options={gaidOptions}
                                    defaultValue
                                    value={gaidSelected}
                                    onChange={(e)=>{setChangesDone(true);setGaidSelected(e)}}
                                    menuPortalTarget={document.body} 
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }),
                                                control: (base,state) => ({
                                                    ...base,
                                                    // maxHeight: 100,
                                                    minHeight: 30,
                                                    fontSize:'10px',
                                                    // transform:'scale(0.8) translateX(-11.5%)',
                                                    overflowY: "auto",
                                                    border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid rgb(200,200,200)',
                                                    boxShadow: 0,
                                                    '&:hover': {
                                                        border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                                        boxShadow: 0,
                                                    }
                                                }),
                                                option: (styles, {isFocused, isSelected}) => ({
                                                    ...styles,
                                                    fontSize:'11px',
                                                    background: isFocused
                                                        ? localStorage.getItem('ambientColor')
                                                        : isSelected
                                                            ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                                            : undefined,
                                                    zIndex: 1
                                                }),
                                            }}
                                    />
                            </div>
                        </div>
                        <div className='offerEditLabelValueV2' style={{marginTop:'20px',alignItems:'flex-start'}}>
                            <div className="offerEditLabelV2" style={{paddingTop:'10px'}}>
                                Subs
                            </div>
                            <div style={{width:'50%',fontSize:'14px'}}>
                                {getSubs()}
                            </div>
                        </div>
                        
                    </div>
            </div>
        }
    }

    var decimal = /^[+-]?(?:\d*\.)?\d+$/;
    const [payoutInvalid, setPayoutInvalid] = useState(false)
    const [advertiserPayoutPercentage, setAdvertiserPayoutPercentage] = useState(70)
    const getDemandSourceManual = async(value) => {
        axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.DEMANDSOURCE),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params:{
                advertiser_id:value,
            }
        })
        .then(resp => {
            if(resp.data.result.length>0){
                var tempPayoutPercentage = 70
                tempPayoutPercentage = resp.data.result[0].payout_percentage;
                tempPayoutPercentage = parseInt(tempPayoutPercentage)
                setAdvertiserPayoutPercentage(tempPayoutPercentage)
            }
            console.log(resp)
        })
        .catch(err => {
            console.error(err);
        }) 
    };  
    const payouts = () => {
        var options = {
            affiliate_id : affiliateList,
            countries : countriesList,
            currency : Object.keys(Constants.CURRENCY_LIST).map((currencyItem,currencyindex)=>{
                            return {label:currencyItem,value:currencyItem}
                        }),
            payment_type:[{label:'CPA',value:'CPA'},{label:'CPI',value:'CPI'}]
        }
        var payoutsHeads = [
            {label:'Affiliate',value:'affiliate_id',width:'180px'},
            {label:'Countries',value:'countries',width:'200px'},
            {label:'Currency',value:'currency',width:'100px'},
            {label:'Payment Type',value:'payment_type',width:'150px'},
            {label:'Revenue',value:'revenue',width:'100px'},
            {label:'Payout',value:'payout',width:'100px'},
            {label:'Goal Value',value:'goal',width:'100px'},
            {label:'Goal Title',value:'title',width:'200px'},
        ]
        if(!requestDone){
            return <div style={{display:'flex',justifyContent:'center',alignItems:"center",height:'70vh'}}>
                <Loader
                type="Bars"
                color={localStorage.getItem('ambientColor')}
                height={50}
                width={100}
                //timeout={1000} //3 secs
                />
            </div>
        }
        else{
            return <div className='offerEditContainerV2' onClick={()=>{if(showMacrosClicked){setShowMacrosClicked(false);}}}>
                    <div className='offerEditLabelValueV2' style={{marginTop:'5px',marginBottom:"15px"}}>
                        <div className="offerEditLabelV2">
                            Repeat Postback Allowed 
                            <Tooltip title='If selected, panel will put goal to delivered quantity.'>
                                <HelpIcon style={{fontSize:"13px",marginLeft:'10px',color:'black'}}/>
                            </Tooltip>
                        </div>
                        <div style={{width:'50%',fontSize:'14px'}}>
                            <Select
                                options={offerPayouts.map((item)=>{return {label:item.goal,value:item.goal}})}
                                value={offerRepeatPostback ? {label:offerRepeatPostback,value:offerRepeatPostback} : null}
                                onChange={(e)=>{
                                    setChangesDone(true);
                                    if(e && e.value){
                                        setOfferRepeatPostback(e.value)
                                    }
                                    else{
                                        setOfferRepeatPostback('')
                                    }
                                    forceUpdate(e)
                                }}
                                isClearable
                                menuPortalTarget={document.body} 
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }),
                                            control: (base,state) => ({
                                                ...base,
                                                // maxHeight: 100,
                                                minHeight: 30,
                                                transform:'scale(0.8) translateX(-11.5%)',
                                                overflowY: "auto",
                                                border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid rgb(200,200,200)',
                                                boxShadow: 0,
                                                '&:hover': {
                                                    border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                                    boxShadow: 0,
                                                }
                                            }),
                                            option: (styles, {isFocused, isSelected}) => ({
                                                ...styles,
                                                fontSize:'11px',
                                                background: isFocused
                                                    ? localStorage.getItem('ambientColor')
                                                    : isSelected
                                                        ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                                        : undefined,
                                                zIndex: 1
                                            }),
                                        }}
                            />
                        </div>
                    </div>
                    {offerPayouts.map((payoutItem,payoutIndex)=>{
                        return <div key={payoutIndex} className="payoutContainerV2">
                                    <div style={{display:'flex',justifyContent:'flex-end'}}>
                                        <Tooltip TransitionComponent={Zoom} title={"Duplicate Payout"}>
                                            <ContentCopyIcon sx={{fontSize:"13px",marginLeft:'10px',color:'rgb(150,150,150)','&:hover':{color:'black'}}} onClick={(e)=>{setOfferPayouts([...offerPayouts,{...payoutItem,copy:offerPayouts.length}]);forceUpdate(e);}}/>
                                        </Tooltip>
                                        <Tooltip TransitionComponent={Zoom} title={"Delete Payout"}>
                                            <DeleteIcon className="payoutIconsV2" sx={{fontSize:"15px",marginLeft:'10px',color:'rgb(150,150,150)','&:hover':{color:'black'}}} onClick={(e)=>{var tempPayout = [];for(var index in offerPayouts){if(parseInt(index)!==parseInt(payoutIndex)){tempPayout.push(offerPayouts[index])}};setOfferPayouts(tempPayout);forceUpdate(e);}}/>
                                        </Tooltip>
                                    </div>
                                    <div style={{display:'flex',alignItems:'center',flexWrap:'wrap'}}>
                                        {payoutsHeads.map((item,index)=>{
                                            if(item.value==='affiliate_id' || item.value==='countries' || item.value==='currency' || item.value==='payment_type'){
                                                var labelView = ""
                                                for(index in options[item.value]){
                                                    if(item.value!=='countries' && payoutItem[item.value]!==null && options[item.value][index].value!==null){
                                                        if(payoutItem[item.value].toString().toLowerCase()===options[item.value][index].value.toLowerCase()){
                                                            labelView = options[item.value][index].label
                                                        }
                                                    }
                                                }
                                                return <div key={payoutIndex+"_"+index} style={{width:item.width,marginTop:'3px',marginBottom:'10px'}}>
                                                            <div style={{paddingLeft:'3px'}}>{item.label}</div>
                                                            <div>
                                                                <Select
                                                                    options={options[item.value]}
                                                                    isMulti={item.value==='countries' ? true : false}
                                                                    value={(item.value==='affiliate_id' && !payoutItem[item.value]) ? {label:'All',value:null} : payoutItem[item.value]===null ? "" : item.value==='countries' ? payoutItem[item.value].map((countryItem,countryIndex)=>{return {label:Constants.COUNTRIES_LIST_WITH_CODE[countryItem.toUpperCase()],value:countryItem}}) : {label:labelView ? labelView : payoutItem[item.value],value:payoutItem[item.value]}}
                                                                    onChange={(e)=>{
                                                                        setChangesDone(true);
                                                                        if(item.value==='countries'){
                                                                            var tempCountries = []
                                                                            for(var index in e){
                                                                                tempCountries.push(e[index].value)
                                                                            }
                                                                            payoutItem[item.value]=tempCountries
                                                                        }
                                                                        else{
                                                                            payoutItem[item.value]=e.value
                                                                        }
                                                                        var tempPayoutList = offerPayouts
                                                                        tempPayoutList[payoutIndex]=payoutItem
                                                                        setOfferPayouts(tempPayoutList);
                                                                        forceUpdate(e)
                                                                    }}
                                                                    menuPortalTarget={document.body} 
                                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }),
                                                                                control: (base,state) => ({
                                                                                    ...base,
                                                                                    // maxHeight: 100,
                                                                                    minHeight: 30,
                                                                                    transform:'scale(0.8) translateX(-11.5%)',
                                                                                    overflowY: "auto",
                                                                                    border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid rgb(200,200,200)',
                                                                                    boxShadow: 0,
                                                                                    '&:hover': {
                                                                                        border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                                                                        boxShadow: 0,
                                                                                    }
                                                                                }),
                                                                                option: (styles, {isFocused, isSelected}) => ({
                                                                                    ...styles,
                                                                                    fontSize:'11px',
                                                                                    background: isFocused
                                                                                        ? localStorage.getItem('ambientColor')
                                                                                        : isSelected
                                                                                            ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                                                                            : undefined,
                                                                                    zIndex: 1
                                                                                }),
                                                                            }}
                                                                    />
                                                            </div>
                                                        </div>
                                            }
                                            return <div key={payoutIndex+"_"+index} style={{width:item.width,marginTop:'5px',marginBottom:'10px'}}>
                                                    <div  style={{paddingLeft:'2px'}}>{item.label}</div>
                                                    <div style={{transform:'scale(0.8) translateX(-11%)'}}>
                                                        <CssTextField size='small' value={payoutItem[item.value]} sx={{background:'white'}} fullWidth onChange={(e)=>{setChangesDone(true);if((item.value==='payout' || item.value==='revenue') && e.target.value!=="" && !e.target.value.toString().match(decimal)){setSaveButtonDisabled(true);setPayoutInvalid(true);}else{setSaveButtonDisabled(false);setPayoutInvalid(false);};var tempPayoutDict = {...offerPayouts[payoutIndex],[item.value]:e.target.value};if(item.value==='revenue' && e.target.value!==''){tempPayoutDict = {...offerPayouts[payoutIndex],payout:parseFloat(e.target.value)*advertiserPayoutPercentage/100,[item.value]:e.target.value}}; var tempPayoutList = offerPayouts; tempPayoutList[payoutIndex] = tempPayoutDict; setOfferPayouts(tempPayoutList);forceUpdate(e)}}/>
                                                    </div>
                                                    {(item.value==='payout' || item.value==='revenue')&& payoutItem[item.value]!=="" && !payoutItem[item.value].toString().match(decimal) && <div style={{fontSize:'10px',color:'red',position:'absolute',width:item.width}}>Invalid {item.value}</div>}
                                                    {(item.value==='goal') && payoutItem[item.value]!=="" && !payoutItem[item.value].toString().match(/(^[A-Za-z0-9_-]*$)/) && <div style={{fontSize:'10px',color:'red',position:'absolute',width:item.width}}>Invalid {item.value} Value</div>}
                                                </div>
                                        })}
                                    </div>
                                </div>
                    })}
                    <div className='addButtonV2' onClick={(e)=>{
                                                                setOfferPayouts([...offerPayouts,{
                                                                    "affiliate_id": null,
                                                                    "countries": [],
                                                                    "currency": "USD",
                                                                    "goal": "",
                                                                    "payment_type": "CPI",
                                                                    "payout": 0,
                                                                    "revenue": 0,
                                                                    "title": ""
                                                                    }]);
                                                                forceUpdate(e);
                                                                }}>
                        +
                    </div>
                </div>
        }
    }

    var tempCapsOptions = {
        countries : countriesList,
        period : [{label:'Hour',value:'hour'},{label:'Day',value:'day'},{label:'Week',value:'week'},{label:'Month',value:'month'},{label:'Year',value:'year'},{label:'Lifetime',value:'lifetime'}],
        type : [{label:'Clicks',value:'clicks'},{label:'Conversions',value:'conversions'},{label:'Impressions',value:'impressions'},{label:'Payouts',value:'payouts'},],
    }
    
    var numbers = /^\d+$/;

    const [capsOptions, setCapsOptions] = useState(tempCapsOptions)
    const [capsInvalid, setCapsInvalid] = useState(false)
    const [conversionCapsStatus, setConversionCapsStatus] = useState([])
    const [overcapsAllowed, setOvercapsAllowed] = useState(false)
    const caps = () => {
        var options = {
            affiliate : affiliateList,
            goal_title : offerPayouts.map((goalItem,goalIndex)=>{
                return {label:goalItem['title'] + "("+goalItem['goal']+")",value:goalItem['goal'].toString()}
            }),
        }
        var payoutsHeads = [
            {label:'Affiliate',value:'affiliate',width:'180px'},
            {label:'Countries',value:'countries',width:'250px'},
            {label:'Period',value:'period',width:'150px'},
            {label:'Goal',value:'goal',width:'200px'},
            {label:'Type',value:'type',width:'150px'},
            {label:'Value',value:'value',width:'100px'},
        ]
        if(!requestDone){
            return <div style={{display:'flex',justifyContent:'center',alignItems:"center",height:'70vh'}}>
                <Loader
                type="Bars"
                color={localStorage.getItem('ambientColor')}
                height={50}
                width={100}
                //timeout={1000} //3 secs
                />
            </div>
        }
        else{
            return <div className='offerEditContainerV2'>
                    {isEdit && 
                        <div className='offerEditLabelValueV2' style={{marginTop:'5px',marginBottom:"15px"}}>
                            <div className="offerEditLabelV2">
                                Conversion Status for caps count
                            </div>
                            <div style={{width:'50%',fontSize:'14px'}}>
                                <Select
                                        options={[{label:'Approved',value:'approved'},{label:'Declined',value:'declined'},]}
                                        isMulti={true}
                                        value={conversionCapsStatus}
                                        onChange={(e)=>{setChangesDone(true);setConversionCapsStatus(e)}}
                                        menuPortalTarget={document.body} 
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }),
                                                    control: (base,state) => ({
                                                        ...base,
                                                        // maxHeight: 100,
                                                        minHeight: 30,
                                                        transform:'scale(0.8) translateX(-11.5%)',
                                                        overflowY: "auto",
                                                        border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid rgb(200,200,200)',
                                                        boxShadow: 0,
                                                        '&:hover': {
                                                            border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                                            boxShadow: 0,
                                                        }
                                                    }),
                                                    option: (styles, {isFocused, isSelected}) => ({
                                                        ...styles,
                                                        fontSize:'11px',
                                                        background: isFocused
                                                            ? localStorage.getItem('ambientColor')
                                                            : isSelected
                                                                ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                                                : undefined,
                                                        zIndex: 1
                                                    }),
                                                }}
                                    />
                            </div>
                        </div>
                    }
                    <div>
                        <div className='offerEditLabelValueV2' style={{marginTop:'5px',marginBottom:"15px"}}>
                            <div className="offerEditLabelV2">
                                Overcaps 
                                <Tooltip title='If enabled, link will automatically be paused if caps reached'>
                                    <HelpIcon style={{fontSize:"13px",marginLeft:'10px',color:'black'}}/>
                                </Tooltip>
                            </div>
                            <div style={{width:'50%',fontSize:'14px'}}>
                                <input type='checkbox' checked={overcapsAllowed} onChange={(e)=>{setOvercapsAllowed(e.target.checked)}}/>
                            </div>
                        </div>
                        <div className='offerEditLabelValueV2' style={{marginTop:'5px',marginBottom:"15px"}}>
                            <div className="offerEditLabelV2">
                                Default Goal Overcap 
                            </div>
                            <div style={{width:'50%',fontSize:'14px'}}>
                                <Select
                                    options={options['goal_title']}
                                    isMulti={false}
                                    value={overcapsGoal}
                                    onChange={(e)=>{setOvercapsGoal(e);setChangesDone(true);}}
                                    menuPortalTarget={document.body} 
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }),
                                                control: (base,state) => ({
                                                    ...base,
                                                    // maxHeight: 100,
                                                    minHeight: 30,
                                                    transform:'scale(0.8) translateX(-11.5%)',
                                                    overflowY: "auto",
                                                    border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid rgb(200,200,200)',
                                                    boxShadow: 0,
                                                    '&:hover': {
                                                        border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                                        boxShadow: 0,
                                                    }
                                                }),
                                                option: (styles, {isFocused, isSelected}) => ({
                                                    ...styles,
                                                    fontSize:'11px',
                                                    background: isFocused
                                                        ? localStorage.getItem('ambientColor')
                                                        : isSelected
                                                            ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                                            : undefined,
                                                    zIndex: 1
                                                }),
                                            }}
                                    />
                            </div>
                        </div>
                    </div>
                    {offerCaps.map((payoutItem,payoutIndex)=>{
                        return <div key={payoutIndex} className="payoutContainerV2">
                                    <div style={{display:'flex',justifyContent:'flex-end'}}>
                                        <Tooltip TransitionComponent={Zoom} title={"Duplicate Caps"}>
                                            <ContentCopyIcon sx={{fontSize:"13px",marginLeft:'10px',color:'rgb(150,150,150)','&:hover':{color:'black'}}} onClick={(e)=>{setOfferCaps([...offerCaps,{...payoutItem,copy:offerCaps.length}]);forceUpdate(e);}}/>
                                        </Tooltip>
                                        <Tooltip TransitionComponent={Zoom} title={"Delete Caps"}>
                                            <DeleteIcon className="payoutIconsV2" sx={{fontSize:"15px",marginLeft:'10px',color:'rgb(150,150,150)','&:hover':{color:'black'}}} onClick={(e)=>{var tempPayout = [];for(var index in offerCaps){if(parseInt(index)!==parseInt(payoutIndex)){tempPayout.push(offerCaps[index])}};setOfferCaps(tempPayout);forceUpdate(e);}}/>
                                        </Tooltip>
                                    </div>
                                    <div style={{display:'flex',alignItems:'center',flexWrap:'wrap'}}>
                                        {payoutsHeads.map((item,index)=>{
                                            if(item.value==='countries' || item.value==='period' || item.value==='type'){
                                                var labelView = ""
                                                for(index in capsOptions[item.value]){
                                                    if(item.value!=='countries' && payoutItem[item.value]!==null && capsOptions[item.value][index].value!==null){
                                                        if(payoutItem[item.value].toString().toLowerCase()===capsOptions[item.value][index].value.toLowerCase()){
                                                            labelView = capsOptions[item.value][index].label
                                                        }
                                                    }
                                                }
                                                return <div key={payoutIndex+"_"+index} style={{width:item.width,marginTop:'3px',marginBottom:'10px'}}>
                                                            <div style={{paddingLeft:'3px'}}>{item.label}</div>
                                                            <div>
                                                                <Select
                                                                    options={capsOptions[item.value]}
                                                                    isMulti={item.value==='countries' ? true : false}
                                                                    value={item.value==='countries' ? payoutItem[item.value].map((countryItem,countryIndex)=>{return {label:Constants.COUNTRIES_LIST_WITH_CODE[countryItem.toUpperCase()],value:countryItem}}) : {label:labelView ? labelView : payoutItem[item.value],value:payoutItem[item.value]}}
                                                                    onChange={(e)=>{
                                                                        setChangesDone(true);
                                                                        if(item.value==='countries'){
                                                                            var tempCountries = []
                                                                            for(var index in e){
                                                                                tempCountries.push(e[index].value)
                                                                            }
                                                                            payoutItem[item.value]=tempCountries
                                                                        }
                                                                        else{
                                                                            payoutItem[item.value]=e.value
                                                                        }
                                                                        var tempPayoutList = offerCaps
                                                                        tempPayoutList[payoutIndex]=payoutItem
                                                                        setOfferCaps(tempPayoutList);
                                                                        forceUpdate(e)
                                                                    }}
                                                                    onInputChange={(input,action)=>{if(action.action==="set-value" && action.prevInputValue!==""){setCapsOptions({...capsOptions,[item.value]:[...capsOptions[item.value],{label:action.prevInputValue,value:action.prevInputValue}]})}}}
                                                                    menuPortalTarget={document.body} 
                                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }),
                                                                                control: (base,state) => ({
                                                                                    ...base,
                                                                                    // maxHeight: 100,
                                                                                    minHeight: 30,
                                                                                    transform:'scale(0.8) translateX(-11.5%)',
                                                                                    overflowY: "auto",
                                                                                    border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid rgb(200,200,200)',
                                                                                    boxShadow: 0,
                                                                                    '&:hover': {
                                                                                        border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                                                                        boxShadow: 0,
                                                                                    }
                                                                                }),
                                                                                option: (styles, {isFocused, isSelected}) => ({
                                                                                    ...styles,
                                                                                    fontSize:'11px',
                                                                                    background: isFocused
                                                                                        ? localStorage.getItem('ambientColor')
                                                                                        : isSelected
                                                                                            ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                                                                            : undefined,
                                                                                    zIndex: 1
                                                                                }),
                                                                            }}
                                                                    />
                                                            </div>
                                                        </div>
                                            }
                                            if(item.value==='affiliate'){
                                                var labelValueView = []
                                                for(index in options[item.value]){
                                                    for(var index2 in payoutItem[item.value]){
                                                        if(payoutItem[item.value][index2] && payoutItem[item.value]!==null && options[item.value][index].value!==null){
                                                            if(payoutItem[item.value][index2].toString()===options[item.value][index].value.toString()){
                                                                labelValueView.push(options[item.value][index])
                                                            }
                                                        }
                                                    }
                                                }
                                                return <div key={payoutIndex+"_"+index} style={{width:item.width,marginTop:'3px',marginBottom:'10px'}}>
                                                            <div style={{paddingLeft:'3px'}}>{item.label}</div>
                                                            <div>
                                                                <Select
                                                                    options={options[item.value]}
                                                                    isMulti={item.value==='affiliate' ? true : false}
                                                                    value={(!payoutItem[item.value] || payoutItem[item.value].length===0) ? {label:"All",value:null} : item.value==='affiliate' ? labelValueView : {label:labelValueView ? labelValueView : payoutItem[item.value],value:payoutItem[item.value]}}
                                                                    onChange={(e)=>{
                                                                        setChangesDone(true);
                                                                        if(item.value==='affiliate'){
                                                                            var tempAffiliates = []
                                                                            for(var index in e){
                                                                                if(e[index].value){
                                                                                    tempAffiliates.push(e[index].value)
                                                                                }
                                                                            }
                                                                            if(e[e.length-1].value===null){
                                                                                tempAffiliates = []
                                                                            }
                                                                            payoutItem[item.value]=tempAffiliates
                                                                        }
                                                                        else{
                                                                            payoutItem[item.value]=e.value
                                                                        }
                                                                        var tempPayoutList = offerCaps
                                                                        tempPayoutList[payoutIndex]=payoutItem
                                                                        setOfferCaps(tempPayoutList);
                                                                        forceUpdate(e)
                                                                    }}
                                                                    menuPortalTarget={document.body} 
                                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }),
                                                                                control: (base,state) => ({
                                                                                    ...base,
                                                                                    // maxHeight: 100,
                                                                                    minHeight: 30,
                                                                                    transform:'scale(0.8) translateX(-11.5%)',
                                                                                    overflowY: "auto",
                                                                                    border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid rgb(200,200,200)',
                                                                                    boxShadow: 0,
                                                                                    '&:hover': {
                                                                                        border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                                                                        boxShadow: 0,
                                                                                    }
                                                                                }),
                                                                                option: (styles, {isFocused, isSelected}) => ({
                                                                                    ...styles,
                                                                                    fontSize:'11px',
                                                                                    background: isFocused
                                                                                        ? localStorage.getItem('ambientColor')
                                                                                        : isSelected
                                                                                            ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                                                                            : undefined,
                                                                                    zIndex: 1
                                                                                }),
                                                                            }}
                                                                    />
                                                            </div>
                                                        </div>
                                            }

                                            if(item.value==='goal'){
                                                return <div key={payoutIndex+"_"+index} style={{width:item.width,marginTop:'3px',marginBottom:'10px'}}>
                                                            <div style={{paddingLeft:'3px'}}>{item.label}</div>
                                                            <div>
                                                                <CreatableSelect
                                                                    options={offerPayouts.map((goalItem,goalIndex)=>{
                                                                        return {label:goalItem['title'] + "("+goalItem['goal']+")",value:goalItem['goal'].toString()}
                                                                    })}
                                                                    isMulti={item.value==='affiliate' ? true : false}
                                                                    value={{label:payoutItem['goal'],value:payoutItem['goal']}}
                                                                    onChange={(e)=>{
                                                                        setChangesDone(true);
                                                                        payoutItem[item.value]=e.value
                                                                        var tempPayoutList = offerCaps
                                                                        tempPayoutList[payoutIndex]=payoutItem
                                                                        setOfferCaps(tempPayoutList);
                                                                        forceUpdate(e)
                                                                    }}
                                                                    menuPortalTarget={document.body} 
                                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }),
                                                                                control: (base,state) => ({
                                                                                    ...base,
                                                                                    // maxHeight: 100,
                                                                                    minHeight: 30,
                                                                                    transform:'scale(0.8) translateX(-11.5%)',
                                                                                    overflowY: "auto",
                                                                                    border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid rgb(200,200,200)',
                                                                                    boxShadow: 0,
                                                                                    '&:hover': {
                                                                                        border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                                                                        boxShadow: 0,
                                                                                    }
                                                                                }),
                                                                                option: (styles, {isFocused, isSelected}) => ({
                                                                                    ...styles,
                                                                                    fontSize:'11px',
                                                                                    background: isFocused
                                                                                        ? localStorage.getItem('ambientColor')
                                                                                        : isSelected
                                                                                            ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                                                                            : undefined,
                                                                                    zIndex: 1
                                                                                }),
                                                                            }}
                                                                    />
                                                            </div>
                                                        </div>
                                            }
                                            return <div key={"text"+payoutIndex+"_"+index} style={{width:item.width,marginTop:'5px',marginBottom:'10px'}}>
                                                    <div  style={{paddingLeft:'2px'}}>{item.label}</div>
                                                    <div style={{transform:'scale(0.8) translateX(-11%)'}}>
                                                        <CssTextField size='small' value={payoutItem[item.value]} sx={{background:'white'}} fullWidth onChange={(e)=>{setChangesDone(true);if(item.value==='value' && e.target.value!=="" && !e.target.value.toString().match(numbers)){setSaveButtonDisabled(true);setCapsInvalid(true);}else{setSaveButtonDisabled(false);setCapsInvalid(false);};var tempPayoutDict = {...offerCaps[payoutIndex],[item.value]:e.target.value}; var tempPayoutList = offerCaps; tempPayoutList[payoutIndex] = tempPayoutDict; setOfferCaps(tempPayoutList);forceUpdate(e)}}/>
                                                    </div>
                                                    {item.value==='value' && payoutItem[item.value]!=="" && !payoutItem[item.value].toString().match(numbers) && <div style={{fontSize:'10px',color:'red',position:'absolute',width:item.width}}>Invalid {item.value}</div>}
                                                </div>
                                        })}
                                    </div>
                                </div>
                    })}
                    <div className='addButtonV2' onClick={(e)=>{
                                                                setOfferCaps([...offerCaps,{
                                                                    "period": "day",
                                                                    "type": "conversions",
                                                                    "value": 0,
                                                                    "countries": [],
                                                                    "goal": []
                                                                    }]);
                                                                forceUpdate(e);
                                                                }}>
                        +
                    </div>
                </div>
        }
    }
    const cutbacks = () => {
        var options = {
            affiliate_id : affiliateList,
            goal_value : offerPayouts.map((goalItem,goalIndex)=>{
                return {label:goalItem['title'] + "("+goalItem['goal']+")",value:goalItem['goal'].toString()}
            }),
        }
        var payoutsHeads = [
            {label:'Affiliate',value:'affiliate_id',width:'33%'},
            {label:'Goal Value',value:'goal_value',width:'33%'},
            {label:'Cutback Value*',value:'cutback_value',width:'33%'},
        ]
        if(!requestDone){
            return <div style={{display:'flex',justifyContent:'center',alignItems:"center",height:'70vh'}}>
                <Loader
                type="Bars"
                color={localStorage.getItem('ambientColor')}
                height={50}
                width={100}
                //timeout={1000} //3 secs
                />
            </div>
        }
        else{
            return <div className='offerEditContainerV2'>
                    {offerCutback.map((payoutItem,payoutIndex)=>{
                        return <div key={payoutIndex} className="payoutContainerV2">
                                    <div style={{display:'flex',justifyContent:'flex-end'}}>
                                        <Tooltip TransitionComponent={Zoom} title={"Duplicate Cutback"}>
                                            <ContentCopyIcon sx={{fontSize:"13px",marginLeft:'10px',color:'rgb(150,150,150)','&:hover':{color:'black'}}} onClick={(e)=>{setOfferCutback([...offerCutback,{...payoutItem,copy:offerCutback.length}]);forceUpdate(e);}}/>
                                        </Tooltip>
                                        <Tooltip TransitionComponent={Zoom} title={"Delete Cutback"}>
                                            <DeleteIcon className="payoutIconsV2" sx={{fontSize:"15px",marginLeft:'10px',color:'rgb(150,150,150)','&:hover':{color:'black'}}} onClick={(e)=>{var tempPayout = [];for(var index in offerCutback){if(parseInt(index)!==parseInt(payoutIndex)){tempPayout.push(offerCutback[index])}};setOfferCutback(tempPayout);forceUpdate(e);}}/>
                                        </Tooltip>
                                    </div>
                                    <div style={{display:'flex',alignItems:'center',flexWrap:'wrap'}}>
                                        {payoutsHeads.map((item,index)=>{
                                            if(item.value==='affiliate_id' || item.value==='goal_value'){
                                                var labelView = ""
                                                for(index in options[item.value]){
                                                    if(payoutItem[item.value] && payoutItem[item.value]!==null && options[item.value][index].value!==null){
                                                        if(payoutItem[item.value].toString().toLowerCase()===options[item.value][index].value.toLowerCase()){
                                                            labelView = options[item.value][index].label
                                                        }
                                                    }
                                                    if(item.value==='goal_value'){
                                                        if(payoutItem['goal_value'].toString()===options[item.value][index].value.toString()){
                                                            labelView = options[item.value][index].label
                                                        }
                                                    }
                                                }
                                                return <div key={payoutIndex+"_"+index} style={{width:item.width,marginTop:'3px',marginBottom:'10px'}}>
                                                            <div style={{paddingLeft:'3px'}}>{item.label}</div>
                                                            <div>
                                                                <Select
                                                                    options={options[item.value]}
                                                                    isMulti={item.value==='countries' ? true : false}
                                                                    value={{label:labelView ? labelView : payoutItem[item.value],value:payoutItem[item.value]}}
                                                                    onChange={(e)=>{
                                                                        setChangesDone(true);
                                                                        if(item.value==='countries'){
                                                                            var tempCountries = []
                                                                            for(var index in e){
                                                                                tempCountries.push(e[index].value)
                                                                            }
                                                                            payoutItem[item.value]=tempCountries
                                                                        }
                                                                        else{
                                                                            if(item.value==='goal_value'){
                                                                                // payoutItem[item.value] = e.label
                                                                                payoutItem['goal_value'] = e.value
                                                                            }
                                                                            else{
                                                                                payoutItem[item.value]=e.value
                                                                            }
                                                                        }
                                                                        var tempPayoutList = offerCutback
                                                                        tempPayoutList[payoutIndex]=payoutItem;
                                                                        console.log(tempPayoutList)
                                                                        setOfferCutback(tempPayoutList);
                                                                        forceUpdate(e)
                                                                    }}
                                                                    menuPortalTarget={document.body} 
                                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }),
                                                                                control: (base,state) => ({
                                                                                    ...base,
                                                                                    // maxHeight: 100,
                                                                                    minHeight: 30,
                                                                                    transform:'scale(0.8) translateX(-11.5%)',
                                                                                    overflowY: "auto",
                                                                                    border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid rgb(200,200,200)',
                                                                                    boxShadow: 0,
                                                                                    '&:hover': {
                                                                                        border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                                                                        boxShadow: 0,
                                                                                    }
                                                                                }),
                                                                                option: (styles, {isFocused, isSelected}) => ({
                                                                                    ...styles,
                                                                                    fontSize:'11px',
                                                                                    background: isFocused
                                                                                        ? localStorage.getItem('ambientColor')
                                                                                        : isSelected
                                                                                            ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                                                                            : undefined,
                                                                                    zIndex: 1
                                                                                }),
                                                                            }}
                                                                    />
                                                            </div>
                                                        </div>
                                            }
                                            return <div key={"textgoal"+payoutIndex+"_"+index} style={{width:item.width,marginTop:'5px',marginBottom:'10px'}}>
                                                    <div  style={{paddingLeft:'2px'}}>{item.label}</div>
                                                    <div style={{transform:'scale(0.8) translateX(-11%)'}}>
                                                        <CssTextField size='small' value={payoutItem[item.value]} sx={{background:'white'}} fullWidth onChange={(e)=>{if(e.target.value.match(numbers) || e.target.value===''){setChangesDone(true);var tempPayoutDict = {...offerCutback[payoutIndex],[item.value]:e.target.value}; var tempPayoutList = offerCutback; tempPayoutList[payoutIndex] = tempPayoutDict; setOfferCutback(tempPayoutList);}forceUpdate(e)}}/>
                                                    </div>
                                                </div>
                                        })}
                                    </div>
                                </div>
                    })}
                    <div className='addButtonV2' style={{minWidth:'max-content',fontSize:'15px',display:'flex',padding:'5px',alignItems:'center'}} onClick={(e)=>{
                                                                setOfferCutback([...offerCutback,{
                                                                        "affiliate_id": null,
                                                                        "offer_id": '',
                                                                        "goal_value": "",
                                                                        "cutback_value": '',
                                                                    }]);
                                                                forceUpdate(e);
                                                                }}>
                            <div>+ Add New Cutback</div>
                        <Tooltip TransitionComponent={Zoom} title={"This will cut nth conversion of the entered number and it will refresh in 24 hours"}>
                            <InfoSharp className="payoutIconsV2" sx={{fontSize:"12px",marginLeft:'2px',color:'rgb(150,150,150)','&:hover':{color:'black'}}}/>
                        </Tooltip>  
                    </div>
                </div>
        }
    }

    var website_regex = /https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/;
    const [postbackInvalid, setPostbackInvalid] = useState(false)

    const postbacks = () => {
        var macrosDetails = {
            "{goal_value}": "Goal Value",
            "{currency}": "Currency",
            "{payout}": "Payout",
            "{ip}": "ip",
            "{offer_name}": "offer title",
            "{offer_id}": "offer id",
          }
        var options = {
            affiliate_id : affiliateList,
            payout_status : [{label:'Any',value:'any'},{label:'Approved',value:'approved'},{label:'Declined',value:'declined'}],
            goal_title : offerPayouts.map((goalItem,goalIndex)=>{
                            return {label:goalItem['title'] + "("+goalItem['goal']+")",value:goalItem['goal'].toString()}
                        }),
        }
        var payoutsHeads = [
            {label:'Affiliate',value:'affiliate_id',width:'200px'},
            {label:'URL',value:'postback_url',width:'250px'},
            {label:'Payout Status',value:'payout_status',width:'150px'},
            {label:'Goal Title',value:'goal_title',width:'150px'},
            {label:'Goal Value',value:'goal_value',width:'100px'},
        ]
        if(!requestDone){
            return <div style={{display:'flex',justifyContent:'center',alignItems:"center",height:'70vh'}}>
                <Loader
                type="Bars"
                color={localStorage.getItem('ambientColor')}
                height={50}
                width={100}
                //timeout={1000} //3 secs
                />
            </div>
        }
        else{
            return <div className='offerEditContainerV2'>
                    {offerPostbacks.map((payoutItem,payoutIndex)=>{
                        if(payoutItem['action']==='delete'){
                            return null;
                        }
                        return <div key={payoutIndex} className="payoutContainerV2">
                                    <div style={{display:'flex',justifyContent:'flex-end'}}>
                                        <Tooltip TransitionComponent={Zoom} title={<div>{Object.keys(macrosDetails).map((item,index)=>{return <div style={{marginBottom:'2px'}} key={index}>{item} - {macrosDetails[item]}</div>})}</div>}>
                                            <div style={{color:localStorage.getItem('ambientColor')}}>
                                                Show Macros
                                            </div>
                                        </Tooltip>
                                        {/* <Tooltip TransitionComponent={Zoom} title={"Duplicate Postback"}>
                                            <ContentCopyIcon sx={{fontSize:"13px",marginLeft:'10px',color:'rgb(150,150,150)','&:hover':{color:'black'}}} onClick={(e)=>{setOfferPostbacks([...offerPostbacks,{...payoutItem,copy:offerPostbacks.length}]);forceUpdate(e);}}/>
                                        </Tooltip> */}
                                        <Tooltip TransitionComponent={Zoom} title={"Delete Postback"}>
                                            <DeleteIcon className="payoutIconsV2" sx={{fontSize:"15px",marginLeft:'10px',color:'rgb(150,150,150)','&:hover':{color:'black'}}} onClick={(e)=>{var tempPayout = offerPostbacks;for(var index in offerPostbacks){if(parseInt(index)===parseInt(payoutIndex)){tempPayout[payoutIndex]['action']='delete'}};setOfferPostbacks(tempPayout);forceUpdate(e);}}/>
                                        </Tooltip>
                                    </div>
                                    <div style={{display:'flex',alignItems:'center',flexWrap:'wrap'}}>
                                        {payoutsHeads.map((item,index)=>{
                                            if(item.value==='affiliate_id' || item.value==='payout_status' || item.value==='goal_title'){
                                                var labelView = ""
                                                for(index in options[item.value]){
                                                    if(payoutItem[item.value] && payoutItem[item.value]!==null && options[item.value][index].value!==null){
                                                        if(payoutItem[item.value].toString().toLowerCase()===options[item.value][index].value.toLowerCase()){
                                                            labelView = options[item.value][index].label
                                                        }
                                                    }
                                                    if(item.value==='goal_title'){
                                                        if(payoutItem['goal_value'].toString()===options[item.value][index].value.toString()){
                                                            labelView = options[item.value][index].label
                                                        }
                                                    }
                                                }
                                                return <div key={payoutIndex+"_"+index} style={{width:item.width,marginTop:'3px',marginBottom:'10px'}}>
                                                            <div style={{paddingLeft:'3px'}}>{item.label}</div>
                                                            <div>
                                                                <Select
                                                                    options={options[item.value]}
                                                                    isMulti={item.value==='countries' ? true : false}
                                                                    value={item.value==='countries' ? payoutItem[item.value].map((countryItem,countryIndex)=>{return {label:Constants.COUNTRIES_LIST_WITH_CODE[countryItem.toUpperCase()],value:countryItem}}) : {label:labelView ? labelView : payoutItem[item.value],value:payoutItem[item.value]}}
                                                                    onChange={(e)=>{
                                                                        setChangesDone(true);
                                                                        if(item.value==='countries'){
                                                                            var tempCountries = []
                                                                            for(var index in e){
                                                                                tempCountries.push(e[index].value)
                                                                            }
                                                                            payoutItem[item.value]=tempCountries
                                                                        }
                                                                        else{
                                                                            if(item.value==='goal_title'){
                                                                                // payoutItem[item.value] = e.label
                                                                                payoutItem['goal_value'] = e.value
                                                                            }
                                                                            else{
                                                                                payoutItem[item.value]=e.value
                                                                            }
                                                                        }
                                                                        var tempPayoutList = offerPostbacks
                                                                        tempPayoutList[payoutIndex]=payoutItem
                                                                        setOfferPostbacks(tempPayoutList);
                                                                        forceUpdate(e)
                                                                    }}
                                                                    menuPortalTarget={document.body} 
                                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }),
                                                                                control: (base,state) => ({
                                                                                    ...base,
                                                                                    // maxHeight: 100,
                                                                                    minHeight: 30,
                                                                                    transform:'scale(0.8) translateX(-11.5%)',
                                                                                    overflowY: "auto",
                                                                                    border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid rgb(200,200,200)',
                                                                                    boxShadow: 0,
                                                                                    '&:hover': {
                                                                                        border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                                                                        boxShadow: 0,
                                                                                    }
                                                                                }),
                                                                                option: (styles, {isFocused, isSelected}) => ({
                                                                                    ...styles,
                                                                                    fontSize:'11px',
                                                                                    background: isFocused
                                                                                        ? localStorage.getItem('ambientColor')
                                                                                        : isSelected
                                                                                            ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                                                                            : undefined,
                                                                                    zIndex: 1
                                                                                }),
                                                                            }}
                                                                    />
                                                            </div>
                                                        </div>
                                            }
                                            return <div key={"textgoal"+payoutIndex+"_"+index} style={{width:item.width,marginTop:'5px',marginBottom:'10px'}}>
                                                    <div  style={{paddingLeft:'2px'}}>{item.label}</div>
                                                    <div style={{transform:'scale(0.8) translateX(-11%)'}}>
                                                        <CssTextField size='small' value={payoutItem[item.value]} sx={{background:'white'}} fullWidth onChange={(e)=>{if((item.value==='postback_url') && e.target.value!=="" && !e.target.value.toString().match(website_regex)){setPostbackInvalid(true)}else{setPostbackInvalid(false);};setChangesDone(true);var tempPayoutDict = {...offerPostbacks[payoutIndex],[item.value]:e.target.value}; var tempPayoutList = offerPostbacks; tempPayoutList[payoutIndex] = tempPayoutDict; setOfferPostbacks(tempPayoutList);forceUpdate(e)}}/>
                                                    </div>
                                                    {(item.value==='postback_url') && payoutItem[item.value]!=="" && !payoutItem[item.value].toString().match(website_regex) && <div style={{fontSize:'10px',color:'red',position:'absolute',width:item.width}}>Invalid {item.value}</div>}
                                                </div>
                                        })}
                                    </div>
                                </div>
                    })}
                    <div className='addButtonV2' onClick={(e)=>{
                                                                setOfferPostbacks([...offerPostbacks,{
                                                                    "action":'add',
                                                                    "id": "",
                                                                    "postback_url": "",
                                                                    "offer_id": "",
                                                                    "goal_value": "",
                                                                    "payout_status": "",
                                                                    "affiliate_id": ""
                                                                }]);
                                                                forceUpdate(e);
                                                                }}>
                        +
                    </div>
                </div>
        }
    }

    const creatives = () => {
        
        if(!requestDone){
            return <div style={{display:'flex',justifyContent:'center',alignItems:"center",height:'70vh'}}>
                <Loader
                type="Bars"
                color={localStorage.getItem('ambientColor')}
                height={50}
                width={100}
                //timeout={1000} //3 secs
                />
            </div>
        }
        else{
            return <div className='offerEditContainerV2'>
                    <div className="payoutContainerV2">
                        <div style={{fontSize:'15px',fontWeight:'500',marginBottom:'20px',marginLeft:'1%'}}>Creative Links</div>
                        {offerCreatives.map((payoutItem,payoutIndex)=>{
                            return <div>
                                        <div style={{display:'flex',alignItems:'center'}}>
                                            <CssTextField value={payoutItem} sx={{background:'white',transform:'scale(0.8) translateX(-11%)'}} onChange={(e)=>{setChangesDone(true);var tempOfferCreatives = offerCreatives; tempOfferCreatives[payoutIndex]=e.target.value; setOfferCreatives(tempOfferCreatives); forceUpdate(e);}} fullWidth size='small'/>
                                            <Tooltip TransitionComponent={Zoom} title={"Delete link"}>
                                                <DeleteIcon className="payoutIconsV2" sx={{fontSize:"15px",marginLeft:'10px',color:'rgb(150,150,150)',transform:'scale(1.4) translateX(-50%)','&:hover':{color:'black'}}} onClick={(e)=>{var tempPayout = [];for(var index in offerCreatives){if(parseInt(index)!==parseInt(payoutIndex)){tempPayout.push(offerCreatives[index])}};setOfferCreatives(tempPayout);forceUpdate(e);}}/>
                                            </Tooltip>
                                        </div>
                                        {(payoutItem==="" || !payoutItem.toString().match(website_regex)) && <div style={{fontSize:'10px',color:'red',marginLeft:'1%'}}>Invalid Creative link</div>}
                                    </div>
                        })}
                        <div className='addButtonV2' style={{width:'max-content',padding:'5px',fontSize:'12px'}} onClick={(e)=>{
                                                                    setOfferCreatives([...offerCreatives,""]);
                                                                    forceUpdate(e);
                                                                    }}>
                            + Add New Url
                        </div>
                    </div>
                </div>
        }
    }

    const updateCapsConversionStatusApi = async(offer_id) => {
        var statusList = []
        for(var index in conversionCapsStatus){
            statusList.push(conversionCapsStatus[index].value)
        }
        await axios({
            method: 'POST',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"offer/caps/allowedstatus/",offer_id,"/change"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
                "Content-type":'application/json'
            },
            data:{
                'allowed_status':statusList
            }
        })
        .then(resp => {
            return true;
        })
        .catch(err => {
            setSaveButtonDisabled(false);
            console.error(err);
            if(err.response && err.response.data.result){
                Store.addNotification({
                    title: "Error!",
                    message: err.response.data.result,
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 2000,
                      onScreen: true
                    }
                  });
            }
        }) 
    }
    const offerAddEditRequest = async() => {
        var dataValidationCheck = true;
        var dataValidationError = "";
        setChangesDone(false);
        var caps = []
        console.log('eadjiajsodajdsoi')
        if(offerCaps.length>0){
            if(offerCaps.length===1){
                if(offerCaps[0].value.toString()==="0" && offerCaps[0].goal.length===0){
                    caps = []
                }
                else if(offerCaps[0].value.toString()==="0" && offerCaps[0].goal.length>0){
                    dataValidationCheck = false;
                    dataValidationError = "Please update goal value in caps, since you have provided goal title.";
                }
                else{
                    caps.push(offerCaps[0]);
                    if(typeof offerCaps[0].goal === 'string'){
                        caps[0].goal = [offerCaps[0].goal]
                    }
                    caps[0].value = parseInt(offerCaps[0].value)
                }
            }
            else{
                for(var capsIndex in offerCaps){
                    caps.push(offerCaps[capsIndex]);
                    if(offerCaps[capsIndex].value.toString()==="0" && offerCaps[capsIndex].goal.length>0){
                        dataValidationCheck = false;
                        dataValidationError = "Please update goal value in caps, since you have provided goal title.";
                    }
                    else{
                        if(typeof offerCaps[capsIndex].goal === 'string'){
                            caps[capsIndex].goal = [offerCaps[capsIndex].goal]
                        }
                        caps[capsIndex].value = parseInt(offerCaps[capsIndex].value)
                    }
                }
            }
        }

        var payouts = []
        if(offerPayouts.length>0){
            for(var payoutIndex in offerPayouts){
                payouts.push(offerPayouts[payoutIndex]);
                if(offerPayouts[payoutIndex].revenue===""){
                    payouts[payoutIndex].revenue = 0;
                }
                else{
                    payouts[payoutIndex].revenue = parseFloat(payouts[payoutIndex].revenue)
                }
                if(offerPayouts[payoutIndex].payout===""){
                    payouts[payoutIndex].payout = 0;
                }
                else{
                    payouts[payoutIndex].payout = parseFloat(payouts[payoutIndex].payout)
                }

                if(offerPayouts[payoutIndex].goal===""){
                    payouts[payoutIndex].goal = "1";
                }
                if(parseFloat(offerPayouts[payoutIndex].revenue) < parseFloat(offerPayouts[payoutIndex].payout)){
                    dataValidationCheck = false;
                    dataValidationError = "Revenue is less than payout."
                }
                if(offerPayouts[payoutIndex].affiliate_id===""){
                    payouts[payoutIndex].goal = "1";
                }
            }
        }

        var cutbacks = []
        if(offerCutback.length>0){
            for(var cutbackIndex in offerCutback){
                if(offerCutback[cutbackIndex].cutback_value){
                    cutbacks.push({
                        "affiliate_id": parseInt(offerCutback[cutbackIndex].affiliate_id),
                        "offer_id": offerID,
                        "goal_value": offerCutback[cutbackIndex].goal_value,
                        "cutback_value": parseInt(offerCutback[cutbackIndex].cutback_value)
                    })
                }
            }
        }

        if(offerImpressionLink!=="" && !offerImpressionLink.match(website_regex)){
            dataValidationCheck = false;
            dataValidationError = "Please check impression link format."
        }
        if(offerPreviewUrl!=="" && !offerPreviewUrl.match(website_regex)){
            dataValidationCheck = false;
            dataValidationError = "Please check preview link format."
        }
        if(offerTrackingUrl==="" || !offerTrackingUrl.match(website_regex)){
            dataValidationCheck = false;
            dataValidationError = "Please check tracking link format."
        }
        if(!offerAdvertiser){
            dataValidationCheck = false;
            dataValidationError = "Please add an advertiser to offer"
        }
        if(offerTitle===""){
            dataValidationCheck = false;
            dataValidationError = "Please add a title to offer"
        }

        if(!dataValidationCheck){
            Store.addNotification({
                title: "Warning!",
                message: dataValidationError,
                type: "warning",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 2000,
                  onScreen: true
                },
                onRemoval: (id, removedBy) => {
                    setSaveButtonDisabled(false);
                }
            });
            
        }
        else{
            
            var title = offerTitle;
            var external_offer_id = offerExternalOfferId ? offerExternalOfferId : "";
            var offer_status = offerStatus.value;
            var offer_type = offerType.value;
            var advertiser = null
            console.log('ereaa',offerAdvertiser)
            if(offerAdvertiser){
                advertiser = ''
                if(offerAdvertiser.value.includes('(') && offerAdvertiser.value.includes(')')){
                    advertiser+='('+offerAdvertiser.value.split(')')[0].split('(')[1]+')'
                    advertiser+=offerAdvertiser.value.split(')')[1]
                }
                else{
                    advertiser = "("+offerAdvertiser.value+") "+offerAdvertiser.label
                }
            }
            
            var categories = []
            categories = offerCategories.map((item,index)=>{return item.value});
            var kpi = offerKpi ? draftToHtml(convertToRaw(offerKpi.getCurrentContent())) : ""
            var description = offerDescription ? draftToHtml(convertToRaw(offerDescription.getCurrentContent())) : ""
            var tracking_url = offerTrackingUrl
            var preview_url = offerPreviewUrl
            var impression_link = offerImpressionLink
            var targeting = offerTargetingCountries
            var creatives = offerCreatives
            var sub_id = subIdSelected
            var offer_repeat_goal = offerRepeatPostback
            var gaid = []
            gaid = gaidSelected.map((item,index)=>{return item.value})
            var logo = ""
            var stop_at = offerStopAt && offerStopAt!=="None" ? offerStopAt : ""


            var url = "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.GETOFFERS,"/add")
            if(isEdit){
                url = "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.GETOFFERS,"/",offerID,"/change")
            }
            await axios({
                method: 'POST',
                url,
                headers:{
                    Authorization:getLocalStorageValue('adminPanelLoginData').token,
                    "Content-Type":"application/json",
                  },
                data:{
                    advertiser,
                    package_name:offerPackageName ? offerPackageName : '',
                    caps,
                    categories,
                    creatives,
                    description,
                    external_offer_id,
                    // "id": 14,
                    impression_link,
                    kpi,
                    logo,
                    offer_status,
                    offer_type,
                    payouts,
                    preview_url,
                    stop_at,
                    targeting,
                    title,
                    tracking_url,
                    gaid,
                    offer_repeat_goal,
                    sub_id,
                    cutback : cutbacks,
                    overcaps:overcapsAllowed,
                    overcaps_goal:overcapsGoal ? overcapsGoal.value : "",
                    offer_expiry: (offerSource==='manual' || !isEdit) ? new Date(dateRangeState[0].startDate.getTime()+19800000).toISOString().split('T')[0] : undefined,
                }
            })
            .then(async(resp) => {
                setChangesDone(false);
                var offerid = offerID;
                if(offerid){
                    await updateCapsConversionStatusApi(offerid)
                }
                if((resp.data.result==="Offer Updated." || resp.data.result==="Offer added.")){
                    if(offerPostbacks.length>0 && isEdit){
                        updatePostbacks();
                    }
                    else{
                        Store.addNotification({
                            title: "Success!",
                            message: resp.data.result,
                            type: "success",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 1000,
                                onScreen: true
                            },
                            onRemoval: (id, removedBy) => {
                                if(isEdit){
                                    window.location.href = "/offers/view/"+offerID
                                }
                                else{
                                    window.location.href = "/offers"
                                }
                            }
                            });
                        }     
                }
                else{
                    setSaveButtonDisabled(false);
                    Store.addNotification({
                        title: "Error!",
                        message: typeof resp.data.result === "string" ? resp.data.result : "Some error occured",
                        type: "warning",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3000,
                            onScreen: true
                        }
                    }); 
                }
          })
          .catch(function (error) {
                setChangesDone(false);
              if (error.response) {
                // Request made and server responded
                Store.addNotification({
                    title: "Error!",
                    message: typeof error.response.data.result === "string" ? error.response.data.result : "Some error occured",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true
                    }
                }); 
                console.log(error.response.status);
                console.log(error.response.headers);
              } else {
                // Something happened in setting up the request that triggered an Error
                Store.addNotification({
                    title: "Error!",
                    message: typeof error.message === "string" ? error.message : "Some error occured",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true
                    }
                }); 
                console.log('Error', error.message);
              }
          
            });
        }
        setSaveButtonDisabled(false);
    }

    const updatePostbacks = async(offer_id) => {
        var dataValidationCheck = true;
        var dataValidationError = "";
        var postbacks = []
        if(offerPostbacks.length>0){
            for(var postbackIndex in offerPostbacks){
                if(offerPostbacks[postbackIndex].id==="" && 
                    offerPostbacks[postbackIndex].offer_id==="" && 
                    offerPostbacks[postbackIndex].payout_status==="" && 
                    offerPostbacks[postbackIndex].postback_url==="" && 
                    offerPostbacks[postbackIndex].goal_value==="" && 
                    (offerPostbacks[postbackIndex].affiliate_id==="" || offerPostbacks[postbackIndex].affiliate_id===null)){
                }
                else if((offerPostbacks[postbackIndex].postback_url.toString()!=="" && offerPostbacks[postbackIndex].goal_value.toString()==="" && !offerPostbacks[postbackIndex].affiliate_id )
                    ||
                    (offerPostbacks[postbackIndex].postback_url.toString()==="" && offerPostbacks[postbackIndex].goal_value.toString()==="" && offerPostbacks[postbackIndex].affiliate_id )
                    ||
                    (offerPostbacks[postbackIndex].postback_url.toString()==="" && offerPostbacks[postbackIndex].goal_value.toString()!=="" && !offerPostbacks[postbackIndex].affiliate_id )
                    ){
                    var indexPostback = parseInt(postbackIndex) + 1
                    dataValidationCheck = false;
                    dataValidationError = "Please check postback, index -> "+indexPostback+".";
                }
                else{
                    postbacks.push(offerPostbacks[postbackIndex])
                    postbacks[postbackIndex].offer_id = offerID.toString()
                }
            }     
        }

        if(!dataValidationCheck){
            Store.addNotification({
                title: "Warning!",
                message: dataValidationError,
                type: "warning",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 2000,
                  onScreen: true
                },
                onRemoval:(id,removedBy) => {
                    setSaveButtonDisabled(false);
                }
              });
        }
        else{
            axios({
                method: 'POST',
                url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"eventpostbacks/update"),
                headers:{
                    Authorization:getLocalStorageValue('adminPanelLoginData').token,
                    "Content-type":'application/json'
                },
                data:postbacks
            })
            .then(resp => {
                Store.addNotification({
                    title: "Success!",
                    message: "Offer Edited Successfully!",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 2000,
                      onScreen: true
                    },
                    onRemoval: (id, removedBy) => {
                        window.location.href = "/offers/view/"+offerID
                    }
                  });
            })
            .catch(err => {
                setSaveButtonDisabled(false);
                console.error(err);
                if(err.response && err.response.data.result){
                    Store.addNotification({
                        title: "Error!",
                        message: err.response.data.result,
                        type: "warning",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                          duration: 2000,
                          onScreen: true
                        }
                      });
                }
            }) 
        }
    }
    return (
        <>
            <NavigationPrompt 
                    when={changesDone} >
                    {({ onConfirm, onCancel }) => (
                        <div style={{position:'fixed',padding:'0%',zIndex:10000,width:'100vw',height:'100vh',top:0,left:0,background:'rgb(150,150,150,0.2)',backdropFilter:'blur(2px)',alignItems:'center',justifyContent:'center',display:'flex'}}>
                            <div className='stopRedirectPopup'>
                                <div className='stopRedirectPopupHeader'>
                                    <div style={{fontSize:'13px',fontWeight:'500',paddingLeft:'5px'}}>Unsave changes</div>
                                    <div style={{marginRight:'10px',cursor:'pointer'}} onClick={onCancel}>
                                        X
                                    </div>
                                </div>
                                <div className='stopRedirectPopupBody'>
                                    <div style={{fontSize:'12px',paddingLeft:'5px'}}>
                                        You have unsaved changes. Are you sure you want to continue?
                                    </div>
                                    <div style={{display:'flex',marginTop:'10%',justifyContent:'center',marginBottom:'15px'}}>
                                        <Button sx={{...Constants.CONTAINEDBUTTON,height:"30px",fontWeight:'900',fontSize:10,marginRight:'20px'}} onClick={onConfirm}>Yes</Button>
                                        <Button sx={{...Constants.OUTLINEDBUTTON,height:"30px",fontWeight:'900',fontSize:10,marginRight:'20px'}} onClick={onCancel}>No</Button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    )} 
            </NavigationPrompt>
            <div className='mainContainerV2' style={{padding:'2%',paddingTop:'0%',paddingBottom:'0%'}} id="outside">
                <div style={{position:'sticky',alignItems:'center',top:top,display:'flex',background:'white',fontSize:'13px',zIndex:100,height:'50px'}}>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs 
                                    TabIndicatorProps={{style: {background:localStorage.getItem('ambientColor'),backgroundColor:localStorage.getItem('ambientColor')}}}
                                    value={tabValue} 
                                    onChange={handleTabValue} 
                                    aria-label="basic tabs example">
                                <Tab label={<span className={tabValue===0? 'OfferTabActive' : 'OfferTabInactive'} style={(offerTitle==="" || offerAdvertiser==="") ? {color:'red'} : {}}>General</span>}/>
                                <Tab label={<span className={tabValue===1? 'OfferTabActive' : 'OfferTabInactive'} style={offerTrackingUrl==="" ? {color:'red'} : {}}>Tracking</span>}/>
                                <Tab label={<span className={tabValue===2? 'OfferTabActive' : 'OfferTabInactive'}>Targeting Group</span>}/>
                                <Tab label={<span className={tabValue===3? 'OfferTabActive' : 'OfferTabInactive'} style={payoutInvalid ? {color:'red'} : {}}>Payouts</span>}/>
                                <Tab label={<span className={tabValue===4? 'OfferTabActive' : 'OfferTabInactive'} style={capsInvalid ? {color:'red'} : {}}>Caps</span>}/>
                                <Tab label={<span className={tabValue===5? 'OfferTabActive' : 'OfferTabInactive'}>Creatives</span>}/>
                                {isEdit && <Tab label={<span className={tabValue===6? 'OfferTabActive' : 'OfferTabInactive'}>Cutback</span>}/>}
                                {isEdit && <Tab label={<span className={tabValue===7? 'OfferTabActive' : 'OfferTabInactive'} style={postbackInvalid ? {color:'red'} : {}}>Postbacks</span>}/>}
                            </Tabs>
                        </Box>
                    </Box>
                </div>
                <div style={{minHeight:'calc(72vh)'}}>
                    <TabPanel value={tabValue} index={0}>
                        {general()}
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        {tracking_link()}
                    </TabPanel>
                    <TabPanel value={tabValue} index={2}>
                        {targetting()}
                    </TabPanel>
                    <TabPanel value={tabValue} index={3}>
                        {payouts()}
                    </TabPanel>
                    <TabPanel value={tabValue} index={4}>
                        {caps()}
                    </TabPanel>    
                    <TabPanel value={tabValue} index={5}>
                        {creatives()}
                    </TabPanel>   

                    <TabPanel value={tabValue} index={6}>
                        {cutbacks()}
                    </TabPanel>   
                    {isEdit && <TabPanel value={tabValue} index={7}>
                        {postbacks()}
                    </TabPanel>}    
                </div>
                <div style={{position:'sticky',alignItems:'center',height:'50px',bottom:20,display:'flex',background:'white',fontSize:'13px',borderTop:'1px solid rgb(200,200,200)',zIndex:100,marginTop:'10px'}}>
                    {isEdit && <NavLink to={'/offers/view/'+offerID} style={{textDecoration:'none'}}><Button sx={{...Constants.OUTLINEDBUTTON,height:"30px",width:'max-content',fontWeight:'600',fontSize:10,marginRight:'20px'}}>View</Button></NavLink>}
                    <Button disabled={offerAdvertiser==="" || offerTitle==="" || offerTrackingUrl==="" || saveButtonDisabled} sx={{...Constants.CONTAINEDBUTTON,height:"30px",width:'60px',fontWeight:'600',fontSize:10,marginRight:'20px'}} onClick={(e)=>{setChangesDone(false);setSaveButtonDisabled(true);forceUpdate(e);offerAddEditRequest();}}>
                        {saveButtonDisabled && <CircularProgress size={'18px'} sx={{color:'white'}}/>}
                        {!saveButtonDisabled && 'Save'}
                    </Button>
                    <NavLink to={'/offers'} style={{textDecoration:'none'}}><Button sx={{color:localStorage.getItem('ambientColor'),'&:hover':{background:'transparent'},height:"30px",width:'max-content',fontWeight:'600',fontSize:10,marginRight:'20px'}}>Cancel</Button></NavLink>
                </div>
            </div>
        </>
    );
};
 
export default NewOfferPageV2;