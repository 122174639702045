import React, { useState, useEffect, useRef } from "react";
import CustomSelect from "../../insightsManik/components/CustomSelect";
import { components } from "react-select";
import axios from "axios";
import PercentageDiv from "../../insightsManik/components/Percentagediv";
import "../../../billing/insightsManik/insightsCss.css";
import ReactPaginate from "react-paginate";
import leftArrow from "../../../billing/insightsManik/assets/leftArrow.svg";
import rightArrow from "../../../billing/insightsManik/assets/RightArrow.svg";
import whiteArrow from "../../../billing/insightsManik/assets/whiteArrow.svg";
import LoadingSpinner from "../../insightsManik/components/Loaderspinner";
import { getLocalStorageValue } from "../../insightsManik/util/util";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import * as Constants from '../../../../data/constants';

function CustomTable({
  selectedRev,
  selectedColumns,
  group,
  sortOrder,
  date,
  Initdate,
  Finaldate,
  advertiser,
  packageName,
  sortMonth,
  btnClick,
  setBtnClick,
  advData,
  className,
  advertiserString,
  packageString,
  managerString,
  name,
  isOffer,
  setAdvapp,
  setAdvappmonth,
  initial,
  setInitial,
  monthly,
  setMonthly,
  appPackageMap,
  advappmonth,
  summaryOffer

}) {
  const [sortTableHead, setSortTableHead] = useState("");
  const [sortHeadOrder, setSortHeadOrder] = useState(false);
  const [sorHead, setSorHead] = useState("");
  const [loading, setLoading] = useState(true);
  const [advAppItem,setAdvAppItem]=useState("");
  var columnName;

  const swappedMap = new Map();
  for (let i = 0; i < advData.length; i++) {
    if (advData[i]) {
        swappedMap.set(advData[i].value, advData[i].label.split(' - ')[0]);
    }
  }

  const combinedClassName = className ? className : "main_table1";
  var obj = {
    Jan: "01",
    Feb: "02",
    Mar: "03",
    Apr: "04",
    May: "05",
    Jun: "06",
    Jul: "07",
    Aug: "08",
    Sep: "09",
    Oct: "10",
    Nov: "11",
    Dec: "12",
  };
  var sortKey = "total_revenue";
  var sort = false;
  var initdate;
  var finaldate;
  if (date) {
    initdate = "20"+date.substring(date.indexOf(" ")+1)+'-'+obj[date.substring(0,date.indexOf(" "))];
    finaldate = "20"+date.substring(date.indexOf(" ")+1)+'-'+obj[date.substring(0,date.indexOf(" "))];
    sort = false;
    sortKey = "month";
  } 
  else if (Initdate) {
    initdate = Initdate;
    finaldate = Finaldate;
    sort = false;
    sortKey = "month";
  } else {
    initdate = "2023-01";
    finaldate = "2023-10";
  }

  const [itemsOnPage, setItemsOnPage] = useState(group=="month"? 12:10);
  const sortingOptions = [
    { value: "ascending", label: "Sort in Ascending order" },
    { value: "descending", label: "Sort in Descending order" },
  ];


  if(name=="yearly" && !initial && !monthly){   
  group="month"
  sortKey="month";
  // sort=true;
  }
  if(name=="topAdvertiser" && !initial){
    sortKey=selectedRev==="Verified Revenue" ? "conversions_verified":"total_revenue";
    if(packageName==="")
    group="Advertisers";
  else
  group="package_name";
  }
  
  var groupBy;
  if (group === "Advertisers") {
    groupBy = "advertiser_id";
    columnName=initial?"Advertisers":"App Name";
  } else if (group === "month") {
    groupBy = "month";
    columnName="Months";
  } else {
    groupBy = "package_name";
    columnName=initial?"App Name":"Advertisers";
  }
  if(name=="topAdvertiser" && summaryOffer){
    groupBy="offers";
    columnName="Offer Name";
    selectedColumns[1]=false;
    selectedColumns[3]=true;
  }
  var adv_app = "";
  if (advertiser !="") {
      adv_app = advertiser;
    } else {
      adv_app = packageName;
    }

  if (sortOrder === 0) {
    sortKey = "manager_approved_revenue";
  } else if (sortOrder === 1) {
    sortKey = "deduction_revenue";
    if (selectedRev === "Verified Revenue") sortKey = "deduction_revenue_manual";
  } else if (sortOrder === 2) {
    sortKey = "pending_revenue";
    if (selectedRev === "Verified Revenue") sortKey = "pending_revenue_manual";
  }
  else if (sortOrder === 3) {
    sortKey = "payment_received";
  }
  else if (sortOrder === 4) {
    sortKey = "payment_received_pending";
  }
  if (sortMonth !== "no-sort") {
    if (sortMonth === "Verified Revenue") {
      if (selectedRev === "Panel Revenue") {
        sortKey = "total_revenue";
      } else {
        sortKey = "conversions_verified";
      }
      sort = false;
    } else if (sortMonth === "Approved Revenue") {
      sortKey = "manager_approved_revenue";
      sort = false;
    } else if (sortMonth === "Pending Validation") {
      if (selectedRev === "Panel Revenue") {
        sortKey = "pending_revenue";
      } else {
        sortKey = "pending_revenue_manual";
      }
      sort = false;
    } else if (sortMonth === "Deduction") {
      if (selectedRev === "Panel Revenue") {
        sortKey = "deduction_revenue";
      } else {
        sortKey = "deduction_revenue_manual";
      }
      sort = false;
    } else if (sortMonth === "Payment Received") {
        sortKey = "payment_received";
        sort = false;
    }else if (sortMonth === "Payment Pending") {
      sortKey = "payment_received_pending";
      sort = false;
  }
  else if (sortMonth === "Payment Received %") {
    sortKey = "payment_received_pending_percent";
  sort = true;
} else if (sortMonth === "Validated Percentage") {
  if (selectedRev === "Panel Revenue") {
    sortKey = "validation_percentage";
  } else {
    sortKey = "validation_percentage_manual";
  }
  sort = false;
}

  }
  if (sortTableHead != "") {
    if (selectedRev === "Panel Revenue") {
      let obj2 = {
        "Panel Revenue": "total_revenue",
        "Approved Revenue": "manager_approved_revenue",
        "Validated Percentage": "validation_percentage",
        "Deduction": "deduction_revenue",
        "Pending Validation": "pending_revenue",
        "Offers Validated": "validated",
        "Difference in Revenue": "diff_manual_panel",
        "Panel Rev. Accuracy": "diff_manual_panel_percent",
        "Delivered Revenue": "delivered_revenue",
        "Payment Received":"payment_received",
        "Payment Pending":"payment_received_pending",
        "Payment Received %":"payment_received_pending_percent"
      };
      sortKey = obj2[sortTableHead];
      sort = sortKey==="payment_received_pending_percent"?!sortHeadOrder:sortHeadOrder;
    } else {
      let obj2 = {
        "Verified Revenue": "conversions_verified",
        "Approved Revenue": "manager_approved_revenue",
        "Validated Percentage": "validation_percentage_manual",
        "Deduction": "deduction_revenue_manual",
        "Pending Validation": "pending_revenue_manual",
        "Offers Validated": "validated",
        "Difference in Revenue": "diff_manual_panel",
        "Panel Rev. Accuracy": "diff_manual_panel_percent",
        "Delivered Revenue": "delivered_revenue",
        "Payment Received":"payment_received",
        "Payment Pending":"payment_received_pending",
        "Payment Received %":"payment_received_pending_percent"
      };
      sortKey = obj2[sortTableHead];
      sort = sortKey==="payment_received_pending_percent"?!sortHeadOrder:sortHeadOrder;
    }
  }
  var columnHeadings;
  if (selectedRev === "Verified Revenue")
    columnHeadings = [
      "S.no.",
      "Advertisers",
      "Approved Revenue",
      "Payment Received",
      "Payment Received %",
      "Payment Pending"
    ];
 
  else {
    columnHeadings = [
      "S.no.",
      "Advertiser",
      "Approved Revenue",
      "Payment Received",
      "Payment Received %",
      "Payment Pending"
    ];
  }
   columnHeadings[1] = columnName;

   if(name=="topAdvertiser" && summaryOffer){
    columnHeadings.push("Validation Status");
  }
  

  var sortColourclass = {
    total_revenue: "Panel Revenue",
    conversions_verified: "Verified Revenue",
    validation_percentage: "Validated Percentage",
    validation_percentage_manual: "Validated Percentage",
    pending_revenue: "Pending Validation",
    pending_revenue_manual: "Pending Validation",
    deduction_revenue: "Deduction",
    deduction_revenue_manual: "Deduction",
    manager_approved_revenue: "Approved Revenue",
    diff_manual_panel_percent: "Panel Rev. Accuracy",
    diff_manual_panel: "Difference in Revenue",
    validated: "Offers Validated",
    delivered_revenue: "Delivered Revenue",
    payment_received:"Payment Received",
    payment_received_pending:"Payment Pending",
    payment_received_pending_percent:"Payment Received %"
  };

  const API_ENDPOINT =!isOffer?
  "".concat(Constants.PROTOCOL,Constants.HOST,"/api/admin/v2/invoice/summary"):
  "".concat(Constants.PROTOCOL,Constants.HOST,"/api/admin/v1/invoice/adv-data")
  const params = {
    fromMonth: initdate,
    toMonth: finaldate,
    total_row_required: true,
    group_by: groupBy,
    sorting_key: sortKey,
    sorting_order: sort,
  };
  if (advertiser != "" && packageName != "") {
    params.advertiser_id = advertiserString;
    params.package_name = packageString;
  } else if (advertiser != "") {
    params.advertiser_id = advertiserString;
  } else {
    params.package_name = packageString;
  }
  if(name==="topAdvertiser"){
    if(isOffer){
      params.offer_id=adv_app;
      if(summaryOffer){
      params.adv_id=adv_app;
      params.offer_id=null;
      }
    }
    else{
    if (typeof adv_app === "number") {
      params.advertiser_id = adv_app;
    } else if (typeof adv_app === "string" && adv_app != "") {
      params.package_name = adv_app;
    }
  }

  if ( packageString != "") {
    params.package_name = packageString;
  }
  if ( advertiserString != "") {
    params.adv_id = advertiserString;
  }

   
  }
  if(managerString!="")
  {
     params.manager_id = managerString;
  }
  const token = getLocalStorageValue('adminPanelLoginData').token;
  const [tableData, setTableData] = useState({
    panel_revenue: [],
    manual_revenue: [],
    panel_revenue_total_row: [],
    manual_revenue_total_row: [],
  });

  const handleClick=(item,heading,monthly_temp)=>{
    setCurrentPage(0);
    setInitial(false);
    setLoading(true);
    if(group=="month"){
      setItemsOnPage(12);
      setSortTableHead("");
    }
    else{
      setItemsOnPage(10);
    }
 
    if(heading)
    setSortTableHead(heading);
  
    if(name=="yearly"){
      if(!monthly){
      setAdvAppItem(item);
      }
    }
    else
    setAdvAppItem(item);
    monthly_temp = monthly_temp ? monthly_temp : monthly
    const DOMAIN="".concat(Constants.PROTOCOL,Constants.HOST);
    var API_ENDPOINT=DOMAIN.concat("/api/admin/v1/invoice/summary/package-name-data");
    if(typeof item!="number")
    API_ENDPOINT=DOMAIN.concat("/api/admin/v1/invoice/summary/adv-data");
    if(name=="yearly" && !monthly_temp){
      API_ENDPOINT=DOMAIN.concat("/api/admin/v2/invoice/summary");
    }
    if(name=="yearly" && monthly_temp && typeof advAppItem==="number"){
      API_ENDPOINT=DOMAIN.concat("/api/admin/v1/invoice/summary/package-name-data");
    }
    if(name=="yearly" && monthly_temp && typeof advAppItem!=="number"){

      API_ENDPOINT=DOMAIN.concat("/api/admin/v1/invoice/summary/adv-data");
    }
    if(name=="topAdvertiser" && packageName===""){
    API_ENDPOINT=DOMAIN.concat("/api/admin/v1/invoice/summary/package-name-data");
    }
    if(name=="topAdvertiser" && typeof adv_app!=="number"){
      API_ENDPOINT=DOMAIN.concat("/api/admin/v1/invoice/summary/adv-data");
    }
    if(name==="topAdvertiser" && isOffer){
      API_ENDPOINT=DOMAIN.concat("/api/admin/v1/invoice/adv-data");
    }
    var params={
    fromMonth: initdate,
    toMonth: finaldate,
    total_row_required: true,
    group_by: groupBy,
    sorting_key: sortKey,
    sorting_order: sort,
    get_app_name:true
    };
   if ( advertiser != "" && packageName != "") {
    params.adv_id = advertiserString;
    params.package_name = packageString;
  } else if (advertiser != "") {
    params.adv_id = advertiserString;
  } else {
    params.package_name = packageString;
  }
    if(typeof item==="number")
    params.adv_id=item;
  else
  params.package_name=item;
  if(name=="yearly" && !monthly_temp){
    params.group_by="month";
    // params.sorting_key="month";
    if(typeof item==="number")
    params.advertiser_id=item;
    else
    params.package_name=item;
  }
  if(name=="topAdvertiser"){
    params.fromMonth="20"+item.substring(item.indexOf(" ")+1)+'-'+obj[item.substring(0,item.indexOf(" "))];
    params.toMonth="20"+item.substring(item.indexOf(" ")+1)+'-'+obj[item.substring(0,item.indexOf(" "))];
    if(typeof adv_app==="number"){
    params.adv_id=adv_app;
    params.package_name=null;
    params.group_by="package_name";
    if(isOffer){
      params.offer_id=adv_app;
      params.adv_id=null;
    }
    }
  else{
  params.package_name=adv_app;
  params.group_by="advertiser_id";
  }
  }
  if(name=="yearly" && monthly_temp){
    params.fromMonth="20"+advappmonth.substring(advappmonth.indexOf(" ")+1)+'-'+obj[advappmonth.substring(0,advappmonth.indexOf(" "))];
    params.toMonth="20"+advappmonth.substring(advappmonth.indexOf(" ")+1)+'-'+obj[advappmonth.substring(0,advappmonth.indexOf(" "))];
    if(typeof advAppItem==="number"){
    params.adv_id=advAppItem;
    }
    else{
      params.package_name=advAppItem;
    }
  }
  if(name=="topAdvertiser"){
    params.fromMonth="20"+item.substring(item.indexOf(" ")+1)+'-'+obj[item.substring(0,item.indexOf(" "))];
    params.toMonth="20"+item.substring(item.indexOf(" ")+1)+'-'+obj[item.substring(0,item.indexOf(" "))];
    if ( packageString != "") {
      params.package_name = packageString;
    }
    if ( advertiserString != "") {
      params.adv_id = advertiserString;
    }
  }  
    axios
      .get(API_ENDPOINT, {
        params: params,
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((response) => {
        const data = response.data.result;
        const tableData = {
          panel_revenue: [],
          manual_revenue: [],
          panel_revenue_total_row: [],
          manual_revenue_total_row: [],
        };
        if (groupBy === "advertiser_id") {
          cnt=1;
          for (let i = 0; i < data.length; i++) {
            let x;
            if(data[i].app_name==="")
              x=data[i].package_name;
            else
              x=data[i].app_name;
            tableData.panel_revenue.push([
              cnt,
              x,
              data[i].manager_approved_revenue,
              data[i].payment_received,
              data[i].payment_received==0?0:(data[i].payment_received/data[i].manager_approved_revenue)*100,
              data[i].payment_received_pending
            ]);
            tableData.manual_revenue.push([
              cnt,
              x,
              data[i].manager_approved_revenue,
              data[i].payment_received,
              data[i].payment_received==0?0:(data[i].payment_received/data[i].manager_approved_revenue)*100,
              data[i].payment_received_pending
            ]);
            cnt++;
          }
        } else if (groupBy === "month") {
          cnt=1;
          for (let i = sortKey=="month"? data.length-1:0; sortKey=="month"? i>=0:i<data.length;sortKey=="month"?i-- : i++) {
            tableData.panel_revenue.push([
              cnt,
              new Date(data[i].month).toLocaleDateString("en-US", {
                month: "short",year:"2-digit"
              }),
              data[i].manager_approved_revenue,
              data[i].payment_received,
              data[i].payment_received==0?0:(data[i].payment_received/data[i].manager_approved_revenue)*100,
              data[i].payment_received_pending
            ]);
            tableData.manual_revenue.push([
              cnt,
              new Date(data[i].month).toLocaleDateString("en-US", {
                month: "short",year:"2-digit"
              }),
              data[i].manager_approved_revenue,
              data[i].payment_received,
              data[i].payment_received==0?0:(data[i].payment_received/data[i].manager_approved_revenue)*100,
              data[i].payment_received_pending
            ]);
            cnt++;
          }
        } else {
          cnt=1;
          for (let i = 0; i < data.length; i++) {
          
            let x;
            if(data[i].app_name==="")
              x=data[i].package_name;
            else
              x=data[i].app_name;
            tableData.panel_revenue.push([
              cnt,
             data[i].advertiser_id,
             data[i].manager_approved_revenue,
             data[i].payment_received,
             data[i].payment_received==0?0:(data[i].payment_received/data[i].manager_approved_revenue)*100,
             data[i].payment_received_pending
            ]);
            tableData.manual_revenue.push([
              cnt,
             data[i].advertiser_id,
             data[i].manager_approved_revenue,
             data[i].payment_received,
             data[i].payment_received==0?0:(data[i].payment_received/data[i].manager_approved_revenue)*100,
             data[i].payment_received_pending
            ]);
            cnt++;
          }
        }
        const totalRowData = response.data.total_row;

        tableData.panel_revenue_total_row = [
          "",
          "Grand Total",
          totalRowData.manager_approved_revenue,
          totalRowData.payment_received,
          totalRowData.payment_received==0?0:(totalRowData.payment_received/totalRowData.manager_approved_revenue)*100,
          totalRowData.payment_received_pending
        ];

        tableData.manual_revenue_total_row = [
          "",
          "Grand Total",
          totalRowData.manager_approved_revenue,
          totalRowData.payment_received,
          totalRowData.payment_received==0?0:(totalRowData.payment_received/totalRowData.manager_approved_revenue)*100,
          totalRowData.payment_received_pending
        ];

        setTableData(tableData);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
    
  }

  useEffect(() => {
    if(!initial)
    handleClick(advAppItem,sortTableHead,monthly);
    if(initial){
    setLoading(true); 
    axios
      .get(API_ENDPOINT, {
        params: params,
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((response) => {
        const data = response.data.result;
        const tableData = {
          panel_revenue: [],
          manual_revenue: [],
          panel_revenue_total_row: [],
          manual_revenue_total_row: [],
        };
        if (groupBy === "advertiser_id") {
          cnt=1;
          for (let i = 0; i < data.length; i++) {
            tableData.panel_revenue.push([
              cnt,
              data[i].advertiser_id,
              data[i].manager_approved_revenue,
              data[i].payment_received,
              data[i].payment_received==0?0:(data[i].payment_received/data[i].manager_approved_revenue)*100,
              data[i].payment_received_pending
            ]);
            tableData.manual_revenue.push([
              cnt,
              data[i].advertiser_id,
              data[i].manager_approved_revenue,
              data[i].payment_received,
              data[i].payment_received==0?0:(data[i].payment_received/data[i].manager_approved_revenue)*100,
              data[i].payment_received_pending
            ]);
            cnt++;
          }
        } else if (groupBy === "month") {
          cnt=1;
          for (let i = sortKey=="month"? data.length-1:0; sortKey=="month"? i>=0:i<data.length;sortKey=="month"?i-- : i++) {
            tableData.panel_revenue.push([
              cnt,
              new Date(data[i].month).toLocaleDateString("en-US", {
                month: "short",year:"2-digit"
              }),
              data[i].manager_approved_revenue,
              data[i].payment_received,
              data[i].payment_received==0?0:(data[i].payment_received/data[i].manager_approved_revenue)*100,
              data[i].payment_received_pending
            ]);
            tableData.manual_revenue.push([
              cnt,
              new Date(data[i].month).toLocaleDateString("en-US", {
                month: "short",year:"2-digit"
              }),
              data[i].manager_approved_revenue,
              data[i].payment_received,
              data[i].payment_received==0?0:(data[i].payment_received/data[i].manager_approved_revenue)*100,
              data[i].payment_received_pending
            ]);
            cnt++;
          }
        }
        else if (groupBy === "offers") {
          cnt=1;
          for (let i = 0; i<data.length; i++) {
            tableData.panel_revenue.push([
              cnt,
              data[i].offer_name,
              data[i].manager_approved_revenue,
              data[i].payment_received,
              data[i].payment_received==0?0:(data[i].payment_received/data[i].manager_approved_revenue)*100,
              data[i].payment_received_pending,
              data[i].validated? 'Validated':'Pending',
            ]);
            tableData.manual_revenue.push([
              cnt,
              data[i].offer_name,
              data[i].manager_approved_revenue,
              data[i].payment_received,
              data[i].payment_received==0?0:(data[i].payment_received/data[i].manager_approved_revenue)*100,
              data[i].payment_received_pending,
              data[i].validated? 'Validated':'Pending',
            ]);
            cnt++;
          }
        } else {
          cnt=1;
          for (let i = 0; i < data.length; i++) {
          
            let x;
            if(data[i].app_name==="")
              x=data[i].package_name;
            else
              x=data[i].app_name;
            tableData.panel_revenue.push([
              cnt,
              x,
              data[i].manager_approved_revenue,
              data[i].payment_received,
              data[i].payment_received==0?0:(data[i].payment_received/data[i].manager_approved_revenue)*100,
              data[i].payment_received_pending
            ]);
            tableData.manual_revenue.push([
              cnt,
              x,
              data[i].manager_approved_revenue,
              data[i].payment_received,
              data[i].payment_received==0?0:(data[i].payment_received/data[i].manager_approved_revenue)*100,
              data[i].payment_received_pending
            ]);
            cnt++;
          }
        }
        const totalRowData = response.data.total_row;
        tableData.panel_revenue_total_row = [
          "",
          "Grand Total",
          totalRowData.manager_approved_revenue,
          totalRowData.payment_received,
          totalRowData.payment_received==0?0:(totalRowData.payment_received/totalRowData.manager_approved_revenue)*100,
          totalRowData.payment_received_pending
        ];

        tableData.manual_revenue_total_row = [
          "",
          "Grand Total",
          totalRowData.manager_approved_revenue,
          totalRowData.payment_received,
          totalRowData.payment_received==0?0:(totalRowData.payment_received/totalRowData.manager_approved_revenue)*100,
          totalRowData.payment_received_pending
        ];
        if(summaryOffer){
          tableData.panel_revenue_total_row.push( 
            totalRowData.validated)
          tableData.manual_revenue_total_row.push( 
              totalRowData.validated)
          }

        setTableData(tableData);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
    }
  }, [groupBy, sortTableHead, sortHeadOrder, btnClick,monthly,initial]);

  const selectedTable =
    selectedRev === "Panel Revenue"
      ? tableData.panel_revenue
      : tableData.manual_revenue;
  const selectedTotalRow =
    selectedRev === "Panel Revenue"
      ? tableData.panel_revenue_total_row
      : tableData.manual_revenue_total_row;
  const itemsPerPage = itemsOnPage;
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageChange = (selected) => {
    setCurrentPage(selected.selected);
  };

  const startIdx = currentPage * itemsPerPage;
  const endIdx = (currentPage + 1) * itemsPerPage;
  const dataToDisplay = selectedTable.slice(startIdx, endIdx);
  var tot;
  var cnt = 1;
  var rowIndex;
   const getBackgroundColor = (index) => {
     return index % 2 === 0 ? "#FFFFFF" : "#e7f4fb";
   };
  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <div className={combinedClassName}>
            <table className="tab1" style={{ textAlign: "center", padding:"0"}}>
              <thead>
                <tr>
                  {columnHeadings.map((heading, index) => {
                    if (index == 0)
                      return (
                        <th
                          style={{
                            textAlign: "left",
                            paddingLeft: "0.5rem",
                            fontWeight: "500",
                          }}
                        >
                          {heading}
                        </th>
                      );
                    else if (index == 1) {
                      return (
                        <th
                          style={{
                            textAlign: "left",
                            paddingLeft: "1rem",
                            fontWeight: "500",
                          }}
                        >
                          {heading}
                        </th>
                      );
                    } else if (selectedColumns[index - 2])
                      return (
                        <th style={{ paddingRight: "0rem", backgroundColor:sortColourclass[sortKey] === columnHeadings[index]?"#AFC4D6":"#CFE1EE" }}>
                          <CustomSelect
                            placeholder={heading}
                            options={sortingOptions}
                            isRadioOption={true}
                            isPlaceholderFixed={true}
                            sortTableHead={sortTableHead}
                            setSortTableHead={setSortTableHead}
                            sortHeadOrder={sortHeadOrder}
                            setSortHeadOrder={setSortHeadOrder}
                            sorHead={sorHead}
                            setSorHead={setSorHead}
                            width={"9rem"}
                            width_val={"7rem"}
                            fontWeight2={"500"}
                            marginLeft={"4.2rem"}
                            paddingRight={"0px"}
                            menu={true}
                          />
                        </th>
                      );
                  })}
                </tr>

                <tr className="last-row-addtable" style={{backgroundColor:"#0E6BA8"}}>
                  {selectedTotalRow.map((item, index) => {
                    if (index === 0) return <td key={index}>{item}</td>;
                    if (index === 1)
                      return (
                        <td
                          style={{textAlign: "left", paddingLeft: "0.76rem" ,paddingRight:"2rem"}}
                          key={index}
                        >
                          {item}
                        </td>
                      );
                    if (index === 2) tot = item;
                    if (selectedColumns[index - 2]) {
                      if (index === 4)
                        return <td key={index}>{Math.round(item)}%</td>;
                      if (index === 6) return <td key={index}>{item}</td>;
                      if (index==2 || index === 3 || index === 5) {
                        if (index === 5) {
                          return (
                            <td key={index}>
                              
                               {Number(Math.round(item)).toLocaleString(
                                  "en-US"
                                )}
                             
                            </td>
                          );
                        } else {
                          return (
                            <td key={index}>
                              {Number(Math.round(item)).toLocaleString(
                                  "en-US"
                                )}
                              
                            </td>
                          );
                        }
                      } else {
                        return (
                          <td key={index}>
                            {" "}
                            {Number(Math.round(item)).toLocaleString("en-US")}
                          </td>
                        );
                      }
                    }
                  })}
                </tr>
              </thead>
              {dataToDisplay.map((item1, index) => {
                rowIndex=index;
                return (
                  <tr
                    key={index}
                    className={index % 2 !== 0 ? "oddcolour" : "rowclass"}
                  >
                    {item1.map((item, index) => {
                      if (index == 0) {
                        return (
                          <td
                            style={{ textAlign: "left", paddingLeft: "0.5rem"}}
                          >
                            {item}
                          </td>
                        );
                      } else if (index == 1) {
                        if (typeof item === "number") {
                          return (
                            <td
                              style={{
                                textAlign: "Left",
                                paddingLeft: "1rem",
                                backgroundColor: getBackgroundColor(rowIndex),
                              }}
                              className="first_col"
                              key={index}
                              onClick={()=>{if(initial){
                                if(setAdvapp)
                                setAdvapp(swappedMap.get(item.toString()));
                                handleClick(item);
                                }
                                else if(name=="yearly" && !monthly){
                                  handleClick(item);
                                }
                              }
                              }
                            >
                              {swappedMap.get(item.toString())}
                            </td>
                          );
                        } else {
                          return (
                            <td
                              style={{
                                textAlign: "Left",
                                paddingLeft: "1rem",
                              paddingRight: "1rem" ,
                                backgroundColor: getBackgroundColor(rowIndex),
                              }}
                              className="first_col"
                              key={index}
                              onClick={()=>{if(initial){
                                if(setAdvapp)
                                setAdvapp(item.toString());
                              else if(name=="topAdvertiser")
                              handleClick(item);
                            else
                                handleClick(appPackageMap.get(item)); //
                               }
                                else if(name=="yearly" && !monthly){
                                  setMonthly(true);
                                  if(setAdvappmonth)
                                  setAdvappmonth(item); 
                                  handleClick(advAppItem,'',true);
                                }
                              }}
                            >
                              {item}
                            </td>
                          );
                        }
                      }
                      {
                        if (selectedColumns[index - 2]) {
                          if(index===6  ){
                            return <td
                            key={index}
                            style={{paddingRight: "4rem" ,}}
                            className="centre allcell"
                            >
                              {item==="Validated"?
                              ( <div style={{display:"flex",justifyContent:"flex-end",alignItems:"center"}}>
                                 <FiberManualRecordIcon sx={{color:'green',fontSize:'12px',paddingRight:"4px"}}/>
                              Validated
                              </div>):(<div style={{display:"flex",justifyContent:"flex-end",alignItems:"center"}}>
                                <FiberManualRecordIcon sx={{color:'orange',fontSize:'12px',paddingRight:"4px"}}/>
                               Pending </div>
                              )}
                            </td>
                          }
                          if (index === 4) {
                              return (
                                <td
                                  key={index}
                                  style={{paddingRight: "4rem" ,}}
                                  className="centre allcell"
                                  onClick={()=>{
                                    if(typeof item1[1] === "number"){
                                      {if(initial){
                                        if(setAdvapp)
                                        setAdvapp(swappedMap.get(item1[1].toString()));
                                        handleClick(item1[1],columnHeadings[index]);
                                        }
                                        else if(name=="yearly" && !monthly){
                                          handleClick(item1[1],columnHeadings[index]);
                                        }
                                      }
                                    }
                                    else{
                                      {if(initial){
                                        if(setAdvapp)
                                        setAdvapp(item1[1].toString());
                                      else if(name=="topAdvertiser")
                                      handleClick(item1[1]);
                                    else
                                        handleClick(appPackageMap.get(item1[1])); //
                                       }
                                        else if(name=="yearly" && !monthly){
                                          setMonthly(true);
                                          if(setAdvappmonth)
                                          setAdvappmonth(item1[1]); 
                                          handleClick(advAppItem,columnHeadings[index],true);
                                        }
                                      }}
                                  }}
                                >
                                  <PercentageDiv percentage={item.toFixed(2)} />
                                </td>
                              );
                          }
                          if (index != 0) {
                            if (
                              sortColourclass[sortKey] === columnHeadings[index]
                            ) {
                              return (
                                <td
                                onClick={()=>{
                                  if(typeof item1[1] === "number"){
                                    {if(initial){
                                      if(setAdvapp)
                                      setAdvapp(swappedMap.get(item1[1].toString()));
                                      handleClick(item1[1],columnHeadings[index]);
                                      }
                                      else if(name=="yearly" && !monthly){
                                        handleClick(item1[1],columnHeadings[index]);
                                      }
                                    }
                                  }
                                  else{
                                    {if(initial){
                                      if(setAdvapp)
                                      setAdvapp(item1[1].toString());
                                    else if(name=="topAdvertiser")
                                    handleClick(item1[1]);
                                  else
                                      handleClick(appPackageMap.get(item1[1])); //
                                     }
                                      else if(name=="yearly" && !monthly){
                                        setMonthly(true);
                                        if(setAdvappmonth)
                                        setAdvappmonth(item1[1]); 
                                        handleClick(advAppItem,columnHeadings[index],true);
                                      }
                                    }}
                                }}
                                  className="allcell"
                                  key={index}
                                  style={{paddingRight: "4rem" ,}}
                                >
                                  {Number(Math.round(item)).toLocaleString(
                                    "en-US"
                                  )}
                                </td>
                              );
                            } else {
                              return (
                                <td
                                  // style={{ width: "10px !important" }}
                                  className="allcell"
                                  style={{paddingRight: "4rem" ,}}
                                  key={index}
                                  onClick={()=>{
                                    if(typeof item1[1] === "number"){
                                      {if(initial){
                                        if(setAdvapp)
                                        setAdvapp(swappedMap.get(item1[1].toString()));
                                        handleClick(item1[1],columnHeadings[index]);
                                        }
                                        else if(name=="yearly" && !monthly){
                                          handleClick(item1[1],columnHeadings[index]);
                                        }
                                      }
                                    }
                                    else{
                                      {if(initial){
                                        if(setAdvapp)
                                        setAdvapp(item1[1].toString());
                                      else if(name=="topAdvertiser")
                                      handleClick(item1[1]);
                                    else
                                        handleClick(appPackageMap.get(item1[1])); //
                                       }
                                        else if(name=="yearly" && !monthly){
                                          setMonthly(true);
                                          if(setAdvappmonth)
                                          setAdvappmonth(item1[1]); 
                                          handleClick(advAppItem,columnHeadings[index],true);
                                        }
                                      }}
                                  }}
                                >
                                  {Number(Math.round(item)).toLocaleString(
                                    "en-US"
                                  )}
                                </td>
                              );
                            }
                          }
                        }
                      }
                    })}
                  </tr>
                );
              })}
            </table>
          </div>
          <div className="footer">
            <CustomSelect
              className="data"
              placeholder={"Data Limit"}
              isPlaceholderFixed={true}
              options={[
                { value: 5, label: 5 },
                { value: 10, label: 10 },
                { value: 50, label: 50 },
              ]}
              setItemsOnPage={setItemsOnPage}
              itemsOnPage={itemsOnPage}
              width="7rem"
              maxHeight="25px"
              color="#FFF9F9"
              bgcolor="#0E6BA8"
              img={whiteArrow}
              marginTop={"1rem"}
              menu={true}
            />
            <ReactPaginate
              pageCount={Math.ceil(selectedTable.length / itemsPerPage)}
              pageRangeDisplayed={5}
              marginPagesDisplayed={0}
              initialPage={currentPage}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"activeManik"}
              pageLinkClassName={"page-number"}
              previousLabel={
                <img
                  src={leftArrow}
                  alt="Previous"
                  style={{ marginTop: "5px", width: "10px", height: "15px" }}
                />
              }
              breakLabel={false}
              nextLabel={
                <img
                  src={rightArrow}
                  alt="Next"
                  style={{ marginTop: "5px", width: "10px", height: "15px" }}
                />
              }
            />
          </div>
        </div>
      )}
    </>
  );
}

export default CustomTable;
