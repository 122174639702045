import React, { useEffect, useRef, useState } from "react";
import Calendar from "react-calendar";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa6";
// import 'react-calendar/dist/Calendar.css';
import '../pages/accountManager/managerStatsCss.css';
const CalendarHeader = ({ date, onPrev, onNext }) => {
  const month = date.toLocaleDateString("en-US", { month: "long" });
  const year = date.getFullYear();

  return (
    <div className="flex justify-center space-x-4 items-center mb-4">
      <FaCaretLeft className="text-[#0E6BA8]" onClick={onPrev} />
      <h1 className=" text-[#0E6BA8] font-bold font-kanit text-sm">
        {month} {year}
      </h1>
      <FaCaretRight className="text-[#0E6BA8]" onClick={onNext} />
    </div>
  );
};

const CalenderComponent = ({ onClick, isModalOpen, setisModalOpen, setSelectedRangedata,handleTable,setDayLabel}) => {
  const [Calendar1value, Calendar1onChange] = useState(new Date());
  const [Calendar2value, Calendar2onChange] = useState(new Date());
  const [selectedRange, setSelectedRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [daysName, setdaysName] = useState('Last 30 days')
  const [isDragging, setIsDragging] = useState(false);
  const [initialClickDate, setInitialClickDate] = useState(null);

  const modalRef = useRef(null);

  const handleClick=()=>{
    setSelectedRangedata(selectedRange,daysName)
    if(handleTable)
    handleTable(selectedRange)
    console.log(selectedRange)
    console.log(daysName)
  }

  useEffect(() => {
    handleClick()
  }, [selectedRange])
  
  // useEffect(() => {
  //   const storedData = localStorage.getItem("selectedRange");
  //   if (storedData) {
  //     localStorage.clear();
  //   } else {
  //     localStorage.setItem("selectedRange", JSON.stringify(selectedRange));
  //   }
  // }, [selectedRange]);

  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isModalOpen]);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setisModalOpen(false);
    }
  };

  useEffect(() => {
    const prevMonth = new Date(Calendar2value);
    prevMonth.setMonth(prevMonth.getMonth() - 1);
    Calendar1onChange(prevMonth);
  }, [Calendar2value]);

  const handleCalendar2Change = (value) => {
    Calendar2onChange(value);

    const prevMonth = new Date(value);
    prevMonth.setMonth(prevMonth.getMonth() - 1);
    Calendar1onChange(prevMonth);
  };

  const increaseMonth = () => {
    const nextMonth = new Date(Calendar2value);
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    Calendar2onChange(nextMonth);
  };

  const decreaseMonth = () => {
    const prevMonth = new Date(Calendar2value);
    prevMonth.setMonth(prevMonth.getMonth() - 1);
    Calendar2onChange(prevMonth);
  };

  const formatShortWeekday = (locale, date) => {
    const weekdays = ["S", "M", "T", "W", "T", "F", "S"];
    return weekdays[date.getDay()];
  };

  const handleDateRangeClick = (option) => {
    const today = new Date();
    let startDate, endDate;
    let name;

    switch (option) {
      
      case "Today":
        startDate = new Date(today);
        endDate = new Date(today);
        name = "Today"
        break;
      case "Yesterday":
        startDate = new Date(today);
        startDate.setDate(today.getDate() - 1);
        endDate = new Date(startDate);
        name = "Yesterday"
        break;
      case "This week":
        startDate = new Date(today);
        startDate.setDate(today.getDate() - today.getDay());
        endDate = new Date(today);
        endDate.setDate(startDate.getDate() + 7);
        name = "This week"
        break;
      case "Last Week":
        startDate = new Date(today);
        startDate.setDate(today.getDate() - today.getDay() - 7);
        endDate = new Date(today);
        endDate.setDate(startDate.getDate() + 7);
        name = "Last Week"
        break;
      case "This Month":
        startDate = new Date(today.getFullYear(), today.getMonth(), 1);
        endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        name = "This Month"
        break;
      case "Last Month":
        startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        endDate = new Date(today.getFullYear(), today.getMonth(), 0);
        name = "Last Month"
        break;
      case "Last 7 days":
        startDate = new Date(today);
        startDate.setDate(today.getDate() - 8);
        endDate = new Date(today);
        name = "Last 7 days"
        break;
      case "Last 15 days":
        startDate = new Date(today);
        startDate.setDate(today.getDate() - 16);
        endDate = new Date(today);
        name = "Last 15 days"
        break;
      case "Last 30 days":
        startDate = new Date(today);
        startDate.setDate(today.getDate() - 31);
        endDate = new Date(today);
        name = "Last 30 days"
        break;
      case "Last 45 days":
        startDate = new Date(today);
        startDate.setDate(today.getDate() - 46);
        endDate = new Date(today);
        name = "Last 45 days"
        break;
      case "Last 60 days":
        startDate = new Date(today);
        startDate.setDate(today.getDate() - 61);
        endDate = new Date(today);
        name = "Last 60 days"
        break;
      default:
        startDate = new Date(today);
        startDate.setDate(today.getDate() - 15);
        endDate = new Date(today);
        break;
    }
    if(setDayLabel)
    setDayLabel(name)
    setSelectedRange({ startDate: startDate, endDate: endDate });
    setdaysName(name)
    const data = { startDate: startDate, endDate: endDate };
    setInitialClickDate(startDate);
    setIsDragging(true);
  };


  const handleDateRangeDrag = (date) => {
    if (isDragging) {
      const newEndDate = date > initialClickDate ? date : initialClickDate;
      const newStartDate = date < initialClickDate ? date : initialClickDate;

      setSelectedRange({ startDate: newStartDate, endDate: newEndDate });
    }
  };

  const handleDateRangeDragEnd = () => {
    setIsDragging(false);
    setInitialClickDate(null);
  };

  const [isSelectingEndDate, setIsSelectingEndDate] = useState(false);
  const [firstDate, setfirstDate] = useState(null)
  const [secondDate, setsecondDate] = useState(null)

  const handleDayClick = (value) => {
    let data = { startDate: null, endDate: null }
    let startData = null;
    let endData = null;
    if (!isSelectingEndDate) {
      console.log("Setting start date:", value);
      data = { startDate: value, endDate: null }
      setfirstDate(value)
      startData = value;
      setIsSelectingEndDate(true);
    } else {
      console.log("Setting end date:", value);
      endData = value
      setsecondDate(value)
      setIsSelectingEndDate(false);
      console.log(startData)
    }
    const start=selectedRange.startDate
    const end=selectedRange.endDate 
    setSelectedRange({startDate:startData!=null? startData:start,endDate:endData!=null?endData:startData})
    setDayLabel("")
    setdaysName("")
    setfirstDate(null)
    setsecondDate(null)

  };

  const isDateInRange = (date) => {
    if (!selectedRange.startDate || !selectedRange.endDate) return false;
    return date >= selectedRange.startDate && date <= selectedRange.endDate;
  };

  return (
    <div className="" ref={modalRef}>
      <div className="fixed px-3 py-2 top-[17%] left-[7%] h-[390px] shadow-md shadow-slate-400 z-50 rounded-[10px] w-[45%] flex-col space-y-2 items-center justify-start bg-white">
        <div>
          <h1 className=" text-sm text-black text-start"  style={{fontSize:"20px",fontWeight:"500"}}>
            Show for
          </h1>
          <div 
          // style={{display:"flex", flexWrap:"wrap"}}
           className="mt-1 grid grid-cols-6">
          <div
              onClick={() => handleDateRangeClick("Today")}
              className={` ${daysName == "Today" && "bg-blue-300"} col-span-1 m-1 border text-center cursor-pointer calendar-day`}
            >
             Today
          </div>
            <div
              onClick={() => handleDateRangeClick("Yesterday")}
              className={` ${daysName == "Yesterday" && "bg-blue-300"} col-span-1 m-1 border text-center cursor-pointer calendar-day`}
            >
                Yesterday
            </div>
            <div
              onClick={() => handleDateRangeClick("This week")}
              className={` ${daysName == "This week" && "bg-blue-300"} col-span-1 m-1 border text-center cursor-pointer calendar-day`}
            >
                This week
            </div>
            <div
               onClick={() => handleDateRangeClick("Last Week")}
              className={` ${daysName == "Last Week" && "bg-blue-300"} col-span-1 m-1 border text-center cursor-pointer calendar-day`}
            >
               Last Week
            </div>
            <div
              onClick={() => handleDateRangeClick("This Month")}
              className={` ${daysName == "This Month" && "bg-blue-300"} col-span-1 m-1 border text-center cursor-pointer calendar-day`}
            >
                This Month
            </div>
            <div
              onClick={() => handleDateRangeClick("Last Month")}
              className={` ${daysName == "Last Month" && "bg-blue-300"} col-span-1 m-1 border text-center cursor-pointer calendar-day`}
            >
                Last Month
            </div>
            <div
              onClick={() => handleDateRangeClick("Last 7 days")}
              className={` ${daysName == "Last 7 days" && "bg-blue-300"} col-span-1 m-1 border text-center cursor-pointer calendar-day`}
            >
                Last 7 days
            </div>
            <div
              onClick={() => handleDateRangeClick("Last 15 days")}
              className={` ${daysName == "Last 15 days" && "bg-blue-300"} col-span-1 m-1 border text-center cursor-pointer calendar-day`}
            >
                Last 15 days
            </div>
            <div
              onClick={() => handleDateRangeClick("Last 30 days")}
              className={` ${daysName == "Last 30 days" && "bg-blue-300"} col-span-1 m-1 border text-center cursor-pointer calendar-day`}
            >
                Last 30 days
            </div>
            <div
              onClick={() => handleDateRangeClick("Last 45 days")}
              className={` ${daysName == "Last 45 days" && "bg-blue-300"} col-span-1 m-1 border text-center cursor-pointer calendar-day`}
            >
                Last 45 days
            </div>
            <div
              onClick={() => handleDateRangeClick("Last 60 days")}
              className={` ${daysName == "Last 60 days" && "bg-blue-300"} col-span-1 m-1 border text-center cursor-pointer calendar-day`}
            >
                Last 60 days
            </div>
            </div>
        </div>
          <div className=" border-b border"/>
        <div>
          <CalendarHeader
            date={Calendar2value}
            onPrev={decreaseMonth}
            onNext={increaseMonth}
          />
        </div>
        <div className=" flex items-center justify-between">
          <div className=" flex w-[47%]  items-center justify-center">
            <Calendar
              onChange={Calendar1onChange}
              value={Calendar1value}
              formatShortWeekday={formatShortWeekday}
              onClickDay={(value) => handleDayClick(value)}
              tileClassName={({ date }) =>
                isDateInRange(date) ? "selected-range" : ""
              }
            />
          </div>
          <div className="flex w-[47%] items-center justify-center">
            <style>{`
        .react-calendar__month-view__weekdays__weekday abbr {
          font-weight: 600;
          font-size:14px;
          text-decoration: none;
        }
        .react-calendar__navigation__label {
          color: #676767; // Change the color as needed
          text-decoration: none;
          font-weight: 500;
        }
        .react-calendar__navigation__arrow {
          display: none; // Hide the navigation arrows
        }
        .selected-range {
          background-color: #0E6BA8;
          color: #ffffff;
        }
      `}</style>
            <Calendar
              onChange={handleCalendar2Change}
              value={Calendar2value}
              formatShortWeekday={formatShortWeekday}
              className=""
              onClickDay={(value) => handleDayClick(value)}
              tileClassName={({ date }) =>
                isDateInRange(date) ? "selected-range" : ""
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalenderComponent;
