import Header from "./components/Header";
import "./insightsCss.css";
import YearlySynopsis from "./components/YearlySynopsis";
import MonthlySynopsis from "./components/MonthlySynopsis";
import TeamWiseAnatomy from "./components/TeamWiseAnatomy";
import RedFlagAdvertisers from "./components/RedFlagAdvertisers";
import NavBar from "./components/NavBar";
import Reset from "./assets/reset2.svg"
import { useState, useEffect } from "react";
import axios from "axios";
import { getLocalStorageValue } from "./util/util";
import FilterRow from "../../../components/filterV2/filtersRow";
import { getAdvertisers, getManager } from '../../../apis/dropdown/dropdowns';

import { AdvertiserIcon, AdvertiserManagerIcon, AffiliateIcon, AppIcon, DateRangeIcon, MoneyIcon, SpiralIcon, TagIcon, TeamIcon } from '../../../data/icons';
import { getRequests } from "../../../util/util";
import * as Constants from '../../../data/constants';
function InsightsManik() {


  var defaultFilterData = {
    startMonth:new Date(new Date().getFullYear(),new Date().getMonth()-3,1),
    endMonth:new Date(new Date().getFullYear(),new Date().getMonth()-1,1),
    selectedRev:{label:'Verified Revenue',value:'Verified Revenue'}
  }

    
  const [selectedRev, setSelectedRev] = useState(defaultFilterData.selectedRev.value);
  const [initdate, setInitdate] = useState(new Date(defaultFilterData.startMonth));
  const [finaldate, setFinaldate] = useState(new Date(defaultFilterData.endMonth));
  const [team, setTeam] = useState("");
  const [advapp, setAdvapp] = useState("Red Flag Advertisers (Top 10) ");
  const [advertiser, setAdvertiser] = useState([]);
  const [sortOrder, setSortOrder] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [appData, setAppData] = useState({})
  const [packageName,setPackageName]=useState([]);
  const [manager, setManager] = useState([]);
  const [reset,setReset]=useState(true);
  
  const [optionsAdvertiserList,setOptionsAdvertiserList] = useState([])
  const [optionsManagersList,setOptionsManagerList] = useState([])
  var advData = optionsAdvertiserList
  // const [advData, setAdvData] = useState([{}]);
  
  var advertiserString="";
  var packageString="";
  var managerString="";

  const appPackageMap = new Map();
  for (let key in appData) {
    appPackageMap.set(appData[key].app_name, key);
  }
  for(let i=0;i<advertiser.length;i++)
  { 
    if(i===advertiser.length-1)
      advertiserString += advertiser;
    else
      advertiserString+=advertiser+",";
  }
  for(let i=0;i<packageName.length;i++)
  { 
    if(i===packageName.length-1)
      packageString += packageName;
    else
      packageString+=packageName+",";
  }
  for (let i = 0; i <manager.length; i++) 
  {
    if (i === manager.length - 1) managerString += manager;
    else managerString += manager + ",";
  }
  
  const swappedMap = new Map();
  for (let i = 0; i < advData.length; i++) {
    if (advData[i]) {
        swappedMap.set(advData[i].value, advData[i].label.split(' - ')[0]);
    }
  }
  var sort = "deduction_revenue_manual";
  var group = "advertiser_id";
  if (advapp === "Red Flag Advertisers (Top 10) ") {
    if (selectedRev === "Panel Revenue") sort = "deduction_revenue";
    else sort = "deduction_revenue_manual";
    group = "advertiser_id";
  }
  if (advapp === "Validated Advertisers (Top 10) ") {
    sort = "manager_approved_revenue";
    group = "advertiser_id";
  }
  if (advapp === "Red Flag Apps (Top 10) ") {
    if (selectedRev === "Panel Revenue") sort = "deduction_revenue";
    else sort = "deduction_revenue_manual";
    group = "package_name";
  }
  if (advapp === "Validated Apps (Top 10) ") {
    sort = "manager_approved_revenue";
    group = "package_name";
  }
  if (advapp === "Pending Validations ( Advertisers Top 10 )") {
    group = "advertiser_id";
    if (selectedRev === "Panel Revenue") sort = "pending_revenue";
    else sort = "pending_revenue_manual";
  }
  if (advapp === "Pending Validations ( Offers Top 10 )") {
    group = "offer_id";
    if (selectedRev === "Panel Revenue") sort = "pending_revenue";
    else sort = "pending_revenue_manual";
  }
  if (advapp === "Pending Validations ( Apps Top 10 )") {
    group = "package_name";
    if (selectedRev === "Panel Revenue") sort = "pending_revenue";
    else sort = "pending_revenue_manual";
  }
  const originalString = team;
  const modTeam = originalString.replace(/\s+/g, "");

  var sorting;
  sorting = false;
  if (sortColumn === "Verified Revenue") {
    //console.log("helooooo");
    if (selectedRev === "Panel Revenue") {
      sort = "total_revenue";
    } else {
      sort = "conversions_verified";
    }
    sorting = sortOrder;
  } else if (sortColumn === "Approved Revenue") {
    sort = "manager_approved_revenue";
    sorting = sortOrder;
  } else if (sortColumn === "Validation %") {
    if (selectedRev === "Panel Revenue") {
      sort = "validation_percentage";
    } else {
      sort = "validation_percentage_manual";
    }
    sorting = sortOrder;
  } else if (sortColumn === "Pending Validation") {
    if (selectedRev === "Panel Revenue") {
      sort = "pending_revenue";
    } else {
      sort = "pending_revenue_manual";
    }
    sorting = sortOrder;
  } else if (sortColumn === "Deduction") {
    if (selectedRev === "Panel Revenue") {
      sort = "deduction_revenue";
    } else {
      sort = "deduction_revenue_manual";
    }
    sorting = sortOrder;
  }
  const [chartData2, setChartData2] = useState({
    panel_revenue: {
      month: [],
      pending_revenue: [],
      deduction_revenue: [],
      validation_revenue: [],
      validation_percentage: [],
      panel_revenue: [],
    },
    manual_revenue: {
      month: [],
      pending_revenue: [],
      deduction_revenue: [],
      validation_revenue: [],
      validation_percentage: [],
      panel_revenue: [],
    },
  });
  const [redFlagChartData2, setRedFlagChartData2] = useState({
    panel_revenue: {
      month: [],
      pending_revenue: [],
      deduction_revenue: [],
      validation_revenue: [],
      validation_percentage: [],
      panel_revenue: [],
    },
    manual_revenue: {
      month: [],
      pending_revenue: [],
      deduction_revenue: [],
      validation_revenue: [],
      validation_percentage: [],
      panel_revenue: [],
    },
  });

  const [tableData2, setTableData2] = useState({
    panel_revenue: [],
    manual_revenue: [],
  });

  const getFormatedDate = (date) => {
    const originalDate = new Date(date);
    const year = originalDate.getFullYear();
    const month = (originalDate.getMonth() + 1).toString().padStart(2, "0");
    return `${year}-${month}`;
  };

  const [optionsAppList,setOptionsAppList] = useState([])
  const [optionsPackageNameList,setOptionsPackageNameList] = useState([])

const getAllManager = async() => {
  try{
    const response = await axios({
      method: 'GET',
      url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"employees"),
      headers:{
        Authorization:getLocalStorageValue('adminPanelLoginData').token
      },
    });
    const managerlist = {};
    const data = response.data.result;
    for (const item of data) {
      managerlist[item.id] = item['employee_type'];
    }
    setOptionsManagerList(prevOptionsManagersList => {
      return prevOptionsManagersList.filter(manager => {
        const id = manager.value;
        return managerlist[id] === 'affiliate_manager' || managerlist[id] === 'account_manager';
      });
    });
  }
  catch (err) {
    console.error(err);
    return {};
  }
};

  useEffect(() => {

    getRequests(getAdvertisers(),setOptionsAdvertiserList)
    getRequests(getManager(),setOptionsManagerList)     
    getAllManager()
    axios.get(
      "".concat(Constants.PROTOCOL,Constants.HOST,"/api/admin/v1/packagename_appname"),
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      )
      .then((response) => {
        const data = response.data.result;
        var packageNameList = []
        var appNameList = []
        for(var package_name in data){
          var item = data[package_name]
          if(item.app_name){
            appNameList.push({label:item.app_name,value:package_name})
          }
          packageNameList.push({label:package_name,value:package_name})
        }
        setAppData(data)
        setOptionsAppList(appNameList)
        setOptionsPackageNameList(packageNameList)
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  
  //console.log(sort);
  const token = getLocalStorageValue('adminPanelLoginData').token;

  console.log('rrrrrr',swappedMap)
  const getStatsData = async() => {
    await axios
    .get(
      "".concat(Constants.PROTOCOL,Constants.HOST,"/api/admin/v2/invoice/summary"),
      {
        headers: {
          Authorization: `${token}`,
        },
        params:{
          team:modTeam ? modTeam : undefined,
          fromMonth:getFormatedDate(initdate),
          toMonth:getFormatedDate(finaldate),
          // limit:10,
          page:1,
          total_row_required:true,
          group_by:group,
          sorting_key:sort,
          sorting_order:sorting,
          manager_id:managerString,
          advertiser_id:advertiser!=""?advertiserString:null,
          package_name:packageName!=""?packageString:null
        }
      }
    )
    .then((response) => {
      const chartData = {
        panel_revenue: {
          month: [],
          pending_revenue: [],
          deduction_revenue: [],
          validation_revenue: [],
          validation_percentage: [],
          panel_revenue: [],
        },
        manual_revenue: {
          month: [],
          pending_revenue: [],
          deduction_revenue: [],
          validation_revenue: [],
          validation_percentage: [],
          panel_revenue: [],
        },
      };
      const redFlagChartData={
        panel_revenue: {
          month: [],
          pending_revenue: [],
          deduction_revenue: [],
          validation_revenue: [],
          validation_percentage: [],
          panel_revenue: [],
        },
        manual_revenue: {
          month: [],
          pending_revenue: [],
          deduction_revenue: [],
          validation_revenue: [],
          validation_percentage: [],
          panel_revenue: [],
        },
      };
      const tableData = { panel_revenue: [], manual_revenue: [] };

      const data = response.data.result;
      if (group === "advertiser_id") {
        for (let i = 0; i < data.length; i++) {
          chartData.panel_revenue.month.push(
            swappedMap.get(data[i].advertiser_id.toString())
          );
          chartData.panel_revenue.deduction_revenue.push(
            data[i].deduction_revenue
          );
          chartData.panel_revenue.pending_revenue.push(
            data[i].pending_revenue
          );
          chartData.panel_revenue.panel_revenue.push(data[i].total_revenue);
          chartData.panel_revenue.validation_revenue.push(
            data[i].manager_approved_revenue
          );
          chartData.panel_revenue.validation_percentage.push(
            data[i].valiadation_percentage
          );
          if(i<10){
            redFlagChartData.panel_revenue.month.push(
              swappedMap.get(data[i].advertiser_id.toString())
            );
            redFlagChartData.panel_revenue.deduction_revenue.push(
              data[i].deduction_revenue
            );
            redFlagChartData.panel_revenue.pending_revenue.push(
              data[i].pending_revenue
            );
            redFlagChartData.panel_revenue.panel_revenue.push(data[i].total_revenue);
            redFlagChartData.panel_revenue.validation_revenue.push(
              data[i].manager_approved_revenue
            );
            redFlagChartData.panel_revenue.validation_percentage.push(
              data[i].valiadation_percentage
            );
          }
        }
        for (let i = 0; i < data.length; i++) {
          chartData.manual_revenue.month.push(
            swappedMap.get(data[i].advertiser_id.toString()) ? swappedMap.get(data[i].advertiser_id.toString()) : data[i].advertiser_id.toString()
          );
          chartData.manual_revenue.deduction_revenue.push(
            data[i].deduction_revenue_manual
          );
          chartData.manual_revenue.pending_revenue.push(
            data[i].pending_revenue_manual
          );
          chartData.manual_revenue.panel_revenue.push(data[i].conversions_verified);
          chartData.manual_revenue.validation_revenue.push(
            data[i].manager_approved_revenue
          );
          chartData.manual_revenue.validation_percentage.push(
            data[i].validation_percentage_manual
          );

          if(i<10){
            redFlagChartData.manual_revenue.month.push(
              swappedMap.get(data[i].advertiser_id.toString()) ? swappedMap.get(data[i].advertiser_id.toString()) : data[i].advertiser_id.toString()
            );
            redFlagChartData.manual_revenue.deduction_revenue.push(
              data[i].deduction_revenue_manual
            );
            redFlagChartData.manual_revenue.pending_revenue.push(
              data[i].pending_revenue_manual
            );
            redFlagChartData.manual_revenue.panel_revenue.push(data[i].conversions_verified);
            redFlagChartData.manual_revenue.validation_revenue.push(
              data[i].manager_approved_revenue
            );
            redFlagChartData.manual_revenue.validation_percentage.push(
              data[i].validation_percentage_manual
            );
          }
        }
        setChartData2(chartData);
        setRedFlagChartData2(redFlagChartData);
      } else if (group === "package_name") {
        for (let i = 0; i < data.length; i++) {
          chartData.panel_revenue.month.push(data[i].package_name);
          chartData.panel_revenue.deduction_revenue.push(
            data[i].deduction_revenue
          );
          chartData.panel_revenue.pending_revenue.push(
            data[i].pending_revenue
          );
          chartData.panel_revenue.panel_revenue.push(data[i].total_revenue);
          chartData.panel_revenue.validation_revenue.push(
            data[i].manager_approved_revenue
          );
          chartData.panel_revenue.validation_percentage.push(
            data[i].validation_percentage
          );
          if(i<10){
            redFlagChartData.panel_revenue.month.push(data[i].package_name);
            redFlagChartData.panel_revenue.deduction_revenue.push(
            data[i].deduction_revenue
          );
          redFlagChartData.panel_revenue.pending_revenue.push(
            data[i].pending_revenue
          );
          redFlagChartData.panel_revenue.panel_revenue.push(data[i].total_revenue);
          redFlagChartData.panel_revenue.validation_revenue.push(
            data[i].manager_approved_revenue
          );
          redFlagChartData.panel_revenue.validation_percentage.push(
            data[i].validation_percentage
          );
          }
        }
        for (let i = 0; i < data.length; i++) {
          chartData.manual_revenue.month.push(data[i].package_name);
          chartData.manual_revenue.deduction_revenue.push(
            data[i].deduction_revenue_manual
          );
          chartData.manual_revenue.pending_revenue.push(
            data[i].pending_revenue_manual
          );
          chartData.manual_revenue.panel_revenue.push(
            data[i].conversions_verified
          );
          chartData.manual_revenue.validation_revenue.push(
            data[i].manager_approved_revenue
          );
          chartData.manual_revenue.validation_percentage.push(
            data[i].validation_percentage_manual
          );
          if(i<10){
            redFlagChartData.manual_revenue.month.push(data[i].package_name);
            redFlagChartData.manual_revenue.deduction_revenue.push(
            data[i].deduction_revenue_manual
          );
          redFlagChartData.manual_revenue.pending_revenue.push(
            data[i].pending_revenue_manual
          );
          redFlagChartData.manual_revenue.panel_revenue.push(
            data[i].conversions_verified
          );
          redFlagChartData.manual_revenue.validation_revenue.push(
            data[i].manager_approved_revenue
          );
          redFlagChartData.manual_revenue.validation_percentage.push(
            data[i].validation_percentage_manual
          );
          }
        }
        setChartData2(chartData);
        setRedFlagChartData2(redFlagChartData);
      } else {
        for (let i = 0; i < data.length; i++) {
          chartData.panel_revenue.month.push(data[i].offer_id);
          chartData.panel_revenue.deduction_revenue.push(
            data[i].deduction_revenue
          );
          chartData.panel_revenue.pending_revenue.push(
            data[i].pending_revenue
          );
          chartData.panel_revenue.panel_revenue.push(data[i].total_revenue);
          chartData.panel_revenue.validation_revenue.push(
            data[i].manager_approved_revenue
          );
          chartData.panel_revenue.validation_percentage.push(
            data[i].validation_percentage
          );
          if(i<10){
            redFlagChartData.panel_revenue.month.push(data[i].offer_id);
            redFlagChartData.panel_revenue.deduction_revenue.push(
            data[i].deduction_revenue
          );
          redFlagChartData.panel_revenue.pending_revenue.push(
            data[i].pending_revenue
          );
          redFlagChartData.panel_revenue.panel_revenue.push(data[i].total_revenue);
          redFlagChartData.panel_revenue.validation_revenue.push(
            data[i].manager_approved_revenue
          );
          redFlagChartData.panel_revenue.validation_percentage.push(
            data[i].validation_percentage
          );
          }
        }
        for (let i = 0; i < data.length; i++) {
          chartData.manual_revenue.month.push(data[i].offer_id);
          chartData.manual_revenue.deduction_revenue.push(
            data[i].deduction_revenue_manual
          );
          chartData.manual_revenue.pending_revenue.push(
            data[i].pending_revenue_manual
          );
          chartData.manual_revenue.panel_revenue.push(
            data[i].conversions_verified
          );
          chartData.manual_revenue.validation_revenue.push(
            data[i].manager_approved_revenue
          );
          chartData.manual_revenue.validation_percentage.push(
            data[i].validation_percentage_manual
          );
          if(i<10){
            redFlagChartData.manual_revenue.month.push(data[i].offer_id);
            redFlagChartData.manual_revenue.deduction_revenue.push(
            data[i].deduction_revenue_manual
          );
          redFlagChartData.manual_revenue.pending_revenue.push(
            data[i].pending_revenue_manual
          );
          redFlagChartData.manual_revenue.panel_revenue.push(
            data[i].conversions_verified
          );
          redFlagChartData.manual_revenue.validation_revenue.push(
            data[i].manager_approved_revenue
          );
          redFlagChartData.manual_revenue.validation_percentage.push(
            data[i].validation_percentage_manual
          );
          }
        }
        setChartData2(chartData);
        setRedFlagChartData2(redFlagChartData);
      }
      if (group === "advertiser_id") {
        for (let i = 0; i < data.length; i++) {
          tableData.panel_revenue.push([
            swappedMap.get(data[i].advertiser_id.toString()) ? swappedMap.get(data[i].advertiser_id.toString()) : data[i].advertiser_id.toString(),
            data[i].total_revenue,
            data[i].manager_approved_revenue,
            data[i].validation_percentage,
            data[i].deduction_revenue,
            data[i].pending_revenue,
          ]);

          tableData.manual_revenue.push([
            swappedMap.get(data[i].advertiser_id.toString()) ? swappedMap.get(data[i].advertiser_id.toString()) : data[i].advertiser_id.toString(),
            data[i].conversions_verified,
            data[i].manager_approved_revenue,
            data[i].validation_percentage_manual,
            data[i].deduction_revenue_manual,
            data[i].pending_revenue_manual,
          ]);
        }
        setTableData2(tableData);
      } else if (group === "package_name") {
        for (let i = 0; i < data.length; i++) {
          tableData.panel_revenue.push([
            data[i].app_name,
            data[i].total_revenue,
            data[i].manager_approved_revenue,
            data[i].validation_percentage,
            data[i].deduction_revenue,
            data[i].pending_revenue,
          ]);

          tableData.manual_revenue.push([
            data[i].app_name,
            data[i].conversions_verified,
            data[i].manager_approved_revenue,
            data[i].validation_percentage_manual,
            data[i].deduction_revenue_manual,
            data[i].pending_revenue_manual,
          ]);
        }
        setTableData2(tableData);
      } else {
        for (let i = 0; i < data.length; i++) {
          tableData.panel_revenue.push([
            data[i].offer_id,
            data[i].total_revenue,
            data[i].manager_approved_revenue,
            data[i].validation_percentage,
            data[i].deduction_revenue,
            data[i].pending_revenue,
          ]);

          tableData.manual_revenue.push([
            data[i].offer_id,
            data[i].conversions_verified,
            data[i].manager_approved_revenue,
            data[i].validation_percentage_manual,
            data[i].deduction_revenue_manual,
            data[i].pending_revenue_manual,
          ]);
        }
        setTableData2(tableData);
      }
    })
    .catch((error) => {
      setChartData2({panel_revenue: [], manual_revenue: [] })
      setRedFlagChartData2({panel_revenue: [], manual_revenue: [] })
      setTableData2({ panel_revenue: [], manual_revenue: [] })
      console.error(error);
    });
  }
  useEffect(() => {
    if(advData){
      getStatsData()
    }
  }, [initdate, finaldate, team, advapp, sortColumn, sortOrder,advData,reset,manager,advertiser,packageName]);


  const [filterData, setFilterData] = useState(defaultFilterData)
  var filtersList = [
    {label:'Date Range',icon:DateRangeIcon,paramKey:'dateRange',value:'date_range',type:'monthPicker'},
    {label:'Team Name',icon:TeamIcon,paramKey:'team',value:'team',type:'select',multiple:false,hideValueInLabel:true,options:[{label:'Team 1',value:'Team1'},{label:'Team 2',value:'Team2'},{label:'Team 3',value:'Team3'}]},
    {label:'App Name',icon:AppIcon,paramKey:'appData',value:'appData',type:'select',multiple:true,hideValueInLabel:true,options:optionsAppList},
    {label:'Package Name',icon:SpiralIcon,paramKey:'packageName',value:'package_name',type:'select',multiple:true,hideValueInLabel:true,options:optionsPackageNameList},
    {label:'Revenue',icon:MoneyIcon,paramKey:'selectedRev',value:'selectedRev',type:'select',hideValueInLabel:true,options:[{label:'Verified Revenue',value:'Verified Revenue'},{label:'Panel Revenue',value:'Panel Revenue'}]},
    {label:'Advertiser',icon:AdvertiserIcon,paramKey:'advertiser',value:'advertiser_id',type:'select',multiple:true,options:optionsAdvertiserList},
    {label:'Account Manager',icon:AdvertiserManagerIcon,paramKey:'advertiserManager',value:'advertiser_manager_id',type:'select',multiple:true,options:optionsManagersList},
    {label:'Submit Reset Button',type:'submit_reset_button',onSubmit:getStatsData,onReset:()=>{setFilterData(defaultFilterData);setTeam("");setPackageName([]);setSelectedRev('Verified Revenue');setAdvertiser([]);setManager([]);setReset(!reset)}},
  ]
  const overrideSetFilterData = (value,filterItem) => {
    if(filterItem.value==='team'){
      setTeam(value.value)
    }
    if(filterItem.value==='appData' || filterItem.value==='package_name'){
      var __list = []
      for(var index in value){
        var item = value[index]
        __list.push(item.value)
      }
      setPackageName(__list)
    }
    if(filterItem.value==='selectedRev'){
      setSelectedRev(value.value)
    }
    if(filterItem.value==='advertiser_id'){
      var __list = []
      for(var index in value){
        var item = value[index]
        __list.push(item.value)
      }
      setAdvertiser(__list)
    }
    if(filterItem.value==='advertiser_manager_id'){
      var __list = []
      for(var index in value){
        var item = value[index]
        __list.push(item.value)
      }
      setManager(__list)
    }

    
    if(filterItem==='dateRangePicker'){
        setFilterData({...filterData,...value})
    }
    else if(filterItem.type==='monthPicker'){
      setInitdate(value[0])
      setFinaldate(value[1] ? value[1] : value[0])
      setFilterData({...filterData,startMonth:value[0],endMonth:value[1]})
    }
    else{
        setFilterData({...filterData,[filterItem.value]:value})
    }
  }
  console.log('rrrrrrrrrr',packageName)
  return (
    <div className="insightsManik">
      <Header />
      <FilterRow
          filtersList={filtersList}
          filterData={filterData}
          setFilterData={overrideSetFilterData}
          filterItemWidth={'25'}
      />
      {/* <NavBar
        selectedRev={selectedRev}
        setSelectedRev={setSelectedRev}
        initdate={initdate}
        setInitdate={setInitdate}
        finaldate={finaldate}
        setFinaldate={setFinaldate}
        team={team}
        setTeam={setTeam}
        advertiser={advertiser}
        setAdvertiser={setAdvertiser}
        advData={advData}
        setAdvData={setAdvData}
        packageName={packageName}
        setPackageName={setPackageName}
        appData={appData}
        setAppData={setAppData}
        manager={manager}
        setManager={setManager}
      /> */}
      <div
        style={{ display: "flex", width: "104%", justifyContent: "flex-end" }}
      ></div>

      <YearlySynopsis
        team={modTeam}
        selectedRev={selectedRev}
        advertiser={advertiser}
        packageName={packageName}
        manager={manager}
        advData={advData}
        advertiserString={advertiserString}
        packageString={packageString}
        managerString={managerString}
        appPackageMap={appPackageMap}
        setAdvertiser={setAdvertiser}
        setTeam={setTeam}
        setPackageName={setPackageName}
        setManager={setManager}
        setSelectedRev={setSelectedRev}
        setFilterData={setFilterData}
        filterData={filterData}
        initdate={getFormatedDate(initdate)}
        finaldate={getFormatedDate(finaldate)}
      />

      <MonthlySynopsis
        modTeam={modTeam}
        initdate={getFormatedDate(initdate)}
        finaldate={getFormatedDate(finaldate)}
        selectedRev={selectedRev}
        advertiser={advertiser}
        packageName={packageName}
        advData={advData}
        manager={manager}
        advertiserString={advertiserString}
        packageString={packageString}
        managerString={managerString}
        appPackageMap={appPackageMap}
        setAdvertiser={setAdvertiser}
        setTeam={setTeam}
        setPackageName={setPackageName}
        setManager={setManager}
        setSelectedRev={setSelectedRev}
        setFilterData={setFilterData}
        filterData={filterData}
      />
      <TeamWiseAnatomy
        initdate={getFormatedDate(initdate)}
        finaldate={getFormatedDate(finaldate)}
        advertiser={advertiser}
        packageName={packageName}
        manager={manager}
        selectedRev={selectedRev}
        advData={advData}
        advertiserString={advertiserString}
        packageString={packageString}
        managerString={managerString}
        appPackageMap={appPackageMap}
        setAdvertiser={setAdvertiser}
        setTeam={setTeam}
        setPackageName={setPackageName}
        setManager={setManager}
        setSelectedRev={setSelectedRev}
        setFilterData={setFilterData}
        filterData={filterData}
      />
      <RedFlagAdvertisers
        advapp={advapp}
        setAdvapp={setAdvapp}
        chartData={
          selectedRev === "Verified Revenue"
            ? redFlagChartData2.manual_revenue
            : redFlagChartData2.panel_revenue
        }
        tableData2={tableData2}
        setTableData2={setTableData2}
        modTeam={modTeam}
        selectedRev={selectedRev}
        advertiser={advertiser}
        packageName={packageName}
        initdate={getFormatedDate(initdate)}
        finaldate={getFormatedDate(finaldate)}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        sortColumn={sortColumn}
        setSortColumn={setSortColumn}
        advData={advData}
        manager={manager}
        // reset={reset}
        // setReset={setReset}
        appPackageMap={appPackageMap}
        managerString={managerString}
        setAdvertiser={setAdvertiser}
        setTeam={setTeam}
        setPackageName={setPackageName}
        setManager={setManager}
        setSelectedRev={setSelectedRev}
        setFilterData={setFilterData}
        filterData={filterData}
        advertiserString={advertiserString}
        packageString={packageString}
      />
    </div>
  );
}

export default InsightsManik;
