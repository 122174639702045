import React, { useEffect, useState } from 'react';
import './v2dateRangePicker.css';
import { DateRangePicker } from 'react-date-range';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { locale } from 'dayjs';

export const getThisWeek = (locale=null,options={}) => {
    var curr = new Date;
    var firstday = new Date(curr.setDate(curr.getDate() - curr.getDay()));
    var lastday = new Date(curr.setDate(curr.getDate() - curr.getDay()+6));
    if(locale){
        firstday = firstday.toLocaleString(locale,options)
        lastday = lastday.toLocaleString(locale,options)
    }
    return {firstDayOfThisWeek:firstday,lastDayOfThisWeek:lastday}
}
export const lastThisWeek = (locale=null,options=null) => {
    var curr = new Date(new Date(new Date().getTime()-86400000*7)); // get current date
    var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    var last = first + 6; // last day is the first day + 6

    var firstday = new Date(curr.setDate(first));
    var lastday = new Date(curr.setDate(last));
    if(locale){
        var firstday = firstday.toLocaleString(locale,options)
        var lastday = lastday.toLocaleString(locale,options)
    }
    return {firstDayOfLastWeek:firstday,lastDayOfLastWeek:lastday}
}

export const thisMonth = (locale=null,options=null) => {
    var date = new Date(), y = date.getFullYear(), m = date.getMonth();
    var firstday = new Date(y, m, 1);
    var lastday = new Date(y, m + 1, 0);
    if(locale){
        var firstday = firstday.toLocaleString(locale,options)
        var lastday = lastday.toLocaleString(locale,options)
    }
    return {firstDayOfThisMonth:firstday,lastDayOfThisMonth:lastday}
}

export const lastMonth = (locale=null,options=null) => {
    var date = new Date();
    var firstday = new Date(date.getFullYear(), date.getMonth()-1, 1);
    var lastday = new Date(date.getFullYear(), date.getMonth(), 0);
    if(locale){
        var firstday = firstday.toLocaleString(locale,options)
        var lastday = lastday.toLocaleString(locale,options)
    }
    return {firstDayOfLastMonth:firstday,lastDayOfLastMonth:lastday}
}
const DateRangePickerComponent = (props) => {
    var dateRangeState = [{
        startDate: props.filterData && props.filterData.from_date ? props.filterData.from_date : new Date(),
        endDate: props.filterData && props.filterData.end_date ? props.filterData.end_date : new Date(),
        key: 'selection'
    }]
    
    const getLabel = () => {
        var startDate = new Date(dateRangeState[0].startDate).toLocaleString('en-GB',{year:'2-digit',month:'short',day:'numeric'})
        var endDate = new Date(dateRangeState[0].endDate).toLocaleString('en-GB',{year:'2-digit',month:'short',day:'numeric'})
        var today = new Date().toLocaleString('en-GB',{year:'2-digit',month:'short',day:'numeric'})
        var yesterday = new Date(new Date().getTime()-86400000*1).toLocaleString('en-GB',{year:'2-digit',month:'short',day:'numeric'})
        var {firstDayOfThisWeek,lastDayOfThisWeek} = getThisWeek('en-GB',{year:'2-digit',month:'short',day:'numeric'})
        var {firstDayOfLastWeek,lastDayOfLastWeek} = lastThisWeek('en-GB',{year:'2-digit',month:'short',day:'numeric'})
        var {firstDayOfThisMonth,lastDayOfThisMonth} = thisMonth('en-GB',{year:'2-digit',month:'short',day:'numeric'})
        var {firstDayOfLastMonth,lastDayOfLastMonth} = lastMonth('en-GB',{year:'2-digit',month:'short',day:'numeric'})
        if(startDate===endDate){
            if(today === startDate){
                return 'Today'
            }
            if(yesterday === startDate){
                return 'Yesterday'
            }
        }
        if(firstDayOfThisWeek===startDate && lastDayOfThisWeek===endDate){
            return 'This Week'
        }
        if(firstDayOfLastWeek===startDate && lastDayOfLastWeek===endDate){
            return 'Last Week'
        }
        if(firstDayOfThisMonth===startDate && lastDayOfThisMonth===endDate){
            return 'This Month'
        }
        if(firstDayOfLastMonth===startDate && lastDayOfLastMonth===endDate){
            return 'Last Month'
        }
        return startDate + ' -> ' + endDate
    }
    const [showDateRangePicker, setShowDateRangePicker] = useState(0)

    useEffect(() => {
        document.addEventListener('click',e=>{
            setShowDateRangePicker(0)
        })
    
        return () => {
            document.removeEventListener('click',e=>{
                setShowDateRangePicker(0)
            })
        }
    }, [])

    const changeDateRange = (item) => {
        if(props.customDateRangeFunction){
            return props.customDateRangeFunction(item,showDateRangePicker,setShowDateRangePicker)
        }
        setShowDateRangePicker(showDateRangePicker+1)
        var value = [item.selection.startDate,item.selection.endDate]
        props.setFilterData({from_date:value[0],end_date:value[1]},'dateRangePicker')
        if(showDateRangePicker===2){
            setShowDateRangePicker(0)
        }
    }
    return (
        <div>
            <div className='v2demographyDateRangeContainer' onClick={(e)=>{e.stopPropagation();setShowDateRangePicker(1)}}>
                <div className='v2DateLabel'>{getLabel()}</div>
            </div>
            {showDateRangePicker!==0
            &&
            <div className='v2demographyDateRangePicker' onClick={(e)=>{e.stopPropagation()}}>
                <DateRangePicker
                    onChange={item => changeDateRange(item)}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    preventSnapRefocus={true}
                    rangeColors={[localStorage.getItem('ambientColor')]}
                    ranges={dateRangeState}
                    showDateDisplay={false}
                    scroll={{ enabled: window.innerWidth<800 ? true : false }}
                    direction={window.innerWidth<800 ? "vertical" : "horizontal"}
                    calendarFocus="backwards"
                    />
            </div>
            }
        </div>
    )
}

export default DateRangePickerComponent